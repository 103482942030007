import React from 'react'

const CeldaConPunto = ({mostrar}) => {
  return (
    <div className="flex justify-center items-center">
      <span
        className={
          !mostrar
            ? 'hidden'
            : 'h-5 w-5 bg-cendiatra-semaforo-rojo rounded-full '
        }
      ></span>
    </div>
  )
}

export default CeldaConPunto
