import React from 'react'

const EncabezadoPestana = ({
  contexto,
  numeroPaginacion,
  titulo,
  deshabilitarValidaciones,
}) => {
  const irAlTab = (numeroPaginaNuevo) => {
    if (
      deshabilitarValidaciones ||
      contexto.numeroPagina >= numeroPaginaNuevo
    ) {
      contexto.setNumeroPagina(numeroPaginaNuevo)
    } else if (
      !contexto.formularioActualTieneErrores &&
      (!contexto?.errores || contexto?.errores?.length == 0) &&
      numeroPaginaNuevo == contexto.numeroPagina + 1
    ) {
      contexto.setNumeroPagina(numeroPaginaNuevo)
    }
  }

  return (
    <div
      className={`w-full md:w-1/5 flex flex-wrap items-center border-b-4 ${
        contexto.numeroPagina === numeroPaginacion
          ? 'border-cendiatra'
          : 'border-cendiatra-gris-4'
      } m-1`}
      onClick={() => irAlTab(numeroPaginacion)}
    >
      <div className="flex justify-center  w-full">
        <div className="w-4/7 flex justify-center items-center">
          <p
            className={`w-5 h-5 rounded-full text-center text-14px ${
              contexto.numeroPagina === numeroPaginacion
                ? 'bg-cendiatra'
                : 'bg-cendiatra-gris-4'
            } text-white font-bold my-9px mx-10px`}
          >
            {numeroPaginacion}
          </p>
        </div>
        <div className="w-4/7 flex justify-start items-center">
          <p
            className={`text-14px text-center  ${
              contexto.numeroPagina === numeroPaginacion
                ? 'text-cendiatra'
                : 'text-cendiatra-gris-4 '
            }  `}
          >
            {titulo}
          </p>
        </div>
      </div>
    </div>
  )
}

export default EncabezadoPestana
