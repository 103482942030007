import React, {useContext} from 'react'
import {ContextApplication} from '../../contexto'
import Modal from './Modal'

const ModalCendiatra = () => {
  const contexto = useContext(ContextApplication)
  return (
    <Modal open={contexto.modal.abierto}>
      <div className="w-full flex flex-wrap justify-center items-center uppercase">
        {!Boolean(contexto.modal.titulo) &&
        !Boolean(contexto.modal.subtitulo) ? null : (
          <div
            className={`w-full ${
              contexto.modal?.removerEspacios ? 'mt-70px' : 'mt-90px'
            }`}
          >
            <span className="text-cendiatra text-17px  leading-20px font-bold uppercase">
              {contexto.modal.titulo}
            </span>
            <span className="text-cendiatra text-17px  mb-30px leading-20px uppercase">
              {contexto.modal.subtitulo}
            </span>
          </div>
        )}
        <div
          className={`w-full flex flex-wrap justify-center ${
            contexto.modal?.removerEspacios ? 'mt-30px' : 'mt-50px'
          } leading-20px text-17px text-cendiatra-verde-7 uppercase`}
        >
          {contexto.modal.contenido}
        </div>
        {contexto.modal.botones.length > 0 ? (
          <div
            className={`w-full flex justify-evenly items-center ${
              contexto.modal?.removerEspacios
                ? 'mt-30px mb-60px'
                : 'mt-50px mb-80px'
            } text-17px text-cendiatra-verde-7 `}
          >
            {contexto.modal.botones.map((boton) => (
              <button
                key={boton.nombre}
                className="hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                type="button"
                onClick={boton.click}
              >
                {' '}
                {boton.nombre}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    </Modal>
  )
}
export default ModalCendiatra
