import React from 'react'

const EncabezadoConSubTitulos = ({
  titulo,
  subtitulo1 = '',
  subtitulo2 = '',
  paddingFondoClaro = '',
  fondoCendiatra = 'bg-cendiatra-verde-5',
}) => {
  return (
    <div className="h-full w-full flex flex-wrap">
      <div
        className={`w-full flex flex-wrap justify-center items-center ${fondoCendiatra} my-1 mx-0.5 rounded-md text-14px ${paddingFondoClaro}`}
      >
        {titulo}
        <div className="w-full flex flex-nowrap justify-center items-center">
          <span className="w-1/2 flex justify-evenly items-center">
            {subtitulo1}
          </span>
          <span
            className={`${
              subtitulo2 ? 'w-1/2 flex' : 'hidden'
            } justify-evenly items-center`}
          >
            {subtitulo2}
          </span>
        </div>
      </div>
    </div>
  )
}

export default EncabezadoConSubTitulos
