import {createContext, useState} from 'react'

export const ContextoModales = createContext('')

export const ModalesConContexto = ({children}) => {
  const [formulaMedica, setFormulaMedica] = useState({})
  const [interconsulta, setInterConsulta] = useState({})
  const [incapacidadMedica, setIncapacidadMedica] = useState({})
  const [formulaOptometrica, setFormulaOptometrica] = useState({})
  const [solicitudValoracion, setSolicitudValoracion] = useState({})
  const [parametrosFormulario, setParametrosFormulario] = useState(null)

  return (
    <ContextoModales.Provider
      value={{
        formulaMedica: formulaMedica,
        setFormulaMedica: setFormulaMedica,
        interconsulta: interconsulta,
        setInterConsulta: setInterConsulta,
        parametrosFormulario: parametrosFormulario,
        setParametrosFormulario: setParametrosFormulario,
        formulaOptometrica: formulaOptometrica,
        setFormulaOptometrica: setFormulaOptometrica,
        incapacidadMedica: incapacidadMedica,
        setIncapacidadMedica: setIncapacidadMedica,
        solicitudValoracion: solicitudValoracion,
        setSolicitudValoracion: setSolicitudValoracion,
      }}
    >
      {children}
    </ContextoModales.Provider>
  )
}
