import React from 'react'

const LeyendaSemaforizacion = ({colorFondo, texto, nombreTrabajador}) => {
  return (
    <>
      <div className="w-full flex flex-wrap justify-center items-center my-2.5px">
        <div className="w-1/12 justify-center items-center">
          <div className={`h-3 w-3 ${colorFondo}`}></div>
        </div>
        <div className="w-11/12 flex flex-wrap justify-center items-center leading-15px">
          <span className="w-full justify-start items-center text-left text-13px text-cendiatra-gris-1">
            {texto}
          </span>
        </div>
      </div>
    </>
  )
}

export default LeyendaSemaforizacion
