import React from 'react'
import {useTable} from 'react-table'

const TablaTurnosPendientes = ({data, columns}) => {
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, rows} =
    useTable({
      columns,
      data,
    })

  return (
    <div className="flex w-full mb-10">
      <table
        className="flex flex-wrap w-full border-separate"
        {...getTableProps()}
      >
        <thead className="flex w-full flex-wrap text-14px">
          {headerGroups.map((headerGroup) => (
            <tr
              className="flex w-full flex-wrap text-center"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((header) => (
                <th
                  {...header.getHeaderProps({
                    className: `${header.className} ${header.headerStyle} flex justify-center items-center content-center`,
                  })}
                >
                  {header.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="flex w-full flex-wrap h-440 overflow-scroll overflow-x-hidden text-13px content-start"
          {...getTableBodyProps()}
        >
          {rows.map((row, index) => {
            {
              prepareRow(row)
              return (
                <tr
                  className="flex w-full flex-wrap text-center relative"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{backgroundColor: 'white'}}
                        {...cell.getCellProps({
                          className: `${cell.column.className} ${
                            cell.column.tipo == 'boton'
                              ? ''
                              : 'border-2 border-cendiatra-verde-7 rounded-md'
                          } h-9 flex my-1 mx-0.5 justify-center items-center content-center truncate`,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaTurnosPendientes
