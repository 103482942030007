import {getRestApi} from './ApiAxios'

//#region GET
export const ObtenerProximoCodigoInterno = async (tipo) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'referencia-function/ObtenerProximoCodigoInterno?tipo=' + tipo
  )
}

export const ObtenerProximoId = async (tipo) => {
  const apiReq = await getRestApi()
  return await apiReq.get('referencia-function/ObtenerProximoId?tipo=' + tipo)
}

export const ObtenerCacheComercial = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('referencia-function/ObtenerCacheComercial')
}

export const ObtenerSedesCendiatra = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('referencia-function/ObtenerSedes')
}

export const ObtenerValoresDeReferencia = async (tipo) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'referencia-function/ObtenerValoresReferencia?tipo=' + tipo
  )
}

export const ObtenerLocalidades = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('referencia-function/ObtenerLocalidades')
}

export const ObtenerCIE10 = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('referencia-function/ObtenerCie10')
}

export const ObtenerFuenteDatosPlantillas = async (
  fechaUltimaActualizacion
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'referencia-function/ObtenerFuenteDatosPlantillas?fecha=' +
      fechaUltimaActualizacion
  )
}

//#endregion
