import React, {useState, useContext, useEffect} from 'react'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import CeldaConCheckbox from '../Tablas/CeldaConCheckbox'
import CeldaConBotonSimple from '../Tablas/CeldaConBotonSimple'
import {ContenedorDesplegable} from '../Contenedores'
import {ContextApplication} from '../../contexto'
import moment from 'moment'
import FormularioDatosDeLosServicios from './FormularioDatosDeLosServicios'
import FormularioConfirmacionDeDatos from './FormularioConfirmacionDeDatos'
import ComponenteConjuntoDeFiltros from '../Filtros/ComponenteConjuntoDeFiltros'
import {
  agregarFormatoFecha,
  aplicarFormatoMoneda,
} from '../../utilidades/funcionesComunes'
import {AgregarServiciosACache} from '../../cache/servicios-cache'
import {
  ObtenerPagosPendientesPaginados,
  ObtenerInformacionDetalladaPagoPorId,
  ObtenerFechaUltimaCargaRegistrosPagos,
} from '../../microservicios'
import {BotonSimple} from '../Botones'
import {NOMBRE_BANCOS} from '../../constantes/NombreBancos'
import clsx from 'clsx'

const TablaConfirmacionPagos = () => {
  const contextoAplicacion = useContext(ContextApplication)

  useEffect(() => {
    const obtenerDatosServicios = () => {
      AgregarServiciosACache()
    }
    obtenerDatosServicios()
  }, [])

  const filtrosIniciales = {
    tamano: 10,
    pagina: 1,
    filtros: {
      fecha: '',
      numeroCuenta: '',
      nombreBanco: '',
      referencia: '',
      segundaReferencia: '',
      descripcion: '',
      otro: '',
      nombre: '',
      valor: '',
    },
  }

  const [dataFiltros, setDataFiltros] = useState(filtrosIniciales)
  const [actualizarInformacion, setActualizarInformacion] = useState(true)
  const [datosTabla, setDatosTabla] = useState({datos: [], datosPaginacion: {}})
  const [fechaDatosActualizados, setFechaDatosActualizados] =
    useState('dd/mm/aa')

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      try {
        const respuesta = await ObtenerPagosPendientesPaginados(dataFiltros)
        if (respuesta.status === 200) {
          return setDatosTabla({
            datos:
              respuesta?.data?.datos.map((fila) => {
                return {
                  ...fila,
                  facturaSeleccionada: false,
                }
              }) || [],
            datosPaginacion: respuesta?.data?.paginacion || [],
          })
        }
        return setDatosTabla({datos: [], datosPaginacion: {}})
      } catch (error) {
        console.log(error)
      }
    }
    obtenerDatosIniciales()
  }, [actualizarInformacion])

  useEffect(() => {
    const obtenerFechaUltimaActualizacion = async () => {
      const respuesta = await ObtenerFechaUltimaCargaRegistrosPagos()
      if (respuesta.status === 200) {
        return setFechaDatosActualizados(
          moment(respuesta.data).format('DD/MM/YYYY')
        )
      }
      setFechaDatosActualizados('dd/mm/aa')
    }
    obtenerFechaUltimaActualizacion()
  }, [])

  const filtrarInformacion = () => {
    setActualizarInformacion(!actualizarInformacion)
  }

  const limpiarFiltros = () => {
    setDataFiltros(filtrosIniciales)
    setActualizarInformacion(!actualizarInformacion)
  }

  const bancoValido = (nombreBanco) => {
    return [NOMBRE_BANCOS.AVALPAY_GATO].includes(nombreBanco.toUpperCase())
  }

  const columns = [
    {
      accessor: 'id',
      className: 'w-0 hidden',
      headerStyle: '',
      disableFilters: true,
      cellStyles: '',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Fecha'} />,
      accessor: 'fecha',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <span>{moment(row?.original?.fecha).format('DD-MM-YYYY')}</span>
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Cuenta bancaria'} paddingFondoClaro={''} />
      ),
      accessor: 'cuenta.numeroCuenta',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Nombre del banco'} paddingFondoClaro={''} />
      ),
      accessor: 'cuenta.nombreBanco',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Ref. Doc o ID transacción'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'referencia',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Referencia 2'} paddingFondoClaro={''} />
      ),
      accessor: 'segundaReferencia',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Descripción'} paddingFondoClaro={''} />
      ),
      accessor: 'descripcion',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Otro'} paddingFondoClaro={''} />,
      accessor: 'otro',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Nombre'} paddingFondoClaro={''} />
      ),
      accessor: 'nombre',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 pr-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Valor'} paddingFondoClaro={''} />,
      accessor: 'valor',
      className: 'w-9%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <span>{aplicarFormatoMoneda(row?.original?.valor)}</span>
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Factura'} paddingFondoClaro={''} />
      ),
      accessor: 'factura',
      className: 'w-7%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <CeldaConCheckbox
          valor={row.original.facturaSeleccionada}
          funcion={() => cambiarEstadoCheckbox(row.original.id, true)}
          deshabilitado={bancoValido(row?.original?.cuenta?.nombreBanco)}
        />
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Acciones'} paddingFondoClaro={''} />
      ),
      id: 'acciones',
      className: 'w-12%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center break-all',
      Cell: ({row}) => (
        <CeldaConBotonSimple
          texto={'Confirmar pago'}
          estilosBoton={clsx(
            `w-32 h-10 text-white bg-cover bg-no-repeat rounded-lg text-14px`,
            bancoValido(row?.original?.cuenta?.nombreBanco)
              ? 'bg-cendiatra-gris-2 pointer-events-none'
              : 'bg-btnBg'
          )}
          funcion={() => abrirModalConfirmacionPagoSede(row.original.id)}
          deshabilitado={bancoValido(row?.original?.cuenta?.nombreBanco)}
        />
      ),
    },
  ]

  const cambiarEstadoCheckbox = (id, valor) => {
    const datosMapeados = datosTabla?.datos?.map((pago) => {
      if (pago.id === id) {
        return {
          ...pago,
          facturaSeleccionada: valor,
        }
      }
      return pago
    })
    setDatosTabla((prev) => {
      return {
        ...prev,
        datos: datosMapeados,
      }
    })
    abrirModalConfirmacion(id)
  }
  const manejarCerrarModal = (id) => {
    if (id) {
      cambiarEstadoCheckbox(id, false)
    }
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }
  const abrirModalConfirmacion = (id) => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <div className="text-center w-full">
          <h3 className="text-cendiatra mb-6 text-3xl font-bold normal-case">
            ¿Está seguro de enviar este pago a facturación?
          </h3>
          <p className="text-cendiatra text-xl font-bold normal-case mt-20">
            Este procedimiento se realiza cuando no se genera factura
          </p>
          <p className="text-cendiatra text-xl font-bold normal-case mb-20">
            en sede y el pago corresponde a la atención de más de un paciente.
          </p>
        </div>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => abrirModalServiciosAEnviar(false, id),
        },
        {
          nombre: 'No',
          click: () => manejarCerrarModal(id),
        },
      ],
    })
  }
  const abrirModalConfirmacionPagoSede = (id) => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <div className="text-center w-full">
          <h3 className="text-cendiatra mb-6 text-3xl font-bold normal-case">
            ¿Está seguro de facturar en sede?
          </h3>
          <p className="text-cendiatra text-xl font-bold normal-case mt-20">
            Esta acción se realiza cuando se genera factura en sede y
          </p>
          <p className="text-cendiatra text-xl font-bold normal-case mb-20">
            la misma corresponde a la atención de un solo paciente.
          </p>
        </div>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => abrirModalServiciosAEnviar(true, id),
        },
        {
          nombre: 'No',
          click: () => manejarCerrarModal(id),
        },
      ],
    })
  }

  const abrirModalServiciosAEnviar = async (confirmacion, id) => {
    try {
      const response = await ObtenerInformacionDetalladaPagoPorId(id)

      if (response.status === 200) {
        return contextoAplicacion.setModal({
          abierto: true,
          titulo: '',
          contenido: (
            <div className="w-full flex justify-center items-center text-center">
              {confirmacion ? (
                <FormularioConfirmacionDeDatos
                  valorFactura={response?.data}
                  funcionCerrar={manejarCerrarModal}
                  refrescarDatos={() =>
                    setActualizarInformacion(!actualizarInformacion)
                  }
                />
              ) : (
                <FormularioDatosDeLosServicios
                  valorFactura={response?.data}
                  funcionCerrar={manejarCerrarModal}
                  refrescarDatos={() =>
                    setActualizarInformacion(!actualizarInformacion)
                  }
                />
              )}
            </div>
          ),

          botones: [],
        })
      }
      return contextoAplicacion.setModal({
        abierto: true,
        titulo: '',
        contenido: (
          <div className="w-full flex justify-center items-center text-center font-bold text-2xl mb-10">
            <span>La factura no Existe</span>
          </div>
        ),

        botones: [
          {
            nombre: 'Regresar',
            click: () => manejarCerrarModal(id),
          },
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }

  const filtrosExternos = [
    {
      id: 1,
      estilos: 'w-10.125%',
      titulo: 'Fecha',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fecha',
      formato: 'input',
      valor: dataFiltros.filtros.fecha,
    },
    {
      id: 2,
      estilos: 'w-10.125%',
      titulo: 'Cuenta bancaria',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroCuenta',
      formato: 'input',
      valor: dataFiltros.filtros.numeroCuenta,
    },
    {
      id: 3,
      estilos: 'w-10.125%',
      titulo: 'Nombre del Banco',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nombreBanco',
      formato: 'input',
      valor: dataFiltros.filtros.nombreBanco,
    },
    {
      id: 4,
      estilos: 'w-10.125%',
      titulo: 'Ref. Doc o ID transacción',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'referencia',
      fechaMaxima: moment(dataFiltros.filtros.fechaHasta).format('YYYY-MM-DD'),
      formato: 'input',
      valor: dataFiltros.filtros.referencia,
    },
    {
      id: 5,
      estilos: 'w-10.125%',
      titulo: 'Referencia 2',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'segundaReferencia',
      formato: 'input',
      valor: dataFiltros.filtros.segundaReferencia,
    },
    {
      id: 6,
      estilos: 'w-10.125%',
      titulo: 'Descripción',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'descripcion',
      formato: 'input',
      valor: dataFiltros.filtros.descripcion,
    },
    {
      id: 7,
      estilos: 'w-10.125%',
      titulo: 'Otro',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'otro',
      formato: 'input',
      valor: dataFiltros.filtros.otro,
    },
    {
      id: 9,
      estilos: 'w-10.125%',
      titulo: 'Nombre',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nombre',
      formato: 'input',
      valor: dataFiltros.filtros.nombre,
    },
    {
      id: 10,
      estilos: 'w-10.125%',
      titulo: 'Valor',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'valor',
      formato: 'input',
      valor: dataFiltros.filtros.valor,
    },
  ]

  const manejarCambioEnFiltro = (e) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: agregarFormatoFecha(e),
        },
      }
    })
  }
  const manejarCambioDePagina = (numeroPagina) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: numeroPagina,
      }
    })
    setActualizarInformacion(!actualizarInformacion)
  }

  const ordenarPorFechaDesc = (datos = []) => {
    return datos.sort((a, b) => {
      const fechaA = new Date(a.fecha)
      const fechaB = new Date(b.fecha)
      return fechaB - fechaA
    })
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <div className="w-full flex justify-center items-center">
        <span className="text-center text-cendiatra-rojo-1">
          La información se encuentra actualizada hasta {fechaDatosActualizados}
        </span>
      </div>
      <div className="w-full flex justify-start items-center">
        <ComponenteConjuntoDeFiltros
          filtrosPorCrear={filtrosExternos}
          funcionBoton={filtrarInformacion}
        />
      </div>
      <ContenedorDesplegable
        titulo={'Confirmaciones de Pago'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
        ocultarBotonDesplegable={true}
      >
        <div className="w-full border border-cendiatra -mt-3 border-t-0 border-b-0 h-10 -mb-7 "></div>
        <TablaConPaginacionYFiltros
          columns={columns}
          data={ordenarPorFechaDesc(datosTabla?.datos ?? [])}
          filasPorPagina={10}
          funcionFilaSeleccionada={null}
          paginacionBackend={true}
          parametrosPaginacionConBackEnd={datosTabla?.datosPaginacion}
          maximoDePaginasparaMostrar={10}
          funcionCambiarPagina={manejarCambioDePagina}
          estilosTablaDatos={
            'border border-cendiatra -mt-1 border-t-0 pb-2 px-2 rounded-xl rounded-t-none'
          }
          usarSort={false}
        />
        <div className="w-full flex justify-end items-center -mt-32 mr-10">
          <BotonSimple
            texto={'Limpiar filtros'}
            estilosBoton={
              'w-32 h-10 rounded-lg bg-cover bg-btnBg bg-no-repeat text-white text-lg border border-white'
            }
            funcion={limpiarFiltros}
            tipoDeBoton={'button'}
          />
        </div>
      </ContenedorDesplegable>
    </div>
  )
}

export default TablaConfirmacionPagos
