import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import {useEffect} from 'react'
import OidoDerecho from '../../imagenes/oidoderecho.svg'
import OidoIzquierdo from '../../imagenes/oidoizquierdo.svg'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function GraficaAudiometria({
  dataOD,
  dataOI,
  calculosAudiometria,
}) {
  let imagenGraficaOidoDerecho = new Image(110, 65)
  imagenGraficaOidoDerecho.src = OidoDerecho

  let imagenGraficaOidoIzquierdo = new Image(100, 70)
  imagenGraficaOidoIzquierdo.src = OidoIzquierdo

  const valorOD = Object.values(dataOD)

  const valoresOD = valorOD.map((valores) => {
    if (valores === '') {
      return null
    }
    return valores
  })

  const valoresGraficaOD = valoresOD.filter(
    (value) => typeof value !== 'boolean' && value !== 'OD'
  )

  const valoresGraficaODBoolean = valorOD.filter(
    (value) => typeof value == 'boolean'
  )

  const valorOI = Object.values(dataOI)

  const valoresOI = valorOI.map((valores) => {
    if (valores === '') {
      return null
    }
    return valores
  })

  const valoresGraficaOI = valoresOI.filter(
    (value) => typeof value !== 'boolean' && value !== 'OI'
  )

  const valoresGraficaOIBoolean = valorOI.filter(
    (value) => typeof value == 'boolean'
  )

  const PTAOD =
    (parseInt(valoresGraficaOD[2] ?? 0) +
      parseInt(valoresGraficaOD[3] ?? 0) +
      parseInt(valoresGraficaOD[4] ?? 0) +
      parseInt(valoresGraficaOD[5] ?? 0)) /
    4
  const PTAOI =
    (parseInt(valoresGraficaOI[2] ?? 0) +
      parseInt(valoresGraficaOI[3] ?? 0) +
      parseInt(valoresGraficaOI[4] ?? 0) +
      parseInt(valoresGraficaOI[5] ?? 0)) /
    4

  useEffect(() => {
    calculosAudiometria({PTAOI, PTAOD})
  }, [PTAOI, PTAOD])

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
        position: 'top',
      },
    },
    scales: {
      xAxis: {
        position: 'top',
        offset: true,
      },
      y: {
        max: 120,
        min: 0,
        reverse: true,
        ticks: {
          stepSize: 10,
          beginAtZero: true,
        },
      },
    },
  }

  const labels = ['250', '500', '1k', '2k', '3k', '4k', '6k', '8k']

  const dataGraficaOD = {
    labels,
    datasets: [
      {
        label: 'Aérea',
        data: valoresGraficaOD,
        borderColor: '#f98585',
        backgroundColor: '#f98585',
        pointStyle: [
          valoresGraficaODBoolean[0] ? imagenGraficaOidoDerecho : 'circle',
          valoresGraficaODBoolean[1] ? imagenGraficaOidoDerecho : 'circle',
          valoresGraficaODBoolean[2] ? imagenGraficaOidoDerecho : 'circle',
          valoresGraficaODBoolean[3] ? imagenGraficaOidoDerecho : 'circle',
          valoresGraficaODBoolean[4] ? imagenGraficaOidoDerecho : 'circle',
          valoresGraficaODBoolean[5] ? imagenGraficaOidoDerecho : 'circle',
          valoresGraficaODBoolean[6] ? imagenGraficaOidoDerecho : 'circle',
          valoresGraficaODBoolean[7] ? imagenGraficaOidoDerecho : 'circle',
        ],
        borderWidth: 2,
        pointRadius: 5,
      },
    ],
  }

  const dataGraficaOI = {
    labels,
    datasets: [
      {
        label: 'Aérea',
        data: valoresGraficaOI,
        borderColor: '#0000ff',
        backgroundColor: '#0000ff',
        pointStyle: [
          valoresGraficaOIBoolean[0] ? imagenGraficaOidoIzquierdo : 'crossRot',
          valoresGraficaOIBoolean[1] ? imagenGraficaOidoIzquierdo : 'crossRot',
          valoresGraficaOIBoolean[2] ? imagenGraficaOidoIzquierdo : 'crossRot',
          valoresGraficaOIBoolean[3] ? imagenGraficaOidoIzquierdo : 'crossRot',
          valoresGraficaOIBoolean[4] ? imagenGraficaOidoIzquierdo : 'crossRot',
          valoresGraficaOIBoolean[5] ? imagenGraficaOidoIzquierdo : 'crossRot',
          valoresGraficaOIBoolean[6] ? imagenGraficaOidoIzquierdo : 'crossRot',
          valoresGraficaOIBoolean[7] ? imagenGraficaOidoIzquierdo : 'crossRot',
        ],
        borderWidth: 2,
        pointRadius: 5,
      },
    ],
  }

  return (
    <>
      <div className="w-full flex gap-2">
        <div className="flex-col w-1/2">
          <div className="text-center text-gray-400 text-xs">
            AUDIOGRAMA OIDO DERECHO
          </div>
          <Line options={options} data={dataGraficaOD} id="oidoDerecho" />
        </div>
        <div className="flex-col w-1/2">
          <div className="text-center text-gray-400 text-xs">
            AUDIOGRAMA OIDO IZQUIERDO
          </div>
          <Line options={options} data={dataGraficaOI} id="oidoIzquierdo" />
        </div>
      </div>
      <div className="bg-cendiatra text-white text-center rounded-md mt-10 p-2">
        <span>INTERPRETACIÓN</span>
      </div>

      <div className="flex justify-center gap-10">
        <div className="flex-col my-5 w-1/3">
          <label
            className={`flex leading-15px font-medium text-cendiatra pb-3`}
          >
            PTA OD (1K, 2K, 3K Y 4K)
          </label>
          <input
            className={`appearance-none border border-cendiatra relative block w-full px-3 py-3 placeholder-cendiatra-gris-3 text-cendiatra-3 rounded-lg focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm`}
            placeholder="0"
            type="number"
            value={PTAOD}
            disabled={true}
          />
        </div>
        <div className="flex-col my-5 w-1/3">
          <label
            className={`flex leading-15px font-medium text-cendiatra pb-3`}
          >
            PTA OI (1K, 2K, 3K Y 4K)
          </label>
          <input
            className={`appearance-none border border-cendiatra relative block w-full px-3 py-3 placeholder-cendiatra-gris-3 text-cendiatra-3 rounded-lg focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm`}
            placeholder="0"
            type="number"
            value={PTAOI}
            disabled={true}
          />
        </div>
      </div>
    </>
  )
}
