import React from 'react'
import {BotonSimple} from '../Botones'
import CargaArchivo from './CargaArchivo'

const CargaArchivosBancos = ({parametro}) => {
  return (
    <>
      <div className="w-full flex justify-evenly items-center text-cendiatra">
        <p>
          Cargar únicamente archivo con extensión .xls o .xlsx con la estructura
          correcta.
        </p>
      </div>
      <div className="w-full flex justify-evenly items-center mt-3 mb-3">
        <BotonSimple
          texto={parametro.textoBoton}
          estilosBoton={`w-48 h-12 gap-2 flex items-center text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2 justify-center`}
          funcion={parametro.funcion}
          icono={parametro.imagen}
        />
      </div>

      <CargaArchivo />
    </>
  )
}

export default CargaArchivosBancos
