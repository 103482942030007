import {getRestApi} from './ApiAxios'

export const ObtenerOtrosServicios = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('gato-api-function/ObtenerOtrosServicios')
}

export const OperacionesOtrosServicios = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'gato-api-function/OperacionesOtrosServicios',
    payload
  )
}
