const filtrarPrecioNulo = (elementoPrecio) =>
  !elementoPrecio.precio || elementoPrecio.precio == 0

export const obtenerServiciosOPaquetesConPrecioNulo = (contexto) => {
  const serviciosYPaquetes = [
    ...contexto.listaServicios,
    ...contexto.listaPaquetes,
  ]

  const serviciosOPaquetesConPrecioNulo = serviciosYPaquetes.reduce(
    (prev, servicioOPaquete) => {
      let preciosEnCeroSedes =
        servicioOPaquete.precios_sedes.filter(filtrarPrecioNulo)
      let preciosEnCeroAliados =
        servicioOPaquete.precios_aliados.filter(filtrarPrecioNulo)

      if (preciosEnCeroSedes.length > 0 || preciosEnCeroAliados.length > 0) {
        prev.push({
          ...servicioOPaquete,
          precios_sedes: preciosEnCeroSedes,
          precios_aliados: preciosEnCeroAliados,
        })
      }

      return prev
    },
    []
  )

  return serviciosOPaquetesConPrecioNulo
}
