import {useContext, useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {ContextApplication} from '../../../contexto'
import {BotonSimple} from '../../Botones'
import BotonConImagen from '../../BotonConImagen'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import TablaDinamica from '../../TablaDinamica'
import InformacionAsignacionPlantillaAServicios from './InformacionAsignacionPlantillaAServicios'
import addButtonImg from '../../../imagenes/addButton.png'
import removeButtonImg from '../../../imagenes/removeButton.png'
import {
  ObtenerListaPlantillasDB,
  ObtenerPlantillasConServiciosAsignados,
} from '../../../microservicios/Plantilla'
import {
  AgregarServiciosACache,
  ObtenerTodosLosServicios,
} from '../../../cache/servicios-cache'

const FormularioAsignacionPlantillaAServicios = ({
  limpiarFormulario,
  asignarServiciosAPlantilla,
}) => {
  const contextoAplicacion = useContext(ContextApplication)
  const [plantillas, setPlantillas] = useState([])
  const [plantillasConServiciosAsignados, setPlantillasConServiciosAsignados] =
    useState([])
  const [servicios, setServicios] = useState([])
  const [servicioSeleccionado, setServicioSeleccionado] = useState(null)

  const {
    setValue,
    watch,
    control,
    getValues,
    reset,
    setError,
    formState: {errors},
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      plantilla: null,
      servicio: '',
      seleccionados: [],
    },
  })

  const plantilla = watch('plantilla')
  const servicio = watch('servicio')
  const seleccionados = watch('seleccionados')

  const quitarServicioDeSeleccionados = (id) => {
    const valores = getValues()

    const copiaSeleccionados = [...valores.seleccionados]
    setValue(
      'seleccionados',
      copiaSeleccionados.filter((item) => item.id !== id)
    )
  }

  const columnasTablaServicios = useMemo(
    () => [
      {
        Header: 'SERVICIO',
        accessor: 'filtro',
        className:
          'w-full flex text-center justify-center items-center content-center h-20  my-1 mx-0.5 rounded-md text-14px uppercase',
        Cell: ({row}) => {
          return (
            <div className="w-full flex flex-wrap">
              <div className="w-10/12 text-left ">{row.original.filtro}</div>
              <div className="w-2/12">
                <button
                  onClick={() => quitarServicioDeSeleccionados(row.original.id)}
                >
                  <img src={removeButtonImg} />
                </button>
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  const cerrarAlerta = () => {
    contextoAplicacion.setModal({
      abierto: false,
      botones: [],
      contenido: '',
    })
  }

  const obtenerListasParaDesplegables = async () => {
    await ObtenerListaPlantillasDB().then((respuesta) => {
      if (respuesta.status === 200) {
        setPlantillas(respuesta.data)
      }
    })

    await AgregarServiciosACache().then(() => {
      ObtenerTodosLosServicios().then((respuesta) => {
        setServicios(respuesta)
      })
    })

    await ObtenerPlantillasConServiciosAsignados().then((respuesta) => {
      if (respuesta.status === 200) {
        setPlantillasConServiciosAsignados(respuesta.data)
      }
    })
  }

  useEffect(() => {
    if (limpiarFormulario) {
      reset()
      setServicioSeleccionado(null)
      obtenerListasParaDesplegables()
    }
  }, [limpiarFormulario])

  useEffect(() => {
    obtenerListasParaDesplegables()
  }, [])

  const filtrarInformacionArray = (filtro, array) => {
    if (!array) {
      return []
    }

    if (!filtro) {
      return array.map((item) => ({
        id: item.Id ?? item.id,
        filtro: item.Nombre ?? item.nombre,
      }))
    }
    filtro = filtro.toLowerCase()

    return array
      .filter(
        (item) =>
          item?.Nombre?.toLowerCase().includes(filtro) ||
          item?.nombre?.toLowerCase().includes(filtro)
      )
      .map((item) => ({
        id: item.Id ?? item.id,
        filtro: item.Nombre ?? item.nombre,
      }))
  }

  const filtrarServiciosNoVinculadosAPlantillas = (array) => {
    return array.filter(
      (item) =>
        !plantillasConServiciosAsignados.filter(
          (pcsa) => pcsa.servicioId === item.id
        ).length
    )
  }

  const agregarServicioASeleccionados = () => {
    if (
      !seleccionados.filter(
        (seleccionado) => seleccionado.id === servicioSeleccionado.id
      ).length
    ) {
      setValue('seleccionados', [...seleccionados, servicioSeleccionado])
      setValue('servicio', '')
    } else {
      setError('servicio', {
        type: 'custom',
        message: 'El servicio ya ha sido seleccionado',
      })
    }
    setServicioSeleccionado(null)
  }

  return (
    <div className="w-full">
      <div className="w-full flex flex-wrap">
        <div className="w-5/12">
          <Controller
            name="plantilla"
            control={control}
            render={() => {
              return (
                <CampoFiltrarEspañol
                  titulo={'Seleccione una plantilla*'}
                  estilosInput={`border-cendiatra`}
                  placeholder={'DESCRIPCIÓN'}
                  valorDelCampoFiltro={plantilla?.filtro ?? ''}
                  informacionFiltrada={filtrarInformacionArray(
                    plantilla?.filtro ?? '',
                    plantillas
                  )}
                  handleChange={(e) => {
                    setValue('plantilla.filtro', e.target.value)
                    if (e.target.value.length === 0) {
                      setValue('plantilla', null)
                    }
                  }}
                  handleOptionChange={(e) => {
                    setValue('plantilla', e)
                  }}
                  tipo="text"
                  desactivarOtroFiltro={false}
                  id={1}
                />
              )
            }}
          />
        </div>
        <div className="w-2/12"></div>
        <div className="w-5/12 flex flex-wrap">
          <CampoFiltrarEspañol
            titulo={'Seleccione uno o varios servicios*'}
            estilosInput={`border-cendiatra`}
            placeholder={'DESCRIPCIÓN'}
            estilosPersonalizados={`w-11/12`}
            valorDelCampoFiltro={servicio || ''}
            informacionFiltrada={filtrarServiciosNoVinculadosAPlantillas(
              filtrarInformacionArray(servicio, servicios)
            )}
            handleChange={(e) => setValue('servicio', e.target.value)}
            tipo="text"
            desactivarOtroFiltro={false}
            handleOptionChange={(e) => {
              setValue('servicio', e.filtro)
              setServicioSeleccionado(e)
              clearErrors()
            }}
            id={1}
          />
          <BotonConImagen
            ancho={'w-1/12 pt-2 pl-2'}
            imagen={addButtonImg}
            textoAlternativo={'Boton Agregar'}
            funcionAEjecutar={agregarServicioASeleccionados}
            desabilitado={!servicioSeleccionado}
          />
          <span className="text-red-500 w-full">
            {errors.servicio ? errors?.servicio?.message : ''}
          </span>
        </div>
      </div>
      <div className="w-full pt-5">
        <TablaDinamica data={seleccionados} columns={columnasTablaServicios} />
      </div>
      <div className="w-full flex justify-center">
        <BotonSimple
          texto={'Asignar'}
          estilosBoton={
            plantilla && seleccionados.length > 0
              ? 'w-28 h-8 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-xl text-14px m-2'
              : 'w-28 h-8 text-white bg-cendiatra-gris-placeholder bg-center bg-cover bg-no-repeat rounded-xl text-14px m-2'
          }
          funcion={() => {
            contextoAplicacion.setModal({
              abierto: true,
              titulo:
                '¿Está seguro de realizar la asignación para el siguiente servicio y plantilla?',
              contenido: (
                <InformacionAsignacionPlantillaAServicios
                  servicios={seleccionados
                    .map((item) => item?.filtro ?? '')
                    .filter((item) => Boolean(item))}
                  plantilla={plantilla?.filtro ?? ''}
                />
              ),
              botones: [
                {
                  nombre: 'No',
                  click: cerrarAlerta,
                },
                {
                  nombre: 'Si',
                  click: () =>
                    asignarServiciosAPlantilla(
                      plantilla.id,
                      seleccionados.map((item) => item.id)
                    ),
                },
              ],
            })
          }}
        />
      </div>
    </div>
  )
}

export default FormularioAsignacionPlantillaAServicios
