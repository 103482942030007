import React, {useEffect, useContext, useState} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import TablaRoles from '../../componentes/Administrador/TablaRoles'
import BotonesModulosRoles from '../../componentes/Botones/BotonesModulosRoles'
import {ContextApplication} from '../../contexto/ApplicationContext'
import {ObtenerRoles} from '../../microservicios/Rol'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {TablaConPaginacionYFiltros} from '../../componentes/Tablas'

const GestionRol = () => {
  const history = useHistory()

  const [listaRoles, setListaRoles] = useState([])

  useEffect(() => {
    ObtenerRoles()
      .then((res) => {
        if (res.data) {
          setListaRoles(
            res.data.map((rol) => ({
              id: rol.id,
              rol: rol.nombre,
            }))
          )
        } else {
          setListaRoles([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const columnasRoles = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
      disableFilters: true,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Rol'} />,
      accessor: 'rol',
      className: 'w-1/2',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Módulos'} />,
      accessor: 'modulos',
      className: 'w-1/2',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-r-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <BotonesModulosRoles
          funcionEditar={() => manejarClickEditar(row.row.original)}
          funcionVer={() => manejarClickVer(row.row.original)}
        />
      ),
    },
  ])

  const manejarClickEditar = (rol) => {
    history.push('/editarrol', {
      idRol: rol.id,
      editable: true,
      nombreRol: rol.rol,
    })
  }

  const manejarClickVer = (rol) => {
    history.push('/editarrol', {
      idRol: rol.id,
      editable: false,
      nombreRol: rol.rol,
    })
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITILO_ADMINISTRADOR_GATO.GESTION_DE_ROL}
    >
      <div className="w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex mb-16 flex-wrap">
        <TablaConPaginacionYFiltros
          columns={columnasRoles}
          data={listaRoles}
          estilosPersonalizados={'w-8/12'}
          estilosTablaDatos={
            'h-637 overflow-scroll border border-cendiatra overflow-x-hidden'
          }
        />
      </div>
    </ContenedorPagina>
  )
}

export default GestionRol
