import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import {ContextApplication, ContextoFormulario} from '../../contexto'
import {
  ROLES_APP,
  estadosExamenesConst,
  nombreBotonesPlantilla,
} from '../../constantes'
import {
  FachadaCambiarAPendienteServicioAtencion,
  FachadaCambiarEstadoServicioAtencion,
} from '../../microservicios'
import {ObtenerServicioPorId} from '../../cache/servicios-cache'
import imagenBotonCerrar from '../../imagenes/Salir.svg'
import {BarraBotones} from '../Botones'
import {CuadroInformacionUsuario, ListaFormularios} from '../MostrarInformacion'
import {BarraEncabezadosPestana, Pestana} from '../Pestanas'
import {TablaHistoriaClinica} from '../Tablas'
import BotonConImagen from '../BotonConImagen'
import {ContenedorDesplegable} from '../../componentes/Contenedores'
import BasePestana from './BasePestana'
import {CeldaConTooltipPersonalizable} from '../Celdas'

const BaseFormulario = ({}) => {
  const contextoFormulario = useContext(ContextoFormulario)

  const contextoAplicacion = useContext(ContextApplication)

  const {accounts} = useMsal()
  const rolUsuario = accounts[0]?.idTokenClaims?.roles[0] ?? ''

  const history = useHistory()

  const {state} = useLocation()

  const botonesBase = useMemo(
    () => [
      ...contextoFormulario.plantilla.botones,
      {nombreBoton: nombreBotonesPlantilla.GUARDADOPARCIAL, estadoBoton: true},
      {nombreBoton: nombreBotonesPlantilla.CERRAREXAMEN, estadoBoton: true},
      {
        nombreBoton: nombreBotonesPlantilla.IMPRIMIREXAMEN,
        estadoBoton: true,
      },
    ],
    [contextoFormulario.plantilla, contextoFormulario.funcionesDefaultBotones]
  )

  const [listaBotones, setListaBotones] = useState([])

  useEffect(() => {
    contextoFormulario.setTotalPaginas(
      contextoFormulario?.plantilla?.pestanas?.length
    )
  }, [contextoFormulario.plantilla?.pestanas])

  useEffect(() => {
    setListaBotones(
      botonesBase
        .filter((boton) => boton.estadoBoton)
        .map((boton) => ({
          nombre: boton.nombreBoton,
          funcion:
            contextoFormulario?.funcionesDefaultBotones[boton.nombreBoton] ??
            null,
          habilitado:
            contextoFormulario?.esVistaPrevia ||
            typeof contextoFormulario.estadoHabilitadoBotones[
              boton.nombreBoton
            ] === undefined
              ? true
              : contextoFormulario.estadoHabilitadoBotones[boton.nombreBoton],
        }))
    )
  }, [contextoFormulario.estadoHabilitadoBotones, botonesBase])

  const cambiarEstadoAtencion = async (servicioAtencionId, ruta) => {
    const servicio = await ObtenerServicioPorId(
      contextoFormulario.examenInicial.servicioId
    )

    if (
      contextoFormulario.examenInicial.servicioAtencionId !==
        state.servicioAtencionId &&
      !contextoFormulario.habilitarEdicion
    ) {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: `Actualmente se encuentra en proceso de atención de ${servicio.Nombre}`,
        contenido: '',
        botones: [
          {
            nombre: 'Continuar',
            click: () =>
              manejarCerrarExamenSinActualizarEstado(
                contextoFormulario.examenInicial.servicioAtencionId,
                '/'
              ),
          },

          {
            nombre: 'Volver a examen',
            click: () => {
              manejarIrAlExamenInicial()
            },
          },
        ],
      })
    } else {
      if (rolUsuario === ROLES_APP.JEFE_SEDE) {
        history.push(ruta)
        return
      }

      if (
        contextoFormulario?.examenEnVista?.estadoExamen !==
          estadosExamenesConst.CERRADO &&
        !contextoFormulario?.examenEnVista?.finalizado
      ) {
        FachadaCambiarAPendienteServicioAtencion(servicioAtencionId)
          .then(() => {
            history.push(ruta)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        FachadaCambiarEstadoServicioAtencion(servicioAtencionId)
          .then(() => {
            history.push(ruta)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const cerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const manejarCerrarExamenSinActualizarEstado = (servicioAtencionId, ruta) => {
    FachadaCambiarEstadoServicioAtencion(servicioAtencionId)
      .then(() => {
        history.push(ruta)
        cerrarModal()
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const manejarIrAlExamenInicial = () => {
    const newState = {
      usuarioId: contextoFormulario.examenInicial?.usuarioId,
      examenes: contextoFormulario.examenInicial?.examenes,
      ordenId: contextoFormulario.examenInicial?.ordenId,
      servicioAtencionId: contextoFormulario.examenInicial?.servicioAtencionId,
      atencionId: contextoFormulario.examenInicial.atencionId,
      servicioId: contextoFormulario.examenInicial?.servicioId,
    }
    history.push('/examen', {...newState})
    cerrarModal()
  }

  return (
    <>
      <div
        className={`flex w-93% h-full justify-center items-start flex-wrap`}
        id="base-formulario-motor"
      >
        <div className="bg-white w-full  rounded rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl">
          <div className="w-full flex justify-end items-center">
            <BotonConImagen
              imagen={imagenBotonCerrar}
              textoAlternativo={'botonCerrar'}
              estilosContenedor={'w-12'}
              estilosImagen={'w-7 h-7'}
              funcionAEjecutar={() =>
                cambiarEstadoAtencion(state.servicioAtencionId, '/')
              }
            />
          </div>
          <div className="w-full flex justify-center items-center flex-wrap">
            <CuadroInformacionUsuario
              estilosContenedor={
                'w-11/12 h-300 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 shadow-2xl '
              }
              titulo={'DATOS PACIENTE'}
              tituloImagen={'Fecha y hora de atención'}
              descripcionPrimeraLinea={
                contextoFormulario?.datosAtencion?.fechaActual ?? ''
              }
              descripcionSegundaLinea={
                contextoFormulario?.datosAtencion?.horaActual ?? ''
              }
              datos={contextoFormulario.informacionUsuario.datosUsuario}
              urlFoto={contextoFormulario.informacionUsuario.urlFoto}
            />
            <div className="text-cendiatra w-10/12 font-bold uppercase mt-10 -mb-5">
              <CeldaConTooltipPersonalizable
                texto={
                  contextoFormulario?.examenEnVista?.alias ??
                  contextoFormulario?.examenEnVista?.nombreServicio
                }
                estilosPersonalizados={
                  'text-14px bg-cendiatra text-white -mt-14 w-auto'
                }
                anchoMaximo={'70%'}
              />
            </div>
            {!contextoFormulario.plantilla.formulariosFinalPagina ? (
              <ListaFormularios
                estilosContenedor={
                  'w-11/12 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 my-5'
                }
                datos={[]}
              />
            ) : null}

            <div
              className={`w-11/12 flex justify-center items-center mt-10 flex-wrap mx-43px`}
            >
              <BarraEncabezadosPestana
                titulos={contextoFormulario.plantilla.pestanas.map(
                  (x) => x?.tituloPestana?.toUpperCase() ?? ''
                )}
                contexto={contextoFormulario}
                deshabilitarValidaciones={true}
              />
              {contextoFormulario.plantilla.pestanas.map((pestana, key) => (
                <Pestana
                  contexto={contextoFormulario}
                  numeroPagina={pestana.numeroPestana}
                  deshabilitarBotonGuardar={true}
                  key={key}
                  mostrarBotonesEspecificos={false}
                >
                  <BasePestana numeroPestana={pestana.numeroPestana} />
                </Pestana>
              ))}
            </div>
            <BarraBotones
              estilosContenedor={`w-11/12 py-2 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 my-5 ${
                !contextoFormulario.habilitarEdicion &&
                !state?.habilitarBotonesModificarAtencion
                  ? 'pointer-events-none'
                  : ''
              } `}
              listaBotones={listaBotones}
              listaBotonesLaterales={[]}
            />
            <div className="w-11/12 flex justify-center items-center flex-wrap ">
              {contextoFormulario.plantilla.formulariosFinalPagina &&
              contextoFormulario?.formularios?.length ? (
                <ListaFormularios
                  estilosContenedor={
                    'w-full flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 my-5'
                  }
                  datos={contextoFormulario.formularios}
                  habilitarTodosLosFormularios={true}
                />
              ) : null}

              <ContenedorDesplegable
                titulo={'HISTORIA CLINICA PACIENTE'}
                posicionTitulo={'justify-center'}
                expandidoInicial={false}
              >
                <TablaHistoriaClinica
                  data={contextoFormulario?.historiaClinicaUsuario ?? []}
                />
              </ContenedorDesplegable>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BaseFormulario
