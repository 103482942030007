export const valoresPredeterminadosTablaAudiometria = [
  {
    nombre: 'OD',
    F250: null,
    F250SR: false,
    F500: null,
    F500SR: false,
    F1000: null,
    F1000SR: false,
    F2000: null,
    F2000SR: false,
    F3000: null,
    F3000SR: false,
    F4000: null,
    F4000SR: false,
    F6000: null,
    F6000SR: false,
    F8000: null,
    F8000SR: false,
  },
  {
    nombre: 'OI',
    F250: null,
    F250SR: false,
    F500: null,
    F500SR: false,
    F1000: null,
    F1000SR: false,
    F2000: null,
    F2000SR: false,
    F3000: null,
    F3000SR: false,
    F4000: null,
    F4000SR: false,
    F6000: null,
    F6000SR: false,
    F8000: null,
    F8000SR: false,
  },
]
