import Home from './Home.js'
import React, {useEffect, useState} from 'react'
import {BrowserRouter, Route, Redirect} from 'react-router-dom'
import {Provider} from './contexto/ApplicationContext.js'
import Login from './componentes/Login.jsx'
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react'
import {NavegacionContexto} from './contexto/ContextoNavegacion.js'
import AxiosInterceptor from './interceptor/AxiosInterceptor.js'
import {ModalesConContexto} from './contexto/ContextoModales.js'
import {RUTAS_APP} from './constantes/index.js'
import {GrowthBook} from '@growthbook/growthbook-react'
import {GrowthBookProvider} from '@growthbook/growthbook-react'
import {obtenerFeatureFlagConfig} from './config.js'

function App() {
  const [growthbook, setGrowthbook] = useState(null)

  useEffect(() => {
    obtenerFeatureFlagConfig()
      .then((config) => {
        const devMode =
          config.DevMode === 'true' || config.DevMode == true ? true : false
        const gb = new GrowthBook({
          apiHost: config.Host,
          clientKey: config.Key,
          enableDevMode: devMode,
          trackingCallback: (experiment, result) => {
            console.log('Viewed Experiment', {
              experimentId: experiment.key,
              variationId: result.key,
            })
          },
        })

        gb.init({streaming: true})
        setGrowthbook(gb)
      })
      .catch((error) => {
        console.error('Error iniciando Growth:', error)
      })
  }, [])

  // useMsalAuthentication(InteractionType.Redirect)
  return (
    <>
      <GrowthBookProvider growthbook={growthbook}>
        <AuthenticatedTemplate>
          <Provider>
            <ModalesConContexto>
              <NavegacionContexto>
                <AxiosInterceptor>
                  <Home />
                </AxiosInterceptor>
              </NavegacionContexto>
            </ModalesConContexto>
          </Provider>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <BrowserRouter>
            <Login />
            <Route>
              <Redirect to={RUTAS_APP.HOME_APP} />
            </Route>
          </BrowserRouter>
        </UnauthenticatedTemplate>
      </GrowthBookProvider>
    </>
  )
}

export default App
