import {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {BotonSimple} from '../Botones'
import {Input} from '../inputs'

export default function FiltroDatosBasicosPaciente({
  funcionBuscarInformacion,
  funcionLimpiarInformacion,
}) {
  const {control, getValues, reset} = useForm({
    mode: 'onChange',
    defaultValues: {
      numeroDocumento: '',
      nombres: '',
      apellidos: '',
    },
  })

  const buscarInformacionAlPresionarEnter = (evt) => {
    if (evt.key === 'Enter') {
      if (Object.values(getValues()).some((v) => v.length > 0)) {
        funcionBuscarInformacion(getValues())
      }
    }
  }

  useEffect(() => {
    const inputs = document.querySelectorAll('div div input')

    for (const input of inputs) {
      input.addEventListener('keypress', buscarInformacionAlPresionarEnter)
    }

    return () => {
      for (const input of inputs) {
        input.removeEventListener('keypress', buscarInformacionAlPresionarEnter)
      }
    }
  }, [])

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-9/12 flex flex-wrap space-x-5">
        <Controller
          name="numeroDocumento"
          control={control}
          render={({field: {value, onChange}}) => {
            return (
              <Input
                titulo={'No. de documento'}
                placeholder={'Descripción'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm w-full'
                }
                estilosContenedor={'w-3/12'}
                valor={value}
                onChange={onChange}
                tipo={'text'}
              />
            )
          }}
        />
        <Controller
          name="nombres"
          control={control}
          render={({field: {value, onChange}}) => {
            return (
              <Input
                titulo={'Nombres'}
                placeholder={'Descripción'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                estilosContenedor={'w-4/12'}
                valor={value}
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          name="apellidos"
          control={control}
          render={({field: {value, onChange}}) => {
            return (
              <Input
                titulo={'Apellidos'}
                placeholder={'Descripción'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                estilosContenedor={'w-4/12'}
                valor={value}
                onChange={onChange}
              />
            )
          }}
        />
      </div>
      <div className="w-3/12 flex flex-wrap">
        <BotonSimple
          texto={'Buscar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 bg-btnBg`}
          funcion={() => funcionBuscarInformacion(getValues())}
        />
        <BotonSimple
          texto={'Limpiar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 ml-2 bg-btnBg`}
          funcion={() => {
            reset()
            funcionLimpiarInformacion()
          }}
        />
      </div>
    </div>
  )
}
