import {regexTextoCaracteresBasicosConOtrosCaracteresMas} from '../../../../constantes'
import {InputRegex} from '../../../inputs'

export default function Numerico({atributos, actualizarAtributo}) {
  return (
    <div className="w-full flex flex-wrap py-5">
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2">
        <label className="flex flex-wrap w-full align-middle content-center">
          UNIDAD DE MEDIDA
        </label>
      </div>
      <InputRegex
        ancho={'w-1/2 flex'}
        cambioValor={(e) => {
          actualizarAtributo('unidadMedida', e?.toUpperCase() ?? '')
        }}
        estilosContenedor={'w-full flex'}
        estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        tipo={'text'}
        placeholder={'DESCRIPCIÓN'}
        valor={atributos?.unidadMedida}
        longitudMaxima={50}
        regex={regexTextoCaracteresBasicosConOtrosCaracteresMas}
        mensajeRegex="Se permiten estos caracteres: + ( ) [ ] , / - . < > : %"
      />
    </div>
  )
}
