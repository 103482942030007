import React from 'react'
import moment from 'moment'
import {useTable, useFilters} from 'react-table'
import FiltroTexto from './FiltroTexto'

const TablaAgenda = ({ocultarTabla, data, columns}) => {
  const filterTypes = React.useMemo(
    () => ({
      dobleTexto: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue.Fila1)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase()) ||
                String(rowValue.Fila2)
                  .toLowerCase()
                  .includes(String(filterValue).toLowerCase())
            : true
        })
      },
      fecha: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? moment(rowValue).format('DD/MM/YYYY') ==
                moment(filterValue).format('DD/MM/YYYY')
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: FiltroTexto,
    }),
    []
  )
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, rows} =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: {pageIndex: 0},
        autoResetFilters: false,
      },
      useFilters
    )

  return (
    <div className="flex w-full my-5 h-full">
      <table
        className="flex flex-wrap w-full border-separate"
        style={{height: '100%'}}
        {...getTableProps()}
      >
        <thead
          className="flex w-full flex-wrap text-14px"
          style={{width: 'calc(100% - 16px)'}}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              className="flex w-full flex-wrap text-center"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((header) => (
                <th
                  {...header.getHeaderProps({
                    className: `${header.className} ${header.headerStyle} bg-cendiatra-verde-7 flex flex-wrap justify-center items-center content-center`,
                  })}
                >
                  {header.canFilter ? (
                    <div
                      className="w-full flex-wrap flex bg-cendiatra-verde-5 rounded-md text-14px h-auto mr-1"
                      style={{paddingTop: '5px'}}
                    >
                      <div className="w-full flex justify-center items-center  rounded-md text-14px">
                        {header.render('Header')}
                      </div>
                      <div
                        className="w-full flex justify-evenly items-center mb-2 text-13px  "
                        style={{margin: ''}}
                      >
                        {header.render('Filter')}
                      </div>
                    </div>
                  ) : (
                    header.render('Header')
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className={`${
            ocultarTabla ? 'hidden' : ''
          } flex w-full flex-wrap h-550 overflow-scroll overflow-x-hidden text-13px content-start`}
          {...getTableBodyProps()}
        >
          {rows.map((row, index) => {
            {
              prepareRow(row)
              return (
                <>
                  <tr
                    className="flex w-full flex-wrap text-center justify-center items-center border border-cendiatra-verde-4 my-2 rounded-lg "
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={{backgroundColor: 'white'}}
                          {...cell.getCellProps({
                            className: `${cell.column.className} ${cell.column.cellStyles} `,
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                </>
              )
            }
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaAgenda
