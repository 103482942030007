import React, {useEffect, useContext, useCallback} from 'react'
import {NavLink} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import {ContextoPlantilla, ContextApplication} from '../../contexto'
import Pestana from '../../componentes/Pestanas/Pestana'
import FormularioNombrarPlantilla from '../../componentes/Administrador/formularios/FormularioNombrarPlantilla'
import PlantillaEstructura from '../../componentes/Administrador/Plantilla/PlantillaEstructura'
import {ObtenerTiposCampo} from '../../cache/servicios-cache/TipoCampo'
import {
  CrearPlantillaBD,
  ObtenerCamposPreCarga,
} from '../../microservicios/Plantilla'
import {useHistory} from 'react-router-dom'
import PreVisualizacion from '../../componentes/Administrador/Plantilla/PreVisualizacion'
import ParametrosCertificado from '../../componentes/Administrador/Plantilla/ParametrosCertificado'
import ParametrosHistoria from '../../componentes/Administrador/Plantilla/ParametrosHistoria'
import {AgregarFuentesDeDatosACache} from '../../cache/servicios-cache/FuentesDeDatos'
import {nombreBotonesPlantilla} from '../../constantes/plantillaBotones'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ARRAY_BOTONES_SELECCION} from '../../constantes/motorPlantilla'

const CrearPlantilla = () => {
  const contextoPlantilla = useContext(ContextoPlantilla)
  const contextoAplicacion = useContext(ContextApplication)

  const history = useHistory()

  const obtenerInformacionInicial = useCallback(async () => {
    contextoPlantilla.setTiposCampo(await ObtenerTiposCampo())
    const preCarga =
      (await ObtenerCamposPreCarga())?.data?.map((item) => ({
        ...item,
        atributos: JSON.parse(item.atributos),
      })) ?? []
    contextoPlantilla.setCamposPreCarga(preCarga)
    await AgregarFuentesDeDatosACache()
  }, [])

  useEffect(() => {
    obtenerInformacionInicial()
    contextoPlantilla.setPlantilla({
      formulariosFinalPagina: true,
      imprimeCertificado: false,
      estructuraId: -1,
      incluirExamenes: false,
      incluirRecomendaciones: false,
      consentimientoCertificado: '',
      codigoCertificado: '',
      fechaCertificado: '',
      versionCertificado: '',
      consentimientoHistoria:
        'Acepto las anteriores valoraciones Médicas realizadas, hago constar que la información suministrada sobre mi condición de salud es verdadera, y me comprometo a cumplir las recomendaciones y las restricciones a que haya lugar. Cualquier omisión o falsedad será causa justa de finalización de contrato. Artículo 62 CST. La firma de este documento no significa aceptación de los conceptos médicos expresados y de su contenido. Sirve adicionalmente para constatar quien presento la valoración.',
      codigoHistoria: '',
      fechaHistoria: '',
      versionHistoria: '',
      nombrePlantilla: '',
      pestanas: [
        {
          tituloPestana: '',
          numeroPestana: 1,
          secciones: [
            {
              nombreSeccion: '',
              numeroSeccion: 1,
              campos: [
                {
                  tituloCampo: '',
                  numeroCampo: 1,
                  tipoCampoId: -1,
                  requerido: false,
                  replicable: false,
                  mostrarEnCertificado: false,
                  atributos: {},
                },
              ],
            },
          ],
        },
      ],
      botones: ARRAY_BOTONES_SELECCION,
    })
  }, [obtenerInformacionInicial])

  const listaPestanas = [
    'Nombrar plantilla',
    'Definir estructura',
    'Parámetros para certificado',
    'Parámetros para historia',
    'Vista previa',
  ]

  const manejarGuardar = async () => {
    let plantilla = {
      ...contextoPlantilla.plantilla,
      nombrePlantilla: contextoPlantilla.plantilla.nombrePlantilla.trim(),
      pestanas: contextoPlantilla.plantilla.pestanas.map((pestana) => {
        return {
          ...pestana,
          tituloPestana: pestana.tituloPestana.trim(),
          secciones: pestana.secciones.map((seccion) => {
            return {
              ...seccion,
              nombreSeccion: seccion.nombreSeccion.trim(),
              campos: seccion.campos.map((campo) => {
                return {
                  ...campo,
                  atributos: JSON.stringify(campo.atributos),
                  tituloCampo: campo.tituloCampo.trim(),
                }
              }),
            }
          }),
        }
      }),
    }
    await CrearPlantillaBD(plantilla)
      .then((res) => {
        if (res.status === 200) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'PLANTILLA CREADA',
            contenido: <></>,
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarCerrarModalConfirmacion,
              },
            ],
          })
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITILO_ADMINISTRADOR_GATO.CREAR_PLANTILLA}
    >
      <div
        className={`w-full flex justify-center items-center mt-10 flex-wrap mx-43px`}
      >
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoPlantilla}
        />
        <Pestana contexto={contextoPlantilla} numeroPagina={1}>
          <FormularioNombrarPlantilla numeroPagina={1} />
        </Pestana>
        <Pestana contexto={contextoPlantilla} numeroPagina={2}>
          <PlantillaEstructura numeroPagina={2} />
        </Pestana>
        <Pestana contexto={contextoPlantilla} numeroPagina={3}>
          <ParametrosCertificado numeroPagina={3} />
        </Pestana>
        <Pestana contexto={contextoPlantilla} numeroPagina={4}>
          <ParametrosHistoria numeroPagina={4} />
        </Pestana>
        <Pestana
          contexto={contextoPlantilla}
          numeroPagina={5}
          clickGuardar={manejarGuardar}
        >
          <PreVisualizacion numeroPagina={5} />
        </Pestana>
      </div>
    </ContenedorPagina>
  )
}

export default CrearPlantilla
