import {FORMATO_FECHA_INGLES} from '../constantes'

const moment = require('moment')

export function obtenerFechaMinima(annios) {
  const hoy = moment()
  const fechaMinima = hoy.subtract(annios, 'years')
  const fechaMinimaFormateada = fechaMinima.format(FORMATO_FECHA_INGLES)
  return fechaMinimaFormateada
}
