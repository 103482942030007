import React, {useState, useEffect, useContext} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import BotonesModulosRoles from '../../componentes/Botones/BotonesModulosRoles'
import TablaGestionFirma from '../../componentes/Administrador/TablaGestionFirma'
import FiltroListaSeleccionar from '../../componentes/Administrador/FiltroListaSeleccionar'
import {FachadaObtenerMedicos} from '../../microservicios/Fachada'
import {ContextApplication} from '../../contexto'
import moment from 'moment'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {TablaConPaginacionYFiltros} from '../../componentes/Tablas'
import {EncabezadoFondo} from '../../componentes/Tablas'

const GestionFirma = () => {
  const [licenciasVencidas, setLicenciasVencidas] = useState(0)
  const [usuariosSinFirmaYSello, setUsuariosSinFirmaYSello] = useState(0)
  const [listaFirmas, setListaFirmas] = useState([])

  const history = useHistory()

  const contextoApp = useContext(ContextApplication)

  useEffect(() => {
    obtenerMedicos()
  }, [])

  const columnasFirmas = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Nombre de usuario'} paddingFondoClaro={''} />
      ),
      accessor: 'nombre',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-l-lg pl-1',
      disableFilters: false,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: ({row: {original}}) => original?.nombre?.toUpperCase() ?? '',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Correo de Login'} paddingFondoClaro={''} />
      ),
      accessor: 'correo',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20 pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row: {original}}) => original?.correo?.toUpperCase() ?? '',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Rol'} paddingFondoClaro={''} />,
      accessor: 'rol',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: ({row: {original}}) => original?.rol?.toUpperCase() ?? '',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Fecha vencimiento licencia'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'fechaVencimiento',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <span
          className={
            row.row.original.licenciaVencida
              ? 'text-red-600'
              : '' || row.row.original.licenciaProximaVencer
              ? 'text-cendiatra-semaforo-naranja'
              : ''
          }
        >
          {' '}
          {row.row.original.fechaVencimiento !== null &&
            moment(row.row.original.fechaVencimiento).format('YYYY-MM-DD')}
          <span />
        </span>
      ),
    },
    {
      Header: () => <EncabezadoFondo titulo={'Licencia Vencida'} />,
      accessor: 'licenciaVencida',
      disableFilters: false,
      filter: 'exactText',
      Filter: FiltroListaSeleccionar,
      Cell: (row) => (
        <span
          className={row.row.original.licenciaVencida ? 'text-red-600' : ''}
        >
          {row.row.original.licenciaVencida ? 'SI' : 'NO'}
        </span>
      ),
      className: 'w-1/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Firma y Sello'} paddingFondoClaro={''} />
      ),
      accessor: 'tieneFirmaYSello',
      filter: 'exactText',
      Filter: FiltroListaSeleccionar,
      Cell: (row) => (
        <span
          className={!row.row.original.tieneFirmaYSello ? 'text-red-600' : ''}
        >
          {row.row.original.tieneFirmaYSello ? 'SI' : 'NO'}
        </span>
      ),
      className: 'w-1/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Módulos'} paddingFondoClaro={''} />
      ),
      accessor: 'modulos',
      disableFilters: true,
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg pl-1',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <BotonesModulosRoles
          funcionEditar={() => manejarClickEditar(row.row.original.id)}
          funcionVer={() => manejarClickVer(row.row.original.id)}
        />
      ),
    },
  ])

  const manejarClickEditar = (id) => {
    history.push('/editarfirma', {id: id, editable: true})
  }

  const manejarClickVer = (id) => {
    history.push('/editarfirma', {id: id, editable: false})
  }

  const obtenerMedicos = async () => {
    await FachadaObtenerMedicos()
      .then(async (res) => {
        setLicenciasVencidas(res.data.totalLicenciasVencidas)
        setUsuariosSinFirmaYSello(res.data.totalSinFirmaYSello)
        setListaFirmas(res.data.listado)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.TITILO_ADMINISTRADOR_GATO
          .GESTION_FIRMA_HISTORIA_CLINICA_USUARIO_MEDICO
      }
      estilosContenedor={'w-11/12'}
    >
      <div className="flex flex-row ml-auto mr-22% justify-end gap-4 w-1/2">
        <p className="flex flex-row text-center text-cendiatra text-sm w-130px pt-12">
          <span className="h-3 w-4 pr-2 bg-cendiatra-semaforo-naranja rounded-full mt-1"></span>{' '}
          Licencias próximas a vencer
        </p>
        <div className="flex w-130px flex-col  text-center text-cendiatra text-md font-bold">
          <span> LICENCIAS VENCIDAS</span>
          <input
            className={
              licenciasVencidas > 0
                ? 'text-red-600 border-cendiatra rounded-lg border h-10 text-center text-2xl font-bold'
                : 'text-cendiatra border-cendiatra rounded-lg border h-10 text-center text-2xl font-bold'
            }
            type="text"
            disabled={true}
            value={licenciasVencidas}
          />
        </div>
        <div className="flex flex-col w-130px  text-center text-cendiatra text-md font-bold">
          <span> USUARIOS SIN FIRMA Y SELLO</span>
          <input
            className={
              usuariosSinFirmaYSello > 0
                ? 'text-red-600 border-cendiatra rounded-lg border h-10 text-center text-2xl font-bold'
                : 'text-cendiatra border-cendiatra rounded-lg border h-10 text-center text-2xl font-bold'
            }
            type="text"
            disabled={true}
            value={usuariosSinFirmaYSello}
          />
        </div>
      </div>

      <div className="w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex mb-16 mt-10 flex-wrap">
        <TablaConPaginacionYFiltros
          columns={columnasFirmas}
          data={listaFirmas}
          paginacion={true}
          estilosTablaDatos={'border border-cendiatra rounded-lg'}
        />
      </div>
    </ContenedorPagina>
  )
}

export default GestionFirma
