import React, {useContext, useState} from 'react'
import iconoClip from '../../imagenes/tesorero/Clip.svg'
import Excelok from '../../imagenes/tesorero/DocExcel.svg'
import {BotonSimple} from '../Botones'
import {ContextApplication} from '../../contexto'
import {useModal} from '../../hooks/useModal'
import {CargarArchivoPagos} from '../../microservicios'

const CargaArchivo = () => {
  const contextoApp = useContext(ContextApplication)
  const [cerrarModal] = useModal()

  const [nombreArchivo, setNombreArchivo] = useState('')
  const [archivo, setArchivo] = useState(null)

  const excelFile =
    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  const cargarArchivoInput = (e) => {
    const archivo = e.target.files[0]

    var reader = new FileReader()
    reader.readAsDataURL(archivo)

    reader.onload = async function (e) {
      let formData = new FormData()

      formData.append('Archivo', archivo, archivo.name)
      formData.append('NombreArchivo', archivo.name)

      setNombreArchivo(archivo.name)
      setArchivo(formData)
    }
  }

  const cargarArchivo = async () => {
    try {
      let respuesta = await CargarArchivoPagos(archivo)

      if (respuesta.status === 200) {
        modalExito()
      }
    } catch (error) {
      if (typeof error.response.data !== 'string') {
        cerrarModal()
        modalErrores(error?.response?.data)
      } else {
        console.log(error)
      }
    }
  }

  const modalExito = () => {
    contextoApp.setModal({
      abierto: true,
      titulo: (
        <span className="font-bold">
          <p className="text-cendiatra text-xl mb-10">Archivo cargado</p>
          <p className="text-cendiatra">
            Se ha cargado correctamente el archivo
          </p>
        </span>
      ),
      contenido: '',
      botones: [
        {
          nombre: 'Cerrar',
          click: () => {
            limpiarArchivo()
          },
        },
      ],
    })
  }

  const modalErrores = (error) => {
    contextoApp.setModal({
      abierto: true,
      titulo: (
        <span className="font-bold">
          <p className="text-red-500 text-xl mb-10 2xl">
            Hay errores en el formato del archivo
          </p>
          <p className="text-cendiatra normal-case">
            Por favor valide los datos ingresados en:
          </p>
        </span>
      ),
      contenido: error.map((error, index) => (
        <div className="w-full mb-10 text-cendiatra" key={index}>
          <span className="font-bold normal-case">Hoja:</span> {error.hoja}
          {error.errores.map((errorHoja, index) => (
            <div key={index}>
              <p className="text-cendiatra normal-case">
                <span className="font-bold">Columna:</span> {errorHoja.campo}{' '}
                <span className="font-bold"> Fila:</span> {errorHoja.fila}
              </p>
            </div>
          ))}
        </div>
      )),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            limpiarArchivo()
          },
        },
      ],
    })
  }

  const limpiarArchivo = () => {
    setNombreArchivo('')
    setArchivo(null)
    cerrarModal()
  }

  const modalConfirmarEnvio = () => {
    contextoApp.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <span className="font-bold normal-case text-2xl mb-10">
          ¿Está seguro de la información cargada?
        </span>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            cerrarModal()
          },
        },
        {
          nombre: 'Cargar',
          click: () => {
            cargarArchivo()
          },
        },
      ],
    })
  }

  return (
    <div className="w-full flex justify-evenly items-center text-cendiatra flex-col">
      <div className="w-64 mx-auto text-center border border-gray-300 rounded-lg p-5">
        <p className="w-full flex flex-col font-bold">CARGAR ARCHIVO</p>

        <div className="w-full flex justify-center border border-gray-300 rounded-lg p-3 mt-3 relative">
          {!!nombreArchivo ? (
            <span
              onClick={limpiarArchivo}
              className="text-red-500 border border-red-500 rounded-full w-1 h-1 p-3 flex justify-center items-center cursor-pointer absolute top-0 right-0 mt-2 mr-2 z-10"
            >
              X
            </span>
          ) : null}

          <label
            className="flex flex-col items-center cursor-pointer text-center text-gray-500"
            htmlFor="archivo"
          >
            {!nombreArchivo ? (
              <>
                <span> Seleccionar archivo</span>
                <img className="mt-3" src={iconoClip} alt="clip" width={20} />
              </>
            ) : (
              <>
                <img className="mt-3" src={Excelok} alt="excel" width={40} />
                <span>{nombreArchivo}</span>
              </>
            )}
          </label>

          <input
            className="hidden"
            id="archivo"
            type="file"
            accept={excelFile}
            onChange={(e) => cargarArchivoInput(e)}
            onClick={(e) => (e.target.value = null)}
          />
        </div>
      </div>
      <BotonSimple
        texto={'Continuar'}
        estilosBoton={`w-28 h-10 gap-2 flex items-center text-white bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2 justify-center mt-3
          ${
            nombreArchivo !== '' ? 'bg-btnBg' : 'bg-gray-300 cursor-not-allowed'
          }
          `}
        funcion={() => modalConfirmarEnvio()}
        deshabilitado={!!!nombreArchivo}
      />
    </div>
  )
}

export default CargaArchivo
