import {Controller, useForm} from 'react-hook-form'
import {useEffect, useState, useContext} from 'react'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {Input, ListaDesplegable} from '../inputs'
import {BotonOpcion, BotonSimple} from '../Botones'
import {ComponenteAlerta} from '../Visuales'
import {
  ObtenerEmpresas,
  ObtenerUsuarioPorNombreUsuarioB2c,
  ObtenerEmpresasPorIds,
  ObtenerUsuariosPorCorreo,
  ObtenerTodasCiudades,
} from '../../cache/servicios-cache'
import {regexCorreoElectronico, regexNombreUsuario} from '../../constantes'
import {ROLES_PORTAL_CLIENTE} from '../../constantes'
import {ContextApplication} from '../../contexto'
import {useLocation} from 'react-router-dom'

const caracteresNoPermitidosB2c = /[/&%$#"=)(¿¡?*~{}`´^~;,'@]/
const caracteresNoPermitidosMail = /[/&%$#"=)(¿¡?*~{}`´^~;,']/

function FormularioUsuario({
  funcionGuardar,
  datosUsuario = {filtros: {}, informacion: {}},
  creacion = false,
}) {
  const [empresas, setEmpresas] = useState([])

  const [ciudades, setCiudades] = useState({})

  const [cargaInicial, setCargaInicial] = useState(false)

  const contextoAplicacion = useContext(ContextApplication)

  const location = useLocation()
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: {isValid, errors},
    getValues,
    reset,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      filtros: {
        empresa: '',
      },
      formulario: {
        empresaId: null,
        rol: null,
        cargo: null,
        nombres: null,
        correoPersonal: null,
        userPrincipalName: null,
        activo: true,
      },
    },
    reValidateMode: 'onChange',
  })
  const [b2cReglas, setB2CReglas] = useState({})
  const [correoExistente, setCorreoExistente] = useState(false)

  const eventosValidacion = ['pattern', 'maxLength', 'validate']

  useEffect(() => {
    const obtenerInformacionCiudades = async () => {
      try {
        const informacionCiudades = await ObtenerTodasCiudades()
        setCiudades(
          informacionCiudades?.reduce((acc, ciudad) => {
            if (!acc[ciudad.Id]) acc[ciudad.Id] = ciudad.Nombre

            return acc
          }, {}) ?? {}
        )
      } catch (error) {
        console.log(error)
      }
    }

    obtenerInformacionCiudades()
  }, [])

  useEffect(() => {
    if (Object.values(datosUsuario.informacion).length) {
      reset({
        filtros: datosUsuario.filtros,
        formulario: datosUsuario.informacion,
      })
    }
  }, [datosUsuario])

  const precarga = async () => {
    const listaEmpresas = await ObtenerEmpresas()

    setEmpresas(
      listaEmpresas?.map((empresa) => ({
        id: empresa.Id,
        filtro: `${empresa?.Nit} - ${empresa.Nombre}`,
      })) ?? []
    )
  }

  useEffect(() => {
    precarga()
    setTimeout(() => trigger(), 1000)
  }, [])

  useEffect(() => {
    const subscripcion = watch(() => trigger())

    return () => subscripcion.unsubscribe()
  }, [watch])

  useEffect(() => {
    const pattern = {
      required: 'Usuario es un campo requerido',
      pattern: {
        value: !creacion ? regexCorreoElectronico : regexNombreUsuario,
        message: !creacion
          ? 'Descripción correcta: ejemplo@ejemplo.com'
          : 'Descripción correcta: ejemplo ó ejemplo.ejemplo',
      },
    }
    if (creacion) {
      setB2CReglas({
        ...pattern,
        maxLength: {
          value: 32,
          message: 'Excedió el límite de 32 caracteres',
        },
      })
    } else {
      setB2CReglas(pattern)
    }
  }, [creacion])

  const contieneCaracteresNoPermitidosMail = !caracteresNoPermitidosMail.test(
    watch('formulario.correoPersonal') ?? ''
  )

  const contieneCaracteresNoPermitidosB2c = !caracteresNoPermitidosB2c.test(
    watch('formulario.userPrincipalName') ?? ''
  )

  useEffect(() => {
    if (
      contieneCaracteresNoPermitidosMail &&
      watch('formulario.correoPersonal')?.length
    ) {
      setError('formulario.correoPersonal', {type: 'required'})
    }
  }, [contieneCaracteresNoPermitidosMail])

  useEffect(() => {
    if (
      contieneCaracteresNoPermitidosB2c &&
      watch('formulario.userPrincipalName')?.length &&
      !datosUsuario?.informacion?.guid
    ) {
      setError('formulario.userPrincipalName', {type: 'required'})
    }
  }, [contieneCaracteresNoPermitidosB2c])

  const cerrarModal = () =>
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })

  const validarNombreUsuarioB2c = async () => {
    let usuarioDisponible = true
    try {
      const usuarioEnDb = await ObtenerUsuarioPorNombreUsuarioB2c(
        watch('formulario.userPrincipalName')
      )

      if (usuarioEnDb && creacion) {
        usuarioDisponible = false
      }

      if (
        usuarioEnDb &&
        !creacion &&
        usuarioEnDb?.Guid !== datosUsuario?.informacion?.guid
      ) {
        usuarioDisponible = false
      }
    } catch (error) {
      usuarioDisponible = false
    }

    return usuarioDisponible
  }

  const validarCorreoElectronico = async () => {
    if (!watch('formulario.correoPersonal')) return

    try {
      const usuariosCorreos = await ObtenerUsuariosPorCorreo(
        watch('formulario.correoPersonal')
      )

      if (!usuariosCorreos.length) return

      const empresasId = usuariosCorreos.map((usuario) => usuario.EmpresaId)
      const informacionEmpresas = await ObtenerEmpresasPorIds(empresasId)

      const infoEmpresaConCiudad =
        informacionEmpresas?.map((empresa) => {
          return {
            ciudad: ciudades[empresa.CiudadId] ?? '',
            nit: empresa.Nit,
            nombre: empresa.Nombre,
          }
        }) ?? []

      contextoAplicacion.setModal({
        abierto: true,
        titulo: informacionEmpresas?.length
          ? 'EL CORREO ELECTRÓNICO ESTÁ EN USO PARA:'
          : 'EL CORREO ELECTRÓNICO ESTÁ EN USO',
        contenido: (
          <div className="w-full flex flex-col gap-2">
            {informacionEmpresas.length ? (
              <div className="h-36 overflow-y-auto">
                {infoEmpresaConCiudad.map((empresa, key) => {
                  return (
                    <p
                      className="text-cendiatra-gris-1 py-2 border-b"
                      key={key}
                    >
                      {empresa.ciudad ? `${empresa.ciudad} /` : ''}{' '}
                      {empresa.nit} - {empresa.nombre}
                    </p>
                  )
                })}
              </div>
            ) : null}
            <p className="w-full">
              <p className="normal-case">
                ¿Desea asignar este correo a una nueva empresa para la creación
                y notificación del usuario?
              </p>
            </p>
          </div>
        ),
        botones: [
          {
            nombre: 'Si',
            click: () => {
              cerrarModal()
            },
          },
          {
            nombre: 'No',
            click: () => {
              setValue('formulario.correoPersonal', '')
              trigger('formulario.correoPersonal')
              cerrarModal()
            },
          },
        ],
        removerEspacios: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (watch('formulario.correoPersonal')?.length < 5 || !cargaInicial) return

    const puedeValidarCorreo = async () => {
      if (await trigger('formulario.correoPersonal')) validarCorreoElectronico()
    }

    puedeValidarCorreo()
  }, [watch('formulario.correoPersonal')])

  return (
    <div className="w-ful flex flex-wrap">
      <div className="w-full">
        <Controller
          name="formulario.empresaId"
          control={control}
          rules={{required: true}}
          render={({fieldState: {error}}) => {
            return (
              <CampoFiltrarEspañol
                titulo={'Empresa (Nit)*'}
                informacionFiltrada={
                  watch('filtros.empresa')?.length >= 3
                    ? empresas.filter((emp) =>
                        emp?.filtro
                          ?.toUpperCase()
                          ?.includes(watch('filtros.empresa').toUpperCase())
                      )
                    : []
                }
                valorDelCampoFiltro={watch('filtros.empresa')}
                handleChange={(evt) => {
                  setValue('filtros.empresa', evt.currentTarget.value)
                  setValue('formulario.empresaId', evt?.id ?? '')
                }}
                handleOptionChange={(evt) => {
                  setValue('formulario.empresaId', evt?.id ?? '')
                  setValue('filtros.empresa', evt?.filtro ?? '')
                }}
                estilosInput={`text-cendiatra-gris-3  rounded-lg focus:outline-none  focus:z-10 sm:text-sm appearance-none rounded w-full  p-1.5 border ${
                  !error?.type ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                }`}
                tipo={'text'}
                desactivarOtroFiltro={false}
              />
            )
          }}
        />
      </div>
      <div className="w-6/12">
        <Controller
          name="formulario.rol"
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}, fieldState: {error}}) => {
            return (
              <ListaDesplegable
                titulo={'Rol'}
                opciones={ROLES_PORTAL_CLIENTE.map((rol) => ({
                  Id: rol.id,
                  Nombre: rol.id,
                }))}
                estilosLista={`text-cendiatra-gris-3  rounded-lg focus:outline-none focus:z-10 sm:text-sm appearance-none rounded w-full  p-1.5 border ${
                  !error?.type ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                }`}
                onChange={onChange}
                valor={value}
              />
            )
          }}
        />
      </div>
      <div className="w-6/12 pl-3">
        <Controller
          name="formulario.cargo"
          rules={{
            required: 'Cargo es un campo requerido',
            minLength: {value: 1, message: 'Cargo es un campo requerido'},
          }}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => {
            return (
              <Input
                onChange={onChange}
                valor={value}
                titulo={'Cargo'}
                estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                  !error?.message
                    ? 'border-cendiatra'
                    : 'border-cendiatra-rojo-1'
                }`}
              />
            )
          }}
        />
      </div>
      <div className="w-full">
        <Controller
          name="formulario.nombres"
          rules={{
            required: 'Nombres y Apellidos es un campo requerido',
          }}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => {
            return (
              <Input
                onChange={onChange}
                valor={value}
                titulo={'Nombres y Apellidos'}
                estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                  !error?.message
                    ? 'border-cendiatra'
                    : 'border-cendiatra-rojo-1'
                }`}
              />
            )
          }}
        />
      </div>
      <div className="w-full content-center items-center place-content-center flex">
        <div className="w-5/12">
          <Controller
            name="formulario.correoPersonal"
            rules={{
              required: 'Usuario es un campo requerido',
              pattern: {
                value: regexCorreoElectronico,
                message: 'Descripción correcta: ejemplo@ejemplo.com',
              },
              maxLength: {
                value: 64,
                message: 'Excedió el límite de 64 caracteres',
              },
            }}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => {
              return (
                <>
                  <Input
                    onChange={(evt) => {
                      onChange(evt)
                      setCargaInicial(true)
                    }}
                    valor={value}
                    titulo={'Correo electrónico (Correo personal)'}
                    estilosInput={`rounded text-cendiatra-gris-3 relative block w-full p-1.5 border rounded-lg focus:outline-none sm:text-sm border ${
                      error?.message || correoExistente
                        ? 'border-cendiatra-rojo-1'
                        : 'border-cendiatra'
                    }`}
                    estilosContenedor={'pb-2'}
                  />
                </>
              )
            }}
          />
        </div>
        <div className="w-5/12 pl-3">
          <Controller
            name="formulario.userPrincipalName"
            rules={{
              ...b2cReglas,
              validate: validarNombreUsuarioB2c,
            }}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => {
              return (
                <>
                  <Input
                    onChange={onChange}
                    valor={value}
                    titulo={'Nombre de usuario B2C'}
                    estilosInput={`rounded relative  block w-full p-1.5 border rounded-lg focus:outline-none sm:text-sm border ${
                      error?.type
                        ? 'border-cendiatra-rojo-1 text-cendiatra-gris-3'
                        : !creacion
                        ? 'bg-cendiatra-gris-placeholder border-cendiatra'
                        : 'border-cendiatra text-cendiatra-gris-3'
                    }`}
                    deshabilitado={!creacion}
                    estilosContenedor={'pb-2'}
                  />
                </>
              )
            }}
          />
        </div>
        <div className="w-2/12">
          <BotonOpcion
            titulo={'Activar'}
            estaActivo={watch('formulario.activo')}
            centrarLabel={true}
            funcionClick={() =>
              setValue('formulario.activo', !watch('formulario.activo'))
            }
          />
        </div>
      </div>
      <div className="w-full content-center items-center place-content-center flex">
        <div className="w-5/12">
          {(eventosValidacion.includes(
            errors.formulario?.correoPersonal?.type ?? ''
          ) &&
            errors.formulario?.correoPersonal?.message) ||
          correoExistente ? (
            <ComponenteAlerta
              mensaje={
                contieneCaracteresNoPermitidosMail
                  ? correoExistente
                    ? 'El correo ya se encuentra creado'
                    : errors?.formulario?.correoPersonal?.message ?? ''
                  : 'Solo se permiten los siguientes caracteres: . - _'
              }
            />
          ) : (
            ''
          )}
        </div>
        <div className="w-5/12 pl-3">
          {eventosValidacion.includes(
            errors.formulario?.userPrincipalName?.type ?? ''
          ) &&
          (errors.formulario?.userPrincipalName?.message ||
            errors.formulario?.userPrincipalName?.type === 'validate') ? (
            <ComponenteAlerta
              mensaje={
                contieneCaracteresNoPermitidosB2c
                  ? errors.formulario?.userPrincipalName?.type === 'validate'
                    ? 'El usuario ya se encuentra creado'
                    : errors?.formulario?.userPrincipalName?.message ?? 'BTC'
                  : 'Solo se permiten los siguientes caracteres: . - _'
              }
            />
          ) : (
            ''
          )}
        </div>
        <div className="w-2/12"></div>
      </div>
      <div className="w-full content-center items-center place-content-center text-center">
        <BotonSimple
          texto={'Guardar y enviar'}
          estilosBoton={`w-40 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 ${
            isValid && !correoExistente
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }`}
          funcion={() => {
            const {formulario} = getValues()
            funcionGuardar({
              guid: datosUsuario?.informacion?.guid ?? '',
              nombres: formulario?.nombres,
              rol:
                ROLES_PORTAL_CLIENTE.find((x) => x.id === formulario?.rol)
                  ?.nombre ?? '',
              activo: formulario?.activo,
              empresaId: formulario?.empresaId,
              correo: formulario?.correoPersonal,
              cargo: formulario?.cargo,
              contrasena: creacion ? '' : null,
              userPrincipalName: formulario?.userPrincipalName,
            })
          }}
          deshabilitado={!isValid && !correoExistente}
        />
      </div>
    </div>
  )
}

export default FormularioUsuario
