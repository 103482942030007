import React, {useEffect, useState, useContext} from 'react'
import {Input, ListaDesplegable, ErroresInput} from '../inputs'
import {useForm, Controller} from 'react-hook-form'
import {ContenedorDesplegable} from '../Contenedores'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import imagenBotonAgregar from '../../imagenes/addButton.png'
import BotonConImagen from '../BotonConImagen'
import {TablaConPaginacionYFiltros} from '../Tablas'
import {BotonSimple} from '../Botones'
import {
  ObtenerPaquetePorNombre,
  AgregarPaquetesACache,
} from '../../cache/servicios-cache'
import {ObtenerCuentasDeIngreso} from '../../cache/servicios-cache'
import {
  AgregarServiciosACache,
  ObtenerServiciosPorCodigo,
  ObtenerServiciosPorNombre,
} from '../../cache/servicios-cache'
import BotonEliminar from '../../imagenes/removeButton.png'
import {ContextoPaquete} from '../../contexto'
import {ContextApplication} from '../../contexto'
import {useModal} from '../../hooks/useModal'
import {
  regexTextoCaracteresBasicos,
  regexFiltroPaquetes,
} from '../../constantes'

const FormularioCrearPaquete = ({
  nombreBoton = '',
  codigoInterno = '',
  funcionTransaccion = () => {},
  paqueteIdEditar = 0,
}) => {
  const [nombrePaqueteAValidar, setNombreDelPaqueteAValidar] = useState('')
  const [parametrosContabilidad, setParametrosContabilidad] = useState([])
  const [servicioSeleccionado, setServicioSeleccionado] = useState({})
  const [serviciosFiltroPorCodigo, setServiciosFiltroPorCodigo] = useState([])
  const [serviciosFiltroPorNombre, setServiciosFiltroPorNombre] = useState([])

  const contextoAplicacion = useContext(ContextApplication)

  const [cerrarModal] = useModal()

  const {
    control,
    clearErrors,
    setValue,
    reset,
    trigger,
    watch,
    getValues,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm({
    mode: 'onChange',
  })

  const contextoPaquete = useContext(ContextoPaquete)

  useEffect(() => {
    if (paqueteIdEditar) {
      reset(contextoPaquete?.informacionPaquete)
      setNombreDelPaqueteAValidar(
        contextoPaquete?.informacionPaquete?.nombrePaquete
      )
    }
    trigger()
  }, [])

  useEffect(() => {
    const obtenerInformacionInicial = async () => {
      try {
        await AgregarPaquetesACache()
        await AgregarServiciosACache()
        const cuentaDeIngresos = await ObtenerCuentasDeIngreso()
        setParametrosContabilidad(cuentaDeIngresos)
      } catch (error) {
        console.log(error)
      }
    }
    obtenerInformacionInicial()
  }, [])

  const validarNuevoPaquete = async (nombre) => {
    if (nombrePaqueteAValidar.trim() === nombre.trim().toUpperCase()) {
      return true
    } else {
      let respuesta = await ObtenerPaquetePorNombre(nombre.toUpperCase())
      return !respuesta
    }
  }

  useEffect(() => {
    const subscription = watch(() => {
      contextoPaquete.setInformacionPaquete(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const columns = React.useMemo(() => [
    {
      Header: 'Código servicio',
      accessor: 'codigo',
      className: 'w-5/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-10 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: 'Nombre del servicio',
      accessor: 'nombre',
      className: 'w-6/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-10 ',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      id: 'button',
      className: 'w-1/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-10 rounded-r-lg pr-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <BotonConImagen
          ancho={'w-full'}
          imagen={BotonEliminar}
          textoAlternativo={'Boton eliminar'}
          funcionAEjecutar={() =>
            paqueteIdEditar
              ? modalEliminarServicio(row?.row?.original)
              : eliminarServicio(row?.row?.original?.id)
          }
          medio={true}
        />
      ),
    },
  ])
  const filtrarCodigos = async (e) => {
    setServicioSeleccionado({})
    if (e.target.value.length > 3) {
      const serviciosFiltrados = await ObtenerServiciosPorCodigo(e.target.value)
      setServiciosFiltroPorCodigo(serviciosFiltrados)
    } else {
      setServiciosFiltroPorCodigo([])
      setServiciosFiltroPorNombre([])
      setValue('nombreServicio', '')
    }
  }

  const filtrarNombres = async (e) => {
    setServicioSeleccionado({})
    if (e.target.value.length > 3) {
      const serviciosFiltrados = await ObtenerServiciosPorNombre(e.target.value)
      setServiciosFiltroPorNombre(serviciosFiltrados)
    } else {
      setServiciosFiltroPorNombre([])
      setServiciosFiltroPorCodigo([])
      setValue('codigoServicio', '')
    }
  }

  const manejarElementoSelecionado = (elemento) => {
    setValue('codigoServicio', elemento.codigo)
    setValue('nombreServicio', elemento.nombre)
    setServicioSeleccionado(elemento)
  }

  const agregarServicioALista = () => {
    if (JSON.stringify(servicioSeleccionado) === '{}') {
      return
    }
    const existeServicioEnLista = contextoPaquete.serviciosSeleccionados?.find(
      (servicio) => servicio.codigo === servicioSeleccionado.codigo
    )
    if (!existeServicioEnLista) {
      setValue('codigoServicio', '')
      setValue('nombreServicio', '')
      setServiciosFiltroPorNombre([])
      setServiciosFiltroPorCodigo([])
      contextoPaquete.setServiciosSeleccionados((prev) => {
        return [...prev, servicioSeleccionado]
      })
      setServicioSeleccionado({})
    }
  }

  const modalEliminarServicio = (datosExamen) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '¿ESTA SEGURO DE QUITAR EL SERVICIO?',
      contenido: (
        <div className="w-full flex flex-wrap m-5">
          <span className="w-full text-cendiatra text-xl font-bold">
            CÓDIGO SERVICIO: {datosExamen?.codigo}{' '}
          </span>
          <span className="w-full text-cendiatra text-xl font-bold">
            NOMBRE DEL SERVICIO: {datosExamen?.nombre}{' '}
          </span>
        </div>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => eliminarServicio(datosExamen?.id),
        },
        {
          nombre: 'No',
          click: cerrarModal,
        },
      ],
    })
  }

  const eliminarServicio = (id) => {
    let nuevaLista = contextoPaquete.serviciosSeleccionados?.filter((x) => {
      return x.id !== id
    })
    contextoPaquete.setServiciosSeleccionados(nuevaLista)
    cerrarModal()
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <div className="w-9/12 flex justify-between items-center flex-wrap">
        <Controller
          name="codigoPaquete"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-3/12'}
              estilosInput={
                'appearance-none rounded-none relative block w-full px-3 py-2 border border-gris bg-gris placeholder-white text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
              }
              tipo={'text'}
              deshabilitado={true}
              titulo={'Código paquete'}
              valor={codigoInterno ? codigoInterno : value}
            />
          )}
        />
        <div className="w-8/12 flex flex-wrap justify-center items-center">
          <Controller
            name="nombrePaquete"
            control={control}
            rules={{
              required: true,
              pattern: regexTextoCaracteresBasicos,
              validate: validarNuevoPaquete,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <span className="text-red-500 w-full">
                  {errors.name?.type === 'validate' &&
                    'El nombre del paquete especificado ya se encuentra registrado'}
                </span>
                <Input
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) => element === 'nombrePaquete'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'text'}
                  placeholder={'NOMBRE DEL PAQUETE'}
                  titulo={'Nombre del paquete*'}
                  onChange={onChange}
                  valor={value}
                />
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={'nombrePaquete'}
                  tipoError={'pattern'}
                  mensaje={'Se permiten estos caracteres: + ( ) [ ] , / - . '}
                />
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={'nombrePaquete'}
                  tipoError={'validate'}
                  mensaje={'Ya existe un paquete con este nombre'}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="w-5/12 flex justify-between items-center flex-wrap mt-6 border border-cendiatra rounded-xl">
        <ContenedorDesplegable
          titulo={'Parámetros contabilidad'}
          expandidoInicial={true}
          posicionTitulo={'justify-center'}
          ocultarBotonDesplegable={true}
        >
          <Controller
            name="cuentaIngreso"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'w-8/12 mb-5'}
                estilosLista={
                  Object.keys(errors).find(
                    (element) => element === 'cuentaIngreso'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                titulo={'Cuenta de ingreso*'}
                opciones={parametrosContabilidad}
                valor={value}
              />
            )}
          />
        </ContenedorDesplegable>
      </div>
      <div className="w-9/12 flex justify-between items-center flex-wrap mt-6">
        <ContenedorDesplegable
          titulo={'AGREGAR SERVICIOS'}
          expandidoInicial={true}
          posicionTitulo={'justify-center'}
          ocultarBotonDesplegable={true}
        >
          <div className="w-full flex justify-evenly items-center flex-wrap">
            <Controller
              name="codigoServicio"
              control={control}
              rules={{
                required: false,
                onChange: (e) => filtrarCodigos(e),
                pattern: regexFiltroPaquetes,
              }}
              render={({field: {onChange, value}}) => (
                <div className="w-5/12">
                  <CampoFiltrarEspañol
                    estilosPersonalizados={'w-full'}
                    titulo={'Código de servicios'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'codigoServicio'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'INGRESE CÓDIGO AUTOCOMPLETAR'}
                    valorDelCampoFiltro={value}
                    desactivarOtroFiltro={false}
                    informacionFiltrada={serviciosFiltroPorCodigo}
                    handleChange={onChange}
                    handleOptionChange={manejarElementoSelecionado}
                    id={1}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'codigoServicio'}
                    tipoError={'pattern'}
                    mensaje={'Se aceptan números, puntos(.) y guiones(-)'}
                  />
                </div>
              )}
            />
            <Controller
              name="nombreServicio"
              control={control}
              rules={{
                required: false,
                onChange: (e) => filtrarNombres(e),
                pattern: regexTextoCaracteresBasicos,
              }}
              render={({field: {onChange, value}}) => (
                <div className="w-5/12">
                  <CampoFiltrarEspañol
                    estilosPersonalizados={'w-full'}
                    titulo={'Nombre del servicio'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'nombreServicio'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'DESCRIPCIÓN (AUTOCOMPLETAR)'}
                    valorDelCampoFiltro={value}
                    desactivarOtroFiltro={false}
                    informacionFiltrada={serviciosFiltroPorNombre}
                    handleChange={onChange}
                    handleOptionChange={manejarElementoSelecionado}
                    id={2}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'nombreServicio'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten estos caracteres: + ( ) [ ] , / - . '}
                  />
                </div>
              )}
            />
            <BotonConImagen
              ancho={'w-1/12'}
              imagen={imagenBotonAgregar}
              textoAlternativo={'Boton Agregar'}
              funcionAEjecutar={agregarServicioALista}
            />
          </div>
        </ContenedorDesplegable>
      </div>
      <div
        className="w-9/12 flex jusify-center flex-wrap content-start"
        style={{minHeight: '300px', maxHeight: '320px'}}
      >
        <span className="w-full flex justify-start items-center mt-7 font-bold text-cendiatra -mb-3">
          Agregar minimo 2 servicios*
        </span>
        <TablaConPaginacionYFiltros
          columns={columns}
          data={contextoPaquete.serviciosSeleccionados}
          estilosTablaDatos={'overflow-y-scroll h-48'}
        />
      </div>
      <div className="w-full flex justify-center items-center">
        <BotonSimple
          texto={nombreBoton}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            Boolean(
              Object.keys(errors).length === 0 &&
                contextoPaquete.serviciosSeleccionados.length >= 2
            )
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder'
          }  '}`}
          funcion={funcionTransaccion}
          tipoDeBoton={'button'}
        />
      </div>
    </div>
  )
}

export default FormularioCrearPaquete
