import React, {useContext} from 'react'
import {ContextoPlantilla} from '../../contexto'
import {BotonOpcion} from '../Botones'

const BotonesPlantillas = ({}) => {
  const contextoPlantilla = useContext(ContextoPlantilla)

  const manejarCambioBoton = (id) => {
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      botones: contextoPlantilla.plantilla.botones.map((boton) => {
        if ((boton?.botonId ?? boton?.id) == id) {
          return {
            ...boton,
            estadoBoton: !boton.estadoBoton,
          }
        }
        return boton
      }),
    })
  }

  return (
    <div className="w-full">
      <label className="block text-13px leading-15px text-cendiatra-verde-2 my-5px">
        Seleccione los botones que desea activar
      </label>
      <div className="flex flex-wrap border-cendiatra border rounded rounded-lg justify-center">
        {contextoPlantilla.plantilla.botones.map((boton) => (
          <div className="w-1/6 flex mb-2 p-5">
            <BotonOpcion
              id={boton?.botonId ?? boton?.id}
              titulo={boton.nombreBoton}
              estaActivo={boton.estadoBoton}
              funcionClick={manejarCambioBoton}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default BotonesPlantillas
