export const OPCIONES_PRECIO = [
  {
    Id: 1,
    Nombre: 'Inmediato',
  },
  {
    Id: 2,
    Nombre: 'A partir de mañana',
  },
]

export const OPCIONES_PRECIO_STRING = {
  INMEDIATO: '1',
  A_PARTIR_DE_MAÑANA: '2',
}
