import React, {useContext, useEffect} from 'react'
import {ContextoPlantilla} from '../../../contexto'
import PlantillaPestana from './PlantillaPestana.jsx'

const PlantillaEstructura = ({numeroPagina}) => {
  const contextoPlantilla = useContext(ContextoPlantilla)

  useEffect(() => {
    if (numeroPagina === contextoPlantilla.numeroPagina) {
      contextoPlantilla.setErrores([])
    }
  }, [contextoPlantilla.numeroPagina])

  return (
    <div className="w-full">
      <div className="flex flex-wrap">
        {contextoPlantilla.plantilla.pestanas.map((pestana) => (
          <div className="w-full flex mb-2 p-5">
            <PlantillaPestana
              numeroPestana={pestana.numeroPestana}
              numeroPagina={numeroPagina}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PlantillaEstructura
