import React, {useContext} from 'react'
import {Input} from '../../../inputs'
import {Controller, useForm} from 'react-hook-form'
import {ContextoRegistro} from '../../../../contexto'
import {aplicarFormatoMoneda} from '../../../../utilidades'

const Factura = () => {
  const {control} = useForm()

  const contextoRegistro = useContext(ContextoRegistro)

  return (
    <div className="w-full flex justify-center items-center rounded-xl mt-2  mb-4 border border-cendiatra-gris-3 py-6">
      <div className="w-6/12">
        <div className="w-full flex-col">
          <h3 className="text-xl text-center mb-5 text-cendiatra font-bold">
            AUTORIZACIÓN CORTESÍA
          </h3>
        </div>
        <div className="w-full flex-col flex items-center">
          <Controller
            control={control}
            name="autorizacion"
            render={({field: {onChange, value}}) => (
              <Input
                onChange={onChange}
                estilosContenedor={
                  'w-8/12 flex justify-center text-17px font-bold'
                }
                estilosInput={
                  'appearance-none h-40 rounded relative block w-full  p-1.5 border border-cendiatra-gris-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 text-cendiatra-gris-1 text-2xl px-4'
                }
                tipo={'text'}
                placeholder={''}
                valor={contextoRegistro.informacionFacturacion.autorizacion}
                deshabilitado={true}
              />
            )}
          />
        </div>
      </div>
      <div className="w-6/12">
        <div className="w-full flex justify-between items-center my-10 ">
          <div className="w-6/12 flex justify-end items-center pt-2">
            <span className="text-xl  text-cendiatra font-bold">
              VALOR TOTAL SERVICIOS
            </span>
          </div>
          <div className="w-4/12 flex flex-wrap justify-center items-center">
            <span className="text-cendiatra-gris-1 font-bold text-xl ">
              {aplicarFormatoMoneda(contextoRegistro.valorTotalServicios)}
            </span>
          </div>
        </div>
        <div className="w-full flex justify-between items-center my-10">
          <div className="w-6/12 flex justify-end items-center ">
            <span className="text-xl  text-cendiatra font-bold">CORTESÍA</span>
          </div>
          <div className="flex justify-center items-center ml-16">
            <span className="text-cendiatra-gris-1 font-bold text-xl ">
              {contextoRegistro.porcentajeCortesia}%
            </span>
          </div>

          <div className="w-4/12 flex justify-center items-center ">
            <span className="text-red-700 font-bold text-xl ">
              {contextoRegistro.valorCortesia > 0 ? '-' : ''}
              {aplicarFormatoMoneda(contextoRegistro.valorCortesia)}
            </span>
          </div>
        </div>
        <div className="w-full flex justify-between items-center my-10 ">
          <div className="w-6/12 flex justify-end items-center pt-2">
            <span className="text-xl  text-cendiatra font-bold">SUB TOTAL</span>
          </div>
          <div className="w-4/12 flex justify-center items-center ">
            <span className="text-cendiatra-gris-1 font-bold text-xl ">
              {aplicarFormatoMoneda(contextoRegistro.valorSubTotalAtencion)}
            </span>
          </div>
        </div>

        <div className="w-full flex justify-between items-center my-10 ">
          <div className="w-6/12 flex justify-end items-center pt-2">
            <span className="text-xl text-cendiatra font-bold">IVA</span>
          </div>
          <div className="w-4/12 flex justify-center items-center ">
            <span className="text-cendiatra-gris-1 font-bold text-xl ">
              {aplicarFormatoMoneda(contextoRegistro.valorIva)}
            </span>
          </div>
        </div>
        <div className="w-full flex justify-between items-center my-10  pt-5 border-t-2 border-cendiatra-gris-1">
          <div className="w-6/12 flex justify-end items-center pt-2">
            <span className="text-xl text-cendiatra font-bold">TOTAL</span>
          </div>
          <div className="w-4/12 flex justify-center items-center ">
            <span className="text-cendiatra font-bold text-xl ">
              {aplicarFormatoMoneda(contextoRegistro.valorTotalAtencion)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Factura
