import React, {useState, createContext, useMemo} from 'react'
import {nombreBotonesPlantilla} from '../constantes/plantillaBotones'
import {FLAGS_PLUGINS_PESTANNAS_MOTOR} from '../constantes/motorPlantilla'

export const ContextoPlantilla = createContext('')

export const PlantillaConContexto = ({children}) => {
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(5)
  const [errores, setErrores] = useState([])
  const [plantilla, setPlantilla] = useState({
    formulariosFinalPagina: true,
    imprimeCertificado: false,
    estructuraId: -1,
    incluirExamenes: false,
    consentimientoCertificado: '',
    codigoCertificado: '',
    fechaCertificado: '',
    versionCertificado: '',
    consentimientoHistoria: '',
    codigoHistoria: '',
    fechaHistoria: '',
    versionHistoria: '',
    pestanas: [],
    botones: [
      {
        nombreBoton: nombreBotonesPlantilla.GUARDARTOMADEDATOS,
        estadoBoton: false,
        botonId: 1,
      },
      {
        nombreBoton: nombreBotonesPlantilla.FORMULAMEDICA,
        estadoBoton: false,
        botonId: 2,
      },
      {
        nombreBoton: nombreBotonesPlantilla.FORMULAOPTOMETRICA,
        estadoBoton: false,
        botonId: 3,
      },
      {
        nombreBoton: nombreBotonesPlantilla.INTERCONSULTA,
        estadoBoton: false,
        botonId: 4,
      },
      {
        nombreBoton: nombreBotonesPlantilla.INCAPACIDADMEDICA,
        estadoBoton: false,
        botonId: 5,
      },
      {
        nombreBoton: nombreBotonesPlantilla.IMPRIMIRCERTIFICADO,
        estadoBoton: false,
        botonId: 6,
      },
    ],
  })
  const [tiposCampo, setTiposCampo] = useState([])
  const [camposPreCarga, setCamposPreCarga] = useState([])

  const pestannaRecomendacionMedica = useMemo(
    () => ({
      tituloPestana: 'RECOMENDACIONES',
      numeroPestana: plantilla.pestanas.length + 1,
      secciones: [
        {
          nombreSeccion: 'RECOMENDACIONES',
          numeroSeccion: 1,
          campos: [
            {
              tituloCampo: 'EVITAR SOBRECARGAS MAYORES A 25KG',
              numeroCampo: 1,
              tipoCampoId: 6,
              requerido: false,
              replicable: false,
              mostrarEnCertificado: true,
              atributos: {
                [FLAGS_PLUGINS_PESTANNAS_MOTOR.RECOMENDACIONES]: true,
              },
            },
            {
              tituloCampo:
                'ACUDIR A CONSULTA MÉDICA EN SU EPS PARA MANEJO Y CONTROL POR:',
              numeroCampo: 2,
              tipoCampoId: 6,
              requerido: false,
              replicable: false,
              mostrarEnCertificado: true,
              atributos: {
                [FLAGS_PLUGINS_PESTANNAS_MOTOR.RECOMENDACIONES]: true,
              },
            },
            {
              tituloCampo: 'NUTRICIONALES - CAMBIOS DE ESTILOS DE VIDA',
              numeroCampo: 3,
              tipoCampoId: 6,
              requerido: false,
              replicable: false,
              mostrarEnCertificado: true,
              atributos: {
                [FLAGS_PLUGINS_PESTANNAS_MOTOR.RECOMENDACIONES]: true,
              },
            },
            {
              tituloCampo: 'VALORACIÓN OPTOMÉTRICA',
              numeroCampo: 4,
              tipoCampoId: 6,
              requerido: false,
              replicable: false,
              mostrarEnCertificado: true,
              atributos: {
                [FLAGS_PLUGINS_PESTANNAS_MOTOR.RECOMENDACIONES]: true,
              },
            },
            {
              tituloCampo: 'OTRAS:',
              numeroCampo: 5,
              tipoCampoId: 6,
              requerido: false,
              replicable: false,
              mostrarEnCertificado: true,
              atributos: {
                [FLAGS_PLUGINS_PESTANNAS_MOTOR.RECOMENDACIONES]: true,
              },
            },
          ],
        },
      ],
    }),
    [plantilla]
  )

  return (
    <ContextoPlantilla.Provider
      value={{
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
        plantilla: plantilla,
        setPlantilla: setPlantilla,
        tiposCampo: tiposCampo,
        setTiposCampo: setTiposCampo,
        errores: errores,
        setErrores: setErrores,
        camposPreCarga: camposPreCarga,
        setCamposPreCarga: setCamposPreCarga,
        pestannaRecomendacionMedica: pestannaRecomendacionMedica,
      }}
    >
      {children}
    </ContextoPlantilla.Provider>
  )
}
