export const TIPOS_FACTURACION = {
  CREDITO: 'CRÉDITO',
  EFECTIVO: 'EFECTIVO',
  CONTADO: 'CONTADO',
}

export const LISTA_TIPOS_FACTURACION = [
  {Id: 1, Nombre: TIPOS_FACTURACION.CONTADO},
  {Id: 2, Nombre: TIPOS_FACTURACION.CREDITO},
]

export const MODOS_FACTURACION = {
  CONTADO: 1,
  CREDITO: 2,
}

export const MODALIDAD_FACTURACION = [
  {Id: 1, Nombre: 'Por empresa padre'},
  {Id: 2, Nombre: 'Por empresa hija'},
]

export const ESTADOS_FACTURACION_PAGOS = {
  1: 'PENDIENTE POR CONFIRMACIÓN',
  2: 'CONFIRMADO PENDIENTE POR FACTURAR',
  3: 'FACTURADO',
}
