import React, {useContext, useEffect} from 'react'
import EncabezadoDinamico from './EncabezadoDinamico'
import FilaDatosOtrosServicios from './FilaDatosOtrosServicios'
import {Boton} from '../Botones'
import {ContextoGestionOtrosServicios, ContextApplication} from '../../contexto'
import {
  OperacionesOtrosServicios,
  ObtenerOtrosServicios,
} from '../../microservicios/GestionServicios'
import {ObtenerCupPorId} from '../../cache/servicios-cache/Cups'
import {useHistory} from 'react-router-dom'
import {APLICAR_PRECIO_DESDE} from '../../constantes'
import clsx from 'clsx'

const FormularioAgregarPreciosOtrosServicios = () => {
  const contextoGestionOtrosServicios = useContext(
    ContextoGestionOtrosServicios
  )

  const contextoAplicacion = useContext(ContextApplication)
  const history = useHistory()

  const moverCerosAlFinal = (arr) => {
    let sinConcepto = arr.filter(
      (item) => !item?.concepto && !item?.rangoMaximo
    )
    let conConcepto = arr.filter((item) => item?.concepto && item?.rangoMaximo)
    return conConcepto.concat(sinConcepto)
  }

  useEffect(() => {
    const obtenerDatosServicios = async () => {
      const datosServicios = await ObtenerOtrosServicios()
      if (datosServicios.status === 200) {
        contextoGestionOtrosServicios.setDatosServicios(
          await Promise.all(
            datosServicios.data.map(async (servicio) => {
              const informacionCups = await ObtenerCupPorId(servicio.cupsId)

              const arregloRangosEnOrden = moverCerosAlFinal(servicio.rangos)

              const cup = clsx({
                [`${informacionCups?.Codigo} - ${informacionCups?.Nombre}`]:
                  informacionCups?.Codigo && informacionCups?.Nombre,
              })

              return {
                id: servicio.id || null,
                nombre: servicio.nombre || '',
                tipoServicioId: servicio.tipoServicioId || '',
                cup: cup,
                cupSeleccionado: {
                  id: informacionCups?.Id || '',
                  codigo: informacionCups?.Codigo || '',
                  filtro:
                    `${informacionCups?.Codigo} - ${informacionCups?.Nombre}` ||
                    '',
                },
                cantidad: servicio.cantidad || 0,
                tieneRangos: servicio.tieneRangos || false,
                iva: servicio.iva || 0,
                valor: servicio.valor || '',
                cuentaContableId: servicio.cuentaContableId || 0,
                noEstaDuplicado: true,
                cupsNoExiste: true,
                rangos: arregloRangosEnOrden.map((rango, i) => {
                  return {
                    id: rango.id || null,
                    concepto: rango.concepto || '',
                    index: i === servicio.rangos.length - 1 ? 9999 : i + 1,
                    rangoMinimo: rango.rangoMinimo || null,
                    rangoMaximo: rango.rangoMaximo || null,
                    precioDiaSiguiente: rango.precioDiaSiguiente
                      ? APLICAR_PRECIO_DESDE.A_PARTIR_DE_MANANA.toString()
                      : APLICAR_PRECIO_DESDE.INMEDIATO.toString(),
                    precioActual:
                      rango.precioDiaSiguiente && rango.valorAnterior
                        ? rango.valorAnterior
                        : rango.valor,
                    valor: rango.precioDiaSiguiente ? rango.valor : '',
                    rangoCompleto:
                      i === servicio.rangos.length - 1
                        ? `${rango.rangoMinimo} En adelante`
                        : `${rango.rangoMinimo} a ${rango.rangoMaximo} ${rango.concepto}`,
                  }
                }),
              }
            })
          )
        )
      }
    }
    obtenerDatosServicios()
  }, [])

  const arrayEncavezados = [
    {
      titulo: 'ID Otros Servicios',
      estilos: 'w-1/12',
    },
    {
      titulo: 'Nombre',
      estilos: 'w-2/12',
    },
    {
      titulo: 'Tipo de servicio',
      estilos: 'w-1/12',
    },
    {
      titulo: 'CUPS',
      estilos: 'w-2/12',
    },
    {
      titulo: 'Cantidad',
      estilos: 'w-1/12',
    },
    {
      titulo: 'Requiere rangos',
      estilos: 'w-6%',
    },
    {
      titulo: 'IVA',
      estilos: 'w-7%',
    },
    {
      titulo: 'Valor',
      estilos: 'w-7%',
    },
    {
      titulo: 'Cuenta contable',
      estilos: 'w-2/12',
    },
  ]

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const mostrarServiciosConErrores = (serviciosConErrores) => {
    return (
      <div className="text-center w-full">
        {serviciosConErrores.length > 0 ? (
          <>
            <div className="text-cendiatra mb-6 text-2xl font-bold">
              FALTAN CAMPOS POR INGRESAR
            </div>
            <span className="text-cendiatra text-xl font-bold">
              VALIDAR OTRO SERVICIO:
            </span>
            {serviciosConErrores.map((servicio) => (
              <div key={servicio.id ?? Math.random()}>
                <span className="w-full flex items-center justify-center text-cendiatra text-lg font-bold">
                  ID: {servicio.id ?? 0}
                </span>
                <span className="w-full flex items-center justify-center text-cendiatra-rojo-1 text-lg font-bold">
                  {servicio.nombre}
                </span>
              </div>
            ))}
            <div className="w-full flex justify-center items-center"></div>
          </>
        ) : null}
      </div>
    )
  }

  const determinarTextoModal = (serviciosNuevos, serviciosModificados) => {
    return (
      <div className="text-center w-full">
        {serviciosNuevos.length > 0 ? (
          <>
            <div className="text-cendiatra mb-6 text-2xl font-bold mt-10">
              HAY NUEVOS ELEMENTOS EN OTROS SERVICIOS
            </div>
            {serviciosNuevos.map((servicio) => (
              <div key={servicio.id ?? Math.random()}>
                <span className="w-full flex items-center justify-center text-cendiatra text-lg font-bold">
                  -{servicio.nombre}
                </span>
              </div>
            ))}
            <div className="w-full flex justify-center items-center"></div>
          </>
        ) : null}
        {serviciosModificados.length > 0 ? (
          <>
            <div className="text-cendiatra mb-6 text-2xl font-bold mt-10">
              HAY CAMBIOS EN OTROS SERVICIOS
            </div>
            {serviciosModificados.map((servicio) => (
              <div key={servicio.id ?? Math.random()}>
                <span className="w-full flex items-center justify-center text-cendiatra text-lg font-bold">
                  -{servicio.nombre}
                </span>
              </div>
            ))}
            <div className="w-full flex justify-center items-center"></div>
          </>
        ) : null}

        <div className="text-cendiatra mb-6 text-2xl font-bold mt-10">
          ¿DESEA GUARDARLOS?
        </div>
      </div>
    )
  }
  const enviarPayloadServicios = async () => {
    const payloadOtrosServicios = {
      OtrosServicios: contextoGestionOtrosServicios.datosServicios.map(
        (servicios) => {
          return {
            id: servicios?.id || null,
            nombre: servicios?.nombre || '',
            tipoServicioId: servicios?.tipoServicioId || 0,
            cupsId: servicios?.cupSeleccionado?.id || 0,
            cantidad: servicios?.cantidad || 0,
            tieneRangos: servicios?.tieneRangos || false,
            iva: servicios?.iva || 0,
            valor: servicios?.valor || 0,
            cuentaContableId: servicios?.cuentaContableId || 0,
            rangos: servicios?.tieneRangos
              ? servicios?.rangos.map((rango) => {
                  return {
                    id: rango.id || null,
                    concepto: rango.concepto || '',
                    rangoMinimo: rango.rangoMinimo || null,
                    rangoMaximo: rango.rangoMaximo || null,
                    precioDiaSiguiente:
                      parseInt(rango.precioDiaSiguiente) ===
                      APLICAR_PRECIO_DESDE.A_PARTIR_DE_MANANA,
                    valor: rango.valor || rango.precioActual,
                  }
                })
              : [],
          }
        }
      ),
      EliminarOtrosServicios: contextoGestionOtrosServicios.serviciosAEliminar,
    }
    try {
      const respuesta = await OperacionesOtrosServicios(payloadOtrosServicios)

      if (respuesta.status === 200) {
        manejaCerrarModal()
        history.go(0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const modalElementosTienenErrores = (serviciosConErrores) => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: mostrarServiciosConErrores(serviciosConErrores),
      botones: [
        {
          nombre: 'Regresar',
          click: manejaCerrarModal,
        },
      ],
    })
  }

  const modalMostrarCambiosRealizados = (
    serviciosConErrores,
    serviciosNuevos,
    serviciosModificados
  ) => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: determinarTextoModal(
        serviciosConErrores,
        serviciosNuevos,
        serviciosModificados
      ),
      botones: [
        {
          nombre: 'No',
          click: manejaCerrarModal,
        },
        {
          nombre: 'Si',
          click: enviarPayloadServicios,
        },
      ],
    })
  }

  const manejarClickBotonGuardar = () => {
    if (contextoGestionOtrosServicios.serviciosConErrores.length > 0) {
      return modalElementosTienenErrores(
        contextoGestionOtrosServicios.serviciosConErrores
      )
    }
    if (
      contextoGestionOtrosServicios.serviciosNuevos.length > 0 ||
      contextoGestionOtrosServicios.serviciosModificados.length > 0
    ) {
      return modalMostrarCambiosRealizados(
        contextoGestionOtrosServicios.serviciosNuevos,
        contextoGestionOtrosServicios.serviciosModificados
      )
    }
    enviarPayloadServicios()
  }

  return (
    <>
      <div
        className="w-full flex items-start justify-center flex-wrap content-start"
        style={{minHeight: '500px'}}
      >
        <EncabezadoDinamico datos={arrayEncavezados} />
        <FilaDatosOtrosServicios />
      </div>
      <div className="w-full flex justify-center items-center">
        <Boton
          titulo={'Guardar'}
          funcionCLick={manejarClickBotonGuardar}
          habilitado={
            contextoGestionOtrosServicios.datosServicios.length > 0 ||
            contextoGestionOtrosServicios.serviciosAEliminar.length > 0
          }
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
        />
      </div>
    </>
  )
}

export default FormularioAgregarPreciosOtrosServicios
