import React, {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination, useRowSelect} from 'react-table'

const TablaDinamica = ({
  data,
  tableClass,
  columns,
  estilosHeader,
  paginacion = false,
  filasPorPagina = 10,
  estilosTr = '',
  children,
  quitarThead = false,
  actualizarSeleccionado,
}) => {
  const [numeroInicio, setNumeroInicio] = useState(0)
  const [numeroFinal, setNumeroFinal] = useState(filasPorPagina)
  const [numerosParaMostrar, setNumerosparaMostrar] = useState([])

  const initialStateMemo = useMemo(() => {
    if (paginacion) {
      return {pageIndex: 0, pageSize: filasPorPagina}
    }
    return {pageIndex: 0}
  }, [paginacion])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canNextPage,
    nextPage,
    previousPage,
    pageCount,
    gotoPage,
    pageOptions,
    state: {pageIndex},
  } = useTable(
    {
      columns,
      data,
      actualizarSeleccionado,
      initialState: {...initialStateMemo},
    },
    usePagination,
    useRowSelect
  )

  const datos = useMemo(() => {
    return paginacion ? page : rows
  }, [paginacion, page, rows])

  useEffect(() => {
    const crearArregloNumeros = () => {
      if (pageOptions.length > 10) {
        let numeros = pageOptions.slice(numeroInicio, numeroFinal)
        setNumerosparaMostrar(numeros)
      } else {
        setNumerosparaMostrar(pageOptions)
      }
    }
    crearArregloNumeros()
  }, [data, pageOptions, numeroInicio])

  useEffect(() => {
    const hasta =
      pageIndex !== 0 ? pageIndex + 1 * filasPorPagina : filasPorPagina
    setNumeroInicio(pageIndex)
    setNumeroFinal(hasta)
  }, [pageIndex])

  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <table className={`w-full ${tableClass}`} {...getTableProps()}>
        {!quitarThead ? (
          <thead
            className={`w-full flex justify-evenly items-center ${
              estilosHeader
                ? estilosHeader
                : 'bg-cendiatra-verde-7 text-white rounded-10px'
            }  h-14 `}
            style={{lineHeight: '16px'}}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="w-full flex justify-evenly items-center h-full p-3px"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: `${column.className} h-full flex flex-wrap justify-center items-center content-center  m-3px rounded-9px font-light normal-case`,
                      },
                    ])}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody className="w-full " {...getTableBodyProps()}>
          {datos.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className={
                  estilosTr
                    ? estilosTr
                    : 'w-full flex border-b-2 border-b-gris justify-evenly items-center text-center text-17px text-cendiatra-gris-1  my-5'
                }
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: `${cell.column.className} h-full flex flex-wrap justify-center items-center content-center  m-3px rounded-9px font-light`,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {children}
      {data.length > filasPorPagina && paginacion ? (
        <div className="w-full flex justify-center my-5">
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => gotoPage(0)}
          >
            {'«'}
          </button>{' '}
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => previousPage()}
          >
            {'‹'}
          </button>{' '}
          {numerosParaMostrar.map((elemento) => (
            <div
              key={elemento}
              className={
                pageIndex === elemento
                  ? 'z-10 bg-cendiatra border-cendiatra text-white relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full '
                  : '  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full'
              }
              onClick={() => gotoPage(elemento)}
            >
              {elemento + 1}
            </div>
          ))}
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'›'}
          </button>{' '}
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'»'}
          </button>{' '}
        </div>
      ) : null}
    </div>
  )
}

export default TablaDinamica
