import {getRestApi} from './ApiAxios'

export async function GuardarNumeroFactura(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PATCH',
    url: 'gato-api-function/GuardarNumeroFactura',
    data: payload,
  })
}

export async function ObtenerPagosPendientesPaginados(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'gato-api-function/ObtenerPagosPendientesPaginados',
    data: payload,
  })
}

export async function ObtenerInformacionDetalladaPagoPorId(id) {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'gato-api-function/ObtenerInformacionDetalladaPagoPorId',
    {params: {id}}
  )
}

export async function GuardarDatosFacturacionPago(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PATCH',
    url: 'gato-api-function/GuardarDatosFacturacionPago',
    data: payload,
  })
}

export async function ObtenerPagosConfirmados(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'gato-api-function/ObtenerPagosConfirmados',
    data: payload,
  })
}

export async function GuardarDatosUnicaFacturacion(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PATCH',
    url: 'gato-api-function/GuardarDatosUnicaFacturacion',
    data: payload,
  })
}

export async function ObtenerPagosFacturadosYPendientes(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'gato-api-function/ObtenerPagosFacturadosYPendientes',
    data: payload,
  })
}

export async function ObtenerFechaUltimaCargaRegistrosPagos() {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'gato-api-function/ObtenerFechaUltimaCargaRegistrosPagos'
  )
}

export async function ObtenerReportePagosFacturadosYPendientes(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'gato-api-function/ObtenerReportePagosFacturadosYPendientes',
    data: payload,
  })
}

export async function AnularPago(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PATCH',
    url: 'gato-api-function/AnularPago',
    data: payload,
  })
}
