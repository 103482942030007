import React, {useEffect, useState, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {Input, ListaDesplegable} from '../../inputs'
import {BotonSimple} from '../../Botones'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import {
  AgregarEmpresasACache,
  ObtenerEmpresasPorNitONombre,
  ObtenerTodasLasSedes,
} from '../../../cache/servicios-cache'
import {useModal} from '../../../hooks/useModal'
import {ContextApplication} from '../../../contexto'
import {useHistory} from 'react-router-dom'
import {
  GenerarReporteLaboratorio,
  GenerarReporteRips,
  GenerarReporteDetalleAtencion,
  GenerarReporteDetalleHC,
  GenerarReporteDetalleMedioPago,
  GenerarReporteContabilidad,
  GenerarReporteAudiometria,
  GenerarReporteSVE,
} from '../../../microservicios/Reporte'
import {useMsal} from '@azure/msal-react'
import {ROLES_APP} from '../../../constantes'

const defaultValues = {
  fechaInicio: '',
  fechaFinal: '',
  sede: '',
  empresa: '',
  tipoReporte: '',
}

const FormularioGeneradorDeReportes = () => {
  const [cerrarModal] = useModal()
  const history = useHistory()
  const contextoApp = useContext(ContextApplication)

  const {accounts} = useMsal()
  const rolUsuario = accounts[0].idTokenClaims.roles[0]

  const {
    trigger,
    reset,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
  })

  const empresaSeleccionadaPorDefecto = {
    empresaPadreId: '',
    id: '',
    nombre: '',
    nit: '',
    telefono: '',
    direccion: '',
    observaciones: '',
    tipoFacturacion: '',
    correo: '',
    filtro: '',
  }

  const [sedes, setSedes] = useState([])

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(
    empresaSeleccionadaPorDefecto
  )

  const [empresasFiltradas, setEmpresasFiltradas] = useState([])

  const [sedeRequerida, setSedeRequerida] = useState(false)

  const [empresaRequerida, setEmpresaRequerida] = useState(false)

  const [sedeOpcional, setSedeOpcional] = useState(false)

  const [empresaOpcional, setEmpresaOpcional] = useState(false)

  const [fechaMinima, setFechaMinima] = useState('')
  const [fechaMaxima, setFechaMaxima] = useState('')

  useEffect(() => {
    let componenteMontado = true
    trigger()
    const obtenerDatosIniciales = async () => {
      await AgregarEmpresasACache()
      const todasLasSedes = await ObtenerTodasLasSedes()
      setSedes(todasLasSedes)
    }
    if (componenteMontado) {
      obtenerDatosIniciales()
    }

    return () => {
      componenteMontado = false
    }
  }, [])

  const TEXTO_TIPO_REPORTES = {
    DETALLE_HC: 1,
    RIPS: 2,
    LABORATORIO: 3,
    ATENCION_DETALLE: 4,
    DETALLE_MEDIOS_PAGO: 5,
    CONTABILIDAD: 6,
    AUDIOMETRIA: 7,
    SVE: 8,
  }

  useEffect(() => {
    const subscription = watch((value) => {
      const tipoReporte = parseInt(value?.tipoReporte)

      setSedeRequerida(
        [
          TEXTO_TIPO_REPORTES.RIPS,
          TEXTO_TIPO_REPORTES.DETALLE_MEDIOS_PAGO,
        ].includes(tipoReporte)
      )
      setEmpresaRequerida(
        [
          TEXTO_TIPO_REPORTES.DETALLE_HC,
          TEXTO_TIPO_REPORTES.AUDIOMETRIA,
          TEXTO_TIPO_REPORTES.SVE,
        ].includes(tipoReporte)
      )
      trigger()

      setSedeOpcional(
        [
          TEXTO_TIPO_REPORTES.LABORATORIO,
          TEXTO_TIPO_REPORTES.ATENCION_DETALLE,
          TEXTO_TIPO_REPORTES.CONTABILIDAD,
        ].includes(tipoReporte)
      )
      setEmpresaOpcional(
        [TEXTO_TIPO_REPORTES.ATENCION_DETALLE].includes(tipoReporte)
      )

      setFechaMinima(value?.fechaInicio)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    setValue('sede', '')
    setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
  }, [sedeRequerida, empresaRequerida])

  const manejarEmpresaCambiada = async (e) => {
    if (e.target.value === '') {
      setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
    } else {
      setEmpresaSeleccionada({
        ...empresaSeleccionada,
        filtro: e.target.value,
      })
    }
    setValue('empresa')
    if (e.target.value.length >= 3) {
      let empresas
      if (isNaN(e.target.value)) {
        empresas = await ObtenerEmpresasPorNitONombre('', e.target.value)
      } else {
        empresas = await ObtenerEmpresasPorNitONombre(e.target.value, '')
      }
      setEmpresasFiltradas(
        empresas.map((empresa) => ({
          id: empresa.Id,
          filtro: empresa.Nit + '-' + empresa.Nombre.toUpperCase(),
          empresaPadreId: empresa.EmpresaPadreId,
          nit: empresa.Nit,
          observaciones: empresa.Observaciones,
          direccion: empresa.Direccion,
          nombre: empresa.Nombre,
          tipoFacturacion: empresa.TipoFacturacion.toUpperCase(),
          telefono: empresa.Telefono,
          corre: '',
        }))
      )
    } else {
      setEmpresasFiltradas([])
    }
  }
  const manejarEmpresaSeleccionada = (empresaSeleccionada) => {
    setEmpresaSeleccionada({
      empresaPadreId: empresaSeleccionada.empresaPadreId,
      id: empresaSeleccionada.id,
      nombre: empresaSeleccionada.filtro,
      nit: empresaSeleccionada.nit,
      filtro: empresaSeleccionada.filtro,
      telefono: empresaSeleccionada.telefono,
      direccion: empresaSeleccionada.direccion,
      observaciones: empresaSeleccionada.observaciones,
      tipoFacturacion: empresaSeleccionada.tipoFacturacion.toUpperCase(),
      correo: '',
    })
    setValue('empresa', empresaSeleccionada.filtro)
  }

  const manejarCrearReporte = async (data) => {
    try {
      await llamarServicioCrearReportes(
        data?.tipoReporte,
        data?.fechaFinal,
        data?.fechaInicio,
        data?.sede,
        empresaSeleccionada?.id ? empresaSeleccionada?.id : 0
      )
      contextoApp.setModal({
        abierto: true,
        titulo: (
          <span className="font-bold">El reporte será enviado a su correo</span>
        ),
        contenido: '',
        botones: [
          {
            nombre: 'Finalizar',
            click: () => {
              history.push('/main')
              cerrarModal()
            },
          },
          {
            nombre: 'Regresar',
            click: () => {
              history.go(0)
              cerrarModal()
            },
          },
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }

  const llamarServicioCrearReportes = async (
    tipoReporte,
    fechaFinal,
    fechaInicio,
    sede,
    empresaId
  ) => {
    switch (tipoReporte) {
      case '1':
        return await GenerarReporteDetalleHC(fechaInicio, fechaFinal, empresaId)
      case '2':
        return await GenerarReporteRips(fechaInicio, fechaFinal, sede)
      case '3':
        return await GenerarReporteLaboratorio(fechaInicio, fechaFinal, sede)
      case '4':
        return await GenerarReporteDetalleAtencion(
          fechaInicio,
          fechaFinal,
          empresaId,
          sede
        )
      case '5':
        return await GenerarReporteDetalleMedioPago(
          fechaInicio,
          fechaFinal,
          sede
        )
      case '6':
        return await GenerarReporteContabilidad(fechaInicio, fechaFinal, sede)
      case '7':
        return await GenerarReporteAudiometria(
          fechaInicio,
          fechaFinal,
          empresaId
        )
      case '8':
        return await GenerarReporteSVE(fechaInicio, fechaFinal, empresaId)
    }
  }

  const TIPO_DE_REPORTES = [
    {
      Id: 1,
      Nombre: 'Detalle HC',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.FACTURADOR,
        ROLES_APP.LICITADOR,
      ],
    },
    {
      Id: 2,
      Nombre: 'Rips',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
      ],
    },
    {
      Id: 3,
      Nombre: 'Laboratorio',
      ROLES: [
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.PROFESIONAL_SST,
      ],
    },
    {
      Id: 4,
      Nombre: 'Atención detalle',
      ROLES: [
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.FACTURADOR,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.LICITADOR,
      ],
    },
    {
      Id: 5,
      Nombre: 'Detalles medios de pago',
      ROLES: [
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.FACTURADOR,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.AUXILIAR_CONTABLE,
      ],
    },
    {
      Id: 6,
      Nombre: 'Contabilidad',
      ROLES: [
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
      ],
    },
    {
      Id: 7,
      Nombre: 'Reporte audiometrías',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
      ],
    },
    {
      Id: 8,
      Nombre: 'Informe SVE',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
      ],
    },
  ]

  const ObtenerDatosMenu = () => {
    const menu = TIPO_DE_REPORTES.filter((elemento) =>
      elemento.ROLES.includes(rolUsuario)
    )
    return menu ?? []
  }

  const limpiarFormulario = () => {
    reset()
    setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
    setFechaMinima('')
    setFechaMaxima('')
  }

  const validarFechas = (valor, fechaInicio) => {
    if (fechaInicio) {
      setValue('fechaInicio', valor)
      setFechaMinima(valor)
    } else {
      setValue('fechaFinal', valor)
      setFechaMaxima(valor)
    }
    trigger()
  }

  return (
    <form
      className="w-full flex justify-center items-center flex-wrap"
      onSubmit={handleSubmit(manejarCrearReporte)}
    >
      <div className="w-full flex justify-evenly items-center">
        <Controller
          name="fechaInicio"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              estilosContenedor="w-1/4"
              estilosInput={
                Object.keys(errors).find((element) => element === 'fechaInicio')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              mostrarErrores={true}
              tipo={'date'}
              titulo={'Fecha de inicio*'}
              valor={value}
              fechaMaxima={fechaMaxima}
              onChange={(e) => validarFechas(e.target.value, true)}
            />
          )}
        />
        <Controller
          name="fechaFinal"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              estilosContenedor="w-1/4"
              estilosInput={
                Object.keys(errors).find((element) => element === 'fechaFinal')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'date'}
              titulo={'Fecha de fin*'}
              valor={value}
              fechaMinima={fechaMinima}
              onChange={(e) => validarFechas(e.target.value)}
            />
          )}
        />
        <Controller
          name="sede"
          control={control}
          rules={{
            required: sedeRequerida,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-1/4'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'sede')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : `${
                      !sedeRequerida && !sedeOpcional
                        ? 'bg-cendiatra-gris-4 pointer-events-none'
                        : ''
                    } appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
              }
              titulo={`Sede ${sedeRequerida ? '*' : ''}`}
              opciones={sedes}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-center items-center mt-10">
        <Controller
          name="empresa"
          control={control}
          rules={{
            required: empresaRequerida,
            onChange: (e) => manejarEmpresaCambiada(e),
          }}
          render={({field: {onChange}}) => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-1/4'}
              titulo={`Empresa ${empresaRequerida ? '*' : ''}`}
              estilosInput={
                Object.keys(errors).find((element) => element === 'empresa')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : `${
                      !empresaRequerida && !empresaOpcional
                        ? 'bg-cendiatra-gris-4 pointer-events-none'
                        : ''
                    } appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
              }
              tipo={'text'}
              placeholder={'Autocompletar'}
              valorDelCampoFiltro={empresaSeleccionada.filtro}
              informacionFiltrada={empresasFiltradas}
              desactivarOtroFiltro={false}
              handleChange={manejarEmpresaCambiada}
              handleOptionChange={(e) => manejarEmpresaSeleccionada(e)}
            />
          )}
        />
        <Controller
          name="tipoReporte"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-1/4 mx-10'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'tipoReporte')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Tipo de reportes*'}
              opciones={ObtenerDatosMenu()}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-center items center my-10">
        <BotonSimple
          texto={'Generar'}
          estilosBoton={`w-24 h-8 text-white ${
            isValid
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={null}
          tipoDeBoton={'submit'}
        />
        <BotonSimple
          texto={'Limpiar'}
          estilosBoton={`w-24 h-8 text-white ${
            true
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={limpiarFormulario}
        />
      </div>
    </form>
  )
}

export default FormularioGeneradorDeReportes
