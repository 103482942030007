import {getRestApi} from './ApiAxios'

//#region PUT
export const CrearEmpresaBD = async (empresa) => {
  const apiReq = await getRestApi()
  return await apiReq.put('empresa-function/CrearEmpresa', empresa)
}

export const CrearEmpresaHijaBD = async (empresaHija) => {
  const apiReq = await getRestApi()
  return await apiReq.put('empresa-function/CrearEmpresaHija', empresaHija)
}
//#endregion

//#region GET
export const ExportarEmpresas = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('empresa-function/ExportarEmpresas')
}

export const ExportarEmpresasHijas = async (idEmpresaPadre) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'empresa-function/ExportarEmpresasHijas?idPadre=' + idEmpresaPadre
  )
}

export const ValidarNit = async (nit) => {
  const apiReq = await getRestApi()
  return await apiReq.get('empresa-function/ValidarNit?nit=' + nit)
}

export const ObtenerEmpresasDB = async (fechaUltimaActualizacion) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'empresa-function/ObtenerEmpresas?fecha=' + fechaUltimaActualizacion
  )
}

export const ObtenerEmpresaPorIdDB = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get('empresa-function/ObtenerEmpresaPorId?id=' + id)
}

export const ObtenerEmpresasPadrePorNitYNombre = async (nit, nombre) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'empresa-function/ObtenerEmpresasPadrePorNitYNombre?nit=' +
      nit +
      '&nombre=' +
      nombre
  )
}
//#endregion

//#region PATCH
export const ModificarEmpresa = async (empresa) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('empresa-function/ModificarEmpresa', empresa)
}

export const ModificarEmpresaHija = async (empresaHija) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'empresa-function/ModificarEmpresaHija',
    empresaHija
  )
}
export const ObtenerEmpresasPag = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'post',
    url: 'empresa-function/ObtenerEmpresasPag',
    data: payload,
  })
}
//#endregion
