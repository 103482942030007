import React, {useState, createContext} from 'react'

export const ContextoJefeDeSede = createContext('')

export const JefeDeSedeConContexto = ({children}) => {
  const [modificarAtencion, setModificarAtencion] = useState(true)
  const [informacionUsuario, setInformacionUsuario] = useState({
    urlFoto: '',
    datosUsuario: [],
  })
  const [
    informacionExamenesParaModificar,
    setInformacionExamenesParaModificar,
  ] = useState([])
  const [comentario, setComentario] = useState('')
  const [examenesAtendidosFiltrados, setexamenesAtendidosFiltrados] = useState(
    []
  )
  return (
    <ContextoJefeDeSede.Provider
      value={{
        modificarAtencion: modificarAtencion,
        setModificarAtencion: setModificarAtencion,
        informacionExamenesParaModificar: informacionExamenesParaModificar,
        setInformacionExamenesParaModificar:
          setInformacionExamenesParaModificar,
        comentario: comentario,
        setComentario: setComentario,
        examenesAtendidosFiltrados: examenesAtendidosFiltrados,
        setexamenesAtendidosFiltrados: setexamenesAtendidosFiltrados,
        informacionUsuario:informacionUsuario,
        setInformacionUsuario:setInformacionUsuario
      }}
    >
      {children}
    </ContextoJefeDeSede.Provider>
  )
}
