import React, {useEffect, useState, useContext} from 'react'
import {ListaDesplegable} from '../../../inputs'
import {useForm, Controller} from 'react-hook-form'
import {ObtenerTurnosDeTrabajo} from '../../../../cache/servicios-cache'
import {ContextoMedicoOcupacional} from '../../../../contexto'

const DatosEmpresa = ({numeroPagina}) => {
  const [turnosDeTrabajo, setTurnosDeTrabajo] = useState([])

  const {
    register,
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    watch,
    reset,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  useEffect(async () => {
    setTurnosDeTrabajo(await ObtenerTurnosDeTrabajo())
  }, [])

  useEffect(() => {
    reset(contextoMedicoOcupacional.datosEmpresa)
    trigger()
  }, [contextoMedicoOcupacional.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      trigger()
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, contextoMedicoOcupacional.numeroPagina])

  useEffect(() => {
    const subscription = watch(() => {
      contextoMedicoOcupacional.setDatosEmpresa(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div className="w-full flex justify-center items-center">
      <Controller
        name="turnoTrabajo"
        control={control}
        rules={{
          required: false,
        }}
        render={({field: {onChange, value}}) => (
          <ListaDesplegable
            onChange={onChange}
            estilosContenedor={'w-4/12 mb-20'}
            estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
            titulo={'TURNO DE TRABAJO'}
            opciones={turnosDeTrabajo}
            valor={value}
          />
        )}
      />
    </div>
  )
}

export default DatosEmpresa
