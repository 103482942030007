import {Input} from '../../inputs'

export default function FormularioReadOnlyEmpresa({empresa}) {
  return (
    <div className="w-full grid grid-cols-5 gap-3">
      <div>
        <Input
          estilosContenedor={'w-full'}
          estilosInput={`appearance-none border-cendiatra rounded relative block w-full p-1.5 border  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white`}
          tipo={'text'}
          placeholder={''}
          titulo={'NIT*'}
          mostrarErrores={true}
          onChange={() => {}}
          valor={empresa?.nit ?? ''}
          deshabilitado={false}
          nombre={'nit'}
        />
      </div>
      <div>
        <Input
          estilosContenedor={'w-full'}
          estilosInput={`appearance-none border-cendiatra rounded relative block w-full p-1.5 border  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white`}
          tipo={'text'}
          placeholder={''}
          titulo={'Razón social*'}
          mostrarErrores={true}
          onChange={() => {}}
          valor={empresa?.nombre ?? ''}
          deshabilitado={false}
          nombre={'nombre'}
        />
      </div>
      <div>
        <Input
          estilosContenedor={'w-full'}
          estilosInput={`appearance-none border-cendiatra rounded relative block w-full p-1.5 border  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white`}
          tipo={'text'}
          placeholder={''}
          titulo={'Dirección*'}
          mostrarErrores={true}
          onChange={() => {}}
          valor={empresa?.direccion ?? ''}
          deshabilitado={false}
          nombre={'direccion'}
        />
      </div>
      <div>
        <Input
          estilosContenedor={'w-full'}
          estilosInput={`appearance-none border-cendiatra rounded relative block w-full p-1.5 border  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white`}
          tipo={'text'}
          placeholder={''}
          titulo={'Teléfono*'}
          mostrarErrores={true}
          onChange={() => {}}
          valor={empresa?.telefono ?? ''}
          deshabilitado={false}
          nombre={'telefono'}
        />
      </div>
      <div>
        <Input
          estilosContenedor={'w-full'}
          estilosInput={`appearance-none border-cendiatra rounded relative block w-full p-1.5 border  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white`}
          tipo={'text'}
          placeholder={''}
          titulo={'Correo electrónico para facturación*'}
          mostrarErrores={true}
          onChange={() => {}}
          valor={empresa?.correo ?? ''}
          deshabilitado={false}
          nombre={'correo'}
        />
      </div>
    </div>
  )
}
