import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import ConfirmacionTarifarioCreado from '../../componentes/Tarifario/ConfirmacionTarifarioCreado'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextApplication, ContextoTarifario} from '../../contexto'
import {
  CrearTarifarioBD,
  EditarTarifarioBD,
} from '../../microservicios/Tarifario'
import {obtenerCeroSiEsNullOVacio} from '../../utilidades/funcionesComunes'

import {useModal} from '../useModal'
import {useModalPreciosEnCero} from '../useModalPreciosEnCero'

export const useGuardarTarifario = (creando = true) => {
  const contextoApp = useContext(ContextApplication)
  const contextoTarifario = useContext(ContextoTarifario)
  const history = useHistory()
  const [cerrarModal] = useModal()

  const validarPrecioAEnviar = (datosPrecio, nombrePropiedad) => {
    let contexto =
      datosPrecio?.tipo === 'servicio'
        ? contextoTarifario.precioServiciosIniciales
        : contextoTarifario.preciosPaquetesIniciales

    let precioExistia = contexto[nombrePropiedad]?.find(
      (precio) => precio?.id === datosPrecio?.idPrecio
    )

    if (precioExistia && precioExistia?.fechaFinal !== null) {
      return obtenerCeroSiEsNullOVacio(datosPrecio?.nuevoPrecio)
    }

    return datosPrecio.nuevoPrecio
      ? obtenerCeroSiEsNullOVacio(datosPrecio.nuevoPrecio)
      : obtenerCeroSiEsNullOVacio(datosPrecio.precio)
  }

  const validarTipoGuardado = (id, tipoElemento) => {
    const arrayInicial = Boolean(tipoElemento === 'Servicio')
      ? contextoTarifario.serviciosIniciales
      : contextoTarifario.paquetesIniciales
    if (Boolean(arrayInicial.find((elemento) => elemento === id))) {
      return 0
    }
    return 1
  }

  const guardarTarifario = async () => {
    let payloadTarifario = {
      Id: contextoTarifario.idTarifario,
      EmpresaId: contextoTarifario.idEmpresa,
      Nombre: contextoTarifario.formularioEmpresa.nombre,
      FechaInicial: contextoTarifario.formularioEmpresa.fechaInicial,
      FechaFinal: contextoTarifario.formularioEmpresa.fechaFinal,
      PortafolioId: '',
      Servicios: contextoTarifario.listaServicios.map((servicio) => ({
        Alias: servicio.alias ? servicio.alias : null,
        ServicioId: servicio.servicio_id,
        tipoCambioSedes:
          servicio?.tipoCambioSedes ??
          validarTipoGuardado(servicio.servicio_id, 'Servicio'),
        tipoCambioAliados:
          servicio?.tipoCambioAliados ??
          validarTipoGuardado(servicio.servicio_id, 'Servicio'),
        PreciosSedes: servicio.precios_sedes.map((precioSede) => ({
          SedeId: precioSede.id.replace('servicio:', ''),
          Precio: validarPrecioAEnviar(precioSede, 'preciosSedes'),
          Id: creando ? undefined : precioSede.idPrecio,
        })),
        PreciosAliados: servicio.precios_aliados.map((precioAliado) => ({
          AliadoId: precioAliado.id.replace('aliado:', ''),
          Precio: validarPrecioAEnviar(precioAliado, 'preciosAliados'),
          Id: creando ? undefined : precioAliado.idPrecio,
        })),
      })),
      Paquetes: contextoTarifario.listaPaquetes.map((paquete) => ({
        PaqueteId: paquete.paquete_id,
        tipoCambioSedes:
          paquete?.tipoCambioSedes ??
          validarTipoGuardado(paquete.paquete_id, 'Paquete'),
        tipoCambioAliados:
          paquete?.tipoCambioAliados ??
          validarTipoGuardado(paquete.paquete_id, 'Paquete'),
        PreciosSedes: paquete.precios_sedes.map((precioSede) => ({
          SedeId: precioSede.id.replace('paquete:', ''),
          Precio: validarPrecioAEnviar(precioSede, 'preciosSedes'),
          Id: creando ? undefined : precioSede.idPrecio,
        })),
        PreciosAliados: paquete.precios_aliados.map((precioAliado) => ({
          AliadoId: precioAliado.id.replace('aliado:', ''),
          Precio: validarPrecioAEnviar(precioAliado, 'preciosAliados'),
          Id: creando ? undefined : precioAliado.idPrecio,
        })),
      })),
    }

    try {
      const peticion = creando ? CrearTarifarioBD : EditarTarifarioBD

      await peticion(payloadTarifario)

      contextoApp.setModal({
        abierto: true,
        titulo: TEXTO_VISUAL.TARIFARIO.MODAL.TARIFARIO_GUARDADO(creando).TITULO,
        contenido: (
          <ConfirmacionTarifarioCreado
            id={contextoTarifario.idTarifario}
            nombreEmpresa={contextoTarifario.formularioEmpresa.empresa}
            nombreTarifario={contextoTarifario.formularioEmpresa.nombre}
          />
        ),
        botones: [
          {
            nombre: TEXTO_VISUAL.BOTONES.FINALIZAR,
            click: () => {
              cerrarModal()
              history.push('/main')
            },
          },
          {
            nombre: creando
              ? TEXTO_VISUAL.BOTONES.CREAR_NUEVO
              : TEXTO_VISUAL.BOTONES.REGRESAR,
            click: () => {
              history.go(0)
            },
          },
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }

  const [manejarGuardar] = useModalPreciosEnCero(
    contextoTarifario,
    guardarTarifario
  )

  return [manejarGuardar]
}
