import React, {useContext} from 'react'
import {ContextoFormulario} from '../../contexto'
import {ContenedorDesplegable} from '../Contenedores'
import BaseCampo from './BaseCampo'

const BaseSeccion = ({numeroPestana, numeroSeccion}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  return (
    <div className="w-full">
      <ContenedorDesplegable
        titulo={seccion?.nombreSeccion?.toUpperCase() ?? ''}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
      >
        <div className="w-full flex flex-wrap">
          {seccion.campos.map((campo, key) => (
            <BaseCampo
              key={key}
              numeroCampo={campo.numeroCampo}
              numeroSeccion={numeroSeccion}
              numeroPestana={numeroPestana}
            />
          ))}
        </div>
      </ContenedorDesplegable>
    </div>
  )
}

export default BaseSeccion
