import React from 'react'

const FiltroListaSeleccionar = ({
  column: {filterValue, setFilter, preFilteredRows, id},
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      className="flex w-11/12 justify-evenly items-center rounded-md text-cendiatra p-5px"
      value={filterValue ?? ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">Elegir</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option ? 'SI' : 'NO'}
        </option>
      ))}
    </select>
  )
}

export default FiltroListaSeleccionar
