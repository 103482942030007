import React, {useState, createContext} from 'react'

export const ContextoServicio = createContext('')

export const ServicioContexto = ({children}) => {
  const [informacionServicio, setInformacionServicio] = useState({
    nombreServicio: '',
  })
  const [cupsInformacion, setCupsInformacion] = useState({})
  const [modoEdicion, setModoEdicion] = useState(false)
  const [actualizar, setActualizar] = useState(false)
  const [informacionSinEditar, setInformacionSinEditar] = useState({})

  return (
    <ContextoServicio.Provider
      value={{
        informacionServicio: informacionServicio,
        setInformacionServicio: setInformacionServicio,
        cupsInformacion: cupsInformacion,
        setCupsInformacion: setCupsInformacion,
        modoEdicion: modoEdicion,
        setModoEdicion: setModoEdicion,
        actualizar: actualizar,
        setActualizar: setActualizar,
        informacionSinEditar: informacionSinEditar,
        setInformacionSinEditar: setInformacionSinEditar,
      }}
    >
      {children}
    </ContextoServicio.Provider>
  )
}
