import {getRestApi} from './ApiAxios'

export const ObtenerMedico = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get('medico-function/ObtenerMedico?id=' + id)
}

export const ObtenerMedicos = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('medico-function/ObtenerMedicos')
}

export const ActualizarMedico = async (medico) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('medico-function/ActualizarMedico', medico)
}
