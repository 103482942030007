import React from 'react'
import IconoAlerta from '../../imagenes/alerta.svg'

const ErrorAudiometria = ({error, mostrarError}) => {
  return (
    <>
      {mostrarError && (
        <div className="relative">
          <div className="w-60 flex justify-center items-center bg-cendiatra-gris-5 py-2 mb-1 rounded-xl absolute bottom-0 -right-14">
            <div className="w-1/12 flex justify-center items-center mx-2">
              <img src={IconoAlerta} className={'w-5 h-5'} alt="alerta" />
            </div>
            <div className="w-11/12 pr-1">
              <span className="text-cendiatra-semaforo-rojo font-bold text-14px lowercase">
                {error}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ErrorAudiometria
