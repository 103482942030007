import React, {useEffect, useContext} from 'react'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink} from 'react-router-dom'
import {ContextApplication, ContextoEmpresa} from '../../contexto'
import {useHistory} from 'react-router-dom'
import FormularioDatosEmpresa from '../../componentes/Empresa/FormularioDatosEmpresa'
import FormularioFacturacion from '../../componentes/Empresa/FormularioFacturacion'
import FormularioAdicional from '../../componentes/Empresa/FormularioAdicional'
import ConfirmacionEmpresaCreada from '../../componentes/Empresa/ConfirmacionEmpresaCreada'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import {ObtenerProximoCodigoInterno} from '../../microservicios/Referencia'
import {ObtenerCiudadPorNombre} from '../../cache/servicios-cache/Ciudades'
import {AgregarEmpresasACache} from '../../cache/servicios-cache/Empresas'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import FormularioUsuarioB2c from '../../componentes/Empresa/FormularioUsuarioB2c'
import {AgregarUsuariosACache} from '../../cache/servicios-cache'
import {FachadaCrearEmpresa} from '../../microservicios'
import {transformarTipoFacturacion} from '../../utilidades/funcionesComunes'

const CrearEmpresa = () => {
  const contextoApp = useContext(ContextApplication)
  const contextoEmpresa = useContext(ContextoEmpresa)
  const history = useHistory()

  async function crearEmpresa() {
    let ciudadId = (
      await ObtenerCiudadPorNombre(contextoEmpresa.formularioDatos.ciudad)
    )?.Id
    const contenido = {
      codigoInterno: contextoEmpresa.codigoInternoEmpresa ?? '',
      nit: contextoEmpresa.formularioDatos.nit ?? '',
      verificacionNit: contextoEmpresa.formularioDatos.verificacionNit ?? '',
      nombre: contextoEmpresa.formularioDatos.razonSocial ?? '',
      direccion: contextoEmpresa.formularioDatos.direccion ?? '',
      ciudadId: ciudadId ?? '',
      telefono: contextoEmpresa.formularioDatos.telefono ?? '',
      correo: contextoEmpresa.formularioDatos.correo ?? '',
      nombreEmpresaHC: contextoEmpresa.formularioDatos.nombreEmpresaHC ?? '',
      estaActiva: contextoEmpresa.formularioAdicional.estaActiva ?? '',
      motivoDesactivacion:
        contextoEmpresa.formularioAdicional.motivoDesactivacion ?? '',
      ejecutivoComercial:
        contextoEmpresa.formularioAdicional.ejecutivoComercial ?? '',
      observaciones: contextoEmpresa.formularioAdicional.observaciones ?? '',
      empresaPadreId: 0,
      logoUrl: contextoEmpresa.logoEmpresa.url ?? '',
      facturacionEmpresa: {
        razonSocial: contextoEmpresa.formularioFacturacion.factura ?? '',
        rut53: contextoEmpresa.formularioFacturacion.RUT53 ?? '',
        rut54: contextoEmpresa.formularioFacturacion.RUT54 ?? '',
        codigoPostal: contextoEmpresa.formularioFacturacion.codigoPostal ?? '',
        actividadEconomica:
          contextoEmpresa.formularioFacturacion.actividadEconomica ?? '',
        matriculaMercantil:
          contextoEmpresa.formularioFacturacion.matriculaMercantil ?? '',
        correoFacturacion:
          contextoEmpresa.formularioFacturacion.correoFacturacion ?? '',
        valorContrato:
          contextoEmpresa.formularioFacturacion.valorContrato ?? '',
        condicionPago:
          contextoEmpresa.formularioFacturacion.condicionesPago ?? null,
        descuento: contextoEmpresa.formularioFacturacion.descuento
          ? JSON.stringify(contextoEmpresa.formularioFacturacion.descuento)
          : '',
        impuesto: '',
        fechaCorte: contextoEmpresa.formularioFacturacion.fechaCorte ?? '',
        tipoFacturacion:
          transformarTipoFacturacion(
            contextoEmpresa.formularioFacturacion?.tipoFacturacion
          ) ?? '',
        observaciones:
          contextoEmpresa.formularioFacturacion.observacionesFacturacion ?? '',
        nombreContacto:
          contextoEmpresa.formularioFacturacion.nombreContacto ?? '',
        correoContacto:
          contextoEmpresa.formularioFacturacion.correoContacto ?? '',
        telefonoContacto:
          contextoEmpresa.formularioFacturacion.telefonoContacto ?? '',
        condicionesPrefactura:
          contextoEmpresa.formularioFacturacion.condicionesPrefactura ?? null,
        modalidadFacturacion:
          contextoEmpresa.formularioFacturacion.modalidadFacturacion ?? null,
        prefactura: contextoEmpresa.formularioFacturacion.prefactura ?? false,
        licitacion: contextoEmpresa.formularioFacturacion.licitacion ?? false,
      },
      usuarioB2c: contextoEmpresa.formularioUsuarioB2c,
    }
    await FachadaCrearEmpresa(contenido)
      .then((res) => {
        contextoApp.setIsLoading(false)
        if (res.status === 200) {
          contextoApp.setModal({
            abierto: true,
            titulo: 'EMPRESA CREADA',
            contenido: (
              <ConfirmacionEmpresaCreada
                id={res.data}
                codigoCliente={contextoEmpresa.codigoInternoEmpresa}
                nombreEmpresa={contextoEmpresa.formularioDatos.razonSocial}
              />
            ),
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarCerrarModalConfirmacion,
              },
              {
                nombre: 'Crear Nueva',
                click: manejarCrearNueva,
              },
            ],
          })
        } else {
        }
      })
      .catch((error) => {
        contextoApp.setIsLoading(false)
        console.log(error)
      })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoApp.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  useEffect(() => {
    AgregarUsuariosACache([], true)
  }, [])

  useEffect(() => {
    if (contextoEmpresa.modoEdicion) {
      history.go(0)
    }
    contextoEmpresa.setTotalPaginas(4)
    contextoEmpresa.setNumeroPagina(1)
    async function fetchData() {
      contextoApp.setIsLoading(true)
      await AgregarEmpresasACache()
      await ObtenerProximoCodigoInterno('empresa').then((res) => {
        contextoEmpresa.setCodigoInternoEmpresa(res.data)
        contextoApp.setIsLoading(false)
      })
    }
    fetchData()
    contextoEmpresa.setformularioDatos({})
    contextoEmpresa.setformularioFacturacion({})
    contextoEmpresa.setformularioAdicional({estaActiva: true})
    contextoEmpresa.setNumeroPagina(1)
    contextoEmpresa.setActualizar(!contextoEmpresa.actualizar)
    contextoEmpresa.setformularioDatos({ciudad: '', departamento: '', pais: 1})

    return () => {
      contextoEmpresa.setformularioDatos({})
      contextoEmpresa.setformularioFacturacion({})
      contextoEmpresa.setformularioAdicional({estaActiva: true})
      contextoEmpresa.setFormularioUsuarioB2c({})
    }
  }, [])

  const manejarCrearNueva = () => {
    history.go(0)
  }

  const listaPestanas = [
    'Datos de la empresa',
    'Facturación',
    'Información adicional',
    'Crear Usuario Del Portal Cliente',
  ]

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.EJECUTIVO_COMERCIAL.CREAR_EMPRESA}
    >
      <div className="w-full flex flex-wrap justify-center my-3">
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoEmpresa}
        />
        <div className="rounded-md  -space-y-px flex justify-center flex-wrap my-3 items-center w-full">
          <div
            className={`rounded-md ${
              contextoEmpresa.numeroPagina === 1 ? '' : 'hidden'
            } -space-y-px flex justify-center flex-wrap my-3 items-center w-full`}
          >
            <FormularioDatosEmpresa numPag={1} />
          </div>
          <div
            className={`rounded-md ${
              contextoEmpresa.numeroPagina === 2 ? '' : 'hidden'
            } -space-y-px flex justify-center flex-wrap my-3 items-center w-full`}
          >
            <FormularioFacturacion numPag={2} />
          </div>
          <div
            className={`rounded-md ${
              contextoEmpresa.numeroPagina === 3 ? '' : 'hidden'
            } -space-y-px flex justify-center flex-wrap my-3 items-center w-full`}
          >
            <FormularioAdicional numPag={3} />
          </div>
          <div
            className={`rounded-md ${
              contextoEmpresa.numeroPagina === 4 ? '' : 'hidden'
            } -space-y-px flex justify-center flex-wrap my-3 items-center w-full`}
          >
            <FormularioUsuarioB2c funcionSubmit={crearEmpresa} numPag={4} />
          </div>
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default CrearEmpresa
