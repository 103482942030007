import React, {useState} from 'react'
import {ROLES_APP} from '../../constantes/roles'
import {useMsal} from '@azure/msal-react'
import {estadosExamenesConst} from '../../constantes/estadoExamen'

const LeyendaSemaforoConTooltip = ({
  colorFondo,
  texto,
  nombreTrabajador,
  redireccion,
  funcion,
  estadoServicio,
  admiteTomaDeDatos,
  ocultarNombreTrabajador = false,
}) => {
  const [abrirDescripcion, setAbrirDescripcion] = useState(false)

  const {accounts} = useMsal()
  const rolUsuario = accounts[0].idTokenClaims.roles[0]

  const validarAccesoAExamen = (redireccion, estadoServicio) => {
    const esAuxiliarEnfermeria = rolUsuario === ROLES_APP.AUXILIAR_ENFERMERIA
    const examenPendientePorAtender =
      estadoServicio === estadosExamenesConst.PENDIENTE_POR_ATENDER ||
      estadoServicio === estadosExamenesConst.LLAMANDO ||
      estadoServicio === estadosExamenesConst.EN_PROCESO
    const examenCerrado = estadoServicio === estadosExamenesConst.CERRADO

    if (!esAuxiliarEnfermeria && redireccion && !examenCerrado) {
      return true
    } else {
      if (
        admiteTomaDeDatos &&
        esAuxiliarEnfermeria &&
        examenPendientePorAtender &&
        redireccion
      ) {
        return true
      }

      if (!admiteTomaDeDatos && redireccion && !examenCerrado) {
        return true
      }
      return false
    }
  }

  return (
    <div className="w-full flex flex-wrap  justify-center items-center relative bg-white">
      <div
        className="w-full flex flex-wrap justify-center items-center my-2.5px relative"
        onMouseEnter={() => setAbrirDescripcion(true)}
        onMouseLeave={() => setAbrirDescripcion(false)}
      >
        <div className="w-1/12 justify-center items-center">
          <div className={` ${colorFondo}`}></div>
        </div>
        <div className="w-11/12 flex  justify-center items-center leading-15px z-40">
          <span
            className={`w-full whitespace-nowrap text-left text-13px  truncate ${
              validarAccesoAExamen(redireccion, estadoServicio)
                ? 'underline text-cendiatra cursor-pointer'
                : 'text-cendiatra-gris-1 pointer-events-none'
            }`}
            onClick={redireccion && estadoServicio !== 4 ? funcion : null}
          >
            {texto}
          </span>
        </div>
      </div>
      <div className="w-1/12 justify-center items-center"></div>
      <div className="w-11/12 flex flex-wrap justify-center items-center leading-15px">
        <span className="w-full justify-start items-center text-left text-13px text-cendiatra-verde-4">
          {!ocultarNombreTrabajador ? nombreTrabajador : ''}
        </span>
      </div>
      <div className="w-full flex justify-start items-center">
        <div
          className={
            abrirDescripcion
              ? 'w-auto flex flex-wrap justify-start items-center text-sm bg-cendiatra text-gray-100 px-1 absolute rounded  shadow-xl top-2 mt-3 z-50'
              : 'hidden'
          }
        >
          <p>
            <span className="text-center text-md z-50">{texto}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LeyendaSemaforoConTooltip
