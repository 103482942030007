import React, {useEffect, useContext} from 'react'
import {Input, ErroresInput} from '../inputs'
import {Controller, useForm} from 'react-hook-form'
import {regexValidarNit, regexNombreEmpresaHC} from '../../constantes'
import {BotonSimple} from '../Botones'
import {GuardarDatosUnicaFacturacion} from '../../microservicios'
import {ContextApplication} from '../../contexto'

const FormularioConfirmacionDeDatos = ({
  valorFactura,
  funcionCerrar,
  refrescarDatos,
}) => {
  const {
    control,
    getValues,
    trigger,
    formState: {errors},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const contextoAplicacion = useContext(ContextApplication)

  const sedeId = JSON.parse(sessionStorage.getItem('sedeId'))

  const objetoVacio = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }
  const formularioTieneErrores = objetoVacio(errors)

  useEffect(() => {
    trigger()
  }, [])

  const guardarDatosFacturacion = async () => {
    const valoresFormulario = getValues()
    const payload = {
      nit: valoresFormulario.documento,
      razonSocial: valoresFormulario.razonSocial,
      pagoId: valorFactura?.id,
      numeroFactura: valoresFormulario.factura,
      sedeId: sedeId,
    }

    try {
      const respuesta = await GuardarDatosUnicaFacturacion(payload)
      if (respuesta.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: '',
          contenido: (
            <div className="w-full flex justify-center items-center text-center flex-wrap">
              <span className="w-full font-bold text-3xl mb-12 normal-case">
                Guardado exitoso
              </span>
              <span className="w-full normal-case text-xl">
                La confirmación del pago fue guardada correctamente
              </span>
            </div>
          ),

          botones: [
            {
              nombre: 'Cerrar',
              click: () => funcionCerrar(),
            },
          ],
        })
        return refrescarDatos()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap -mt-10">
      <div className="w-full flex items-center justify-center text-2xl text-center font-bold normal-case">
        Datos de la facturación
      </div>
      <div className="w-full flex jusitfy-center items-center border rounded-2xl flex-wrap p-4 mt-10 text-center">
        <div className="w-full flex justify-evenly items-center mt-5 flex-wrap">
          <Controller
            name="factura"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}}) => (
              <div className="w-full flex items-center justify-center flex-wrap">
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full text-left my-2'}
                  estilosInput={`appearance-none rounded relative block w-full  p-1.5 border ${
                    Object.keys(errors).find((element) => element === 'factura')
                      ? 'border-cendiatra-rojo-1'
                      : 'border-cendiatra'
                  } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
                  tipo={'text'}
                  placeholder={'Descripción'}
                  titulo={'Número de factura*'}
                  valor={value}
                />
              </div>
            )}
          />
          <Controller
            name="razonSocial"
            control={control}
            rules={{
              required: true,
              pattern: regexNombreEmpresaHC,
            }}
            render={({field: {value, onChange}}) => (
              <Input
                onChange={onChange}
                estilosContenedor={'w-full text-left my-2'}
                estilosInput={`appearance-none rounded relative block w-full  p-1.5 border ${
                  Object.keys(errors).find(
                    (element) => element === 'razonSocial'
                  )
                    ? 'border-cendiatra-rojo-1'
                    : 'border-cendiatra'
                } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Razón social o nombre del paciente*'}
                valor={value}
              />
            )}
          />

          <Controller
            name="documento"
            control={control}
            rules={{
              required: true,
              pattern: regexValidarNit,
            }}
            render={({field: {value, onChange}}) => (
              <Input
                onChange={onChange}
                estilosContenedor={'w-full text-left my-2'}
                estilosInput={`appearance-none rounded relative block w-full  p-1.5 border ${
                  Object.keys(errors).find((element) => element === 'documento')
                    ? 'border-cendiatra-rojo-1'
                    : 'border-cendiatra'
                } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'NIT o documento del paciente*'}
                valor={value}
              />
            )}
          />
        </div>
        <div className="w-full flex items-center justify-evenly mt-10">
          <BotonSimple
            texto={'Regresar'}
            estilosBoton={`hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra`}
            funcion={() => funcionCerrar()}
            deshabilitado={false}
          />
          <BotonSimple
            texto={'Guardar'}
            estilosBoton={`hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center ${
              !formularioTieneErrores
                ? 'bg-cendiatra-gris-2 pointer-events-none'
                : 'bg-btnBg'
            } bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra`}
            funcion={() => guardarDatosFacturacion()}
            deshabilitado={!formularioTieneErrores}
          />
        </div>
      </div>
    </div>
  )
}
export default FormularioConfirmacionDeDatos
