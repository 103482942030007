import React, {useContext, useEffect} from 'react'
import {ContextoFormulario, ContextoPlantilla} from '../../../contexto'
import userImg from '../../../imagenes/usuarioImg.png'
import BaseFormulario from '../../Formularios/BaseFormulario.jsx'

const PreVisualizacion = ({numeroPagina}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const contextoPlantilla = useContext(ContextoPlantilla)

  useEffect(() => {
    contextoFormulario.setNumeroPagina(1)
    contextoFormulario.setInformacionUsuario({
      urlFoto: userImg,
      datosUsuario: [
        {titulo: 'Nombre'},
        {titulo: 'Documento'},
        {titulo: 'Fecha primera atención'},
        {titulo: 'Género'},
        {titulo: 'Fecha de nacimiento'},
        {titulo: 'Edad'},
        {titulo: 'Lugar nacimiento'},
        {titulo: 'Estrato'},
        {titulo: 'Escolaridad'},
        {titulo: 'Estado civil'},
        {titulo: 'Nacionalidad'},
        {titulo: 'Tipo de examen'},
        {titulo: 'Empresa'},
        {titulo: 'Cargo'},
        {titulo: 'Actividad económica'},
      ],
    })
    contextoFormulario.setHabilitarEdicion(true)
    return () => contextoFormulario.setHabilitarEdicion(false)
  }, [])

  useEffect(() => {
    contextoFormulario.setVistaPrevia(true)
    return () => contextoFormulario.setVistaPrevia(false)
  }, [])

  useEffect(() => {
    if (numeroPagina === contextoPlantilla.numeroPagina) {
      contextoFormulario.setPlantilla(contextoPlantilla.plantilla)
      contextoFormulario.setTotalPaginas(
        contextoPlantilla.plantilla.pestanas.length
      )
    }
  }, [contextoPlantilla.numeroPagina])

  return (
    <div className="w-full mb-20">
      <BaseFormulario />
    </div>
  )
}

export default PreVisualizacion
