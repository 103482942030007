import React, {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Boton from '../../componentes/Botones/Boton'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextoNavegacion} from '../../contexto'

const TEXTO_PANTALLA = TEXTO_VISUAL.ACCESO_DENEGADO

export const AccesoDenegado = () => {
  const contextoNavegacion = useContext(ContextoNavegacion)
  const history = useHistory()

  useEffect(() => {
    contextoNavegacion.setMostrar(false)

    return () => {
      contextoNavegacion.setMostrar(true)
    }
  }, [])

  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div className="flex flex-col w-2/3 border border-cendiatra-gris-3 p-4 rounded-md">
        <h1 className="text-cendiatra-verde-2 text-lg font-bold">
          {TEXTO_PANTALLA.TITULO}
        </h1>
        <p>{TEXTO_PANTALLA.MENSAJE}</p>
        <Boton
          ancho="w-28 place-self-center"
          titulo={TEXTO_VISUAL.BOTONES.IR_INICIO}
          funcionCLick={() => history.replace('/')}
          habilitado
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
        />
      </div>
    </div>
  )
}
