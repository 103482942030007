export const campoDeConfiguracionDeComponentes = {
  COMPONENTE: true,
}

export const nombresDeComponentes = {
  AUDIOMETRIATAMIZ: 'AUDIOMETRÍA TAMÍZ',
}

export const nombresDeTiposDeCamposConstantes = {
  BOOLEAN: 'BOOLEANO (SI/NO)',
  COMPONENTE: 'COMPONENTE',
  NUMERICO: 'NÚMERICO',
  FORMULA: 'FORMULA',
}
