export const SpanNombresSegunTipoPrecios = ({titulo, precios = []}) => {
  if (
    typeof titulo === 'string' &&
    titulo.trim().length > 0 &&
    Array.isArray(precios) &&
    precios.length > 0
  ) {
    return (
      <span>
        {titulo} ({precios.map((p) => p.nombre).join(', ')})
      </span>
    )
  }

  return null
}
