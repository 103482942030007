import React from 'react'
import {LeyendaSemaforoConCheckbox} from '../leyendas'

const LaboratoriosPaciente = ({
  datos,
  funcionCheckbox,
  valorSeleccionarTodo,
}) => {
  const establecerColor = (estadoExamen) => {
    switch (estadoExamen) {
      case 1:
        return 'bg-cendiatra-semaforo-rojo'
      case 2:
        return 'bg-cendiatra-semaforo-naranja'
      case 3:
        return 'bg-cendiatra-semaforo-amarillo'
      case 4:
        return 'bg-cendiatra-semaforo-verde'
      default:
        return 'bg-cendiatra-semaforo-naranja'
    }
  }
  return (
    <div className="w-full h-90% py-1 border border-cendiatra-verde-4 rounded-lg ">
      <div className="w-full h-full overflow-scroll overflow-x-hidden px-1">
        <span className="text-cendiatra-semaforo-rojo text-13px">
          Seleccione mínimo un laboratorio para imprimir el sticker
        </span>
        <div className="w-full flex justify-start items-center text-cendiatra my-5">
          <span className="ml-2 mr-1">SELECCIONAR TODO</span>
          <input
            type="checkbox"
            className="checkboxEspecial personalizado -mt-2"
            onChange={() => funcionCheckbox('', true)}
            checked={valorSeleccionarTodo}
            readOnly
          />
        </div>
        <div className="w-full flex items-center flex-wrap justify-start">
          {datos.map((examen) => (
            <div className="w-1/3 flex justify-start items-center bg-white mb-2">
              <LeyendaSemaforoConCheckbox
                colorFondo={`${establecerColor(examen.estadoExamen)}`}
                texto={examen.tipoExamen}
                id={examen.servicioAtencionId}
                seleccionado={examen.seleccionado}
                funcionCheckbox={funcionCheckbox}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LaboratoriosPaciente
