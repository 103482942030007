import React, {useContext, useMemo} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {InputRegex} from '../../inputs'

const CampoTextoCorto = ({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const valorCampo =
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  const manejarCambioValor = (valor) => {
    if (readOnly) return

    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: valor,
        },
      },
    })
  }

  return (
    <div className="w-full flex flex-wrap">
      <InputRegex
        ancho={'w-full flex'}
        estilosContenedor={'w-full flex'}
        estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        tipo={'text'}
        titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
        placeholder={campo.atributos?.textoPredeterminado ?? ''}
        requerido={campo.requerido}
        cambioValor={manejarCambioValor}
        valor={valorCampo}
        readOnly={readOnly}
      />
      {campo.atributos?.unidadMedida ? (
        <div className="flex flex-wrap w-full text-11px text-start">
          <div className="w-full flex flex-wrap">
            <label className="flex  text-cendiatra font-bold">
              UNIDAD DE MEDIDA:
            </label>
            <label className="flex  text-cendiatra ml-1">
              {campo.atributos?.unidadMedida}
            </label>
          </div>
          <div className="w-full flex flex-wrap">
            <label className="flex  text-cendiatra  font-bold">
              VALOR DE REFERENCIA:
            </label>
            <label className="flex  text-cendiatra ml-1">
              {campo.atributos?.valorReferencia}
            </label>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CampoTextoCorto
