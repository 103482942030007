import React, {useContext} from 'react'
import {ContextoPlantilla} from '../../../contexto'
import {AreaDeTexto, InputRegex} from '../../inputs'

import moment from 'moment'

const ParametrosHistoria = ({numeroPagina}) => {
  const contextoPlantilla = useContext(ContextoPlantilla)

  const actualizarCampoPlantilla = (campo, valor) => {
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      [campo]: valor,
    })
  }

  return (
    <div className="w-full">
      <div className="w-full flex flex-wrap justify-center">
        <div className="flex flex-wrap w-1/2 mt-20">
          <AreaDeTexto
            estilosContenedor={'w-full'}
            estilosAreaDeTexto={`h-20 my-3 rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
            placeholder={
              'Acepto las anteriores valoraciones Médicas realizadas, hago constar que la información suministrada sobre mi condición de salud es verdadera, y me comprometo a cumplir las recomendaciones y las restricciones a que haya lugar. Cualquier omisión o falsedad será causa justa de finalización de contrato. Artículo 62 CST. La firma de este documento no significa aceptación de los conceptos médicos expresados y de su contenido. Sirve adicionalmente para constatar quien presento la valoración.'
            }
            valor={contextoPlantilla.plantilla.consentimientoHistoria}
            cambioValor={(e) => {
              actualizarCampoPlantilla('consentimientoHistoria', e.target.value)
            }}
            titulo={'CONSENTIMIENTO DE LA HISTORIA'}
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-center">
        <span className="  text-center text-14px leading-23px font-bold text-cendiatra my-25px">
          CODIFICACIÓN DEL FORMATO
        </span>
      </div>
      <div className="w-full flex flex-wrap justify-center">
        <div className="flex flex-wrap mt-10">
          <InputRegex
            id="codigoHistoria"
            ancho={'w-1/3'}
            cambioValor={(e) => {
              actualizarCampoPlantilla('codigoHistoria', e)
            }}
            estilosContenedor={'w-full flex pr-5'}
            estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
            tipo={'text'}
            placeholder={''}
            titulo={'CÓDIGO'}
            valor={contextoPlantilla.plantilla.codigoHistoria}
            contexto={contextoPlantilla}
            numeroPagina={numeroPagina}
            requerido={true}
          />
          <InputRegex
            id="fechaHistoria"
            ancho={'w-1/3'}
            cambioValor={(e) => {
              actualizarCampoPlantilla(
                'fechaHistoria',
                e ? moment(e).format('YYYY-MM-DD') : ''
              )
            }}
            estilosContenedor={'w-full flex pr-5'}
            estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
            tipo={'date'}
            placeholder={''}
            titulo={'FECHA'}
            valor={contextoPlantilla.plantilla.fechaHistoria}
            contexto={contextoPlantilla}
            numeroPagina={numeroPagina}
            requerido={true}
          />
          <InputRegex
            id="versionHistoria"
            ancho={'w-1/3'}
            cambioValor={(e) => {
              actualizarCampoPlantilla('versionHistoria', e)
            }}
            estilosContenedor={'w-full flex pr-5'}
            estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
            tipo={'text'}
            placeholder={''}
            titulo={'VERSIÓN'}
            valor={contextoPlantilla.plantilla.versionHistoria}
            contexto={contextoPlantilla}
            numeroPagina={numeroPagina}
            requerido={true}
          />
        </div>
      </div>
    </div>
  )
}

export default ParametrosHistoria
