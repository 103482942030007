import React, {useRef, useContext} from 'react'
import moment from 'moment'
import Webcam from 'react-webcam'
import {SubirArchivo} from '../../microservicios'
import {ContextApplication} from '../../contexto'
import {BotonPerifericos} from '../Botones'
import {InputCargarArchivo} from '../inputs'
import ClipImg from '../../imagenes/clip.svg'
import Borrador from '../../imagenes/borrador.png'

const videoConstraints = {
  width: 240,
  height: 224,
  facingMode: 'user',
}

const CamaraWeb = ({
  contexto,
  funcion,
  nombreArchivo,
  modificar,
  funcionCargarImagen,
}) => {
  const contextoAplicacion = useContext(ContextApplication)
  const webcamRef = React.useRef(null)

  const refParaCargarImagen = useRef(null)

  const capture = React.useCallback(() => {
    const dataURLtoBlob = (dataurl) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {type: mime})
    }
    const screenshot = webcamRef.current.getScreenshot()
    const blob = dataURLtoBlob(screenshot)
    const fecha = moment().unix()
    let formData = new FormData()
    formData.append('Archivo', blob, nombreArchivo + fecha + '.webp')
    SubirArchivo(nombreArchivo + fecha + '.webp', formData, 'paciente')
      .then((res) => {
        contexto.setFoto(res.data)
      })
      .catch((error) => {
        console.log(error, 'ERROR AL TOMAR LA FOTO')
      })
  }, [webcamRef, nombreArchivo])

  const AbrirCargarImagen = (e) => {
    refParaCargarImagen.current.querySelector('input').click()
  }
  return (
    <>
      <div
        className={`w-60 h-56 flex flex-wrap justify-center items-center border-2 ${
          contexto.foto == ''
            ? 'border-cendiatra-semaforo-rojo'
            : 'border-cendiatra'
        } rounded-custom mb-20`}
      >
        {contexto.foto != '' ? (
          <img
            className="w-60 h-219"
            src={contexto.foto}
            alt="fotoPaciente"
            style={{borderRadius: '28px'}}
          />
        ) : (
          <Webcam
            style={{borderRadius: '28px'}}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/webp"
            videoConstraints={videoConstraints}
          />
        )}
      </div>
      {modificar ? (
        <div className="w-full flex flex-wrap justify-center items-center -mt-20">
          <div className="w-60 flex justify-start items-center my-2 ">
            <span className="text-13 text-cendiatra">Tamaño maximo 2Mb</span>
          </div>
          <div className="w-full flex items-center justify-center my-5 ">
            <button
              className="w-full flex justify-center items-center "
              onClick={(e) => AbrirCargarImagen(e)}
            >
              <img className="h-6" src={ClipImg} alt="borrador" />
              <span className="text-13 text-cendiatra ml-2">Adjuntar</span>
            </button>
            <div ref={refParaCargarImagen}>
              <InputCargarArchivo
                funcion={funcionCargarImagen}
                estilosInput={'hidden'}
              />
            </div>
            <div className="border h-6 border-cendiatra-gris-4"></div>
            <button
              className="w-full flex justify-center items-center"
              onClick={funcion}
            >
              <img className="h-6" src={Borrador} alt="borrador" />
              <span className="text-13 text-cendiatra ml-2">Borrar</span>
            </button>
          </div>

          <button
            className={`${
              contexto.foto != ''
                ? 'bg-cendiatra-gris-placeholder'
                : 'bg-cendiatra'
            } text-white px-1.5 py-3 rounded-lg text-17px mt-2`}
            onClick={capture}
            disabled={contexto.foto != ''}
          >
            CAPTURAR FOTO
          </button>
        </div>
      ) : (
        <BotonPerifericos
          textoBoton={'CAPTURAR FOTO'}
          funcionCapturar={capture}
          funcionBorrar={funcion}
          deshabilitado={contexto.foto != ''}
        />
      )}
    </>
  )
}

export default CamaraWeb
