import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {ContenedorTituloResaltado} from '../../componentes/Contenedores'
import {
  AlertaDinamica,
  CuadroInformacion,
} from '../../componentes/MostrarInformacion'
import {TablaDinamica} from '../../componentes'
import {EncabezadoFondo} from '../../componentes/Tablas'
import {
  InputFiltroConBotones,
  InputCargarMultiplesArchivos,
  AreaDeTexto,
  ListaDesplegable,
} from '../inputs'
import {Boton} from '../Botones'
import {ContextoNotaCredito, ContextApplication} from '../../contexto'
import {ObtenerFactura} from '../../microservicios'
import {
  ObtenerServicioPorId,
  AgregarServiciosACache,
  ObtenerPaquetePorId,
  AgregarPaquetesACache,
  ObtenerSedePorId,
  ObtenerCausasNotaCredito,
} from '../../cache/servicios-cache'
import {ESTADOS_FACTURA, TEXTO_VISUAL} from '../../constantes'

const FormularioCrearNotaCredito = ({funcionGuardar}) => {
  const contextoNotaCredito = useContext(ContextoNotaCredito)

  const contextoAplicacion = useContext(ContextApplication)
  const [mostrarInformacionFactura, setMostrarInformacionFactura] =
    useState(false)
  const [datosTabla, setDatosTabla] = useState([])

  const [datosUsuario, setDatosUsuario] = useState([])
  const [estadoFacturaId, setEstadoFacturaId] = useState(0)
  const [causasNotaCredito, setCausasNotaCredito] = useState([])

  useEffect(() => {
    obtenerDatosLista()
  }, [])

  const estadosFacturaInvertido = useMemo(() => {
    const init = {}
    for (const key in ESTADOS_FACTURA) {
      init[ESTADOS_FACTURA[key]] = key.replace(/_/g, ' ')
    }

    return init
  }, [])

  const puedeGenerarNotaCredito = useMemo(() => {
    return (
      estadoFacturaId > 0 &&
      estadoFacturaId === ESTADOS_FACTURA.DOCUMENTO_GENERADO
    )
  }, [estadoFacturaId])

  const obtenerDatosLista = useCallback(async () => {
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    setCausasNotaCredito(await ObtenerCausasNotaCredito())
  }, [])

  const columnasServicios = React.useMemo(() => [
    {
      Header: '',
      accessor: 'id',
      className:
        'w-1/12 flex text-center justify-center items-center content-center  h-16 text-14px ',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'CODIGO DEL SERVICIO Y/O PAQUETE'} />
      ),
      accessor: 'codigoServicio',
      className:
        'w-2/12 flex text-center justify-center items-center content-center  h-16 text-14px',
      headerStyle:
        'w-2/12 bg-cendiatra-verde-7 text-white ml-0.5 rounded-l-md h-14 text-white',
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'NOMBRE DEL SERVICIO Y/O PAQUETE'} />
      ),
      accessor: 'nombreServicio',
      className:
        'w-2/12 flex text-center justify-center items-center content-center h-16 text-14px',
      headerStyle: 'w-2/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'CANTIDAD'} />,
      accessor: 'cantidad',
      className:
        'w-2/12 flex text-center justify-center items-center content-center  h-16 text-14px',
      headerStyle: 'w-2/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'VALOR'} />,
      accessor: 'valor',
      className:
        'w-2/12 flex text-center justify-center items-center content-center h-16 text-14px',
      headerStyle: 'w-2/12 bg-cendiatra-verde-7 text-white h-14',
    },
  ])

  const aplicarFormatoDinero = (numero) => {
    const numeroFormateado = numero.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
    })
    return numeroFormateado
  }

  const buscarNumeroFactura = (numeroFactura) => {
    const pattern = /([a-zA-Z]+)-?([0-9]+)/
    const resultado = numeroFactura.match(pattern)

    if (resultado) {
      const prefijo = resultado[1]
      const consecutivo = resultado[2]

      ObtenerFactura(prefijo, consecutivo).then(async (res) => {
        if (res.status === 200) {
          const datosParaMapear = {
            'Fecha De Factura': res.data?.fecha ?? '',
            'Nit o Número De Documento': res.data?.identificacion ?? '',
            'Valor Total Factura': res.data?.valorTotal ?? '',
            'Nombre De Factura':
              res.data?.prefijo + res.data?.consecutivo ?? '',
            'Nombre Del Tercero': res.data?.nombreTercero ?? '',
            Sede: (await ObtenerSedePorId(res.data?.sedeId))?.Nombre ?? '',
            'Realizado Por': res.data?.responsable ?? '',
          }
          setEstadoFacturaId(res?.data?.estadoFacturaId ?? 0)
          setDatosUsuario(
            Object.keys(datosParaMapear).map((elemento) => {
              return {
                titulo: elemento,
                contenido: datosParaMapear[elemento],
              }
            })
          )

          setDatosTabla(
            await Promise.all(
              res.data.items.map(async (examen) => {
                const informacionExamen = examen.esPaquete
                  ? (await ObtenerPaquetePorId(examen?.id)) ?? ''
                  : (await ObtenerServicioPorId(examen?.id)) ?? ''
                return {
                  id: examen?.id ?? 0,
                  codigoServicio: examen.esPaquete
                    ? informacionExamen?.CodigoInterno ?? ''
                    : informacionExamen?.CodigoCompleto ?? '',
                  nombreServicio: informacionExamen?.Nombre ?? '',
                  cantidad: examen?.cantidad ?? '',
                  valor: examen?.valor
                    ? aplicarFormatoDinero(examen?.valor)
                    : '' ?? '',
                }
              })
            )
          )
          contextoNotaCredito.setInformacionFactura({
            numeroFactura: numeroFactura,
            idFactura: res.data?.id,
          })
          setMostrarInformacionFactura(true)
          contextoNotaCredito.setArregloArchivos([])
        } else {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'No existe una factura con este número',
            contenido: '',
            botones: [
              {
                nombre: 'Regresar',
                click: funcionCerrarModal,
              },
            ],
          })
        }
      })
    } else {
      contextoAplicacion.setModal({
        abierto: true,
        titulo:
          'Las facturas se componen primero de un prefijo (letras) y luego de un consecutivo (números)',
        contenido: '',
        botones: [
          {
            nombre: 'Regresar',
            click: funcionCerrarModal,
          },
        ],
      })
    }
  }
  const funcionCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const limpiarBusquedaFactura = () => {
    setMostrarInformacionFactura(false)
    contextoNotaCredito.setArregloArchivos([])
  }

  const manejarCambiosEnFormulario = (nombreElemento, valor) => {
    contextoNotaCredito.setDatosFormulario({
      ...contextoNotaCredito.datosFormulario,
      [nombreElemento]: valor,
    })
  }

  return (
    <>
      <ContenedorPagina tituloPagina={TEXTO_VISUAL.CREAR_NOTA_CREDITO.TITULO}>
        <div className="w-10/12 flex justify-center items-center ml-20">
          <InputFiltroConBotones
            titulo={'Número de factura'}
            funcionBuscar={buscarNumeroFactura}
            funcionLimpiar={limpiarBusquedaFactura}
          />
        </div>

        <ContenedorTituloResaltado
          titulo={'INFORMACIÓN DE LA FACTURA'}
          paddingContenedor={'bg-white px-5 mb-2'}
          margenContenedor={'mb-10 h-full'}
        >
          <div
            className="w-full flex flex-wrap justify-center items-center"
            style={{minHeight: '400px', minWidth: '700px'}}
          >
            <div
              className={` ${
                mostrarInformacionFactura ? 'flex' : 'hidden'
              } justify-center items-center flex-wrap`}
            >
              <CuadroInformacion
                estilosContenedor={
                  'w-full my-5 flex flex-wrap justify-center items-center  '
                }
                titulo={''}
                datos={datosUsuario}
              />
              <div className="w-full py-4 pb-10">
                <AlertaDinamica
                  mostrarAlerta={
                    estadoFacturaId > 0 &&
                    estadoFacturaId !== ESTADOS_FACTURA.DOCUMENTO_GENERADO
                  }
                  mensaje={`No puede crear la nota crédito. La factura tiene el estado: ${estadosFacturaInvertido[estadoFacturaId]}`}
                  background="bg-gray-300"
                  color='text-red-800'
                />
              </div>
              <TablaDinamica columns={columnasServicios} data={datosTabla} />
              <div className="w-full flex justify-center items-start my-10">
                <div className="w-1/2 flex justify-center items-center">
                  <ListaDesplegable
                    opciones={causasNotaCredito}
                    estilosLista={`
                      'appearance-none rounded relative block w-full  p-1.5 border ${
                        puedeGenerarNotaCredito
                          ? contextoNotaCredito.datosFormulario.causa
                            ? 'border-cendiatra'
                            : 'border-cendiatra-rojo-1'
                          : 'bg-cendiatra-gris-placeholder text-white placeholder-white'
                      } border-cendiatra ${
                      puedeGenerarNotaCredito ? 'text-cendiatra-gris-3' : ''
                    } rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
                    estilosContenedor={'w-8/12'}
                    onChange={(valor) =>
                      manejarCambiosEnFormulario('causa', valor.target.value)
                    }
                    titulo={'Causa de Anulación*'}
                    mostrarTextBlanco={!puedeGenerarNotaCredito}
                    deshabilitado={!puedeGenerarNotaCredito}
                    valor={contextoNotaCredito.datosFormulario.causa}
                  />
                </div>

                <div className="w-1/2 flex justify-center items-center">
                  <AreaDeTexto
                    estilosContenedor={`w-full`}
                    estilosAreaDeTexto={`h-20 ${
                      !puedeGenerarNotaCredito
                        ? 'bg-cendiatra-gris-placeholder text-white placeholder-white'
                        : ''
                    } rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
                    titulo={'Nota aclaratoria'}
                    placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
                    cambioValor={(valor) =>
                      manejarCambiosEnFormulario('nota', valor.target.value)
                    }
                    valor={contextoNotaCredito.datosFormulario.nota}
                    deshabilitado={!puedeGenerarNotaCredito}
                  />
                </div>
              </div>
              <div className="w-full flex justify-center items-start mb-20">
                <div className="w-1/2 flex justify-center items-center">
                  <div className="w-8/12 flex justify-start items-center flex-wrap text-start">
                    <InputCargarMultiplesArchivos
                      editable={puedeGenerarNotaCredito}
                      tamanoMaximoArchivo={2}
                      nombreLabel={'Anexo para soporte'}
                      TamanoMaximo={'2Mb'}
                      nombreArchivoServicio={''}
                      archivoRequerido={false}
                      contexto={contextoNotaCredito}
                    />
                  </div>
                </div>
                <div className="w-1/2 flex justify-center items-center"></div>
              </div>
              <div className="w-full flex items-center justify-center mb-10">
                <Boton
                  titulo={'Guardar'}
                  funcionCLick={funcionGuardar}
                  habilitado={
                    contextoNotaCredito.datosFormulario.causa &&
                    puedeGenerarNotaCredito
                  }
                  colorPorDefecto={'bg-grayColor'}
                  colorActivo={'bg-btnBg'}
                  tipo={'submit'}
                />
              </div>
            </div>
          </div>
        </ContenedorTituloResaltado>
      </ContenedorPagina>
    </>
  )
}

export default FormularioCrearNotaCredito
