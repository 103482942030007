import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {InputSubirArchivo} from '../../inputs'

import path from 'path'

const CampoArchivo = ({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const [nombreArchivo, setNombreArchivo] = useState('')

  const valor = contextoFormulario.valoresCampos?.[accesoPestana]?.[
    accesoSeccion
  ]?.[campo?.tituloCampo]
    ? path.basename(
        contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
          campo?.tituloCampo
        ]
      )
    : ''

  useEffect(() => {
    setNombreArchivo(valor)
  }, [valor])

  const manejarCambioArchivo = (nombreArchivo, datosArchivo) => {
    if (readOnly) return

    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: datosArchivo,
        },
      },
    })
  }

  return (
    <InputSubirArchivo
      editable={true}
      tamanoMaximoArchivo={2}
      nombreLabel={campo?.tituloCampo?.toUpperCase() ?? ''}
      TamanoMaximo={'2MB'}
      nombreArchivoServicio={nombreArchivo}
      archivoRequerido={campo.requerido && !valor}
      archivoLimpiado={() => {
        if (readOnly) return

        setNombreArchivo('')
        manejarCambioArchivo('', '')
      }}
      obtenerUrlArchivo={manejarCambioArchivo}
      resetearAlRecibirNombreArchivoVacio={true}
      nombreEnlace={`archivo-${(campo?.tituloCampo ?? '').replace(/ /g, '')}`}
      readOnly={readOnly}
    />
  )
}

export default CampoArchivo
