import React, {useState, useEffect, useContext, useCallback} from 'react'
import {ContextApplication, ContextoEmpresa} from '../../contexto'
import {
  ObtenerCiudadPorId,
  ObtenerTodasCiudades,
  ObtenerDepartamentoPorId,
  ObtenerTodosDepartamentos,
  AgregarEmpresasACache,
  ObtenerEmpresasHijasPorCodigoInternoONombre,
  ObtenerEmpresasHijasPorIdPadre,
} from '../../cache/servicios-cache'
import {
  ObtenerEmpresaPorIdDB,
  ExportarEmpresasHijas,
} from '../../microservicios'
import {BotonesFormularioPasos, ExportarCSV} from '../Botones'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import moment from 'moment'
import {encabezadosExportarEmpresasHijas} from '../CSV/EncabezadosArchivos'
import {validarTipoFacturacion} from '../../utilidades'
import {UseObtenerRolValido} from '../../utilidades/funcionesComunes'

const SeleccionEmpresaHija = ({numPag}) => {
  const cambiarTextoColumnaPorRol = UseObtenerRolValido()
  const columnasTablaEmpresasHijas = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
      disableFilters: true,
    },
    {
      Header: () => <EncabezadoFondo titulo={'NIT'} paddingFondoClaro={''} />,
      accessor: 'nit',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Razón Social'} paddingFondoClaro={''} />
      ),
      accessor: 'razonSocial',
      className: 'w-3/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Activa o Inactiva'} paddingFondoClaro={''} />
      ),
      accessor: 'activa',
      className: 'w-3/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Ejecutivo Comercial'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'ejecutivoComercial',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
  ])

  const contextoApp = useContext(ContextApplication)
  const contextoEmpresa = useContext(ContextoEmpresa)

  const obtenerInformacionInicial = useCallback(async (idEmpresaPadre) => {
    await AgregarEmpresasACache()
    let empresas = await ObtenerEmpresasHijasPorIdPadre(idEmpresaPadre)
    setListaEmpresasHijas(empresas)
  }, [])

  useEffect(() => {
    if (contextoEmpresa.numeroPagina == numPag) {
      obtenerInformacionInicial(contextoEmpresa.idEmpresaPadre).catch(
        console.error
      )
      contextoEmpresa.setformularioActualTieneErrores(true)
    }
  }, [contextoEmpresa.numeroPagina])

  const [listaEmpresasHijas, setListaEmpresasHijas] = useState([])
  const [busquedaCodigoInterno, setBusquedaCodigoInterno] = useState('')
  const [busquedaRazonSocial, setBusquedaRazonSocial] = useState('')

  const filtrarPorCodigoInterno = async (e) => {
    setBusquedaCodigoInterno(e.target.value)

    let empresas = await ObtenerEmpresasHijasPorCodigoInternoONombre(
      contextoEmpresa.idEmpresaPadre,
      e.target.value,
      busquedaRazonSocial
    )
    setListaEmpresasHijas(empresas)
  }

  const filtrarPorRazonSocial = async (e) => {
    setBusquedaRazonSocial(e.target.value)
    let empresas = await ObtenerEmpresasHijasPorCodigoInternoONombre(
      contextoEmpresa.idEmpresaPadre,
      busquedaCodigoInterno,
      e.target.value
    )
    setListaEmpresasHijas(empresas)
  }

  const limpiarFiltros = async () => {
    let empresas = await ObtenerEmpresasHijasPorIdPadre(
      contextoEmpresa.idEmpresaPadre
    )
    setListaEmpresasHijas(empresas)
    setBusquedaRazonSocial('')
    setBusquedaCodigoInterno('')
    contextoEmpresa.setformularioActualTieneErrores(true)
  }

  const empresaHijaSeleccionada = async (empresaHijaSeleccionada) => {
    if (
      empresaHijaSeleccionada &&
      empresaHijaSeleccionada.original &&
      empresaHijaSeleccionada.original.id != contextoEmpresa.idEmpresa
    ) {
      await ObtenerEmpresaPorIdDB(empresaHijaSeleccionada.original.id).then(
        async (res) => {
          contextoEmpresa.setIdEmpresa(res.data.id)
          let ciudad = await ObtenerCiudadPorId(res.data.ciudad)
          let departamento = await ObtenerDepartamentoPorId(
            ciudad[0].DepartamentoId
          )
          let fechaCorteEditable
          if (res.data.facturacionEmpresa.fechaCorte != '0001-01-01T00:00:00') {
            fechaCorteEditable = moment(
              res.data.facturacionEmpresa.fechaCorte
            ).format('YYYY-MM-DD')
          }
          contextoEmpresa.setformularioDatos({
            nit: res.data.nit ?? '',
            verificacionNit: res.data.verificacionNit ?? '',
            razonSocial: res.data.nombre ?? '',
            nombreEmpresaHC: res.data.nombreEmpresaHC ?? '',
            direccion: res.data.direccion ?? '',
            pais: '1' ?? '',
            departamento: departamento.Nombre ?? '',
            ciudad: ciudad[0].Nombre ?? '',
            telefono: res.data.telefono ?? '',
            correo: res.data.correo ?? '',
          })

          contextoEmpresa.setformularioFacturacion({
            factura: res.data.facturacionEmpresa?.razonSocial,
            RUT53: res.data.facturacionEmpresa?.rut53,
            RUT54: res.data.facturacionEmpresa?.rut54,
            codigoPostal: res.data.facturacionEmpresa?.codigoPostal ?? 0,
            actividadEconomica: res.data.facturacionEmpresa?.actividadEconomica,
            matriculaMercantil: res.data.facturacionEmpresa?.matriculaMercantil,
            correoFacturacion: res.data.facturacionEmpresa?.correoFacturacion,
            valorContrato: res.data.facturacionEmpresa?.valorContrato,
            condicionesPago: res.data.facturacionEmpresa?.condicionPago,
            descuento:
              res.data.facturacionEmpresa?.descuento === 0
                ? ''
                : res.data.facturacionEmpresa?.descuento,
            impuestos: res.data.facturacionEmpresa?.impuesto,
            fechaCorte: fechaCorteEditable,
            tipoFacturacion: validarTipoFacturacion(
              res.data.facturacionEmpresa?.tipoFacturacion
            ),
            observacionesFacturacion:
              res.data.facturacionEmpresa?.observaciones,
            nombreContacto: res.data.facturacionEmpresa?.nombreContacto,
            correoContacto: res.data.facturacionEmpresa?.correoContacto,
            telefonoContacto: res.data.facturacionEmpresa?.telefonoContacto,
            prefactura: res.data.facturacionEmpresa?.prefactura ?? false,
            condicionesPrefactura:
              res.data.facturacionEmpresa?.condicionesPrefactura ?? null,
            modalidadFacturacion:
              res.data.facturacionEmpresa?.modalidadFacturacion ?? null,
            licitacion: res.data.facturacionEmpresa?.licitacion ?? false,
          })

          contextoEmpresa.setformularioAdicional({
            estaActiva: res.data.estaActiva,
            motivoDesactivacion: res.data.motivoDesactivacion,
            ejecutivoComercial: res.data.ejecutivoComercial,
            observaciones: res.data.observaciones,
          })

          contextoEmpresa.setCodigoInternoEmpresa(res.data.codigoInterno)
          contextoEmpresa.setActualizar(!contextoEmpresa.actualizar)
          contextoEmpresa.setformularioActualTieneErrores(false)
          contextoEmpresa.setLogoEmpresa({
            nombre: AsginarNombreImagen(res.data.logoUrl),
            url: res.data.logoUrl,
          })
        }
      )
    }
  }

  const AsginarNombreImagen = (url) => {
    if (url) {
      let partes = url.split('/')
      let nombre = partes[partes.length - 1]
      return nombre
    } else {
      return 'Seleccionar archivo'
    }
  }

  const manejarObtenerDatosExportar = async () => {
    let empresas = await ExportarEmpresasHijas(contextoEmpresa.idEmpresaPadre)
    let ciudades = await ObtenerTodasCiudades()
    let departamentos = await ObtenerTodosDepartamentos()
    return empresas.data.map((empresa) => ({
      ...empresa,
      ciudad: ciudades.find((x) => x.Id == empresa.ciudad).Nombre,
      departamento: departamentos.find(
        (d) =>
          d.Id == ciudades.find((x) => x.Id == empresa.ciudad).DepartamentoId
      ).Nombre,
      pais: 'COLOMBIA',
    }))
  }

  return (
    <>
      <div className="bg-white rounded-t-3xl rounded-b-3xl mt-5 mb-5 w-full">
        <div className="rounded-md flex justify-evenly flex-wrap w-full mt-5 items-center">
          <div className="flex justify-evenly flex-wrap items-center w-11/12">
            <CampoFiltrarEspañol
              titulo={'Código interno de la empresa hija'}
              estilosPersonalizados={'w-3/5 md:w-3/12 m-5'}
              estilosInput={'border-cendiatra'}
              placeholder={'Ingrese código (autocompletar)'}
              tipo={'text'}
              valorDelCampoFiltro={busquedaCodigoInterno}
              desactivarOtroFiltro={''}
              informacionFiltrada={[]}
              handleChange={filtrarPorCodigoInterno}
              id={1}
            />
            <CampoFiltrarEspañol
              titulo={'Razón social (Nombre empresa hija)'}
              estilosPersonalizados={'w-3/5 md:w-3/12 m-5'}
              estilosInput={'border-cendiatra'}
              placeholder={'Descripción (autocompletar)'}
              tipo={'text'}
              valorDelCampoFiltro={busquedaRazonSocial}
              desactivarOtroFiltro={''}
              informacionFiltrada={[]}
              handleChange={filtrarPorRazonSocial}
              id={2}
            />
            <div className="w-1/12 flex justify-center items-center ">
              <button
                className="group relative w-12 h-9 mt-6 flex justify-center py-2 px-4 border border-transparent text-xs font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                onClick={() => limpiarFiltros()}
              >
                Limpiar
              </button>
            </div>
            <div className="w-4/5 md:w-1/12 flex justify-center flex-wrap">
              <div className="w-full flex justify-center items-center mt-5">
                <ExportarCSV
                  encabezados={encabezadosExportarEmpresasHijas}
                  obtenerDatos={manejarObtenerDatosExportar}
                  nombreArchivo="Lista_de_empresas_hijas.csv"
                ></ExportarCSV>
              </div>
              <div className="w-full text-center -mt-2">
                <span className="text-gray-400 text-xs">
                  Exportar empresas hijas
                </span>
              </div>
            </div>
            <div className="w-4/5 md:w-2/12 flex justify-center items-center flex-wrap text-center">
              <label className=" text-sm font-medium text-cendiatra w-full ">
                Cantidad empresas hijas
              </label>
              <input
                type="text"
                className="appearance-none text-center relative block w-1/2  px-3 py-2 border border-gray-300 bg-gray-300 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                value={listaEmpresasHijas.length}
                disabled={true}
              />
            </div>
          </div>

          <TablaConPaginacionYFiltros
            columns={columnasTablaEmpresasHijas}
            data={listaEmpresasHijas}
            paginacion={true}
            filasPorPagina={10}
            funcionFilaSeleccionada={empresaHijaSeleccionada}
            parametrosCheckbox={{
              titulo: cambiarTextoColumnaPorRol
                ? 'Seleccione para ver'
                : 'Seleccione para modificar',
              estilosColumna: 'w-2/12',
              estilosEncabezado:
                'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
              estilosCelda:
                'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
            }}
          />
        </div>
        <div className="w-full flex justify-center items-center mt-12">
          <BotonesFormularioPasos contexto={contextoEmpresa} />
        </div>
      </div>
    </>
  )
}

export default SeleccionEmpresaHija
