import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextoReporte} from '../../contexto'
import {TablaConPaginacionYFiltros} from '../../componentes/Tablas'
import {EncabezadoFondo} from '../../componentes/Tablas'
import {CambioDeInformacionRadial} from '../../componentes/MostrarInformacion'
import BotonConImagen from '../../componentes/BotonConImagen'
import {useHistory} from 'react-router-dom'
import {
  RUTAS_APP,
  ESTADOS_REPORTES,
  ESTADO_REPORTES_STRING,
} from '../../constantes'
import {BarraEncabezadosExternos} from '../../componentes/Pestanas'
import {CeldaConTooltipPersonalizable} from '../../componentes/Celdas'
import {useMsal} from '@azure/msal-react'
import BtnCrearReporte from '../../imagenes/BotonCrearNuevoReporte.svg'
import {ObtenerSolicitudesPorEstadoId} from '../../microservicios/Reporte'
import moment from 'moment'
import {FiltroListaSeleccionar} from '../../componentes/Filtros'

const GeneradorDeReportes = () => {
  const listaPestanas = ['Reportes en proceso', 'Reportes generados']

  const contextoReporte = useContext(ContextoReporte)

  const [verTodosLosReportes, setVerTodosLosReportes] = useState(false)

  const [todosLosReportesEnProceso, setTodosLosReportesEnproceso] = useState([])

  const [reportesEnProceso, setReportesEnproceso] = useState([])

  const [todosLosReportesGenerados, setTodosLosReportesGenerados] = useState([])

  const [reportesGenerados, setReportesGenerados] = useState([])

  const coloresEstadoReportes = {
    1: '#24C8A7',
    2: '#FABB54',
    3: '#F57470',
    4: '#C8C6C6',
  }
  const coloresPuestoEnCola = {
    1: 'text-cendiatra-semaforo-verde',
    2: 'text-cendiatra-semaforo-amarillo',
  }
  const history = useHistory()

  const {accounts} = useMsal()

  const correoUsuario = accounts?.[0]?.idTokenClaims?.preferred_username ?? ''

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      const listaReportesEnProceso =
        (
          await ObtenerSolicitudesPorEstadoId([
            ESTADOS_REPORTES.EN_EJECUCIÓN,
            ESTADOS_REPORTES.PROCESO_PENDIENTE,
          ])
        )?.data || []
      const listaReportesGenerados =
        (
          await ObtenerSolicitudesPorEstadoId([
            ESTADOS_REPORTES.ERROR,
            ESTADOS_REPORTES.GENERADO,
          ])
        )?.data || []

      const datosReportesEnProceso = listaReportesEnProceso?.map((elemento) => {
        return {
          ...elemento,
          estadoSolicitudString:
            ESTADO_REPORTES_STRING[elemento?.estadoSolicitud],
        }
      })
      const datosReportesGenerados = listaReportesGenerados?.map((elemento) => {
        return {
          ...elemento,
          estadoSolicitudString:
            ESTADO_REPORTES_STRING[elemento?.estadoSolicitud],
        }
      })

      setTodosLosReportesEnproceso(datosReportesEnProceso)
      setReportesEnproceso(datosReportesEnProceso)

      setTodosLosReportesGenerados(datosReportesGenerados)
      setReportesGenerados(datosReportesGenerados)
    }
    obtenerDatosIniciales()
  }, [])

  const abrirReporte = (url) => {
    window.open(url, '_blank')
  }

  const calcularIndex = (index) => {
    return reportesEnProceso.length - index
  }

  const validarEstilos = (dato, correo) => {
    if (dato === correo) {
      return 'w-full text-cendiatra-verde-8 underline cursor-pointer font-extrabold'
    }
    return 'w-full text-cendiatra-gris-1 pointer-events-none'
  }

  let columnsReportesEnProceso = useMemo(
    () => [
      {
        Header: (row) => <EncabezadoFondo titulo={'Puesto en cola'} />,
        accessor: 'puesto',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-l-lg pl-1',
        disableFilters: true,
        cellStyles: `text-13px h-10 flex justify-center items-center rounded-l-lg `,
        Cell: (row) => (
          <div
            className={`font-extrabold ${
              coloresPuestoEnCola[parseInt(row?.row.original?.estadoSolicitud)]
            }`}
          >
            {calcularIndex(row?.row?.index)}
          </div>
        ),
      },
      {
        Header: (row) => (
          <EncabezadoFondo Fila1={'Fecha y hora'} Fila2={'de solicitud'} />
        ),
        accessor: 'fechaSolicitud',
        className: 'w-2/12',
        disableFilters: true,
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        filter: 'dobleTexto',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center',
        Cell: (row) => (
          <div className={`text-cendiatra-gris-1`}>
            {moment(row?.row?.original?.fechaSolicitud).format(
              'DD/MM/YYYY hh:mm A'
            )}
          </div>
        ),
      },
      {
        Header: (row) => <EncabezadoFondo Fila1={'ID'} Fila2={'de reporte'} />,
        accessor: 'solicitudId',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center',
      },

      {
        Header: (row) => <EncabezadoFondo titulo={'Tipo de reporte'} />,
        accessor: 'nombreReporte',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center',
        filter: 'lista',
        Filter: (cell) => (
          <FiltroListaSeleccionar
            column={cell.column}
            estilos="w-10/12 px-3 py-2"
          />
        ),
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Usuario'} />,
        accessor: 'nombreSolicitante',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Estado del reporte'} />,
        accessor: 'estadoSolicitudString',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg pr-1',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center rounded-r-lg ',
        filter: 'lista',
        Filter: (cell) => (
          <FiltroListaSeleccionar
            column={cell.column}
            estilos="w-10/12 px-3 py-2"
          />
        ),
        Cell: (row) => (
          <div className={`font-extrabold text-cendiatra-gris-1`}>
            {row?.row?.original?.estadoSolicitudString}
          </div>
        ),
      },
    ],
    [reportesEnProceso.length]
  )
  let columnsReportesGenerados = useMemo(
    () => [
      {
        Header: (row) => (
          <EncabezadoFondo titulo={'Fecha y hora de solicitud'} />
        ),
        accessor: 'fechaSolicitud',
        className: 'w-2/12',
        disableFilters: true,
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-20 text-white text-14px rounded-l-lg',
        filter: 'dobleTexto',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center',
        Cell: (row) => (
          <div className={`font-extrabold text-cendiatra-gris-1`}>
            {moment(row?.row?.original?.fechaSolicitud).format(
              'DD/MM/YYYY hh:mm A'
            )}
          </div>
        ),
      },
      {
        Header: (row) => <EncabezadoFondo Fila1={'ID'} Fila2={'de reporte'} />,
        accessor: 'solicitudId',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 ',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center ',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Tipo de reporte'} />,
        accessor: 'nombreReporte',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center',
        filter: 'lista',
        Filter: (cell) => (
          <FiltroListaSeleccionar
            column={cell.column}
            estilos="w-10/12 px-3 py-2"
          />
        ),
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Usuario'} />,
        accessor: 'nombreSolicitante',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Estado del reporte'} />,
        accessor: 'estadoSolicitudString',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-10 flex justify-center items-center rounded-r-lg',
        filter: 'lista',
        Filter: (cell) => (
          <FiltroListaSeleccionar
            column={cell.column}
            estilos="w-10/12 px-3 py-2"
          />
        ),
        Cell: (row) =>
          ESTADO_REPORTES_STRING[row.row.original.estadoSolicitud] ===
          ESTADOS_REPORTES.ERROR ? (
            <CeldaConTooltipPersonalizable
              texto={row.row.original.estadoSolicitud}
              textoAdicional={row.row.original.error}
              estilosPersonalizados={'bg-cendiatra text-white mt-8 '}
              estilosTextoToolTip={'text-sm z-50 text-center'}
            />
          ) : (
            <span
              className={`${validarEstilos(
                row?.row?.original?.correoSolicitante,
                correoUsuario
              )}`}
              onClick={() => abrirReporte(row?.row?.original?.urlArchivo)}
            >
              {row?.row?.original?.estadoSolicitudString}
            </span>
          ),
      },
    ],
    []
  )

  const cambiarReportesAMosTrar = () => {
    setVerTodosLosReportes(!verTodosLosReportes)
  }

  const filtrarReportes = () => {
    setVerTodosLosReportes(true)
    setReportesEnproceso(
      todosLosReportesEnProceso?.filter(
        (elemento) => elemento.correoSolicitante === correoUsuario
      )
    )
    setReportesGenerados(
      todosLosReportesGenerados?.filter(
        (elemento) => elemento.correoSolicitante === correoUsuario
      )
    )
  }

  const mostrarTodosLosReportes = () => {
    setVerTodosLosReportes(false)
    setReportesEnproceso(todosLosReportesEnProceso)
    setReportesGenerados(todosLosReportesGenerados)
  }

  return (
    <>
      <div className="w-full flex justify-center items-center">
        <span className="w-full text-cendiatra-verde-2 text-3xl text-center font-extrabold mt-10 mb-5">
          GESTIÓN DE REPORTES
        </span>
      </div>
      <ContenedorPagina tituloPagina={''}>
        <div
          className="w-full flex justify-center items-center flex-wrap "
          style={{marginTop: '-8.05rem'}}
        >
          <BarraEncabezadosExternos
            titulos={listaPestanas}
            contexto={contextoReporte}
          />
        </div>

        <div className="w-full flex justify-between items-end mt-5">
          <BotonConImagen
            estilosContenedor={'text-white py-4 rounded-lg'}
            imagen={BtnCrearReporte}
            funcionAEjecutar={() => {
              history.push(RUTAS_APP.ADMINISTRADOR_GATO.CREAR_REPORTES)
            }}
          />
          <CambioDeInformacionRadial
            estilosContenedor={
              'w-3/12 flex flex-wrap justify-center items-center py-5px rounded-lg h-20 z-50'
            }
            seleccionado={verTodosLosReportes}
            funcionRadialSuperior={filtrarReportes}
            funcionRadialInferior={mostrarTodosLosReportes}
            tituloLeyendaSuperior={'VER MIS REPORTES'}
            tituloLeyendaInferior={'VER TODOS LOS REPORTES'}
          />
        </div>
        <div
          className={`w-full ${
            contextoReporte.numeroPagina === 1 ? 'flex' : 'hidden'
          }  justify-center items-center flex-wrap `}
        >
          <TablaConPaginacionYFiltros
            data={reportesEnProceso}
            columns={columnsReportesEnProceso}
            paginacion={true}
            rowProps={(row) => ({
              style: {
                border: `2px solid ${
                  coloresEstadoReportes[row?.original?.estadoSolicitud]
                }`,
                height: '45px',
              },
            })}
            deshabilitarBordeFila={true}
            filasPorPagina={12}
            quitarBordeIzquiero={false}
          />
        </div>

        <div
          className={`w-full ${
            contextoReporte.numeroPagina === 2 ? 'flex' : 'hidden'
          }  justify-center items-center flex-wrap `}
        >
          <span className="w-full flex justify-center text-center text-21px font-bold text-cendiatra">
            {
              TEXTO_VISUAL.ADMINISTRADOR_GATO.GENERADOR_DE_REPORTES
                .GESTION_DE_REPORTES_GENERADOS
            }
          </span>
          <TablaConPaginacionYFiltros
            data={reportesGenerados}
            columns={columnsReportesGenerados}
            paginacion={true}
            rowProps={(row) => ({
              style: {
                border: `2px solid ${
                  coloresEstadoReportes[row?.original?.estadoSolicitud]
                }`,
              },
            })}
            deshabilitarBordeFila={true}
            filasPorPagina={12}
            quitarBordeIzquiero={false}
          />
        </div>
      </ContenedorPagina>
    </>
  )
}

export default GeneradorDeReportes
