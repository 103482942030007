import {dbComun} from '../bases-datos/dbComun'

export const ObtenerGrupoSanguineoPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('GrupoSanguineo')
      .filter(
        ({Nombre}) =>
          Nombre.replace(' ', '')
            .toLocaleUpperCase()
            .indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}
export const ObtenerGruposSanguineos = async () => {
  return Promise.resolve(dbComun.table('GrupoSanguineo').toArray())
}
