import React, {useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ListaDesplegable, InputConBoton, Input} from '../../componentes/inputs'
import {RegistrarJustificacionCambioSede} from '../../microservicios'
import {ObtenerSedePorId} from '../../cache/servicios-cache'
import {regexTextoCaracteresBasicos} from '../../constantes'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const FiltroAgenda = ({
  tituloLista,
  tituloInput,
  contexto,
  opcionesLista,
  funcionActualizar,
}) => {
  const {
    setValue,
    clearErrors,
    setError,
    handleSubmit,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const sedeInicialId = parseInt(
    fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
  )
  const consultorioId = parseInt(
    fnObtenerInformacionSessionOLocalStorage('consultorioId', rol)
  )
  const [consultorio, setConsultorio] = useState('')

  useEffect(() => {
    ObtenerSedePorId(sedeInicialId).then((res) => {
      setConsultorio(
        res.Consultorios?.find((consultorio) => consultorio.id == consultorioId)
          ?.nombre
      )
    })
  }, [])

  const manejarSedeSeleccionada = async (e) => {
    if (e.target.value === '') {
      return
    }
    contexto.setSedeSeleccionada(e.target.value)

    if (parseInt(e.target.value) == sedeInicialId || e.target.value == '') {
      clearErrors('input')
      setValue('lista', e.target.value)
      setValue('input', '')
      contexto.setOcultarTablaDatos(false)
    } else {
      setError('input', {type: 'require', message: ''})
      setValue('lista', e.target.value)
      setValue('input', '')
      contexto.setOcultarTablaDatos(true)
    }
    if (parseInt(e.target.value) === sedeInicialId) {
      funcionActualizar(parseInt(e.target.value))
    }
  }

  const manejarCambioDeSede = async (data) => {
    let payloadJustificacion = {
      justificacion: data.input,
      sedeAnteriorId: contexto.sedeAnterior,
      sedeNuevaId: contexto.sedeSeleccionada,
    }
    if (contexto.sedeAnterior != contexto.sedeSeleccionada) {
      RegistrarJustificacionCambioSede(payloadJustificacion)
        .then((res) => {
          if (res) {
            contexto.setSedeAnterior(contexto.sedeSeleccionada)
            contexto.setMotivoCambioSede('')
            setValue('input')
            setError('input', {type: 'require', message: ''})
            funcionActualizar()
            contexto.setOcultarTablaDatos(false)
          }
        })
        .catch((error) => {
          console.log(error)
          contexto.setOcultarTablaDatos(false)
        })
    }
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <form
        className="w-full flex flex-wrap justify-evenly items-start"
        onSubmit={handleSubmit(manejarCambioDeSede)}
      >
        <Controller
          name="lista"
          control={control}
          rules={{
            required: false,
          }}
          render={() => (
            <ListaDesplegable
              onChange={manejarSedeSeleccionada}
              estilosContenedor={'w-4/12'}
              estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              titulo={tituloLista}
              opciones={opcionesLista}
              valor={contexto.sedeSeleccionada}
            />
          )}
        />
        <div className="w-7/12 flex justify-center flex-wrap">
          <Controller
            name="input"
            control={control}
            rules={{
              required: true,
              pattern: regexTextoCaracteresBasicos,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <InputConBoton
                  estilosContenedor={'w-full'}
                  estilosInput={`${
                    sedeInicialId != contexto.sedeSeleccionada
                      ? 'flex'
                      : 'hidden'
                  } items-center justify-between rounded relative block w-full  py-1.5 pl-1.5 border
              ${
                Object.keys(errors).find((element) => element === 'input')
                  ? 'border-cendiatra-rojo-1'
                  : 'border-cendiatra '
              } 
              text-cendiatra-gris-3  rounded-lg`}
                  titulo={
                    sedeInicialId != contexto.sedeSeleccionada
                      ? tituloInput
                      : ''
                  }
                  tipoInput={'text'}
                  placeholder={'Descripción'}
                  funcionInput={onChange}
                  funcionBoton={null}
                  tipoBoton={'submit'}
                  habilitado={isValid}
                  valorInput={value}
                />
                <div
                  className={`w-full ${
                    sedeInicialId == contexto.sedeSeleccionada
                      ? 'flex'
                      : 'hidden'
                  } justify-start items-center`}
                >
                  <Input
                    estilosContenedor={'mt-2 w-full'}
                    estilosInput={
                      'ml-5  rounded relative block w-full  p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm mt-9px'
                    }
                    deshabilitado={true}
                    valor={consultorio}
                  />
                </div>
              </>
            )}
          />
          <span className="w-full text-cendiatra-semaforo-rojo text-13px">
            {errors.input?.type === 'pattern' &&
              'Se permiten estos caracteres + ( ) [ ] , / - . '}
          </span>
        </div>
      </form>
    </div>
  )
}

export default FiltroAgenda
