import React, {useEffect, useState} from 'react'
import {CeldaTiempoEspera} from '../Tablas'
import iconoGeneral from '../../imagenes/usuarioGeneral.svg'
import iconoPreferencial from '../../imagenes/usuarioPreferencial.svg'
import iconoVIP from '../../imagenes/usuarioVIP.svg'
import {estadosExamenesConst} from '../../constantes'
import moment from 'moment'

const CeldaTipoPaciente = ({tipoUsurio, atencion, listaServicios}) => {
  const [tiempoInicial, setTiempoInicial] = useState(null)

  const tipoUsuario = (usuarioImagen) => {
    switch (usuarioImagen) {
      case 1:
        return iconoGeneral
      case 2:
        return iconoPreferencial
      case 3:
        return iconoVIP
      default:
        return iconoGeneral
    }
  }

  useEffect(() => {
    if (validarIniciarContador()) {
      return setTiempoInicial('00:00:00')
    }
    const timer = setInterval(() => {
      setTiempoInicial(calcularTiempoEspera())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const validarIniciarContador = () => {
    let validarExistenciaExamenEnProceso = Boolean(
      listaServicios.find(
        (examen) => examen?.estadoExamen === estadosExamenesConst?.EN_PROCESO
      )
    )
    return validarExistenciaExamenEnProceso
  }

  const calcularTiempoEspera = () => {
    const fechaParaCalculo =
      atencion?.fechaUltimoLlamado === '0001-01-01T00:00:00'
        ? atencion?.fechaCreacion
        : atencion?.fechaUltimoLlamado
    var duracion = moment.duration(moment().diff(moment(fechaParaCalculo)))
    return (
      String(duracion.hours()).padStart(2, '0') +
      ':' +
      String(duracion.minutes()).padStart(2, '0') +
      ':' +
      String(duracion.seconds()).padStart(2, '0')
    )
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <img className="" src={tipoUsuario(tipoUsurio)} alt="tipoUsuario" />
      {atencion ? (
        <div className="w-full">
          <CeldaTiempoEspera tiempoInicial={tiempoInicial} />
        </div>
      ) : null}
    </div>
  )
}

export default CeldaTipoPaciente
