import React, {useEffect} from 'react'
import {InputRegex} from '../../../inputs'
import RangoOpcion from './RangoOpcion'

const Rango = ({atributos, actualizarAtributo}) => {
  useEffect(() => {
    setTimeout(() => {
      if (!atributos?.listaRangos) {
        let nuevoRango = {
          id: 1,
          rangoInferior: 0,
          rangoSuperior: 0,
          valor: '',
        }
        let listaRangos = [nuevoRango]
        actualizarAtributo('listaRangos', listaRangos)
      }
    }, 300)
  }, [])

  const actualizarValorRango = (atributo, valor, id) => {
    actualizarAtributo(
      'listaRangos',
      atributos.listaRangos.map((rango) => {
        if (rango.id == id) {
          return {
            ...rango,
            [atributo]: atributo == 'valor' ? valor : valor?.floatValue ?? 0,
          }
        }
        return rango
      })
    )
  }

  const agregarRango = (id) => {
    let nuevoRango = {
      id: id + 1,
      rangoInferior: 0,
      rangoSuperior: 0,
      valor: '',
    }
    let listaRangos = [...atributos.listaRangos].map((rango) => {
      if (rango.id > id) {
        return {
          ...rango,
          id: rango.id + 1,
        }
      }
      return rango
    })
    listaRangos.splice(id, 0, nuevoRango)
    actualizarAtributo('listaRangos', listaRangos)
  }

  const eliminarRango = (id) => {
    let listaRangos = [...atributos.listaRangos.filter((x) => x.id != id)].map(
      (rango) => {
        if (rango.id > id) {
          return {
            ...rango,
            id: rango.id - 1,
          }
        }
        return rango
      }
    )
    if (listaRangos.length == 0) {
      return
    }
    actualizarAtributo('listaRangos', listaRangos)
  }

  return (
    <div className="w-full flex flex-wrap">
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2">
        <label className="flex flex-wrap w-full align-middle content-center">
          Campo referencia
        </label>
      </div>
      <InputRegex
        ancho={'w-1/2 flex'}
        cambioValor={(e) => {
          actualizarAtributo('campoReferencia', e)
        }}
        estilosContenedor={'w-full flex'}
        estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        tipo={'text'}
        placeholder={'CAMPO'}
        valor={atributos?.campoReferencia}
        requerido={true}
      />
      {atributos?.listaRangos ? (
        <div className="w-full mt-10">
          <span className=" w-full text-cendiatra text-17px leading-20px font-bold uppercase">
            LISTA RANGOS
          </span>
          <div className="w-full flex flex-wrap">
            {atributos.listaRangos.map((rango) => (
              <div className="w-full flex pl-10">
                <RangoOpcion
                  id={rango.id}
                  rangoInferior={rango.rangoInferior}
                  rangoSuperior={rango.rangoSuperior}
                  valorRango={rango.valor}
                  actualizarValorRango={actualizarValorRango}
                  eliminarRango={eliminarRango}
                  agregarRango={agregarRango}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Rango
