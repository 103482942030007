import React from 'react'
import {NumericFormat} from 'react-number-format'

const InputMoneda = ({
  onValueChange,
  estilosContenedor,
  estilosInput,
  placeholder,
  titulo,
  deshabilitado,
  valor,
  formatoVacio,
  onFocus,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      <label className="block text-13px text-cendiatra my-5px">{titulo}</label>
      <NumericFormat
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'$'}
        value={parseFloat(valor)}
        defaultValue={0}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.source == 'event') {
            onValueChange(values)
          }
        }}
        className={estilosInput}
        placeholder={placeholder}
        disabled={deshabilitado}
        allowEmptyFormatting={formatoVacio}
        onFocus={onFocus}
      />
    </div>
  )
}

export default InputMoneda
