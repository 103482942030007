import moment from 'moment'
import React, {useEffect, useState, useContext} from 'react'
import Fila from '../Fila'
import CeldaConTooltip from '../CeldaConTooltip'
import {
  AgregarPortafoliosACache,
  ObtenerTodosLosPortafolios,
  ObtenerTodasLasSedes,
  ObtenerTodosLosAliados,
  obtenerServiciosPorIds,
  obtenerPaquetesPorIds,
} from '../../cache/servicios-cache'
import {ContextoPortafolio, ContextApplication} from '../../contexto'
import {transformarNumero} from '../../utilidades'
import {TEXTO_VISUAL} from '../../constantes'
import {obtenerPortafolioPorId} from '../../microservicios'
import {EncabezadoFondo, TablaConPaginacionYFiltros} from '../Tablas'

const TEXTO_PANTALLA =
  TEXTO_VISUAL.PORTAFOLIO.EDITAR.PESTANA.SELECCIONAR_PORTAFOLIO

export const SeleccionPortafolio = () => {
  const contextoApp = useContext(ContextApplication)
  const contextoPortafolio = useContext(ContextoPortafolio)
  const [listaPortafolios, setListaPortafolios] = useState([])

  useEffect(() => {
    contextoApp.setIsLoading(true)

    AgregarPortafoliosACache()
      .then(() => ObtenerTodosLosPortafolios())
      .then((portafolios) =>
        setListaPortafolios(
          portafolios.map((elemento) => {
            if (elemento.Id < 10) {
              return {
                ...elemento,
                Id: transformarNumero(elemento.Id),
              }
            }
            return elemento
          })
        )
      )
      .catch((e) => console.error)
      .finally(() => contextoApp.setIsLoading(false))
  }, [])

  const verificarInformacionSeleccionada = (filaSeleccionada) => {
    if (filaSeleccionada) {
      obtenerPortafolioSeleccionado(filaSeleccionada?.original?.Id)
      contextoPortafolio.setformularioActualTieneErrores(false)
    }
  }

  const obtenerPortafolioSeleccionado = async (id) => {
    const obtenerArrayConDatos = (arrayServicios, arrayPaquetes) => {
      if (arrayServicios && arrayServicios.length > 0) {
        return arrayServicios
      }
      if (arrayPaquetes && arrayPaquetes.length > 0) {
        return arrayPaquetes
      }
      return []
    }
    const sedes = await ObtenerTodasLasSedes()
    const aliados = await ObtenerTodosLosAliados()
    const respuesta = await obtenerPortafolioPorId(id)

    const {data: portafolio} = respuesta

    const serviciosIds = portafolio.servicios.map((s) => s.servicioId)
    const paquetesIds = portafolio.paquetes.map((s) => s.paqueteId)
    const servicios = await obtenerServiciosPorIds(serviciosIds)
    const paquetes = await obtenerPaquetesPorIds(paquetesIds)

    contextoPortafolio.setFormularioPortafolio({
      id: portafolio.id,
      fechaInicial: moment(portafolio.fechaInicial).format('YYYY-MM-DD'),
      fechaFinal: portafolio.fechaFinal
        ? moment(portafolio.fechaFinal).format('YYYY-MM-DD')
        : '',
      nombre: portafolio.nombre,
    })

    contextoPortafolio.setNombrePortafolioValidacion(
      portafolio.nombre.trim().toLowerCase()
    )

    const arrayObtenerSedes = obtenerArrayConDatos(
      respuesta?.data?.servicios,
      respuesta?.data?.paquetes
    )

    contextoPortafolio.setSedesIniciales(
      arrayObtenerSedes?.[0]?.preciosSedes?.map((sede) => {
        return sede?.sedeId
      }) ?? []
    )
    contextoPortafolio.setAliadosIniciales(
      arrayObtenerSedes?.[0]?.preciosAliados?.map((sede) => {
        return sede?.aliadoId
      }) ?? []
    )

    const preciosServiciosIniciales = {
      preciosSedes:
        portafolio?.servicios.flatMap((item) => item?.preciosSedes) ?? [],
      preciosAliados:
        portafolio?.servicios.flatMap((item) => item?.preciosAliados) ?? [],
    }

    const preciospaquetesIniciales = {
      preciosSedes:
        portafolio?.paquetes?.flatMap((item) => item?.preciosSedes) ?? [],
      preciosAliados:
        portafolio?.paquetes?.flatMap((item) => item?.preciosAliados) ?? [],
    }
    contextoPortafolio.setPreciosServiciosIniciales(preciosServiciosIniciales)

    contextoPortafolio.setPreciosPaquetesIniciales(preciospaquetesIniciales)

    const serviciosIniciales =
      portafolio?.servicios.map((servicio) => servicio?.servicioId) ?? []
    contextoPortafolio.setServiciosIniciales(serviciosIniciales)

    const paquetesIniciales =
      portafolio?.paquetes.map((paquete) => paquete?.paqueteId) ?? []
    contextoPortafolio.setPaquetesIniciales(paquetesIniciales)

    let sedesIds = []
    let aliadosIds = []

    if (portafolio.paquetes.length > 0) {
      sedesIds = portafolio.paquetes[0].preciosSedes.map((x) => x.sedeId)
      aliadosIds =
        portafolio.paquetes[0].preciosAliados == null
          ? []
          : portafolio.paquetes[0].preciosAliados.map((x) => x.aliadoId)
    } else {
      sedesIds = portafolio.servicios[0].preciosSedes.map((x) => x.sedeId)
      aliadosIds =
        portafolio.servicios[0].preciosAliados == null
          ? []
          : portafolio.servicios[0].preciosAliados.map((x) => x.aliadoId)
    }

    contextoPortafolio.setListaPaquetes(
      portafolio.paquetes.map((elemento) => {
        return {
          paquete_id: elemento.paqueteId,
          puedeModificarse: elemento.puedeModificarse,
          nombre: paquetes.find((x) => x.Id == elemento.paqueteId).Nombre,
          precios_sedes: elemento.preciosSedes.map((sede) => {
            const sedeCache = sedes.find((x) => x.Id == sede.sedeId)

            return {
              grupo: sedeCache.Grupo,
              id: 'paquete:' + sede.sedeId,
              nombre: sedeCache.Nombre,
              precio: sede.precio,
              idPrecio: sede.id,
              nuevoPrecio: sede.precioAModificarse,
              tipo: 'paquete',
              idGeneral: elemento.paqueteId,
            }
          }),
          precios_aliados:
            elemento.preciosAliados == null
              ? []
              : elemento.preciosAliados.map((aliado) => {
                  return {
                    grupo: 'ALIADOS',
                    id: 'aliado:' + aliado.aliadoId,
                    nombre: aliados.find((x) => x.Id == aliado.aliadoId).Nombre,
                    precio: aliado.precio,
                    idPrecio: aliado.id,
                    nuevoPrecio: aliado.precioAModificarse,
                    tipo: 'paquete',
                    idGeneral: elemento.paqueteId,
                  }
                }),
        }
      })
    )

    contextoPortafolio.setListaServicios(
      portafolio.servicios.map((elemento) => {
        return {
          alias: elemento.alias,
          nombre: servicios.find((x) => x.Id == elemento.servicioId).Nombre,
          servicio_id: elemento.servicioId,
          precios_aliados:
            elemento.preciosAliados == null
              ? []
              : elemento.preciosAliados.map((aliado) => {
                  return {
                    grupo: 'ALIADOS',
                    id: 'aliado:' + aliado.aliadoId,
                    nombre: aliados.find((x) => x.Id == aliado.aliadoId).Nombre,
                    precio: aliado.precio,
                    idPrecio: aliado.id,
                    nuevoPrecio: aliado.precioAModificarse,
                    tipo: 'servicio',
                    idGeneral: elemento.servicioId,
                  }
                }),
          precios_sedes: elemento.preciosSedes.map((sede) => {
            const sedeCache = sedes.find((x) => x.Id == sede.sedeId)

            return {
              grupo: sedeCache.Grupo,
              id: 'servicio:' + sede.sedeId,
              nombre: sedeCache.Nombre,
              precio: sede.precio,
              idPrecio: sede.id,
              tipo: 'servicio',
              nuevoPrecio: sede.precioAModificarse,
            }
          }),
        }
      })
    )

    contextoPortafolio.setSedesSeleccionadas(
      sedes.map((sede) => {
        return {
          Id: sede.Id,
          Nombre: sede.Nombre,
          Ciudad: sede.Ciudad,
          Grupo: sede.Grupo,
          Activo: sedesIds.find((x) => x == sede.Id) ? true : false,
        }
      })
    )

    contextoPortafolio.setAliadosSeleccionados(
      aliados.map((aliado) => {
        return {
          Id: aliado.Id,
          Nombre: aliado.Nombre,
          Activo: aliadosIds.find((x) => x == aliado.Id) ? true : false,
        }
      })
    )
    contextoPortafolio.setActualizar(!contextoPortafolio.actualizar)
    contextoPortafolio.setFilaSeleccionada(-1)
  }

  const columns = React.useMemo(() => [
    {
      Header: () => (
        <EncabezadoFondo
          titulo={TEXTO_PANTALLA.COLUMNA.CODIGO_PORTAFOLIO.TITULO}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'Id',
      className: 'w-1/12 z-0',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={TEXTO_PANTALLA.COLUMNA.NOMBRE_PORTAFOLIO.TITULO}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'Nombre',
      className: 'w-5/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: CeldaConTooltip,
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={TEXTO_PANTALLA.COLUMNA.FECHA_MODIFICACION.TITULO}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'FechaModificacion',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={TEXTO_PANTALLA.COLUMNA.EMPRESA_PORTAFOLIO_ASIGNADO.TITULO}
          paddingFondoClaro={''}
        />
      ),

      accessor: 'EmpresasAsignado',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
  ])

  return (
    <div className="bg-white rounded-t-3xl rounded-b-3xl mt-5 mb-5 w-full">
      <div className="rounded-md  flex justify-evenly flex-wrap w-full mt-5 items-center">
        <Fila ancho={'w-full justify-evenly items-center'} flexWrap={true}>
          <TablaConPaginacionYFiltros
            columns={columns}
            data={listaPortafolios}
            paginacion={true}
            filasPorPagina={10}
            funcionFilaSeleccionada={verificarInformacionSeleccionada}
            parametrosCheckbox={{
              titulo: 'Editar',
              estilosColumna: 'w-2/12',
              estilosEncabezado:
                'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
              estilosCelda:
                'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
            }}
          />
        </Fila>
      </div>
    </div>
  )
}

export default SeleccionPortafolio
