import {useContext, useEffect, useMemo} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {ContextApplication, ContextoModales} from '../../../contexto'
import {BotonSimple} from '../../Botones'
import {AreaDeTexto, Input} from '../../inputs'
import {
  FachadaGuardarFormulaOptometrica,
  GenerarFormatoFormulaOptometrica,
  ObtenerInformacionFormulaOptometricaPorServicioAtencionId,
} from '../../../microservicios'

export function GenerarFormularioOptometrica() {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoModales = useContext(ContextoModales)

  const {
    watch,
    control,
    getValues,
    reset,
    trigger,
    formState: {isValid},
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
    if (!!contextoModales?.parametrosFormulario?.servicioAtencionId) {
      ObtenerInformacionFormulaOptometricaPorServicioAtencionId(
        contextoModales.parametrosFormulario.servicioAtencionId
      )
        .then((res) => {
          if (res.status === 204) {
            reset(contextoModales.formulaOptometrica)
          } else {
            let datosFormulaMedica = {
              od: {
                esfera: res.data.esferaDerecho,
                cilindro: res.data.cilindroDerecho,
                eje: res.data.ejeDerecho,
                agudezaVisualVl: res.data.agudezaVisualVLDerecho,
                agudezaVisualVp: res.data.agudezaVisualVPDerecho,
              },
              oi: {
                esfera: res.data.esferaIzquierdo,
                cilindro: res.data.cilindroIzquierdo,
                eje: res.data.ejeIzquierdo,
                agudezaVisualVl: res.data.agudezaVisualVLIzquierdo,
                agudezaVisualVp: res.data.agudezaVisualVPIzquierdo,
              },
              adicion: {
                add: res.data.esferaAdicion,
                agudezaVisual: res.data.agudezaVisual,
                observaciones: res.data.observaciones,
              },
            }
            reset(datosFormulaMedica)
            contextoModales.setFormulaOptometrica({
              ...contextoModales.formulaOptometrica,
              guardado: true,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return () => contextoModales.setFormulaOptometrica({})
  }, [])

  useEffect(() => {
    const subscripcion = watch(() => {
      let valoresFormulario = getValues()
      contextoModales.setFormulaOptometrica({
        ...contextoModales.formulaOptometrica,
        valoresFormulario,
      })
    })
    return () => subscripcion.unsubscribe()
  }, [watch])

  const funcionCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const funcionGuardarFormulaOptometrica = () => {
    const datosFormulario = getValues()

    let payload = {
      servicioAtencionId:
        contextoModales.parametrosFormulario.servicioAtencionId,
      ojoIzquierdo: {
        esfera: datosFormulario.oi.esfera,
        cilindro: datosFormulario.oi.cilindro,
        eje: datosFormulario.oi.eje,
        agudezaVisualVL: datosFormulario.oi.agudezaVisualVl,
        agudezaVisualVP: datosFormulario.oi.agudezaVisualVp,
      },
      ojoDerecho: {
        esfera: datosFormulario.od.esfera,
        cilindro: datosFormulario.od.cilindro,
        eje: datosFormulario.od.eje,
        agudezaVisualVL: datosFormulario.od.agudezaVisualVl,
        agudezaVisualVP: datosFormulario.od.agudezaVisualVp,
      },
      adicion: {
        add: datosFormulario.adicion.add,
        agudezaVisul: datosFormulario.adicion.agudezaVisual,
        observaciones: datosFormulario.adicion.observaciones,
      },
      servicioId: contextoModales.parametrosFormulario.servicioId,
    }
    FachadaGuardarFormulaOptometrica(payload)
      .then((res) => {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'Formato de formula optométrica guardado',
          contenido: '',
          botones: [
            {
              nombre: 'Regresar',
              click: funcionCerrarModal,
            },
            {
              nombre: 'Imprimir',
              click: imrpimirFormatoOptometria,
            },
          ],
        })

        contextoModales.setFormulaOptometrica({
          ...contextoModales.formulaOptometrica,
          guardado: true,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const imrpimirFormatoOptometria = () => {
    GenerarFormatoFormulaOptometrica(
      contextoModales.parametrosFormulario.servicioAtencionId
    )
      .then((res) => {
        if (res?.data) {
          window.open(res?.data?.replace(/['"]+/g, ''), '_blank').focus()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const camposSeccionesOjos = useMemo(
    () => [
      {
        id: 'esfera',
        nombre: 'ESFERA',
      },
      {
        id: 'cilindro',
        nombre: 'CILINDRO',
      },
      {
        id: 'eje',
        nombre: 'EJE',
      },
      {
        id: 'agudezaVisualVl',
        nombre: 'AGUDEZA VISUAL VL',
      },
      {
        id: 'agudezaVisualVp',
        nombre: 'AGUDEZA VISUAL VP',
      },
    ],
    []
  )

  return (
    <div className="w-full">
      <div
        className={`w-full pb-5 ${
          contextoModales.formulaOptometrica.guardado
            ? 'pointer-events-none'
            : ''
        }`}
      >
        <div className="w-full">
          <p className="mb-2 font-bold">OJO DERECHO (OD)</p>
          <div className="w-full flex space-x-2">
            {camposSeccionesOjos.map((campo, index) => {
              return (
                <div className="w-1/5" key={index}>
                  <Controller
                    name={`od.${campo.id}`}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        estilosContenedor={'w-full text-left'}
                        estilosInput={
                          false
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'text'}
                        placeholder={'DESCRIPCIÓN'}
                        titulo={campo.nombre}
                        onChange={onChange}
                        valor={value}
                      />
                    )}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className="w-full py-5">
          <p className="mb-2 font-bold">OJO IZQUIERDO (OI)</p>
          <div className="w-full flex space-x-2">
            {camposSeccionesOjos.map((campo, index) => {
              return (
                <div className="w-1/5" key={index}>
                  <Controller
                    name={`oi.${campo.id}`}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        estilosContenedor={'w-full text-left'}
                        estilosInput={
                          false
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'text'}
                        placeholder={'DESCRIPCIÓN'}
                        titulo={campo.nombre}
                        onChange={onChange}
                        valor={value}
                      />
                    )}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className="w-full py-5">
          <p className="mb-2 font-bold">ADICIÓN</p>
          <div className="w-full ">
            <div className="w-1/1 flex space-x-2">
              <div className="w-3/12">
                <Controller
                  name="adicion.add"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({field: {onChange, value}}) => (
                    <Input
                      estilosContenedor={'w-full text-left'}
                      estilosInput={
                        false
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      tipo={'text'}
                      placeholder={'DESCRIPCIÓN'}
                      titulo={'ADD'}
                      onChange={onChange}
                      valor={value}
                    />
                  )}
                />
              </div>
              <div className="w-3/12">
                <Controller
                  name="adicion.agudezaVisual"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({field: {onChange, value}}) => (
                    <Input
                      estilosContenedor={'w-full text-left'}
                      estilosInput={
                        false
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      tipo={'text'}
                      placeholder={'DESCRIPCIÓN'}
                      titulo={'AGUDEZA VISUAL'}
                      onChange={onChange}
                      valor={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <Controller
              control={control}
              name="adicion.observaciones"
              rules={{
                required: false,
              }}
              render={({field: {onChange, value}}) => (
                <AreaDeTexto
                  estilosContenedor={'w-full flex'}
                  estilosAreaDeTexto={`rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
                  titulo={'OBSERVACIONES Y/O RECOMENDACIONES'}
                  placeholder={'DESCRIPCIÓN'}
                  requerido={false}
                  cambioValor={onChange}
                  valor={value}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <BotonSimple
          texto={'Guardar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            isValid && !contextoModales.formulaOptometrica.guardado
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={funcionGuardarFormulaOptometrica}
          deshabilitado={
            !isValid || contextoModales.formulaOptometrica.guardado
          }
        />
        <BotonSimple
          texto={'Regresar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 bg-btnBg`}
          funcion={funcionCerrarModal}
        />
        <BotonSimple
          texto={'Imprimir'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            contextoModales.formulaOptometrica.guardado
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={imrpimirFormatoOptometria}
          deshabilitado={!contextoModales.formulaOptometrica.guardado}
        />
      </div>
    </div>
  )
}
