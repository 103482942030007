import React from 'react'

const Boton = ({
  titulo,
  lineaPrimerCaracter,
  funcionCLick,
  habilitado,
  colorPorDefecto,
  colorActivo,
  tipo,
  tamanoTexto,
  ancho,
  alto,
  id = '',
}) => {
  return (
    <button
      id={id}
      className={
        habilitado
          ? `items-center group relative ${ancho ?? 'w-24'} ${
              alto ?? 'h-12'
            } flex justify-center px-4 border border-transparent ${
              tamanoTexto ?? 'text-md'
            } font-medium rounded-md text-white bg-center ${colorActivo} bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra m-2 z-40`
          : `items-center group relative ${ancho ?? 'w-24'} ${
              alto ?? 'h-12'
            } flex justify-center px-4 border border-transparent ${
              tamanoTexto ?? 'text-md'
            } font-medium rounded-md text-white bg-center ${colorPorDefecto} bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra m-2 z-40`
      }
      type={tipo}
      onClick={funcionCLick}
      disabled={!habilitado}
    >
      {' '}
      {lineaPrimerCaracter === true ? (
        <u>{titulo.substring(0, 1)}</u>
      ) : (
        titulo.substring(0, 1)
      )}
      {titulo.substring(1, titulo.length)}
    </button>
  )
}

export default Boton
