import React, {useEffect, useContext} from 'react'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {BotonOpcionConObservaciones} from '../../../Botones'

const VigilanciasEpidemiologicas = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      contextoMedicoOcupacional.setformularioActualTieneErrores(false)
    }
  }, [contextoMedicoOcupacional.numeroPagina])

  const manejarCambioVigilancia = (id) => {
    contextoMedicoOcupacional.setListaVigilanciasEpidemiologicas(
      contextoMedicoOcupacional.listaVigilanciasEpidemiologicas.map(
        (vigilancia) => {
          if (vigilancia.Id == id) {
            return {
              ...vigilancia,
              EstaActivo: !vigilancia.EstaActivo,
            }
          }
          return vigilancia
        }
      )
    )
  }

  const manejarCambioObservaciones = (valor, id) => {
    contextoMedicoOcupacional.setListaVigilanciasEpidemiologicas(
      contextoMedicoOcupacional.listaVigilanciasEpidemiologicas.map(
        (vigilancia) => {
          if (vigilancia.Id == id) {
            return {
              ...vigilancia,
              Observaciones: valor,
            }
          }
          return vigilancia
        }
      )
    )
  }

  return (
    <div className="w-11/12 flex flex-wrap justify-start items-center ml-16">
      {contextoMedicoOcupacional.listaVigilanciasEpidemiologicas.map(
        (vigilancia) => (
          <div className="w-1/3 flex mb-2 p-5">
            <BotonOpcionConObservaciones
              id={vigilancia.Id}
              titulo={vigilancia.Nombre.toUpperCase()}
              estaActivo={vigilancia.EstaActivo}
              observaciones={vigilancia.Observaciones}
              funcionClick={manejarCambioVigilancia}
              cambioObservaciones={manejarCambioObservaciones}
            />
          </div>
        )
      )}
    </div>
  )
}

export default VigilanciasEpidemiologicas
