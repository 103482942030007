import React, {useState, useContext, useEffect, useMemo} from 'react'
import {useHistory} from 'react-router-dom'
import {useForm, Controller} from 'react-hook-form'
import {ContextoRegistro, ContextApplication} from '../../contexto'
import {CampoFiltrarEspañol} from '../../componentes'
import {BotonSimple} from '../../componentes/Botones'
import {
  Input,
  InputMoneda,
  ListaDesplegable,
  InputConResultado,
} from '../../componentes/inputs'
import {ContenedorTituloResaltado} from '../../componentes/Contenedores'
import BotonConImagen from '../../componentes/BotonConImagen'
import {TablaSinPaginacion} from '../Tablas'
import {CeldaFormatoDinero} from '../Celdas'
import {AlertaDinamica} from '../MostrarInformacion'
import CeldaConBoton from './CeldaConBoton'
import BotonAgregar from '../../imagenes/addButton.png'
import BotonQuitar from '../../imagenes/removeButton.png'
import {
  ObtenerEmpresasPorNitONombre,
  ObtenerEmpresaPorNit,
  AgregarEmpresasACache,
  ObtenerMediosDePago,
  ObtenerMediosDePagoPorId,
  ObtenerSedePorId,
  ObtenerEmpresaPorCorreo,
} from '../../cache/servicios-cache'
import {
  FachadaGenerarReciboCajaRecarga,
  CrearMovimientoEmpresa,
} from '../../microservicios'
import {useModal} from '../../hooks/useModal'
import {
  TIPOS_FACTURACION,
  regexSoloNumeros,
  regexTextoCaracteresBasicos,
  regexCorreoElectronico,
  TEXTO_VISUAL,
} from '../../constantes'
import {aplicarFormatoMoneda} from '../../utilidades'
import {FiltrarCiudadPorNombre} from '../../cache/servicios-cache'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const FormularioRecargarSaldoEnSede = () => {
  const contextoRegistro = useContext(ContextoRegistro)

  const contextoAplicacion = useContext(ContextApplication)
  const [cerrarModal] = useModal()
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const history = useHistory()
  const [sede, setSede] = useState({})
  const [empresaExisteOEsCredito, setEmpresaExisteOEsCredito] = useState({
    empresaExiste: false,
    mensaje: '',
  })

  const [alertaICA, setAlertaICA] = useState(false)

  const [totalTabla, setTotalTabla] = useState(0)

  const [municipiosFiltrados, setMunicipiosFiltrados] = useState([])
  const [municipio, setMunicipio] = useState({})
  const [nuevaEmpresa, setNuevaEmpresa] = useState(false)

  const {
    trigger,
    control,
    setValue,
    clearErrors,
    setError,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  })

  let newMovimientoId = 0

  const transformarMedioDePago = (medioDePago) => {
    const EFECTIVO = 1
    const TARJETA_DEBITO = 2
    const medioDePagoMap = {
      2: EFECTIVO,
      5: TARJETA_DEBITO,
    }

    return medioDePagoMap[medioDePago]
  }

  const recargarSaldo = () => {
    const {id, nit, nombre, direccion, telefono, correo} = empresaSeleccionada
    const medioDePagoParaAgregar = contextoRegistro.mediosDePagoRecarga.map(
      ({id, valor}) => ({
        medioPagoId: transformarMedioDePago(id),
        valor,
      })
    )

    const newPayloadRecargaSaldo = {
      crearEmpresa: !!!id ? {nit, nombre, direccion, telefono, correo} : null,
      empresaId: !!id ? id : 0,
      valor: parseFloat(valoresTabla?.montoRecarga),
      valorRecibido: parseFloat(valorTotal.toFixed(2)),
      tipoMovimiento: 1,
      detalle: `Recarga en sede ${sede?.nombre}`,
      atencionId: 0,
      estadoMovimiento: 0,
      transaccionId: 0,
      sedeId: parseInt(fnObtenerInformacionSessionOLocalStorage('sedeId', rol)),
      municipioId: municipio?.Id,
      retefuente: valoresTabla?.retencionRenta || 0,
      reteIca: valoresTabla?.retencionICA || 0,
      bomberil: valoresTabla?.sobretasaBomberil || 0,
      mediosDePago: medioDePagoParaAgregar,
    }

    CrearMovimientoEmpresa(newPayloadRecargaSaldo)
      .then((resp) => {
        if (resp.status === 200) {
          newMovimientoId = resp.data
          recargaExitosa()
          generarRecibo()
        }
      })
      .catch(manejarModalError)
  }

  const recargaExitosa = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full text-cendiatra font-bold text-xl">
            {' '}
            EMPRESA CREADA Y
          </span>
          <span className="w-full text-cendiatra font-bold text-xl">
            {' '}
            RECARGA REALIZADA
          </span>
        </div>
      ),
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full text-cendiatra  text-xl">
            {' '}
            RECUERDE INFORMARLE AL CLIENTE QUE
          </span>
          <span className="w-full text-cendiatra  text-xl">
            {' '}
            REVISE EL CORREO ELECTRÓNICO
          </span>
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            history.go(0)
            cerrarModal()
          },
        },
        {
          nombre: 'Finalizar',
          click: () => {
            cerrarModal()
            history.push('/main')
          },
        },
      ],
    })
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const irAInicio = () => {
    manejarCerrarModal()
    history.push('/')
  }

  const manejarModalError = () => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <div className="w-full flex justify-center items-center text-cendiatra text-3xl -mt-10 my-10 font-bold flex-wrap">
          <span className="w-full">Tu cuenta NO ha sido recargada</span>
          <span className="w-full">¿Qué deseas hacer?</span>
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar a recargar cuenta',
          click: () => manejarCerrarModal(),
        },
        {
          nombre: 'Finalizar',
          click: () => irAInicio(),
        },
      ],
    })
  }

  const empresaSeleccionadaPorDefecto = {
    empresaPadreId: '',
    id: '',
    nombre: '',
    nit: '',
    telefono: '',
    direccion: '',
    observaciones: '',
    tipoFacturacion: '',
    correo: '',
    filtro: '',
  }

  const mediosDePagoPorDefecto = {
    id: '',
    nombre: '',
    valor: '',
  }

  const valoresTablaPorDefecto = {
    montoRecarga: '',
    retencionRenta: '',
    rentaTotal: '',
    retencionICA: '',
    ICATotal: '',
    sobretasaBomberil: '',
    bomberilTotal: '',
    tarifaIVA: '',
    totalIVA: '',
  }

  const [mediosDePago, setMediosDePago] = useState([])

  const [medioDePagoParaAgregar, setMedioDePagoParaAgregar] = useState(
    mediosDePagoPorDefecto
  )
  const [valoresTabla, setValoresTabla] = useState(valoresTablaPorDefecto)

  const [valorTotal, setValorTotal] = useState(0)

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(
    empresaSeleccionadaPorDefecto
  )

  const [empresasFiltradas, setEmpresasFiltradas] = useState([])

  useEffect(() => {
    const cargarInformacionInicial = async () => {
      const mediosPagoLista = await ObtenerMediosDePago()

      setMediosDePago(
        mediosPagoLista.filter((x) =>
          ['EFECTIVO', 'TARJETAS DEBITO - CREDITO'].includes(x?.Nombre ?? '')
        )
      )
      await AgregarEmpresasACache(true)
    }
    cargarInformacionInicial()
  }, [])

  const manejarEmpresaCambiada = async (e) => {
    setNuevaEmpresa(false)
    if (e.target.value === '') {
      setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
    } else {
      setEmpresaSeleccionada({
        ...contextoRegistro.empresaSeleccionada,
        filtro: e.target.value,
      })
    }
    if (nuevaEmpresa) {
      return
    }

    if (e.target.value.length >= 3) {
      let empresas
      if (isNaN(e.target.value)) {
        empresas = await ObtenerEmpresasPorNitONombre('', e.target.value)
      } else {
        empresas = await ObtenerEmpresasPorNitONombre(e.target.value, '')
      }
      setEmpresasFiltradas(
        empresas.map((empresa) => ({
          id: empresa.Id,
          filtro: empresa.Nit + '-' + empresa.Nombre.toUpperCase(),
          empresaPadreId: empresa.EmpresaPadreId,
          nit: empresa.Nit,
          observaciones: empresa.Observaciones,
          direccion: empresa.Direccion,
          nombre: empresa.Nombre,
          tipoFacturacion: empresa.TipoFacturacion.toUpperCase(),
          telefono: empresa.Telefono,
          correo: empresa.Correo,
        }))
      )
    } else {
      setEmpresasFiltradas([])
      setValue('nit', '')
      setValue('nombre', '')
      setValue('direccion', '')
      setValue('telefono', '')
      setValue('correo', '')
      setEmpresaExisteOEsCredito({
        empresaExiste: false,
        mensaje: '',
      })
      setValoresTabla(valoresTablaPorDefecto)
      setMunicipio({})
      setValue('municipio', '')
    }
  }

  const manejarEmpresaSeleccionada = (empresaSeleccionada) => {
    setEmpresaSeleccionada({
      empresaPadreId: empresaSeleccionada.empresaPadreId,
      id: empresaSeleccionada.id,
      nombre: empresaSeleccionada.filtro,
      nit: empresaSeleccionada.nit,
      filtro: empresaSeleccionada.filtro,
      telefono: empresaSeleccionada.telefono,
      direccion: empresaSeleccionada.direccion,
      observaciones: empresaSeleccionada.observaciones,
      tipoFacturacion: empresaSeleccionada.tipoFacturacion.toUpperCase(),
      correo: empresaSeleccionada.correo,
    })
  }

  useEffect(() => {
    const suma = contextoRegistro.mediosDePagoRecarga.reduce(
      (acumulador, objeto) => {
        const valor = parseFloat(objeto.valor)
        return acumulador + valor
      },
      0
    )
    setValorTotal(suma)
  }, [contextoRegistro.mediosDePagoRecarga])

  const crearNuevaEmpresa = () => {
    setNuevaEmpresa(true)
  }

  useEffect(() => {
    if (nuevaEmpresa) {
      setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
      setEmpresasFiltradas([])
    }
  }, [nuevaEmpresa])

  useEffect(() => {
    trigger()
  }, [nuevaEmpresa, empresaSeleccionada])

  useEffect(() => {
    if (empresaSeleccionada.nit !== '') {
      const validarExistenciaEmpresa = async () => {
        const empresa = await ObtenerEmpresaPorNit(empresaSeleccionada.nit, '')

        if (
          empresa?.TipoFacturacion?.toUpperCase() ===
            TIPOS_FACTURACION.CREDITO &&
          !nuevaEmpresa
        ) {
          return setEmpresaExisteOEsCredito({
            empresaExiste: true,
            mensaje: 'Esta empresa ya está creada como crédito',
          })
        }
        if (empresa && nuevaEmpresa) {
          return setEmpresaExisteOEsCredito({
            empresaExiste: true,
            mensaje: 'El nit especificado ya se encuentra registrado',
          })
        }
        setEmpresaExisteOEsCredito({
          empresaExiste: false,
          mensaje: '',
        })
      }

      validarExistenciaEmpresa()
    }
  }, [empresaSeleccionada])

  const limpiarInformacion = () => {
    setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
    setEmpresasFiltradas([])
    contextoRegistro.setMediosDePagoRecarga([])
    setMedioDePagoParaAgregar(mediosDePagoPorDefecto)
    setEmpresaExisteOEsCredito({
      empresaExiste: false,
      mensaje: '',
    })
    setValoresTabla(valoresTablaPorDefecto)
    setMunicipio({})
    setValue('municipio', '')
  }
  const medioDePagoSeleccionado = ({medioDePago, valor}) => {
    if (medioDePago) {
      setMedioDePagoParaAgregar((prev) => {
        return {
          ...prev,
          id: medioDePago,
        }
      })
    } else if (valor) {
      setMedioDePagoParaAgregar((prev) => {
        return {
          ...prev,
          valor: valor,
        }
      })
    }
  }
  const calcularRetencionRenta = (valor) => {
    if (valor && valoresTabla?.montoRecarga) {
      const total = (valoresTabla?.montoRecarga * valor) / 100
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: isNaN(total) ? '' : total,
        }
      })
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: '',
        }
      })
    }
  }

  const calcularRetencionICA = (valor) => {
    if (valor && valoresTabla?.montoRecarga) {
      const total = (valoresTabla?.montoRecarga * valor) / 1000
      setValoresTabla((prev) => {
        return {
          ...prev,
          ICATotal: isNaN(total) ? '' : total,
        }
      })
      setAlertaICA(false)
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          ICATotal: '',
          bomberilTotal: '',
        }
      })
    }
  }

  const calcularSobretasaBomberil = (valor) => {
    if (!valoresTabla?.ICATotal && valor) {
      return setAlertaICA(true)
    }
    if (valor && valoresTabla?.montoRecarga) {
      const total = (valoresTabla?.ICATotal * valor) / 100
      setValoresTabla((prev) => {
        return {
          ...prev,
          bomberilTotal: isNaN(total) || !valoresTabla?.ICATotal ? '' : total,
        }
      })
      setAlertaICA(false)
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          bomberilTotal: '',
        }
      })
      setAlertaICA(false)
    }
  }

  const calcularValoresEnTabla = (nombrePropiedad, valor) => {
    const funcionesPorPropiedad = {
      retencionRenta: () => calcularRetencionRenta(valor),
      retencionICA: () => calcularRetencionICA(valor),
      sobretasaBomberil: () => calcularSobretasaBomberil(valor),
    }
    if (nombrePropiedad in funcionesPorPropiedad) {
      funcionesPorPropiedad[nombrePropiedad]()
    }
  }
  const modificarValoresTabla = (nombrePropiedad, valor) => {
    setValoresTabla((prev) => {
      return {
        ...prev,
        [nombrePropiedad]: valor ?? '',
      }
    })
    calcularValoresEnTabla(nombrePropiedad, valor)
    if (nombrePropiedad === 'montoRecarga' && !valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: '',
          ICATotal: '',
          bomberilTotal: '',
        }
      })
    }
  }
  useEffect(() => {
    const calcularValorTotal = () => {
      const valoresNumericos = [
        valoresTabla?.montoRecarga,
        valoresTabla?.rentaTotal,
        valoresTabla?.ICATotal,
        valoresTabla?.bomberilTotal,
      ]
        .filter((valor) => valor !== '')
        .map((valor) => parseFloat(valor))
      if (valoresNumericos.length >= 1) {
        const resultado = valoresNumericos
          .slice(1)
          .reduce(
            (acumulador, valor) => acumulador - valor,
            valoresNumericos[0]
          )
        return setTotalTabla(resultado.toFixed(2))
      } else {
        return setTotalTabla(0)
      }
    }
    calcularValorTotal()
  }, [
    valoresTabla?.montoRecarga,
    valoresTabla?.rentaTotal,
    valoresTabla?.ICATotal,
    valoresTabla?.bomberilTotal,
  ])

  useEffect(() => {
    calcularRetencionRenta(valoresTabla?.retencionRenta)
    calcularRetencionICA(valoresTabla?.retencionICA)
    calcularSobretasaBomberil(valoresTabla?.sobretasaBomberil)
  }, [valoresTabla?.montoRecarga, valoresTabla?.ICATotal])

  const actualizarDatosFormulario = (valor, nombre) => {
    setEmpresaSeleccionada((prev) => {
      return {
        ...prev,
        [nombre]: valor,
      }
    })
  }

  const borrarMedioDePago = (id) => {
    let nuevaLista = contextoRegistro.mediosDePagoRecarga.filter((x) => {
      return x.id !== id
    })

    contextoRegistro.setMediosDePagoRecarga(nuevaLista)
  }

  const agregarMedioDePago = async () => {
    if (
      medioDePagoParaAgregar.valor !== '' &&
      medioDePagoParaAgregar.id !== ''
    ) {
      const nuevoMedioDePago = {
        ...medioDePagoParaAgregar,
        nombre: (
          await ObtenerMediosDePagoPorId(parseInt(medioDePagoParaAgregar.id))
        )?.Nombre,
        id: medioDePagoParaAgregar.id,
      }

      const medioDePagoEnLista = contextoRegistro.mediosDePagoRecarga.find(
        (medioPago) => medioPago.id === nuevoMedioDePago.id
      )

      if (!!medioDePagoEnLista) return

      contextoRegistro.setMediosDePagoRecarga((prev) => [
        ...prev,
        nuevoMedioDePago,
      ])
      setMedioDePagoParaAgregar({
        id: '',
        nombre: '',
        valor: '',
      })
    }
  }

  useEffect(() => {
    const obtenerDatosSede = async () => {
      try {
        const sedeId = parseInt(
          fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
        )
        let informacionSede = await ObtenerSedePorId(sedeId)
        const [dataSedeNombre, dataSedeDireccion, dataSedeTelefono] =
          await Promise.all([
            informacionSede?.Nombre ?? '',
            informacionSede?.Direccion ?? '',
            informacionSede?.Telefono ?? '',
          ])

        setSede({
          nombre: dataSedeNombre,
          direccion: dataSedeDireccion,
          telefono: dataSedeTelefono,
        })
      } catch (error) {
        console.error(error)
      }
    }

    obtenerDatosSede()
  }, [])

  useEffect(() => {
    setSede((prev) => {
      return {
        ...prev,
        municipio: municipio?.filtro,
      }
    })
  }, [medioDePagoParaAgregar])

  const generarRecibo = () => {
    const sedeId = parseInt(
      fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
    )
    setSede((prev) => {
      return {
        ...prev,
        municipio: municipio?.filtro,
      }
    })

    const payload = {
      movimientoId: newMovimientoId,
      sedeId,
      sede,
      empresa: {
        documento: empresaSeleccionada.nit,
        nombre: empresaSeleccionada.filtro || empresaSeleccionada.nombre,
        direccion: empresaSeleccionada.direccion,
        telefono: empresaSeleccionada.telefono,
      },
      urlQr:
        'https://uatcendiatra.blob.core.windows.net/staticweb/codigo_qr.png',
    }

    FachadaGenerarReciboCajaRecarga(payload)
      .then((res) => {
        if (res.status === 200) {
          const file = res.data
          window.open(file, '_blank')
          limpiarInformacion()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const columnasTabla = useMemo(
    () => [
      {
        Header: '',
        accessor: 'nombre',
        className:
          'w-5/12 flex justify-center items-center content-center h-10 text-14px uppercase border-cendiatra-gris-3 border-b text-cendiatra ',
      },
      {
        Header: '',
        accessor: 'valor',
        className:
          'w-5/12 flex justify-center items-center content-center h-10 text-14px uppercase border-cendiatra-gris-3 border-b text-cendiatra-gris-3 ',
        Cell: (row) => (
          <CeldaFormatoDinero
            estilos={`text-right mr-10 appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
            valor={row.row.original.valor}
          />
        ),
      },

      {
        id: 'CeldaConBoton',
        className:
          'w-1/12 bg-white-verde-7 h-10 border-cendiatra-gris-3 border-b ',
        Cell: (row) => (
          <CeldaConBoton
            funcion={() => borrarMedioDePago(row.row.original.id)}
            imagen={BotonQuitar}
            estilos={''}
          />
        ),
      },
      {
        Header: '',
        accessor: 'id',
        className: 'hidden ',
      },
    ],
    [contextoRegistro.mediosDePagoRecarga]
  )

  const obtenerEstilosInputs = (error) => {
    let estilosCampoRequerido = ''
    let estilosCrearNuevaEmpresa = ''

    estilosCampoRequerido =
      error && nuevaEmpresa ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
    estilosCrearNuevaEmpresa = !nuevaEmpresa
      ? 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
      : 'text-cendiatra-gris-3'
    return `${estilosCrearNuevaEmpresa} ${estilosCampoRequerido}`
  }

  const manejarCiudadCambiada = (e) => {
    setError('municipio', {type: 'require', message: ''})
    setValue('municipio', e.target.value)
    if (e.target.value.length >= 3) {
      FiltrarCiudadPorNombre(e.target.value).then((res) => {
        if (res) {
          setMunicipiosFiltrados(
            res.map((ciudad) => ({
              Id: ciudad?.Id,
              filtro: ciudad?.Nombre,
            }))
          )
        }
      })
    } else {
      setMunicipio({})
      setMunicipiosFiltrados([])
    }
  }

  const manejarCiudadSeleccionada = (ciudadSeleccionada) => {
    setValue('municipio', ciudadSeleccionada?.filtro)
    clearErrors('municipio')
    setMunicipio(ciudadSeleccionada)
  }

  const validarCaracteristicasEmpresa = () => {
    return (
      !!empresaSeleccionada?.id &&
      empresaSeleccionada?.tipoFacturacion !== TIPOS_FACTURACION?.CREDITO
    )
  }

  return (
    <>
      <div className="w-full flex justify-start items-center">
        <CampoFiltrarEspañol
          estilosPersonalizados={'w-1/4'}
          titulo={'Empresa (Nit) o razón social'}
          estilosInput={
            'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
          }
          tipo={'text'}
          placeholder={'Autocompletar'}
          valorDelCampoFiltro={empresaSeleccionada.filtro}
          desactivarOtroFiltro={false}
          informacionFiltrada={empresasFiltradas}
          handleChange={manejarEmpresaCambiada}
          handleOptionChange={manejarEmpresaSeleccionada}
        />
        <BotonSimple
          texto={'Empresa nueva'}
          estilosBoton={`w-32 h-10 text-white bg-cover bg-center bg-no-repeat rounded-lg text-14px mx-2 mt-5 ${
            empresaSeleccionada.id === ''
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          } `}
          funcion={crearNuevaEmpresa}
        />
        <BotonSimple
          texto={'Limpiar'}
          estilosBoton={`w-32 h-10 text-white bg-cover bg-center bg-no-repeat rounded-lg text-14px mx-2 mt-5 ${
            empresaSeleccionada.id !== ''
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          } `}
          funcion={limpiarInformacion}
        />
      </div>
      <div className="w-full flex justify-between items-center mt-5">
        <Controller
          name="nit"
          control={control}
          rules={{
            required: nuevaEmpresa ? true : false,
            onChange: (e) => actualizarDatosFormulario(e.target.value, 'nit'),
            pattern: {
              value: regexSoloNumeros,
              message: TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_NIT,
            },
          }}
          render={({field: {onChange}, fieldState: {error}}) => (
            <Input
              estilosContenedor={'w-2/12'}
              estilosInput={`appearance-none rounded relative block w-full p-1.5 border  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm ${obtenerEstilosInputs(
                error?.type
              )}  `}
              erroresFormulario={errors}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'NIT*'}
              mostrarErrores={true}
              onChange={onChange}
              valor={empresaSeleccionada.nit}
              deshabilitado={false}
              nombre={'nit'}
            />
          )}
        />

        <Controller
          name="nombre"
          control={control}
          rules={{
            required: nuevaEmpresa ? true : false,
            onChange: (e) =>
              actualizarDatosFormulario(e.target.value, 'nombre'),
            pattern: {
              value: regexTextoCaracteresBasicos,
              message: TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_TEXTO,
            },
          }}
          render={({field: {onChange}, fieldState: {error}}) => (
            <Input
              estilosContenedor={'w-2/12'}
              estilosInput={`appearance-none rounded relative block w-full p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm
              ${obtenerEstilosInputs(error?.type)}`}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'Razón social*'}
              onChange={onChange}
              valor={empresaSeleccionada.nombre}
              deshabilitado={false}
              erroresFormulario={errors}
              mostrarErrores={true}
              nombre={'nombre'}
            />
          )}
        />
        <Controller
          name="direccion"
          control={control}
          rules={{
            required: nuevaEmpresa ? true : false,
            onChange: (e) =>
              actualizarDatosFormulario(e.target.value, 'direccion'),
            pattern: {
              value: regexTextoCaracteresBasicos,
              message: TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_TEXTO,
            },
          }}
          render={({field: {onChange}, fieldState: {error}}) => (
            <Input
              estilosContenedor={'w-2/12'}
              estilosInput={`appearance-none rounded relative block w-full p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm
              ${obtenerEstilosInputs(error?.type)}`}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'Dirección*'}
              onChange={onChange}
              nombre={'direccion'}
              valor={empresaSeleccionada.direccion}
              deshabilitado={false}
              erroresFormulario={errors}
              mostrarErrores={true}
            />
          )}
        />
        <Controller
          name="telefono"
          control={control}
          rules={{
            required: nuevaEmpresa ? true : false,
            onChange: (e) =>
              actualizarDatosFormulario(e.target.value, 'telefono'),
          }}
          render={({field: {onChange}, fieldState: {error}}) => (
            <Input
              estilosContenedor={'w-2/12'}
              estilosInput={`appearance-none rounded relative block w-full p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm
              ${obtenerEstilosInputs(error?.type)}`}
              tipo={'number'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'Teléfono*'}
              onChange={onChange}
              nombre={'telefono'}
              valor={empresaSeleccionada.telefono}
              deshabilitado={false}
            />
          )}
        />
        <Controller
          name="correo"
          control={control}
          rules={{
            required: nuevaEmpresa ? true : false,
            onChange: (e) =>
              actualizarDatosFormulario(e.target.value, 'correo'),

            pattern: {
              value: regexCorreoElectronico,
              message: TEXTO_VISUAL.FORMULARIO.CORREO_INVALIDO,
            },
          }}
          render={({field: {onChange}, fieldState: {error}}) => (
            <Input
              estilosContenedor={'w-2/12'}
              estilosInput={`appearance-none rounded relative block w-full p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm
              ${obtenerEstilosInputs(error?.type)}`}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'Correo electrónico para facturación*'}
              onChange={onChange}
              nombre={'correo'}
              valor={empresaSeleccionada.correo}
              deshabilitado={false}
              erroresFormulario={errors}
              mostrarErrores={true}
              onPaste={(evt) => evt.preventDefault()}
            />
          )}
        />
      </div>

      <div className="w-full flex justify-start items-center">
        <div className="w-1/5 flex justify-center items-center">
          <AlertaDinamica
            mostrarAlerta={empresaExisteOEsCredito.empresaExiste}
            mensaje={empresaExisteOEsCredito.mensaje}
          />
        </div>
        <div className="w-1/5 flex justify-center items-center"></div>
        <div className="w-1/5 flex justify-center items-center"></div>
        <div className="w-1/5 flex justify-center items-center"></div>
        <div className="w-1/5 flex justify-center items-center"></div>
      </div>

      <div className="w-full flex justify-center items-center border-r border-l border-t border-cendiatra rounded-tr-xl rounded-tl-xl mt-10">
        <div className="w-10/12 flex justify-center items-center flex-wrap ">
          <div className="w-10/12 flex justify-end items-center my-2">
            <div className="w-5/12 flex justify-end items-center">
              <span className="w-5/12 text-cendiatra-verde-3 font-bold">
                Monto a recargar
              </span>
              <InputMoneda
                estilosContenedor={'w-7/12 flex  items-center'}
                estilosInput={`appearance-none rounded relative block w-full p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm 
                ${
                  nuevaEmpresa || validarCaracteristicasEmpresa()
                    ? 'text-cendiatra-verde-2 font-bold text-center'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                valor={valoresTabla.montoRecarga}
                formatoVacio={true}
                onValueChange={(e) =>
                  modificarValoresTabla('montoRecarga', e.floatValue)
                }
                titulo={''}
              />
            </div>
          </div>
          <div className="w-10/12 flex justify-end items-center my-2">
            <div className="w-5/12 flex flex-wrap justify-end items-center">
              <span className="w-5/12 text-cendiatra-verde-3 font-bold">
                Municipio*
              </span>
              <Controller
                name="municipio"
                control={control}
                rules={{
                  required: true,
                }}
                render={({field: {value}, fieldState: {error}}) => (
                  <CampoFiltrarEspañol
                    titulo={''}
                    estilosPersonalizados={'w-7/12'}
                    estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border ${
                      error && (nuevaEmpresa || validarCaracteristicasEmpresa())
                        ? 'border-cendiatra-semaforo-rojo'
                        : 'border-cendiatra'
                    }   rounded-lg focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm 
              ${
                nuevaEmpresa || validarCaracteristicasEmpresa()
                  ? 'text-cendiatra-verde-2 font-bold text-center'
                  : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
              }
                  
                  `}
                    placeholder={'Autocompletar'}
                    tipo={'text'}
                    valorDelCampoFiltro={value}
                    desactivarOtroFiltro={''}
                    informacionFiltrada={municipiosFiltrados}
                    handleChange={manejarCiudadCambiada}
                    handleOptionChange={manejarCiudadSeleccionada}
                  />
                )}
              />
            </div>
          </div>
          <span className="w-8/12 my-3 text-center text-cendiatra-rojo-1 text-17px font-semibold">
            Es responsabilidad del cliente conocer e ingresar sus tarifas de
            retención. Recuerde que las retenciones se deben realizar con cada
            pago. (Art. 392 ET). Por favor, asesórese para diligenciar
            correctamente esta información.
          </span>
          <div className="w-10/12 flex justify-center items-center flex-wrap p-2 text-cendiatra font-bold">
            <ContenedorTituloResaltado
              titulo={'TARIFAS DE RETENCIÓN'}
              margenContenedor={'mb-20 border-none'}
              paddingContenedor={
                'w-full flex justify-center items-center flex-wrap '
              }
            >
              <span className="w-8/12 my-3 text-center text-cendiatra-gris-1 text-17px">
                No olvide que la base para practicar la retención en la fuente,
                inicia a partir de 4 UVT ($ 188.000 COP año 2024)
              </span>
              <InputConResultado
                titulo={
                  'Tarifa de retención de Renta (% sobre valor de recarga)'
                }
                estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm 
                ${
                  nuevaEmpresa || validarCaracteristicasEmpresa()
                    ? 'text-cendiatra-verde-3 font-bold text-center'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                placeholder={'0,00%'}
                valor={valoresTabla.retencionRenta}
                funcion={(e) =>
                  modificarValoresTabla('retencionRenta', e.floatValue)
                }
                totalCalculado={valoresTabla.rentaTotal}
              />
              <div className="w-full flex justify-center items-center flex-wrap border-2 rounded-lg py-5 my-2">
                <InputConResultado
                  titulo={
                    'Tarifa de retención ICA (valor de recarga * tarifa / 1000)'
                  }
                  estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border border-cendiatra  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm 
                ${
                  nuevaEmpresa || validarCaracteristicasEmpresa()
                    ? 'text-cendiatra-verde-2 font-bold text-center'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                  placeholder={'0,00'}
                  valor={valoresTabla.retencionICA}
                  funcion={(e) =>
                    modificarValoresTabla('retencionICA', e.floatValue)
                  }
                  totalCalculado={valoresTabla.ICATotal}
                  descripcion={'Res. DDI-023769 Grandes contribuyentes Bogotá.'}
                  porcentaje={false}
                />
                <InputConResultado
                  titulo={'Sobretasa Bomberil (% sobre el valor del ICA)'}
                  estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm 
                ${
                  nuevaEmpresa || validarCaracteristicasEmpresa()
                    ? 'text-cendiatra-verde-3 font-bold text-center'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                  placeholder={'0,00%'}
                  valor={valoresTabla.sobretasaBomberil}
                  funcion={(e) =>
                    modificarValoresTabla('sobretasaBomberil', e.floatValue)
                  }
                  totalCalculado={valoresTabla.bomberilTotal}
                />
                <div className="w-full flex justify-start items-center">
                  <div className="w-6/12"></div>
                  <div className="w-5/12">
                    <AlertaDinamica
                      mostrarAlerta={alertaICA}
                      mensaje={
                        'Se requiere la tarifa ICA para calcular Bomberil'
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-start items-center ml-2 my-2 border-b-2 pb-5">
                <InputConResultado
                  titulo={'Tarifa retención de IVA (% sobre el valor del IVA)'}
                  estilosTitulo={'text-cendiatra-gris-4'}
                  estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm 
                ${
                  false //Descativado hasta que desidan darle funcionalidad
                    ? 'text-cendiatra-gris-3'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                  valor={valoresTabla.tarifaIVA}
                  funcion={(e) =>
                    modificarValoresTabla('tarifaIVA', e.floatValue)
                  }
                  totalCalculado={valoresTabla.totalIVA}
                  porcentaje={false}
                  deshabilitarTab={true}
                />
              </div>
              <div className="w-full flex justify-end items-center my-2">
                <div className="w-3/12 flex justify-center ">
                  <span className=" text-cendiatra-verde-2 font-bold text-start text-2xl">
                    Valor a recibir
                  </span>
                </div>
                <div className="w-3/12 flex justify-center">
                  <span className="text-cendiatra-verde-2 font-bold text-center text-2xl">
                    {aplicarFormatoMoneda(totalTabla)}
                  </span>
                </div>
              </div>
              <div className="w-full flex justify-center items-center my-2">
                <span className=" text-cendiatra-gris-1 font-bold text-start text-lg">
                  Cendiatra no asume la responsabilidad de errores en la
                  información proporcionada por el cliente.
                </span>
              </div>
            </ContenedorTituloResaltado>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center items-center border border-cendiatra rounded-br-xl rounded-bl-xl -mt-10">
        <div className="w-10/12 flex justify-center items-center flex-wrap ">
          <div className="w-10/12 flex justify-center items-center flex-wrap p-2">
            <span className="text-cendiatra font-bold my-5">
              AGREGAR MEDIOS DE PAGOS DE LA RECARGA
            </span>
            <div className="w-full flex items-center justify-evenly my-5">
              <div className="w-5/12 flex justify-center items-center">
                <span className="w-5/12 text-cendiatra-verde-3 font-bold">
                  Medio de pago
                </span>
                <ListaDesplegable
                  estilosContenedor={'w-7/12'}
                  estilosLista={`appearance-none rounded w-full p-1.5 border  border-cendiatra  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm
                ${
                  nuevaEmpresa || validarCaracteristicasEmpresa()
                    ? 'text-cendiatra-gris-3'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                  titulo={''}
                  opciones={mediosDePago}
                  onChange={(e) =>
                    medioDePagoSeleccionado({medioDePago: e.target.value})
                  }
                  placeholder={'ELEGIR'}
                  valor={medioDePagoParaAgregar.id}
                  nombre={'id'}
                />
              </div>
              <div className="w-5/12 flex justify-center items-center">
                <span className="w-3/12 text-cendiatra-verde-3 font-bold">
                  Valor $
                </span>
                <InputMoneda
                  estilosContenedor={'w-7/12 flex  items-center'}
                  estilosInput={`appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm 
                ${
                  nuevaEmpresa || validarCaracteristicasEmpresa()
                    ? 'text-cendiatra-gris-3'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                  valor={medioDePagoParaAgregar.valor}
                  formatoVacio={true}
                  onValueChange={(e) =>
                    medioDePagoSeleccionado({valor: e.floatValue})
                  }
                  titulo={''}
                />
              </div>

              <BotonConImagen
                ancho={'w-1/12 flex justify-center items-center -mt-5'}
                imagen={BotonAgregar}
                textoAlternativo={'Agregar'}
                funcionAEjecutar={() => agregarMedioDePago()}
                estilosImagen={'w-8 h-8'}
              />
            </div>
            <div className="w-full flex justify-center items-center flex-wrap border border-cendiatra rounded-md my-5">
              <TablaSinPaginacion
                columns={columnasTabla}
                data={contextoRegistro.mediosDePagoRecarga}
              />
              <hr className="w-10/12 border border-cendiatra my-2" />
              <div className="w-full flex justify-center items-center mb-5">
                <span className="w-5/12 flex justify-center items-center text-cendiatra">
                  TOTAL
                </span>
                <span className="w-5/12 flex justify-center items-center text-cendiatra">
                  <CeldaFormatoDinero
                    estilos={`text-right mr-10 appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
                    valor={valorTotal}
                  />
                </span>
                <span className="w-1/12"></span>
              </div>
            </div>
            <div className="w-full flex justify-center items-center my-10">
              <BotonSimple
                texto={'Recargar'}
                estilosBoton={`w-24 h-10 text-white ${
                  valorTotal > 0 &&
                  empresaSeleccionada.tipoFacturacion !==
                    TIPOS_FACTURACION.CREDITO &&
                  parseFloat(valorTotal) === parseFloat(totalTabla) &&
                  Object.keys(errors).length === 0 &&
                  !alertaICA
                    ? 'bg-btnBg'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none'
                }  bg-center bg-cover bg-no-repeat rounded-xl text-14px m-2`}
                funcion={recargarSaldo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormularioRecargarSaldoEnSede
