import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import InputConBotonPrecios from './InputConBotonPrecios'

const ReplicarPrecios = ({
  idFila,
  actualizarTodosLosPrecios,
  posicion,
  row,
  precioAliados,
  tipoElemento,
}) => {
  const {
    control,
    formState: {isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })
  return (
    <div>
      {posicion === 0 && (
        <div>
          <Controller
            name="input"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <InputConBotonPrecios
                  estilosContenedor={'w-full text-lg'}
                  estilosInput={`flex items-center justify-between rounded relative block w-full  py-1.5 pl-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg`}
                  titulo={`Replicar precio sedes`}
                  placeholder={'$0,00'}
                  funcionInput={onChange}
                  funcionBoton={() =>
                    actualizarTodosLosPrecios(
                      value,
                      idFila,
                      'precios_sedes',
                      tipoElemento
                    )
                  }
                  tipoBoton={'submit'}
                  habilitado={value !== '' && isValid}
                  valorInput={value}
                  tituloBoton="Replicar"
                />
              </>
            )}
          />
        </div>
      )}
      {posicion === row.length - 1 && precioAliados > 0 ? (
        <div>
          <Controller
            name="input"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <InputConBotonPrecios
                  estilosContenedor={'w-full text-lg'}
                  estilosInput={`flex items-center justify-between rounded relative block w-full  py-1.5 pl-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg`}
                  titulo={`Replicar precio aliados`}
                  placeholder={'$0,00'}
                  funcionInput={onChange}
                  funcionBoton={() =>
                    actualizarTodosLosPrecios(
                      value,
                      idFila,
                      'precios_aliados',
                      tipoElemento
                    )
                  }
                  tipoBoton={'submit'}
                  habilitado={value !== '' && isValid}
                  valorInput={value}
                  tituloBoton="Replicar"
                />
              </>
            )}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ReplicarPrecios
