import React from 'react'
import { NumericFormat } from 'react-number-format'
import Boton from '../Botones/Boton'

const InputConBotonPrecios = ({
  estilosContenedor,
  titulo,
  estilosInput,
  placeholder,
  funcionInput,
  funcionBoton,
  habilitado,
  valorInput,
  tipoBoton,
  tituloBoton,
  formatoVacio,
}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <label className="block text-17px uppercase font-bold leading-15px text-cendiatra-verde-2 my-5px">
        {titulo}
      </label>
      <div className="flex items-center">
        <div className={`${estilosInput} leading-15px text-13px my-5px h-8`}>
          <NumericFormat
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
            value={valorInput}
            defaultValue={''}
            onChange={funcionInput}
            className={'focus:outline-none w-6/12'}
            placeholder={placeholder}
            allowEmptyFormatting={formatoVacio}
          />
        </div>

        <Boton
          titulo={tituloBoton}
          ancho={'w-5/12 mr-0'}
          habilitado={habilitado}
          funcionCLick={funcionBoton}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={tipoBoton}
          alto={'h-8'}
        />
      </div>
    </div>
  )
}

export default InputConBotonPrecios
