import React from 'react'
import AreaDeTexto from '../inputs/AreaDeTexto'
import BotonSeleccionarSi from '../../imagenes/BotonSeleccionarSi.svg'
import BotonSeleccionarNo from '../../imagenes/BotonSeleccionarNo.svg'
import {regexTextoCaracteresBasicos} from '../../constantes'

const BotonOpcionConObservaciones = ({
  id,
  titulo,
  estaActivo,
  observaciones,
  funcionClick,
  cambioObservaciones,
  readOnly = false,
}) => {
  var regex = regexTextoCaracteresBasicos
  var errorObservaciones = regex.test(observaciones)
    ? null
    : 'Se permiten estos caracteres: + ( ) [ ] , / - . '

  return (
    <div className={`w-full flex flex-wrap`}>
      <div
        className={`w-full flex flex-wrap justify-start items-center ${
          readOnly ? 'pointer-events-none' : ''
        }`}
      >
        <div
          className="w-2/12 align-middle select-none transition duration-200 ease-in"
          onClick={() => {
            if (readOnly) return

            funcionClick(id)
          }}
        >
          <div className="flex items-center justify-center w-full">
            {estaActivo ? (
              <img
                className="h-12 cursor-pointer"
                src={BotonSeleccionarSi}
                alt="SI"
              />
            ) : (
              <img
                className="h-12 cursor-pointer"
                src={BotonSeleccionarNo}
                alt="NO"
              />
            )}
          </div>
        </div>
        <div className="pl-2 flex flex-wrap w-9/12 text-cendiatra-verde-2 text-14px">
          <label>{titulo}</label>
        </div>
      </div>
      <span
        className={`flex text-cendiatra-semaforo-rojo w-full text-13px ${
          estaActivo ? '' : 'hidden'
        }`}
      >
        {errorObservaciones !== null && errorObservaciones}
      </span>
      <AreaDeTexto
        estilosContenedor={`w-full ${estaActivo ? '' : 'hidden'}`}
        estilosAreaDeTexto={`h-20 my-3 rounded relative block w-full  p-1.5 border ${
          errorObservaciones ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
        } text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
        valor={observaciones}
        cambioValor={(e) => {
          if (readOnly) return

          cambioObservaciones(e.target.value, id)
        }}
        readOnly={readOnly}
      />
    </div>
  )
}

export default BotonOpcionConObservaciones
