import moment from 'moment'
import {TEXTO_VISUAL, FORMATO_FECHA_GENERAL} from '../../constantes'
import {transformarNumero} from '../../utilidades'

const TEXTO_MODAL =
  TEXTO_VISUAL.RESOLUCION_FIRMA_ELECTRONICA.MODAL.GUARDADO_EXITOSO

export const ConfirmacionResolucionCreada = ({
  resolucion: {
    id,
    numeroResolucion,
    prefijo,
    activo,
    fechaInicial,
    fechaFinal,
    numeracionInicial,
    numeracionFinal,
  },
}) => {
  return (
    <div className="w-full flex flex-wrap m-2 text-cendiatra text-xl font-bold uppercase">
      <p className="w-full">
        {TEXTO_MODAL.CODIGO_RESOLUCION}: {transformarNumero(id)}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.NUMERO_RESOLUCION}: {numeroResolucion}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.PREFIJO}: {prefijo}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.ACTIVO}: {activo ? 'Si' : 'No'}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.FECHA_INICIAL}:{' '}
        {moment(fechaInicial).format(FORMATO_FECHA_GENERAL)}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.FECHA_FINAL}:{' '}
        {moment(fechaFinal).format(FORMATO_FECHA_GENERAL)}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.NUMERACION_INICIAL}: {numeracionInicial}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.NUMERACION_FINAL}: {numeracionFinal}
      </p>
    </div>
  )
}
