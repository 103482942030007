import React, {useState, createContext} from 'react'

export const ContextoAsignarTurno = createContext({})

export const AsignarTurnoConContexto = ({children}) => {
  const [formularioAsignarTurno, setFormularioAsignarTurno] = useState({
    numeroDocumento: '',
    tipoDocumento: '',
    apellidos: '',
    nombre: '',
    grupoSanguineo: '',
    fechaNacimiento: '',
    lugarNacimiento: '',
    sexo: '',
    indicativoCelular: '+57',
    celular: '',
    nacionalidad: '',
    tipoTurno: 1,
  })
  const [usuarioId, setUsuarioId] = useState('')
  const [actualizar, setActualizar] = useState(false)

  return (
    <ContextoAsignarTurno.Provider
      value={{
        formularioAsignarTurno: formularioAsignarTurno,
        setFormularioAsignarTurno: setFormularioAsignarTurno,
        usuarioId: usuarioId,
        setUsuarioId: setUsuarioId,
        actualizar: actualizar,
        setActualizar: setActualizar,
      }}
    >
      {children}
    </ContextoAsignarTurno.Provider>
  )
}
