import {useTable, usePagination, useRowSelect, useFilters} from 'react-table'
import React, {useEffect, useState} from 'react'

const TablaResultadosAliados = ({data, columns}) => {
  const [numeroInicio, setNumeroInicio] = useState(0)
  const [numeroFinal, setNumeroFinal] = useState(10)
  const [numerosParaMostrar, setNumerosparaMostrar] = useState([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: 10},
    },
    useFilters,
    usePagination,
    useRowSelect
  )

  useEffect(() => {
    const crearArregloNumeros = () => {
      if (pageOptions.length > 10) {
        let numeros = pageOptions.slice(numeroInicio, numeroFinal)
        setNumerosparaMostrar(numeros)
      } else {
        setNumerosparaMostrar(pageOptions)
      }
    }
    crearArregloNumeros()
  }, [data, pageOptions, numeroInicio, numeroFinal])

  return (
    <div className="flex flex-col w-full mb-10 px-4">
      <table
        className="w-full flex flex-wrap border-separate justify-center items-center"
        {...getTableProps()}
      >
        <thead className="flex w-full flex-wrap text-14px justify-center items-center">
          {headerGroups.map((headerGroup) => (
            <tr
              className="flex w-full flex-wrap text-center justify-center items-center"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: `${column.headerStyle} flex justify-center items-center content-center`,
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="flex w-full flex-wrap text-13px justify-center items-center"
          {...getTableBodyProps()}
        >
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className="flex w-full flex-wrap text-center justify-center items-center content-center my-1"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{backgroundColor: 'white'}}
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length > 10 ? (
        <div className="w-full flex justify-center my-5">
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => gotoPage(0)}
          >
            {'«'}
          </button>{' '}
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => previousPage()}
          >
            {'‹'}
          </button>{' '}
          {numerosParaMostrar.map((elemento) => (
            <div
              key={elemento}
              className={
                pageIndex === elemento
                  ? 'z-10 bg-cendiatra border-cendiatra text-white relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full '
                  : '  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full'
              }
              onClick={() => gotoPage(elemento)}
            >
              {elemento + 1}
            </div>
          ))}
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'›'}
          </button>{' '}
          <button
            className="text-5xl text-gray-400 pb-2 ml-2"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'»'}
          </button>{' '}
        </div>
      ) : null}
    </div>
  )
}

export default TablaResultadosAliados
