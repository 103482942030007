import React, {useState, createContext} from 'react'

export const ContextoCargarDatosAliado = createContext({})

export const CargarDatosAliadoConContexto = ({children}) => {
  const [informacionCargarDatosAliado, setInformacionCargarDatosAliado] =
    useState({})
  const [aliadoId, setAliadoId] = useState()
  const [nombreAliado, setNombreAliado] = useState('')
  const [tarifarioAliado, setTarifarioAliado] = useState([])
  const [empresaPadreSeleccionada, setEmpresaPadreSeleccionada] = useState({
    id: '',
    nombre: '',
  })
  const [empresaHijaSeleccionada, setEmpresaHijaSeleccionada] = useState({
    id: '',
  })
  const [todosLosPaquetesYServicios, setTodosLosPaquetesYServicios] = useState(
    []
  )
  const [servicios, setServicios] = useState([])
  const [laboratorios, setLaboratorios] = useState([])
  const [urlLaboratorios, setUrlLaboratorios] = useState({nombre: '', url: ''})
  const [actualizar, setActualizar] = useState(false)
  const [idResultados, setIdResultados] = useState(null)
  const [aliadoNoExiste, setAliadoNoExiste] = useState(false)
  const [deshabilitarIputs, setDeshabilitarInputs] = useState(true)
  const [aliadoParaModificar, setAliadoParaModificar] = useState({
    id: '',
    editable: true,
  })

  return (
    <ContextoCargarDatosAliado.Provider
      value={{
        informacionCargarDatosAliado: informacionCargarDatosAliado,
        setInformacionCargarDatosAliado: setInformacionCargarDatosAliado,
        aliadoId: aliadoId,
        setAliadoId: setAliadoId,
        tarifarioAliado: tarifarioAliado,
        setTarifarioAliado: setTarifarioAliado,
        empresaPadreSeleccionada: empresaPadreSeleccionada,
        setEmpresaPadreSeleccionada: setEmpresaPadreSeleccionada,
        empresaHijaSeleccionada: empresaHijaSeleccionada,
        setEmpresaHijaSeleccionada: setEmpresaHijaSeleccionada,
        todosLosPaquetesYServicios: todosLosPaquetesYServicios,
        setTodosLosPaquetesYServicios: setTodosLosPaquetesYServicios,
        servicios: servicios,
        setServicios: setServicios,
        laboratorios: laboratorios,
        setLaboratorios: setLaboratorios,
        urlLaboratorios: urlLaboratorios,
        setUrlLaboratorios: setUrlLaboratorios,
        actualizar: actualizar,
        setActualizar: setActualizar,
        idResultados: idResultados,
        setIdResultados: setIdResultados,
        aliadoNoExiste: aliadoNoExiste,
        setAliadoNoExiste: setAliadoNoExiste,
        deshabilitarIputs: deshabilitarIputs,
        setDeshabilitarInputs: setDeshabilitarInputs,
        aliadoParaModificar: aliadoParaModificar,
        setAliadoParaModificar: setAliadoParaModificar,
        nombreAliado: nombreAliado,
        setNombreAliado: setNombreAliado,
      }}
    >
      {children}
    </ContextoCargarDatosAliado.Provider>
  )
}
