import {useContext, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {ContextApplication, ContextoModales} from '../../../contexto'
import {BotonSimple} from '../../Botones'
import {AreaDeTexto} from '../../inputs'
import {
  FachadaGuardarFormulaMedica,
  GenerarFormatoFormulaMedica,
  ObtenerInformacionFormulaMedicaPorServicioAtencionId,
} from '../../../microservicios'

export function GenerarFormulaMedica() {
  const contextoAplicacion = useContext(ContextApplication)

  const contextoModales = useContext(ContextoModales)

  const {
    watch,
    control,
    getValues,
    reset,
    trigger,
    formState: {isValid},
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
    if (!!contextoModales?.parametrosFormulario?.servicioAtencionId) {
      ObtenerInformacionFormulaMedicaPorServicioAtencionId(
        contextoModales.parametrosFormulario.servicioAtencionId
      )
        .then((res) => {
          if (res.status === 204) {
            reset(contextoModales.formulaMedica)
          } else {
            reset(res.data)
            contextoModales.setFormulaMedica({
              ...contextoModales.formulaMedica,
              guardado: true,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return () => contextoModales.setFormulaOptometrica({})
  }, [])

  const funcionCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  useEffect(() => {
    const subscripcion = watch(() => {
      let valoresFormulario = getValues()
      contextoModales.setFormulaMedica({
        ...contextoModales.formulaMedica,
        valoresFormulario,
      })
    })
    return () => subscripcion.unsubscribe()
  }, [watch])

  const funcionGuardarFormulaMedica = () => {
    const datosFormulario = getValues()
    let payload = {
      servicioAtencionId:
        contextoModales.parametrosFormulario.servicioAtencionId,
      descripcionFormula: datosFormulario.descripcionFormula,
      servicioId: contextoModales.parametrosFormulario.servicioId,
    }
    FachadaGuardarFormulaMedica(payload)
      .then((res) => {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'Formato de formula médica guardado',
          contenido: '',
          botones: [
            {
              nombre: 'Regresar',
              click: funcionCerrarModal,
            },
            {
              nombre: 'Imprimir',
              click: imrpimirFormulaMedica,
            },
          ],
        })
        contextoModales.setFormulaMedica({
          ...contextoModales.formulaMedica,
          guardado: true,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const imrpimirFormulaMedica = () => {
    GenerarFormatoFormulaMedica(
      contextoModales.parametrosFormulario.servicioAtencionId
    )
      .then((res) => {
        if (res?.data) {
          window.open(res?.data?.replace(/['"]+/g, ''), '_blank').focus()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="w-full">
      <div
        className={`w-full pb-5 ${
          contextoModales.formulaMedica.guardado ? 'pointer-events-none' : ''
        }`}
      >
        <Controller
          name="descripcionFormula"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {value, onChange}}) => (
            <AreaDeTexto
              titulo={'Escriba la fórmula médica'}
              estilosContenedor={'w-full normal-case'}
              estilosAreaDeTexto={
                'rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
              }
              placeholder={'Descripción'}
              rows={6}
              cambioValor={onChange}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full">
        <BotonSimple
          texto={'Guardar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            isValid && !contextoModales.formulaMedica.guardado
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={funcionGuardarFormulaMedica}
          deshabilitado={!isValid || contextoModales.formulaMedica.guardado}
        />
        <BotonSimple
          texto={'Regresar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 bg-btnBg`}
          funcion={funcionCerrarModal}
        />
        <BotonSimple
          texto={'Imprimir'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            contextoModales.formulaMedica.guardado
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={imrpimirFormulaMedica}
          deshabilitado={!contextoModales.formulaMedica.guardado}
        />
      </div>
    </div>
  )
}
