import React from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes'
import {FormularioAgregarPreciosOtrosServicios} from '../../componentes/gerenteComercial'

const GestionDePreciosOtrosServicios = () => {
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.GERENTE_COMERCIAL.GESTION_DE_PRECIOS_PARA_OTROS_SERVICIOS
      }
      estilosContenedor={'w-11/12'}
    >
      <FormularioAgregarPreciosOtrosServicios />
    </ContenedorPagina>
  )
}

export default GestionDePreciosOtrosServicios
