import React, {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ContextoRegistro, ContextApplication} from '../../../contexto'
import {Input, ListaDesplegable, InputMoneda} from '../../inputs'
import {CampoFiltrarEspañol, BotonConImagen} from '../../index'
import {BotonSimple} from '../../Botones'
import {CeldaConPunto, CeldaConBoton, TablaOrdenServicio} from '../index'
import {CeldaFormatoDinero} from '../../Celdas'
import BotonAgregar from '../../../imagenes/addButton.png'
import BotonQuitar from '../../../imagenes/removeButton.png'
import {
  ObtenerServiciosActivosPorSede,
  ObtenerPaquetePorId,
  ObtenerTarifarioPorEmpresaId,
} from '../../../microservicios'
import {ObtenerServicioPorId} from '../../../cache/servicios-cache'
import {regexBasicoV1} from '../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../constantes'
import {CeldaConTooltipPersonalizable} from '../../Celdas/index'

const InformacionOrden = ({numeroPagina}) => {
  const {
    register,
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const contextoRegistro = useContext(ContextoRegistro)
  const contextoAplicacion = useContext(ContextApplication)

  const [actualizarTarifario, setActualizarTarifario] = useState(true)

  useEffect(() => {
    trigger()
  }, [])

  useEffect(() => {
    if (numeroPagina == contextoRegistro.numeroPagina) {
      trigger()
      contextoRegistro.setformularioActualTieneErrores(
        !isValid ||
          Object.keys(errors).length != 0 ||
          contextoRegistro.examenesSeleccionados.length == 0
      )
    }
    contextoRegistro.setInformacionOrdenServicio(getValues())
  }, [
    isValid,
    contextoRegistro.numeroPagina,
    contextoRegistro.examenesSeleccionados.length,
  ])

  useEffect(() => {
    reset(contextoRegistro.informacionOrdenServicio)
    trigger()
  }, [
    contextoRegistro.actualizar,
    contextoRegistro.informacionOrdenServicio.tipoExamen,
  ])

  useEffect(() => {
    const actualizartafirafio = async () => {
      contextoRegistro.setCancelarValidacionServiciosYPaquetes(true)
      contextoRegistro.setTarifarioRegistro(
        (
          await ObtenerTarifarioPorEmpresaId(
            contextoRegistro?.empresaSeleccionada?.id
          )
        )?.data
      )
    }
    if (contextoRegistro?.empresaSeleccionada?.id) {
      actualizartafirafio()
    }
  }, [actualizarTarifario])

  useEffect(() => {
    const establecerPaquetesYServicios = async () => {
      let serviciosIds = []
      let paqueteServiciosIds = []
      let informacionPrecios = []

      if (contextoRegistro.tarifarioRegistro !== '') {
        let sedeServicio = contextoRegistro.tarifarioRegistro?.servicios
          .find((elemento) => elemento.preciosSedes)
          ?.preciosSedes.find(
            (objeto) => objeto.sedeId == contextoAplicacion.sede.sedeId
          )?.sedeId

        let sedePaquete = contextoRegistro.tarifarioRegistro?.paquetes
          .find((elemento) => elemento.preciosSedes)
          ?.preciosSedes.find(
            (objeto) => objeto.sedeId == contextoAplicacion.sede.sedeId
          )?.sedeId

        if (!sedeServicio && !sedePaquete) {
          contextoRegistro.setSedeNoExiste(true)
          return
        }

        contextoRegistro.setSedeNoExiste(false)

        const promesas = []

        for (const servicio of contextoRegistro.tarifarioRegistro?.servicios) {
          promesas.push(
            (async () => {
              const indexDbServicio = await ObtenerServicioPorId(
                servicio.servicioId
              )
              serviciosIds.push(servicio.servicioId)
              informacionPrecios.push({
                id: servicio.servicioId,
                precio:
                  servicio?.preciosSedes?.find(
                    (elemento) =>
                      elemento.sedeId == contextoAplicacion.sede.sedeId
                  ).precio ?? 0,
                tipo: 'servicio',
                nombre: servicio.alias ?? indexDbServicio.Nombre,
                codigo: indexDbServicio.CodigoCompleto,
                ivaEnPesos:
                  (
                    servicio?.preciosSedes?.find(
                      (elemento) =>
                        elemento.sedeId == contextoAplicacion.sede.sedeId
                    ).precio *
                    (parseInt(indexDbServicio.Iva) / 100)
                  )?.toFixed(2) ?? 0,
                cantidad: '1',
              })
            })()
          )
        }

        for (const paquete of contextoRegistro.tarifarioRegistro.paquetes) {
          promesas.push(
            (async () => {
              const informacionPaquete = (
                await ObtenerPaquetePorId(paquete.paqueteId)
              ).data
              let serviciosPaquete = await Promise.all(
                informacionPaquete.servicios.map(async (servicio) => {
                  paqueteServiciosIds.push(servicio)
                  const indexDbServicio = await ObtenerServicioPorId(servicio)
                  return {
                    id: indexDbServicio.Id,
                    nombre: indexDbServicio.Nombre,
                    codigo: indexDbServicio.CodigoCompleto,
                  }
                })
              )

              informacionPrecios.push({
                id: informacionPaquete.id,
                codigo: informacionPaquete.codigoInterno,
                nombre: informacionPaquete.nombre,
                precio: contextoRegistro.tarifarioRegistro.paquetes
                  .filter(
                    (elemento) => elemento.paqueteId == informacionPaquete.id
                  )
                  .find((elemento) => elemento.preciosSedes)
                  .preciosSedes.find(
                    (objeto) => objeto.sedeId == contextoAplicacion.sede.sedeId
                  ).precio,
                tipo: 'paquete',
                cantidad: '1',
                ivaEnPesos: 0,
                examenes: serviciosPaquete,
              })
            })()
          )
        }

        await Promise.all(promesas)
        establecerExamenes(
          serviciosIds.concat(paqueteServiciosIds),
          informacionPrecios
        )
      }
    }
    establecerPaquetesYServicios()
  }, [contextoRegistro.tarifarioRegistro])

  useEffect(() => {
    const valorTotalServicios = contextoRegistro.examenesSeleccionados
      .map((item) => parseFloat(item.precio))
      .reduce((prev, curr) => prev + curr, 0)
      .toFixed(2)

    contextoRegistro.setValorTotalServicios(valorTotalServicios)

    const valorIva = contextoRegistro.examenesSeleccionados
      .reduce((prev, curr) => prev + parseFloat(curr.ivaEnPesos), 0)
      .toFixed(2)

    contextoRegistro.setValorIva(parseFloat(valorIva))
  }, [contextoRegistro.examenesSeleccionados.length])

  useEffect(() => {
    contextoRegistro.setSumatoriaMediosDePago(
      contextoRegistro.mediosDePagoSeleccionados
        .map((item) => parseFloat(item.valor))
        .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
        .toFixed(2)
    )
  }, [contextoRegistro.mediosDePagoSeleccionados.length])

  const establecerExamenes = (serviciosIds, informacionPrecios) => {
    ObtenerServiciosActivosPorSede({
      serviciosIds: serviciosIds,
      sedeId: contextoAplicacion.sede.sedeId,
    }).then((res) => {
      contextoRegistro.setTodosLosExamenes(
        informacionPrecios.map((examen) => {
          if (examen.tipo == 'servicio') {
            return {
              ...examen,
              habilitado: res.data.find(
                (elemento) => elemento.servicioId == examen.id
              )?.activo,
            }
          } else {
            return {
              ...examen,
              examenes: examen.examenes.map((item) => {
                return {
                  ...item,
                  habilitado: res.data.find(
                    (servicio) => servicio.servicioId == item.id
                  )?.activo,
                }
              }),
            }
          }
        })
      )
    })
  }

  const columns = React.useMemo(() => [
    {
      Header: 'Código del servicio o paquete',
      accessor: 'codigo',
      className: 'w-3/12 bg-cendiatra-verde-3',
    },
    {
      Header: 'Nombre del servicio o paquete',
      accessor: 'nombre',
      className: 'w-3/12 bg-cendiatra-verde-3',
      Cell: (row) => (
        <CeldaConTooltipPersonalizable texto={row.row?.original?.nombre} />
      ),
    },
    {
      Header: 'Servicio no prestado en la sede',
      accessor: 'habilitado',
      className: 'w-3/12 bg-cendiatra-verde-3',
      Cell: (row) => <CeldaConPunto mostrar={row.row.original.habilitado} />,
    },
    {
      Header: 'Precio',
      accessor: 'precio',
      className: 'w-3/12 bg-cendiatra-verde-3',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={
            'text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5  text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
          }
          valor={row.row.original.precio}
        />
      ),
    },
    {
      id: 'CeldaConBoton',
      className: 'w-12 bg-white-verde-7 -mr-10 ',
      Cell: (row) => (
        <CeldaConBoton
          funcion={() => borrarExamen(row.row.original.codigo)}
          imagen={BotonQuitar}
          estilos={''}
        />
      ),
    },
  ])

  const filtrarExamenes = async (e) => {
    contextoRegistro.setExamen(e.target.value)
    if (e.target.value.length >= 2) {
      const filtro = contextoRegistro.todosLosExamenes.filter(
        ({nombre}) =>
          nombre.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
      )
      const filtroCodigo = contextoRegistro.todosLosExamenes.filter(
        ({codigo}) =>
          codigo.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
      )
      const examenes = filtro.concat(filtroCodigo)
      contextoRegistro.setExamenesFiltrados(
        examenes.map((elemento) => {
          return {
            id: elemento.id,
            filtro: elemento.codigo + ' - ' + elemento.nombre,
            tipo: elemento.tipo,
          }
        })
      )
    } else {
      contextoRegistro.setExamenesFiltrados([])
      contextoRegistro.setExamenParaAgregar('')
    }
  }

  const examenSeleccionado = (examen) => {
    contextoRegistro.setExamen(examen.filtro)
    contextoRegistro.setExamenParaAgregar(examen)
  }

  const agregarExamen = async () => {
    if (contextoRegistro.examenParaAgregar.length < 1) {
      return
    }
    const examenSeleccionado = contextoRegistro.examenesSeleccionados.filter(
      (elemento) =>
        elemento.id === contextoRegistro.examenParaAgregar.id &&
        elemento.tipo == contextoRegistro.examenParaAgregar.tipo
    )
    if (examenSeleccionado.length > 0) {
      return
    } else {
      const nuevoServicio = contextoRegistro.todosLosExamenes.find(
        (elemento) =>
          elemento.id == contextoRegistro.examenParaAgregar.id &&
          elemento.tipo == contextoRegistro.examenParaAgregar.tipo
      )
      contextoAplicacion.setIsLoading(true)
      contextoRegistro.setExamenesSeleccionados([
        ...contextoRegistro.examenesSeleccionados,
        nuevoServicio,
      ])
      contextoRegistro.setExamenParaAgregar('')
      contextoRegistro.setExamen('')
      contextoRegistro.setExamenesFiltrados([])
      contextoAplicacion.setIsLoading(false)
    }
  }

  const borrarExamen = (codigo) => {
    let nuevaLista = contextoRegistro.examenesSeleccionados.filter((x) => {
      return x.codigo !== codigo
    })
    contextoRegistro.setExamenesSeleccionados(nuevaLista)
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center mb-50px ">
      <div className="w-full flex justify-between items-center my-2">
        <Controller
          name="numeroOrden"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-custom'}
              estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              tipo={'number'}
              placeholder={''}
              titulo={'Número de orden de servicio'}
              deshabilitado={true}
              valor={value != '' ? value : '00'}
            />
          )}
        />
        <Controller
          name="cargo"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-custom">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'cargo')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Cargo*'}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.cargo?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
      </div>
      <div className="w-full flex justify-between items-end my-2">
        <Controller
          name="tipoExamen"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-custom'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'tipoExamen')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Tipo de exámen*'}
              opciones={contextoAplicacion.tipoExamen}
              valor={value}
            />
          )}
        />
        <div className="w-custom flex justify-center items-center ">
          <span className="w-5/12 flex justify-end text-cendiatra mr-5">
            Valor total servicios
          </span>
          <Controller
            name="valorTotalServicio"
            control={control}
            rules={{
              required: false,
            }}
            render={({}) => (
              <InputMoneda
                estilosContenedor={'w-7/12'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm pointer-events-none'
                }
                valor={contextoRegistro.valorTotalServicios}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full flex justify-center items-center ">
        <div className="w-7/12 flex justify-center items-center">
          <CampoFiltrarEspañol
            titulo={'Seleccione mínimo un exámen'}
            estilosPersonalizados={'w-11/12 rounded'}
            textoTitulo={''}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm  '
            }
            placeholder={'Descripción (Autocompletar)'}
            tipo={'text'}
            valorDelCampoFiltro={contextoRegistro.examen}
            desactivarOtroFiltro={''}
            informacionFiltrada={contextoRegistro.examenesFiltrados}
            handleChange={filtrarExamenes}
            handleOptionChange={examenSeleccionado}
            id={1}
          />

          <BotonConImagen
            ancho={'w-1/12 flex justify-center items-center  ml-2 mt-2'}
            imagen={BotonAgregar}
            textoAlternativo={'Agregar'}
            funcionAEjecutar={agregarExamen}
          />
        </div>
        <div className="w-2/12 flex justify-start items-center">
          <BotonSimple
            texto={'Actualizar'}
            estilosBoton={`w-24 h-8 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2 mt-8 ml-3 z-50`}
            funcion={() => setActualizarTarifario(!actualizarTarifario)}
            tipoDeBoton={'button'}
          />
        </div>
      </div>
      <div className="w-11/12 flex flex-wrap justify-center items-center ">
        <TablaOrdenServicio
          titulo={'Agregar mínimo un servicio o paquete*'}
          data={contextoRegistro.examenesSeleccionados}
          columns={columns}
        />
      </div>
    </div>
  )
}

export default InformacionOrden
