import moment from 'moment'
import React, {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {
  PATRON_INPUT_TEXTO,
  PATRON_SOLO_NUMEROS_POSITIVOS,
  TEXTO_VISUAL,
  FORMATO_FECHA_INGLES,
} from '../../constantes'
import {ContextoResolucionFirmaElectronica} from '../../contexto'
import Fila from '../Fila'
import {Input} from '../inputs'
import {construirEstilosInput} from '../../utilidades'
import {ContenedorTituloResaltado} from '../Contenedores'
import {BotonOpcion, Boton} from '../Botones'

const TEXTO_PANTALLA = TEXTO_VISUAL.RESOLUCION_FIRMA_ELECTRONICA.FORMULARIO

export const FormularioResolucionFirmaElectronica = ({manejadorGuardar}) => {
  const contextoResolucionFirmaElectronica = useContext(
    ContextoResolucionFirmaElectronica
  )
  const [minimoFechaFinal, setMinimoFechaFinal] = useState('')
  const [minimoNumeracionFinal, setMinimoNumeracionFinal] = useState('')
  const {
    trigger,
    setValue,
    getValues,
    control,
    formState: {errors, isValid},
    reset,
  } = useForm({
    defaultValues: {
      id: '',
      fechaCreacion: moment().format(FORMATO_FECHA_INGLES),
      numeroResolucion: '',
      prefijo: '',
      activo: false,
      fechaInicial: '',
      fechaFinal: '',
      numeracionInicial: '',
      numeracionFinal: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    contextoResolucionFirmaElectronica.setformularioActualTieneErrores(
      !isValid || Object.keys(errors).length != 0
    )

    trigger()
  }, [isValid, errors])

  useEffect(() => {
    if (contextoResolucionFirmaElectronica.actualizar) {
      reset(contextoResolucionFirmaElectronica.formulario)
      trigger()

      setMinimoFechaFinal(
        moment(contextoResolucionFirmaElectronica.formulario.fechaInicial)
          .add(1, 'days')
          .format(FORMATO_FECHA_INGLES)
      )

      contextoResolucionFirmaElectronica.setResolucionId(
        contextoResolucionFirmaElectronica.formulario.id
      )
    }
  }, [contextoResolucionFirmaElectronica.actualizar])

  const restringirFechaFinal = (fecha) => {
    if (fecha === '') {
      setMinimoFechaFinal('')
    } else {
      setMinimoFechaFinal(
        moment(fecha).add(1, 'days').format(FORMATO_FECHA_INGLES)
      )
    }

    setValue('fechaFinal', '')
  }

  const restringirNumeracionFinal = (numeracionInicial) => {
    if (numeracionInicial === '') {
      setMinimoNumeracionFinal('')
    } else {
      setMinimoNumeracionFinal(parseInt(numeracionInicial) + 1)
    }

    setValue('numeracionFinal', '')
  }

  const clickGuardar = () => {
    if (typeof manejadorGuardar === 'function') {
      manejadorGuardar(getValues())
    }
  }

  const textoBotonGuardar = contextoResolucionFirmaElectronica.actualizar
    ? TEXTO_VISUAL.BOTONES.GUARDAR
    : TEXTO_VISUAL.BOTONES.CARGAR

  return (
    <>
      <Fila ancho="w-full" flexWrap={true}>
        <div className="w-4/12 flex justify-start flex-wrap">
          <Controller
            name="fechaCreacion"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                deshabilitado={true}
                estilosContenedor="w-full"
                estilosInput={construirEstilosInput(errors, name, true)}
                nombre={name}
                titulo={TEXTO_PANTALLA.FECHA_CREACION.LABEL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </Fila>
      <Fila ancho="w-full" flexWrap={true}>
        <div className="w-4/12 flex justify-start flex-wrap mr-12">
          <Controller
            name="numeroResolucion"
            control={control}
            rules={{
              pattern: {
                value: PATRON_SOLO_NUMEROS_POSITIVOS,
                message:
                  TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_SOLO_NUMEROS_POSITIVOS,
              },
              required: true,
            }}
            render={({field: {name, onChange, value}}) => (
              <Input
                deshabilitado={false}
                erroresFormulario={errors}
                estilosContenedor="w-full"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                placeholder={TEXTO_PANTALLA.NUMERO_RESOLUCION.PLACEHOLDER}
                titulo={TEXTO_PANTALLA.NUMERO_RESOLUCION.LABEL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-3/12 flex justify-start flex-wrap mr-12">
          <Controller
            name="prefijo"
            control={control}
            rules={{
              pattern: {
                value: PATRON_INPUT_TEXTO,
                message: TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_TEXTO,
              },
              required: true,
            }}
            render={({field: {name, onChange, value}}) => (
              <Input
                deshabilitado={false}
                erroresFormulario={errors}
                estilosContenedor="w-full"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                placeholder={TEXTO_PANTALLA.PREFIJO.PLACEHOLDER}
                titulo={TEXTO_PANTALLA.PREFIJO.LABEL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-2/12 flex justify-start flex-wrap">
          <Controller
            name="activo"
            control={control}
            render={({field: {onChange, value}}) => (
              <BotonOpcion
                titulo={TEXTO_PANTALLA.ACTIVO.LABEL}
                estaActivo={value}
                estilosContenedorImagen={{justifyContent: 'start'}}
                funcionClick={() => onChange(!value)}
              />
            )}
          />
        </div>
      </Fila>
      <ContenedorTituloResaltado
        titulo={TEXTO_PANTALLA.SECCION_FECHA_AUTORIZACION.TITULO}
      >
        <Fila
          ancho="w-full"
          alineacionHorizontal="justify-evenly"
          flexWrap={true}
        >
          <div className="w-5/12 flex justify-start flex-wrap">
            <Controller
              name="fechaInicial"
              control={control}
              rules={{
                required: true,
                onChange: (e) => restringirFechaFinal(e.target.value),
              }}
              render={({field: {name, onChange, value}}) => (
                <Input
                  deshabilitado={false}
                  erroresFormulario={errors}
                  estilosContenedor="w-full"
                  estilosInput={construirEstilosInput(errors, name, false)}
                  mostrarErrores={true}
                  nombre={name}
                  tipo="date"
                  titulo={
                    TEXTO_PANTALLA.SECCION_FECHA_AUTORIZACION.FECHA_INICIAL
                      .LABEL
                  }
                  valor={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="w-5/12 flex justify-start flex-wrap">
            <Controller
              name="fechaFinal"
              control={control}
              rules={{
                min: {
                  value: minimoFechaFinal,
                  message: TEXTO_VISUAL.FORMULARIO.FECHA_FINAL_ES_MENOR_INICIAL,
                },
                required: true,
              }}
              render={({field: {name, onChange, value}}) => (
                <Input
                  deshabilitado={minimoFechaFinal === ''}
                  erroresFormulario={errors}
                  estilosContenedor="w-full"
                  estilosInput={construirEstilosInput(errors, name, false)}
                  fechaMinima={minimoFechaFinal}
                  mostrarErrores={true}
                  nombre={name}
                  tipo="date"
                  titulo={
                    TEXTO_PANTALLA.SECCION_FECHA_AUTORIZACION.FECHA_FINAL.LABEL
                  }
                  valor={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </Fila>
      </ContenedorTituloResaltado>
      <ContenedorTituloResaltado
        titulo={TEXTO_PANTALLA.SECCION_CONSECUTIVO_AUTORIZACION.TITULO}
      >
        <Fila
          ancho="w-full"
          alineacionHorizontal="justify-evenly"
          flexWrap={true}
        >
          <div className="w-5/12 flex justify-start flex-wrap">
            <Controller
              name="numeracionInicial"
              control={control}
              rules={{
                pattern: {
                  value: PATRON_SOLO_NUMEROS_POSITIVOS,
                  message:
                    TEXTO_VISUAL.FORMULARIO
                      .VALOR_INVALIDO_SOLO_NUMEROS_POSITIVOS,
                },
                required: true,
                onChange: (e) => restringirNumeracionFinal(e.target.value),
              }}
              render={({field: {name, onChange, value}}) => (
                <Input
                  deshabilitado={false}
                  erroresFormulario={errors}
                  estilosContenedor="w-full"
                  estilosInput={construirEstilosInput(errors, name, false)}
                  mostrarErrores={true}
                  nombre={name}
                  placeholder={
                    TEXTO_PANTALLA.SECCION_CONSECUTIVO_AUTORIZACION
                      .CONSECUTIVO_INICIAL.PLACEHOLDER
                  }
                  tipo="number"
                  titulo={
                    TEXTO_PANTALLA.SECCION_CONSECUTIVO_AUTORIZACION
                      .CONSECUTIVO_INICIAL.LABEL
                  }
                  valor={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="w-5/12 flex justify-start flex-wrap">
            <Controller
              name="numeracionFinal"
              control={control}
              rules={{
                min: {
                  value: minimoNumeracionFinal,
                  message:
                    TEXTO_PANTALLA.SECCION_CONSECUTIVO_AUTORIZACION
                      .CONSECUTIVO_FINAL.ERROR.ES_MENOR_NUMERACION_INICIAL,
                },
                pattern: {
                  value: PATRON_SOLO_NUMEROS_POSITIVOS,
                  message:
                    TEXTO_VISUAL.FORMULARIO
                      .VALOR_INVALIDO_SOLO_NUMEROS_POSITIVOS,
                },
                required: true,
              }}
              render={({field: {name, onChange, value}}) => (
                <Input
                  deshabilitado={minimoNumeracionFinal === ''}
                  erroresFormulario={errors}
                  estilosContenedor="w-full"
                  estilosInput={construirEstilosInput(errors, name, false)}
                  mostrarErrores={true}
                  nombre={name}
                  placeholder={
                    TEXTO_PANTALLA.SECCION_CONSECUTIVO_AUTORIZACION
                      .CONSECUTIVO_FINAL.PLACEHOLDER
                  }
                  tipo="number"
                  titulo={
                    TEXTO_PANTALLA.SECCION_CONSECUTIVO_AUTORIZACION
                      .CONSECUTIVO_FINAL.LABEL
                  }
                  valor={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </Fila>
      </ContenedorTituloResaltado>
      <Fila
        alineacionHorizontal="justify-center"
        ancho="w-full mt-12"
        flexWrap={true}
      >
        <Boton
          titulo={textoBotonGuardar}
          funcionCLick={clickGuardar}
          habilitado={
            !contextoResolucionFirmaElectronica.formularioActualTieneErrores
          }
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
        />
      </Fila>
    </>
  )
}
