import moment from 'moment/moment'
import {useContext, useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import CampoFiltrarEspañol from '../../componentes/CampoFiltrarEspañol'
import {CeldaConEventoClick} from '../../componentes/Celdas/CeldaConEventoClick'
import {TablaFacturasPendientesCorreccionSede} from '../../componentes/gestionFacturacion/TablaFacturasPendientesCorreccionSede'
import {IndicadorContadores} from '../../componentes/indicador/IndicadorContadores'
import ListaDesplegable from '../../componentes/inputs/ListaDesplegable'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import {FORMATO_FECHA_GENERAL} from '../../constantes/formatos'
import {RUTAS_APP} from '../../constantes/rutas/rutasApp'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextoGestionFacturacion} from '../../contexto'
import {obtenerFacturasRechazadas} from '../../microservicios/Fachada'
import {
  filtrarDatosYCrearSugerenciasSegunTexto,
  crearSugerenciasSegunCampoFecha,
  aplicarFormatoMoneda,
} from '../../utilidades/funcionesComunes'
import {TablaConPaginacionYFiltros} from '../../componentes/Tablas'
import FiltroListaSeleccionar from '../../componentes/Filtros/FiltroListaSeleccionar'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const TEXTO_PANTALLA =
  TEXTO_VISUAL.GESTION_FACTURACION.FACTURAS_PENDIENTES_CORRECCION_SEDE

export const FacturasPendientesCorreccionSede = () => {
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const contextoGestionFacturacion = useContext(ContextoGestionFacturacion)
  const history = useHistory()
  const [
    datosIndicadorFacturasNoGeneradas,
    setdatosIndicadorFacturasNoGeneradas,
  ] = useState([])

  const [facturasPendientes, setFacturasPendientes] = useState([])
  const [informacionFiltrada, setInformacionFiltrada] = useState([])
  const [filtroNumeroFactura, setFiltroNumeroFactura] = useState('')
  const [numerosFacturaSugeridos, setNumerosFacturaSugeridos] = useState([])
  const [filtroFechaEmisionFactura, setFiltroFechaEmisionFactura] = useState('')
  const [fechasEmisionFacturaSugeridos, setFechasEmisionFacturaSugeridos] =
    useState([])

  const [
    filtroIdentificacionNombreCliente,
    setFiltroIdentificacionNombreCliente,
  ] = useState('')

  const [
    identificacionNombresClientesSugeridos,
    setIdentificacionNombresClientesSugeridos,
  ] = useState([])

  const filtrarPorNumeroFactura = (e) => {
    const textoEscrito = e.target.value.trim()

    setFiltroNumeroFactura(textoEscrito)

    const informacionFiltrada = filtrarDatosYCrearSugerenciasSegunTexto(
      facturasPendientes,
      'numeroFactura',
      textoEscrito
    )

    setNumerosFacturaSugeridos(informacionFiltrada.sugerencias)
    setInformacionFiltrada(informacionFiltrada.datosFiltrados)
    setFiltroFechaEmisionFactura('')
    setFiltroIdentificacionNombreCliente('')
  }

  const manejarNumeroFacturaSeleccionado = (numeroFacturaSeleccionado) => {
    const valorFiltro = numeroFacturaSeleccionado.filtro
    const informacionFiltrada = facturasPendientes.filter(
      (factura) => factura.numeroFactura === valorFiltro
    )

    setFiltroNumeroFactura(valorFiltro)
    setInformacionFiltrada(informacionFiltrada)
    setFiltroFechaEmisionFactura('')
    setFiltroIdentificacionNombreCliente('')
  }

  const manejarfechaEmisionFacturaSeleccionada = (
    fechaEmisionFacturaSeleccionada
  ) => {
    const valorFiltro = fechaEmisionFacturaSeleccionada.target.value

    if (valorFiltro.length > 0) {
      const informacionFiltrada = facturasPendientes.filter(
        (factura) =>
          moment(factura.fechaEmision).format(FORMATO_FECHA_GENERAL) ===
          valorFiltro
      )

      setInformacionFiltrada(informacionFiltrada)
    } else {
      setInformacionFiltrada(facturasPendientes)
    }

    setFiltroFechaEmisionFactura(valorFiltro)
    setFiltroNumeroFactura('')
    setFiltroIdentificacionNombreCliente('')
  }

  const filtrarPorIdentificacionNombreCliente = (e) => {
    const textoEscrito = e.target.value.trim()

    setFiltroIdentificacionNombreCliente(textoEscrito)

    const informacionFiltrada = filtrarDatosYCrearSugerenciasSegunTexto(
      facturasPendientes,
      'identificacionNombreCliente',
      textoEscrito
    )

    setIdentificacionNombresClientesSugeridos(informacionFiltrada.sugerencias)
    setInformacionFiltrada(informacionFiltrada.datosFiltrados)
  }

  const manejarIdentificacionNombreClienteSeleccionado = (
    identificacionNombreClienteSeleccionado
  ) => {
    const valorFiltro = identificacionNombreClienteSeleccionado.filtro
    const informacionFiltrada = facturasPendientes.filter(
      (factura) => factura.identificacionNombreCliente === valorFiltro
    )

    setFiltroIdentificacionNombreCliente(valorFiltro)
    setInformacionFiltrada(informacionFiltrada)
    setFiltroNumeroFactura('')
    setFiltroFechaEmisionFactura('')
  }

  const manejadorDescripcionError = (registro) => {
    contextoGestionFacturacion.setFacturaId(registro.id)
    history.push(
      RUTAS_APP.GESTION_FACTURACION
        .CORRECCION_ERRORES_TRANSMISION_FACTURA_ELECTRONICA,
      {id: registro.id}
    )
  }

  const columnas = useMemo(
    () => [
      {
        Header: () => (
          <EncabezadoFondo
            titulo={TEXTO_PANTALLA.COLUMNA.NUMERO_FACTURA.TITULO}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'numeroFactura',
        className: 'w-2/12 ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-l-lg pl-1',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo
            paddingFondoClaro={''}
            titulo={TEXTO_PANTALLA.COLUMNA.SEDE.TITULO}
          />
        ),
        accessor: 'nombreSede',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo
            paddingFondoClaro={''}
            titulo={TEXTO_PANTALLA.COLUMNA.FECHA_EMISION_FACTURA.TITULO}
          />
        ),
        accessor: 'fechaEmision',
        className: 'w-1/12 ',
        Filter: FiltroListaSeleccionar,
        headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        Cell: ({value}) => moment(value).format(FORMATO_FECHA_GENERAL),
      },
      {
        Header: () => (
          <EncabezadoFondo
            paddingFondoClaro={''}
            titulo={TEXTO_PANTALLA.COLUMNA.IDENTIFICACION_NOMBRE_CLIENTE.TITULO}
          />
        ),
        accessor: 'identificacionNombreCliente',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo
            paddingFondoClaro={''}
            titulo={TEXTO_PANTALLA.COLUMNA.VALOR_TOTAL.TITULO}
          />
        ),
        accessor: 'montoTotal',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({value}) => aplicarFormatoMoneda(value),
      },
      {
        Header: () => (
          <EncabezadoFondo
            paddingFondoClaro={''}
            titulo={TEXTO_PANTALLA.COLUMNA.ESTADO_FACTURA.TITULO}
          />
        ),
        accessor: 'estadoFacturaId',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: () => TEXTO_PANTALLA.COLUMNA.ESTADO_FACTURA.VALOR,
      },
      {
        Header: () => (
          <EncabezadoFondo
            paddingFondoClaro={''}
            titulo={TEXTO_PANTALLA.COLUMNA.DESCRIPCION_ERROR.TITULO}
          />
        ),
        accessor: 'cantidadErroresFactura',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-r-lg pr-1',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({row: {original}, value}) => (
          <CeldaConEventoClick
            texto={TEXTO_PANTALLA.COLUMNA.DESCRIPCION_ERROR.ERRORES(value)}
            manejarClick={() => manejadorDescripcionError(original)}
          />
        ),
      },
    ],
    [
      filtroNumeroFactura,
      filtroFechaEmisionFactura,
      filtroIdentificacionNombreCliente,
      numerosFacturaSugeridos,
      fechasEmisionFacturaSugeridos,
      identificacionNombresClientesSugeridos,
    ]
  )

  const datosTabla = useMemo(
    () =>
      informacionFiltrada.length > 0 ? informacionFiltrada : facturasPendientes,
    [facturasPendientes, informacionFiltrada]
  )

  useEffect(() => {
    let componenteMontado = true
    const llamarObtenerFacturasRechazadas = async () => {
      let facturas = await obtenerFacturasRechazadas(
        fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
      )

      if (componenteMontado) {
        facturas = facturas.map((factura) => ({
          ...factura,
          identificacionNombreCliente: `${factura.identificacion} ${factura.nombreCliente}`,
        }))

        const fechaHoy = moment()
        const fechaAyer = fechaHoy.clone().subtract(1, 'day')
        const indicadores = facturas.reduce(
          (indicadoresPrev, factura) => {
            const fechaEmision = moment(factura.fechaEmision)

            if (fechaEmision.isSame(fechaHoy, 'date')) {
              indicadoresPrev.hoy++
            } else if (fechaEmision.isSame(fechaAyer, 'date')) {
              indicadoresPrev.ayer++
            } else if (fechaEmision.isBefore(fechaAyer, 'date')) {
              indicadoresPrev.anteriores++
            }

            return indicadoresPrev
          },
          {
            hoy: 0,
            ayer: 0,
            anteriores: 0,
          }
        )

        setdatosIndicadorFacturasNoGeneradas([
          {
            titulo:
              TEXTO_PANTALLA.INDICADOR
                .FACTURAS_NO_GENERADAS_REQUIEREN_CORRECCION.LABEL.HOY,
            valor: indicadores.hoy,
          },
          {
            titulo:
              TEXTO_PANTALLA.INDICADOR
                .FACTURAS_NO_GENERADAS_REQUIEREN_CORRECCION.LABEL.AYER,
            valor: indicadores.ayer,
          },
          {
            titulo:
              TEXTO_PANTALLA.INDICADOR
                .FACTURAS_NO_GENERADAS_REQUIEREN_CORRECCION.LABEL.ANTERIORES,
            valor: indicadores.anteriores,
          },
        ])

        setFacturasPendientes(facturas)

        const sugerenciasFechas = crearSugerenciasSegunCampoFecha(
          facturas,
          'fechaEmision'
        )

        setFechasEmisionFacturaSugeridos(sugerenciasFechas)
      }
    }

    llamarObtenerFacturasRechazadas()

    return () => {
      componenteMontado = false
    }
  }, [])

  return (
    <ContenedorPagina tituloPagina={TEXTO_PANTALLA.TITULO}>
      <IndicadorContadores
        ancho="w-1/2"
        contadores={datosIndicadorFacturasNoGeneradas}
        tituloPrincipal={
          TEXTO_PANTALLA.INDICADOR.FACTURAS_NO_GENERADAS_REQUIEREN_CORRECCION
            .TITULO
        }
      />
      <div className="mt-2">
        <TablaConPaginacionYFiltros
          data={datosTabla}
          columns={columnas}
          estilosRow={
            'w-full bg-red-200 flex justify-center items-center rounded-full my-2'
          }
        />
      </div>
    </ContenedorPagina>
  )
}
