import React, {useCallback, useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {useLocation} from 'react-router-dom'
import {
  DatosPaciente,
  Factura,
  MediosPago,
  Motivo,
  ServiciosPaquetes,
} from '../../componentes/jefeDeSede/ResumenTransaccionModificar'
import {
  AgregarServiciosACache,
  AgregarPaquetesACache,
  ObtenerEmpresaPorId,
  ObtenerTipoDocumentoPorId,
} from '../../cache/servicios-cache'
import {DOCUMENTO} from '../../constantes'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {FachadaObtenerDatosCreacionAtencion} from '../../microservicios/Fachada'
import {transformarTipoFacturacion} from '../../utilidades/funcionesComunes'

const ResumenTransaccionModificar = () => {
  const {state} = useLocation()

  const [tipoDocumentoTransformado, setTipoDocumentoTransformado] = useState('')
  const [empresaTransformada, setEmpresaTransformada] = useState({
    empresa: '',
    nit: '',
  })

  const [empresaHijaTransformada, setEmpresaHijaTransformada] = useState({
    empresa: '',
    nit: '',
  })

  const usuario = useRef({
    nombres: '',
    apellidos: '',
    numeroDocumento: '',
    tipoDocumento: '',
  })

  const empresa = useRef({empresaId: '', empresaHijaId: ''})
  const facturacion = useRef({
    fechaComprobante: '',
    tipoFacturacionId: '',
    facturacionServicios: [],
    autorizacionCortesia: '',
    totalServicios: 0,
    cortesia: 0,
    iva: 0,
    porcentajeCortesia: 0,
    total: 0,
    subTotal: 0,
    mediosDePago: [],
  })

  const facturacionString = {
    Contado: 1,
    Crédito: 2,
  }

  const [datosUsuario, setDatosUsuario] = useState([])

  const obtenerInformacionInicial = useCallback(async () => {
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
  }, [])

  useEffect(() => {
    obtenerInformacionInicial()
  }, [obtenerInformacionInicial])

  const obtenerTipoDocumento = async (tipoDocumento) => {
    if (!tipoDocumento) return
    try {
      const data = await ObtenerTipoDocumentoPorId(tipoDocumento)

      setTipoDocumentoTransformado(DOCUMENTO[data.Nombre])
    } catch (error) {
      console.error('Error al obtener el tipo de documento:', error)
    }
  }

  const obtenerEmpresa = async (id) => {
    if (!id) return
    try {
      const res = await ObtenerEmpresaPorId(id)
      setEmpresaTransformada({empresa: res.Nombre, nit: res.Nit})
    } catch (error) {
      console.error('Error al obtener la empresa:', error)
    }
  }

  const obtenerEmpresaHija = async (id) => {
    if (!id) return
    try {
      const res = await ObtenerEmpresaPorId(id)
      setEmpresaHijaTransformada({empresa: res.Nombre, nit: res.Nit})
    } catch (error) {
      console.error('Error al obtener la empresa Hija:', error)
    }
  }

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      try {
        const respuesta = await FachadaObtenerDatosCreacionAtencion(
          state.idAtencion
        )
        if (respuesta?.status === 200) {
          const {
            usuario: {nombres, apellidos, numeroDocumento, tipoDocumento},
            empresaId,
            empresaHijaId,
            facturacion: {
              tipoFacturacionId,
              fechaComprobante,
              facturacionServicios,
              autorizacionCortesia,
              totalServicios,
              cortesia,
              iva,
              porcentajeCortesia,
              total,
              subTotal,
              mediosDePago,
            },
          } = respuesta?.data

          usuario.current = {nombres, apellidos, numeroDocumento, tipoDocumento}

          facturacion.current = {
            fechaComprobante,
            tipoFacturacionId,
            facturacionServicios,
            autorizacionCortesia,
            totalServicios,
            cortesia,
            iva,
            porcentajeCortesia,
            total,
            subTotal,
            mediosDePago,
          }

          empresa.current = {empresaId, empresaHijaId}

          obtenerTipoDocumento(tipoDocumento)
          obtenerEmpresa(empresaId)
          obtenerEmpresaHija(empresaHijaId)
        }
      } catch (error) {
        console.log(error)
      }
    }

    obtenerDatosIniciales()
  }, [state.idAtencion])

  useEffect(() => {
    setDatosUsuario([
      {
        titulo: 'Tipo De facturación',
        contenido: transformarTipoFacturacion(
          facturacion.current.tipoFacturacionId
        ),
      },
      {
        titulo: 'Fecha',
        contenido: facturacion.current.fechaComprobante
          ? moment(facturacion.current.fechaComprobante).format('DD/MM/YYYY')
          : '',
      },
      {
        titulo: 'Empresa (NIT)*',
        contenido: `${empresaTransformada.nit} - ${empresaTransformada.empresa}`,
        mostrarToolTip: true,
      },
      {
        titulo: 'Empresa hija (NIT)',
        contenido: `${empresaHijaTransformada.nit} 
        ${empresaHijaTransformada.nit ? ' - ' : ''}
        ${empresaHijaTransformada.empresa}`,
        mostrarToolTip: true,
      },
      {
        titulo: 'Nombre*',
        contenido: usuario.current.nombres,
      },
      {
        titulo: 'Apellidos*',
        contenido: usuario.current.apellidos,
      },
      {
        titulo: 'Tipo de documento*',
        contenido: tipoDocumentoTransformado,
      },
      {
        titulo: 'No. Documento*',
        contenido: usuario.current.numeroDocumento,
      },
    ])
  }, [
    tipoDocumentoTransformado,
    empresaTransformada.empresa,
    empresaHijaTransformada.empresa,
  ])

  return (
    <div>
      <ContenedorPagina tituloPagina={'RESUMEN DE TRANSACCIÓN A MODIFICAR'}>
        <div className="bg-white mt-10 rounded-t-3xl rounded-b-3xl justify-center items-center flex flex-wrap mb-16">
          <DatosPaciente datos={datosUsuario} />
          <ServiciosPaquetes
            examenes={facturacion.current.facturacionServicios}
          />
          <Factura factura={facturacion.current} />

          {facturacion.current.tipoFacturacionId ===
            facturacionString['Contado'] && (
            <MediosPago medioPago={facturacion.current.mediosDePago} />
          )}
          <Motivo datosAtencion={state.idAtencion} />
        </div>
      </ContenedorPagina>
    </div>
  )
}

export default ResumenTransaccionModificar
