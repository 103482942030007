import React, {useContext, useMemo, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import {ContextApplication} from '../../contexto'
import {
  TEXTO_VISUAL,
  RUTAS_APP,
  casosAtencionEjecutada,
  estadosAtencionEjecutada,
} from '../../constantes'
import {EncabezadoFondo, EncabezadoConSubTitulos} from '../Tablas'
import TablaDinamica from '../TablaDinamica'
import BotonConImagen from '../BotonConImagen'
import {MotivoComponenteModificarAtencionEjecutada} from '../MostrarInformacion'
import iconoEditar from '../../imagenes/iconoEditarVerde.svg'
import iconoEditarGris from '../../imagenes/iconoEditarGris.svg'
import iconoCerrar from '../../imagenes/botonCerrarCuadrado.svg'
import iconoCerrarGris from '../../imagenes/botonCerrarCuadraroGris.svg'
import {
  ObtenerEstadoAtencion,
  CambiarEnProcesoCancelacionAtencion,
} from '../../microservicios'

const TablaModificarAtencionEjecutada = ({datosExamenes = []}) => {
  const contextoAplicacion = useContext(ContextApplication)

  const {accounts} = useMsal()

  const nombreUsuario = accounts[0]?.name

  const [atencionSeleccionada, setAtencionSeleccionada] = useState({
    atencionId: 0,
    accionUsuario: '',
  })

  const history = useHistory()

  const cerrarModal = (irAInicio) => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    setAtencionSeleccionada({
      atencionId: 0,
      accionUsuario: '',
    })
    if (irAInicio) {
      history.push('/')
    }
  }

  const configurarModal = (tipo, atencionId, usuarioResponsable) => {
    let titulo
    let contenido
    let botones

    switch (tipo) {
      case casosAtencionEjecutada.EN_PROCESO:
        titulo = `${TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA.MODIFICAR_ATENCION_EJECUTADA.MODAL_ATENDIDO.TITULO}`
        contenido = ''
        botones = [
          {
            nombre: 'Finalizar',
            click: () => {
              cerrarModal(true)
            },
          },
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal()
            },
          },
        ]
        break
      case casosAtencionEjecutada.BLOQUEO:
        titulo = `LA ATENCION # 01 ESTA EN PROCESO DE MODIFICACIÓN POR PARTE DEL USUARIO: RECEPECIÓN 2`
        contenido = ''
        botones = [
          {
            nombre: 'Finalizar',
            click: () => {
              cerrarModal(true)
            },
          },
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal()
            },
          },
        ]
        break
      case casosAtencionEjecutada.MODIFICACION:
        titulo = `${TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA.MODIFICAR_ATENCION_EJECUTADA.MODAL_MODIFICAR.TITULO}`
        contenido = ''
        botones = [
          {
            nombre: 'Todo',
            click: () => {
              irAResumen(atencionId)
            },
          },
          {
            nombre: 'Empresa hija y/o cargo',
            click: () => {
              abrirModal({
                tipo: casosAtencionEjecutada.MODIFICACION_EMPRESA_CARGO,
              })
            },
          },
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal()
            },
          },
        ]
        break
      case casosAtencionEjecutada.MODIFICACION_COMPLETA:
      case casosAtencionEjecutada.MODIFICACION_EMPRESA_CARGO:
        titulo = `${TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA.MODIFICAR_ATENCION_EJECUTADA.MODAL_MOTIVO.TITULO}`
        contenido = (
          <MotivoComponenteModificarAtencionEjecutada
            tipoMotivo={tipo}
            datosAtencion={atencionSeleccionada}
            funcionCerrar={() => cerrarModal()}
            tituloSegundoBoton={'Continuar'}
            history={history}
          />
        )
        botones = []
        break

      case estadosAtencionEjecutada.EN_PROCESO_MODIFICACION:
        titulo = (
          <div className="w-full flex flex-wrap justify-center items-center">
            <span className="w-full text-center my-2">
              LA ATENCIÓN #{atencionId} ESTA EN PROCESO DE MODIFICACIÓN
            </span>
            <span className="w-full text-center ">POR PARTE DEL USUARIO:</span>
          </div>
        )
        contenido = (
          <div className="w-full flex flex-wrap justify-center items-center uppercase text-cendiatra">
            {usuarioResponsable}
          </div>
        )
        botones = [
          {
            nombre: 'Finalizar',
            click: () => {
              cerrarModal(true)
            },
          },
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal()
            },
          },
        ]
        break

      case estadosAtencionEjecutada.EN_PROCESO_CANCELACION:
        titulo = (
          <div className="w-full flex flex-wrap justify-center items-center">
            <span className="w-full text-center my-2">
              LA ATENCIÓN #{atencionId} ESTA EN PROCESO DE CANCELACIÓN
            </span>
            <span className="w-full text-center ">POR PARTE DEL USUARIO:</span>
          </div>
        )
        contenido = (
          <div className="w-full flex flex-wrap justify-center items-center uppercase text-cendiatra">
            {usuarioResponsable}
          </div>
        )
        botones = [
          {
            nombre: 'Finalizar',
            click: () => {
              cerrarModal(true)
            },
          },
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal()
            },
          },
        ]
        break

      default:
        titulo = ''
        contenido = ''
        botones = []
    }

    return {
      titulo,
      contenido,
      botones,
    }
  }

  const abrirModal = ({tipo, atencionId = 0, usuarioResponsable}) => {
    const {titulo, contenido, botones} = configurarModal(
      tipo,
      atencionId,
      usuarioResponsable
    )

    contextoAplicacion.setModal({
      abierto: true,
      titulo,
      contenido,
      botones,
    })
  }

  const handleClick = async (atencionId, accionUsuario) => {
    setAtencionSeleccionada({
      atencionId: atencionId,
      accionUsuario: accionUsuario,
    })
  }

  useEffect(() => {
    const abrirModalConfirmacion = async () => {
      if (atencionSeleccionada.atencionId !== 0) {
        await manejarAccionUsuario(
          atencionSeleccionada.atencionId,
          atencionSeleccionada.accionUsuario
        )
      }
    }
    abrirModalConfirmacion()
  }, [atencionSeleccionada.atencionId])

  const manejarAccionUsuario = async (atencionId, accionUsuario) => {
    const validacionAtencionOcupada = await ObtenerEstadoAtencion(atencionId)

    if (validacionAtencionOcupada?.status === 200) {
      if (
        validacionAtencionOcupada?.data?.enProceso &&
        validacionAtencionOcupada?.data?.estadoAtencionId ===
          estadosAtencionEjecutada.EN_PROCESO_MEDICO
      ) {
        abrirModal({
          tipo: casosAtencionEjecutada.EN_PROCESO,
        })
        return
      }

      if (
        validacionAtencionOcupada?.data?.estadoAtencionId ===
          estadosAtencionEjecutada.EN_PROCESO_MODIFICACION &&
        nombreUsuario !== validacionAtencionOcupada?.data?.nombreResponsable
      ) {
        abrirModal({
          tipo: estadosAtencionEjecutada.EN_PROCESO_MODIFICACION,
          atencionId: atencionId,
          usuarioResponsable:
            validacionAtencionOcupada?.data?.nombreResponsable ?? '',
        })
        return
      }

      if (
        validacionAtencionOcupada?.data?.estadoAtencionId ===
          estadosAtencionEjecutada.EN_PROCESO_CANCELACION &&
        nombreUsuario !== validacionAtencionOcupada?.data?.nombreResponsable
      ) {
        abrirModal({
          tipo: estadosAtencionEjecutada.EN_PROCESO_CANCELACION,
          atencionId: atencionId,
          usuarioResponsable:
            validacionAtencionOcupada?.data?.nombreResponsable ?? '',
        })
        return
      }
      if (accionUsuario === casosAtencionEjecutada.CANCELAR) {
        const cambiarEstadoAtencion = await CambiarEnProcesoCancelacionAtencion(
          {atencionId}
        )
        if (cambiarEstadoAtencion?.status === 200) {
          history.push(
            RUTAS_APP.RECEPCION.MODIFICAR_CANCELAR_ATENCION_EMPRESA_HIJA_CARGO,
            {
              idAtencion: atencionId,
              cancelar: true,
            }
          )
        }
        return
      }
      if (accionUsuario === casosAtencionEjecutada.MODIFICACION) {
        abrirModal({
          tipo: casosAtencionEjecutada.MODIFICACION,
          atencionId: atencionId,
        })
        return
      }
      // if (estado === casosAtencionEjecutada.BLOQUEO) {
      //   abrirModal(casosAtencionEjecutada.BLOQUEO)
      //   return
      // }ESTE CODIGO SE HABILITARA CUANDO SE AVANCE MAS EN EL DESARROLLO
    }
  }

  const irAResumen = (atencionId) => {
    history.push(RUTAS_APP.JEFE_DE_SEDE.RESUMEN_TRANSACCION_MODIFICAR, {
      idAtencion: atencionId,
    })
    cerrarModal()
  }

  const columnas = useMemo(
    () => [
      {
        Header: () => <EncabezadoFondo titulo={'Número de atención'} />,
        accessor: 'atencionId',
        className:
          'w-1/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Fecha de atención'} />,
        accessor: 'fechaAtencion',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Tipo de facturación'} />,
        accessor: 'tipoFacturacion',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Documento electronico'} />,
        accessor: 'documentosElectronicos',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) => {
          return row?.original?.documentosElectronicos?.map((documento) => (
            <span
              className={`w-full flex justify-center items-center ${
                documento.esNotaCredito ? 'text-cendiatra-rojo-1' : ''
              }`}
            >
              {documento.numeroDocumento}
            </span>
          ))
        },
      },
      {
        Header: () => <EncabezadoFondo titulo={'No de documento'} />,
        accessor: 'numeroDocumento',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Nombre Apellido'} />,
        accessor: 'nombre',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => (
          <EncabezadoConSubTitulos
            titulo={'Módulos'}
            subtitulo1={'Modificar'}
            subtitulo2={'Cancelar'}
          />
        ),
        accessor: 'modulos',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) => {
          return (
            <div className="w-full flex justify-center items-center">
              <BotonConImagen
                imagen={
                  row?.original?.estadoAtencionId !== 6
                    ? iconoEditar
                    : iconoEditarGris
                }
                estilosContenedor={'w-1/2 pb-1'}
                estilosImagen={'w-10 h-10'}
                medio={true}
                desabilitado={row?.original?.estadoAtencionId === 6}
                funcionAEjecutar={() =>
                  handleClick(
                    row.original.atencionId,
                    casosAtencionEjecutada.MODIFICACION
                  )
                }
              />
              <BotonConImagen
                imagen={
                  row?.original?.estadoAtencionId !== 6
                    ? iconoCerrar
                    : iconoCerrarGris
                }
                estilosContenedor={'w-1/2 pb-1'}
                estilosImagen={'w-10 h-10'}
                medio={true}
                desabilitado={row?.original?.estadoAtencionId === 6}
                funcionAEjecutar={() =>
                  handleClick(
                    row.original.atencionId,
                    casosAtencionEjecutada.CANCELAR
                  )
                }
              />
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <div className="w-full">
      <TablaDinamica columns={columnas} data={datosExamenes} />
    </div>
  )
}

export default TablaModificarAtencionEjecutada
