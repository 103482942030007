import React, {useContext, useEffect, useState, useRef} from 'react'
import closeImg from '../../imagenes/Salir.svg'
import {useLocation} from 'react-router-dom'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import Pestana from '../../componentes/Pestanas/Pestana'
import {ContextoRegistro, ContextApplication} from '../../contexto'
import InformacionPersonal from '../../componentes/recepcionista/formularioDeRegistro/InformacionPersonal'
import InformacionDireccion from '../../componentes/recepcionista/formularioDeRegistro/InformacionDireccion'
import InformacionGeneral from '../../componentes/recepcionista/formularioDeRegistro/InformacionGeneral'
import InformacionOrden from '../../componentes/recepcionista/formularioDeRegistro/InformacionOrden'
import FirmaFotoHuella from '../../componentes/recepcionista/formularioDeRegistro/FirmaFotoHuella'
import Facturacion from '../../componentes/recepcionista/formularioDeRegistro/Facturacion'
import {ObtenerEpsPorNombre} from '../../cache/servicios-cache/Eps'
import {ObtenerArlPorNombre} from '../../cache/servicios-cache/Arl'
import {ObtenerFondoDePensionPorNombre} from '../../cache/servicios-cache/FondoPension'
import {
  ObtenerDepartamentoPorId,
  ObtenerDepartamentosPorNombre,
} from '../../cache/servicios-cache/Departamentos'
import {
  ObtenerCiudadPorNombre,
  ObtenerInformacionCiudadPorId,
} from '../../cache/servicios-cache/Ciudades'
import {ObtenerLocalidadPorNombre} from '../../cache/servicios-cache/Localidad'
import {
  GenerarComprobanteAtencion,
  GenerarComprobanteAtencionEmpresaCredito,
  FachadaGenerarReciboCajaAtencion,
  GenerarDocumentoFactura,
} from '../../microservicios'
import {
  registrarPaciente,
  FachadaModificarAtencion,
} from '../../microservicios/Fachada.js'
import {useHistory} from 'react-router-dom'
import {ObtenerTipoDocumentoPorId} from '../../cache/servicios-cache/TipoDocumento'
import {
  ObtenerEmpresaPorId,
  ObtenerEmpresasHijasPorIdPadre,
} from '../../cache/servicios-cache/Empresas.js'
import {ObtenerEmpresaPorIdDB} from '../../microservicios/Empresa'
import {ObtenerSedePorId} from '../../cache/servicios-cache/Sedes'
import {useMsal} from '@azure/msal-react'
import {ObtenerTipoFacturacionPorNombre} from '../../cache/servicios-cache/TipoFacturacion'
import {EmitirFactura} from '../../microservicios/Facturacion'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {useModal} from '../../hooks/useModal'
import {AMBIENTES_SIN_FIRMA} from '../../constantes/Ambientes'
import {FachadaObtenerDatosCreacionAtencion} from '../../microservicios/Fachada.js'
import moment from 'moment'
import {ObtenerLocalidadPorId} from '../../cache/servicios-cache/Localidad'
import {ObtenerArlPorId} from '../../cache/servicios-cache/Arl'
import {ObtenerEpsPorId} from '../../cache/servicios-cache/Eps'
import {ObtenerFondoDePensionPorId} from '../../cache/servicios-cache/FondoPension'
import {ObtenerTarifarioPorEmpresaId} from '../../microservicios/Tarifario'
import {CLASIFICACION_SERVICIO} from '../../constantes/textoVisual'
import {motivosModificacion} from '../../constantes/casosAtencionEjecutada'
import {FachadaFinalizarModificacionAtencion} from '../../microservicios/Fachada.js'
import {RUTAS_APP} from '../../constantes/rutas/rutasApp'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import ConfirmarDatos from '../../componentes/recepcionista/formularioDeRegistro/confirmarDatos/ConfirmarDatos.jsx'
import Botones from '../../componentes/recepcionista/formularioDeRegistro/confirmarDatos/Botones.jsx'
import {obtenerNombreAmbiente} from '../../config.js'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils.js'

const RegistrarPaciente = () => {
  const {state} = useLocation()

  const {accounts} = useMsal()

  const ejecutadaRef = useRef(false)

  const nombreUsuario = accounts[0].idTokenClaims.name
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const contextoRegistro = useContext(ContextoRegistro)

  const contextoAplicacion = useContext(ContextApplication)

  const [cerrarModal] = useModal()

  const history = useHistory()

  const [turnoId, setTurnoId] = useState(0)

  const [atencionIdModificacion, setAtencionIdModificacion] = useState(0)
  const [ambiente, setAmbiente] = useState('')

  useEffect(() => {
    obtenerNombreAmbiente().then((response) => {
      setAmbiente(response)
    })
  }, [])

  const listaPestanas = [
    'Inf. Paciente Personal',
    'Inf. Paciente Dirección',
    'Inf. General Paciente',
    'Inf. Orden Servicio',
    'Firma Foto Y Huella',
    'Facturación',
    'Confirmar datos',
  ]

  useEffect(() => {
    if (state && state.state) {
      contextoRegistro.setTurno(state.state)
    }
  }, [])

  useEffect(() => {
    contextoRegistro.setNumeroPagina(1)
    contextoRegistro.setTotalPaginas(7)
    contextoRegistro.setFoto('')
    contextoRegistro.setFirma('')
    contextoRegistro.setUsuarioId('')
    return () => {
      contextoRegistro.setModificarPaciente(false)
      contextoRegistro.setInformacionDireccion({
        paisResidencia: 'COLOMBIA',
        departamento: '',
        municipio: '',
        localidad: '',
      })
      contextoRegistro.setFoto('')
      contextoRegistro.setFirma('')
      contextoRegistro.setNumeroPagina(1)

      contextoRegistro.setTotalPaginas(7)
      contextoRegistro.setExisteAtencion(false)

      contextoRegistro.setAtencionCreada(false)

      contextoRegistro.setValidacionBoton(true)
      contextoRegistro.setValidacionBotonCredito(true)
      contextoRegistro.setModificarPacienteUsuarioId('')
      contextoRegistro.setUsuarioId('')
      contextoRegistro.setInformacionPersonal('')
      contextoRegistro.setInformacionDireccion({
        paisResidencia: 'COLOMBIA',
        departamento: '',
        municipio: '',
        localidad: '',
      })
      contextoRegistro.setInformacionGeneral({
        eps: '',
        arl: '',
        fondoPension: '',
        raza: '',
      })
      contextoRegistro.setInformacionOrdenServicio({
        numeroOrden: '',
        tipoExamen: '',
        cargo: '',
      })
      contextoRegistro.setInformacionFacturacion({})
      contextoRegistro.setEmpresaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        tipoAtencion: '',
        observaciones: '',
        tipoFacturacion: '',
      })
      contextoRegistro.setEmpresaHijaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        observaciones: '',
      })
      contextoRegistro.setTarifarioRegistro('')
      contextoRegistro.setTodosLosExamenes([])
      contextoRegistro.setNumeroDocumento('')
      contextoRegistro.setTipoDocumentoId('')
      contextoRegistro.setEmitioFactura(false)
      contextoRegistro.setUrlComprobante('')
      contextoRegistro.setExamen('')
      contextoRegistro.setExamenParaAgregar('')
      contextoRegistro.setExamenesFiltrados([])
      contextoRegistro.setExamenesSeleccionados([])
      contextoRegistro.setMediosDePagoSeleccionados([])
    }
  }, [])

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const manejarFinalizar = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    ejecutadaRef.current = false
    history.push('/main')
  }

  const manejarIrAModificarAtencion = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    ejecutadaRef.current = false
    contextoAplicacion.setMostrarModalMenu(false)
    history.push(RUTAS_APP.RECEPCION.MODIFICAR_ATENCION_EJECUTADA)
  }

  const manejarIrALlamadoPaciente = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    ejecutadaRef.current = false
    history.push('/llamarpaciente')
  }

  const manejarRegistrarNuevoPaciente = () => {
    history.replace('/registrarpaciente', null)
    ejecutadaRef.current = false
    history.go(0)
  }

  const retornarModal = (idAtencion) => {
    if (atencionIdModificacion) {
      return {
        abierto: true,
        titulo: (
          <div className="w-full flex flex-wrap justify-center items-center">
            <span className="w-full flex justify-center items-center">
              ATENCIÓN #{atencionIdModificacion}
            </span>
            <span className="w-full flex justify-center items-center">
              MODIFICADA
            </span>
          </div>
        ),
        contenido: '',
        botones: [
          {
            nombre: 'Regresar',
            click: manejaCerrarModal,
          },
        ],
      }
    } else {
      return {
        abierto: true,
        titulo: 'EL PACIENTE HA QUEDADO REGISTRADO',
        contenido: (
          <>
            <div className="w-full flex justify-center items-center flex-wrap text-cendiatra">
              {idAtencion ? (
                <span className="w-full text-cendiatra text-lg">
                  <b>NÚMERO ATENCIÓN:</b> {idAtencion}
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="w-full flex justify-center items-center flex-wrap text-cendiatra">
              <span className="w-full text-cendiatra text-lg">
                <b>PACIENTE: </b>
                {contextoRegistro.informacionPersonal.nombre.toUpperCase()}{' '}
                {contextoRegistro.informacionPersonal.apellidos.toUpperCase()}
              </span>
            </div>
          </>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejaCerrarModal,
          },
        ],
      }
    }
  }

  const manejarGuardar = async () => {
    if (
      contextoRegistro.mediosDePagoSeleccionados.length == 0 &&
      contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !=
        'CRÉDITO'
    ) {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: 'NO SE HAN INGRESADO MEDIOS DE PAGO',
        contenido: null,
        botones: [
          {
            nombre: 'Regresar',
            click: manejaCerrarModal,
          },
        ],
      })
    } else if (
      contextoRegistro.sumatoriaMediosDePago !=
        contextoRegistro.valorTotalAtencion &&
      contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !=
        'CRÉDITO'
    ) {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: 'LA SUMA DE LOS MEDIOS DE PAGO NO COINCIDE CON EL VALOR TOTAL',
        contenido: null,
        botones: [
          {
            nombre: 'Regresar',
            click: manejaCerrarModal,
          },
        ],
      })
    } else {
      let nuevoUsuarioId
      let epsId = (
        await ObtenerEpsPorNombre(contextoRegistro.informacionGeneral.eps)
      )[0].Id
      let arlId = contextoRegistro.informacionGeneral.arl
        ? (
            await ObtenerArlPorNombre(contextoRegistro.informacionGeneral.arl)
          )[0].Id
        : null
      let fondoPensionesId = (
        await ObtenerFondoDePensionPorNombre(
          contextoRegistro.informacionGeneral.fondoPension
        )
      )[0].Id
      let paisId = 1
      let departamentoId = (
        await ObtenerDepartamentosPorNombre(
          contextoRegistro.informacionDireccion.departamento
        )
      )[0].Id
      let municipioId = (
        await ObtenerCiudadPorNombre(
          contextoRegistro.informacionDireccion.municipio
        )
      ).Id
      let localidadId = contextoRegistro.informacionDireccion.localidad
        ? (
            await ObtenerLocalidadPorNombre(
              contextoRegistro.informacionDireccion.localidad
            )
          )[0].Id
        : null
      let tipoFacturacionId = (
        await ObtenerTipoFacturacionPorNombre(
          contextoRegistro.empresaSeleccionada.tipoFacturacion
        )
      )[0].Id
      let sedeId = parseInt(
        fnObtenerInformacionSessionOLocalStorage('sedeId', rol) ?? 0
      )

      let serviciosFacturacion = contextoRegistro.examenesSeleccionados.map(
        (examen) => {
          return {
            servicioId: examen?.tipo === 'servicio' ? examen?.id : null,
            paqueteId: examen?.tipo === 'paquete' ? examen?.id : null,
            nombre: examen?.nombre,
            precio: examen?.precio,
            descuento: 0,
            iva: examen?.ivaEnPesos,
          }
        }
      )

      let payloadRegistro = {
        atencionId: atencionIdModificacion ?? 0,
        tipoMotivoId: atencionIdModificacion
          ? motivosModificacion.MODIFICACION_COMPLETA
          : 0,
        epsId: epsId,
        arlId: arlId,
        sedeId: sedeId,
        tarifarioId: contextoRegistro.tarifarioRegistro.id,
        fondoPensionesId: fondoPensionesId,
        tipoAtencionId: contextoRegistro.informacionPersonal.tipoAtencion,
        nacionalidad:
          contextoRegistro.informacionPersonal?.nacionalidad?.toUpperCase() ??
          '',
        acompanante:
          contextoRegistro.informacionGeneral.acompanante?.toUpperCase() ?? '',
        telefonoAcompanante:
          contextoRegistro.informacionGeneral.telAcompanante ?? '',
        parentescoId:
          contextoRegistro.informacionGeneral.parentesco == ''
            ? undefined
            : contextoRegistro.informacionGeneral.parentesco,
        discapacidad:
          contextoRegistro.informacionGeneral.discapacidad?.toUpperCase() ?? '',
        ordenId: contextoRegistro.informacionOrdenServicio.numeroOrden ?? null,
        tipoTurnoId: turnoId,
        cargo:
          contextoRegistro.informacionOrdenServicio.cargo?.toUpperCase() ?? '',
        tipoExamenId: contextoRegistro.informacionOrdenServicio.tipoExamen,
        firmaURL: !AMBIENTES_SIN_FIRMA.includes(ambiente)
          ? contextoRegistro.firma
          : 'https://www.google.com',
        huellaURL: 'https://www.google.com',
        fotoURL: contextoRegistro.foto,
        usuarioId:
          contextoRegistro.usuarioId == ''
            ? nuevoUsuarioId
            : contextoRegistro.usuarioId,
        usuario: {
          tipoDocumento: contextoRegistro.informacionPersonal.tipoDocumento,
          numeroDocumento: contextoRegistro.informacionPersonal.numeroDocumento,
          nombres:
            contextoRegistro.informacionPersonal?.nombre?.toUpperCase() ?? '',
          apellidos:
            contextoRegistro.informacionPersonal?.apellidos?.toUpperCase() ??
            '',
          celular: contextoRegistro.informacionDireccion.celular,
          correo:
            contextoRegistro.informacionDireccion.correoElectronico == ''
              ? null
              : contextoRegistro.informacionDireccion.correoElectronico,
          cargo:
            contextoRegistro.informacionOrdenServicio.cargo?.toUpperCase() ??
            '',
          grupoSanguineoId:
            contextoRegistro.informacionPersonal.grupoSanguineo ?? null,
          fechaNacimiento: contextoRegistro.informacionPersonal.fechaNacimiento,
          sexoId: contextoRegistro.informacionPersonal.sexo,
          estadoCivilId: contextoRegistro.informacionPersonal.estadoCivil,
          lugarNacimiento:
            contextoRegistro.informacionPersonal.lugarNacimiento?.toUpperCase() ??
            '',
          escolaridadId: contextoRegistro.informacionPersonal.escolaridad,
          indicativo: contextoRegistro.informacionDireccion.indicativoCelular,
          razaId: contextoRegistro.informacionGeneral.raza,
          nacionalidad:
            contextoRegistro.informacionPersonal?.nacionalidad?.toUpperCase() ??
            '',
        },
        direccion: {
          paisId: paisId,
          departamentoId: departamentoId,
          municipioId: municipioId,
          localidadId: localidadId,
          zonaId: contextoRegistro.informacionDireccion.zona ?? null,
          direccion: contextoRegistro.informacionDireccion.direccion ?? '',
          estratoId: contextoRegistro.informacionDireccion.estrato,
          telefono: contextoRegistro.informacionDireccion.telefono,
          indicativo: contextoRegistro.informacionDireccion.indicativoCelular,
          celular: contextoRegistro.informacionDireccion.celular,
          correo:
            contextoRegistro.informacionDireccion.correoElectronico == ''
              ? null
              : contextoRegistro.informacionDireccion.correoElectronico,
        },
        servicios: contextoRegistro.examenesSeleccionados
          .filter((x) => x.tipo == 'servicio')
          .map((x) => x.id),
        paquetes: contextoRegistro.examenesSeleccionados
          .filter((x) => x.tipo == 'paquete')
          .map((x) => x.id),
        facturacion: {
          empresaId:
            contextoRegistro.empresaHijaSeleccionada.id != ''
              ? contextoRegistro.empresaHijaSeleccionada.id
              : contextoRegistro.empresaSeleccionada.id,
          tipoFacturacionId: tipoFacturacionId,
          fechaComprobante:
            contextoRegistro.informacionFacturacion.fechaComprobante,
          cortesia: contextoRegistro.valorCortesia || 0,
          porcentajeCortesia: contextoRegistro.porcentajeCortesia || 0,
          autorizacionCortesia:
            contextoRegistro.informacionFacturacion.autorizacion ?? '',
          totalServicios: contextoRegistro.valorTotalServicios,
          subTotal: contextoRegistro.valorSubTotalAtencion,
          iva: contextoRegistro.valorIva,
          total: contextoRegistro.valorTotalAtencion,
          mediosDePago: contextoRegistro.mediosDePagoSeleccionados.map(
            (medioDePago) => {
              return {
                medioPagoId: medioDePago.idMedioPago,
                valor: medioDePago.valor,
                voucher: medioDePago.voucher ?? '',
                codigo: '',
              }
            }
          ),
          facturacionServicios: serviciosFacturacion,
        },
      }

      if (!ejecutadaRef.current) {
        ejecutadaRef.current = true
        try {
          let respuesta
          if (atencionIdModificacion) {
            respuesta = await FachadaModificarAtencion(payloadRegistro)
          } else {
            respuesta = await registrarPaciente(payloadRegistro)
          }
          if (respuesta.status === 200) {
            if (
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ==
              'CRÉDITO'
            ) {
              contextoRegistro.setValidacionBoton(false)
            } else {
              contextoRegistro.setValidacionBotonCredito(false)
            }

            contextoRegistro.setIdAtencion(
              atencionIdModificacion ? atencionIdModificacion : respuesta?.data
            )
            contextoRegistro.setAtencionCreada(true)
            contextoAplicacion.setModal(retornarModal(respuesta?.data))
            contextoAplicacion.setMostrarModalMenu(true)
          }
        } catch (error) {
          ejecutadaRef.current = false
          console.log(error)
        }
      }
    }
  }

  const construirPayloadComprobante = async (
    contexto,
    empresaInfo,
    sedeInfo,
    nombreUsuario
  ) => {
    return {
      atencionId: contexto.idAtencion,
      empresa: {
        documento: contexto.empresaSeleccionada.nit,
        nombre: contexto.empresaSeleccionada.nombre,
        direccion: empresaInfo.Direccion,
        telefono: empresaInfo.Telefono,
      },
      paciente: {
        nombre: (
          contexto.informacionPersonal.nombre +
          ' ' +
          contexto.informacionPersonal.apellidos
        ).toUpperCase(),
        tipoDocumento: (
          await ObtenerTipoDocumentoPorId(
            parseInt(contexto.informacionPersonal.tipoDocumento)
          )
        ).Nombre,
        numeroDocumento: contexto.informacionPersonal.numeroDocumento,
      },
      itemsAtencion: contexto.examenesSeleccionados.map((examen) => ({
        codigo: examen.codigo,
        concepto: examen.nombre,
        cantidad: 1,
        valor: examen.precio,
      })),
      totalServicios: contexto.valorTotalServicios,
      cortesia: contexto.valorCortesia,
      subTotal: contexto.valorTotalAtencion,
      mediosDePago: contexto.mediosDePagoSeleccionados.map((metodoDePago) => ({
        nombre: metodoDePago.medioDePago,
        valor: metodoDePago.valor,
        descripcion: metodoDePago.voucher,
      })),
      recepcionista: nombreUsuario,
      urlQR:
        'https://uatcendiatra.blob.core.windows.net/staticweb/codigo_qr.png',
      urlLogoCendiatra:
        'https://uatcendiatra.blob.core.windows.net/staticweb/logo_cendiatra.png',
      sede: {
        nombre: sedeInfo.Nombre,
        direccion: sedeInfo.Direccion,
        telefono: sedeInfo.Telefono,
      },
    }
  }

  const manejarEmitirComprobante = async () => {
    const sedeInfo = await ObtenerSedePorId(
      parseInt(fnObtenerInformacionSessionOLocalStorage('sedeId', rol) ?? 0)
    )
    const empresaInfo = await ObtenerEmpresaPorId(
      contextoRegistro.empresaSeleccionada.id
    )
    const payload = await construirPayloadComprobante(
      contextoRegistro,
      empresaInfo,
      sedeInfo,
      nombreUsuario
    )

    GenerarComprobanteAtencion(payload)
      .then((res) => {
        contextoRegistro.setValidacionBoton(false)
        let url = res.data.replace('  ', '')
        window.open(url.replace(/['"]+/g, ''), '_blank').focus()
        contextoRegistro.setUrlComprobante(res.data.replace('  ', ''))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarEmitirComprobanteCredito = async () => {
    const sedeInfo = await ObtenerSedePorId(
      parseInt(fnObtenerInformacionSessionOLocalStorage('sedeId', rol) ?? 0)
    )
    const empresaInfo = await ObtenerEmpresaPorId(
      contextoRegistro.empresaSeleccionada.id
    )
    const payload = await construirPayloadComprobante(
      contextoRegistro,
      empresaInfo,
      sedeInfo,
      nombreUsuario
    )

    GenerarComprobanteAtencionEmpresaCredito(payload)
      .then((res) => {
        contextoRegistro.setValidacionBotonCredito(false)
        let url = res.data.replace('  ', '')
        window.open(url.replace(/['"]+/g, ''), '_blank').focus()
        contextoRegistro.setUrlComprobante(res.data.replace('  ', ''))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const imprimirComprobante = () => {
    window
      .open(contextoRegistro.urlComprobante.replace(/['"]+/g, ''), '_blank')
      .focus()
  }

  const generarReciboDeCaja = (estado) => {
    FachadaGenerarReciboCajaAtencion({
      atencionId: contextoRegistro.idAtencion,
      modificacion: atencionIdModificacion,
    })
      .then((resp) => {
        contextoRegistro.setDocumentoRecibo(resp?.data)
        modalRecibo(estado, resp?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarEmitirFactura = async () => {
    let departamentoId = (
      await ObtenerDepartamentosPorNombre(
        contextoRegistro.informacionDireccion.departamento
      )
    )[0].Id
    let municipioId = (
      await ObtenerCiudadPorNombre(
        contextoRegistro.informacionDireccion.municipio
      )
    ).Id

    const empresa = await ObtenerEmpresaPorIdDB(
      contextoRegistro.empresaSeleccionada.id
    )

    let facturacionParticular = Boolean(
      contextoRegistro?.empresaSeleccionada?.tipoAtencion?.toUpperCase() ===
        TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR
    )

    let sedeId = parseInt(
      fnObtenerInformacionSessionOLocalStorage('sedeId', rol) ?? 0
    )

    let payloadFactura = {
      sedeId: sedeId,
      atencionId: contextoRegistro.idAtencion ?? '',
      tipoIdentificacion: facturacionParticular
        ? contextoRegistro.informacionPersonal.tipoDocumento ?? ''
        : 12,
      identificacion: facturacionParticular
        ? contextoRegistro.informacionPersonal.numeroDocumento ?? ''
        : empresa.data?.nit ?? '',
      verificacionNit: facturacionParticular
        ? ''
        : empresa.data?.verificacionNit ?? '',
      razonSocial: facturacionParticular
        ? contextoRegistro.informacionPersonal.nombre +
          ' ' +
          contextoRegistro.informacionPersonal.apellidos
        : contextoRegistro.empresaSeleccionada.nombre,
      telefono: facturacionParticular
        ? contextoRegistro.informacionDireccion.telefono ?? ''
        : empresa.data?.telefono ?? '',
      correo: facturacionParticular
        ? contextoRegistro.informacionDireccion.correoElectronico ?? ''
        : empresa.data?.correo ?? '',
      direccion: facturacionParticular
        ? contextoRegistro.informacionDireccion?.direccion ?? ''
        : empresa.data?.direccion ?? '',
      codigoPostal: empresa.data?.facturacionEmpresa?.codigoPostal ?? '',
      actividadEconomica:
        empresa.data?.facturacionEmpresa?.actividadEconomica ?? '',
      departamentoId: departamentoId ?? '',
      municipioId: municipioId ?? '',
      responsabilidadFiscal:
        empresa.data?.facturacionEmpresa?.rut53 !== '0'
          ? empresa.data?.facturacionEmpresa?.rut53
          : '',
      subtotal: contextoRegistro.valorTotalServicios ?? '',
      montoTotal: contextoRegistro.valorTotalAtencion ?? '',
      descuento: contextoRegistro.valorCortesia ?? '',
      baseImpuesto: contextoRegistro.valorIva ?? '',
      montoPagado: contextoRegistro.valorTotalAtencion ?? '',
      servicios: contextoRegistro.examenesSeleccionados.map((examen) => {
        return {
          servicioId: examen.id,
          nombre: examen.nombre,
          cantidad: 1,
          precio: examen.precio,
          descuento: 0,
          esPaquete: examen.tipo === 'paquete' ? true : false,
        }
      }),
    }

    let estado = 2
    try {
      await EmitirFactura(payloadFactura).then((respuesta) => {
        const facturaId = respuesta?.data?.facturaId
        estado = respuesta?.data?.estadoFacturaId
        if (estado === 3) {
          GenerarDocumentoFactura({
            facturaId,
          }).then((resp) => {
            contextoRegistro.setDocumentoRecibo(resp?.data)
            modalRecibo(estado, resp?.data)
          })
        } else {
          generarReciboDeCaja(estado)
        }

        contextoRegistro.setEmitioFactura(respuesta.status === 200)
      })

      contextoRegistro.setValidacionBoton(false)
      contextoRegistro.setValidacionBotonCredito(false)
    } catch (error) {
      contextoRegistro.setValidacionBoton(false)
      contextoRegistro.setValidacionBotonCredito(false)
      generarReciboDeCaja(estado)
      contextoRegistro.setEmitioFactura(true)
      console.log(error)
    }
  }

  const imprimirFactura = (documento) => {
    window.open(documento, '_blank')
    manejaCerrarModal()
  }

  const imprimirFacturaBoton = () => {
    window.open(contextoRegistro.documentoRecibo, '_blank')
  }

  const MODAL_CONFIG = {
    3: {
      titulo: '',
      contenido: <span>SE HA GENERADO EXITOSAMENTE LA FACTURA</span>,
      botones: [
        {
          nombre: TEXTO_VISUAL.BOTONES.CERRAR,
          click: cerrarModal,
        },
        {
          nombre: 'Imprimir Factura',
          click: () => imprimirFactura(ContextoRegistro.documentoRecibo),
        },
      ],
    },
    2: {
      titulo:
        'NO HA SIDO POSIBLE TRANSMITIR LA FACTURA ELECTRÓNICA EN ESTE MOMENTO',
      contenido: (
        <span>
          HEMOS ENCONTRADO PROBLEMAS CON EL PROVEEDOR DE FACTURACIÓN
          ELECTRÓNICA, POR LO QUE SE GENERARÁ UN RECIBO DE CAJA EN SU LUGAR POR
          FAVOR, DESCÁRGUELO.
        </span>
      ),
      botones: [
        {
          nombre: 'Recibo de Caja',
          click: () => imprimirFactura(ContextoRegistro.documentoRecibo),
        },
      ],
    },
    4: {
      titulo: 'LA FACTURA NO FUE TRANSMITIDA Y SE DEBE CORREGIR',
      contenido: <span>IMPRIMA EL RECIBO DE CAJA PARA ENTREGAR PACIENTE</span>,
      botones: [
        {
          nombre: 'Imprimir recibo',
          click: () => imprimirFactura(ContextoRegistro.documentoRecibo),
        },
      ],
    },
  }

  const modalRecibo = (state, documento) => {
    const config = {...MODAL_CONFIG[state]}

    if (config) {
      config.botones.forEach((btn) => {
        btn.click = () => imprimirFactura(documento)
      })

      contextoAplicacion.setModal({
        abierto: true,
        ...config,
      })
    }
  }

  const manejarTerminarTransaccion = async () => {
    if (atencionIdModificacion) {
      try {
        const finalizarAtencion = await FachadaFinalizarModificacionAtencion({
          atencionId: atencionIdModificacion ?? 0,
          enviarCorreo: true,
        })

        if (finalizarAtencion.status === 200) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'TRANSACCIÓN TERMINADA',
            contenido: <></>,
            botones: [
              {
                nombre: 'Regresar',
                click: manejarIrAModificarAtencion,
              },
              {
                nombre: 'Finalizar',
                click: manejarFinalizar,
              },
            ],
          })
        }
        return
      } catch (error) {
        console.log(error)
        return
      }
    }
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'TRANSACCIÓN TERMINADA',
      contenido: <></>,
      botones: [
        {
          nombre: 'Crear Nuevo',
          click: manejarRegistrarNuevoPaciente,
        },
        {
          nombre: 'Regresar Llamado Paciente',
          click: manejarIrALlamadoPaciente,
        },
        {
          nombre: 'Finalizar',
          click: manejarFinalizar,
        },
      ],
    })
  }

  const manejarPermanecerEnPagina = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <div className="w-full flex justify-center items-center text-center text-2xl font-bold">
          NO PUEDE SALIR DE LA TRANSACCIÓN
        </div>
      ),
      subtitulo: (
        <>
          <div className="w-full flex justify-center items-center text-center text-2xl font-medium">
            DEBE TERMINAR EL REGISTRO Y EMITIR EL COMPROBANTE
          </div>
          <div className="w-full flex justify-center items-center text-center text-2xl font-medium mb-10">
            CORRESPONDIENTE.
          </div>
          <div className="w-full flex justify-center items-center text-center text-2xl font-medium">
            FINALICE CON EL BOTÓN TERMINAR
          </div>
        </>
      ),
      contenido: null,
      botones: [
        {
          nombre: 'Regresar',
          click: manejaCerrarModal,
        },
      ],
    })
  }

  useEffect(() => {
    if (state?.idAtencion) {
      setAtencionIdModificacion(state?.idAtencion)
      preCargarDatosModificarAtencion()
    }
  }, [])

  const validarExistenciaEmpresaPadre = async (datosEmpresa) => {
    if (datosEmpresa?.EmpresaPadreId !== 0) {
      return {
        empresaPadre:
          (await ObtenerEmpresaPorId(datosEmpresa?.EmpresaPadreId ?? 0)) ?? {},
        empresaHija: datosEmpresa ?? {},
      }
    }
    return {
      empresaPadre: datosEmpresa ?? {},
      empresaHija: {},
    }
  }

  const preCargarDatosModificarAtencion = async () => {
    try {
      const respuesta = await FachadaObtenerDatosCreacionAtencion(
        state?.idAtencion
      )
      if (respuesta?.status === 200) {
        const empresa = await ObtenerEmpresaPorId(
          respuesta?.data?.facturacion?.empresaId ?? 0
        )

        const datosEmpresas = await validarExistenciaEmpresaPadre(empresa)

        const TIPO_EMPRESA = {PARTICULAR: TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR}

        setTurnoId(respuesta?.data?.tipoTurnoId)
        contextoRegistro.setEmpresaSeleccionada({
          ...contextoRegistro.empresaSeleccionada,
          empresaPadreId: datosEmpresas?.empresaPadre?.EmpresaPadreId,
          id: datosEmpresas?.empresaPadre?.Id,
          nombre: `${datosEmpresas?.empresaPadre?.Nit} - ${datosEmpresas?.empresaPadre?.Nombre}`,
          nit: datosEmpresas?.empresaPadre?.Nit,
          observaciones: datosEmpresas?.empresaPadre?.Observaciones,
          tipoAtencion: TIPO_EMPRESA[datosEmpresas?.empresaPadre?.Nombre] ?? '',
          tipoFacturacion:
            datosEmpresas?.empresaPadre?.TipoFacturacion?.toUpperCase(),
        })

        contextoRegistro?.setEmpresasHijas(
          await ObtenerEmpresasHijasPorIdPadre(
            empresa.EmpresaPadreId ? empresa.EmpresaPadreId : empresa.Id
          )
        )
        if (Object.keys(datosEmpresas.empresaHija).length > 0) {
          contextoRegistro.setEmpresaHijaSeleccionada({
            id: datosEmpresas?.empresaHija?.Id ?? '',
            nombre:
              `${datosEmpresas?.empresaHija?.Nit}-${datosEmpresas?.empresaHija?.Nombre}` ??
              '',
            empresaPadreId: datosEmpresas?.empresaHija?.EmpresaPadreId ?? '',
            nit: datosEmpresas?.empresaHija?.Nit ?? '',
            observaciones: datosEmpresas?.empresaHija?.Observaciones ?? '',
          })
        }

        contextoRegistro.setTarifarioRegistro(
          (
            await ObtenerTarifarioPorEmpresaId(
              empresa.EmpresaPadreId ? empresa.EmpresaPadreId : empresa.Id
            )
          ).data
        )

        contextoRegistro.setNumeroDocumento(
          respuesta?.data?.usuario?.numeroDocumento ?? ''
        )
        contextoRegistro.setTipoDocumentoId(
          respuesta?.data?.usuario?.tipoDocumento ?? ''
        )

        contextoRegistro.setInformacionPersonal({
          numeroDocumento: respuesta?.data?.usuario?.numeroDocumento ?? '',
          tipoDocumento: respuesta?.data?.usuario?.tipoDocumento ?? '',
          apellidos: respuesta?.data?.usuario?.apellidos ?? '',
          empresaNit: datosEmpresas?.empresaPadre?.Nombre ?? '',
          empresaHijaNit: datosEmpresas?.empresaHija?.Nombre ?? '',
          nombre: respuesta?.data?.usuario?.nombres ?? '',
          grupoSanguineo: respuesta?.data?.usuario?.grupoSanguineoId ?? '',
          fechaNacimiento:
            moment(respuesta?.data?.usuario?.fechaNacimiento)?.format(
              'YYYY-MM-DD'
            ) ?? '',
          sexo: respuesta?.data?.usuario?.sexoId ?? '',
          estadoCivil: respuesta?.data?.usuario?.estadoCivilId ?? '',
          lugarNacimiento: respuesta?.data?.usuario?.lugarNacimiento ?? '',
          escolaridad: respuesta?.data?.usuario?.escolaridadId ?? '',
          nacionalidad: respuesta?.data?.usuario?.nacionalidad ?? '',
          tipoAtencion: respuesta?.data?.tipoAtencionId ?? '',
        })

        contextoRegistro.setInformacionDireccion({
          celular: respuesta?.data?.direccion?.celular,
          correoElectronico: respuesta?.data?.direccion?.correo,
          departamento:
            (
              await ObtenerDepartamentoPorId(
                respuesta?.data?.direccion?.departamentoId ?? 0
              )
            )?.Nombre ?? '',
          direccion: respuesta?.data?.direccion?.direccion,
          estrato: respuesta?.data?.direccion?.estratoId ?? 0,
          indicativoCelular: respuesta?.data?.direccion?.indicativo,
          localidad:
            (
              await ObtenerLocalidadPorId(
                respuesta?.data?.direccion?.localidadId ?? 0
              )
            )?.Nombre ?? '',
          municipio:
            (
              await ObtenerInformacionCiudadPorId(
                respuesta?.data?.direccion?.municipioId ?? 0
              )
            )?.Nombre ?? '',
          paisResidencia: 'COLOMBIA',
          telefono: respuesta?.data?.direccion?.telefono,
          zona: respuesta?.data?.direccion?.zonaId,
        })

        contextoRegistro.setInformacionGeneral({
          acompanante: respuesta?.data?.acompanante ?? '',
          arl:
            (await ObtenerArlPorId(respuesta?.data?.arlId ?? 0))?.Nombre ?? '',
          composicionFamiliar: respuesta?.data?.composicionFamiliarId ?? 0,
          discapacidad: respuesta?.data?.discapacidad ?? '',
          eps:
            (await ObtenerEpsPorId(respuesta?.data?.epsId ?? 0)).Nombre ?? '',
          fondoPension:
            (
              await ObtenerFondoDePensionPorId(
                respuesta?.data?.fondoPensionesId ?? 0
              )
            ).Nombre ?? '',
          telAcompanante: respuesta?.data?.telefonoAcompanante ?? '',
          raza: respuesta?.data?.usuario?.razaId,
        })

        contextoRegistro.setFoto(respuesta?.data?.fotoURL)
        contextoRegistro.setFirma(respuesta?.data?.firmaURL)

        contextoRegistro.setActualizar(!contextoRegistro.actualizar)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const regresarAPantallPrincipal = async () => {
    if (atencionIdModificacion) {
      try {
        const cambiarEstadoAtencion =
          await FachadaFinalizarModificacionAtencion({
            atencionId: atencionIdModificacion ?? 0,
            enviarCorreo: false,
          })

        if (cambiarEstadoAtencion.status === 200) {
          history.push(RUTAS_APP.MAIN)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      history.push(RUTAS_APP.MAIN)
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITULOS_RECEPCIONISTA.REGISTRO_DE_PACIENTE}
      funcionBotonCerrar={
        contextoRegistro.atencionCreada &&
        (contextoRegistro.validacionBoton ||
          contextoRegistro.validacionBotonCredito)
          ? manejarPermanecerEnPagina
          : regresarAPantallPrincipal
      }
    >
      <div
        className={`w-full justify-center items-center flex ${
          contextoRegistro.existeAtencion ? 'pointer-events-none' : ''
        }`}
      >
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoRegistro}
          deshabilitado={contextoRegistro.atencionCreada}
        />
      </div>
      <Pestana contexto={contextoRegistro} numeroPagina={1}>
        <InformacionPersonal
          numeroPagina={1}
          modoEdicion={Boolean(state?.idAtencion)}
        />
      </Pestana>
      <Pestana contexto={contextoRegistro} numeroPagina={2}>
        <InformacionDireccion
          numeroPagina={2}
          modoEdicion={Boolean(state?.idAtencion)}
        />
      </Pestana>
      <Pestana contexto={contextoRegistro} numeroPagina={3}>
        <InformacionGeneral numeroPagina={3} />
      </Pestana>
      <Pestana contexto={contextoRegistro} numeroPagina={4}>
        <InformacionOrden numeroPagina={4} />
      </Pestana>
      <Pestana contexto={contextoRegistro} numeroPagina={5}>
        <FirmaFotoHuella
          numeroPagina={5}
          deshabilitarEdicion={Boolean(state?.idAtencion)}
        />
      </Pestana>
      <Pestana contexto={contextoRegistro} numeroPagina={6}>
        <Facturacion
          numeroPagina={6}
          modoEdicion={Boolean(state?.idAtencion)}
        />
      </Pestana>
      <Pestana
        contexto={contextoRegistro}
        numeroPagina={7}
        textoBotonUltimaPagina={'Terminar'}
        clickGuardar={manejarTerminarTransaccion}
        esModificacionAtencion={true}
      >
        <ConfirmarDatos numeroPagina={7} />
        <Botones
          clickGuardar={manejarGuardar}
          clickComprobante={manejarEmitirComprobante}
          clickComprobanteCredito={manejarEmitirComprobanteCredito}
          imprimirComprobante={imprimirComprobante}
          imprimirFacturaBoton={imprimirFacturaBoton}
          emitirFactura={manejarEmitirFactura}
        />
      </Pestana>
    </ContenedorPagina>
  )
}

export default RegistrarPaciente
