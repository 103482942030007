import React, {useContext} from 'react'
import {useTable, usePagination, useRowSelect} from 'react-table'
import BotonConImagen from '../BotonConImagen'
import expandir from '../../imagenes/Expandible/flechaDesplegableExpandirBlanco.svg'
import {ContextoRegistro} from '../../contexto'

const TablaInformacionPaquete = ({data, columns, posicion}) => {
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, rows} =
    useTable(
      {
        columns,
        data,
        initialState: {pageIndex: 0},
      },
      usePagination,
      useRowSelect
    )
  const contextoRegistro = useContext(ContextoRegistro)

  const mostrarOEsconderPrecios = () => {
    contextoRegistro.setFilaSeleccionada(
      contextoRegistro.filaSeleccionada == posicion ? -1 : posicion
    )
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <table className="w-full" {...getTableProps()}>
        <thead
          className="w-full flex justify-evenly items-center bg-cendiatra-verde-3 text-white h-10 rounded-10px mt-2 "
          style={{lineHeight: '16px'}}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              className="w-full flex justify-evenly items-center h-full p-3px  "
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: `${column.className}  h-full flex flex-wrap justify-center items-center content-center  m-3px rounded-9px font-light`,
                    },
                  ])}
                  style={{maxWidth: '500px'}}
                >
                  {column.render('Header')}
                </th>
              ))}
              <th className="w-10 " style={{marginLeft: '-34px'}}>
                <BotonConImagen
                  estilosImagen={'w-20 -ml-24'}
                  imagen={expandir}
                  medio={true}
                  funcionAEjecutar={mostrarOEsconderPrecios}
                  rotacion={
                    contextoRegistro.filaSeleccionada == posicion ? '180' : '0'
                  }
                  textoAlternativo={'expandir'}
                />
              </th>
            </tr>
          ))}
        </thead>
        <tbody
          className="w-full"
          {...getTableBodyProps()}
          style={
            contextoRegistro.filaSeleccionada == posicion
              ? {
                  boxShadow: '0 0 0 1px rgb(28, 160, 167)',
                  borderBottomRightRadius: '10px',
                  borderBottomLeftRadius: '10px',
                }
              : null
          }
        >
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className={
                  contextoRegistro.filaSeleccionada == posicion
                    ? 'w-full flex items-center border-b-2 border-b-gris justify-evenly  text-center text-17px text-cendiatra-gris-1  mt-5 mb-2'
                    : 'hidden'
                }
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{backgroundColor: 'white', maxWidth: '500px'}}
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaInformacionPaquete
