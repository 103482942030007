import React, {useState, useLayoutEffect} from 'react'
import ReactDom from 'react-dom'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  padding: '50px',
  zIndex: 1000,
  //   border: "1px solid",
  borderRadius: '2%',
  textAlign: 'center',
}

const OVERLAY_STYLE = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  rigth: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
}

const ServicesModal = ({
  open,
  setTitle,
  title,
  setTotalServicesValue,
  laboratoryServices,
  setLaboratoryServices,
  processServices,
  setProcessServices,
  vaccineServices,
  setVaccineServices,
}) => {
  const [laboratoryTotalValue, setLaboratoryTotalValue] = useState('')

  const [processTotalValue, setProcessTotalValue] = useState('')

  const [vaccineTotalValue, setVaccineTotalValue] = useState('')

  const [showoptions, setShowOptions] = useState(false)
  const [search, setSearch] = useState('')
  const [filtredServices, setFiltredServices] = useState([])
  const [serviceToAdd, setServiceToAdd] = useState('')

  const formatter = new Intl.NumericFormat({
    style: 'currency',
    currency: 'USD',
  })

  useLayoutEffect(() => {
    const getAllTotalValues = () => {
      if (laboratoryServices) {
        const sumLab = laboratoryServices.reduce((a, b) => a + b.price, 0)

        setLaboratoryTotalValue(sumLab)
      }

      if (processServices) {
        const sumProc = processServices.reduce((a, b) => a + b.price, 0)

        setProcessTotalValue(sumProc)
      }

      if (vaccineServices) {
        const sumVacc = vaccineServices.reduce((a, b) => a + b.price, 0)

        setVaccineTotalValue(sumVacc)
      }
    }
    getAllTotalValues()
  }, [laboratoryServices, processServices, vaccineServices])

  useLayoutEffect(() => {
    const calculateTotalValue = () => {
      const totalValue = [
        laboratoryTotalValue,
        processTotalValue,
        vaccineTotalValue,
      ].reduce((a, b) => a + b, 0)
      setTotalServicesValue(totalValue)
    }
    calculateTotalValue()
  }, [laboratoryTotalValue, processTotalValue, vaccineTotalValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const changeSearch = (e) => {
    setSearch(e.target.value)

    if (e.target.value.length >= 4) {
      //Pendiente: Buscar en caché
      if (showoptions === false) {
        setShowOptions(true)
      }
    } else {
      setShowOptions(false)
    }
  }

  const clearFields = () => {
    setTitle('')
    setSearch('')
    setShowOptions(false)
    setFiltredServices([])
    setServiceToAdd('')
  }

  const addService = (service) => {
    if (service === '') {
      return
    }
    if (laboratoryServices.find((item) => item.name === service.ServiceName)) {
      return
    }
    if (processServices.find((item) => item.name === service.ServiceName)) {
      return
    }
    if (vaccineServices.find((item) => item.name === service.ServiceName)) {
      return
    }
    if (title === 'LABORATORIOS') {
      setLaboratoryServices([
        ...laboratoryServices,
        {
          itemId: service.Id,
          name: service.ServiceName,
          price: service.Rate,
        },
      ])
    }
    if (title === 'PROCEDIMIENTOS') {
      setProcessServices([
        ...processServices,
        {
          itemId: service.Id,
          name: service.ServiceName,
          price: service.Rate,
        },
      ])
    }
    if (title === 'VACUNAS') {
      setVaccineServices([
        ...vaccineServices,
        {
          itemId: service.Id,
          name: service.ServiceName,
          price: service.Rate,
        },
      ])
    }

    setSearch('')
    setShowOptions(false)
  }
  const deleteService = (itemName) => {
    if (title === 'LABORATORIOS') {
      const filter = laboratoryServices.filter((item) => item.name !== itemName)
      setLaboratoryServices(filter)
    }
    if (title === 'PROCEDIMIENTOS') {
      const filter = processServices.filter((item) => item.name !== itemName)
      setProcessServices(filter)
    }
    if (title === 'VACUNAS') {
      const filter = vaccineServices.filter((item) => item.name !== itemName)
      setVaccineServices(filter)
    }
  }

  const selectedOption = (item) => {
    setSearch(item.ServiceName)
    setShowOptions(false)
    setServiceToAdd(item)
  }

  if (!open) return null
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLE}>
        <div
          className="border border-cendiatra w-4/5 lg:w-3/5"
          style={MODAL_STYLES}
        >
          <div className="w-full">
            <p className="text-cendiatra text-center font-bold text-3xl">
              {open}
            </p>
          </div>

          <div className="w-full flex items-center justify-evenly">
            <div className="w-3/5">
              <input
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                placeholder="Buscar..."
                autoComplete="off"
                onChange={(e) => changeSearch(e)}
                value={search}
              />
              {showoptions && (
                <div className="bg-white border border-cendiatra">
                  {filtredServices.map((item) => (
                    <div
                      className="bg-gray-100"
                      onClick={() => selectedOption(item)}
                      key={item.Id}
                      tabIndex="0"
                    >
                      <span>{item.ServiceName}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="w-2/5 flex justify-center">
              <button
                className="hover:opacity-80 items-center group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                type="button"
                onClick={() => addService(serviceToAdd)}
              >
                {' '}
                Añadir +
              </button>
            </div>
          </div>

          <div className="w-full rounded border border-opacity-50 border-gray-700 shadow-2xl my-5">
            <div className="flex flex-wrap w-full">
              <div className="w-1/3 flex justify-center">
                <p className="text-newCendiatra font-lg">Servicio</p>
              </div>
              <div className="w-1/3 flex justify-center">
                <p className="text-newCendiatra font-lg">Costo</p>
              </div>
              <div className="w-1/3 flex justify-center"></div>
            </div>
          </div>
          {title === 'LABORATORIOS' && (
            <>
              <div>
                {laboratoryServices.map((item) => (
                  <div
                    key={item.itemId}
                    className="w-full border-b border-gray-300 my-2"
                  >
                    <div className="w-full flex flex-wrap">
                      <div className="w-1/3 flex justify-center">
                        <p className="text-gray-500 font-lg">{item.name}</p>
                      </div>
                      <div className="w-1/3 flex justify-center">
                        <p className="text-gray-500 font-lg">
                          ${formatter.format(item.price)}
                        </p>
                      </div>
                      <div className="w-1/3">
                        <button
                          className="bg-cendiatra"
                          onClick={() => deleteService(item.name)}
                          style={{
                            fontSize: '10px',
                            height: '20px',
                            padding: '0px 5px',
                            marginLeft: '10px',
                            borderRadius: '15%',
                            color: '#fff',
                            border: '0px',
                          }}
                        >
                          borrar
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex flex-wrap justify-center">
                <span className="text-cendiatra flex text-lg my-5">
                  Valor Total:{' '}
                  <p className="font-bold">
                    {' '}
                    ${formatter.format(laboratoryTotalValue)}
                  </p>
                </span>
              </div>
            </>
          )}
          {title === 'PROCEDIMIENTOS' && (
            <>
              <div>
                {processServices.map((item) => (
                  <div
                    key={item.itemId}
                    className="w-full border-b border-gray-300 my-2"
                  >
                    <div className="w-full flex flex-wrap">
                      <div className="w-1/3 flex justify-center">
                        <p className="text-gray-500 font-lg">{item.name}</p>
                      </div>
                      <div className="w-1/3 flex justify-center">
                        <p className="text-gray-500 font-lg">
                          ${formatter.format(item.price)}
                        </p>
                      </div>
                      <div className="w-1/3">
                        <button
                          className="bg-cendiatra"
                          onClick={() => deleteService(item.name)}
                          style={{
                            fontSize: '10px',
                            height: '20px',
                            padding: '0px 5px',
                            marginLeft: '10px',
                            borderRadius: '15%',
                            color: '#fff',
                            border: '0px',
                          }}
                        >
                          borrar
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex flex-wrap justify-center">
                <span className="text-cendiatra flex text-lg my-5">
                  Valor Total:{' '}
                  <p className="font-bold">
                    {' '}
                    ${formatter.format(processTotalValue)}
                  </p>
                </span>
              </div>
            </>
          )}
          {title === 'VACUNAS' && (
            <>
              <div>
                {vaccineServices.map((item) => (
                  <div
                    key={item.itemId}
                    className="w-full border-b border-gray-300 my-2"
                  >
                    <div className="w-full flex flex-wrap">
                      <div className="w-1/3 flex justify-center">
                        <p className="text-gray-500 font-lg">{item.name}</p>
                      </div>
                      <div className="w-1/3 flex justify-center">
                        <p className="text-gray-500 font-lg">
                          ${formatter.format(item.price)}
                        </p>
                      </div>
                      <div className="w-1/3">
                        <button
                          className="bg-cendiatra"
                          onClick={() => deleteService(item.name)}
                          style={{
                            fontSize: '10px',
                            height: '20px',
                            padding: '0px 5px',
                            marginLeft: '10px',
                            borderRadius: '15%',
                            color: '#fff',
                            border: '0px',
                          }}
                        >
                          borrar
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex flex-wrap justify-center">
                <span className="text-cendiatra flex text-lg my-5">
                  Valor Total:{' '}
                  <p className="font-bold">
                    {' '}
                    ${formatter.format(vaccineTotalValue)}
                  </p>
                </span>
              </div>
            </>
          )}

          <div className="w-full flex justify-center">
            <div></div>
            <button
              className="hover:opacity-80 items-center group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              type="button"
              onClick={() => clearFields()}
            >
              {' '}
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default ServicesModal
