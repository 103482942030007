import React from 'react'
import {useTable} from 'react-table'

const TablaConCheckbox = ({
  data,
  columns,
  actualizarDatos,
  actualizarSeleccionado,
}) => {
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, rows} =
    useTable({
      columns,
      data,
      actualizarDatos,
      actualizarSeleccionado,
    })
  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <table
        className="w-full flex flex-wrap border-separate justify-center items-center"
        {...getTableProps()}
      >
        <thead className="flex w-full flex-wrap text-14px justify-center items-center">
          {headerGroups.map((headerGroup) => (
            <tr
              className="flex w-full flex-wrap text-center justify-center items-center"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: `${column.headerStyle} flex items-center content-center`,
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="flex w-full flex-wrap text-13px justify-center items-center"
          {...getTableBodyProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className="flex w-full flex-wrap text-center justify-center items-center content-center my-1 rounded-l-md border-b-2 "
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{backgroundColor: 'white'}}
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaConCheckbox
