import React, {useState, useEffect, useContext} from 'react'
import {consultMedicAgenda, getPatientAgendaInfo} from '../../servicios/Medico'
import {ContextApplication} from '../../contexto'
import VIPImg from '../../imagenes/vip.png'
import moment from 'moment'
import {useMsal} from '@azure/msal-react'
import {useHistory} from 'react-router-dom'

const MedicalSchedule = () => {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(10)
  const [arrToMap, setArrayToMap] = useState([])
  const [allData, setAllData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [numbersToMap, setNumbersToMap] = useState([])
  const [cendiatraSites, setCendiatraSites] = useState([])

  const todayDate = moment().format().slice(0, -15)
  const ctx = useContext(ContextApplication)

  const history = useHistory()
  const {accounts} = useMsal()
  const userRole = accounts[0].idTokenClaims.extension_Role

  useEffect(() => {
    if (userRole !== 'Medic') {
      ctx.setShowDoctorMenu(false)
      history.push('/createpatient')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPatientInfo = async (idOrder) => {
    await getPatientAgendaInfo(idOrder)
      .then((res) => {
        if (res.status === 200) {
          ctx.setPatientAgendaInfo(res.data)
          history.push('/medicform')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  useEffect(() => {
    const siteId = localStorage.getItem('siteId')
    consultMedicAgenda(siteId).then((res) => {
      const Agenda = JSON.parse(res.data)
      if (Agenda.length === 0) {
        return
      }

      if (Agenda.length < 10) {
        setEnd(Agenda.length)
      }
      setAllData(Agenda)
      const newArr = Agenda.slice(start, end)
      setArrayToMap(newArr)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newArrToMap = () => {
      const newArr = allData.slice(start, end)
      setArrayToMap(newArr)
    }
    newArrToMap()
  }, [start, end, allData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const numberArr = []

    const createArrNumber = () => {
      const index = Math.ceil(allData.length / 10)
      for (let i = 1; i <= index; i++) {
        numberArr.push({value: i, id: i})
      }
      setNumbersToMap(numberArr)
    }
    createArrNumber()
  }, [allData]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterBySiteId = (event) => {
    localStorage.setItem('siteId', event.target.value)

    localStorage.setItem(
      'siteName',
      event.target[event.target.selectedIndex].text
    )

    const storedName = localStorage.getItem('siteName')

    const storedId = localStorage.getItem('siteId')

    ctx.setSiteName(storedName)

    consultMedicAgenda(storedId).then((res) => {
      const Agenda = JSON.parse(res.data)

      if (Agenda.length === 0) {
        setAllData([])
        setArrayToMap([])
        setStart(0)
        setEnd(10)

        return
      }
      if (Agenda.length < 10) {
        setStart(0)
        setEnd(Agenda.length)
      }
      if (Agenda.length > 10) {
        setStart(0)
        setEnd(10)
      }

      setAllData(Agenda)
    })
  }

  const filterByDate = (dateToFilter) => {
    const storedId = localStorage.getItem('siteId')
    consultMedicAgenda(storedId).then((res) => {
      const ArrByDate = JSON.parse(res.data)

      const filtredData = ArrByDate.filter(
        (data) => data.date.slice(0, -9) === moment(dateToFilter).format('l')
      )

      if (filtredData.length === 0) {
        setAllData([])
        setArrayToMap([])
        setStart(0)
        setEnd(10)

        return
      }
      if (filtredData.length < 10) {
        setStart(0)
        setEnd(filtredData.length)
      }
      if (filtredData.length > 10) {
        setStart(0)
        setEnd(10)
      }
      setAllData(filtredData)
      setCurrentPage(1)
    })
  }

  const selectPage = (number) => {
    setCurrentPage(number + 1)
    if (10 + 10 * number > allData.length) {
      setEnd(allData.length + 1)
    } else {
      setEnd(10 + 10 * number)
    }
    setStart(0 + 10 * number)
  }

  return (
    <div className="min-h-screen flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
      <div className="mt-20 space-y-6 w-full sm:w-4/5 md:w-4/5 lg:w-11/12 ">
        <div className="w-full flex flex-wrap justify-center bg-white items-start pb-4 rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl">
          <div className="bg-white rounded-t-3xl rounded-b-3xl mx-5 mt-5 w-full">
            <h2 className="text-center text-3xl font-bold text-cendiatra">
              AGENDA MEDICA
            </h2>
          </div>
          <div className="rounded-md -space-y-px flex justify-evenly flex-wrap w-full">
            <div className="w-4/5 md:w-1/3">
              <select
                className="appearance-none  rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                placeholder="Dirección Email"
                onChange={(e) => filterBySiteId(e)}
              >
                <option key="-1" value="">
                  Sede*
                </option>
                {cendiatraSites.map((item) => (
                  <option key={item.Id} value={item.Id}>
                    {item.SiteName}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-4/5 md:w-1/3">
              <input
                type="date"
                className="appearance-none  rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                max={todayDate}
                onChange={(e) => filterByDate(e.target.value)}
              />
            </div>
          </div>
          <div className="w-10/12 bg-cendiatra mt-2 rounded-xl flex flex-wrap font-bold">
            <div className="w-1/5 text-center text-lg">
              <span className="text-white">Fecha/Hora llegada</span>
            </div>
            <div className="w-1/5 text-center text-lg ">
              <span className="text-white">Paciente</span>
            </div>
            <div className="w-1/5 text-center ">
              <span className="text-white text-lg">Empresa</span>
            </div>
            <div className="w-1/5 text-center text-lg ">
              <span className="text-white">Tipo de paciente</span>
            </div>
            <div className="w-1/5 text-center text-lg ">
              <span className="text-white">Procedimientos</span>
            </div>
          </div>
          {arrToMap.map((item) => (
            <div
              key={item.id}
              className={
                item.category === 'VIP'
                  ? 'w-10/12 bg-white text-newCendiatra mt-2 items-center rounded-xl flex flex-wrap  border-b-2 transform hover:scale-110 ...'
                  : 'w-10/12 bg-white text-gray-500 mt-2 items-center rounded-xl flex flex-wrap  border-b-2 transform hover:scale-110 ...'
              }
              onClick={() => getPatientInfo(item.id)}
            >
              <div className="w-1/5 text-center text-lg ">
                <span>{item.date}</span>
              </div>
              <div className="w-1/5 text-center text-lg">
                <span>{item.pacientName}</span>
              </div>
              <div className="w-1/5 text-center text-lg">
                <span>{item.company}</span>
              </div>
              {item.category === 'VIP' ? (
                <div className="w-1/5 text-center text-lg flex justify-center ">
                  <img className="h-10 w-8 " alt="VipImg" src={VIPImg} />
                </div>
              ) : (
                <div className="w-1/5 text-center text-lg "></div>
              )}

              <div className="w-1/5 text-center text-lg flex flex-wrap">
                {item.process.map((item) => (
                  <span key={item.serviceid} className=" text-sm w-full">
                    *{item.name}
                  </span>
                ))}
              </div>
            </div>
          ))}

          <div className="rounded-md w-full -space-y-px flex justify-center flex-wrap mt-10 items-center">
            <div className="w-full flex flex-wrap justify-center">
              <div className="z-0 flex rounded-md shadow-sm -space-x-px">
                {numbersToMap.map((item) => (
                  <div
                    key={item.id}
                    className={
                      currentPage === item.value
                        ? 'z-10 bg-cendiatra border-cendiatra text-white relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full '
                        : '  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full'
                    }
                    onClick={() => selectPage(item.value - 1)}
                  >
                    {item.value}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"></div>
    </div>
  )
}

export default MedicalSchedule
