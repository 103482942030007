import {useMsal} from '@azure/msal-react'
import {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {ConfirmacionPortafolioCreado} from '../../componentes/portafolio/ConfirmacionPortafolioCreado'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextApplication, ContextoPortafolio} from '../../contexto'
import {
  crearPortafolio,
  modificarPortafolio,
} from '../../microservicios/Portafolio'
import {obtenerCeroSiEsNullOVacio} from '../../utilidades/funcionesComunes'

import {useModal} from '../useModal'
import {useModalPreciosEnCero} from '../useModalPreciosEnCero'

export const useGuardarPortafolio = (creando = true) => {
  const contextoApp = useContext(ContextApplication)
  const contextoPortafolio = useContext(ContextoPortafolio)
  const history = useHistory()
  const [cerrarModal] = useModal()
  const {accounts} = useMsal()

  const validarPrecioAEnviar = (datosPrecio, nombrePropiedad) => {
    let contexto =
      datosPrecio?.tipo === 'servicio'
        ? contextoPortafolio.precioServiciosIniciales
        : contextoPortafolio.preciosPaquetesIniciales

    let precioExistia = contexto[nombrePropiedad]?.find(
      (precio) => precio?.id === datosPrecio?.idPrecio
    )

    if (precioExistia && precioExistia?.fechaFinal !== null) {
      return obtenerCeroSiEsNullOVacio(datosPrecio?.nuevoPrecio)
    }

    return datosPrecio.nuevoPrecio
      ? obtenerCeroSiEsNullOVacio(datosPrecio.nuevoPrecio)
      : obtenerCeroSiEsNullOVacio(datosPrecio.precio)
  }

  const validarTipoGuardado = (id, tipoElemento) => {
    const arrayInicial = Boolean(tipoElemento === 'Servicio')
      ? contextoPortafolio.serviciosIniciales
      : contextoPortafolio.paquetesIniciales
    if (Boolean(arrayInicial.find((elemento) => elemento === id))) {
      return 0
    }
    return 1
  }

  const guardarPortafolio = async () => {
    const portafolioId = contextoPortafolio.portafolioId

    let datosPeticion = {
      id: portafolioId,
      nombre: contextoPortafolio.formularioPortafolio.nombre,
      fechaInicial: contextoPortafolio.formularioPortafolio.fechaInicial,
      fechaFinal: contextoPortafolio.formularioPortafolio.fechaFinal,
      servicios: contextoPortafolio.listaServicios.map((servicio) => ({
        alias: servicio.alias ? servicio.alias : null,
        servicioId: servicio.servicio_id,
        tipoCambioSedes:
          servicio?.tipoCambioSedes ??
          validarTipoGuardado(servicio.servicio_id, 'Servicio'),
        tipoCambioAliados:
          servicio?.tipoCambioAliados ??
          validarTipoGuardado(servicio.servicio_id, 'Servicio'),
        preciosSedes: servicio.precios_sedes.map((precioSede) => ({
          sedeId: precioSede.id.replace('servicio:', ''),
          precio: validarPrecioAEnviar(precioSede, 'preciosSedes'),
          id: creando ? undefined : precioSede.idPrecio,
        })),
        PreciosAliados: servicio.precios_aliados.map((precioAliado) => ({
          aliadoId: precioAliado.id.replace('aliado:', ''),
          precio: validarPrecioAEnviar(precioAliado, 'preciosAliados'),
          id: creando ? undefined : precioAliado.idPrecio,
        })),
      })),
      paquetes: contextoPortafolio.listaPaquetes.map((paquete) => ({
        paqueteId: paquete.paquete_id,
        tipoCambioSedes:
          paquete?.tipoCambioSedes ??
          validarTipoGuardado(paquete.paquete_id, 'Paquete'),
        tipoCambioAliados:
          paquete?.tipoCambioAliados ??
          validarTipoGuardado(paquete.paquete_id, 'Paquete'),
        preciosSedes: paquete.precios_sedes.map((precioSede) => ({
          sedeId: precioSede.id.replace('paquete:', ''),
          precio: validarPrecioAEnviar(precioSede, 'preciosSedes'),
          id: creando ? undefined : precioSede.idPrecio,
        })),
        preciosAliados: paquete.precios_aliados.map((precioAliado) => ({
          aliadoId: precioAliado.id.replace('aliado:', ''),
          precio: validarPrecioAEnviar(precioAliado, 'preciosAliados'),
          id: creando ? undefined : precioAliado.idPrecio,
        })),
      })),
      usuario: accounts[0].name,
    }
    try {
      const peticion = creando ? crearPortafolio : modificarPortafolio

      await peticion(datosPeticion)

      contextoApp.setModal({
        abierto: true,
        titulo:
          TEXTO_VISUAL.PORTAFOLIO.MODAL.PORTAFOLIO_GUARDADO(creando).TITULO,
        contenido: (
          <ConfirmacionPortafolioCreado
            id={portafolioId}
            codigoPortafolio={portafolioId}
            nombrePortafolio={contextoPortafolio.formularioPortafolio.nombre}
          />
        ),
        botones: [
          {
            nombre: TEXTO_VISUAL.BOTONES.FINALIZAR,
            click: () => {
              cerrarModal()
              history.push('/main')
            },
          },
          {
            nombre: TEXTO_VISUAL.BOTONES.CREAR_NUEVO,
            click: () => {
              history.go(0)
            },
          },
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }

  const [manejarGuardar] = useModalPreciosEnCero(
    contextoPortafolio,
    guardarPortafolio
  )

  return [manejarGuardar]
}
