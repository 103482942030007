import React, {useContext, useEffect} from 'react'
import {ContextoJefeDeSede} from '../../contexto'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import TablaPacientes from '../../componentes/recepcionista/TablaPacientes'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import IconoVer from '../../imagenes/iconoResultadosVerde.svg'
import CeldaConBotonDinamica from '../../componentes/Celdas/CeldaConBotonDinamica'
import {useHistory} from 'react-router-dom'
import FiltroSeleccionarExamenesAtendidos from '../../componentes/inputs/FiltroSeleccionarExamenesAtendidos'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import {CeldaDobleTexto} from '../../componentes/Celdas/CeldaDobleTexto'
import moment from 'moment'

const HabilitarModificarExamenesAtendidos = () => {
  const contextoJefeDeSede = useContext(ContextoJefeDeSede)
  const history = useHistory()

  useEffect(async () => {
    await AgregarServiciosACache()
  }, [])

  const columnasPacientes = React.useMemo(() => [
    {
      Header: 'solicitudId',
      accessor: 'solicitudId',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Número de atención / Fecha'} />
      ),
      accessor: 'atencionId',
      className:
        'w-17% flex text-center justify-center items-center content-center border-cendiatra rounded-l-md border-l border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-17%  bg-cendiatra-verde-7 text-white rounded-l-md h-14',
      Cell: ({row}) => (
        <CeldaDobleTexto
          datos={{
            Fila1: row.original.atencionId,
            Fila2: moment(row.original.fechaAtencion).format('DD/MM/YYYY'),
          }}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'No de documento'} />,
      accessor: 'numeroDocumento',
      className:
        'w-22% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1 uppercase',
      headerStyle: 'w-22% bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nombre'} />,
      accessor: 'nombre',
      className:
        'w-22% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-22% bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Apellidos'} />,
      accessor: 'apellidos',
      className:
        'w-22% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-22% bg-cendiatra-verde-7 text-white h-14',
    },

    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={
            <div className="flex justify-center items-center flex-wrap">
              <span className="w-full ">Módulos</span>
              <span className="w-full">ver atención</span>
            </div>
          }
        />
      ),
      accessor: 'modulos',
      className:
        'w-17% flex text-center justify-center items-center content-center border-cendiatra border-t border-b border-r rounded-r-md h-16',
      headerStyle: 'w-17% bg-cendiatra-verde-7 text-white rounded-r-md h-14',
      Cell: (row) => (
        <CeldaConBotonDinamica
          habilitado={true}
          imagenHabilitado={IconoVer}
          funcion={() => manejarClickVer(row.row.original.solicitudId)}
          imagen={IconoVer}
          estilosImagen={'h-10 w-10'}
        />
      ),
    },
  ])

  const manejarClickVer = (solicitudId) => {
    contextoJefeDeSede.setexamenesAtendidosFiltrados([])
    contextoJefeDeSede.setModificarAtencion(false)
    history.push('/verexamenesatendidos', solicitudId)
  }

  return (
    <div>
      <ContenedorPagina
        tituloPagina={'HABILITAR MODIFICACIÓN DE EXÁMENES ATENDIDOS'}
      >
        <>
          <FiltroSeleccionarExamenesAtendidos />
          <div className="bg-white mt-10 rounded-t-3xl rounded-b-3xl justify-center items-center flex flex-wrap mb-16">
            <TablaPacientes
              columns={columnasPacientes}
              data={contextoJefeDeSede.examenesAtendidosFiltrados}
            />
          </div>
        </>
      </ContenedorPagina>
    </div>
  )
}

export default HabilitarModificarExamenesAtendidos
