import React, {useState, useEffect} from 'react'
import CeldaTiempoEspera from './CeldaTiempoEspera'
import moment from 'moment'

const CeldaCronometro = ({fechaInicioTiempo}) => {
  const [tiempoInicial, setTiempoInicial] = useState(null)
  useEffect(() => {
    const timer = setInterval(() => {
      setTiempoInicial(calcularTiempoEspera())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const calcularTiempoEspera = () => {
    var duracion = moment.duration(moment().diff(moment(fechaInicioTiempo)))
    return (
      String(duracion.hours()).padStart(2, '0') +
      ':' +
      String(duracion.minutes()).padStart(2, '0') +
      ':' +
      String(duracion.seconds()).padStart(2, '0')
    )
  }

  return (
    <div className="w-full">
      <CeldaTiempoEspera tiempoInicial={tiempoInicial} />
    </div>
  )
}

export default CeldaCronometro
