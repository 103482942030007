import {NavLink} from 'react-router-dom'
import {validarTextoNoVacio} from '../../utilidades'
import closeImg from '../../imagenes/Salir.svg'
import {BarraMensajeInformativo} from '../MostrarInformacion'
import BotonConImagen from '../BotonConImagen'

export const ContenedorPagina = ({
  clasesCssAdicionales,
  tituloPagina,
  children,
  navegacion,
  mensaje,
  funcionBotonCerrar,
  estilosContenedor = 'w-5/6',
}) => {
  let clasesCss =
    'min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover'

  if (validarTextoNoVacio(clasesCssAdicionales)) {
    clasesCss += ` ${clasesCssAdicionales}`
  }
  return (
    <div className={clasesCss}>
      <div className="flex justify-center items-start flex-wrap w-full">
        <div className="w-full flex flex-wrap justify-center items-center">
          <span className="text-center text-21px font-bold text-cendiatra my-25px uppercase">
            {tituloPagina}
          </span>
        </div>
        <div
          className={`bg-white p-4 rounded rounded-t-3xl rounded-b-3xl border border-opacity-50 border-cendiatra shadow-2xl ${estilosContenedor}`}
        >
          <div className="bg-white px-8 pt-2 rounded-t-3xl rounded-b-3xl">
            <div className="flex justify-end h-7">
              {funcionBotonCerrar ? (
                <BotonConImagen
                  estilosImagen={'h-7'}
                  imagen={closeImg}
                  medio={true}
                  funcionAEjecutar={funcionBotonCerrar}
                  textoAlternativo={'cerrar'}
                />
              ) : (
                <NavLink to={navegacion ? navegacion : '/'}>
                  <img className="h-7" src={closeImg} alt="close" />
                </NavLink>
              )}
            </div>
            {children}
          </div>
        </div>
        <div
          className={`w-10/12 ${
            mensaje ? 'flex' : 'hidden'
          } justify-center items-center my-5`}
        >
          <BarraMensajeInformativo mensaje={mensaje} />
        </div>
      </div>
    </div>
  )
}
