import React, {useState, useEffect} from 'react'
import moment from 'moment'

const CeldaTiempoEspera = ({tiempoInicial}) => {
  return (
    <span
      className={`${
        moment(tiempoInicial, 'HH:mm:ss').hour() >= 1 ||
        moment(tiempoInicial, 'HH:mm:ss').minute() >= 30
          ? 'text-cendiatra-rojo-1'
          : ''
      }`}
    >
      {tiempoInicial}
    </span>
  )
}

export default CeldaTiempoEspera
