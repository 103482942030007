import React from 'react'
import IconoAlerta from '../../imagenes/alerta.svg'

const AlertaDinamica = ({
  mostrarAlerta,
  mensaje,
  background = 'bg-cendiatra-gris-5',
  color = 'text-cendiatra-rojo-1'
}) => {
  return (
    <div
      className={`w-full ${background} ${
        mostrarAlerta ? 'flex' : 'hidden'
      } justify-start items-center mt-2 py-2  rounded-lg `}
    >
      <div className="w-2/12 flex justify-center items-center">
        <img src={IconoAlerta} alt="alerta" />
      </div>
      <div className="w-10/12">
        <span className={`${color} w-full`}>{mensaje}</span>
      </div>
    </div>
  )
}

export default AlertaDinamica
