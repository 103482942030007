import {getRestApi} from './ApiAxios'

const contenedorLogos = 'empresas-logos'
const contenedorUsuarios = 'registro-paciente'
export const contenedorCertificados = 'certificados-pre-ingreso'

export const SubirArchivo = async (nombre, imagen, tipoContenedor) => {
  const apiReq = await getRestApi()
  let contenedor = contenedorLogos
  if (tipoContenedor == 'paciente') {
    contenedor = contenedorUsuarios
  }
  if (tipoContenedor === 'certificados') {
    contenedor = contenedorCertificados
  }
  return await apiReq.put(
    'archivos-function/SubirArchivo?nombreArchivo=' +
      nombre +
      '&contenedor=' +
      contenedor,
    imagen,
    'Archivo'
  )
}

export const SubirArchivoPdf = async (informacionArchivo) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'archivos-function/SubirArchivoPdf',
    informacionArchivo
  )
}

export const CargarArchivoPagos = async (informacionArchivo) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'gato-api-function/CargarArchivoPagos',
    informacionArchivo
  )
}
