import React from 'react'
import ReportePowerBi from '../../componentes/powerBi/ReportePowerBi'

const ReporteDeVentas = () => {
  return (
    <div className="w-full flex justify-center items-center">
      <ReportePowerBi />
    </div>
  )
}

export default ReporteDeVentas
