import React, {useState} from 'react'
import {FachadaAgregarMotivo} from '../../../microservicios'
import {ObtenerMotivoModificacionAtencionPorNombre} from '../../../cache/servicios-cache'
import {RUTAS_APP, casosAtencionEjecutada} from '../../../constantes'
import {AreaDeTexto} from '../../inputs'
import {BotonSimple} from '../../Botones'
import {useHistory} from 'react-router-dom'

export const Motivo = ({datosAtencion}) => {
  const [valorMotivo, setValorMotivo] = useState('')

  const sede = sessionStorage.getItem('sedeId')

  const history = useHistory()

  const manejarIrAModificarAtencion = async () => {
    try {
      const respuesta = await FachadaAgregarMotivo({
        atencionId: datosAtencion ?? 0,
        sedeId: sede,
        tipoMotivoId:
          (
            await ObtenerMotivoModificacionAtencionPorNombre(
              casosAtencionEjecutada.MODIFICACION_COMPLETA ?? ''
            )
          )?.Id ?? 0,
        motivo: valorMotivo,
      })
      if (respuesta.status === 200) {
        history.push(RUTAS_APP.RECEPCION.REGISTRAR_PACIENTE, {
          idAtencion: datosAtencion,
          modificacion: true,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full flex flex-wrap">
      <h2 className="w-full text-lg font-bold text-cendiatra uppercase my-4">
        {' '}
        Motivo por el cual desea hacer la modificación{' '}
      </h2>
      <AreaDeTexto
        valor={valorMotivo}
        cambioValor={(evt) => {
          setValorMotivo(evt.currentTarget?.value)
        }}
        estilosContenedor={'w-full'}
        estilosAreaDeTexto={`      ${
          !valorMotivo ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
        } rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        placeholder="descripción"
      />
      <div className="w-full flex justify-center items-center mt-10">
        <BotonSimple
          texto={'Siguiente'}
          estilosBoton={`w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            !!valorMotivo ? ' bg-btnBg ' : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={manejarIrAModificarAtencion}
          deshabilitado={!!!valorMotivo}
        />
      </div>
    </div>
  )
}

export default Motivo
