import React from 'react'
import Boton from '../Botones/Boton'

const InputConBoton = ({
  estilosContenedor,
  titulo,
  estilosInput,
  tipoInput,
  placeholder,
  funcionInput,
  funcionBoton,
  habilitado,
  valorInput,
  tipoBoton,
}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <label className="block text-13px leading-15px text-cendiatra-verde-2 my-5px">
        {titulo}
      </label>
      <div className={`${estilosInput} leading-15px text-13px my-5px h-8`}>
        <input
          className={'focus:outline-none w-6/12'}
          type={tipoInput}
          placeholder={placeholder}
          onChange={funcionInput}
          value={valorInput}
        />
        <Boton
          titulo="Cambiar sede"
          ancho={'w-5/12 mr-0'}
          habilitado={habilitado}
          funcionCLick={funcionBoton}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={tipoBoton}
          alto={'h-7'}
        />
      </div>
    </div>
  )
}

export default InputConBoton
