import React from 'react'
import {LeyendaAbreviatura, LeyendaSemaforizacion} from '../leyendas'
import {useMsal} from '@azure/msal-react'

const LeyendasAgenda = ({leyendas}) => {
  const {accounts} = useMsal()

  const rolUsuario = accounts[0].idTokenClaims.roles[0]
  return (
    <>
      {leyendas.map((elemento) => {
        if (elemento?.rolDenegado?.includes(rolUsuario)) {
          return null
        }
        if (elemento.textoAbreviado) {
          return (
            <div className="w-1/2 flex justify-center items-center">
              <LeyendaAbreviatura
                textoAbreviado={elemento.textoAbreviado}
                texto={elemento.texto}
              />
            </div>
          )
        }
        return (
          <div className="w-1/2 flex justify-center items-center">
            <LeyendaSemaforizacion
              colorFondo={elemento.colorFondo}
              texto={elemento.texto}
            />
          </div>
        )
      })}
    </>
  )
}

export default LeyendasAgenda
