import React, {useContext, useState} from 'react'
import {ContextoEmpresa} from '../../contexto'
import {InputSubirArchivo} from '../inputs'
import {UseObtenerRolValido} from '../../utilidades/funcionesComunes'

const CargarLogo = ({contexto}) => {
  const contextoEmpresa = useContext(ContextoEmpresa)
  const [nombreArchivoServicio, setNombreArchivoServicio] = useState('')
  const [archivoRequerido, setArchivoRequerido] = useState(false)

  const rolValido = UseObtenerRolValido()

  return (
    <div className="w-full flex flex-wrap justify-around mb-20 ">
      <div className="w-5/12 flex flex-wrap justify-start m-2 h-48">
        {!rolValido && (
          <InputSubirArchivo
            contexto={contextoEmpresa}
            tamanoMaximoArchivo={2}
            nombreLabel={'Adjuntar logo empresa'}
            anchoMaximo={400}
            altoMaximo={400}
            editable={true}
            nombreArchivoServicio={contexto.logoEmpresa.url}
            archivoRequerido={false}
            archivoLimpiado={() => {
              setNombreArchivoServicio('')
              setArchivoRequerido(false)
            }}
            archivoCargado={() => setArchivoRequerido(false)}
          />
        )}
      </div>

      <div className="w-3/12 flex flex-wrap justify-center items-center mx-2 h-48">
        <label className="w-full block text-sm font-medium text-cendiatra my-2">
          Logo empresa
        </label>
        <div
          className="w-full flex  flex-wrap border border-cendiatra justify-center rounded-lg h-full bg-contain bg-no-repeat bg-center"
          style={{backgroundImage: `url(${contexto.logoEmpresa.url})`}}
        ></div>
        <div className="w-full flex flex-wrap justify-center items-center mx-2">
          <label
            className="block text-sm font-medium my-2 text-cendiatra"
            style={{color: contextoEmpresa.cambiarColorMensaje}}
          >
            Tamaño 400px x 400px, formato png o jpg peso Max 2mb
          </label>
        </div>
      </div>
    </div>
  )
}

export default CargarLogo
