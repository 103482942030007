const InformacionAsignacionPlantillaAServicios = ({
  servicios = [],
  asignacion = true,
  plantilla,
}) => {
  return (
    <div className="w-full">
      <div className="w-full pb-5">
        <h1 className="w-full font-bold pb-2">PLANTILLA:</h1>
        <p className="w-full font-bold">{plantilla}</p>
      </div>
      <div className="w-full">
        <h1 className="w-full font-bold pb-2">
          {asignacion ? 'SERVICIOS:' : 'SERVICIO:'}
        </h1>
        <ul className="w-full list-none">
          {servicios.map((item, key) => (
            <li key={key} className="font-bold w-full">
              {asignacion ? '-' : ''}
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default InformacionAsignacionPlantillaAServicios
