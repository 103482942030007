export const TIPO_SERVICIOS = [
  {
    Id: 1,
    Nombre: 'Extramural',
  },
  {
    Id: 2,
    Nombre: 'Tiquetera',
  },
]

export const CATEGORIA_SERVICIOS = {
  EXTRAMURAL: 1,
  TIQUETERA: 2,
}
