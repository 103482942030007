import React, {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {EncabezadoFondo} from '../Tablas'
import TablaResultadosAliados from './TablaResultadosAliado'
import {BotonesModulosRoles} from '../Botones'
import {FiltroSeleccionarAliado} from '../inputs'
import {CeldaDobleTexto} from '../../componentes/Celdas'
import {ContextoCargarDatosAliado} from '../../contexto'
import moment from 'moment'
import CeldaConTooltip from '../CeldaConTooltip'

const SeleccionarAliado = ({funcionClick}) => {
  const [aliados, setAliados] = useState([])

  const contextoCargarDatosAliado = useContext(ContextoCargarDatosAliado)

  const columnasPacientes = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Creado por'} />,
      accessor: 'creadoPor',
      className:
        'w-13% flex text-center justify-center items-center content-center border-cendiatra rounded-l-md border-l border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle:
        'w-13% bg-cendiatra-verde-7 text-white ml-0.5 rounded-l-md h-14',
      Cell: CeldaConTooltip,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Fecha de atención'} />,
      accessor: 'fechaAtencion',
      className:
        'w-13% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-13% bg-cendiatra-verde-7 text-white h-14',
      Cell: (row) => (
        <span>
          {' '}
          {moment(row.row.original.fechaAtencion).format('YYYY-MM-DD')}
        </span>
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Ciudad - nombre aliado'} />,
      accessor: 'informacionAliado',
      className:
        'w-13% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-13% bg-cendiatra-verde-7 text-white h-14',
      Cell: (row) => (
        <CeldaDobleTexto datos={row.row.original.informacionAliado} />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'No de documento'} />,
      accessor: 'numeroDocumento',
      className:
        'w-13% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1 uppercase',
      headerStyle: 'w-13% bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nombres'} />,
      accessor: 'nombres',
      className:
        'w-13% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-13% bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Apellidos'} />,
      accessor: 'apellidos',
      className:
        'w-13% flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-13% bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Módulos'} />,
      accessor: 'modulos',
      className:
        'w-13% flex text-center justify-center items-center content-center border-cendiatra border-t border-b border-r rounded-r-md h-16',
      headerStyle: 'w-13% bg-cendiatra-verde-7 text-white rounded-r-md h-14',
      Cell: (row) => (
        <BotonesModulosRoles
          funcionEditar={() => manejarClickEditar(row.row.original.id)}
          funcionVer={() => manejarClickVer(row.row.original.id)}
        />
      ),
    },
  ])

  const manejarClickEditar = (id) => {
    funcionClick(id)
    contextoCargarDatosAliado.setAliadoParaModificar({id: id, editable: true})
  }

  const manejarClickVer = (id) => {
    funcionClick(id)
    contextoCargarDatosAliado.setAliadoParaModificar({id: id, editable: false})
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <div className="w-11/12 ">
        <FiltroSeleccionarAliado setAliados={setAliados} data={aliados} />
      </div>
      <div className="w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex flex-wrap mb-16">
        <TablaResultadosAliados columns={columnasPacientes} data={aliados} />
      </div>
    </div>
  )
}

export default SeleccionarAliado
