import {dbComun} from '../bases-datos/dbComun'

export const ObtenerCie10PorNombreOCodigo = async (filtro) => {
  let cie10PorNombre = (
    await dbComun
      .table('Cie10')
      .filter(
        ({Nombre, Cie10}) =>
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1 ||
          Cie10.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
    cie10: elemento.Cie10,
  }))
  return Promise.resolve(cie10PorNombre)
}
