import React, {useEffect, useContext} from 'react'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {BotonOpcionConObservaciones} from '../../../Botones'

const Recomendaciones = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      contextoMedicoOcupacional.setformularioActualTieneErrores(false)
    }
  }, [contextoMedicoOcupacional.numeroPagina])

  const manejarCambioRecomendacion = (id) => {
    contextoMedicoOcupacional.setListaRecomendaciones(
      contextoMedicoOcupacional.listaRecomendaciones.map((recomendacion) => {
        if (recomendacion.Id == id) {
          return {
            ...recomendacion,
            EstaActivo: !recomendacion.EstaActivo,
          }
        }
        return recomendacion
      })
    )
  }

  const manejarCambioObservaciones = (valor, id) => {
    contextoMedicoOcupacional.setListaRecomendaciones(
      contextoMedicoOcupacional.listaRecomendaciones.map((recomendacion) => {
        if (recomendacion.Id == id) {
          return {
            ...recomendacion,
            Observaciones: valor,
          }
        }
        return recomendacion
      })
    )
  }

  return (
    <div className="w-11/12 flex flex-wrap justify-start items-center ml-16">
      {contextoMedicoOcupacional.listaRecomendaciones.map((recomendacion) => (
        <div className="w-1/3 flex mb-2 p-5">
          <BotonOpcionConObservaciones
            id={recomendacion.Id}
            titulo={recomendacion.Nombre.toUpperCase()}
            estaActivo={recomendacion.EstaActivo}
            observaciones={recomendacion.Observaciones}
            funcionClick={manejarCambioRecomendacion}
            cambioObservaciones={manejarCambioObservaciones}
          />
        </div>
      ))}
    </div>
  )
}

export default Recomendaciones
