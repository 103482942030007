import moment from 'moment'
import {numericFormatter} from 'react-number-format'
import {FORMATO_FECHA_GENERAL} from '../constantes/formatos'
import {ROLES_APP} from '../constantes/roles'
import {useMsal} from '@azure/msal-react'
import {CLASES_DE_FACTURACION, estadosExamenesConst} from '../constantes'
import {name} from '@azure/msal-browser/dist/packageMetadata'

export function useObtenerRol() {
  const {accounts} = useMsal()
  const rolUsuario = accounts[0]?.idTokenClaims?.roles[0] ?? ''

  return {rolUsuario}
}

export function useObtenerInfoUsuario() {
  const {accounts} = useMsal()
  const rolUsuario = accounts[0] ?? ''

  return {nombre: rolUsuario.name, correo: rolUsuario.username}
}

export function UseObtenerRolValido() {
  const {accounts} = useMsal()
  const rolUsuario = accounts[0]?.idTokenClaims?.roles[0] ?? ''

  return [
    ROLES_APP.GERENTE_GENERAL,
    ROLES_APP.EJECUTIVO_COMERCIAL,
    ROLES_APP.JEFE_SEDE,
    ROLES_APP.ADMINISTRADOR_CONTABLE,
    ROLES_APP.GERENTE_COMERCIAL,
    ROLES_APP.FACTURADOR,
    ROLES_APP.AUDITOR,
  ].includes(rolUsuario)
}

export const transformarNumero = (numero) => {
  if (typeof numero === 'string' && !Number.isNaN(numero)) {
    numero = parseInt(numero)
  }

  if (!Number.isNaN(numero)) {
    return `${numero < 10 ? '0' : ''}${numero}`
  }

  return ''
}
export const rolRequiereConfiguracionSede = (rol) => {
  const menus = [
    ROLES_APP.RECEPCION,
    ROLES_APP.MEDICO_OCUPACIONAL,
    ROLES_APP.AUXILIAR_ENFERMERIA,
    ROLES_APP.BACTERIOLOGO,
    ROLES_APP.FONOAUDIOLOGO,
    ROLES_APP.OPTOMETRA,
    ROLES_APP.PSICOLOGO,
    ROLES_APP.JEFE_SEDE,
    ROLES_APP.GERENTE_GENERAL,
    ROLES_APP.FISIOTERAPEUTA,
    ROLES_APP.AUDITOR,
    ROLES_APP.AUXILIAR_CONTABLE,
    ROLES_APP.TESORERO,
    ROLES_APP.FACTURADOR,
  ]

  return menus.find((element) => element === rol) ?? false
}

export const validarRolAsignado = (objeto, rol) => {
  if (objeto === undefined) {
    return true
  }
  if (Array.isArray(objeto)) {
    return objeto.filter((elemento) => elemento === rol)
  } else {
    return objeto === rol
  }
}

export const mayusculaInicial = (texto) => {
  return texto.substring(0, 1).toUpperCase() + texto.substring(1, texto.length)
}

export const camelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index == 0 ? word.toUpperCase() : word.toLowerCase()
  })
}

export const capitalizarPalabras = (string) => {
  return string
    .split(' ')
    .map(
      (word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
    )
    .join(' ')
}

export const filtrarDatosYCrearSugerenciasSegunTexto = (
  datos,
  campoFiltrar,
  textoEscrito
) => {
  let informacionFiltrada = {sugerencias: [], datosFiltrados: []}

  if (
    Array.isArray(datos) &&
    typeof campoFiltrar === 'string' &&
    typeof textoEscrito === 'string'
  ) {
    textoEscrito = textoEscrito.trim().toLowerCase()

    informacionFiltrada = datos.reduce((filtrado, registro, indice) => {
      if (
        textoEscrito.length === 0 ||
        (textoEscrito.length > 0 &&
          String(registro[campoFiltrar])
            .trim()
            .toLowerCase()
            .includes(textoEscrito))
      ) {
        filtrado.datosFiltrados.push(registro)

        if (
          filtrado.sugerencias.findIndex(
            (sugerencia) => sugerencia.filtro === registro[campoFiltrar]
          ) < 0
        )
          filtrado.sugerencias.push({
            id: `${indice}-${registro[campoFiltrar]}`,
            filtro: registro[campoFiltrar],
          })
      }

      return filtrado
    }, informacionFiltrada)
  }

  return informacionFiltrada
}

export const crearSugerenciasSegunCampoFecha = (datos, campofecha) => {
  let sugerencias = []

  if (Array.isArray(datos) && typeof campofecha === 'string') {
    sugerencias = datos.reduce((sugerenciasPrev, registro) => {
      const fechaFormateada = moment(registro[campofecha]).format(
        FORMATO_FECHA_GENERAL
      )

      if (
        sugerenciasPrev.findIndex(
          (sugerencia) => sugerencia.Nombre === fechaFormateada
        ) < 0
      ) {
        sugerenciasPrev.push({Id: fechaFormateada, Nombre: fechaFormateada})
      }

      return sugerenciasPrev
    }, sugerencias)
  }

  return sugerencias
}

/**
 * Permite dar formato a un valor para que sea como moneda.
 * @param {string} valorTexto Cadena que representa el valor a dar formato.
 * @returns Valor con el formato moneda.
 */
export const aplicarFormatoMoneda = (valorTexto) => {
  const valorConFormato = numericFormatter(String(valorTexto), {
    decimalSeparator: ',',
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: '.',
  })

  return `$${valorConFormato}`
}

export const obtenerCeroSiEsNullOVacio = (valor) => {
  if (
    valor === null ||
    (typeof valor === 'string' && valor.trim().length === 0)
  ) {
    valor = 0
  }

  if (typeof valor === 'string') {
    if (valor.startsWith('$')) {
      let [parteEntera, parteDecimal] = valor.substring(1).split(',')

      parteEntera = parteEntera.replace(/\./g, '').replace(',', '.')

      const nuevoValor = `${parteEntera}.${parteDecimal}`

      if (!Number.isNaN(nuevoValor)) {
        valor = Number(nuevoValor)
      }
    } else if (!Number.isNaN(valor)) {
      valor = Number(valor)
    }
  }

  return valor
}

export const base64ABlob = (contenido, tipoContenido) => {
  tipoContenido = tipoContenido || ''
  let sliceSize = 512
  let byteCharacters = window.atob(contenido)

  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, {
    type: tipoContenido,
  })
  return blob
}

export const validarTipoFacturacion = (tipoFacturacion) => {
  const facturacion = {
    CONTADO: 1,
    CRÉDITO: 2,
  }
  return facturacion[tipoFacturacion?.toUpperCase() ?? '']
}

export const transformarTipoFacturacion = (idFacturacion) => {
  const facturacionString = {
    1: 'Contado',
    2: 'Crédito',
  }
  return facturacionString[parseInt(idFacturacion) ?? '']
}

export const eliminarDuplicadosPorPropiedad = (array, propiedad) => {
  const uniqueValues = new Map()
  const result = []

  for (const item of array) {
    if (!uniqueValues.has(item[propiedad])) {
      uniqueValues.set(item[propiedad], true)
      result.push(item)
    }
  }

  return result
}

export const validarFecha = (fecha) => {
  const fechaParaValidar = moment(fecha, moment.ISO_8601, true)

  if (!fechaParaValidar.isValid()) {
    return false
  }

  const year = fechaParaValidar.year()
  if (year < 1753 || year > 9999) {
    return false
  }

  return true
}

export const informacionMedicoParaServicioAtencion = (
  estadoServicioId,
  servicioAtencion
) => {
  if (
    ![
      estadosExamenesConst.EN_PROCESO,
      estadosExamenesConst.PENDIENTE_POR_CERRAR,
    ].includes(estadoServicioId)
  )
    return ''

  if (estadoServicioId === estadosExamenesConst.EN_PROCESO) {
    return (
      servicioAtencion?.nombreMedico ??
      servicioAtencion?.medicoLlamando?.nombre ??
      servicioAtencion?.informacionUltimoGuardado?.nombreMedico ??
      ''
    )
  }

  return (
    (servicioAtencion.informacionUltimoGuardado?.tipoGuardado ?? '') +
    ' ' +
    (servicioAtencion.informacionUltimoGuardado?.nombreMedico ??
      servicioAtencion.nombreMedico ??
      '')
  )
}

export const agregarFormatoFecha = (e) => {
  if (e?.target?.name === 'fechaDesde') {
    return e.target.value === ''
      ? moment().format('YYYY-MM-DD 00:00:00')
      : moment(e.target.value).format('YYYY-MM-DD 00:00:00')
  } else if (e?.target?.name === 'fechaHasta') {
    if (
      moment(e.target.value).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD')
    ) {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    }
    return e.target.value === ''
      ? moment().format('YYYY-MM-DD HH:mm:ss')
      : moment(e.target.value).format('YYYY-MM-DD 23:59:59')
  }
  return e.target.value
}
