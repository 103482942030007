import React, {useEffect, useState} from 'react'
import TablaDinamica from '../../TablaDinamica'
import {aplicarFormatoMoneda} from '../../../utilidades'
import {
  ObtenerPaquetePorId,
  ObtenerServicioPorId,
} from '../../../cache/servicios-cache'

export const ServiciosPaquetes = ({examenes}) => {
  const [total, setTotal] = useState(0)
  const [examenesTransformados, setExamenesTransformados] = useState([examenes])

  useEffect(() => {
    let nuevoTotal = 0

    examenes?.forEach((element) => {
      nuevoTotal += element.precio
    })

    setTotal(nuevoTotal)
  }, [examenes])

  useEffect(() => {
    if (examenes && examenes.length > 0) {
      Promise.all(
        examenes.map((element) => {
          if (element?.servicioId) {
            return ObtenerServicioPorId(element?.servicioId).then((examen) => {
              element.CodigoCompleto = examen.CodigoCompleto
            })
          } else {
            return ObtenerPaquetePorId(element?.paqueteId).then((paquete) => {
              element.CodigoInterno = paquete.CodigoInterno
            })
          }
        })
      ).then(() => {
        setExamenesTransformados(examenes)
      })
    }
  }, [examenes])

  const columns = React.useMemo(() => [
    {
      Header: 'No.',
      id: 'row',
      className: 'w-2/12',
      Cell: (row) => {
        return <div>{row.row.index + 1}</div>
      },
    },

    {
      Header: 'CÓDIGO DE SERVICIO Y/O PAQUETE',
      accessor: 'CodigoCompleto',
      className: 'w-3/12 ',
      Cell: (row) => {
        return <div>{examenesTransformados[row.row.index]?.CodigoCompleto}</div>
      },
    },
    {
      Header: 'NOMBRE DEL SERVICIO Y/O PAQUETE',
      accessor: 'nombre',
      className: 'w-20% uppercase',
      Cell: (row) => {
        return <div>{row.row.original.nombre}</div>
      },
    },
    {
      Header: 'CANTIDAD',
      accessor: 'cantidad',
      className: 'w-2/12',
      Cell: () => {
        return <div>1</div>
      },
    },
    {
      Header: 'VALOR',
      accessor: 'precio',
      className: 'w-2/12',
      Cell: (row) => (
        <span>{aplicarFormatoMoneda(row.row.original.precio)}</span>
      ),
    },
  ])

  return (
    <div className="w-full mt-10">
      <h3 className="text-cendiatra font-bold text-2xl text-center">
        SERVICIOS Y/O PAQUETES APLICADOS
      </h3>

      <div className="w-full flex justify-between items-center my-6">
        <div className="w-full flex-col">
          <TablaDinamica
            estilosHeader="bg-white text-cendiatra border-cendiatra border rounded-xl font-bold text-lg"
            data={examenes}
            columns={columns}
          >
            <div className="grid grid-cols-5 w-full">
              <div class="col-start-5 col-span-1 flex items-center justify-center">
                <div className="flex gap-10 mr-16 text-cendiatra-gris-3 font-bold text-xl">
                  <span>TOTAL</span>
                  <span>{aplicarFormatoMoneda(total)}</span>
                </div>
              </div>
            </div>
          </TablaDinamica>
        </div>
      </div>
    </div>
  )
}

export default ServiciosPaquetes
