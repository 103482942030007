import React, {useState, useEffect, useContext} from 'react'
import {NavLink} from 'react-router-dom'
import FormularioGestionFirma from '../../componentes/Administrador/formularios/FormularioGestionFirma'
import closeImg from '../../imagenes/Salir.svg'
import {ObtenerMedico} from '../../microservicios/Medico'
import {useLocation} from 'react-router-dom'
import {ContextApplication} from '../../contexto'

const EditarFirma = () => {
  const {state} = useLocation()
  const contextoApp = useContext(ContextApplication)

  const [datosMedico, setDatosMedico] = useState({
    id: '',
    nombre: '',
    rol: '',
    documento: '',
    registroMedico: '',
    licencia: '',
    fechaVencimientoLicencia: '',
  })

  useEffect(() => {
    obtenerMedico()
  }, [])

  const obtenerMedico = async () => {
    await ObtenerMedico(state.id)
      .then(async (res) => {
        setDatosMedico(res.data)
        contextoApp.setUrlCargaImagen(res.data.urlFirmaSello)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        <div className="flex w-10/12 justify-center items-start flex-wrap">
          <div className="w-full flex flex-wrap justify-center items-center">
            <span className="  text-center text-21px leading-23px font-bold text-cendiatra my-25px">
              EDITAR FIRMA HISTORIA CLÍNICA USUARIO MÉDICO
            </span>
          </div>
          <div className="bg-white w-full  rounded rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl mt-5 p-6">
            <div className="flex flex-row ml-auto justify-end gap-4 w-1/2">
              <NavLink to="/gestionfirma">
                <img className="h-7" src={closeImg} alt="close" />
              </NavLink>
            </div>

            <div className="w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex mb-16 mt-10">
              <FormularioGestionFirma
                datosMedico={datosMedico}
              ></FormularioGestionFirma>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditarFirma
