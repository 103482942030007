import React, {useContext, useEffect} from 'react'
import {AreaDeTexto} from '../../../inputs'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {regexBasicoV1} from '../../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../../constantes'

const ExamenesParaclinicos = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  var regex = regexBasicoV1
  var errorObservaciones = regex.test(
    contextoMedicoOcupacional.examenesParaclinicos
  )
    ? null
    : TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        errorObservaciones !== null
      )
    }
  }, [errorObservaciones, contextoMedicoOcupacional.numeroPagina])

  const manejarCambioExamenesParaClinicos = (e) => {
    contextoMedicoOcupacional.setExamenesParaclinicos(e.target.value)
  }

  return (
    <div className="w-full flex items-center justify-center flex-wrap">
      <AreaDeTexto
        estilosContenedor={`w-full`}
        estilosAreaDeTexto={`h-20 my-3 rounded relative block w-full  p-1.5 border ${
          errorObservaciones ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
        } text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        titulo={'EXÁMENES PARACLÍNICOS'}
        placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
        cambioValor={(e) => manejarCambioExamenesParaClinicos(e)}
        valor={contextoMedicoOcupacional.examenesParaclinicos}
      />
      <span className={`flex text-cendiatra-semaforo-rojo w-full text-13px `}>
        {errorObservaciones !== null && errorObservaciones}
      </span>
    </div>
  )
}

export default ExamenesParaclinicos
