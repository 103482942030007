import axios from 'axios'
import {loginRequest} from '../authConfig'
import {getMsalInstance} from '../msalService'
import {obtenerApiUrl} from '../config'

let restApi = null

const initializeRestApi = async () => {
  const apiConfig = await obtenerApiUrl()
  restApi = axios.create({
    baseURL: apiConfig,
  })
}

const getRestApi = async () => {
  if (!restApi) {
    await initializeRestApi()
  }
  return restApi
}

let idToken = '...'

const getAccountInfo = () => {
  const accounts = getMsalInstance().getAllAccounts()
  return accounts[0]
}

const refreshAccessToken = async () => {
  const account = getAccountInfo()
  try {
    const token = await getMsalInstance().acquireTokenSilent({
      account,
      scopes: loginRequest.scopes,
    })
    idToken = token.idToken
    return token.accessToken
  } catch (error) {
    return getMsalInstance()
      .acquireTokenRedirect(loginRequest)
      .then((resp) => {
        return resp.accessToken
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export {getRestApi, refreshAccessToken, getAccountInfo, idToken}
