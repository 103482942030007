import React from 'react'

const LabelNumero = ({numero, esHeader}) => {
  return (
    <div className={`flex justify-start items-center flex-wrap w-5 mr-2`}>
      <label
        className={`block text-13px w-5 h-5 text-center ${
          esHeader
            ? 'text-white bg-cendiatra-verde-2 rounded rounded-full font-bold'
            : 'text-cendiatra-verde-2'
        }`}
      >
        {numero}
      </label>
    </div>
  )
}

export default LabelNumero
