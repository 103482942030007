import React from 'react'
import addButtonImg from '../../../../imagenes/addButton.png'
import removeButtonImg from '../../../../imagenes/removeButton.png'
import {InputNumerico, InputRegex} from '../../../inputs'
import BotonConImagen from '../../../BotonConImagen'

const RangoOpcion = ({
  id,
  rangoInferior,
  rangoSuperior,
  valorRango,
  actualizarValorRango,
  eliminarRango,
  agregarRango,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-wrap">
        <InputNumerico
          estilosContenedor={'w-3/12'}
          estilosInput={
            'my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
          }
          placeholder={'Rango inferior'}
          onValueChange={(e) => {
            actualizarValorRango('rangoInferior', e, id)
          }}
          valor={rangoInferior}
        />
        <label className="pt-5 mx-2"> a </label>
        <InputNumerico
          estilosContenedor={'w-3/12'}
          estilosInput={
            'my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
          }
          placeholder={'Rango superior'}
          onValueChange={(e) => {
            actualizarValorRango('rangoSuperior', e, id)
          }}
          valor={rangoSuperior}
        />
        <label className="pt-5 mx-2"> {'--->'} </label>
        <InputRegex
          ancho={'w-3/12 flex'}
          cambioValor={(e) => {
            actualizarValorRango('valor', e, id)
          }}
          estilosContenedor={'w-full flex'}
          estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
          tipo={'text'}
          placeholder={'Valor rango'}
          valor={valorRango}
          requerido={true}
        />
        <BotonConImagen
          estilosContenedor={'w-10 pt-2'}
          imagen={removeButtonImg}
          medio={true}
          funcionAEjecutar={() => {
            eliminarRango(id)
          }}
          textoAlternativo={'eliminar'}
        />
        <BotonConImagen
          estilosContenedor={'w-6 pt-2'}
          imagen={addButtonImg}
          medio={true}
          funcionAEjecutar={() => {
            agregarRango(id)
          }}
          textoAlternativo={'agregar'}
        />
      </div>
    </div>
  )
}

export default RangoOpcion
