export const TextoSemaforo = ({
  alineacion = 'justify-start',
  colorSemaforo = 'bg-cendiatra-verde-3',
  estilosTexto,
  mostrarSemaforo = true,
  texto,
}) => {
  return (
    <p className={`flex w-full flex-row items-center ${alineacion}`}>
      {mostrarSemaforo && (
        <span className={`w-3 h-3 mr-2 rounded-full ${colorSemaforo}`} />
      )}
      <span className={estilosTexto}>{texto}</span>
    </p>
  )
}
