import {useState} from 'react'
import {ObtenerCiudadesPorDepartamento} from '../../cache/servicios-cache'

export const useAutocompletarCiudad = () => {
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([])

  const filtrarCiudades = async (nombreDepartamento, textoEscrito) => {
    if (typeof textoEscrito === 'string' && textoEscrito.length >= 3) {
      textoEscrito = textoEscrito.toLocaleUpperCase()

      const ciudades = await ObtenerCiudadesPorDepartamento(
        textoEscrito,
        nombreDepartamento
      )

      setCiudadesFiltradas(
        ciudades.map((ciudad) => ({
          id: ciudad.Id,
          filtro: ciudad.Nombre,
        }))
      )
    } else {
      setCiudadesFiltradas([])
    }
  }

  return [filtrarCiudades, ciudadesFiltradas]
}
