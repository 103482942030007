import React, {useEffect, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {Input} from '../../../inputs'
import {regexBasicoV1} from '../../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../../constantes'

const AntecedentesGinecobstetricos = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  const {
    getValues,
    reset,
    control,
    formState: {errors, isValidating, isValid},
  } = useForm({
    defaultValues: {
      gpa: '',
      ultimoParto: '',
      fum: '',
      planificacion: '',
    },
    mode: 'onChange',
  })

  useEffect(async () => {
    if (!isValidating && isValid) {
      contextoMedicoOcupacional.setAntecedentesGinecobstetricos(getValues())
    }
  }, [isValidating])

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, contextoMedicoOcupacional.numeroPagina])

  useEffect(() => {
    reset(contextoMedicoOcupacional.antecedentesGinecobstetricos)
  }, [contextoMedicoOcupacional.actualizar])

  useEffect(async () => {
    if (
      contextoMedicoOcupacional.numeroPagina == numeroPagina &&
      contextoMedicoOcupacional.informacionUsuario.datosUsuario.find(
        (x) => x.titulo == 'Género'
      )?.contenido != 'MUJER'
    ) {
      contextoMedicoOcupacional.setNumeroPagina(
        contextoMedicoOcupacional.numeroPagina + 1
      )
    }
  }, [contextoMedicoOcupacional.numeroPagina])

  return (
    <div className="w-full flex flex-wrap justify-start items-center">
      <Controller
        name="gpa"
        control={control}
        rules={{
          required: false,
          maxLength: 60,
          pattern: regexBasicoV1,
        }}
        render={({field: {onChange, value}}) => (
          <div className="w-1/3 pr-10 mb-2">
            <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.gpa?.type === 'maxLength' &&
                'El texto llegó a los 60 caracteres permitidos'}
              {errors.gpa?.type === 'pattern' &&
                'Se permiten estos caracteres:'}
            </span>
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.gpa?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1.split(
                  ':'
                )[1].trim()}
            </span>
            <Input
              estilosContenedor={'w-full'}
              estilosInput={
                Object.keys(errors).find((element) => element === 'gpa')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'DESCRIPCIÓN (TEXTO CORTO)'}
              titulo={'GPA'}
              onChange={onChange}
              valor={value}
            />
          </div>
        )}
      />
      <Controller
        name="ultimoParto"
        control={control}
        rules={{
          required: false,
          maxLength: 60,
          pattern: regexBasicoV1,
        }}
        render={({field: {onChange, value}}) => (
          <div className="w-1/3 pr-10 mb-2">
            <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.ultimoParto?.type === 'maxLength' &&
                'El texto llegó a los 60 caracteres permitidos'}
              {errors.ultimoParto?.type === 'pattern' &&
                'Se permiten estos caracteres:'}
            </span>
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.ultimoParto?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1.split(
                  ':'
                )[1].trim()}
            </span>
            <Input
              estilosContenedor={'w-full'}
              estilosInput={
                Object.keys(errors).find((element) => element === 'ultimoParto')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'DESCRIPCIÓN (TEXTO CORTO)'}
              titulo={'ÚLTIMO PARTO (AÑOS)'}
              onChange={onChange}
              valor={value}
            />
          </div>
        )}
      />
      <Controller
        name="fum"
        control={control}
        rules={{
          required: false,
          maxLength: 60,
          pattern: regexBasicoV1,
        }}
        render={({field: {onChange, value}}) => (
          <div className="w-1/3 pr-10 mb-2">
            <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.fum?.type === 'maxLength' &&
                'El texto llegó a los 60 caracteres permitidos'}
              {errors.fum?.type === 'pattern' &&
                'Se permiten estos caracteres:'}
            </span>
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.fum?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1.split(
                  ':'
                )[1].trim()}
            </span>
            <Input
              estilosContenedor={'w-full'}
              estilosInput={
                Object.keys(errors).find((element) => element === 'fum')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'DESCRIPCIÓN (TEXTO CORTO)'}
              titulo={'FUM'}
              onChange={onChange}
              valor={value}
            />
          </div>
        )}
      />
      <Controller
        name="planificacion"
        control={control}
        rules={{
          required: false,
          maxLength: 60,
          pattern: regexBasicoV1,
        }}
        render={({field: {onChange, value}}) => (
          <div className="w-1/3 pr-10 mb-2">
            <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.planificacion?.type === 'maxLength' &&
                'El texto llegó a los 60 caracteres permitidos'}
              {errors.planificacion?.type === 'pattern' &&
                'Se permiten estos caracteres:'}
            </span>
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.planificacion?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1.split(
                  ':'
                )[1].trim()}
            </span>
            <Input
              estilosContenedor={'w-full'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'planificacion'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'DESCRIPCIÓN (TEXTO CORTO)'}
              titulo={'PLANIFICACIÓN'}
              onChange={onChange}
              valor={value}
            />
          </div>
        )}
      />
    </div>
  )
}

export default AntecedentesGinecobstetricos
