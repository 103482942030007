import {getRestApi} from './ApiAxios'

export const obtenerResoluciones = async () => {
  const apiReq = await getRestApi()
  let {data: resoluciones, status} = await apiReq.get(
    'facturacion-function/ObtenerResoluciones'
  )

  return status === 200 ? resoluciones : []
}

export const obtenerResolucionPorId = async (id) => {
  const apiReq = await getRestApi()
  const {data: resolucion} = await apiReq.get(
    'facturacion-function/ObtenerResolucionPorId',
    {params: {id}}
  )

  return resolucion
}

export const crearResolucion = async (datosEnviar) => {
  const apiReq = await getRestApi()
  const {data: id} = await apiReq.put(
    'facturacion-function/CrearResolucion',
    datosEnviar
  )

  return id
}

export const actualizarResolucion = async (datosEnviar) => {
  const apiReq = await getRestApi()
  return apiReq.patch('facturacion-function/ActualizarResolucion', datosEnviar)
}
