import {dbComun} from '../bases-datos/dbComun'
import {ObtenerCiudadPorId} from './Ciudades'

export const ObtenerTodosLosAliados = async () => {
  return Promise.resolve(dbComun.table('Aliados').toArray())
}

export const ObtenerAliadoPorNombreONombreCiudad = async (filtro) => {
  let aliados = await dbComun.table('Aliados').toArray()

  let ciudades = await dbComun.table('Ciudades').toArray()

  let aliadosFiltradosPorNombre = aliados
    .filter(
      ({Nombre}) => Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
    )
    .map((aliado) => ({
      id: aliado.Id,
      filtro:
        ciudades.find(({Id}) => Id == aliado.CiudadId).Nombre +
        ' - ' +
        aliado.Nombre,
    }))

  let ciudadesIds = (
    await dbComun
      .table('Ciudades')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((ciudad) => {
    return ciudad.Id
  })

  let aliadosFiltradosPorCiudad = aliados
    .filter((aliado) => ciudadesIds.includes(aliado.CiudadId))
    .map((aliado) => ({
      id: aliado.Id,
      filtro:
        ciudades.find(({Id}) => Id == aliado.CiudadId).Nombre +
        ' - ' +
        aliado.Nombre,
    }))

  return Promise.resolve(
    aliadosFiltradosPorNombre.concat(aliadosFiltradosPorCiudad)
  )
}

export const ObtenerAliadoPorId = async (id) => {
  return Promise.resolve(dbComun.table('Aliados').get({Id: id}))
}
