import {IndicadorContador} from './IndicadorContador'

export const IndicadorContadores = ({
  tituloPrincipal,
  contadores,
  ancho = 'w-full',
}) => {
  return (
    <div
      className={`flex flex-row border-2 p-2 border-cendiatra-verde-2 rounded-lg ${ancho}`}
    >
      {tituloPrincipal && (
        <span className=" w-5/12 text-cendiatra-rojo-1">{tituloPrincipal}</span>
      )}
      {Array.isArray(contadores) &&
        contadores.map((contador, indice) => (
          <IndicadorContador
            key={`contador-${indice}:${contador.titulo}`}
            titulo={contador.titulo}
            valor={contador.valor === 0 ? '-' : contador.valor}
          />
        ))}
    </div>
  )
}
