import {dbComun} from '../bases-datos/dbComun'

export const ObtenerTodasLasArl = async () => {
  return Promise.resolve(dbComun.table('Arl').toArray())
}

export const ObtenerArlPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('Arl')
      .filter(
        ({Nombre}) =>
          Nombre.toLocaleUpperCase().indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerArlPorId = async (id) => {
  return Promise.resolve(dbComun.table('Arl').get({Id: id}))
}
