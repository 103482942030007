import React, {useState, useEffect} from 'react'
import moment from 'moment'

const TextoTiempoEspera = ({tiempoInicial, colorTexto}) => {
  const [tiempo, setTiempo] = useState(tiempoInicial)

  const calcularTiempo = () => {
    return moment(tiempo, 'HH:mm:ss').add(1, 'seconds').format('HH:mm:ss')
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTiempo(calcularTiempo())
    }, 1000)

    return () => clearTimeout(timer)
  })

  return <span className={`${colorTexto}`}>{tiempo}</span>
}

export default TextoTiempoEspera
