import {dbComun} from '../bases-datos/dbComun'

export const ObtenerCiudadPorNombre = async (nombre) => {
  return Promise.resolve(dbComun.table('Ciudades').get({Nombre: nombre}))
}

export const FiltrarCiudadPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('Ciudades')
      .filter(
        ({Nombre}) =>
          Nombre.toLocaleUpperCase().indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerCiudadPorId = async (id) => {
  return Promise.resolve(
    dbComun
      .table('Ciudades')
      .filter(({Id}) => Id == id)
      .toArray()
  )
}

export const ObtenerInformacionCiudadPorId = async (id) => {
  return Promise.resolve(dbComun.table('Ciudades').get({Id: id}))
}

export const ObtenerCiudadesPorDepartamento = async (
  filtroCiudad,
  nombreDepartamento
) => {
  let departamento = await dbComun
    .table('Departamentos')
    .get({Nombre: nombreDepartamento ?? ''})
  return Promise.resolve(
    dbComun
      .table('Ciudades')
      .filter(
        ({Nombre}) => Nombre.indexOf(filtroCiudad.toLocaleUpperCase()) > -1
      )
      .filter(({DepartamentoId}) =>
        departamento
          ? DepartamentoId == departamento.Id
          : (DepartamentoId = DepartamentoId)
      )
      .toArray()
  )
}

export const ObtenerTodasCiudades = async () => {
  return Promise.resolve(dbComun.table('Ciudades').toArray())
}
