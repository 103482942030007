import {useContext, useEffect, useMemo, useState} from 'react'
import {ObtenerTodosLosServicios} from '../../../cache/servicios-cache'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import BotonConImagen from '../../BotonConImagen'
import addButtonImg from '../../../imagenes/addButton.png'
import removeButtonImg from '../../../imagenes/removeButton.png'
import {Controller, useForm} from 'react-hook-form'
import {AreaDeTexto} from '../../inputs'
import TablaDinamica from '../../TablaDinamica'
import {BotonSimple} from '../../Botones'
import {ContextApplication, ContextoModales} from '../../../contexto'
import {
  GenerarFormatoSolicitudValoracion,
  ObtenerSolicitudValoracionPorServicioAtencionId,
} from '../../../microservicios'
import {GuardarSolicitudValoracion} from '../../../microservicios/Examen'
import {TEXTO_VISUAL} from '../../../constantes'

const defaultValues = {
  servicios: [],
  motivo: '',
  observaciones: '',
}

export default function SolicitudDeValoracion() {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoModales = useContext(ContextoModales)
  const {control, setValue, watch, trigger, reset, getValues} = useForm({
    defaultValues,
    mode: 'onChange',
  })
  const [filtro, setFiltro] = useState('')
  const [seleccionado, setSeleccionado] = useState(null)
  const [examenes, setExamenes] = useState([])

  const obtenerServicios = async () => {
    const response = await ObtenerTodosLosServicios()
    setExamenes(response ? response : [])
  }

  useEffect(() => {
    obtenerServicios()
    trigger()

    return () => contextoModales.setSolicitudValoracion({})
  }, [])

  useEffect(() => {
    trigger()
    if (!!contextoModales?.parametrosFormulario?.servicioAtencionId) {
      ;(async () => {
        try {
          const res = await ObtenerSolicitudValoracionPorServicioAtencionId(
            contextoModales.parametrosFormulario.servicioAtencionId
          )
          if (res.status === 204) {
            reset(defaultValues)
          } else {
            reset(res.data)
            contextoModales.setSolicitudValoracion({
              ...contextoModales.solicitudValoracion,
              ...res.data,
              guardado: true,
            })
          }
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [])

  const removerItemDeSeleccionados = (id) => {
    const array = watch('servicios')

    setValue(
      'servicios',
      array.filter((item) => item !== id)
    )
  }

  const obtenerExamenPorId = (id) => {
    const examen = examenes.find((item) => item.Id === id)

    return examen
  }

  const columnasTablaServicios = useMemo(
    () => [
      {
        Header: 'SERVICIO',
        accessor: 'filtro',
        className:
          'w-full flex text-center justify-center items-center content-center h-20  my-1 mx-0.5 rounded-md text-14px uppercase',
        Cell: ({row}) => {
          const examen = obtenerExamenPorId(row.original.id)
          return (
            <div className="w-full flex flex-wrap">
              <div className="w-11/12 text-left ">{`${examen?.CodigoCompleto} - ${examen?.Nombre}`}</div>
              <div className="w-1/12 text-center">
                <BotonConImagen
                  imagen={removeButtonImg}
                  ancho={'w-full'}
                  medio={true}
                  funcionAEjecutar={() =>
                    removerItemDeSeleccionados(row.original.id)
                  }
                  textoAlternativo={'eliminar'}
                />
              </div>
            </div>
          )
        },
      },
    ],
    [examenes]
  )

  const informacionFiltrada = () => {
    return !filtro
      ? []
      : examenes
          .filter(
            (examen) =>
              examen?.Nombre?.toLowerCase().includes(filtro) ||
              examen?.nombre?.toLowerCase().includes(filtro) ||
              examen?.CodigoCompleto?.toLowerCase().includes(filtro) ||
              examen?.codigoCompleto?.toLowerCase().includes(filtro)
          )
          .map((examen) => ({
            id: examen?.Id ?? examen?.id,
            filtro: `${examen.CodigoCompleto} - ${examen?.Nombre}`,
          }))
  }

  const funcionGuardar = async () => {
    const form = getValues()
    try {
      const payload = {
        servicioAtencionId:
          contextoModales.parametrosFormulario.servicioAtencionId,
        servicios: form.servicios,
        motivo: form.motivo ?? '',
        observaciones: form.observaciones ?? '',
      }

      const response = await GuardarSolicitudValoracion(payload)

      if (response?.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'Solicitud de valoración guardado',
          contenido: '',
          botones: [
            {
              nombre: TEXTO_VISUAL.BOTONES.REGRESAR_A_EXAMEN,
              click: funcionCerrarModal,
            },
            {
              nombre: 'Imprimir',
              click: imprimirSolicitudDeValoracion,
            },
          ],
        })
        contextoModales.setSolicitudValoracion({
          ...contextoModales.solicitudValoracion,
          guardado: true,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const funcionCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const imprimirSolicitudDeValoracion = async () => {
    try {
      const res = await GenerarFormatoSolicitudValoracion(
        contextoModales.parametrosFormulario.servicioAtencionId
      )
      if (res?.data) {
        window.open(res?.data?.replace(/['"]+/g, ''), '_blank').focus()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full">
      <div
        className={`w-full pb-10 ${
          contextoModales.solicitudValoracion.guardado
            ? 'pointer-events-none'
            : ''
        }`}
      >
        <div className="w-full flex flex-wrap">
          <div className="w-11/12">
            <CampoFiltrarEspañol
              titulo={'Seleccione uno o varios servicios*'}
              estilosInput={`${
                !watch('servicios').length
                  ? 'border-red-600'
                  : 'border-cendiatra '
              } text-cendiatra-gris-3`}
              colorTextoLabel="normal-case"
              placeholder={'DESCRIPCIÓN'}
              estilosPersonalizados={`w-full`}
              valorDelCampoFiltro={filtro || ''}
              informacionFiltrada={informacionFiltrada()}
              handleChange={(e) => setFiltro(e.target.value)}
              tipo="text"
              desactivarOtroFiltro={false}
              handleOptionChange={(e) => {
                setFiltro(e.filtro)
                setSeleccionado(e)
              }}
              estilosOpcion="text-cendiatra-gris-3"
              id={1}
            />
          </div>
          <BotonConImagen
            ancho={'w-1/12'}
            imagen={addButtonImg}
            textoAlternativo={'Boton Agregar'}
            funcionAEjecutar={() => {
              if (!!!seleccionado) return

              if (
                !watch('servicios').find(
                  (examen) => examen === seleccionado?.id
                )
              ) {
                setValue('servicios', [...watch('servicios'), seleccionado.id])
              }
              setSeleccionado(null)
              setFiltro('')
            }}
            desabilitado={!!!seleccionado}
          />
        </div>
        <div className="w-full">
          <TablaDinamica
            columns={columnasTablaServicios}
            data={watch('servicios').map((servicio) => ({id: servicio}))}
            quitarThead
          />
        </div>
        <div className="w-full">
          <Controller
            name="motivo"
            control={control}
            render={({field, fieldState}) => {
              return (
                <AreaDeTexto
                  titulo={'Escriba el motivo de la remisión'}
                  estilosContenedor={'w-full normal-case'}
                  estilosAreaDeTexto={`rounded relative block w-full p-1.5 border ${
                    fieldState.error
                      ? 'border-cendiatra-rojo-1'
                      : 'border-cendiatra'
                  } text-cendiatra-gris-3 rounded-lg focus:outline-none sm:text-sm`}
                  placeholder={'Descripción'}
                  rows={4}
                  cambioValor={field.onChange}
                  valor={field.value}
                />
              )
            }}
          />
        </div>
        <div className="w-full">
          <Controller
            name="observaciones"
            control={control}
            render={({field, fieldState}) => {
              return (
                <AreaDeTexto
                  titulo={'Observaciones adicionales'}
                  estilosContenedor={'w-full normal-case'}
                  estilosAreaDeTexto={`rounded relative block w-full p-1.5 border ${
                    fieldState.error
                      ? 'border-cendiatra-rojo-1'
                      : 'border-cendiatra'
                  } text-cendiatra-gris-3 rounded-lg focus:outline-none sm:text-sm`}
                  placeholder={'Descripción'}
                  rows={4}
                  cambioValor={field.onChange}
                  valor={field.value}
                />
              )
            }}
          />
        </div>
      </div>
      <div className="w-full">
        <BotonSimple
          texto={'Guardar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            watch('servicios').length &&
            !contextoModales.solicitudValoracion?.guardado
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={funcionGuardar}
          deshabilitado={
            !watch('servicios').length ||
            contextoModales.solicitudValoracion?.guardado
          }
        />
        <BotonSimple
          texto={'Regresar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 bg-btnBg`}
          funcion={funcionCerrarModal}
        />
        <BotonSimple
          texto={'Imprimir'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            contextoModales.solicitudValoracion?.guardado
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          deshabilitado={!contextoModales.solicitudValoracion?.guardado}
          funcion={imprimirSolicitudDeValoracion}
        />
      </div>
    </div>
  )
}
