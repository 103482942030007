import {dbComun} from '../bases-datos/dbComun'

export const ObtenerTipoFacturacionPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('TipoFacturacion')
      .filter(
        ({Nombre}) =>
          Nombre.toLocaleUpperCase().indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerTipoFacturacionPorId = async (id) => {
  return Promise.resolve(dbComun.table('TipoFacturacion').get({Id: id}))
}
