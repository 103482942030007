import {useContext, useEffect, useMemo} from 'react'
import {
  ContextApplication,
  ContextoControlVersionPlantillas,
  ContextoPlantilla,
} from '../../../contexto'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import BarraEncabezadosPestana from '../../../componentes/Pestanas/BarraEncabezadosPestana'
import Pestana from '../../../componentes/Pestanas/Pestana'
import FormularioNombrarPlantilla from '../../../componentes/Administrador/formularios/FormularioNombrarPlantilla'
import PlantillaEstructura from '../../../componentes/Administrador/Plantilla/PlantillaEstructura'
import ParametrosCertificado from '../../../componentes/Administrador/Plantilla/ParametrosCertificado'
import ParametrosHistoria from '../../../componentes/Administrador/Plantilla/ParametrosHistoria'
import PreVisualizacion from '../../../componentes/Administrador/Plantilla/PreVisualizacion'
import {TEXTO_VISUAL} from '../../../constantes/textoVisual'
import {useLocation} from 'react-router-dom'
import {ObtenerTiposCampo} from '../../../cache/servicios-cache/TipoCampo'
import {
  CrearPlantillaBD,
  ModificarPlantilla,
  ObtenerCamposPreCarga,
} from '../../../microservicios/Plantilla'
import {AgregarFuentesDeDatosACache} from '../../../cache/servicios-cache/FuentesDeDatos'
import Boton from '../../../componentes/Botones/Boton'
import {useHistory} from 'react-router-dom'

function ControlVersionPlantilla() {
  const history = useHistory()
  const {state} = useLocation()
  const contextoAplicacion = useContext(ContextApplication)
  const contextoCVPlantillas = useContext(ContextoControlVersionPlantillas)
  const contextoPlantilla = useContext(ContextoPlantilla)
  const esBorrador = useMemo(() => state?.esBorrador ?? false, [state])
  const version = useMemo(
    () => (state?.esBorrador ? state?.version : state?.version + 1),
    [state]
  )

  useEffect(() => {
    if (!contextoCVPlantillas.plantilla?.id) {
      history.goBack()
    }
  }, [contextoCVPlantillas.plantilla])

  const listaPestanas = [
    'Nombrar plantilla',
    'Definir estructura',
    'Parámetros para certificado',
    'Parámetros para historia',
    'Vista previa',
  ]

  const retornarPayload = (necesitoIds = true, esCreacion) => {
    const {id, plantillaId, ...restoPlantilla} = contextoPlantilla.plantilla
    if (!necesitoIds) {
      return {
        ...restoPlantilla,
        nombrePlantilla: restoPlantilla.nombrePlantilla.trim(),
        pestanas: restoPlantilla.pestanas.map((pestana) => {
          return {
            ...pestana,
            tituloPestana: pestana.tituloPestana.trim(),
            secciones: pestana.secciones.map((seccion) => {
              return {
                ...seccion,
                nombreSeccion: seccion.nombreSeccion.trim(),
                campos: seccion.campos.map((campo) => {
                  return {
                    ...campo,
                    atributos: JSON.stringify(campo.atributos),
                    tituloCampo: campo.tituloCampo.trim(),
                  }
                }),
              }
            }),
          }
        }),
        esBorrador: !esCreacion,
      }
    }
    return {
      ...restoPlantilla,
      nombrePlantilla: restoPlantilla.nombrePlantilla.trim(),
      pestanas: restoPlantilla.pestanas.map((pestana) => {
        const {id, ...restoPestana} = pestana
        return {
          ...restoPestana,
          tituloPestana: pestana.tituloPestana.trim(),
          secciones: pestana.secciones.map((seccion) => {
            const {id, ...restoSeccion} = seccion
            return {
              ...restoSeccion,
              nombreSeccion: seccion.nombreSeccion.trim(),
              campos: seccion.campos.map((campo) => {
                const {id, ...restoCampo} = campo
                return {
                  ...restoCampo,
                  atributos: JSON.stringify(campo.atributos),
                  tituloCampo: campo.tituloCampo.trim(),
                }
              }),
            }
          }),
        }
      }),
      botones: restoPlantilla.botones.map((boton) => {
        const {id, ...restoBoton} = boton
        return {
          ...restoBoton,
        }
      }),
      esBorrador: !esCreacion,
    }
  }

  const manejarGuardar = async (esCreacion = true) => {
    try {
      const plantilla = retornarPayload(!esBorrador, esCreacion)

      if (esBorrador) {
        Object.assign(plantilla, {
          id: state?.id,
          plantillaId: state?.plantillaId,
        })
      } else {
        Object.assign(plantilla, {
          plantillaId: state?.plantillaId,
        })
      }

      let respuesta

      if (!esBorrador) {
        respuesta = await CrearPlantillaBD(plantilla)
      } else {
        respuesta = await ModificarPlantilla(plantilla)
      }

      if (respuesta.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: '',
          contenido: (
            <EsqueletoNotificacionControlVersion
              nombrePlantilla={plantilla.nombrePlantilla}
              esCreacion={esCreacion}
              id={plantilla?.id ?? respuesta?.data}
              version={version}
            />
          ),
          botones: [
            {
              nombre: 'Finalizar',
              click: () => {
                history.push('/main')
                contextoAplicacion.cerrarAppModal()
              },
            },
            {
              nombre: 'Regresar',
              click: () => {
                history.go(0)
                contextoAplicacion.cerrarAppModal()
              },
            },
          ],
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (Object.entries(contextoCVPlantillas.plantilla).length) {
      contextoPlantilla.setPlantilla(contextoCVPlantillas.plantilla)
    } else {
    }
  }, [contextoCVPlantillas.plantilla])

  useEffect(() => {
    ;(async () => {
      contextoPlantilla.setTiposCampo(await ObtenerTiposCampo())
      const preCarga =
        (await ObtenerCamposPreCarga())?.data?.map((item) => ({
          ...item,
          atributos: JSON.parse(item.atributos),
        })) ?? []
      contextoPlantilla.setCamposPreCarga(preCarga)
      await AgregarFuentesDeDatosACache()
    })()
  }, [])

  return (
    <ContenedorPagina
      tituloPagina={
        esBorrador
          ? `${TEXTO_VISUAL.TITILO_ADMINISTRADOR_GATO.EDITAR_BORRADOR_PLANTILLA} NO. ${version}`
          : `${TEXTO_VISUAL.TITILO_ADMINISTRADOR_GATO.CREAR_NUEVA_VERSION_PLANTILLA} NO. ${version}`
      }
    >
      <div
        className={`w-full flex justify-center items-center mt-10 flex-wrap`}
      >
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoPlantilla}
        />
        <Pestana contexto={contextoPlantilla} numeroPagina={1}>
          <FormularioNombrarPlantilla numeroPagina={1} />
        </Pestana>
        <Pestana
          contexto={contextoPlantilla}
          numeroPagina={2}
          renderOnValidacionPagina={true}
        >
          <PlantillaEstructura numeroPagina={2} />
        </Pestana>
        <Pestana contexto={contextoPlantilla} numeroPagina={3}>
          <ParametrosCertificado numeroPagina={3} />
        </Pestana>
        <Pestana contexto={contextoPlantilla} numeroPagina={4}>
          <ParametrosHistoria numeroPagina={4} />
        </Pestana>
        <Pestana
          contexto={contextoPlantilla}
          numeroPagina={5}
          clickGuardar={manejarGuardar}
        >
          <PreVisualizacion numeroPagina={5} />
        </Pestana>
      </div>
      <div className="w-full flex justify-center items-center pointer-events-auto">
        <div className="w-full flex justify-center items-center pointer-events-auto">
          <Boton
            titulo={'Guardar Borrador'}
            lineaPrimerCaracter={false}
            funcionCLick={() => manejarGuardar(false)}
            colorPorDefecto={'bg-grayColor'}
            colorActivo={'bg-btnBg'}
            tipo={'button'}
            habilitado={true}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}

function EsqueletoNotificacionControlVersion({
  esCreacion,
  version,
  nombrePlantilla,
  id,
}) {
  return (
    <div className="w-full">
      <div className="w-full">
        <strong>
          {esCreacion
            ? 'SE HA CREADO LA VERSIÓN'
            : 'SE HA GUARDADO EN BORRADOR'}
        </strong>
      </div>
      <div className="w-full">
        <strong>
          {esCreacion ? 'DE PLANTILLA NO.' : 'LA PLANTILLA NO.'} {version}
        </strong>
      </div>
      <div className="w-full">
        <strong>{nombrePlantilla}</strong>
      </div>
      <div className="w-full">
        <strong>EXITOSAMENTE</strong>
      </div>
      <br />
      <div className="w-full">
        <strong>ID PLANTILLA: {id}</strong>
      </div>
    </div>
  )
}

export default ControlVersionPlantilla
