import {ROLES_APP} from '../constantes'

export const ROLES_LOCAL_STORAGE_APP = [
  ROLES_APP.MEDICO_OCUPACIONAL,
  ROLES_APP.AUXILIAR_ENFERMERIA,
  ROLES_APP.FONOAUDIOLOGO,
  ROLES_APP.OPTOMETRA,
  ROLES_APP.PSICOLOGO,
  ROLES_APP.BACTERIOLOGO,
  ROLES_APP.GERENTE_GENERAL,
  ROLES_APP.FISIOTERAPEUTA,
]

export function fnAsignarInformacionSessionOLocalStorage(
  informacion = {key: '', valor: ''},
  rol
) {
  if (!ROLES_LOCAL_STORAGE_APP.includes(rol)) {
    sessionStorage.setItem(informacion.key, informacion.valor)
    return
  }

  localStorage.setItem(informacion.key, informacion.valor)
}

export function fnObtenerInformacionSessionOLocalStorage(key = '', rol) {
  if (!ROLES_LOCAL_STORAGE_APP.includes(rol)) {
    return sessionStorage.getItem(key) ?? null
  }

  return localStorage.getItem(key) ?? null
}

export function fnRemoverInformacionSessionOLocalStorage(key = '', rol) {
  if (!ROLES_LOCAL_STORAGE_APP.includes(rol)) {
    return sessionStorage.removeItem(key)
  }

  return localStorage.removeItem(key)
}
