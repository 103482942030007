import React, {useContext, useEffect, useCallback} from 'react'
import FormularioTarifario from '../../componentes/Tarifario/FormularioTarifario'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink} from 'react-router-dom'
import {ContextoTarifario, ContextApplication} from '../../contexto'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import Pestana from '../../componentes/Pestanas/Pestana'
import TablaAliados from '../../componentes/Tablas/TablaAliados'
import TablaSedes from '../../componentes/Tablas/TablaSedes'
import FormularioPrecios from '../../componentes/Formularios/FormularioPrecios'
import {ObtenerProximoId} from '../../microservicios/Referencia'
import {TIPO_OBTENER_PROXIMO_ID} from '../../constantes/tipoObtenerProximoId'
import {AgregarTarifariosACache} from '../../cache/servicios-cache/Tarifarios'
import {AgregarEmpresasACache} from '../../cache/servicios-cache/Empresas'
import {transformarNumero} from '../../utilidades/funcionesComunes'
import {useGuardarTarifario} from '../../hooks/tarifarios/useGuardarTarifario'
import InputSeleccionarPortafolio from '../../componentes/Tarifario/InputSeleccionarPortafolio'
import {AgregarPaquetesACache} from '../../cache/servicios-cache/Paquetes'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'

const CrearTarifario = () => {
  const contextoTarifario = useContext(ContextoTarifario)

  const contextoApp = useContext(ContextApplication)

  const [manejarGuardar] = useGuardarTarifario(true)

  const obtenerInformacionInicial = useCallback(async () => {
    contextoApp.setIsLoading(true)
    await ObtenerProximoId(TIPO_OBTENER_PROXIMO_ID.TARIFARIO).then((res) => {
      contextoTarifario.setIdTarifario(transformarNumero(res.data))
    })
    contextoTarifario.setFormularioEmpresa({
      nombre: '',
      empresa: '',
      fechaInicial: '',
      fechaFinal: '',
    })
    contextoTarifario.setListaServicios([])
    contextoTarifario.setListaPaquetes([])
    contextoTarifario.setFilaSeleccionada(-1)
    contextoTarifario.setNombreTarifarioValidacion('')
    contextoTarifario.setActualizar(!contextoTarifario.actualizar)
    await AgregarEmpresasACache()
    await AgregarTarifariosACache()
    await AgregarPaquetesACache()
    await AgregarServiciosACache()
    contextoApp.setIsLoading(false)
  }, [])

  useEffect(() => {
    contextoTarifario.setTotalPaginas(4)
    obtenerInformacionInicial().catch(console.error)
    contextoTarifario.setNumeroPagina(1)
    contextoTarifario.setModoEdicion(false)
    contextoTarifario.setServiciosIniciales([])
    contextoTarifario.setPaquetesIniciales([])
    contextoTarifario.setValidacionActivarBotonTarifario(false)
  }, [obtenerInformacionInicial])

  const listaPestanas = [
    'Seleccionar Empresa',
    'Seleccionar Sedes',
    'Seleccionar Aliados',
    'Asignar Precios y Alias',
  ]

  const usuarioSeguroDeCambios = () => {
    contextoTarifario.setPortafolioSeleccionadoId('')
  }

  useEffect(() => {
    if (contextoTarifario.seguroDeCambio) {
      contextoTarifario.setSeguroDeCambio(false)
      contextoApp.setModal({
        abierto: true,
        titulo: 'ESTÁ MODIFICANDO DATOS DEL PORTAFOLIO',
        contenido: '¿ESTÁ SEGURO DE REALIZARLO?',
        botones: [
          {
            nombre: 'Si',
            click: () => {
              usuarioSeguroDeCambios()
              contextoApp.cerrarAppModal()
            },
          },
          {
            nombre: 'No',
            click: contextoApp.cerrarAppModal,
          },
        ],
      })
    }
  }, [contextoTarifario.seguroDeCambio])

  return (
    <ContenedorPagina tituloPagina={TEXTO_VISUAL.TARIFARIO.CREAR_TARIFARIO}>
      <div className="justify-center items-center flex">
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoTarifario}
        />
      </div>
      <InputSeleccionarPortafolio numeroPagina={1} />
      <Pestana contexto={contextoTarifario} numeroPagina={1}>
        <FormularioTarifario numeroPagina={1} />
      </Pestana>
      <Pestana contexto={contextoTarifario} numeroPagina={2}>
        <TablaSedes contexto={contextoTarifario} numeroPagina={2} />
      </Pestana>
      <Pestana contexto={contextoTarifario} numeroPagina={3}>
        <TablaAliados contexto={contextoTarifario} numeroPagina={3} />
      </Pestana>
      <Pestana
        contexto={contextoTarifario}
        numeroPagina={4}
        clickGuardar={manejarGuardar}
      >
        <FormularioPrecios contexto={contextoTarifario} numeroPagina={4} />
      </Pestana>
    </ContenedorPagina>
  )
}
export default CrearTarifario
