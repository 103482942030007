import React, {useState, createContext} from 'react'

export const ContextoRegistro = createContext('')

export const RegistroContexto = ({children}) => {
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(6)
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1)
  const [informacionPersonal, setInformacionPersonal] = useState('')
  const [informacionDireccion, setInformacionDireccion] = useState({
    paisResidencia: 'COLOMBIA',
    departamento: '',
    municipio: '',
    localidad: '',
  })
  const [informacionGeneral, setInformacionGeneral] = useState({
    eps: '',
    arl: '',
    fondoPension: '',
    raza: '',
  })
  const [informacionOrdenServicio, setInformacionOrdenServicio] = useState({
    numeroOrden: '',
    tipoExamen: '',
    cargo: '',
  })
  const [informacionFacturacion, setInformacionFacturacion] = useState({})
  const [formularioActualTieneErrores, setformularioActualTieneErrores] =
    useState(true)
  const [actualizar, setActualizar] = useState(false)
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState({
    id: '',
    nombre: '',
    empresaPadreId: '',
    nit: '',
    tipoAtencion: '',
    observaciones: '',
    tipoFacturacion: '',
  })
  const [empresaHijaSeleccionada, setEmpresaHijaSeleccionada] = useState({
    id: '',
    nombre: '',
    empresaPadreId: '',
    nit: '',
    observaciones: '',
  })
  const [tarifarioRegistro, setTarifarioRegistro] = useState('')
  const [todosLosExamenes, setTodosLosExamenes] = useState([])
  const [valorTotalServicios, setValorTotalServicios] = useState(0)
  const [valorSubTotalAtencion, setValorSubTotalAtencion] = useState(0)
  const [valorTotalAtencion, setValorTotalAtencion] = useState(0)
  const [porcentajeCortesia, setPorcentajeCortesia] = useState(0)
  const [valorIva, setValorIva] = useState(0)
  const [valorCortesia, setValorCortesia] = useState(0)

  const [turno, setTurno] = useState(null)
  const [numeroDocumento, setNumeroDocumento] = useState('')
  const [tipoDocumentoId, setTipoDocumentoId] = useState('')

  const [examen, setExamen] = useState('')
  const [examenParaAgregar, setExamenParaAgregar] = useState('')
  const [examenesFiltrados, setExamenesFiltrados] = useState([])
  const [examenesSeleccionados, setExamenesSeleccionados] = useState([])
  const [usuarioExiste, setUsuarioExiste] = useState(false)

  const [medioDePagoParaAgregar, setMedioDePagoParaAgregar] = useState({
    idMedioPago: '',
    medioDePago: '',
    valor: '',
    voucher: '',
  })
  const [mediosDePagoSeleccionados, setMediosDePagoSeleccionados] = useState([])
  const [sumatoriaMediosDePago, setSumatoriaMediosDePago] = useState(0)

  const [sedeNoExiste, setSedeNoExiste] = useState(false)

  const [idAtencion, setIdAtencion] = useState(0)
  const [existeAtencion, setExisteAtencion] = useState(false)

  const [atencionCreada, setAtencionCreada] = useState(false)

  const [validacionBoton, setValidacionBoton] = useState(true)
  const [validacionBotonCredito, setValidacionBotonCredito] = useState(true)

  const [usuarioId, setUsuarioId] = useState('')

  const [urlComprobante, setUrlComprobante] = useState('')
  const [emitioFactura, setEmitioFactura] = useState(false)
  const [documentoRecibo, setDocumentoRecibo] = useState('')

  const [modificarPaciente, setModificarPaciente] = useState(false)

  const [descontarSaldoPortal, setDescontarSaldoPortal] = useState(false)

  const [empresasHijas, setEmpresasHijas] = useState([])

  //PERIFERICOS
  const [foto, setFoto] = useState('')

  const [firma, setFirma] = useState('')

  // MODIFICAR PACIENTE

  const [
    modificarPacienteInformacionPersonal,
    setModificarPacienteInformacionPersonal,
  ] = useState({})
  const [modificarPacienteUsuarioId, setModificarPacienteUsuarioId] =
    useState('')
  const [
    modificarPacienteNumeroDocumento,
    setModificarPacienteNumeroDocumento,
  ] = useState('')
  const [
    modificarPacienteTipoDocumentoId,
    setModificarPacienteTipoDocumentoId,
  ] = useState('')
  const [pacientesFiltrados, setPacientesFiltrados] = useState([])
  const [erroresModificarFormulario, setErroresModificarFormulario] = useState({
    personal: true,
    direccion: true,
    firmaFotoHuella: false,
  })

  const [mediosDePagoRecarga, setMediosDePagoRecarga] = useState([])

  const [
    cancelarValidacionServiciosYPaquetes,
    setCancelarValidacionServiciosYPaquetes,
  ] = useState(false)

  const [
    serviciosInicialesIdsModificacion,
    setServiciosInicialesIdsModificacion,
  ] = useState([])
  const [
    paquetesInicialesIdsModificacion,
    setPaquetesInicialesIdsModificacion,
  ] = useState([])

  const [
    informacionServiciosPaquetesIniciales,
    setInformacionServiciosPaquetesIniciales,
  ] = useState([])

  const [
    informacionServiciosPaquetesNuevos,
    setInformacionServiciosPaquetesNuevos,
  ] = useState([])

  const [mediosDePagoIniciales, setMediosDePagoIniciales] = useState({
    informacionMediosDePago: [],
    totalTransaccionAnterior: '',
    informacionFacturacion: [],
    contesiaPorcentaje: 0,
    cortesia: 0,
    informacionFacturacionUltimoTab: [],
    informacionNuevaUltimoTab: [],
  })

  const [informacionCobrarMediosDePago, setInformacionCobrarMediosDePago] =
    useState({
      texto: 'COBRAR MEDIOS DE PAGO POR:',
      valor: 0,
      estilosTexto: 'text-cendiatra-verde-2',
      tituloMediosDePago: 'MEDIOS DE PAGO',
    })

  const [informacionFacturacionNueva, setInformacionFacturacionNueva] =
    useState({
      informacionFacturacion: [
        {
          titulo: '',
          valor: 0,
          estilosFila: '',
        },
        {
          titulo: '',
          valor: 0,
          estilosFila: '',
        },
        {
          titulo: '',
          valor: 0,
          estilosFila: '',
        },
      ],
      cortesia: 0,
      valorTotalAtencion: 0,
      valorTotalCalculado: 0,
      valorPorcentaje: 0,
    })
  const [empresaInicial, setEmpresaInicial] = useState({
    empresaPadreId: '',
    id: '',
    nombre: '',
    nit: '',
  })

  return (
    <ContextoRegistro.Provider
      value={{
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
        filaSeleccionada: filaSeleccionada,
        setFilaSeleccionada: setFilaSeleccionada,
        informacionPersonal: informacionPersonal,
        setInformacionPersonal: setInformacionPersonal,
        informacionDireccion: informacionDireccion,
        setInformacionDireccion: setInformacionDireccion,
        informacionGeneral: informacionGeneral,
        setInformacionGeneral: setInformacionGeneral,
        informacionOrdenServicio: informacionOrdenServicio,
        setInformacionOrdenServicio: setInformacionOrdenServicio,
        informacionFacturacion: informacionFacturacion,
        setInformacionFacturacion: setInformacionFacturacion,
        formularioActualTieneErrores: formularioActualTieneErrores,
        setformularioActualTieneErrores: setformularioActualTieneErrores,
        empresaSeleccionada: empresaSeleccionada,
        setEmpresaSeleccionada: setEmpresaSeleccionada,
        empresaHijaSeleccionada: empresaHijaSeleccionada,
        setEmpresaHijaSeleccionada: setEmpresaHijaSeleccionada,
        tarifarioRegistro: tarifarioRegistro,
        setTarifarioRegistro: setTarifarioRegistro,
        todosLosExamenes: todosLosExamenes,
        setTodosLosExamenes: setTodosLosExamenes,
        examen: examen,
        setExamen: setExamen,
        examenParaAgregar: examenParaAgregar,
        setExamenParaAgregar: setExamenParaAgregar,
        examenesFiltrados: examenesFiltrados,
        setExamenesFiltrados: setExamenesFiltrados,
        examenesSeleccionados: examenesSeleccionados,
        setExamenesSeleccionados: setExamenesSeleccionados,
        valorTotalServicios: valorTotalServicios,
        setValorTotalServicios: setValorTotalServicios,
        valorSubTotalAtencion: valorSubTotalAtencion,
        setValorSubTotalAtencion: setValorSubTotalAtencion,
        valorTotalAtencion: valorTotalAtencion,
        setValorTotalAtencion: setValorTotalAtencion,
        valorIva: valorIva,
        setValorIva: setValorIva,
        porcentajeCortesia: porcentajeCortesia,
        setPorcentajeCortesia: setPorcentajeCortesia,
        valorCortesia: valorCortesia,
        setValorCortesia: setValorCortesia,
        medioDePagoParaAgregar: medioDePagoParaAgregar,
        setMedioDePagoParaAgregar: setMedioDePagoParaAgregar,
        mediosDePagoSeleccionados: mediosDePagoSeleccionados,
        setMediosDePagoSeleccionados: setMediosDePagoSeleccionados,
        sumatoriaMediosDePago: sumatoriaMediosDePago,
        setSumatoriaMediosDePago: setSumatoriaMediosDePago,
        foto: foto,
        setFoto: setFoto,
        atencionCreada: atencionCreada,
        setAtencionCreada: setAtencionCreada,
        idAtencion: idAtencion,
        setIdAtencion: setIdAtencion,
        numeroDocumento: numeroDocumento,
        setNumeroDocumento: setNumeroDocumento,
        tipoDocumentoId: tipoDocumentoId,
        setTipoDocumentoId: setTipoDocumentoId,
        actualizar: actualizar,
        setActualizar: setActualizar,
        usuarioId: usuarioId,
        setUsuarioId: setUsuarioId,
        existeAtencion: existeAtencion,
        setExisteAtencion: setExisteAtencion,
        sedeNoExiste: sedeNoExiste,
        setSedeNoExiste: setSedeNoExiste,
        validacionBoton: validacionBoton,
        setValidacionBoton: setValidacionBoton,
        validacionBotonCredito: validacionBotonCredito,
        setValidacionBotonCredito: setValidacionBotonCredito,
        urlComprobante: urlComprobante,
        setUrlComprobante: setUrlComprobante,
        turno: turno,
        setTurno: setTurno,
        modificarPaciente: modificarPaciente,
        setModificarPaciente: setModificarPaciente,
        modificarPacienteInformacionPersonal:
          modificarPacienteInformacionPersonal,
        setModificarPacienteInformacionPersonal:
          setModificarPacienteInformacionPersonal,
        modificarPacienteUsuarioId: modificarPacienteUsuarioId,
        setModificarPacienteUsuarioId: setModificarPacienteUsuarioId,
        modificarPacienteNumeroDocumento: modificarPacienteNumeroDocumento,
        setModificarPacienteNumeroDocumento:
          setModificarPacienteNumeroDocumento,
        modificarPacienteTipoDocumentoId: modificarPacienteTipoDocumentoId,
        setModificarPacienteTipoDocumentoId:
          setModificarPacienteTipoDocumentoId,
        pacientesFiltrados: pacientesFiltrados,
        setPacientesFiltrados: setPacientesFiltrados,
        erroresModificarFormulario: erroresModificarFormulario,
        setErroresModificarFormulario: setErroresModificarFormulario,
        firma: firma,
        setFirma: setFirma,
        documentoRecibo: documentoRecibo,
        setDocumentoRecibo: setDocumentoRecibo,
        emitioFactura: emitioFactura,
        setEmitioFactura: setEmitioFactura,
        mediosDePagoRecarga: mediosDePagoRecarga,
        setMediosDePagoRecarga: setMediosDePagoRecarga,
        cancelarValidacionServiciosYPaquetes:
          cancelarValidacionServiciosYPaquetes,
        setCancelarValidacionServiciosYPaquetes:
          setCancelarValidacionServiciosYPaquetes,
        descontarSaldoPortal: descontarSaldoPortal,
        setDescontarSaldoPortal: setDescontarSaldoPortal,
        serviciosInicialesIdsModificacion: serviciosInicialesIdsModificacion,
        setServiciosInicialesIdsModificacion:
          setServiciosInicialesIdsModificacion,
        paquetesInicialesIdsModificacion: paquetesInicialesIdsModificacion,
        setPaquetesInicialesIdsModificacion:
          setPaquetesInicialesIdsModificacion,
        informacionServiciosPaquetesIniciales:
          informacionServiciosPaquetesIniciales,
        setInformacionServiciosPaquetesIniciales:
          setInformacionServiciosPaquetesIniciales,
        informacionServiciosPaquetesNuevos: informacionServiciosPaquetesNuevos,
        setInformacionServiciosPaquetesNuevos:
          setInformacionServiciosPaquetesNuevos,
        mediosDePagoIniciales: mediosDePagoIniciales,
        setMediosDePagoIniciales: setMediosDePagoIniciales,
        informacionCobrarMediosDePago: informacionCobrarMediosDePago,
        setInformacionCobrarMediosDePago: setInformacionCobrarMediosDePago,
        informacionFacturacionNueva: informacionFacturacionNueva,
        setInformacionFacturacionNueva: setInformacionFacturacionNueva,
        empresaInicial: empresaInicial,
        setEmpresaInicial: setEmpresaInicial,
        empresasHijas: empresasHijas,
        setEmpresasHijas: setEmpresasHijas,
        usuarioExiste: usuarioExiste,
        setUsuarioExiste: setUsuarioExiste,
      }}
    >
      {children}
    </ContextoRegistro.Provider>
  )
}
