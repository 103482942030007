import React, {useContext} from 'react'
import Boton from '../Botones/Boton'
import {useForm, Controller} from 'react-hook-form'
import Input from './Input'
import {FachadaObtenerSolicitudesModificarAtencion} from '../../microservicios/Fachada'
import {ContextoJefeDeSede, ContextApplication} from '../../contexto'
import moment from 'moment'
import {regexBasicoV1} from '../../constantes/regex'
import {TEXTO_VISUAL} from '../../constantes'

const FiltroSeleccionarExamenesAtendidos = () => {
  const {
    reset,
    handleSubmit,
    setError,
    control,
    formState: {errors},
  } = useForm({
    defaultValues: {
      numeroDocumento: '',
      apellidos: '',
      nombre: '',
    },
    mode: 'onChange',
  })

  const contextoJefeDeSede = useContext(ContextoJefeDeSede)
  const contextoAplicacion = useContext(ContextApplication)

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const onSubmit = async (datos) => {
    if (!datos.numeroDocumento && !datos.nombre && !datos.apellidos) {
      setError('numeroDocumento')
      setError('nombre')
      setError('apellidos')
      return
    }
    if ((!datos?.nombre || !datos?.apellidos) && !datos.numeroDocumento) {
      return contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <div className="text-center w-full">
            <h3 className="text-cendiatra  text-3xl font-bold normal-case">
              La búsqueda debe
            </h3>
            <h3 className="text-cendiatra mb-6 text-3xl font-bold normal-case">
              hacerse con nombre y apellido
            </h3>
            <p className="text-cendiatra-verde-3 text-xl my-10 font-bold normal-case">
              Debe diligenciar los dos campos para realizar la búsqueda
            </p>
          </div>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModal,
          },
        ],
      })
    }

    FachadaObtenerSolicitudesModificarAtencion(
      contextoAplicacion.sede.sedeId,
      datos.numeroDocumento,
      datos.nombre,
      datos.apellidos
    )
      .then((res) => {
        let examenesAtendidos = res.data
        if (examenesAtendidos) {
          contextoJefeDeSede.setexamenesAtendidosFiltrados(
            examenesAtendidos.map((examen) => ({
              id: examen?.usuarioId ?? '',
              atencionId: examen?.atencionId ?? '',
              solicitudId: examen?.solicitudId ?? '',
              fechaAtencion: examen?.fechaAtencion
                ? moment(examen?.fechaAtencion).format('YYYY-MM-DD')
                : '',
              numeroDocumento: examen?.numeroDocumento ?? '',
              apellidos: examen?.apellidos.toUpperCase() ?? '',
              nombre: examen?.nombre.toUpperCase() ?? '',
            }))
          )
        } else {
          contextoJefeDeSede.setexamenesAtendidosFiltrados([])
        }
      })
      .catch((error) => {
        console.log(error)
        contextoJefeDeSede.setexamenesAtendidosFiltrados([])
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`flex justify-end items-end flex-wrap mr-16`}>
        <Controller
          name="numeroDocumento"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              estilosContenedor={'w-3/5 md:w-2/12 mr-20 mb-2'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'numeroDocumento'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'No. de documento'}
              onChange={onChange}
              valor={value}
            />
          )}
        />
        <Controller
          name="nombre"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="w-3/5 md:w-2/12 mr-20 mb-2">
              <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nombre?.type === 'pattern' &&
                  'Se permiten estos caracteres:'}
              </span>
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nombre?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1.split(
                    ':'
                  )[1].trim()}
              </span>
              <Input
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'nombre')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'Nombre'}
                onChange={onChange}
                valor={value}
              />
            </div>
          )}
        />
        <Controller
          name="apellidos"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="w-3/5 md:w-2/12 mr-20 mb-2">
              <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.apellidos?.type === 'pattern' &&
                  'Se permiten estos caracteres:'}
              </span>
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.apellidos?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1.split(
                    ':'
                  )[1].trim()}
              </span>
              <Input
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'apellidos')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'Apellidos'}
                onChange={onChange}
                valor={value}
              />
            </div>
          )}
        />
        <Boton
          titulo="Buscar"
          habilitado={true}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
          alto={'h-9'}
        />
        <Boton
          titulo="Limpiar"
          habilitado={true}
          funcionCLick={() => {
            reset({numeroDocumento: '', apellidos: '', nombre: ''})
            contextoJefeDeSede.setexamenesAtendidosFiltrados([])
          }}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          alto={'h-9'}
        />
      </div>
    </form>
  )
}

export default FiltroSeleccionarExamenesAtendidos
