import React, {useContext} from 'react'
import SeleccionarServicio from '../../componentes/Servicios/SeleccionarServicio'
import {ContextoServicio, ContextApplication} from '../../contexto'
import FormularioCrearServicio from '../../componentes/Servicios/FormularioCrearServicio'
import {NavLink} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import {ObtenerServicioPorId} from '../../microservicios/Servicio'
import {ObtenerCupPorId} from '../../cache/servicios-cache/Cups.js'
import {ObtenerClasificacionPorId} from '../../cache/servicios-cache/ClasificacionServicios'
import {ModificarServicio} from '../../microservicios/Servicio'
import {useHistory} from 'react-router-dom'
import {ObtenerDuracionCitaPorId} from '../../cache/servicios-cache/DuracionCita'
import {ObtenerCuentaDeIngresoPorId} from '../../cache/servicios-cache/CuentaDeIngreso'
import {ObtenerGrupoLaboratorioPorId} from '../../cache/servicios-cache/GrupoLaboratorio'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'

const EditarServicio = () => {
  const contextoServicio = useContext(ContextoServicio)

  const contextoAplicacion = useContext(ContextApplication)

  const history = useHistory()

  const cargarDatosServicio = (fila) => {
    ObtenerServicioPorId(fila.original.Id).then(async (res) => {
      if (res.data) {
        let cupInformacion = await ObtenerCupPorId(res.data.cupsId)
        contextoServicio.setInformacionServicio({
          clasificacionServicio: res.data.clasificacionId,
          codigoInterno: res.data.codigoInterno,
          cuentaIngreso: res.data.cuentaIngresoId,
          cups: cupInformacion.Codigo + ' - ' + cupInformacion.Nombre,
          grupoLaboratorio:
            res.data.grupoLaboratorioId === 0
              ? ''
              : res.data.grupoLaboratorioId,
          iva: `${res.data.iva}%`,
          nombreServicio: res.data.nombre,
          observacionesPreparacion: res.data.observaciones,
          siglaSticker: res.data.siglaSticker,
          siglaStickerInicial: res.data.siglaSticker,
          tiempoDuracion: res.data.tiempoDuracion,
          nombreInicial: res.data.nombre,
          id: res.data.id,
        })
        contextoServicio.setCupsInformacion(cupInformacion)
        contextoServicio.setActualizar(!contextoServicio.actualizar)
        contextoServicio.setModoEdicion(true)
      }
    })
  }

  const editarServicio = async () => {
    const payloadEditarServicio = {
      id: contextoServicio.informacionServicio.id,
      cupsId: contextoServicio.cupsInformacion.Id,
      codigoInterno: contextoServicio.informacionServicio.codigoInterno,
      nombre: contextoServicio.informacionServicio.nombreServicio.toUpperCase(),
      cuentaIngresoId: contextoServicio.informacionServicio.cuentaIngreso,
      tiempoDuracion:
        contextoServicio.informacionServicio.tiempoDuracion === undefined ||
        contextoServicio.informacionServicio.tiempoDuracion === ''
          ? 0
          : contextoServicio.informacionServicio.tiempoDuracion,
      iva: contextoServicio.informacionServicio.iva
        ? isNaN(contextoServicio.informacionServicio.iva)
          ? contextoServicio.informacionServicio.iva.replace('%', '')
          : contextoServicio.informacionServicio.iva
        : 0,
      clasificacion: (
        await ObtenerClasificacionPorId(
          parseInt(contextoServicio.informacionServicio.clasificacionServicio)
        )
      ).Nombre,
      clasificacionId:
        contextoServicio.informacionServicio.clasificacionServicio,
      grupoLaboratorioId: contextoServicio.informacionServicio.grupoLaboratorio
        ? contextoServicio.informacionServicio.grupoLaboratorio
        : 0,
      siglaSticker: contextoServicio.informacionServicio.siglaSticker
        ? contextoServicio.informacionServicio.siglaSticker.toUpperCase()
        : '',
      observaciones:
        contextoServicio.informacionServicio?.observacionesPreparacion === null
          ? null
          : contextoServicio.informacionServicio?.observacionesPreparacion.toUpperCase(),
    }
    ModificarServicio(payloadEditarServicio)
      .then(async (res) => {
        if (res.status === 200) {
          let elementosModal = await Promise.all(
            res.data.map(async (elemento) => {
              return await retorInformacionElemento(elemento)
            })
          )
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'DATOS ACTUALIZADOS',
            contenido: (
              <>
                {elementosModal
                  .filter((element) => {
                    return element !== undefined
                  })
                  .map((elemento) => (
                    <div
                      key={elemento.nombre}
                      className="w-full flex flex-wrap justify-start items-center"
                    >
                      <span className="w-full justify-start items-center my-2">
                        <b>{elemento.nombre} ANTERIOR DEL SERVICIO</b> :
                        {elemento.valorAnterior}
                      </span>
                      <span className="w-full justify-start items-center my-2">
                        <b> ACTUAL {elemento.nombre} DEL SERVICIO</b> :
                        {elemento.valorNuevo}
                      </span>
                    </div>
                  ))}
              </>
            ),
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarFinalizar,
              },
              {
                nombre: 'Regresar',
                click: manejarRegresarAEditarServicios,
              },
            ],
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const retorInformacionElemento = async (elementoModificado) => {
    switch (elementoModificado.atributo) {
      case 'Nombre':
        return {
          nombre: 'NOMBRE',
          valorAnterior: elementoModificado.valorAnterior,
          valorNuevo: elementoModificado.valorActualizado,
        }
      case 'TiempoDuracion':
        return {
          nombre: 'TIEMPO DURACIÓN',
          valorAnterior:
            elementoModificado.valorAnterior === '0'
              ? ''
              : (
                  await ObtenerDuracionCitaPorId(
                    parseInt(elementoModificado.valorAnterior)
                  )
                ).Nombre,
          valorNuevo:
            elementoModificado.valorActualizado === '0'
              ? ''
              : (
                  await ObtenerDuracionCitaPorId(
                    parseInt(elementoModificado.valorActualizado)
                  )
                ).Nombre,
        }
      case 'Iva':
        return {
          nombre: 'IVA',
          valorAnterior: elementoModificado.valorAnterior + '%',
          valorNuevo: elementoModificado.valorActualizado + '%',
        }
      case 'ClasificacionId':
        return {
          nombre: 'CLASIFICACIÓN DE SERVICIO',
          valorAnterior: (
            await ObtenerClasificacionPorId(
              parseInt(elementoModificado.valorAnterior)
            )
          ).Nombre,
          valorNuevo: (
            await ObtenerClasificacionPorId(
              parseInt(elementoModificado.valorActualizado)
            )
          ).Nombre,
        }
      case 'Observaciones':
        return {
          nombre: 'OBSERVACION',
          valorAnterior: elementoModificado.valorAnterior,
          valorNuevo: elementoModificado.valorActualizado,
        }
      case 'CuentaIngresoId':
        return {
          nombre: 'CUENTA DE INGRESO',
          valorAnterior: (
            await ObtenerCuentaDeIngresoPorId(
              parseInt(elementoModificado.valorAnterior)
            )
          ).Nombre,
          valorNuevo: (
            await ObtenerCuentaDeIngresoPorId(
              parseInt(elementoModificado.valorActualizado)
            )
          ).Nombre,
        }
      case 'GrupoLaboratorioId':
        if (elementoModificado.valorAnterior === null) {
          return
        }
        return {
          nombre: 'GRUPO LABORATORIO',
          valorAnterior:
            elementoModificado.valorAnterior === '0'
              ? ''
              : (
                  await ObtenerGrupoLaboratorioPorId(
                    parseInt(elementoModificado.valorAnterior)
                  )
                ).Nombre,
          valorNuevo:
            elementoModificado.valorActualizado === '0'
              ? ''
              : (
                  await ObtenerGrupoLaboratorioPorId(
                    parseInt(elementoModificado.valorActualizado)
                  )
                ).Nombre,
        }
      case 'SiglaSticker':
        return {
          nombre: 'SIGLA STICKER',
          valorAnterior: elementoModificado.valorAnterior,
          valorNuevo: elementoModificado.valorActualizado,
        }
    }
  }
  const manejarRegresarAEditarServicios = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.go(0)
  }
  const manejarFinalizar = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/main')
  }
  return (
    <div className="min-h-screen flex flex-wrap items-center justify-center py-12  sm:px-6 lg:px-8 bg-AppBg bg-cover">
      {!contextoServicio.modoEdicion ? (
        <SeleccionarServicio funcionCargarDatos={cargarDatosServicio} />
      ) : (
        <ContenedorPagina
          tituloPagina={
            TEXTO_VISUAL.EJECUTIVO_COMERCIAL.MODIFICAR_DATOS_SERVICIO
          }
        >
          <div className="w-full flex justify-center items-center">
            <FormularioCrearServicio clickGuardar={editarServicio} />
          </div>
        </ContenedorPagina>
      )}
    </div>
  )
}

export default EditarServicio
