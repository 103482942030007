import React, {useState, createContext} from 'react'

export const ContextoReporte = createContext('')

export const ReporteConContexto = ({children}) => {
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(3)
  return (
    <ContextoReporte.Provider
      value={{
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
      }}
    >
      {children}
    </ContextoReporte.Provider>
  )
}
