import {useMemo} from 'react'
import {aplicarFormatoMoneda} from '../../../utilidades'
import {TablaConPaginacionYFiltros} from '../../Tablas'

import moment from 'moment'
import {orderBy} from 'lodash'

export default function TablaUltimosMovimientos({
  informacionEmpresa = {
    tipoConvenio: '',
    saldoActual: 0,
    movimientos: [],
  },
}) {
  const columnas = useMemo(
    () => [
      {
        Header: 'FECHA',
        accessor: 'fecha',
        cellStyles:
          'w-2/12 flex text-center justify-center items-center content-center border-cendiatra rounded-l-md  h-16  text-cendiatra-gris-1',
        headerStyle: 'w-2/12 text-cendiatra ml-0.5 rounded-l-md h-14',
        disableFilters: true,
        Cell: (row) => (
          <span className="line-clamp-1">
            {moment(row.row.original.fecha).isValid()
              ? moment(row.row.original.fecha).format('DD/MM/YYYY')
              : ''}
          </span>
        ),
        disableBackground: true,
      },
      {
        Header: 'VALOR',
        accessor: 'valor',
        headerStyle: 'w-3/12 text-cendiatra h-14',
        cellStyles:
          'w-3/12 flex text-center justify-center items-center content-center border-cendiatra  h-16 text-cendiatra-gris-1',
        disableFilters: true,
        Cell: (row) => (
          <span
            className={`${
              Math.sign(row.row.original.valor) < 0
                ? 'text-cendiatra-rojo-1'
                : 'text-cendiatra'
            }`}
          >
            {aplicarFormatoMoneda(row.row.original.valor)}
          </span>
        ),
        disableBackground: true,
      },
      {
        Header: 'DETALLE',
        accessor: 'detalle',
        headerStyle: 'w-4/12 text-cendiatra h-14',
        cellStyles:
          'w-4/12 flex text-center justify-center items-center content-center border-cendiatra  h-16 text-cendiatra-gris-1',
        disableFilters: true,
        disableBackground: true,
      },
      {
        Header: (row) => 'NÚMERO DE ATENCIÓN',
        accessor: 'atencionId',
        cellStyles:
          'w-2/12 flex text-center justify-center items-center content-center border-cendiatra rounded-r-md h-16 text-cendiatra-gris-1',
        headerStyle: 'w-2/12 text-cendiatra rounded-r-md h-14',
        disableFilters: true,
        disableBackground: true,
        Cell: (row) =>
          row.row.original.atencionId ? row.row.original.atencionId : '',
      },
    ],
    []
  )

  return (
    <div className="w-full">
      {informacionEmpresa.tipoConvenio ? (
        <div className="w-full grid grid-cols-2">
          <div className="flex justify-start">
            <span className="text-left text-21px font-bold text-cendiatra uppercase">
              TIPO DE CONVENIO: {informacionEmpresa.tipoConvenio}
            </span>
          </div>
          <div className="flex justify-end">
            <span className="text-right text-21px font-bold text-cendiatra uppercase">
              SALDO ACTUAL:{' '}
              {aplicarFormatoMoneda(informacionEmpresa.saldoActual)}
            </span>
          </div>
        </div>
      ) : null}
      <div className="w-full border border-cendiatra rounded-lg p-4">
        <div className="w-full flex justify-center">
          <span className="text-center text-21px font-bold text-cendiatra uppercase">
            ÚLTIMOS MOVIMIENTOS
          </span>
        </div>
        {informacionEmpresa.movimientos.length ? (
          <div>
            <TablaConPaginacionYFiltros
              columns={columnas}
              data={orderBy(informacionEmpresa.movimientos, (d) => d.fecha)}
              deshabilitarBordeFila={true}
              paginacion={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
