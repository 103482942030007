import {PublicClientApplication} from '@azure/msal-browser'
import {LogLevel} from '@azure/msal-browser'

let msalInstance = null

export const initializeMsal = async () => {
  console.log('cargando msal')
  // Cargar la configuración de forma asincrónica
  const response = await fetch('./config.json')
  const config = await response.json()

  // Usar la configuración cargada para inicializar MSAL
  msalInstance = new PublicClientApplication({
    auth: {
      clientId: config.REACT_APP_AD_CLIENT_ID,
      authority: config.REACT_APP_AD_AUTHORITY,
      redirectUri: config.REACT_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            default:
              return
          }
        },
      },
    },
  })
}

export const getMsalInstance = () => {
  if (!msalInstance) {
    throw new Error('MSAL Instance is not initialized.')
  }
  return msalInstance
}
