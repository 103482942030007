import {useHistory} from 'react-router-dom'
import BotonConImagen from '../../componentes/BotonConImagen'
import EstadisticasUsuarios from '../../componentes/ejecutivoComercial/EstadisticasUsuarios'
import TablaGestionUsuarios from '../../componentes/ejecutivoComercial/TablaGestionUsuarios'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import BtnCrearUsuario from '../../imagenes/BtnCrearUsuario.svg'
import {RUTAS_APP} from '../../constantes/rutas/rutasApp'
import {useEffect, useState} from 'react'
import {ObtenerEmpresaPorId} from '../../cache/servicios-cache/Empresas'
import {ROLES_PORTAL_CLIENTE} from '../../constantes/rolesPortalCliente'
import {AgregarUsuariosACache} from '../../cache/servicios-cache'
import {FachadaObtenerUsuariosB2c} from '../../microservicios/Fachada'

function GestionUsuariosPortalCliente() {
  const history = useHistory()
  const [usuarios, setUsuarios] = useState([])

  const obtenerUsuarios = async () => {
    setUsuarios([])
    try {
      const respuesta = await FachadaObtenerUsuariosB2c()
      if (respuesta.status === 200) {
        const datos = await Promise.all(
          respuesta?.data?.map(async (us) => {
            const empresa = await ObtenerEmpresaPorId(us?.empresaId ?? 0)
            const rol = ROLES_PORTAL_CLIENTE.find(
              (r) => r.nombre === us?.rol || r.id === us?.rol
            )

            return {
              ...us,
              nombres: us?.name ?? us?.nombres ?? '',
              usuario:
                us?.usuario ?? us?.userPrincipalName?.split('@')[0] ?? '',
              empresa: empresa?.Nombre ?? '',
              activo: us?.activo,
              rol: rol?.id ?? '',
              id: us?.guid ?? us?.id ?? '',
              cargo: us?.cargo ?? '',
              puedeActivar: us?.puedeActivar ?? false,
            }
          }) ?? []
        )
        const datosOrdenados = ordenarDatosUsuarios(datos)
        setUsuarios(datosOrdenados)
        AgregarUsuariosACache(datosOrdenados)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const ordenarDatosUsuarios = (datos) => {
    const datosOrdenados = datos.sort((a, b) => {
      const empresaA = a.empresa ? a.empresa.charAt(0).toLowerCase().trim() : ''
      const empresaB = b.empresa ? b.empresa.charAt(0).toLowerCase().trim() : ''

      if (empresaA === '') {
        return 1
      }
      if (empresaB === '') {
        return -1
      }

      if (empresaA < empresaB) {
        return -1
      }
      if (empresaA > empresaB) {
        return 1
      }
      return 0
    })

    return datosOrdenados
  }

  useEffect(() => {
    obtenerUsuarios()
  }, [])

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_EJECUTIVO_COMERCIAL
          .GESTION_USUARIOS_PORTAL_CLIENTE
      }
    >
      <div className="w-full">
        <div className="w-full flex flex-wrap pt-8">
          <div className="w-6/12">
            <div className="w-5/12">
              <BotonConImagen
                estilosContenedor={'text-white py-4 rounded-lg'}
                imagen={BtnCrearUsuario}
                funcionAEjecutar={() => {
                  history.push(
                    RUTAS_APP.EJECUTIVA_COMERCIAL.CREAR_USUARIO_PORTAL_CLIENTE,
                    {edicion: false}
                  )
                }}
              />
            </div>
          </div>
          <div className="w-6/12">
            <EstadisticasUsuarios
              activos={usuarios?.filter((x) => x?.activo)?.length ?? 0}
              creados={usuarios?.length ?? 0}
            />
          </div>
        </div>
        <div className="w-full">
          <TablaGestionUsuarios
            data={usuarios}
            funcionRecargaDatos={obtenerUsuarios}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default GestionUsuariosPortalCliente
