import React from 'react'

const ConfirmacionCierreRealizado = ({codigo, fecha}) => {
  return (
    <div className="w-full flex flex-wrap m-2">
      <span className="w-full text-cendiatra text-14px">
        <b>Código de cierre de caja:</b> {codigo}
      </span>
      <span className="w-full text-cendiatra text-14px">
        <b>Fecha y hora: </b>
        {fecha}
      </span>
    </div>
  )
}
export default ConfirmacionCierreRealizado
