import React from 'react'
import ImagenEstandar from './ImagenEstandar'
import TextoTiempoEspera from '../Agenda/TextoTiempoEspera'

const CuadroInformacionUsuario = ({
  estilosContenedor,
  titulo,
  tituloImagen,
  descripcionPrimeraLinea,
  descripcionSegundaLinea,
  datos,
  urlFoto,
  deshabilitarTiempo,
}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <div className="w-full flex justify-center items-center text-center ">
        <div className="w-1/3"></div>
        <div className="w-1/3 mb-10">
          <span className="text-17px text-cendiatra font-bold">{titulo}</span>
        </div>
        <div className="w-1/3 flex justify-end">
          {Boolean(deshabilitarTiempo) ? null : (
            <div className="w-auto h-auto flex justify-center items-center flex-wrap">
              <span className="text-cendiatra text-13px w-full">
                Tiempo de atención
              </span>
              <TextoTiempoEspera
                tiempoInicial={'00:00:00'}
                colorTexto={'text-cendiatra font-bold text-13px'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-center items-center -mt-10">
        <div className="w-3/12 flex justify-center items-center flex-wrap text-center -mt-5">
          <ImagenEstandar
            imagen={urlFoto}
            estilosContenedor={'w-full flex justify-center items-center my-3'}
            estilosImagen={'w-40 h-40 rounded-full'}
            textoAlternativo={'foto'}
          />
          <span className="w-full font-bold text-cendiatra text-14px">
            {tituloImagen}
          </span>
          <span className="w-full font-bold text-cendiatra-gris-1 text-13px">
            {descripcionPrimeraLinea}
          </span>
          <span className="w-full font-bold text-cendiatra-gris-1 text-13px">
            {descripcionSegundaLinea}
          </span>
        </div>

        <div className="w-9/12 flex justify-start items-center flex-wrap">
          {datos.map((item) => (
            <div
              key={item.titulo}
              className="w-1/3 flex-wrap my-2 pl-1 text-cendiatra-gris-1 text-13px uppercase mb-2"
            >
              <span className="text-cendiatra text-13px">
                {item.titulo + ': '}
              </span>
              {item.contenido}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CuadroInformacionUsuario
