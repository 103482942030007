import moment from 'moment'

export const analizarResolucion = (resolucion) => {
  const fechaFinal = moment(resolucion.fechaFinal)
  const fechaLimite = moment(fechaFinal).subtract(15, 'days')
  const estaProximaVencer = moment().isSameOrAfter(fechaLimite)

  const estaVencida =
    moment().isAfter(fechaFinal) ||
    resolucion.numeracionActual === resolucion.numeracionFinal

  return {
    estaProximaVencer,
    estaVencida,
  }
}
