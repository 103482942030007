import React from 'react'
import {NavLink} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'

const BotonCerrar = ({estilosContenedor, ruta}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <NavLink to={ruta}>
        <img className="h-7" src={closeImg} alt="close" />
      </NavLink>
    </div>
  )
}

export default BotonCerrar
