import React, {useContext} from 'react'
import closeImg from '../../../imagenes/Salir.svg'
import {NavLink} from 'react-router-dom'
import EncabezadoFondo from '../../../componentes/Tablas/EncabezadoFondo'
import FiltroSeleccionarFactura from '../../../componentes/inputs/FiltroSeleccionarFactura'
import BotonConImagen from '../../../componentes/BotonConImagen'
import IconoImprimirActivado from '../../../imagenes/iconoImpresoraCuadradoVerde.svg'
import IconoImprimirDesactivado from '../../../imagenes/iconoImprimirGrisCuadrado.svg'
import IconoCorreoActivado from '../../../imagenes/correo.svg'
import IconoCorreoDesactivado from '../../../imagenes/correo-deshabilitado.svg'
import TablaFacturacion from '../../../componentes/recepcionista/TablaFacturacion'
import moment from 'moment'
import {ContextoGestionFacturacion} from '../../../contexto'
import CeldaFormatoDinero from '../../../componentes/Celdas/CeldaFormatoDinero'
import {ObtenerDocumentoPDF} from '../../../microservicios/Facturacion'
import {base64ABlob} from '../../../utilidades/funcionesComunes'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../../constantes/textoVisual'
import {TablaConPaginacionYFiltros} from '../../../componentes/Tablas'

const ConsultaEstadoFacturacion = () => {
  const contextoGestionFacturacion = useContext(ContextoGestionFacturacion)

  const obtenerDocumentoPDFDeFactura = async (id, nombreFactura) => {
    try {
      const respuesta = await ObtenerDocumentoPDF(id)
      if (respuesta.status === 200) {
        if (respuesta.data?.documento) {
          const base64Content = respuesta.data.documento
          const arrayBuffer = base64ABlob(base64Content)
          const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
          const url = URL.createObjectURL(blob)

          const newWindow = window.open(url, '_blank')

          newWindow.addEventListener('load', () => {
            newWindow.document.title = nombreFactura
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columnasFacturas = React.useMemo(() => [
    {
      Header: (row) => <EncabezadoFondo titulo={'Número de factura'} />,
      accessor: 'numeroFactura',
      className: 'w-1/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Sede'} />,
      accessor: 'sedeId',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Fecha de emisión factura'} />,
      accessor: 'fechaEmisionFactura',
      className: 'w-1/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <span>
          {' '}
          {moment(row.row.original.fechaEmisionFactura).format('YYYY-MM-DD')}
        </span>
      ),
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Identificación y nombre de cliente'} />
      ),
      accessor: 'nombreCliente',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Valor total'} />,
      accessor: 'valorTotal',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={'text-center bg-transparent'}
          valor={row.row.original.valorTotal}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Estado de factura'} />,
      accessor: 'estadoFacturaId',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <div
          className="w-full flex flex-wrap justify-center items-center bg-cendiatra-verde-5  mx-0.5 rounded-md text-14px"
          style={{height: '5.4rem'}}
        >
          <div className="w-full flex justify-center items-center">Módulos</div>
          <div className="w-full flex flex-row justify-around">
            <span>Enviar</span>
            <span>Imprimir</span>
          </div>
        </div>
      ),
      accessor: 'modulos',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-r-lg pr-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <div
          className="w-full flex justify-center items-center text-cendiatra"
          style={{lineHeight: '15px'}}
        >
          <div className="w-1/2">
            <BotonConImagen
              imagen={
                row.row.original.facturaDisponible
                  ? IconoCorreoActivado
                  : IconoCorreoDesactivado
              }
              textoAlternativo={'correo'}
              funcionAEjecutar={null}
              desabilitado={!row.row.original.facturaDisponible}
              estilosImagen={'w-10 h-10 content-start justify-start'}
              estilosContenedor={' h-10 content-start flex-col pb-5'}
            />
          </div>
          <div className="w-1/2">
            <BotonConImagen
              imagen={
                row.row.original.facturaDisponible
                  ? IconoImprimirActivado
                  : IconoImprimirDesactivado
              }
              textoAlternativo={'imprimir'}
              funcionAEjecutar={() => {
                obtenerDocumentoPDFDeFactura(
                  row.row.original.id,
                  row.row.original.numeroFactura
                )
              }}
              desabilitado={!row.row.original.facturaDisponible}
              estilosImagen={'w-10 h-10 content-start justify-start'}
              estilosContenedor={' h-10 content-start flex-col pb-5'}
            />
          </div>
        </div>
      ),
    },
  ])

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.TITULOS_RECEPCIONISTA.CONSULTA_ESTADO_FACTURACION
      }
    >
      <FiltroSeleccionarFactura />
      <div className="bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex flex-wrap mb-16">
        <TablaConPaginacionYFiltros
          columns={columnasFacturas}
          data={contextoGestionFacturacion.facturaFiltrada}
        />
      </div>
    </ContenedorPagina>
  )
}

export default ConsultaEstadoFacturacion
