import React, {useState, createContext} from 'react'

export const ContextoBacteriologo = createContext({})

export const BacteriologoConContexto = ({children}) => {
  const [laboratoriosDiaActual, setLaboratoriosDiaActual] = useState([])
  const [laboratoriosDiasPasados, setLaboratoriosDiasPasados] = useState([])

  return (
    <ContextoBacteriologo.Provider
      value={{
        laboratoriosDiaActual: laboratoriosDiaActual,
        setLaboratoriosDiaActual: setLaboratoriosDiaActual,
        laboratoriosDiasPasados: laboratoriosDiasPasados,
        setLaboratoriosDiasPasados: setLaboratoriosDiasPasados,
      }}
    >
      {children}
    </ContextoBacteriologo.Provider>
  )
}
