import React, {useCallback, useContext, useEffect, useState} from 'react'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink} from 'react-router-dom'
import moment from 'moment'
import {ContextApplication, ContextoCierreCaja} from '../../contexto'
import {
  CrearCierreCaja,
  ObtenerCierreCaja,
  ObtenerConsolidadoDiaPorSede,
} from '../../microservicios/Caja'
import ConfirmacionCierreRealizado from '../../componentes/recepcionista/ConfirmacionCierreRealizado'
import {useMsal} from '@azure/msal-react'
import TablaCierreDeCaja from '../../componentes/recepcionista/TablaCierreDeCaja'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import CeldaFormatoDinero from '../../componentes/Celdas/CeldaFormatoDinero'
import {Controller, useForm} from 'react-hook-form'
import Input from '../../componentes/inputs/Input'
import Boton from '../../componentes/Botones/Boton'
import {TIPOS_MEDIOS_DE_PAGO} from '../../constantes/MediosDePago'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {regexBasicoV1} from '../../constantes/regex'

const CierreDeCaja = () => {
  const {accounts} = useMsal()
  const nombreUsuario = accounts[0].name

  const contextoAplicacion = useContext(ContextApplication)
  const contextoCierreCaja = useContext(ContextoCierreCaja)

  const [guardarHabilitado, setGuardarHabilitado] = useState(true)
  const [imprimirHabilitado, setImprimirHabilitado] = useState(false)
  const [valoresDeCaja, setvaloresDeCaja] = useState({})
  const [listaCajaCierre, setListaCajaCierre] = useState([])

  const {
    control,
    getValues,
    reset,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const obtenerInformacionCierre = async () => {
    let cierreCajaServicio = (
      await ObtenerCierreCaja(contextoAplicacion.sede.sedeId)
    ).data
    contextoCierreCaja.setCierreCaja(cierreCajaServicio)
    if (cierreCajaServicio) {
      contextoCierreCaja.setInputsHabilitados(false)
      setGuardarHabilitado(false)
      setImprimirHabilitado(true)
    } else {
      contextoCierreCaja.setInputsHabilitados(true)
      setGuardarHabilitado(true)
      setImprimirHabilitado(false)
    }
    contextoCierreCaja.setActualizar(!contextoCierreCaja.actualizar)
  }

  const obtenerConsolidadoDiaPorSede = useCallback(async () => {
    try {
      const resp = await ObtenerConsolidadoDiaPorSede(
        contextoAplicacion.sede.sedeId
      )
      setvaloresDeCaja(resp.data)
      reset(resp.data)

      setListaCajaCierre([
        {medioDePago: TIPOS_MEDIOS_DE_PAGO.BONO, valor: resp.data.valorBono},
        {
          medioDePago: TIPOS_MEDIOS_DE_PAGO.EFECTIVO,
          valor: resp.data.valorEfectivo,
        },
        {
          medioDePago: TIPOS_MEDIOS_DE_PAGO.TARJETA,
          valor: resp.data.valorTarjetas,
        },
        {
          medioDePago: TIPOS_MEDIOS_DE_PAGO.TIQUETERA,
          valor: resp.data.valorTiquetera,
        },
        {
          medioDePago: TIPOS_MEDIOS_DE_PAGO.USO_DE_SALDO_CUENTA_CLIENTE,
          valor: resp.data.valorSaldoPortal,
        },
        {
          medioDePago: TIPOS_MEDIOS_DE_PAGO.CONSIGNACIONES_TRANSFERENCIAS,
          valor: resp.data.valorConsignacion,
        },
        {
          medioDePago: TIPOS_MEDIOS_DE_PAGO.RETEFUENTE_RENTA,
          valor: resp.data.valorRetefuenteRenta,
        },
        {
          medioDePago: TIPOS_MEDIOS_DE_PAGO.RETEFUENTE_ICA,
          valor: resp.data.valorRetefuenteIca,
        },
        {
          medioDePago: TEXTO_VISUAL.TEXTO_GENERAL.TOTAL,
          valor: resp.data.valorTotal,
        },
      ])
    } catch (error) {
      console.error(error)
    }
  }, [contextoAplicacion.sede.sedeId])

  useEffect(() => {
    obtenerConsolidadoDiaPorSede()
  }, [obtenerConsolidadoDiaPorSede])

  const obtenerInformacionInicial = useCallback(async () => {
    await obtenerInformacionCierre()
  }, [])

  useEffect(() => {
    obtenerInformacionInicial().catch(console.error)
  }, [obtenerInformacionInicial])

  const guardarCierre = async () => {
    let payloadGuardar = {
      observaciones: getValues().observaciones,
      sedeId: contextoAplicacion.sede.sedeId,
      recepcionId: contextoAplicacion.sede.recepcionId,
      nombreRecepcionista: nombreUsuario,
      nombreSede: contextoAplicacion.sede.nombreSede,
    }

    await CrearCierreCaja(payloadGuardar)
      .then((respuesta) => {
        if (respuesta.status === 200) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'CIERRE DE CAJA REALIZADO',
            contenido: (
              <ConfirmacionCierreRealizado
                codigo={respuesta.data}
                fecha={moment().format('DD-MM-YYYY hh:mm A')}
              />
            ),
            botones: [
              {
                nombre: 'Imprimir',
                click: imprimirCierre,
              },
            ],
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
    await obtenerInformacionCierre()
  }

  const imprimirCierre = async () => {
    if (contextoCierreCaja.cierreCaja.urlArchivo) {
      window
        .open(
          contextoCierreCaja.cierreCaja.urlArchivo.replace(/['"]+/g, ''),
          '_blank'
        )
        .focus()
    } else {
      let cierreCajaServicio = (
        await ObtenerCierreCaja(contextoAplicacion.sede.sedeId)
      ).data
      if (cierreCajaServicio.urlArchivo) {
        window
          .open(cierreCajaServicio.urlArchivo.replace(/['"]+/g, ''), '_blank')
          .focus()
      }
    }
    contextoAplicacion.setModal({abierto: false, botones: []})
  }

  const columnasCierreDeCaja = React.useMemo(() => [
    {
      Header: () => <EncabezadoFondo titulo={'MEDIO DE PAGO'} />,
      accessor: 'medioDePago',
      className:
        'w-1/2 flex text-center text-cendiatra text-xl font-bold justify-center items-center content-center h-16 border border-cendiatra',
      headerStyle: 'w-1/2 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: () => <EncabezadoFondo titulo={'VALOR'} />,
      accessor: 'valor',
      className:
        'w-1/2 flex text-center text-gray-400 text-xl font-bold justify-center items-center content-center h-16 border border-cendiatra',
      headerStyle: 'w-1/2 bg-cendiatra-verde-7 text-white h-14',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={'text-center bg-transparent font-bold pointer-events-none'}
          valor={row.row.original.valor}
        />
      ),
    },
  ])

  let fechaActual = moment().format('DD / MM / YYYY')
  let horaActual = moment().format('hh:mm A')

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITULOS_RECEPCIONISTA.CIERRE_DE_CAJA}
    >
      <div className="flex gap-4 my-5 ml-16.7%">
        <div className="flex flex-col">
          <span className="w-full flex justify-start items-center text-cendiatra font-bold text-14px">
            Fecha cierre caja
          </span>
          <span className="w-full flex justify-start items-center text-cendiatra-verde-3 font-bold text-17px">
            {fechaActual}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="w-full flex justify-start items-center text-cendiatra font-bold text-14px">
            Hora cierre caja
          </span>
          <span className="w-full flex justify-start items-center text-cendiatra-verde-3 font-bold text-17px">
            {horaActual}
          </span>
        </div>
      </div>

      <TablaCierreDeCaja
        columns={columnasCierreDeCaja}
        data={listaCajaCierre}
      />

      <div className="w-full flex flex-wrap justify-center items-center my-5 ">
        <div className="w-full ml-16.7%">
          <span className="text-red-500 w-full sm:text-sm">
            {errors.observaciones?.type === 'pattern' &&
              TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
          </span>
        </div>

        <Controller
          name="observaciones"
          control={control}
          rules={{
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-8/12'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'observaciones'
                )
                  ? `appearance-none rounded relative block w-full  h-48  p-1.5 border border-red-600 ${
                      contextoCierreCaja.inputsHabilitados
                        ? ''
                        : 'bg-cendiatra-gris-placeholder'
                    } ${
                      contextoCierreCaja.inputsHabilitados
                        ? 'text-cendiatra-gris-3'
                        : 'text-white'
                    } rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm`
                  : `appearance-none rounded relative block w-full  h-48  p-1.5 border border-cendiatra ${
                      contextoCierreCaja.inputsHabilitados
                        ? ''
                        : 'bg-cendiatra-gris-placeholder'
                    } ${
                      contextoCierreCaja.inputsHabilitados
                        ? 'text-cendiatra-gris-3'
                        : 'text-white'
                    } rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
              }
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'Observaciones'}
              valor={value}
              deshabilitado={!contextoCierreCaja.inputsHabilitados}
            />
          )}
        />
      </div>

      <div className="w-full flex flex-wrap justify-center items-center my-5">
        <Boton
          titulo="Guardar"
          funcionCLick={guardarCierre}
          habilitado={guardarHabilitado && isValid}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
          ancho={'w-32'}
        />
        <Boton
          titulo="Imprimir cierre generado"
          funcionCLick={imprimirCierre}
          habilitado={imprimirHabilitado}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
          ancho={'w-32'}
          tamanoTexto={'text-sm'}
        />
      </div>
    </ContenedorPagina>
  )
}

export default CierreDeCaja
