import React, {useContext} from 'react'
import {SubirArchivo} from '../../microservicios/Archivos'
import {ContextApplication} from '../../contexto'
import ClipImg from '../../imagenes/Clip_adjuntar.svg'

const InputCargarMultiplesArchivos = ({
  editable,
  tamanoMaximoArchivo,
  nombreLabel,
  TamanoMaximo,
  contexto,
  archivoRequerido,
  archivosAceptaos = 'image/png, image/webp, image/jpeg, image/svg, image/jpg, application/pdf',
}) => {
  const contextoAplicacion = useContext(ContextApplication)
  console.log(editable)
  const cargarArchivo = async (e) => {
    for (const archivo of e.target.files) {
      const tamanoArchivo = archivo.size / 1024 / 1024 // Conversion en  MB
      if (tamanoArchivo > tamanoMaximoArchivo) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: `El tamaño maximo para los archivos es de ${TamanoMaximo}`,
          contenido: '',
          botones: [
            {
              nombre: 'Regresar',
              click: manejaCerrarModal,
            },
          ],
        })
        return
      } else {
        var reader = new FileReader()
        reader.readAsDataURL(archivo)
        reader.onload = async () => {
          let formData = new FormData()
          formData.append('Archivo', archivo, archivo.name)
          if (
            contexto.arregloArchivos.find((x) => {
              return x.nombreArchivo === archivo.name
            })
          ) {
            contextoAplicacion.setModal({
              abierto: true,
              titulo: 'No esta permitido cargar archivos con el mismo nombre',
              contenido: '',
              botones: [
                {
                  nombre: 'Regresar',
                  click: manejaCerrarModal,
                },
              ],
            })
            return
          }

          const urlArchivo = await new Promise((resolve, reject) => {
            SubirArchivo(archivo.name, formData)
              .then((res) => {
                return resolve(res?.data ?? '')
              })

              .catch((error) => {
                console.log(error)
                return resolve('')
              })
          })
          contexto.setArregloArchivos((prev) => [
            ...prev,
            {
              nombreArchivo: archivo.name,
              urlArchivo: urlArchivo,
            },
          ])
        }
      }
    }
  }

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const limpiarArchivo = (nombreArchivo) => {
    let nuevaLista = contexto.arregloArchivos.filter((x) => {
      return x.nombreArchivo !== nombreArchivo
    })
    contexto.setArregloArchivos(nuevaLista)
  }

  return (
    <div className="w-full flex justify-center items-center">
      <span className="w-full pr-4 file-uploader">
        <label className="block text-sm font-medium text-cendiatra">
          {nombreLabel}
        </label>
        <input
          type="file"
          onChange={(e) => cargarArchivo(e)}
          disabled={!editable}
          className="hidden"
          accept={archivosAceptaos}
          multiple
          id={'upload'}
        />
        <label
          className={
            !editable
              ? 'flex gap-3 text-white placeholder-white items-center justify-center mt-2 py-1 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none bg-white rounded-2xl border border-cendiatra bg-cendiatra-gris-placeholder'
              : archivoRequerido && contexto.arregloArchivos.length === 0
              ? 'flex gap-3 items-center justify-center mt-2 py-1 px-5 mr-2 mb-2 text-sm font-medium text-gray-400 focus:outline-none bg-white rounded-2xl border border-red-600 hover:bg-gray-100 cursor-pointer'
              : 'flex gap-3 items-center justify-center mt-2 py-1 px-5 mr-2 mb-2 text-sm font-medium text-gray-400 focus:outline-none bg-white rounded-2xl border border-cendiatra hover:bg-gray-100 cursor-pointer'
          }
          for={'upload'}
        >
          <img className="h-6" src={ClipImg} alt="borrador" />
          Seleccionar Archivo
        </label>
        <span className="text-cendiatra flex items-center">
          {' '}
          <div className="flex justify-center items-center ">
            <div className="w-full flex justify-start items-center flex-wrap">
              {contexto.arregloArchivos.map((archivo) => (
                <div className="w-full flex justify-start items-center flex-nowrap my-1">
                  <span className="truncate w-auto mr-5">
                    {archivo.nombreArchivo}
                  </span>

                  <strong
                    onClick={() => limpiarArchivo(archivo.nombreArchivo)}
                    className="text-xl align-center cursor-pointer text-red-600 border-2 rounded-full border-red-600 justify-center p-2.5 items-center flex w-5 h-6 z-10"
                  >
                    <span className="text-sm font-bold"> x </span>
                  </strong>
                </div>
              ))}
            </div>
          </div>
        </span>
      </span>
    </div>
  )
}

export default InputCargarMultiplesArchivos
