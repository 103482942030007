import React, {useState, useEffect, useRef, useContext} from 'react'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import {ContenedorDesplegable} from '../Contenedores'
import moment from 'moment'

import ComponenteConjuntoDeFiltros from '../Filtros/ComponenteConjuntoDeFiltros'
import {
  agregarFormatoFecha,
  aplicarFormatoMoneda,
} from '../../utilidades/funcionesComunes'
import {AgregarServiciosACache} from '../../cache/servicios-cache'
import {
  ObtenerPagosFacturadosYPendientes,
  AnularPago,
} from '../../microservicios'
import {BotonSimple} from '../Botones'
import clsx from 'clsx'
import {ObtenerReportePagosFacturadosYPendientes} from '../../microservicios/ConfirmacionPagos'
import {encabezadosReporteContabilidad} from '../CSV/EncabezadosArchivos'
import {CSVLink} from 'react-csv'
import {FORMATOS_STANDARD} from '../../constantes/formatos'
import {useMsal} from '@azure/msal-react'
import {ContextApplication} from '../../contexto'
import {ROLES_APP} from '../../constantes'
import {useHistory} from 'react-router-dom'
import {ESTADOS_FACTURACION_PAGOS} from '../../constantes/tiposFacturacion'

const TablaContabilidad = () => {
  useEffect(() => {
    const obtenerDatosServicios = () => {
      AgregarServiciosACache()
    }
    obtenerDatosServicios()
  }, [])

  const contextoAplicacion = useContext(ContextApplication)

  const {accounts} = useMsal()

  const history = useHistory()

  const rolUsuario = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const rolesBotonAnular = [ROLES_APP.AUXILIAR_CONTABLE, ROLES_APP.TESORERO]

  const filtrosIniciales = {
    tamano: 10,
    pagina: 1,
    filtros: {
      razonSocial: '',
      nit: '',
      sede: '',
      fechaPago: '',
      fechaConfirmacion: '',
      nombreUsuario: '',
      numeroCuenta: '',
      valor: '',
      numeroFactura: '',
      referencia: '',
      segundaReferencia: '',
      descripcion: '',
    },
  }

  const [dataFiltros, setDataFiltros] = useState(filtrosIniciales)
  const [actualizarInformacion, setActualizarInformacion] = useState(true)
  const [datosTabla, setDatosTabla] = useState({datos: [], datosPaginacion: {}})
  const [reporteCsv, setReporteCsv] = useState({
    data: [],
    headers: [],
    filename: '',
  })
  const csvRef = useRef(null)

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      try {
        const respuesta = await ObtenerPagosFacturadosYPendientes(dataFiltros)
        if (respuesta.status === 200) {
          setDatosTabla({
            datos:
              respuesta?.data?.datos.map((fila) => {
                return {
                  ...fila,
                  facturaSeleccionada: false,
                }
              }) || [],
            datosPaginacion: respuesta?.data?.paginacion || [],
          })
        } else {
          setDatosTabla({datos: [], datosPaginacion: {}})
        }
      } catch (error) {
        console.log(error)
      }
    }
    obtenerDatosIniciales()
  }, [actualizarInformacion])

  const filtrarInformacion = () => {
    setActualizarInformacion(!actualizarInformacion)
  }

  const limpiarFiltros = () => {
    setDataFiltros(filtrosIniciales)
    setActualizarInformacion(!actualizarInformacion)
  }

  const ordenarPorFecha = (datos = []) => {
    return datos.sort((a, b) => {
      const fechaA = new Date(a.fechaConfirmacion)
      const fechaB = new Date(b.fechaConfirmacion)
      return fechaA - fechaB
    })
  }

  const generarReporteContabilidad = async () => {
    try {
      const respuesta = await ObtenerReportePagosFacturadosYPendientes(
        dataFiltros.filtros
      )

      if (respuesta.status === 200) {
        setReporteCsv({
          data: respuesta.data.map((dato) => ({
            ...dato,
            fechaConfirmacion: moment(dato.fechaConfirmacion).isValid()
              ? moment(dato.fechaConfirmacion).format(
                  FORMATOS_STANDARD.DD_MM_YYYY_HH_mm_SEP_BARRA_DIAGONAL
                )
              : dato.fechaConfirmacion,
            fechaPago: moment(dato.fecha).isValid()
              ? moment(dato.fecha).format(
                  FORMATOS_STANDARD.DD_MM_YYYY_SEP_BARRA_DIAGONAL
                )
              : dato.fecha,
            estadoFacturacion: ESTADOS_FACTURACION_PAGOS[dato.estadoId],
            facturacion: dato?.servicios?.length
              ? dato?.correo ?? ''
              : 'FACTURA GENERADA EN SEDE',
            numeroFactura: dato?.numeroFactura ?? 'N/A',
          })),
          filename: `REPORTE_CONTABILIDAD_${moment().format(
            FORMATOS_STANDARD.DDMMYYYYHHmmss
          )}.csv`,
          headers: encabezadosReporteContabilidad,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      accessor: 'id',
      className: 'w-0 hidden',
      headerStyle: '',
      disableFilters: true,
      cellStyles: '',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Cuenta'} />,
      accessor: 'cuenta',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => <span>{row?.original?.cuenta?.numeroCuenta}</span>,
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Fecha de Pago'} paddingFondoClaro={''} />
      ),
      accessor: 'fechaPago',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <span>
          {moment(row?.original?.fecha).isValid()
            ? moment(row?.original?.fecha).format(
                FORMATOS_STANDARD.DD_MM_YYYY_SEP_BARRA_DIAGONAL
              )
            : ''}
        </span>
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Fecha confirmación'} paddingFondoClaro={''} />
      ),
      accessor: 'fechaConfirmacion',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <span>
          {moment(row?.original?.fechaConfirmacion).isValid()
            ? moment(row?.original?.fechaConfirmacion).format(
                FORMATOS_STANDARD.DD_MM_YYYY_HH_mm_SEP_BARRA_DIAGONAL
              )
            : ''}
        </span>
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Usuario confirmación'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'nombreUsuario',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Valor'} paddingFondoClaro={''} />,
      accessor: 'valor',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <span>{aplicarFormatoMoneda(row?.original?.valor)}</span>
      ),
    },
    {
      Header: () => <EncabezadoFondo titulo={'Sede'} paddingFondoClaro={''} />,
      accessor: 'nombreSede',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'# Factura'} paddingFondoClaro={''} />
      ),
      accessor: 'numeroFactura',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <span
          className={clsx(
            'p-2',
            !row?.original?.numeroFactura &&
              'border rounded-xl border-yellow-600'
          )}
        >
          {row?.original?.numeroFactura ?? 'Pendiente por facturar'}
        </span>
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Razón social - nombre'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'razonSocial',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Documento'} paddingFondoClaro={''} />
      ),
      accessor: 'nit',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => <span>{row?.original?.nit}</span>,
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Ref. Doc o ID Transacción'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'referencia',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => <span>{row?.original?.referencia}</span>,
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Referencia 2'} paddingFondoClaro={''} />
      ),
      accessor: 'segundaReferencia',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => <span>{row?.original?.segundaReferencia}</span>,
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Descripción'} paddingFondoClaro={''} />
      ),
      accessor: 'descripcion',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => <span>{row?.original?.descripcion}</span>,
    },
    {
      id: 'button',
      className: 'w-7.69%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => (
        <div className="w-full flex justify-center items-center">
          <BotonSimple
            texto={'Anular'}
            estilosBoton={clsx(
              'w-4/5 p-1 h-10 rounded-lg bg-cover bg-no-repeat text-white text-lg border border-white',
              {
                'bg-cendiatra-gris-2 pointer-events-none':
                  !rolesBotonAnular.includes(rolUsuario),
                'bg-btnBg': rolesBotonAnular.includes(rolUsuario),
              }
            )}
            funcion={() => confirmarAnularFacturacion(row?.original?.id)}
            tipoDeBoton={'button'}
          />
        </div>
      ),
    },
  ]

  const anularFacturacion = async (id) => {
    try {
      const respuesta = await AnularPago({pagoId: id})

      if (respuesta.status === 200) {
        history.go(0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const confirmarAnularFacturacion = (id) => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <div className="text-center w-full">
          <h3 className="text-cendiatra-rojo-1 mb-6 text-3xl font-bold normal-case">
            ¿Está seguro de anular la confirmación del pago?
          </h3>
        </div>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => anularFacturacion(id),
        },
        {
          nombre: 'No',
          click: () => manejarCerrarModal(),
        },
      ],
    })
  }

  const filtrosExternos = [
    {
      id: 1,
      estilos: 'w-10.125%',
      titulo: 'Cuenta bancaria',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroCuenta',
      formato: 'input',
      valor: dataFiltros.filtros.numeroCuenta,
    },
    {
      id: 3,
      estilos: 'w-10.125%',
      titulo: 'Fecha de Pago',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fechaPago',
      formato: 'input',
      valor: dataFiltros.filtros.fechaPago,
    },
    {
      id: 4,
      estilos: 'w-10.125%',
      titulo: 'Fecha confirmación',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fechaConfirmacion',
      formato: 'input',
      valor: dataFiltros.filtros.fechaConfirmacion,
    },
    {
      id: 5,
      estilos: 'w-10.125%',
      titulo: 'Usuario confirmación',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nombreUsuario',
      formato: 'input',
      valor: dataFiltros.filtros.nombreUsuario,
    },
    {
      id: 6,
      estilos: 'w-10.125%',
      titulo: 'Valor',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'valor',
      formato: 'input',
      valor: dataFiltros.filtros.valor,
    },
    {
      id: 7,
      estilos: 'w-10.125%',
      titulo: 'Sede',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'sede',
      formato: 'input',
      valor: dataFiltros.filtros.sede,
    },
    {
      id: 8,
      estilos: 'w-10.125%',
      titulo: '# Factura',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroFactura',
      formato: 'input',
      valor: dataFiltros.filtros.numeroFactura,
    },
    {
      id: 9,
      estilos: 'w-10.125%',
      titulo: 'Razón social - nombre',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'razonSocial',
      formato: 'input',
      valor: dataFiltros.filtros.razonSocial,
    },
    {
      id: 10,
      estilos: 'w-10.125%',
      titulo: 'Documento',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nit',
      formato: 'input',
      valor: dataFiltros.filtros.nit,
    },
    {
      id: 11,
      estilos: 'w-10.125%',
      titulo: 'Ref. Doc ID Transacción',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'referencia',
      formato: 'input',
      valor: dataFiltros.filtros.referencia,
    },
    {
      id: 12,
      estilos: 'w-10.125%',
      titulo: 'Referencia 2',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'segundaReferencia',
      formato: 'input',
      valor: dataFiltros.filtros.segundaReferencia,
    },
    {
      id: 13,
      estilos: 'w-10.125%',
      titulo: 'Descripción',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'descripcion',
      formato: 'input',
      valor: dataFiltros.filtros.descripcion,
    },
  ]

  const manejarCambioEnFiltro = (e) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: agregarFormatoFecha(e),
        },
      }
    })
  }
  const manejarCambioDePagina = (numeroPagina) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: numeroPagina,
      }
    })
    setActualizarInformacion(!actualizarInformacion)
  }

  useEffect(() => {
    if (reporteCsv.data.length > 0) {
      csvRef.current.link.click()
      setReporteCsv({data: [], filename: '', headers: []})
    }
  }, [reporteCsv.data])

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <div className="w-full flex justify-start items-center">
        <ComponenteConjuntoDeFiltros
          filtrosPorCrear={filtrosExternos}
          funcionBoton={filtrarInformacion}
        />
      </div>
      <ContenedorDesplegable
        titulo={'Servicios a facturar'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
        ocultarBotonDesplegable={true}
      >
        <div className="w-full border border-cendiatra -mt-3 border-t-0 border-b-0 h-10 -mb-7 "></div>
        <TablaConPaginacionYFiltros
          columns={columns}
          data={ordenarPorFecha(datosTabla?.datos ?? [])}
          filasPorPagina={10}
          funcionFilaSeleccionada={null}
          paginacionBackend={true}
          parametrosPaginacionConBackEnd={datosTabla?.datosPaginacion}
          maximoDePaginasparaMostrar={10}
          funcionCambiarPagina={manejarCambioDePagina}
          estilosTablaDatos={
            'border border-cendiatra -mt-1 border-t-0 pb-2 px-2 rounded-xl rounded-t-none'
          }
        />
        <div className="w-full flex justify-end items-center -mt-32 mr-10">
          <CSVLink {...reporteCsv} ref={csvRef} />
          <BotonSimple
            texto={'Descargar reporte'}
            estilosBoton={
              'w-50 p-1 h-10 rounded-lg bg-cover bg-btnBg bg-no-repeat text-white text-lg border border-white'
            }
            funcion={generarReporteContabilidad}
            tipoDeBoton={'button'}
          />
          <BotonSimple
            texto={'Limpiar filtros'}
            estilosBoton={
              'w-32 h-10 rounded-lg bg-cover bg-btnBg bg-no-repeat text-white text-lg border border-white'
            }
            funcion={limpiarFiltros}
            tipoDeBoton={'button'}
          />
        </div>
      </ContenedorDesplegable>
    </div>
  )
}

export default TablaContabilidad
