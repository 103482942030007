import React, {useContext, useState} from 'react'
import Boton from '../Botones/Boton'
import {useForm} from 'react-hook-form'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {
  ObtenerEstadoFacturacion,
  ObtenerFacturasPorNombreOIdentificacion,
} from '../../microservicios/Facturacion'
import {ObtenerSedePorId} from '../../cache/servicios-cache/Sedes'
import {ContextoGestionFacturacion} from '../../contexto'
import {ObtenerEstadoFacturaPorId} from '../../cache/servicios-cache/EstadoFactura'

const FiltroSeleccionarFactura = () => {
  const {handleSubmit} = useForm({
    mode: 'onChange',
  })

  const contextoGestionFacturacion = useContext(ContextoGestionFacturacion)
  const [busquedaFactura, setBusquedaFactura] = useState({
    id: 0,
    nombre: '',
    filtro: '',
  })

  const [listaFacturas, setListaFacturas] = useState([])

  const onSubmit = async () => {
    ObtenerEstadoFacturacion(busquedaFactura.id)
      .then(async (res) => {
        let listaFacturas = res.data
        if (listaFacturas) {
          contextoGestionFacturacion.setFacturaFiltrada(
            await Promise.all(
              listaFacturas.map(async (factura) => ({
                estadoFacturaId:
                  (
                    await ObtenerEstadoFacturaPorId(factura.estadoFacturaId)
                  )?.Nombre ?? '',
                facturaDisponible: factura.facturaDisponible,
                fechaEmisionFactura: factura.fechaEmisionFactura,
                identificacion: factura.identificacion,
                nombreCliente: factura.nombreCliente,
                numeroFactura: factura.numeroFactura,
                sedeId: (await ObtenerSedePorId(factura.sedeId))?.Nombre ?? '',
                valorTotal: factura.valorTotal,
                id: factura.id,
              }))
            )
          )
        } else {
          contextoGestionFacturacion.setFacturaFiltrada([])
        }
      })
      .catch((error) => {
        console.log(error)
        contextoGestionFacturacion.setFacturaFiltrada([])
      })
  }

  const filtrarPorFactura = async (e) => {
    setBusquedaFactura(e.target.value)
    if (e.target.value.length >= 3) {
      let facturas = await ObtenerFacturasPorNombreOIdentificacion(
        e.target.value
      )
      let data = facturas.data

      const agruparObjetos = data.reduce((acc, curr) => {
        if (acc[curr.identificacion]) {
          return acc
        }
        acc[curr.identificacion] = curr
        return acc
      }, {})

      const nuevoArray = Object.values(agruparObjetos)

      mapListaFacturas(nuevoArray)
    } else {
      setListaFacturas([])
    }
  }

  const mapListaFacturas = (facturas) => {
    if (facturas) {
      setListaFacturas(
        facturas.map((factura) => ({
          id: factura.identificacion,
          nombre: factura.nombre,
          filtro: `${factura.identificacion}-${factura.nombre}`,
        }))
      )
    }
  }

  const seleccionarFactura = (e) => {
    setBusquedaFactura(e)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`flex justify-center items-center flex-wrap`}>
        <CampoFiltrarEspañol
          titulo={'Identificación o nombre de cliente'}
          estilosPersonalizados={'w-22% mr-5 mb-7'}
          estilosInput={'border-cendiatra'}
          placeholder={'DESCRIPCIÓN (autocompletar)'}
          tipo={'text'}
          valorDelCampoFiltro={busquedaFactura.filtro}
          desactivarOtroFiltro={''}
          informacionFiltrada={listaFacturas}
          handleChange={filtrarPorFactura}
          handleOptionChange={seleccionarFactura}
        />

        <Boton
          titulo="Buscar"
          habilitado={true}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
          alto={'h-9'}
        />

        <Boton
          titulo="Limpiar"
          habilitado={true}
          funcionCLick={() => {
            contextoGestionFacturacion.setFacturaFiltrada([])
            setBusquedaFactura({
              id: 0,
              nombre: '',
              filtro: '',
            })
            setListaFacturas([])
          }}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          alto={'h-9'}
        />
      </div>
    </form>
  )
}

export default FiltroSeleccionarFactura
