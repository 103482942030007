import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
  AgregarServiciosACache,
  ObtenerTodosLosServicios,
} from '../../../cache/servicios-cache/Servicios'
import InformacionAsignacionPlantillaAServicios from '../../../componentes/Administrador/MotorDePlantillas/InformacionAsignacionPlantillaAServicios'
import BotonConImagen from '../../../componentes/BotonConImagen'

import {ContextApplication} from '../../../contexto'
import {ReactComponent as AsignarPlantillaAServicioIcono} from '../../../imagenes/asignarPlantillaAServicioBoton.svg'
import DesvincularHabilitado from '../../../imagenes/Desvincular Habilitado.svg'
import TablaConPaginacionYFiltros from '../../../componentes/Tablas/TablaConPaginacionYFiltros'
import {
  DesvincularServicioPlantilla,
  ObtenerPlantillasConServiciosAsignados,
} from '../../../microservicios/Plantilla'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../../constantes/textoVisual'

const GestionAsignacionPlantillaAServicio = () => {
  const history = useHistory()
  const [listaPlantillas, setListaPlantillas] = useState([])
  const [servicios, setServicios] = useState([])
  const [informacionPreparada, setInformacionPreparada] = useState([])
  const contextoAplicacion = useContext(ContextApplication)

  const obtenerPlantillas = async () => {
    await ObtenerPlantillasConServiciosAsignados().then((respuesta) => {
      if (respuesta.status === 200) {
        setListaPlantillas(respuesta.data)
      }
    })
  }

  const obtenerServicios = async () => {
    await AgregarServiciosACache().then(() => {
      ObtenerTodosLosServicios().then((respuesta) => {
        setServicios(respuesta)
      })
    })
  }

  const obtenerPlantillasYServicios = useCallback(async () => {
    await obtenerPlantillas()
    await obtenerServicios()
  }, [])

  useEffect(() => {
    obtenerPlantillasYServicios()
  }, [obtenerPlantillasYServicios])

  useEffect(() => {
    const info = listaPlantillas.map((item) => ({
      plantillaServicioId: item.plantillaServicioId,
      nombre: item.nombre,
      nombreServicio:
        servicios.find((servicio) => servicio.Id === item.servicioId)?.Nombre ??
        '',
    }))
    setInformacionPreparada(info)
  }, [listaPlantillas, servicios])

  const cerrarAlerta = () => {
    contextoAplicacion.setModal({
      abierto: false,
      botones: [],
      contenido: '',
    })
  }

  const desvincularPlantilla = async (plantillaServicioId) => {
    await DesvincularServicioPlantilla(plantillaServicioId).then(
      (respuesta) => {
        if (respuesta.status === 200) {
          cerrarAlerta()
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'Se ha desvinculado la plantilla correctamente',
            contenido: '',
            botones: [
              {
                nombre: 'Finalizar',
                click: () => {
                  cerrarAlerta()
                  obtenerPlantillas()
                  obtenerServicios()
                  history.push('/')
                },
              },
              {
                nombre: 'Regresar',
                click: () => {
                  cerrarAlerta()
                  obtenerPlantillas()
                  obtenerServicios()
                },
              },
            ],
          })
        }
      }
    )
  }

  const desvincular = (
    plantillaServicioId,
    nombrePlantilla,
    nombreServicio
  ) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo:
        '¿Está seguro de desvincular la siguiente plantilla del servicio?',
      contenido: (
        <InformacionAsignacionPlantillaAServicios
          plantilla={nombrePlantilla}
          servicios={[nombreServicio]}
          asignacion={false}
        />
      ),
      botones: [
        {
          nombre: 'No',
          click: cerrarAlerta,
        },
        {
          nombre: 'Si',
          click: () => desvincularPlantilla(plantillaServicioId),
        },
      ],
    })
  }

  const columnasTabla = useMemo(
    () => [
      {
        Header: 'Id',
        headerStyle:
          'bg-cendiatra-verde-7 text-white h-20 rounded-l-lg pl-1 uppercase ',
        className: 'w-1/6',
        accessor: 'plantillaServicioId',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center uppercase',
      },
      {
        Header: 'PLANTILLAS',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 uppercase  ',
        className: 'w-2/6',
        accessor: 'nombre',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center uppercase',
      },
      {
        Header: 'SERVICIOS',
        accessor: 'nombreServicio',
        className: 'w-2/6',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 pl-1 uppercase ',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center uppercase',
      },
      {
        Header: 'Desvincular',
        accessor: 'desvincular',
        headerStyle:
          'bg-cendiatra-verde-7 text-white h-20  rounded-r-lg pl-1 uppercase ',
        className: 'w-1/6',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center uppercase',
        disableFilters: true,
        Cell: ({row: {original}}) => (
          <BotonConImagen
            imagen={DesvincularHabilitado}
            funcionAEjecutar={() =>
              desvincular(
                original.plantillaServicioId,
                original.nombre,
                original.nombreServicio
              )
            }
            estilosImagen={'w-10 h-10 content-start justify-start'}
            estilosContenedor={' h-10 content-start flex-col pb-5'}
          />
        ),
      },
    ],
    []
  )

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.TITILO_ADMINISTRADOR_GATO
          .GESTION_DE_ASIGNACION_DE_PLANTILLA_SERVICIO
      }
    >
      <div className="w-full flex flex-wrap justify-center items-center">
        <div className="w-11/12 py-5">
          <button
            className={
              'w-2/12 h-auto flex flex-wrap justify-center items-center text-lg text-white'
            }
            onClick={() => history.push('/asignar-plantilla-a-servicio')}
          >
            <AsignarPlantillaAServicioIcono className="w-full" />
          </button>
        </div>
        <div className="w-11/12 flex flex-nowrap justify-center items-center">
          <TablaConPaginacionYFiltros
            columns={columnasTabla}
            data={informacionPreparada}
            paginacion={true}
            filasPorPagina={6}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default GestionAsignacionPlantillaAServicio
