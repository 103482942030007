import React from 'react'

export const CeldaConEventoClick = ({texto, manejarClick}) => {
  return (
    <div className="w-full flex justify-center items-center">
      <span
        className="uppercase text-cendiatra-verde-2 cursor-pointer underline"
        onClick={manejarClick}
      >
        {texto}
      </span>
    </div>
  )
}

export default CeldaConEventoClick