import {TextoSemaforo} from '../semaforos/TextoSemaforo'

export const CeldaSemaforizada = ({
  alineacion = 'justify-center',
  colorSemaforo = 'bg-cendiatra-verde-3',
  estilosTexto,
  mostrarSemaforo = true,
  valorMostrar,
}) => {
  return (
    <div className="flex w-full">
      <TextoSemaforo
        alineacion={alineacion}
        colorSemaforo={colorSemaforo}
        estilosTexto={estilosTexto}
        mostrarSemaforo={mostrarSemaforo}
        texto={valorMostrar}
      />
    </div>
  )
}
