import {createContext, useState} from 'react'

export const ContextoResolucionFirmaElectronica = createContext('')

export const ResolucionFirmaElectronicaConContexto = ({children}) => {
  const [formulario, setFormulario] = useState({})
  const [formularioActualTieneErrores, setformularioActualTieneErrores] =
    useState(true)
  const [actualizar, setActualizar] = useState(false)
  const [resolucionId, setResolucionId] = useState()

  return (
    <ContextoResolucionFirmaElectronica.Provider
      value={{
        formulario,
        setFormulario,
        formularioActualTieneErrores,
        setformularioActualTieneErrores,
        actualizar,
        setActualizar,
        resolucionId,
        setResolucionId,
      }}
    >
      {children}
    </ContextoResolucionFirmaElectronica.Provider>
  )
}
