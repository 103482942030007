import React from 'react'

const EncabezadoPestanaExternos = ({
  contexto,
  numeroPaginacion,
  titulo,
  deshabilitarValidaciones,
}) => {
  const irAlTab = (numeroPaginaNuevo) => {
    if (
      deshabilitarValidaciones ||
      contexto.numeroPagina >= numeroPaginaNuevo
    ) {
      contexto.setNumeroPagina(numeroPaginaNuevo)
    } else if (
      !contexto.formularioActualTieneErrores &&
      (!contexto?.errores || contexto?.errores?.length == 0) &&
      numeroPaginaNuevo == contexto.numeroPagina + 1
    ) {
      contexto.setNumeroPagina(numeroPaginaNuevo)
    }
  }

  return (
    <div
      className={`w-3/12 flex justify-center  border-r border-l border-t rounded-t-lg  ${
        contexto.numeroPagina === numeroPaginacion
          ? ' border-opacity-50 border-cendiatra bg-white'
          : 'border-none bg-none'
      }`}
      onClick={() => irAlTab(numeroPaginacion)}
    >
      <div className="w-10/12 flex justify-center items-center my-5 flex-wrap">
        <p className={`text-lg text-cendiatra text-center`}>{titulo}</p>
        <div className="w-full h-2  bg-cendiatra"></div>
      </div>
    </div>
  )
}

export default EncabezadoPestanaExternos
