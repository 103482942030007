import React from 'react'
import icono_preferencial from '../../imagenes/icono_preferencial.svg'
import icono_vip from '../../imagenes/icono_vip.svg'
import icono_general from '../../imagenes/icono_general.svg'

const CeldaTipoTurno = ({tipoTurno}) => {
  const obtenerImagenPorTipoTurno = () => {
    switch (tipoTurno) {
      case 'PREFERENCIAL':
        return icono_preferencial
      case 'VIP':
        return icono_vip
      case 'GENERAL':
        return icono_general
      default:
        return icono_general
    }
  }

  return (
    <div className="flex w-full flex-wrap text-center justify-center">
      <div
        className={
          'flex w-10/12 text-center justify-center text-cendiatra-verde-8 font-bold'
        }
      >
        <span>{tipoTurno}</span>
      </div>
      <img
        className={'w-1/12 flex text-white text-lg z-50 '}
        src={obtenerImagenPorTipoTurno()}
        alt="tipoTurno"
      ></img>
    </div>
  )
}

export default CeldaTipoTurno
