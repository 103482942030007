import React, {useEffect, useState, useMemo} from 'react'
import removeButtonImg from '../../../imagenes/removeButton.png'
import expandir from '../../../imagenes/Expandible/flechaDesplegableExpandirBlanco.svg'
import BotonConImagen from '../../BotonConImagen'
import {CeldaEditable} from '../../CeldaEditable'
import {HeaderTablaPrecios, TablaPrecios} from '../../Tarifario'
import {ReplicarPrecios, ListaDesplegableTipoGuardado} from '../../inputs'
import {UseObtenerRolValido} from '../../../utilidades/funcionesComunes'

export default function FilaPrecios({
  contexto,
  idFila,
  nombre,
  alias,
  precios,
  actualizarPrecios,
  eliminarFila,
  actualizarAlias,
  posicion,
  tipoElemento,
  esPortafolio = false,
}) {
  const [numeroAliadosSeleccionados, setnumeroAliadosSeleccionados] =
    useState(0)

  const rolValido = UseObtenerRolValido()

  const claseBloqueo = rolValido ? 'pointer-events-none' : ''

  let listaColumnas = useMemo(() => {
    return [
      {
        Header: HeaderTablaPrecios(''),
        accessor: 'nombre',
        className: 'w-3/12',
      },
      {
        Header: HeaderTablaPrecios('PRECIO ACTUAL'),
        accessor: 'precio',
        Cell: CeldaEditable,
        className: 'w-3/12',
        deshabilitado: contexto.modoEdicion ? true : false,
        contexto: contexto.contextoTarifario,
        esPortafolio: esPortafolio,
      },
      {
        Header: HeaderTablaPrecios('PRECIO NUEVO'),
        accessor: 'nuevoPrecio',
        Cell: CeldaEditable,
        className: 'w-4/12',
        deshabilitado: contexto.modoEdicion ? false : true,
        esPortafolio: esPortafolio,
      },
    ]
  }, [contexto.listaServicios, contexto.listaPaquetes])

  const numeroPreciosPendientes = precios.filter(function (elementoPrecio) {
    const validarModo = contexto.modoEdicion
      ? elementoPrecio.precio == 0 && elementoPrecio.nuevoPrecio == 0
      : elementoPrecio?.precio == 0
    return validarModo
  }).length

  const gruposPrecios = precios.reduce((grupoPrecio, elemento) => {
    const {grupo} = elemento
    grupoPrecio[grupo] = grupoPrecio[grupo] ?? []
    grupoPrecio[grupo].push(elemento)
    return grupoPrecio
  }, {})

  const mostrarOEsconderPrecios = () => {
    contexto.setFilaSeleccionada(
      contexto.filaSeleccionada == posicion ? -1 : posicion
    )
  }

  const actualizarPrecio = (rowIndex, columnId, value, titulo) => {
    actualizarPrecios(gruposPrecios[`${titulo}`][rowIndex].id, value, idFila)
  }
  const validarServicioOPaqueteNuevo = (id, esServicio) => {
    const arrayInicial = esServicio
      ? contexto.serviciosIniciales
      : contexto.paquetesIniciales
    return Boolean(arrayInicial.find((elemento) => elemento === id))
  }

  const validarColumnaAEditar = (paqueteOServicioId, datos, esServicio) => {
    const sedeOAliadoNuevo = validacionGeneral(datos)
    if (sedeOAliadoNuevo) {
      const nombrePropiedad =
        contexto.modoEdicion &&
        validarServicioOPaqueteNuevo(paqueteOServicioId, esServicio)
          ? 'nuevoPrecio'
          : 'precio'
      return nombrePropiedad
    }
    return 'precio'
  }

  const validacionGeneral = (data) => {
    const resultado = Boolean(
      contexto?.[
        data?.grupo === 'ALIADOS' ? 'aliadosIniciales' : 'sedesIniciales'
      ].find((x) =>
        data?.grupo === 'ALIADOS'
          ? x === data?.aliadoId ?? 0
          : x === data?.sedeId ?? 0
      )
    )
    return resultado
  }

  const actualizarTodosLosPrecios = (
    valor,
    idFila,
    nombrePropiedad,
    tipoElemento
  ) => {
    if (tipoElemento === 'Servicio') {
      contexto.setListaServicios(
        contexto.listaServicios.map((servicio) => {
          if (servicio.servicio_id === idFila) {
            return {
              ...servicio,
              [nombrePropiedad]: servicio[nombrePropiedad].map(
                (elementoPrecio) => {
                  return {
                    ...elementoPrecio,
                    [validarColumnaAEditar(
                      servicio.servicio_id,
                      elementoPrecio,
                      true
                    )]: valor,
                  }
                }
              ),
            }
          }
          return servicio
        })
      )
    } else if (tipoElemento === 'Paquete') {
      contexto.setListaPaquetes(
        contexto.listaPaquetes.map((paquete) => {
          if (paquete.paquete_id === idFila) {
            return {
              ...paquete,
              [nombrePropiedad]: paquete[nombrePropiedad].map(
                (elementoPrecio) => {
                  return {
                    ...elementoPrecio,
                    [validarColumnaAEditar(
                      paquete.paquete_id,
                      elementoPrecio,
                      false
                    )]: valor,
                  }
                }
              ),
            }
          }
          return paquete
        })
      )
    }
  }

  const obtenerNumeroDeAliadosSeleccionados = () => {
    const sedesSeleccionadas = contexto.aliadosSeleccionados
    const sedeActiva = sedesSeleccionadas.filter((sede) => sede.Activo)
    setnumeroAliadosSeleccionados(sedeActiva.length)
  }

  useEffect(() => {
    obtenerNumeroDeAliadosSeleccionados()
  }, [contexto.aliadosSeleccionados])

  const avisoSeguroDeReplicacion = () => {
    contexto.setSeguroDeCambio(true)
  }

  const opcionesPrecioAPartirDeMañana = [
    {
      Id: 1,
      Nombre: 'Inmediato',
    },
    {
      Id: 2,
      Nombre: 'A partir de mañana',
    },
  ]

  return (
    <div className="w-full flex flex-wrap">
      <div
        className={
          contexto.filaSeleccionada == posicion
            ? 'flex justify-center items-start pt-5'
            : 'flex justify-center items-center '
        }
      >
        <BotonConImagen
          ancho={'w-10' + ' ' + claseBloqueo}
          imagen={removeButtonImg}
          textoAlternativo={'Boton eliminar'}
          funcionAEjecutar={() => {
            eliminarFila(idFila)
          }}
          medio={true}
        />
      </div>
      <div className="w-11/12 border border-cendiatra rounded-xl my-2">
        <div className="w-full flex bg-cendiatra rounded-xl h-auto min-h-14 items-center py-1 relative z-10 pointer-events-auto">
          <div className="w-4/12 text-center text-md bg-gris my-2 rounded-xl mx-5 h-auto min-h-10 items-center justify-center flex uppercase">
            <span className="text-white">{nombre}</span>
          </div>
          <div className={`w-4/12  ${claseBloqueo}`}>
            {alias !== undefined && (
              <div>
                <label className="text-md text-white">Alias</label>
                <input
                  className="appearance-none  px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 mx-2"
                  type="text"
                  value={alias}
                  onChange={(e) => actualizarAlias(idFila, e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="w-4/12 text-center text-md flex flex-nowrap items-center">
            <span className="text-white">Precios pendientes por asignar: </span>{' '}
            <span
              className={`bg-gris rounded-xl mx-5 p-2 ${
                numeroPreciosPendientes > 0 ? 'text-red-500' : ''
              }`}
            >
              {numeroPreciosPendientes}
            </span>
          </div>
          <BotonConImagen
            ancho={'w-10'}
            imagen={expandir}
            textoAlternativo={'Expandir'}
            funcionAEjecutar={() => {
              mostrarOEsconderPrecios()
            }}
            medio={true}
            rotacion={contexto.filaSeleccionada == posicion ? '180' : '0'}
          />
        </div>
        {contexto.filaSeleccionada === posicion ? (
          <div
            className={`w-full flex justify-center items-center flex-wrap ${claseBloqueo}`}
          >
            {Object.keys(gruposPrecios)
              .reduce((gruposPrec, elem) => {
                if (elem === 'ALIADOS') return [...gruposPrec, elem]
                return [elem, ...gruposPrec]
              }, [])
              .map((grupo, posicion, row) => (
                <>
                  <div className="w-full flex text-center">
                    <label className="w-full text-cendiatra text-lg my-5 ">
                      {grupo}
                    </label>
                  </div>

                  <div
                    className={`w-full flex justify-start items-center my-10 ${claseBloqueo}`}
                  >
                    <div className="w-1/4 mx-20">
                      <ReplicarPrecios
                        posicion={posicion}
                        row={row}
                        idFila={idFila}
                        tipoElemento={tipoElemento}
                        precioAliados={numeroAliadosSeleccionados}
                        actualizarTodosLosPrecios={
                          !Boolean(contexto.portafolioSeleccionadoId)
                            ? actualizarTodosLosPrecios
                            : avisoSeguroDeReplicacion
                        }
                      />
                    </div>
                    {(posicion === 0 && contexto.modoEdicion) ||
                    (posicion === row.length - 1 &&
                      numeroAliadosSeleccionados > 0 &&
                      contexto.modoEdicion) ? (
                      <ListaDesplegableTipoGuardado
                        estilosContenedor={'w-1/4'}
                        nombre={`listaTipoGuardado${posicion}`}
                        idFila={idFila}
                        tipoElemento={tipoElemento}
                        listaOpciones={opcionesPrecioAPartirDeMañana}
                        contexto={contexto}
                        grupo={grupo}
                      />
                    ) : null}
                  </div>

                  <TablaPrecios
                    columns={
                      contexto.modoEdicion
                        ? listaColumnas
                        : listaColumnas.filter(
                            (elemento) => elemento.accessor !== 'nuevoPrecio'
                          )
                    }
                    titulo={grupo}
                    data={gruposPrecios[`${grupo}`]}
                    actualizarDatos={actualizarPrecio}
                  />
                </>
              ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}
