import {getRestApi} from './ApiAxios'

export const ObtenerResultadosAliados = async (
  numeroDocumento,
  nombre,
  apellidos
) => {
  let url = 'atencion-function/ObtenerResultadosAliados?'
  if (numeroDocumento) {
    url = url + 'numeroDocumento=' + numeroDocumento + '&'
  }
  if (nombre) {
    url = url + 'nombres=' + nombre + '&'
  }
  if (apellidos) {
    url = url + 'apellidos=' + apellidos + '&'
  }
  const apiReq = await getRestApi()
  return apiReq.get(url)
}

export const ObtenerAliado = async (id) => {
  const apiReq = await getRestApi()
  return apiReq.get('atencion-function/ObtenerResultadoAliadoPorId?id=' + id)
}

export const ObtenerAtencionAliadoPorFecha = async (
  pacienteId,
  fechaAtencion,
  aliadoId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get('atencion-function/ObtenerAtencionAliadoPorFecha', {
    params: {
      pacienteId,
      fechaAtencion,
      aliadoId,
    },
  })
}
