import React from 'react'

const ImagenEstandar = ({
  estilosContenedor,
  imagen,
  estilosImagen,
  textoAlternativo,
}) => {
  return (
    <>
      <div className={`${estilosContenedor}`}>
        <img
          className={`${estilosImagen}`}
          src={imagen}
          alt={textoAlternativo}
        />
      </div>
    </>
  )
}

export default ImagenEstandar
