import React, {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import {useHistory, useLocation} from 'react-router-dom'
import {ContextoJefeDeSede, ContextApplication} from '../../contexto'
import {RUTAS_APP, LISTADO_GENEROS, TEXTO_VISUAL} from '../../constantes'
import {
  FachadaObtenerSolicitudPorId,
  RespoderSolicitudModficacion,
} from '../../microservicios'
import {
  ObtenerTipoDocumentoPorId,
  ObtenerArlPorId,
  ObtenerEpsPorId,
  ObtenerServicioPorId,
} from '../../cache/servicios-cache'
import usuarioImagen from '../../imagenes/usuarioImagen.svg'
import IconoVer from '../../imagenes/iconoResultadosVerde.svg'
import {CuadroInformacionUsuario} from '../MostrarInformacion'
import {CeldaConBotonDinamica} from '../Celdas'
import {BotonOpcion, BotonSimple} from '../Botones'
import {AreaDeTexto} from '../inputs'
import {ContenedorPagina} from '../paginas/ContenedorPagina'
import TablaDinamica from '../TablaDinamica'

const FormularioModificarExamenesAtendidos = () => {
  const contextoJefeDeSede = useContext(ContextoJefeDeSede)

  const {state} = useLocation()
  const history = useHistory()
  const contextoApp = useContext(ContextApplication)

  const [foto, setFoto] = useState('')
  const [examenes, setExamenes] = useState('')
  const [usuarioId, setusuarioId] = useState('')
  const [atencionId, setAtencionId] = useState('')
  const [fechaAtencion, setFechaAtencion] = useState('')
  const [horaAtencion, setHoraAtencion] = useState('')
  const [motivo, setMotivo] = useState('')

  const cerrarModal = () => {
    contextoApp.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const guardarExamenes = () => {
    const servicioAprobados =
      contextoJefeDeSede.informacionExamenesParaModificar.map((item) => ({
        servicioAtencionId: item.id,
        editable: item.habilitado,
      }))

    const payload = {
      id: state,
      comentarios: contextoJefeDeSede.comentario || '',
      servicioAprobados: servicioAprobados,
    }

    RespoderSolicitudModficacion(payload).catch((error) => console.log(error))
  }

  const irAExamen = (servicioAtencionId, servicioId) => {
    history.push('/examen', {
      atencionId: atencionId,
      usuarioId: usuarioId,
      servicioAtencionId: servicioAtencionId,
      servicioId: servicioId,
      examenes: examenes,
    })
  }

  const manejarFinalizarTransaccion = () => {
    cerrarModal()
    history.push(RUTAS_APP.JEFE_DE_SEDE.HABILITAR_MODIFICAR_EXAMENES_ATENDIDOS)
    contextoJefeDeSede.setModificarAtencion(true)
  }

  const manejarGuardarExamenes = () => {
    cerrarModal()
    contextoJefeDeSede.setModificarAtencion(true)

    guardarExamenes()

    contextoApp.setModal({
      abierto: true,
      titulo: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full my-2">EXÁMENES HABILITADOS</span>
          <span className="w-full">PARA EDICIÓN</span>
        </div>
      ),
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full my-2">
            RECUERDE LLAMAR AL SOLICITANTE PARA INFORMARLE
          </span>
          <span className="w-full">LA AUTORIZACIÓN</span>
        </div>
      ),

      botones: [
        {
          nombre: 'Finalizar',
          click: manejarFinalizarTransaccion,
        },
      ],
    })
  }

  const manejarGuardarExamenesSinHabilitar = () => {
    cerrarModal()
    contextoJefeDeSede.setModificarAtencion(true)

    guardarExamenes()

    contextoApp.setModal({
      abierto: true,
      titulo: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full my-2">EXÁMENES NO HABILITADOS</span>
          <span className="w-full">PARA EDICIÓN</span>
        </div>
      ),
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full my-2">
            RECUERDE LLAMAR AL SOLICITANTE PARA INFORMARLE
          </span>
          <span className="w-full">LA AUTORIZACIÓN</span>
        </div>
      ),

      botones: [
        {
          nombre: 'Finalizar',
          click: manejarFinalizarTransaccion,
        },
      ],
    })
  }

  const manejarAbrirModalConfirmacion = () => {
    if (
      contextoJefeDeSede.informacionExamenesParaModificar.find(
        (examen) => examen.habilitado
      )
    ) {
      contextoApp.setModal({
        abierto: true,
        titulo: (
          <div className="w-full flex justify-center items-center flex-wrap">
            <span className="w-full my-2">DESEA HABILITAR</span>
            <span className="w-full">LA EDICIÓN DE ESTOS EXÁMENES</span>
          </div>
        ),
        contenido: contextoJefeDeSede.informacionExamenesParaModificar
          .filter((examen) => examen.habilitado)
          .map((elemento) => (
            <span className="w-full flex justify-center items-center my-2 text-cendiatra">
              - {elemento.nombreExamen?.toUpperCase()}
            </span>
          )),

        botones: [
          {
            nombre: 'NO',
            click: cerrarModal,
          },
          {
            nombre: 'SI',
            click: manejarGuardarExamenes,
          },
        ],
      })
    } else {
      manejarGuardarExamenesSinHabilitar()
    }
  }

  useEffect(() => {
    const obtenerDatosUsuario = async () => {
      try {
        const {data: informacionPaciente} = await FachadaObtenerSolicitudPorId(
          state
        )
        const {datosUsuario, servicios, atencionId, motivo} =
          informacionPaciente || {}

        setExamenes(examenes)

        setMotivo(motivo)

        const getDataTablaServicios = async () => {
          const nombreServicio = async (serviceId) => {
            const service = await ObtenerServicioPorId(serviceId)
            return service?.Nombre.toUpperCase()
          }

          const formatDate = (date) => {
            return date ? moment(date).format('DD/MM/YYYY') : ''
          }

          const dataTablaServicios = await Promise.all(
            servicios.map(async (servicio) => {
              const {
                servicioAtencionId: id,
                servicioId,
                fechaCreacion,
                fechaActualizacion,
                nombreMedico,
              } = servicio

              return {
                id,
                servicioId,
                nombreExamen: await nombreServicio(servicioId),
                fechaYHoraCreacion: formatDate(fechaCreacion),
                fechaYHoraCierre: formatDate(fechaActualizacion),
                nombreMedico: nombreMedico.toUpperCase() || '',
                habilitado: false,
              }
            })
          )

          return dataTablaServicios
        }

        getDataTablaServicios().then((dataTablaServicios) => {
          contextoJefeDeSede.setInformacionExamenesParaModificar(
            dataTablaServicios
          )
          contextoJefeDeSede.setComentario('')
        })

        setFoto(datosUsuario?.urlFoto)
        setusuarioId(datosUsuario?.id)
        setAtencionId(atencionId)
        setFechaAtencion(
          moment(datosUsuario?.fechaAtencion).format('DD/MM/YYYY')
        )
        setHoraAtencion(moment(datosUsuario?.fechaAtencion).format('hh:mm'))

        contextoJefeDeSede.setInformacionUsuario({
          urlFotoUsuario: datosUsuario?.urlFoto ?? '',
          datosUsuario: [
            {
              titulo: 'Tipo De Documento',
              contenido: (
                await ObtenerTipoDocumentoPorId(
                  parseInt(`${datosUsuario?.tipoDocumentoId ?? ''}`)
                )
              ).Nombre,
            },
            {
              titulo: 'Nombre',
              contenido: `${datosUsuario?.nombre ?? ''}`,
            },
            {
              titulo: 'Género',
              contenido: LISTADO_GENEROS[datosUsuario?.sexoId] ?? '',
            },
            {
              titulo: 'EPS',
              contenido: (
                await ObtenerEpsPorId(parseInt(`${datosUsuario?.epsId ?? 0}`))
              )?.Nombre,
            },
            {
              titulo: 'No. Documento',
              contenido: datosUsuario?.documento ?? '',
            },
            {
              titulo: 'Apellidos',
              contenido: `${datosUsuario?.apellidos ?? ''}`,
            },
            {
              titulo: 'Edad',
              contenido: `${moment().diff(
                datosUsuario?.fechaNacimiento,
                'years'
              )} años`,
            },
            {
              titulo: 'ARL',
              contenido: (
                await ObtenerArlPorId(parseInt(`${datosUsuario?.arlId ?? 0}`))
              )?.Nombre,
            },
          ],
        })
      } catch (error) {
        console.log(error)
      }
    }

    obtenerDatosUsuario()
  }, [])

  const manejarHabilitarExamen = (idExamen) => {
    contextoJefeDeSede.setInformacionExamenesParaModificar(
      contextoJefeDeSede.informacionExamenesParaModificar.map((examen) => {
        if (examen.id === idExamen) {
          return {
            ...examen,
            habilitado: !examen.habilitado,
          }
        } else {
          return examen
        }
      })
    )
  }

  const columnasTabla = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: 'servicioId',
      accessor: 'servicioId',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: 'Examen',
      accessor: 'nombreExamen',
      className:
        'w-3/12 flex text-center justify-center items-center content-center rounded-l-lg  h-16',
      headerStyle: 'w-3/12 bg-cendiatra-verde-7 text-white  rounded-l-lg h-14',
    },
    {
      Header: 'Hora creación',
      accessor: 'fechaYHoraCreacion',
      className:
        'w-2/12 flex text-center justify-center items-center content-center h-16',
      headerStyle: 'w-2/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: 'Hora cierre',
      accessor: 'fechaYHoraCierre',
      className:
        'w-2/12 flex text-center justify-center items-center content-center h-16',
      headerStyle: 'w-2/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: 'Médico',
      accessor: 'nombreMedico',
      className:
        'w-3/12 flex text-center justify-center items-center content-center  h-16',
      headerStyle: 'w-3/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: 'Habilitar examen',
      accessor: 'habilitado',
      className:
        'w-1/12 flex text-center justify-center items-center content-center h-16 ',
      headerStyle: 'w-1/12 bg-cendiatra-verde-7 text-white h-14',
      Cell: (row) => (
        <BotonOpcion
          id={row.row.original.id}
          titulo={''}
          estaActivo={row.row.original.habilitado}
          funcionClick={manejarHabilitarExamen}
        />
      ),
    },
    {
      Header: 'Ver plantilla',
      accessor: 'botonExamen',
      className:
        'w-1/12 flex text-center justify-center items-center content-center h-16  rounded-r-lg',
      headerStyle: 'w-1/12 bg-cendiatra-verde-7 text-white h-14 rounded-r-lg',
      Cell: (row) => (
        <CeldaConBotonDinamica
          habilitado={true}
          imagenHabilitado={IconoVer}
          funcion={() =>
            irAExamen(row.row.original.id, row.row.original.servicioId)
          }
          imagen={IconoVer}
          estilosImagen={'h-10 w-10'}
        />
      ),
    },
  ])

  const manejarCambioValorAreaTexto = (comentario) => {
    contextoJefeDeSede.setComentario(comentario)
  }

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.TITULO_JEFE_DE_SEDE.HABILITAR_MODIFICACION_DE_EXAMENES
      }
      navegacion={RUTAS_APP.JEFE_DE_SEDE.HABILITAR_MODIFICAR_EXAMENES_ATENDIDOS}
    >
      <div className="w-full flex justify-center items-center flex-wrap">
        <div className="w-full flex justify-center items-center my-5">
          <CuadroInformacionUsuario
            estilosContenedor={
              'w-full h-280 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 shadow-2xl '
            }
            titulo={'DATOS PACIENTE'}
            tituloImagen={'Fecha y hora de atención'}
            descripcionPrimeraLinea={fechaAtencion ? fechaAtencion : ''}
            descripcionSegundaLinea={horaAtencion ? horaAtencion : ''}
            datos={contextoJefeDeSede.informacionUsuario.datosUsuario}
            deshabilitarTiempo={true}
            urlFoto={foto !== '' ? foto : usuarioImagen}
          />
        </div>
        <div className="w-82% flex justify-center items-center my-10">
          <TablaDinamica
            columns={columnasTabla}
            data={contextoJefeDeSede.informacionExamenesParaModificar}
          />
        </div>
        <div className="w-full flex justify-evenly items-center flex-wrap ">
          <div className="w-7/12 flex justify-center items-center">
            <AreaDeTexto
              titulo={'Motivo'}
              estilosContenedor={'w-full '}
              estilosAreaDeTexto={
                'h-20 my-3 rounded  w-full  p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white pointer-events-none rounded-lg focus:outline-none  sm:text-sm h-28'
              }
              placeholder={'DESCRIPCIÓN'}
              valor={motivo}
              cambioValor={null}
            />
          </div>
          <div className="w-4/12 flex justify-center items-center">
            <AreaDeTexto
              titulo={'Comentarios*'}
              estilosContenedor={'w-full '}
              estilosAreaDeTexto={`h-20 my-3 rounded  w-full  p-1.5 border ${
                Boolean(
                  !contextoJefeDeSede.comentario &&
                    !contextoJefeDeSede.informacionExamenesParaModificar?.find(
                      (examen) => examen.habilitado
                    )
                )
                  ? 'border-cendiatra-rojo-1'
                  : 'border-cendiatra'
              } text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm h-28`}
              placeholder={'DESCRIPCIÓN'}
              valor={contextoJefeDeSede.comentario}
              cambioValor={(e) => manejarCambioValorAreaTexto(e.target.value)}
            />
          </div>
          <div className="w-full flex justify-center items-center my-10">
            <BotonSimple
              texto={'Guardar'}
              estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
                Boolean(
                  contextoJefeDeSede.comentario ||
                    contextoJefeDeSede.informacionExamenesParaModificar?.find(
                      (examen) => examen.habilitado
                    )
                )
                  ? 'bg-btnBg'
                  : 'bg-cendiatra-gris-placeholder'
              }  '}`}
              funcion={manejarAbrirModalConfirmacion}
              deshabilitado={Boolean(
                !contextoJefeDeSede.comentario &&
                  !contextoJefeDeSede.informacionExamenesParaModificar?.find(
                    (examen) => examen.habilitado
                  )
              )}
            />
          </div>
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default FormularioModificarExamenesAtendidos
