import React, {useEffect, useState} from 'react'
import TablaDinamica from '../../../TablaDinamica'
import {aplicarFormatoMoneda} from '../../../../utilidades'
import {TIPOS_FACTURACION} from '../../../../constantes'

const MediosPago = ({medioPago, tipoFacturacion}) => {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let nuevoTotal = 0

    medioPago?.forEach((element) => {
      nuevoTotal += element.valor
    })

    setTotal(nuevoTotal)
  }, [medioPago])

  const columns = React.useMemo(() => [
    {
      Header: 'MEDIO DE PAGO',
      accessor: 'medioDePago',
      className: 'w-2/12',
      Cell: (row) => {
        return <div>{row.row.original.medioDePago}</div>
      },
    },
    {
      Header: '',
      accessor: 'empty1',
      className: 'w-3/12',
    },
    {
      Header: '',
      accessor: 'empty2',
      className: 'w-20%',
    },
    {
      Header: '',
      accessor: 'empty3',
      className: 'w-2/12',
    },
    {
      Header: 'VALOR',
      accessor: 'valor',
      className: 'w-2/12',
      Cell: (row) => (
        <span>{aplicarFormatoMoneda(row.row.original.valor)}</span>
      ),
    },
  ])

  return (
    <>
      {tipoFacturacion !== TIPOS_FACTURACION.CREDITO ? (
        <div className="w-full mb-4">
          <div className="w-full flex justify-between items-center my-6">
            <div className="w-full flex-col">
              <TablaDinamica
                estilosHeader="bg-white text-cendiatra border-cendiatra border rounded-xl font-bold text-lg justify-around"
                data={medioPago}
                columns={columns}
              >
                <div className="grid grid-cols-5 w-full">
                  <div class="col-start-5 col-span-1 flex items-center justify-center">
                    <div className="flex gap-10 mr-16 text-cendiatra-gris-3 font-bold text-xl">
                      <span>TOTAL</span>
                      <span>{aplicarFormatoMoneda(total)}</span>
                    </div>
                  </div>
                </div>
              </TablaDinamica>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default MediosPago
