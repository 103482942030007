import React from 'react'
import {useTable, usePagination, useRowSelect} from 'react-table'
import FilaPaquete from './FilaPaquete'

const TablaOrdenServicio = ({titulo, data, columns}) => {
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, rows} =
    useTable(
      {
        columns,
        data,
        initialState: {pageIndex: 0},
      },
      usePagination,
      useRowSelect
    )

  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <label className="w-full text-start  text-17px text-cendiatra mt-10 mb-2">
        {titulo}
      </label>
      <table className="w-full" {...getTableProps()}>
        <thead
          className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 text-white h-14 rounded-10px"
          style={{lineHeight: '16px'}}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              className="w-full flex justify-evenly items-center h-full p-3px"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: `${column.className} h-full flex flex-wrap justify-center items-center content-center  m-3px rounded-9px font-light`,
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="w-full " {...getTableBodyProps()}>
          {rows.map((row, index) => {
            {
              if (row.original.tipo == 'paquete') {
                return <FilaPaquete fila={row.original} posicion={index} />
              } else {
                prepareRow(row)
                return (
                  <tr
                    className="w-full flex border-b-2 border-b-gris justify-evenly items-center text-center text-17px text-cendiatra-gris-1  my-5 uppercase"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={{backgroundColor: 'white', maxWidth: '500px'}}
                          {...cell.getCellProps({
                            className: cell.column.className,
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              }
            }
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaOrdenServicio
