import React, {
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useState,
} from 'react'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink, useHistory} from 'react-router-dom'
import TablaTurnosPendientes from '../../componentes/recepcionista/TablaTurnosPendientes'
import Boton from '../../componentes/Botones/Boton'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import EncabezadoTextoValor from '../../componentes/Tablas/EncabezadoTextoValor'
import llamarPacientes from '../../imagenes/menuRecepcion/llamarPacientes.svg'
import CeldaConTooltip from '../../componentes/CeldaConTooltip'
import CeldaTipoTurno from '../../componentes/Tablas/CeldaTipoTurno'
import {ContextApplication, ContextoLlamarPaciente} from '../../contexto'
import {ObtenerTipoTurnoPorId} from '../../cache/servicios-cache/TipoTurno'
import moment from 'moment'
import BotonTitilar from '../../componentes/Botones/BotonTitilar'
import {
  AtenderTurno,
  CancelarTurno,
  LlamarTurno,
  ObtenerTurnosPorSedeId,
  ReiniciarTurno,
} from '../../microservicios/Turno'
import {useMsal} from '@azure/msal-react'
import {tiposTurno, estadosTurno} from '../../constantes/estadosTurno'
import {useConexionSignalR} from '../../hooks/signalR/useConexionSignalR'
import CeldaCronometro from '../../componentes/Tablas/CeldaCronometro'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {eliminarDuplicadosPorPropiedad} from '../../utilidades'

const LlamarPaciente = () => {
  const history = useHistory()

  const contextoAplicacion = useContext(ContextApplication)
  const contextoLlamarPaciente = useContext(ContextoLlamarPaciente)

  const [turnoParaEliminar, setTurnoParaEliminar] = useState({})

  useEffect(() => {
    contextoAplicacion.setMostrarModalMenu(false)
  }, [])

  const calcularTiempoEspera = (fechaCreacion) => {
    var duracion = moment.duration(moment().diff(moment(fechaCreacion)))
    return (
      String(duracion.hours()).padStart(2, '0') +
      ':' +
      String(duracion.minutes()).padStart(2, '0') +
      ':' +
      String(duracion.seconds()).padStart(2, '0')
    )
  }

  const {accounts} = useMsal()

  const ordenarListaTurnos = (listaTurnos) => {
    listaTurnos.sort((a, b) => {
      const fechaComparacion = moment(a.creado).isBefore(moment(b.creado))
        ? -1
        : 1
      if (fechaComparacion !== 0) {
        return fechaComparacion
      }
    })

    listaTurnos.sort((a, b) => {
      if (
        a.tipoTurnoId === tiposTurno.GENERAL &&
        b.tipoTurnoId === tiposTurno.PREFERENCIAL
      ) {
        return 1
      }
      if (
        a.tipoTurnoId == tiposTurno.GENERAL &&
        b.tipoTurnoId === tiposTurno.VIP
      ) {
        return 1
      }
      if (
        a.tipoTurnoId === tiposTurno.PREFERENCIAL &&
        b.tipoTurnoId === tiposTurno.GENERAL
      ) {
        return -1
      }
      if (
        a.tipoTurnoId === tiposTurno.PREFERENCIAL &&
        b.tipoTurnoId === tiposTurno.VIP
      ) {
        return -1
      }
      if (
        a.tipoTurnoId === tiposTurno.VIP &&
        b.tipoTurnoId === tiposTurno.GENERAL
      ) {
        return -1
      }
      if (
        a.tipoTurnoId === tiposTurno.VIP &&
        b.tipoTurnoId === tiposTurno.PREFERENCIAL
      ) {
        return 1
      }
      return 0
    })
    return listaTurnos
  }

  const obtenerInformacionTurnos = useCallback(async () => {
    const turnosActivos = (
      await ObtenerTurnosPorSedeId(contextoAplicacion.sede.sedeId)
    ).data
    const turnosOrdenados = ordenarListaTurnos(turnosActivos)
    contextoLlamarPaciente.setListaTurnos(
      await Promise.all(
        turnosOrdenados.map(async (turno) => ({
          idTurno: turno.id,
          estadoTurno: turno.estadoTurnoId,
          noTurno: turno.numeroTurno,
          correo: turno.correoRecepcionista,
          usuarioId: turno.usuarioId,
          tipoTurno: (await ObtenerTipoTurnoPorId(turno.tipoTurnoId)).Nombre,
          tipoTurnoId: turno.tipoTurnoId,
          nombresYApellidos: turno.nombresYApellidos.toUpperCase(),
          tiempoEspera: calcularTiempoEspera(turno.creado),
          fechaCreacion: turno.creado,
        }))
      )
    )

    contextoLlamarPaciente.setFilasLlamadas(
      turnosActivos
        .filter((x) => x.estadoTurnoId === estadosTurno.LLAMANDO)
        .map(function (a) {
          return a.id
        })
    )
  }, [])

  useEffect(() => {
    obtenerInformacionTurnos().catch(console.error)
  }, [obtenerInformacionTurnos])

  const manejarTurnoAgregadoSede = async (message) => {
    let mensajeTurnoAgregado = JSON.parse(message)

    const informacionTurno =
      (await ObtenerTipoTurnoPorId(mensajeTurnoAgregado?.TipoTurnoId)) ?? ''

    let turnoAAgregar = {
      idTurno: mensajeTurnoAgregado.Id,
      estadoTurno: mensajeTurnoAgregado.EstadoTurnoId,
      noTurno: mensajeTurnoAgregado.Turno,
      usuarioId: mensajeTurnoAgregado.UsuarioId,
      tipoTurno: informacionTurno?.Nombre ?? '',
      tipoTurnoId: mensajeTurnoAgregado?.TipoTurnoId,
      correo: informacionTurno?.correoRecepcionista ?? '',
      nombresYApellidos: mensajeTurnoAgregado.NombresYApellidos.toUpperCase(),
      tiempoEspera: calcularTiempoEspera(mensajeTurnoAgregado.FechaCreacion),
      fechaCreacion: mensajeTurnoAgregado.FechaCreacion,
    }

    contextoLlamarPaciente.setListaTurnos((prevTurnos) => {
      return ordenarListaTurnos(
        eliminarDuplicadosPorPropiedad(
          [...prevTurnos, turnoAAgregar],
          'idTurno'
        )
      )
    })
  }

  const manejarTurnoEnAtencionSede = (message) => {
    let mensajeTurnoAtendido = JSON.parse(message)
    contextoLlamarPaciente.setListaTurnos(
      contextoLlamarPaciente.listaTurnos.map((turno) => {
        if (turno.idTurno === mensajeTurnoAtendido.turnoId) {
          return {
            ...turno,
            estadoTurno: estadosTurno.ATENDIENDO,
            tiempoEspera: calcularTiempoEspera(turno.fechaCreacion),
          }
        } else {
          return turno
        }
      })
    )
  }

  useEffect(() => {
    if (turnoParaEliminar?.turnoId) {
      contextoLlamarPaciente.setListaTurnos(
        contextoLlamarPaciente.listaTurnos
          .filter((value) => {
            return value.idTurno !== turnoParaEliminar.turnoId
          })
          .map((turno) => {
            return {
              ...turno,
              tiempoEspera: calcularTiempoEspera(turno.fechaCreacion),
            }
          })
      )
    }
  }, [turnoParaEliminar])

  const manejarTurnoAtendidoOCanceladoSede = (message) => {
    let mensajeTurnoAtendido = JSON.parse(message)
    setTurnoParaEliminar(mensajeTurnoAtendido)
  }

  const manejarTurnoLlamadoSede = (message) => {
    let mensajeTurnoLlamado = JSON.parse(message)
    if (
      !contextoLlamarPaciente.filasLlamadas.includes(
        mensajeTurnoLlamado.turnoId
      )
    ) {
      contextoLlamarPaciente.setFilasLlamadas([
        ...contextoLlamarPaciente.filasLlamadas,
        mensajeTurnoLlamado.turnoId,
      ])
    }
  }
  const listaMensajesYFunciones = [
    {
      nombreConexion: 'turnoAgregadoSede',
      funcion: manejarTurnoAgregadoSede,
    },
    {
      nombreConexion: 'turnoEnAtencionSede',
      funcion: manejarTurnoEnAtencionSede,
    },
    {
      nombreConexion: 'turnoRetiradoSede',
      funcion: manejarTurnoAtendidoOCanceladoSede,
    },
    {
      nombreConexion: 'turnoLlamadoSede',
      funcion: manejarTurnoLlamadoSede,
    },
  ]

  const [conexion, agregarSubscripciones, eliminarSubscripciones] =
    useConexionSignalR(listaMensajesYFunciones, contextoAplicacion.sede.sedeId)

  useEffect(() => {
    if (conexion) {
      eliminarSubscripciones(listaMensajesYFunciones)
      agregarSubscripciones(listaMensajesYFunciones)
    }
  }, [contextoLlamarPaciente.filasLlamadas, contextoLlamarPaciente.listaTurnos])

  const manejarLlamarPaciente = async (id) => {
    contextoLlamarPaciente.setFilasLlamadas([
      ...contextoLlamarPaciente.filasLlamadas,
      id,
    ])
    await LlamarTurno(id, contextoAplicacion.sede.sedeId)
  }

  const manejarLlamadoFinalizacion = async (id) => {
    if (contextoLlamarPaciente.filasLlamadas.includes(id)) {
      await ReiniciarTurno(id, contextoAplicacion.sede.sedeId)
      contextoLlamarPaciente.setFilasLlamadas(
        contextoLlamarPaciente.filasLlamadas.filter((value) => {
          return value !== id
        })
      )
    }
  }

  const manejarClickAtender = async (filaTurno) => {
    await AtenderTurno(filaTurno.idTurno, contextoAplicacion.sede.sedeId)
    contextoLlamarPaciente.setListaTurnos(
      contextoLlamarPaciente.listaTurnos.map((turno) => {
        if (turno.idTurno === filaTurno.idTurno) {
          return {
            ...turno,
            estadoTurno: estadosTurno.ATENDIENDO,
          }
        } else {
          return turno
        }
      })
    )
    contextoLlamarPaciente.setTurnoIdAtendiendo(filaTurno.idTurno)
    history.push('/registrarpaciente', {state: filaTurno})
  }

  const manejarClickCancelar = (filaTurno) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '¿ESTÁ SEGURO DE CANCELAR EL TURNO ' + filaTurno.noTurno + '?',
      botones: [
        {
          nombre: 'Si',
          click: () => {
            manejarCancelarTurno(filaTurno)
          },
        },
        {
          nombre: 'No',
          click: manejarCerrarModal,
        },
      ],
    })
  }

  const manejarCancelarTurno = async (filaTurno) => {
    await CancelarTurno(filaTurno.idTurno, contextoAplicacion.sede.sedeId)
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'EL TURNO ' + filaTurno.noTurno + ' HA SIDO CANCELADO',
      botones: [
        {
          nombre: 'Regresar',
          click: manejarCerrarModal,
        },
      ],
    })
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
  }

  const habilitarBotonCancelar = (row) => {
    if (contextoLlamarPaciente.turnoIdAtendiendo !== undefined) {
      return row.idTurno === contextoLlamarPaciente.turnoIdAtendiendo
    } else {
      if (
        (row.estadoTurno === estadosTurno.ATENDIENDO &&
          row.correo === accounts[0].username) ||
        row.estadoTurno === estadosTurno.LLAMANDO
      ) {
        return true
      } else {
        return row.estadoTurno === estadosTurno.ACTIVO
      }
    }
  }

  let listaColumnas = useMemo(
    () => [
      {
        Header: (row) => (
          <EncabezadoTextoValor
            tituloLinea1={'PENDIENTES'}
            tituloLinea2={'POR REGISTRAR'}
            valor={contextoLlamarPaciente.listaTurnos.length}
          />
        ),
        accessor: 'pendientes',
        className: 'w-8/12',
        columns: [
          {
            accessor: 'usuarioId',
            className: 'hidden',
          },
          {
            accessor: 'idTurno',
            className: 'hidden',
          },
          {
            Header: (row) => <EncabezadoFondo titulo={'No. de turno'} />,
            accessor: 'noTurno',
            className: 'w-1/12',
            headerStyle:
              'bg-cendiatra-verde-7 text-white ml-0.5 rounded-l-md h-14',
          },
          {
            Header: (row) => <EncabezadoFondo titulo={'Tipo de turno'} />,
            accessor: 'tipoTurno',
            className: 'w-2/12',
            headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
            Cell: (row) => (
              <CeldaTipoTurno tipoTurno={row.row.original.tipoTurno} />
            ),
          },
          {
            Header: (row) => <EncabezadoFondo titulo={'Nombres y apellidos'} />,
            accessor: 'nombresYApellidos',
            className: 'w-3/12',
            headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
            Cell: (row) => (
              <CeldaConTooltip value={row.row.original.nombresYApellidos} />
            ),
          },
          {
            Header: (row) => <EncabezadoFondo titulo={'Tiempo de espera'} />,
            accessor: 'tiempoEspera',
            className: 'w-2/12',
            headerStyle: 'bg-cendiatra-verde-7 text-white rounded-r-md h-14',
            Cell: (row) => (
              <CeldaCronometro
                fechaInicioTiempo={row.row.original.fechaCreacion}
              />
            ),
          },
        ],
      },
      {
        Header: () => <img className={'w-5% absolute'} src={llamarPacientes} />,
        accessor: 'imagen',
        className:
          'w-2/12 justify-center items-center content-center ml-16 mt-10',
        columns: [
          {
            accessor: 'llamar',
            tipo: 'boton',
            className: 'w-1/12',
            Cell: ({row}) => (
              <BotonTitilar
                titulo={'LLAMAR'}
                habilitado={
                  row.original.estadoTurno !== estadosTurno.ATENDIENDO
                }
                listaFilas={contextoLlamarPaciente.filasLlamadas}
                id={row.original.idTurno}
                funcionClick={manejarLlamarPaciente}
                funcionDejoTitilar={manejarLlamadoFinalizacion}
              />
            ),
          },
          {
            accessor: 'atender',
            tipo: 'boton',
            className: 'w-1/12',
            Cell: ({row}) => (
              <Boton
                titulo={'ATENDER'}
                habilitado={
                  row.original.estadoTurno !== estadosTurno.ATENDIENDO
                }
                funcionCLick={() => manejarClickAtender(row.original)}
                colorPorDefecto={'bg-cendiatra-gris-4 pointer-events-none'}
                colorActivo={'bg-cendiatra-semaforo-verde'}
                alto={'h-9'}
              />
            ),
          },
          {
            accessor: 'cancelar',
            tipo: 'boton',
            className: 'w-1/12 ml-1.5',
            Cell: ({row}) => (
              <>
                <Boton
                  titulo={'CANCELAR'}
                  habilitado={
                    habilitarBotonCancelar(row.original) ||
                    row.original.estadoTurno !== estadosTurno.ATENDIENDO
                  }
                  funcionCLick={() => manejarClickCancelar(row.original)}
                  colorPorDefecto={'bg-cendiatra-gris-4 pointer-events-none'}
                  colorActivo={'bg-cendiatra-semaforo-rojo'}
                  alto={'h-9'}
                />
              </>
            ),
          },
        ],
      },
    ],
    [contextoLlamarPaciente.listaTurnos, contextoLlamarPaciente.filasLlamadas]
  )

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITULOS_RECEPCIONISTA.LLAMADO_DE_PACIENTE}
    >
      <TablaTurnosPendientes
        columns={listaColumnas}
        data={contextoLlamarPaciente.listaTurnos}
      />
    </ContenedorPagina>
  )
}

export default LlamarPaciente
