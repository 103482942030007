import React, {useState, useContext, useEffect} from 'react'
import {SubirArchivo, SubirArchivoPdf} from '../../microservicios/Archivos'
import {ContextApplication} from '../../contexto'
import ClipImg from '../../imagenes/Clip_adjuntar.svg'
import ImagenPorDefecto from '../../imagenes/no_imagen_sin_fondo.webp'
import ojoIcono from '../../imagenes/ojoIcono.png'
import BotonConImagen from '../BotonConImagen'
import {contenedorCertificados} from '../../microservicios/Archivos'

const InputSubirArchivo = ({
  editable,
  tamanoMaximoArchivo,
  nombreLabel,
  TamanoMaximo,
  anchoMaximo,
  altoMaximo,
  contexto,
  nombreArchivoServicio,
  archivoRequerido,
  archivoLimpiado,
  archivoCargado,
  nombreEnlace,
  obtenerUrlArchivo,
  ocultarLeyenda,
  archivosAceptaos,
  estiloAdicional,
  habilitarVisualizarArchivo = false,
  urlArchivoServicio = '',
  contenedor = '',
  subirPdf = false,
  resetearAlRecibirNombreArchivoVacio = false,
  readOnly = false,
}) => {
  const [nombreArchivo, setNombreArchivo] = useState('')
  const [errorTamano, setErrorTamano] = useState(false)

  useEffect(() => {
    if (resetearAlRecibirNombreArchivoVacio) {
      setNombreArchivo(nombreArchivoServicio)
    }
  }, [resetearAlRecibirNombreArchivoVacio, nombreArchivoServicio])

  const ctx = useContext(ContextApplication)

  const AsginarNombreImagen = (url) => {
    if (url) {
      let partes = url.split('/')
      let nombre = partes[partes.length - 1]
      return nombre
    }
  }

  useEffect(() => {
    if (
      nombreArchivoServicio !== '' &&
      nombreArchivoServicio !== undefined &&
      nombreArchivoServicio !== null
    ) {
      ctx.setcargaImagen(true)
    } else {
      ctx.setcargaImagen(false)
    }
    if (ctx.urlCargaImagen === '' || ctx.urlCargaImagen === undefined) {
      ctx.setUrlCargaImagen(nombreArchivoServicio)
    }
  }, [nombreArchivoServicio])

  const cargarArchivo = async (e) => {
    const archivo = e.target.files[0]
    const tamañoArchivo = archivo.size / 1024 / 1024 // Conversion en  MiB
    if (tamañoArchivo > tamanoMaximoArchivo) {
      setErrorTamano(true)
      document.querySelector(
        nombreEnlace ? '#' + nombreEnlace : '#upload'
      ).value = ''
      setNombreArchivo('')
      contexto && contexto.setcambiarColorMensaje('red')
      return
    } else {
      setErrorTamano(false)
      var reader = new FileReader()
      reader.readAsDataURL(archivo)

      reader.onload = async function (e) {
        if (this.height > altoMaximo || this.width > anchoMaximo) {
          contexto && contexto.setcambiarColorMensaje('red')
          return
        }

        let formData = new FormData()

        try {
          let respuesta
          formData.append('Archivo', archivo, archivo.name)
          if (subirPdf) {
            formData.append('NombreArchivo', archivo.name)
            formData.append('Contenedor', contenedorCertificados)
            respuesta = await SubirArchivoPdf(formData)
          } else {
            respuesta = await SubirArchivo(archivo.name, formData, contenedor)
          }

          if (respuesta.status === 200) {
            setNombreArchivo(archivo.name)

            ctx.setcargaImagen(true)
            ctx.setUrlCargaImagen(respuesta.data)
            if (archivoCargado) {
              archivoCargado(archivo.name)
            }
            if (obtenerUrlArchivo) {
              obtenerUrlArchivo(archivo.name, respuesta.data)
            }
            contexto &&
              contexto.setLogoEmpresa({
                nombre: archivo.name,
                url: respuesta.data,
              })
            contexto && contexto.setcambiarColorMensaje('')
          }
        } catch (error) {}
      }
    }
  }

  const limpiarArchivo = () => {
    document.querySelector(
      nombreEnlace ? '#' + nombreEnlace : '#upload'
    ).value = ''
    setNombreArchivo('')

    ctx.setcargaImagen(false)
    setErrorTamano(false)
    archivoLimpiado()
    contexto &&
      contexto.setLogoEmpresa({
        nombre: 'Seleccionar archivo',
        url: ImagenPorDefecto,
      })
  }

  const abrirDocumentoCargado = (url) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  return (
    <div
      className={`w-56 pr-4 file-uploader ${estiloAdicional} ${
        readOnly ? 'pointer-events-none' : ''
      }`}
    >
      <label className="block text-sm font-medium text-cendiatra">
        {nombreLabel}
      </label>
      <input
        type="file"
        onChange={cargarArchivo}
        disabled={!editable}
        className="hidden"
        accept={
          archivosAceptaos
            ? archivosAceptaos
            : 'image/png, image/webp, image/jpeg, image/svg, image/jpg'
        }
        id={nombreEnlace ? nombreEnlace : 'upload'}
        readOnly={readOnly}
      />
      <label
        className={
          archivoRequerido
            ? 'flex gap-3 items-center justify-center mt-2 py-1 px-5 mr-2 mb-2 text-sm font-medium text-gray-400 focus:outline-none bg-white rounded-2xl border border-red-600 hover:bg-gray-100 cursor-pointer'
            : 'flex gap-3 items-center justify-center mt-2 py-1 px-5 mr-2 mb-2 text-sm font-medium text-gray-400 focus:outline-none bg-white rounded-2xl border border-cendiatra hover:bg-gray-100 cursor-pointer'
        }
        for={nombreEnlace ? nombreEnlace : 'upload'}
      >
        <img className="h-6" src={ClipImg} alt="borrador" />
        Seleccionar Archivo
      </label>

      <div className="text-cendiatra flex justify-center items-center w-full">
        <div className="w-full flex justify-center items-center">
          <div className="truncate w-2/6">
            {nombreArchivo === '' &&
              nombreArchivoServicio !== ImagenPorDefecto &&
              AsginarNombreImagen(nombreArchivoServicio)}
          </div>

          {editable &&
            nombreArchivo === '' &&
            nombreArchivoServicio !== '' &&
            nombreArchivoServicio !== null &&
            nombreArchivoServicio !== ImagenPorDefecto && (
              <strong
                onClick={limpiarArchivo}
                className="text-xl align-center cursor-pointer text-red-600 border-2 rounded-full border-red-600 justify-center p-2.5 items-center flex w-5 h-6 z-10"
              >
                <span className="text-sm font-bold"> x </span>
              </strong>
            )}

          {nombreArchivo && (
            <>
              <span
                className={`truncate ${
                  habilitarVisualizarArchivo ? 'w-2/6' : 'w-44'
                }`}
              >
                {nombreArchivo}
              </span>

              {editable && (
                <strong
                  onClick={limpiarArchivo}
                  className="text-xl align-center cursor-pointer text-red-600 border-2 rounded-full border-red-600 justify-center p-2.5 items-center flex w-5 h-6 z-10"
                >
                  <span className="text-sm font-bold"> x </span>
                </strong>
              )}
            </>
          )}
          {habilitarVisualizarArchivo && nombreArchivoServicio && (
            <div className=" align-center cursor-pointer flex justify-center p-2.5 items-center w-12 h-6 z-10">
              <BotonConImagen
                estilosContenedor={'w-full flex justify-center items-center'}
                imagen={ojoIcono}
                medio={true}
                funcionAEjecutar={() =>
                  abrirDocumentoCargado(urlArchivoServicio)
                }
                textoAlternativo={'agregar'}
                estilosImagen={'w-full'}
              />
            </div>
          )}
        </div>
      </div>
      {TamanoMaximo && (
        <span
          className={
            errorTamano
              ? 'text-red-600 flex items-center gap-4 font-bold text-xs w-2/3 py-2'
              : `text-cendiatra flex items-center gap-4 font-bold text-xs w-2/3 py-2 ${
                  ocultarLeyenda ? 'hidden' : ''
                }`
          }
        >
          {' '}
          Tamaño maximo {TamanoMaximo}
        </span>
      )}
    </div>
  )
}

export default InputSubirArchivo
