import React, {useEffect, useState, useCallback, useMemo} from 'react'
import {
  ObtenerFuenteDeDatosPorId,
  ObtenerFuenteDeDatosPorNombre,
  ObtenerFuentesDeDatos,
} from '../../../../cache/servicios-cache'
import {BotonOpcion} from '../../../Botones'
import {ListaDesplegable} from '../../../inputs'
import TablaDinamica from '../../../TablaDinamica'
import ListaDesplegableOpcion from './ListaDesplegableOpcion'

const ListaDesplegablePropiedades = ({
  atributos,
  actualizarAtributo,
  actualizarAtributos,
}) => {
  const [fuentesDatos, setFuentesDatos] = useState([])
  const [fuenteDatosSeleccionada, setFuenteDatosSeleccionada] = useState(
    fuentesDatos.find((f) => f.Nombre === atributos.fuenteDatos)?.Id ?? -1
  )
  const [listaOpciones, setListaOpciones] = useState([])
  const [opcionPredeterminada, setOpcionPredeterminada] = useState(
    atributos?.valorPredeterminado ?? 0
  )

  const obtenerInformacionInicial = useCallback(async () => {
    let fuentesDatosDB = await ObtenerFuentesDeDatos()
    fuentesDatosDB.unshift({Id: 0, Nombre: 'Elegir manualmente'})
    setFuentesDatos(fuentesDatosDB)
    setFuenteDatosSeleccionada(
      (await ObtenerFuenteDeDatosPorNombre(atributos?.fuenteDatos))?.Id ?? -1
    )

    if (Object.keys(atributos).length === 0) {
      actualizarFuenteDatos({target: {value: '0'}})
    } else if (atributos.fuenteDatos) {
      const fuenteDatosInformacion = fuentesDatosDB.find(
        (fuente) =>
          fuente.Nombre.toUpperCase() === atributos.fuenteDatos.toUpperCase()
      )

      setFuenteDatosSeleccionada(fuenteDatosInformacion?.Id ?? 0)
      actualizarAtributos([
        {
          nombreAtributo: 'fuenteDatos',
          valorAtributo: atributos?.fuenteDatos ?? '',
        },
        {
          nombreAtributo: 'listaOpciones',
          valorAtributo: atributos?.listaOpciones ?? [],
        },
      ])
    }
  }, [])

  useEffect(() => {
    if (Object.keys(atributos).length > 0 && fuentesDatos.length > 0) {
      const esFuenteDeDatosDb = fuentesDatos.find(
        (f) => f.Nombre === atributos.fuenteDatos
      )

      if (esFuenteDeDatosDb) {
        if (esFuenteDeDatosDb.Id > 0) {
          ;(async () => {
            const datos = await ObtenerFuenteDeDatosPorId(esFuenteDeDatosDb.Id)
            setListaOpciones(
              datos?.Opciones?.map((dato) => ({
                id: dato?.identificacion ?? 0,
                valor: dato.nombre ?? '',
              })) ?? []
            )
          })()
        } else {
          setListaOpciones(atributos?.listaOpciones ?? [])
        }
      } else {
        setListaOpciones(atributos?.listaOpciones ?? [])
      }
    }
  }, [atributos])

  useEffect(() => {
    obtenerInformacionInicial()
  }, [obtenerInformacionInicial])

  const actualizarFuenteDatos = async (e) => {
    const valor = e.target.value
    setFuenteDatosSeleccionada(valor)
    let fuenteDatos =
      valor === 0 || valor === '0'
        ? 'Elegir manualmente'
        : fuentesDatos.find((f) => f.Id === Number(valor))?.Nombre ??
          'Elegir manualmente'

    let listaOpciones = atributos.listaOpciones
      ? [...atributos.listaOpciones]
      : []

    if (
      (valor === 0 || valor === '0') &&
      (!atributos?.listaOpciones || atributos.listaOpciones.length == 0)
    ) {
      listaOpciones = [{id: 1, valor: ''}]
    } else if (valor !== 0 && valor !== '0') {
      listaOpciones = []
    }

    actualizarAtributos([
      {nombreAtributo: 'fuenteDatos', valorAtributo: fuenteDatos},
      {nombreAtributo: 'listaOpciones', valorAtributo: listaOpciones},
      {
        nombreAtributo: 'valorPredeterminado',
        valorAtributo: null,
      },
    ])
    setOpcionPredeterminada(0)
  }

  const actualizarTextoPredeterminado = (e) => {
    const valor = e.target?.value ?? '0'
    setOpcionPredeterminada(parseInt(valor ?? 0))
    actualizarAtributos([
      {
        nombreAtributo: 'valorPredeterminado',
        valorAtributo: parseInt(valor ?? 0),
      },
    ])
  }

  const actualizarValorOpcion = (valor, id) => {
    actualizarAtributo(
      'listaOpciones',
      atributos.listaOpciones.map((opcion) => {
        if (opcion.id == id) {
          return {
            ...opcion,
            valor: valor,
          }
        }
        return opcion
      })
    )
  }

  const agregarOpcion = (id) => {
    let nuevaOpcion = {
      id: id + 1,
      valor: '',
    }
    let listaOpciones = [...atributos.listaOpciones].map((opcion) => {
      if (opcion.id > id) {
        return {
          ...opcion,
          id: opcion.id + 1,
        }
      }
      return opcion
    })
    listaOpciones.splice(id, 0, nuevaOpcion)
    actualizarAtributo('listaOpciones', listaOpciones)
  }

  const eliminarOpcion = async (id) => {
    let listaOpciones = [
      ...atributos.listaOpciones.filter((x) => x.id != id),
    ].map((opcion) => {
      if (opcion.id > id) {
        return {
          ...opcion,
          id: opcion.id - 1,
        }
      }
      return opcion
    })
    if (listaOpciones.length == 0) {
      return
    }
    actualizarAtributo('listaOpciones', listaOpciones)
  }

  const columnasTablaDinamica = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'valor',
        className:
          'w-full flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
      },
    ],
    []
  )

  return (
    <div className="w-full flex flex-wrap">
      <BotonOpcion
        id={1}
        titulo={'¿Desea colocar un campo de texto largo?'}
        estaActivo={Boolean(atributos?.campoTextoLargo)}
        esHorizontal={true}
        funcionClick={() => {
          actualizarAtributo('campoTextoLargo', !atributos?.campoTextoLargo)
        }}
      />
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2">
        <label className="flex flex-wrap w-full align-middle content-center">
          FUENTE DE DATOS
        </label>
      </div>
      <ListaDesplegable
        onChange={actualizarFuenteDatos}
        estilosContenedor={'w-1/2 mt-3'}
        estilosLista={`appearance-none rounded relative block w-full  p-1.5 border ${
          fuenteDatosSeleccionada !== '' || fuenteDatosSeleccionada >= 0
            ? 'border-cendiatra'
            : 'border-cendiatra-rojo-1'
        } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
        opciones={fuentesDatos}
        valor={fuenteDatosSeleccionada}
        placeholder={'SELECCIONAR'}
        deshabilitarElegir={true}
      />
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2 pt-3">
        <label className="flex flex-wrap w-full text-left content-center align-middle">
          Elija una opción como texto predeterminado
        </label>
      </div>
      <ListaDesplegable
        onChange={actualizarTextoPredeterminado}
        estilosContenedor={'w-1/2 mt-3'}
        estilosLista={`appearance-none rounded relative block w-full  p-1.5 border ${'border-cendiatra'} text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
        opciones={listaOpciones.map((opcion) => ({
          Id: opcion.id,
          Nombre: opcion.valor,
        }))}
        valor={opcionPredeterminada}
        placeholder={'SELECCIONAR'}
      />
      {(fuenteDatosSeleccionada === 0 || fuenteDatosSeleccionada === '0') &&
      atributos?.listaOpciones ? (
        <div className="w-full mt-10">
          <span className=" w-full text-cendiatra text-17px leading-20px font-bold uppercase">
            LISTA OPCIONES
          </span>
          <div className="w-full flex flex-wrap max-h-72 overflow-y-auto">
            {atributos.listaOpciones.map((opcion, key) => (
              <div className="w-full flex pl-10" key={key}>
                <ListaDesplegableOpcion
                  id={opcion.id}
                  valorOpcion={opcion.valor}
                  actualizarValorOpcion={actualizarValorOpcion}
                  eliminarOpcion={eliminarOpcion}
                  agregarOpcion={agregarOpcion}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {parseInt(fuenteDatosSeleccionada) > 0 ? (
        <div className="w-full pt-10">
          <TablaDinamica
            columns={columnasTablaDinamica}
            data={listaOpciones}
            paginacion={true}
            filasPorPagina={5}
          />
        </div>
      ) : null}
    </div>
  )
}

export default ListaDesplegablePropiedades
