import React from 'react'
import {InputRegex} from '../../../inputs'
import {regexCamposTextoConCaracteresEspecificos} from '../../../../constantes'

const Formula = ({atributos, actualizarAtributo}) => {
  return (
    <div className="w-full flex flex-wrap">
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2">
        <label className="flex flex-wrap w-full align-middle content-center">
          UNIDAD DE MEDIDA
        </label>
      </div>
      <InputRegex
        ancho={'w-1/2 flex'}
        cambioValor={(e) => {
          actualizarAtributo('unidadMedida', e)
        }}
        estilosContenedor={'w-full flex'}
        estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        tipo={'text'}
        placeholder={'Descripción'}
        valor={atributos?.unidadMedida ?? ''}
        regex={regexCamposTextoConCaracteresEspecificos}
        mensajeRegex={
          'SE PERMITEN ESTOS CARÁCTERES: + ( ) [ ] , / - . & % > < :'
        }
      />
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2">
        <label className="flex flex-wrap w-full align-middle content-center">
          VALOR DE REFERENCIA
        </label>
      </div>
      <InputRegex
        ancho={'w-1/2 flex'}
        cambioValor={(e) => {
          actualizarAtributo('valorReferencia', e)
        }}
        estilosContenedor={'w-full flex'}
        estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        tipo={'text'}
        placeholder={'Descripción'}
        valor={atributos?.valorReferencia ?? ''}
        regex={regexCamposTextoConCaracteresEspecificos}
        mensajeRegex={
          'SE PERMITEN ESTOS CARÁCTERES: + ( ) [ ] , / - . & % > < :'
        }
      />
      <div className="flex flex-wrap w-full text-cendiatra-verde-2 text-14px pl-2 pt-5">
        <label className="flex flex-wrap w-full align-middle content-center">
          Para referenciar campos, tenga en cuenta que el campo debe existir en
          el formulario. Se referencia de la siguiente manera: @{'{peso}'}
        </label>
      </div>
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2">
        <label className="flex flex-wrap w-full align-middle content-center">
          Fórmula
        </label>
      </div>
      <InputRegex
        ancho={'w-1/2 flex'}
        cambioValor={(e) => {
          e = e?.replace(' ', '')
          actualizarAtributo('formula', e)
        }}
        estilosContenedor={'w-full flex'}
        estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        tipo={'text'}
        placeholder={'Fórmula. Ejemplo: @{talla}/@{peso}'}
        valor={atributos?.formula}
        esFormula={true}
      />
    </div>
  )
}

export default Formula
