import React from 'react'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'

import {TEXTO_VISUAL} from '../../../constantes'
import InformeCondicionesSalud from '../../../componentes/profesionalsst/InformeCondicionesSalud'
const InformeCondicionSalud = () => {
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_PROFESIONAL_SST
          .INFORME_CONDICIONES_DE_SALUD
      }
    >
      <InformeCondicionesSalud/>
    </ContenedorPagina>
  )
}

export default InformeCondicionSalud
