import React from 'react'

const InputRadio = ({estilos, seleccionado, funcion}) => {
  return (
    <input
      className={estilos}
      type="radio"
      checked={seleccionado}
      onClick={funcion}
    />
  )
}

export default InputRadio
