import React from 'react'

const EncabezadoTextoValor = ({tituloLinea1, tituloLinea2, valor}) => {
  return (
    <div className="w-full flex justify-center items-center text-cendiatra">
      <div className="w-1/4 flex" />
      <div className="w-1/5 flex flex-wrap">
        <span className="flex w-full">{tituloLinea1}</span>
        <span className="flex w-full">{tituloLinea2}</span>
      </div>
      <div className="w-1/12 text-21px">
        <span>{valor}</span>
      </div>
      <div className="w-1/6 flex" />
    </div>
  )
}

export default EncabezadoTextoValor
