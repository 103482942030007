import React, {useContext, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {ContextApplication} from '../../contexto'
import {registerNewPatient} from '../../servicios/Recepcionista.js'
import Modal from '../../componentes/Modales/Modal.jsx'
import moment from 'moment'
import Dexie from 'dexie'

const CreatePatient = ({
  documentCategory,
  documentNumber,
  setDocumentCategory,
  setDocumentNumber,
  setOpenServiceRegister,
  cancelCreatePatient,
  inputTyeDate,
  setInputTypeDate,
}) => {
  // const [CendiatraSites, setCendiatraSites] = useState([]);
  const [modalMessage, setModalMessage] = useState('')

  let todayDate = moment().format().slice(0, -15)

  useEffect(() => {
    document.getElementById('triggerButton').click()
  }, [])

  const ctx = useContext(ContextApplication)
  let maxDateRegister = moment().subtract(5, 'days').format().slice(0, -15)
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues: {
      document_type: documentCategory,
      document_number: documentNumber,
      date: todayDate,
    },
  })

  const changeIdtype = (e) => {
    setDocumentCategory(e.target.value)
    reset({...getValues(), document_number: ''})
  }

  const createNewPatient = async (data) => {
    const storedSite = localStorage.getItem('siteId')

    const requestModel = {
      name: data.name,
      last_name: data.last_name,
      document_type: data.document_type,
      document_number: data.document_number,
      sites_id: storedSite,
      gender: data.gender,
      blood_type: data.blood_type,
      category: data.category,
      birthday: data.birthday,
      //  date: data.date,
    }

    const jsonStringModel = JSON.stringify(requestModel)
    if (ctx.offlineMode === true) {
      const cendiatraDbName = 'cendiatra-db'
      let db = await new Dexie(cendiatraDbName).open()

      const form = {
        Id: data.document_number,
        data: jsonStringModel,
      }

      db.table('CreateUserForm')
        .add(form)
        .then(() => {
          console.log('Form saved')
          ctx.setUserFormInfo([data])
          setOpenServiceRegister(true)
        })
      return
    }

    await registerNewPatient(jsonStringModel)
      .then((response) => {
        if (response.status === 200) {
          const allUserData = Object.assign(data, {id: response.data})
          setOpenServiceRegister(true)
          ctx.setUserFormInfo([allUserData])
          setOpenServiceRegister(true)
          ctx.userFormInfo.push({id: response.data})
        }
      })
      .catch((error) => {
        setModalMessage(`Ocurió un error: ${error}`)
      })
  }

  return (
    <>
      <Modal open={modalMessage !== ''}>
        <div className="flex items-center flex-wrap justify-center">
          <span className=" text-cendiatra text-4xl my-5">{modalMessage}</span>
          <div className="flex justify-evenly my-5 w-full">
            <button
              type="button"
              className="group relative w-1/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              onClick={() => setModalMessage('')}
            >
              Cerrar
            </button>
          </div>
        </div>
      </Modal>

      <div className="w-full flex flex-wrap justify-center ">
        <div className="bg-transparent w-full">
          <h3 className="  text-center text-3xl font-bold text-cendiatra">
            REGISTRO PACIENTE
          </h3>
        </div>
        <form
          className="mt-2 space-y-6 w-4/5 border border-opacity-50 border-cendiatra shadow-2xl rounded"
          onSubmit={handleSubmit(createNewPatient)}
        >
          <div className="bg-white p-4 rounded-lg justify-center">
            <div className="w-full  flex flex-wrap items-center justify-center m-1">
              <div className="flex justify-center w-full md:w-1/3 border-b-4 border-cendiatra">
                <div className="w-3/7 flex justify-center items-center">
                  <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                    1
                  </p>
                </div>
                <div className="w-4/7 flex justify-start items-center">
                  <p className="text-md text-center  text-cendiatra font-semibold ">
                    {' '}
                    Informacion Del Paciente
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
              <div className=" w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Categoria*
                </label>
                <span className="text-red-500">
                  {errors.category && 'Por favor seleccione una opción'}
                </span>

                <select
                  className={
                    Object.keys(errors).find(
                      (element) => element === 'category'
                    )
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                      : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  } //   placeholder="Dirección Email"
                  {...register('category', {required: true})}
                >
                  <option key="-1" value=""></option>
                  <option key="1" value="VIP">
                    VIP
                  </option>
                  <option key="2" value="Normal">
                    Normal
                  </option>
                </select>
              </div>
              <div className=" w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Sexo*
                </label>
                <span className="text-red-500">
                  {errors.gender && 'Por favor seleccione una opción'}
                </span>

                <select
                  className={
                    Object.keys(errors).find((element) => element === 'gender')
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                      : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  }
                  {...register('gender', {required: true})}
                >
                  <option key="-1" value=""></option>
                  <option key="1" value="Masculino">
                    Masculino
                  </option>
                  <option key="2" value="Femenino">
                    Femenino
                  </option>
                </select>
              </div>
              <div className="w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Grupo sanguíneo*
                </label>
                <span className="text-red-500">
                  {errors.blood_type && 'Por favor seleccione una opción'}
                </span>

                <select
                  className={
                    Object.keys(errors).find(
                      (element) => element === 'blood_type'
                    )
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                      : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  }
                  {...register('blood_type', {required: true})}
                >
                  <option key="-1" value=""></option>
                  <option key="1" value="O+">
                    O+
                  </option>
                  <option key="2" value="O-">
                    O-
                  </option>
                  <option key="3" value="A+">
                    A+
                  </option>
                  <option key="4" value="A-">
                    A-
                  </option>
                  <option key="5" value="B+">
                    B+
                  </option>
                  <option key="6" value="B-">
                    B-
                  </option>
                  <option key="7" value="AB+">
                    AB+
                  </option>
                  <option key="8" value="AB-">
                    AB-
                  </option>
                  <option key="9" value="No Refiere">
                    No Refiere
                  </option>
                </select>
              </div>
            </div>
            <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
              <div className=" w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Nombres*
                </label>
                <span className="text-red-500">
                  {errors.name && 'Por favor ingrese un nombre'}
                </span>

                <input
                  className={
                    Object.keys(errors).find((element) => element === 'name')
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                      : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  }
                  type="texto"
                  //autoComplete="email"

                  {...register('name', {required: true})}
                />
              </div>
              <div className="w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Apellidos*
                </label>
                <span className="text-red-500">
                  {errors.last_name && 'Por favor ingrese un apellido'}
                </span>

                <input
                  className={
                    Object.keys(errors).find(
                      (element) => element === 'last_name'
                    )
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                      : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  }
                  type="texto"
                  //autoComplete="email"

                  {...register('last_name', {required: true})}
                />
              </div>
              <div className="w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Fecha de Nacimiento*
                </label>
                <span className="text-red-500">
                  {errors.birthday && 'Por favor seleccione una fecha'}
                </span>

                <input
                  className={
                    Object.keys(errors).find(
                      (element) => element === 'birthday'
                    )
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                      : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  }
                  type={inputTyeDate ? 'date' : 'text'}
                  onFocus={() => setInputTypeDate(true)}
                  max={maxDateRegister}
                  {...register('birthday', {required: true})}
                />
              </div>
            </div>

            <div className="rounded-md -space-y-px flex justify-evenly flex-wrap">
              <div className=" w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Tipo de documento*
                </label>
                <span className="text-red-500">
                  {errors.document_type && 'Por favor seleccione una opción'}
                </span>

                <select
                  className={
                    Object.keys(errors).find(
                      (element) => element === 'document_type'
                    )
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                      : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  } //   placeholder="Dirección Email"
                  {...register('document_type', {required: true})}
                  onChange={(e) => changeIdtype(e)}
                  value={documentCategory}
                >
                  <option key="-1" value=""></option>
                  <option key="1" value="Cédula de ciudadanía">
                    Cédula de Ciudadanía
                  </option>
                  <option key="2" value="Cédula de extranjería">
                    Cédula de Extranjería
                  </option>
                  <option key="3" value="Carnet Diplomatico">
                    Carnet Diplomático
                  </option>
                  <option key="4" value="Pasaporte">
                    Pasaporte
                  </option>
                  <option key="5" value="Salvoconducto">
                    Salvoconducto
                  </option>
                  <option key="6" value="Permiso Especial de Permanencia">
                    Permiso Especial de Permanencia
                  </option>
                  <option key="7" value="Registro Civil">
                    Registro Civil
                  </option>
                  <option key="8" value="Tarjeta de Identidad">
                    Tarjeta de Identidad
                  </option>
                  <option key="9" value="Certificado de Nacido Vivo">
                    Certificado de Nacido Vivo
                  </option>
                  <option key="10" value="Adulto sin Identificacíon">
                    Adulto sin Identificacíon
                  </option>
                  <option key="11" value="Menor sin Identificacíon">
                    Menor sin Identificacíon
                  </option>
                  <option key="12" value="Nit">
                    NIT
                  </option>
                </select>
              </div>
              <div className="w-full md:w-1/4">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  No. Documento*
                </label>
                {documentCategory === '' ? (
                  <input
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'document_number'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    type="number"
                    min="1"
                    disabled={true}
                    {...register('document_number', {
                      required: true,
                      minLength: 4,
                      maxLength: 10,
                    })}
                  />
                ) : null}

                {documentCategory === 'Cédula de ciudadanía' ||
                documentCategory === 'Tarjeta de Identidad' ||
                documentCategory === 'Salvoconducto' ||
                documentCategory === 'Nit' ? (
                  <>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'required' &&
                        'Por favor ingrese el número de documento'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'minLength' &&
                        'El documento no peude tener menos de 4 caracteres'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'maxLength' &&
                        'El documento no peude tener mas de 10 caracteres'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'document_number'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="number"
                      min="1"
                      {...register('document_number', {
                        required: true,
                        minLength: 4,
                        maxLength: 10,
                      })}
                      onChange={(e) => setDocumentNumber(e.target.value)}
                    />
                  </>
                ) : null}

                {documentCategory === 'Cédula de extranjería' ? (
                  <>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'required' &&
                        'Por favor ingrese el número de documento'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'minLength' &&
                        'El documento no peude tener menos de 6 caracteres'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'maxLength' &&
                        'El documento no peude tener mas de 7 caracteres'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'document_number'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="number"
                      min="1"
                      {...register('document_number', {
                        required: true,
                        minLength: 6,
                        maxLength: 7,
                      })}
                      onChange={(e) => setDocumentNumber(e.target.value)}
                    />
                  </>
                ) : null}

                {documentCategory === 'Carnet Diplomatico' ||
                documentCategory === 'Pasaporte' ||
                documentCategory === 'Certificado de Nacido Vivo' ? (
                  <>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'required' &&
                        'Por favor ingrese el número de documento'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'minLength' &&
                        'El documento no peude tener menos de 8 caracteres'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'maxLength' &&
                        'El documento no peude tener mas de 9 caracteres'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'pattern' &&
                        'El documento solo puede contener letras y números'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'document_number'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="text"
                      min="1"
                      {...register('document_number', {
                        required: true,
                        minLength: 8,
                        maxLength: 9,
                        pattern: /^[a-zA-Z0-9-]+$/,
                      })}
                      onChange={(e) => setDocumentNumber(e.target.value)}
                    />
                  </>
                ) : null}

                {documentCategory === 'Permiso Especial de Permanencia' ? (
                  <>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'required' &&
                        'Por favor ingrese el número de documento'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'minLength' &&
                        'El documento no peude tener menos de 15 caracteres'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'maxLength' &&
                        'El documento no peude tener mas de 15 caracteres'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'document_number'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="number"
                      min="1"
                      {...register('document_number', {
                        required: true,
                        minLength: 15,
                        maxLength: 15,
                      })}
                      onChange={(e) => setDocumentNumber(e.target.value)}
                    />
                  </>
                ) : null}

                {documentCategory === 'Registro Civil' ? (
                  <>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'required' &&
                        'Por favor ingrese el número de documento'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'minLength' &&
                        'El documento no peude tener menos de 10 caracteres'}
                    </span>
                    <span className="text-red-500">
                      {errors.document_number?.type === 'maxLength' &&
                        'El documento no peude tener mas de 10 caracteres'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'document_number'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="number"
                      min="1"
                      {...register('document_number', {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                      })}
                      onChange={(e) => setDocumentNumber(e.target.value)}
                    />
                  </>
                ) : null}

                {documentCategory === 'Adulto sin Identificacíon' ||
                documentCategory === 'Menor sin Identificacíon' ? (
                  <input
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'document_number'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    type="number"
                    min="1"
                    disabled={true}
                    {...register('document_number', {
                      required: false,
                      minLength: 4,
                      maxLength: 10,
                    })}
                    onChange={(e) => setDocumentNumber(e.target.value)}
                  />
                ) : null}
              </div>
              <div className=" w-full md:w-1/4"></div>
            </div>

            <div className="flex justify-center my-5 justify-evenly">
              <button
                type="button"
                className="group relative w-1/6 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-cendiatra bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                onClick={cancelCreatePatient}
              >
                Cancelar
              </button>
              <button
                type="submit"
                id="triggerButton"
                className="group relative w-1/6 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-btnBg bg-center bg-cover hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              >
                Registrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default CreatePatient
