import {getRestApi} from './ApiAxios'

export const ReporteAtencionDetalleEntidad = async (fecha, sedeId, sede) => {
  const apiReq = await getRestApi()
  return await apiReq.get('reportes-function/ReporteAtencionDetalleEntidad', {
    params: {
      fecha,
      sedeId,
      sede,
    },
  })
}

export const GenerarReporteLaboratorio = async (
  fechaDesde,
  fechaHasta,
  sedeId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteLaboratorio',
    params: {fechaDesde, fechaHasta, sedeId},
  })
}

export const GenerarReporteRips = async (fechaDesde, fechaHasta, sedeId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteRips',
    params: {fechaDesde, fechaHasta, sedeId},
  })
}

export const GenerarReporteDetalleAtencion = async (
  fechaDesde,
  fechaHasta,
  empresaId,
  sedeId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteDetalleAtencion',
    params: {fechaDesde, fechaHasta, empresaId, sedeId},
  })
}

export const GenerarReporteDetalleHC = async (
  fechaDesde,
  fechaHasta,
  empresaId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteDetalleHC',
    params: {fechaDesde, fechaHasta, empresaId},
  })
}

export const GenerarReporteDetalleMedioPago = async (
  fechaDesde,
  fechaHasta,
  sedeId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteDetalleMedioPago',
    params: {fechaDesde, fechaHasta, sedeId},
  })
}

export const ReporteResumenPorEntidad = async (fecha, sedeId, sede) => {
  const apiReq = await getRestApi()
  return await apiReq.get('reportes-function/ReporteResumenPorEntidad', {
    params: {
      fecha,
      sedeId,
      sede,
    },
  })
}

export const GenerarReporteContabilidad = async (
  fechaDesde,
  fechaHasta,
  sedeId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteContabilidad',
    params: {fechaDesde, fechaHasta, sedeId},
  })
}

export const GenerarReporteAudiometria = async (
  fechaDesde,
  fechaHasta,
  empresaId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteAudiometria',
    params: {fechaDesde, fechaHasta, empresaId},
  })
}

export const GenerarReporteSVE = async (fechaDesde, fechaHasta, empresaId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'reportes-function/ReporteSve',
    params: {fechaDesde, fechaHasta, empresaId},
  })
}

export const ObtenerConfiguracionPorParticion = async (
  particion = 'ConfiguracionReportes'
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'GET',
    url: `gato-api-function/ObtenerConfiguracionPorParticion`,
    params: {particion},
  })
}

export const CrearSolicitudReporte = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.put('gato-api-function/CrearSolicitudReporte', payload)
}

export const ObtenerSolicitudesPorEstadoId = async (EstadoSolicitudReporte) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'gato-api-function/ObtenerSolicitudesPorEstadoId',
    EstadoSolicitudReporte
  )
}
