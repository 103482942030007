import {useContext, useEffect, useState} from 'react'
import {CeldaDobleTexto} from '../../../componentes/Celdas/CeldaDobleTexto'
import {
  EncabezadoFondo,
  TablaConPaginacionYFiltros,
} from '../../../componentes/Tablas'
import EncabezadoConSubTitulos from '../../../componentes/Tablas/EncabezadoConSubTitulos'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../../constantes/textoVisual'
import {ContextoControlVersionPlantillas} from '../../../contexto'
import {CeldaModulosControlVersionPlantillas} from '../../../componentes/Tablas'
import {
  ObtenerPlantillaPorId,
  ObtenerVersionesPlantillas,
} from '../../../microservicios/Plantilla'

import {useHistory} from 'react-router-dom'
import {RUTAS_APP} from '../../../constantes/rutas/rutasApp'
import moment from 'moment'
import {ARRAY_BOTONES_SELECCION} from '../../../constantes/motorPlantilla'

export default function ControlVersionPlantillas() {
  const history = useHistory()
  const contextoCVPlantillas = useContext(ContextoControlVersionPlantillas)
  const [plantilla, setPlantilla] = useState({
    plantillaId: null,
    esBorrador: false,
    id: null,
    accion: null,
  })
  const [versiones, setVersiones] = useState([])

  const parsearAtributos = (responseData) => {
    const fechaCertificado = moment(responseData.fechaCertificado)
    const fechaHistoria = moment(responseData.fechaHistoria)

    const botonesMapped =
      responseData?.botones?.reduce((acc, item) => {
        acc[item.nombreBoton] = item
        return acc
      }, {}) ?? {}

    const botonesConfiguradosConFaltantes = ARRAY_BOTONES_SELECCION.map((b) => {
      const noFaltaBtn = botonesMapped[b.nombreBoton]
      return noFaltaBtn ? noFaltaBtn : b
    })

    const plantillaConAtributosParseados = {
      ...responseData,
      fechaCertificado: fechaCertificado.isValid()
        ? fechaCertificado.format('YYYY-MM-DD')
        : null,
      fechaHistoria: fechaHistoria.isValid()
        ? fechaHistoria.format('YYYY-MM-DD')
        : null,
      pestanas: responseData.pestanas.map((pestana) => ({
        ...pestana,
        secciones: pestana.secciones.map((seccion) => ({
          ...seccion,
          campos: seccion.campos.map((campo) => ({
            ...campo,
            atributos: campo.atributos ? JSON.parse(campo.atributos) : null,
          })),
        })),
      })),
      botones: botonesConfiguradosConFaltantes,
    }
    return plantillaConAtributosParseados
  }

  const obtenerPlantillaPorId = async () => {
    try {
      const response = await ObtenerPlantillaPorId(plantilla.id)

      if (response.status === 200) {
        contextoCVPlantillas.setPlantilla(parsearAtributos(response.data))
        history.push(
          RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .CONTROL_VERSION_PLANTILLA,
          {...plantilla}
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const funcionAccion = (row, accion) => {
    setPlantilla({
      plantillaId: row.original.id,
      id: row?.original?.ultimaVersion?.id ?? 0,
      esBorrador: row?.original?.ultimaVersion?.esBorrador ?? false,
      version: row?.original?.ultimaVersion?.version ?? 1,
      accion,
    })
  }

  const obtenerDatosVersionesPlantillas = async () => {
    setVersiones([])
    try {
      const respuesta = await ObtenerVersionesPlantillas()
      if (respuesta.status === 200) {
        setVersiones(respuesta.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const ordenarAlfabeticamentePorNombrePlantilla = (versiones = []) => {
    return versiones.sort((a, b) => {
      if (a.nombre?.trim().toUpperCase() < b.nombre?.trim().toUpperCase()) {
        return -1
      } else if (
        a.nombre?.trim().toUpperCase() > b.nombre?.trim().toUpperCase()
      ) {
        return 1
      } else {
        return 0
      }
    })
  }

  useEffect(() => {
    if (plantilla.id) {
      obtenerPlantillaPorId()
    }
  }, [plantilla])

  useEffect(() => {
    obtenerDatosVersionesPlantillas()
  }, [])

  const columnas = [
    {
      Header: (row) => <EncabezadoFondo titulo={'Plantillas'} />,
      accessor: 'nombre',
      className: 'w-5/12',
      headerStyle:
        'bg-cendiatra-verde-7 text-white rounded-l-md  h-20 uppercase',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={
            <CeldaDobleTexto
              datos={{Fila1: 'Versiones', Fila2: 'Anteriores'}}
              fila1Color=""
            />
          }
        />
      ),
      accessor: 'versionesAnteriores',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20 uppercase',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Versión actual'} />,
      accessor: 'versionActual',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20 uppercase',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: ({row}) => {
        return row?.original?.ultimaVersion?.version
      },
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          estilos="w-full flex justify-center items-center  my-1 mx-0.5 rounded-md text-14px"
          titulo={
            <EncabezadoConSubTitulos
              titulo={'Módulos'}
              subtitulo1={
                <CeldaDobleTexto
                  datos={{Fila1: 'Editar', Fila2: 'Borrador'}}
                  fila1Color=""
                />
              }
              subtitulo2={
                <CeldaDobleTexto
                  datos={{Fila1: 'Crear nueva', Fila2: 'Versión'}}
                  fila1Color=""
                />
              }
              fondoCendiatra=""
            />
          }
        />
      ),
      accessor: 'modulos',
      className: 'w-3/12',
      headerStyle:
        'bg-cendiatra-verde-7 text-white rounded-r-md h-20 uppercase',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: ({row}) => {
        return (
          <CeldaModulosControlVersionPlantillas
            funcionAccion={funcionAccion}
            row={row}
          />
        )
      },
    },
  ]
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS.CONTROL_DE_VERSIONES
      }
    >
      <TablaConPaginacionYFiltros
        columns={columnas}
        data={ordenarAlfabeticamentePorNombrePlantilla(versiones)}
        paginacion={true}
        usarSort={false}
      />
    </ContenedorPagina>
  )
}
