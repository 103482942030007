import {ObtenerTarifarios} from '../../microservicios/Tarifario'
import {dbComercial} from '../bases-datos/dbComercial'
import Dexie from 'dexie'
import moment from 'moment'
import {ObtenerTodosLosPortafolios} from './Portafolios'

export const AgregarTarifariosACache = async (usarFecha = true) => {
  await dbComercial.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbComercial.table('FechasActualizacion').get({Tabla: 'Tarifarios'})
    )?.FechaUltimaActualizacion
    let Empresa = await dbComercial.table('Empresas').toArray()
    await ObtenerTarifarios(
      usarFecha ? fechaUltimaActualizacion : undefined
    ).then((res) => {
      dbComercial.Tarifarios.bulkPut(
        res.data.map((tarifario) => ({
          Id: tarifario.id,
          EmpresaId: tarifario.empresaId,
          Nombre: tarifario.nombre,
          NombreEmpresa:
            Empresa.find((x) => x.Id == tarifario.empresaId)?.Nombre ?? '',
          FechaInicial: moment(tarifario.fechaInicial).format('YYYY-MM-DD'),
          FechaFinal: tarifario.fechaFinal
            ? moment(tarifario.fechaFinal).format('YYYY-MM-DD')
            : null,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar tarifarios al caché comercial: ' + (e.stack || e)
        )
      })
      dbComercial.FechasActualizacion.put({
        Tabla: 'Tarifarios',
        FechaUltimaActualizacion: moment().format('YYYY-MM-DDTHH:mm:ss'),
      })
    })
  })
}

export const ObtenerTarifariosPorNombreEmpresa = async (empresa) => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()
  const tarifarios = await Promise.resolve(
    dbComercial
      .table('Tarifarios')
      .filter(
        ({NombreEmpresa, Id}) =>
          NombreEmpresa.toUpperCase().indexOf(empresa.toUpperCase()) > -1 &&
          !idsPortafolios.includes(Id)
      )
      .toArray()
  )
  return tarifarios
}

export const ObtenerTarifariosPorNombre = async (nombre) => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()
  const tarifarios = await Promise.resolve(
    dbComercial
      .table('Tarifarios')
      .filter(
        ({Nombre, Id}) =>
          Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1 &&
          !idsPortafolios.includes(Id)
      )
      .toArray()
  )

  return tarifarios
}

export const ObtenerTodosLosTarifarios = async () => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()

  const Tarifarios = await Promise.resolve(
    dbComercial
      .table('Tarifarios')
      .filter(({Id}) => !idsPortafolios.includes(Id))
      .toArray()
  )

  return Tarifarios
}

export const ObtenerTarifarioPorEmpresaId = async (empresaId) => {
  return Promise.resolve(
    dbComercial.table('Tarifarios').get({EmpresaId: empresaId})
  )
}

export const ObtenerTarifarioPorNombre = (nombre) =>
  dbComercial
    .table('Tarifarios')
    .where('Nombre')
    .equalsIgnoreCase(nombre)
    .first()

export const ObtenerTarifariosPorFechas = async (fechaInicial, fechaFinal) => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()

  let tarifarios = dbComercial
    .table('Tarifarios')
    .filter(({Id}) => !idsPortafolios.includes(Id))
  if (fechaInicial == '' && fechaFinal == '') {
    return tarifarios.toArray()
  }
  if (fechaFinal == '') {
    tarifarios = tarifarios.filter(({FechaInicial}) =>
      moment(JSON.stringify(FechaInicial)).isSameOrAfter(
        JSON.stringify(fechaInicial)
      )
    )
  }
  if (fechaInicial == '') {
    tarifarios = tarifarios.filter(({FechaFinal}) =>
      moment(JSON.stringify(FechaFinal)).isSameOrBefore(
        JSON.stringify(fechaFinal)
      )
    )
  }
  if (fechaInicial !== '' && fechaFinal !== '') {
    tarifarios = tarifarios.filter(({FechaInicial, FechaFinal}) =>
      moment(JSON.stringify(FechaInicial))
        .add(1, 'days')
        .isBetween(JSON.stringify(fechaInicial), JSON.stringify(fechaFinal)) &&
      FechaFinal
        ? moment(JSON.stringify(FechaFinal))
            .subtract(1, 'days')
            .isBetween(JSON.stringify(fechaInicial), JSON.stringify(fechaFinal))
        : null
    )
  }
  return await Promise.resolve(tarifarios.toArray())
}

const obtenerIdsDeLosPortafoliosParaValidacion = async () => {
  const portafolios = await ObtenerTodosLosPortafolios()

  return portafolios?.map((portafolio) => portafolio.Id) ?? []
}
