import React, {useEffect, useState} from 'react'
import EncabezadoPestana from './EncabezadoPestana'
import FlechaIzquierda from '../../imagenes/flechaIzquierda.svg'
import FlechaDerecha from '../../imagenes/flechaDerecha.svg'
import BotonConImagen from '../BotonConImagen'

export default function BarraEncabezadosPestana({
  contexto,
  titulos,
  deshabilitado,
  deshabilitarValidaciones,
}) {
  const [titulosAMapear, setTitulosAMapear] = useState([])
  const maximoTabs = 5

  useEffect(() => {
    if (titulos && titulos.length > 0) {
      dividirDatos()
    }
  }, [contexto.numeroPagina, titulos])

  const dividirDatos = () => {
    let min = (Math.ceil(contexto.numeroPagina / maximoTabs) - 1) * maximoTabs
    let max = Math.min(min + maximoTabs, titulos.length)
    setTitulosAMapear(titulos.slice(min, max))
  }

  const avanzarTabs = () => {
    contexto.setNumeroPagina(
      Math.ceil(contexto.numeroPagina / maximoTabs) * maximoTabs + 1
    )
  }

  const retrocederTabs = () => {
    contexto.setNumeroPagina(
      Math.ceil(contexto.numeroPagina / maximoTabs - 1) * maximoTabs -
        maximoTabs +
        1
    )
  }

  return (
    <div className=" flex justify-center w-11/12 pointer-events-auto">
      <BotonConImagen
        imagen={FlechaIzquierda}
        funcionAEjecutar={retrocederTabs}
        estilosContenedor={
          contexto.numeroPagina - maximoTabs <= 0 || !maximoTabs ? 'hidden' : ''
        }
        estilosImagen={'w-10 h-10 mr-10'}
        textoAlternativo={'Atrás'}
        desabilitado={deshabilitado}
      />
      {titulosAMapear.map((titulo, posicion) => (
        <EncabezadoPestana
          contexto={contexto}
          numeroPaginacion={titulos.indexOf(titulo) + 1}
          titulo={titulo}
          key={posicion}
          deshabilitarValidaciones={deshabilitarValidaciones}
        />
      ))}
      <BotonConImagen
        imagen={FlechaDerecha}
        funcionAEjecutar={avanzarTabs}
        estilosContenedor={
          Math.ceil(contexto.numeroPagina / maximoTabs) >=
            Math.ceil(titulos.length / maximoTabs) || !maximoTabs
            ? 'hidden'
            : 'ml-5'
        }
        estilosImagen={'w-10 h-10 mr-10'}
        textoAlternativo={'Siguiente'}
        desabilitado={deshabilitado}
      />
    </div>
  )
}
