import {getRestApi} from './ApiAxios'

//#region GET
export const ObtenerCierreCaja = async (sedeId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('caja-function/ValidarCierreCaja?sedeId=' + sedeId)
}

export const ObtenerConsolidadoDiaPorSede = async (sedeId) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'caja-function/ObtenerConsolidadoDiaPorSede?sedeId=' + sedeId
  )
}

//#region PUT
export const CrearCierreCaja = async (cierre) => {
  const apiReq = await getRestApi()
  return await apiReq.put('caja-function/CrearCierre', cierre)
}

export const GenerarCierresDeCaja = async (cierre) => {
  const apiReq = await getRestApi()
  return await apiReq.put('caja-function/GenerarCierresDeCaja', cierre)
}
