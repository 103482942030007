import React from 'react'
import {InputPorcentaje, InputNumerico} from './index'
import {aplicarFormatoMoneda} from '../../utilidades'

const InputConResultado = ({
  titulo,
  estilosTitulo = '',
  estilosInput,
  placeholder,
  valor,
  funcion,
  totalCalculado,
  descripcion,
  porcentaje = true,
  deshabilitarTab = false,
}) => {
  return (
    <div
      className={'w-full flex flex-wrap justify-start items-center ml-2 my-2'}
    >
      {porcentaje ? (
        <>
          <span
            className={`w-6/12 text-cendiatra-verde-3 font-bold ${estilosTitulo}`}
          >
            {titulo}
          </span>

          <InputPorcentaje
            estilosContenedor={'w-3/12 flex  items-center'}
            estilosInput={estilosInput}
            placeholder={placeholder}
            onValueChange={funcion}
            valor={valor}
            deshabilitarTab={deshabilitarTab}
          />
          <span className="w-2/12 text-cendiatra-verde-3 font-bold text-center">
            {totalCalculado ? aplicarFormatoMoneda(totalCalculado) : ''}
          </span>
        </>
      ) : (
        <>
          <span
            className={`w-6/12 text-cendiatra-verde-3 font-bold ${estilosTitulo}`}
          >
            {titulo}
          </span>
          <InputNumerico
            estilosContenedor={'w-3/12 flex  items-center'}
            estilosInput={estilosInput}
            onValueChange={funcion}
            placeholder={placeholder}
            valor={valor}
            deshabilitarTab={deshabilitarTab}
          />
          <span className="w-2/12 text-cendiatra-verde-3 font-bold text-center">
            {totalCalculado ? aplicarFormatoMoneda(totalCalculado) : ''}
          </span>
          <span className="w-full text-cendiatra-gris-1 font-bold">
            {descripcion}
          </span>
        </>
      )}
    </div>
  )
}

export default InputConResultado
