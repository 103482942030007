import React from 'react'

const BotonSimple = ({
  texto,
  estilosBoton,
  deshabilitado,
  funcion,
  tipoDeBoton,
  icono,
  estilosIcono,
}) => {
  return (
    <button
      className={`${estilosBoton}`}
      disabled={deshabilitado}
      onClick={funcion}
      type={tipoDeBoton}
    >
      {icono ? <img src={icono} alt="icono" className={estilosIcono} /> : ''}
      {texto}
    </button>
  )
}

export default BotonSimple
