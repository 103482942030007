import {dbComun} from '../bases-datos/dbComun'

export const ObtenerCie11PorNombreOCodigo = async (filtro) => {
  let cie10PorNombre = (
    await dbComun
      .table('Cie11')
      .filter(
        ({Nombre, Cie10}) =>
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1 ||
          Cie10.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Cie10 + ' - ' + elemento.Nombre,
  }))
  return Promise.resolve(cie10PorNombre)
}

export const ObtenerCie11Lista = async () => {
  let cie10PorNombre = (await dbComun.table('Cie11').toArray()).map(
    (elemento) => ({
      id: elemento.Id,
      filtro: elemento.Cie10 + ' - ' + elemento.Nombre,
    })
  )
  return Promise.resolve(cie10PorNombre)
}

export const ObtenerCiePorId = (id) => {
  return Promise.resolve(dbComun.table('Cie11').get({Id: id}))
}
