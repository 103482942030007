import React, {useContext, useEffect, useMemo} from 'react'
import {ContextoFormulario} from '../../contexto/ContextoFormulario'
import {
  CampoArchivo,
  CampoAutocompletar,
  CampoBooleano,
  CampoComponente,
  CampoEntero,
  CampoFecha,
  CampoFormula,
  CampoListaDesplegable,
  CampoRango,
  CampoTextoCorto,
  CampoTextoLargo,
} from './Campos'

const BaseCampo = ({numeroCampo, numeroPestana, numeroSeccion}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const debeOcuparTodo = campo?.atributos?.hasOwnProperty('componente') ?? false

  const componenteCampo = useMemo(() => {
    const propsCampos = {
      numeroCampo: numeroCampo,
      numeroPestana: numeroPestana,
      numeroSeccion: numeroSeccion,
      readOnly: !contextoFormulario.habilitarEdicion,
    }

    const tipoCamposId = {
      1: <CampoTextoCorto {...propsCampos} />,
      2: <CampoTextoLargo {...propsCampos} />,
      3: <CampoListaDesplegable {...propsCampos} />,
      4: <CampoAutocompletar {...propsCampos} />,
      5: <CampoFecha {...propsCampos} />,
      6: <CampoBooleano {...propsCampos} />,
      7: <CampoArchivo {...propsCampos} />,
      8: <CampoEntero {...propsCampos} />,
      9: <CampoFormula {...propsCampos} />,
      10: <CampoRango {...propsCampos} />,
      11: <CampoComponente {...propsCampos} />,
    }

    return (
      tipoCamposId[campo.tipoCampoId] ?? <CampoTextoCorto {...propsCampos} />
    )
  }, [campo, numeroCampo, numeroPestana, numeroSeccion])

  useEffect(() => {}, [])

  return (
    <div className={`${debeOcuparTodo ? 'w-full' : 'w-1/2'} flex px-20 my-5`}>
      {componenteCampo}
    </div>
  )
}

export default BaseCampo
