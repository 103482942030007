import React, {useEffect, useContext} from 'react'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {BotonOpcionConObservaciones} from '../../../Botones'

const Restricciones = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      contextoMedicoOcupacional.setformularioActualTieneErrores(false)
    }
  }, [contextoMedicoOcupacional.numeroPagina])

  const manejarCambioRestriccion = (id) => {
    contextoMedicoOcupacional.setListaRestricciones(
      contextoMedicoOcupacional.listaRestricciones.map((restriccion) => {
        if (restriccion.Id == id) {
          return {
            ...restriccion,
            EstaActivo: !restriccion.EstaActivo,
          }
        }
        return restriccion
      })
    )
  }

  const manejarCambioObservaciones = (valor, id) => {
    contextoMedicoOcupacional.setListaRestricciones(
      contextoMedicoOcupacional.listaRestricciones.map((restriccion) => {
        if (restriccion.Id == id) {
          return {
            ...restriccion,
            Observaciones: valor,
          }
        }
        return restriccion
      })
    )
  }

  return (
    <div className="w-11/12 flex flex-wrap justify-start items-center ml-16">
      {contextoMedicoOcupacional.listaRestricciones.map((restriccion) => (
        <div className="w-1/3 flex mb-2 p-5">
          <BotonOpcionConObservaciones
            id={restriccion.Id}
            titulo={restriccion.Nombre.toUpperCase()}
            estaActivo={restriccion.EstaActivo}
            observaciones={restriccion.Observaciones}
            funcionClick={manejarCambioRestriccion}
            cambioObservaciones={manejarCambioObservaciones}
          />
        </div>
      ))}
    </div>
  )
}

export default Restricciones
