import {dbComun} from '../bases-datos/dbComun'

export const ObtenerTodasLasSedes = async () => {
  return Promise.resolve(dbComun.table('Sedes').toArray())
}

export const ObtenerCiudadPorNombreSede = async (nombreCiudad) => {
  return Promise.resolve(
    dbComun
      .table('SedesCendiatra')
      .filter(
        ({Ciudad}) =>
          Ciudad.toLocaleUpperCase().indexOf(nombreCiudad.toLocaleUpperCase()) >
          -1
      )
      .toArray()
  )
}

export const ObtenerSedesPorCiudadId = async (id) => {
  return Promise.resolve(
    dbComun
      .table('SedesCendiatra')
      .filter(({CiudadId}) => CiudadId == id)
      .toArray()
  )
}
export const ObtenerSedePorId = async (id) => {
  return Promise.resolve(dbComun.table('SedesCendiatra').get({Id: id}))
}
