import React, {useEffect, useContext} from 'react'
import FilaPrecios from './filas/FilaPrecios'
import {
  ValidarOrdenesPorTarifarioIdYServicioId,
  ValidarAtencionesPorTarifarioIdYServicioId,
} from '../../microservicios'
import {ContextApplication} from '../../contexto'

export default function TablaServicios({contexto, esPortafolio = false}) {
  const contextoApp = useContext(ContextApplication)

  useEffect(() => {
    contexto.listaServicios.forEach((servicio, index, listaServicios) => {
      listaServicios[index].precios_sedes = contexto.sedesSeleccionadas
        .filter((x) => x.Activo)
        .map((sede) => ({
          id: 'servicio:' + sede.Id,
          nombre: sede.Nombre,
          grupo: sede.Grupo,
          precio:
            servicio.precios_sedes.find((x) => x.id == 'servicio:' + sede.Id) ==
            undefined
              ? 0
              : servicio.precios_sedes.find(
                  (x) => x.id == 'servicio:' + sede.Id
                ).precio,
          idPrecio:
            servicio.precios_sedes.find((x) => x.id == 'servicio:' + sede.Id) ==
            undefined
              ? 0
              : servicio.precios_sedes.find(
                  (x) => x.id == 'servicio:' + sede.Id
                ).idPrecio,
          tipo: 'servicio',
          idGeneral: servicio.servicio_id,
          sedeId: sede.Id,
          nuevoPrecio:
            servicio.precios_sedes.find((x) => x.id == 'servicio:' + sede.Id) ==
            undefined
              ? 0
              : servicio.precios_sedes.find(
                  (x) => x.id == 'servicio:' + sede.Id
                ).nuevoPrecio,
        }))
    })
  }, [contexto.sedesSeleccionadas])

  useEffect(() => {
    contexto.listaServicios.forEach((servicio, index, listaServicios) => {
      listaServicios[index].precios_aliados = contexto.aliadosSeleccionados
        .filter((x) => x.Activo)
        .map((aliado) => ({
          id: 'aliado:' + aliado.Id,
          nombre: aliado.Nombre,
          grupo: 'ALIADOS',
          precio:
            servicio.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : servicio.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).precio,
          idPrecio:
            servicio.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : servicio.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).idPrecio,
          tipo: 'servicio',
          idGeneral: servicio.servicio_id,
          aliadoId: aliado.Id,
          nuevoPrecio:
            servicio.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : servicio.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).nuevoPrecio,
        }))
    })
  }, [contexto.aliadosSeleccionados])

  const manejarCerrarModalEliminar = () => {
    contextoApp.setModal({abierto: false, botones: []})
  }

  const eliminarServicio = async (idServicio) => {
    if (Boolean(contexto.portafolioSeleccionadoId)) {
      contexto.setSeguroDeCambio(true)
      return
    }

    let eliminarLocalmente = true

    const id = contexto.idTarifario || contexto.portafolioId

    const [{data: ordenConRegistros}, {data: atencionConRegistros}] =
      await Promise.all([
        ValidarOrdenesPorTarifarioIdYServicioId(id, idServicio),
        ValidarAtencionesPorTarifarioIdYServicioId(id, idServicio),
      ])

    if (ordenConRegistros || atencionConRegistros) {
      eliminarLocalmente = false

      contextoApp.setModal({
        abierto: true,
        titulo: '',
        contenido: (
          <p className="text-cendiatra text-3xl font-bold">
            ESTE SERVICIO Y/O PAQUETE YA TIENE <br /> REGISTROS NO SE PUEDE
            QUITAR
          </p>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModalEliminar,
          },
        ],
      })
    }

    if (eliminarLocalmente) {
      var filtro = contexto.listaServicios.filter((servicio) => {
        return servicio.servicio_id !== idServicio
      })

      contexto.setListaServicios(filtro)
    }
  }

  const validarElementoNuevo = (id) => {
    return Boolean(
      contexto.serviciosIniciales.find((elemento) => elemento === id)
    )
  }
  const validarColumnaAEditar = (paqueteId, datos) => {
    const sedeOAliadoNuevo = validacionGeneral(datos)
    if (sedeOAliadoNuevo) {
      const nombrePropiedad =
        contexto.modoEdicion && validarElementoNuevo(paqueteId)
          ? 'nuevoPrecio'
          : 'precio'
      return nombrePropiedad
    }
    return 'precio'
  }

  const validacionGeneral = (data) => {
    const resultado = Boolean(
      contexto?.[
        data?.grupo === 'ALIADOS' ? 'aliadosIniciales' : 'sedesIniciales'
      ].find((x) =>
        data?.grupo === 'ALIADOS'
          ? x === data?.aliadoId ?? 0
          : x === data?.sedeId ?? 0
      )
    )
    return resultado
  }

  const actualizarPrecios = (idSede, value, idFila) => {
    contexto.setListaServicios(
      contexto.listaServicios.map((servicio) => {
        if (servicio.servicio_id === idFila) {
          return {
            ...servicio,
            precios_sedes: servicio.precios_sedes.map((elementoPrecio) => {
              if (elementoPrecio.id === idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(servicio.servicio_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
            precios_aliados: servicio.precios_aliados.map((elementoPrecio) => {
              if (elementoPrecio.id === idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(servicio.servicio_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
          }
        }
        return servicio
      })
    )
  }

  const validarCampoAlias = (caracter) => {
    let Validacion = new RegExp(
      /[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,\[/\]/)(+-//\s*]/,
      ''
    )

    return !Validacion.test(caracter)
  }

  const actualizarAlias = (idFila, valor) => {
    if (validarCampoAlias(valor.slice(-1) || valor === ' ')) {
      window.alert('Se aceptan letras y estos caracteres ( ) - . , [ ] / *')
      return
    }
    contexto.setListaServicios(
      contexto.listaServicios.map((servicio) => {
        if (servicio.servicio_id == idFila) {
          return {
            ...servicio,
            alias: valor,
          }
        }
        return servicio
      })
    )
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      {contexto.listaServicios.map((servicio, posicion) => (
        <FilaPrecios
          contexto={contexto}
          idFila={servicio.servicio_id}
          nombre={servicio.nombre}
          alias={servicio.alias}
          precios={servicio.precios_sedes.concat(servicio.precios_aliados)}
          actualizarPrecios={actualizarPrecios}
          eliminarFila={eliminarServicio}
          actualizarAlias={actualizarAlias}
          posicion={posicion}
          key={posicion}
          tipoElemento={'Servicio'}
          esPortafolio={esPortafolio}
        />
      ))}
    </div>
  )
}
