import React from 'react'
import {EncabezadoFondo, TablaConPaginacionYFiltros} from './index'
import {CeldaDobleTexto} from '../Celdas'
import moment from 'moment/moment'
import BotonConImagen from '../BotonConImagen'
import IconoImprimirActivado from '../../imagenes/iconoImpresoraCuadradoVerde.svg'
import IconoImprimirBloqueado from '../../imagenes/iconoBloqueoCuadradoRojo.svg'
import IconoImprimirDesactivado from '../../imagenes/iconoImprimirGrisCuadrado.svg'

const TablaHistoriaClinica = ({data}) => {
  const abrirUrl = (url) => {
    window.open(url, '_blank')
  }

  const columnasServicios = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
      disableFilters: true,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Servicio'} />,
      accessor: 'servicio',
      cellStyles:
        'w-3/12 flex text-center justify-center items-center content-center border-cendiatra rounded-l-md border-l border-t border-b h-16  text-cendiatra-gris-1',
      headerStyle:
        'w-3/12 bg-cendiatra-verde-7 text-white ml-0.5 rounded-l-md h-14',
      disableFilters: true,
      Cell: (row) => (
        <span className="line-clamp-1">{row.row.original.servicio}</span>
      ),
    },
    {
      Header: (row) => (
        <EncabezadoFondo Fila1={'Fecha y'} Fila2={'hora creación'} />
      ),
      accessor: 'fechaYHoraCreacion',
      headerStyle: 'w-1/12 bg-cendiatra-verde-7 text-white h-14',
      cellStyles:
        'w-1/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      disableFilters: true,
      Cell: (row) => (
        <CeldaDobleTexto
          datos={{
            Fila1: moment(row.row.original.fechaYHoraCreacion).format(
              'YYYY-MM-DD'
            ),
            Fila2: moment(row.row.original.fechaYHoraCreacion).format(
              'hh:mm:ss'
            ),
          }}
        />
      ),
    },
    {
      Header: (row) => (
        <EncabezadoFondo Fila1={'Fecha y'} Fila2={'hora cierre'} />
      ),
      accessor: 'fechaYHoraCierre',
      cellStyles:
        'w-1/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-1/12 bg-cendiatra-verde-7 text-white h-14',
      disableFilters: true,
      Cell: (row) => (
        <CeldaDobleTexto
          datos={{
            Fila1: moment(row.row.original.fechaYHoraCierre).format(
              'YYYY-MM-DD'
            ),
            Fila2: moment(row.row.original.fechaYHoraCierre).format('hh:mm:ss'),
          }}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Médico'} />,
      accessor: 'medico',
      cellStyles:
        'w-3/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 text-cendiatra-gris-1',
      headerStyle: 'w-3/12 bg-cendiatra-verde-7 text-white h-14',
      disableFilters: true,
    },
    {
      Header: (row) => (
        <EncabezadoFondo Fila1={'Imprimir'} Fila2={'certificado'} />
      ),
      accessor: 'imprimirCertificado',
      cellStyles:
        'w-1/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 ',
      headerStyle: 'w-1/12 bg-cendiatra-verde-7 text-white h-14',
      disableFilters: true,
      Cell: (row) => (
        <BotonConImagen
          imagen={
            row.row.original.urlCertificado
              ? IconoImprimirActivado
              : IconoImprimirBloqueado
          }
          desabilitado={!row.row.original.urlCertificado}
          textoAlternativo={'Imprimir'}
          funcionAEjecutar={() => abrirUrl(row.row.original.urlCertificado)}
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo Fila1={'Imprimir'} Fila2={'examen'} />,
      accessor: 'imprimirHistoria',
      cellStyles:
        'w-1/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b border-r rounded-r-md h-16',
      headerStyle: 'w-1/12 bg-cendiatra-verde-7 text-white rounded-r-md h-14',
      disableFilters: true,
      Cell: (row) => (
        <BotonConImagen
          imagen={
            row.row.original.urlHistoria
              ? IconoImprimirActivado
              : IconoImprimirDesactivado
          }
          desabilitado={!row.row.original.urlHistoria}
          textoAlternativo={'Imprimir'}
          funcionAEjecutar={() => abrirUrl(row.row.original.urlHistoria)}
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
        />
      ),
    },
  ])
  return (
    <div className="w-full flex flex-wrap bg-white rounded-t-3xl rounded-b-3xl justify-center items-center  mb-2 mt-2">
      <TablaConPaginacionYFiltros
        columns={columnasServicios}
        data={data}
        deshabilitarBordeFila={true}
        paginacion={true}
      />
    </div>
  )
}

export default TablaHistoriaClinica
