// Librerías/Frameworks
import React, {useContext, useState} from 'react'

import {ContextoPlantilla, ContextApplication} from '../../../contexto'
import expandir from '../../../imagenes/Expandible/flechaDesplegableExpandirVerde.svg'
import removeButtonImg from '../../../imagenes/removeButton.png'
import addButtonImg from '../../../imagenes/addButton.png'
import {InputRegex} from '../../inputs'
import {LabelNumero} from '../../Labels'
import BotonConImagen from '../../BotonConImagen'
import PlantillaSeccion from './PlantillaSeccion.jsx'

const PlantillaPestana = ({numeroPestana, expandidoInicial, numeroPagina}) => {
  const contextoApp = useContext(ContextApplication)
  const contextoPlantilla = useContext(ContextoPlantilla)
  const pestana = contextoPlantilla.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const [expandido, setExpandido] = useState(expandidoInicial)

  const actualizarTituloPestana = (nuevoValor) => {
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: contextoPlantilla.plantilla.pestanas.map((pestana) => {
        if (pestana.numeroPestana == numeroPestana) {
          return {
            ...pestana,
            tituloPestana: nuevoValor,
          }
        }
        return pestana
      }),
    })
  }

  const validarPestanaDuplicada = (nombrePestana) => {
    let listaPestanas = contextoPlantilla.plantilla.pestanas.filter(
      (x) => x.numeroPestana != numeroPestana
    )
    let existeNombrePestana = listaPestanas.find(
      (x) => x.tituloPestana == nombrePestana
    )
    let idError = 'nombreTab' + numeroPestana

    if (!existeNombrePestana && contextoPlantilla.errores.includes(idError)) {
      contextoPlantilla.setErrores(
        contextoPlantilla.errores.filter((x) => x != idError)
      )
    }
    if (existeNombrePestana && !contextoPlantilla.errores.includes(idError)) {
      contextoPlantilla.setErrores([...contextoPlantilla.errores, idError])
      contextoApp.setModal({
        abierto: true,
        titulo: 'EL NOMBRE DEL TAB YA SE ENCUENTRA DENTRO DE LA PLANTILLA',
        contenido: null,
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModalError,
          },
        ],
      })
    }
  }

  const manejarCerrarModalError = () => {
    contextoApp.setModal({abierto: false, botones: []})
  }

  const agregarPestana = () => {
    let nuevaPestana = {
      tituloPestana: '',
      numeroPestana: numeroPestana + 1,
      secciones: [
        {
          nombreSeccion: '',
          numeroSeccion: 1,
          campos: [
            {
              tituloCampo: '',
              numeroCampo: 1,
              tipoCampoId: -1,
              requerido: false,
              mostrarEnCertificado: false,
              atributos: {},
            },
          ],
        },
      ],
    }
    let listaPestanas = [...contextoPlantilla.plantilla.pestanas].map(
      (pestana) => {
        if (pestana.numeroPestana > numeroPestana) {
          return {
            ...pestana,
            numeroPestana: pestana.numeroPestana + 1,
          }
        }
        return pestana
      }
    )
    listaPestanas.splice(numeroPestana, 0, nuevaPestana)
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: listaPestanas,
    })
  }

  const eliminarPestana = () => {
    let listaPestanas = [
      ...contextoPlantilla.plantilla.pestanas.filter(
        (x) => x.numeroPestana != numeroPestana
      ),
    ].map((pestana) => {
      if (pestana.numeroPestana > numeroPestana) {
        return {
          ...pestana,
          numeroPestana: pestana.numeroPestana - 1,
        }
      }
      return pestana
    })
    if (listaPestanas.length == 0) {
      return
    }
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: listaPestanas,
    })
    contextoPlantilla.setErrores([])
  }
  return (
    <div className="w-full">
      <div className="flex flex-wrap">
        <LabelNumero numero={pestana.numeroPestana} esHeader={true} />
        <InputRegex
          id={'nombreTab' + numeroPestana}
          ancho={'w-10/12'}
          cambioValor={actualizarTituloPestana}
          estilosContenedor={'w-full flex'}
          estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
          tipo={'text'}
          placeholder={'NOMBRE DEL TAB'}
          valor={pestana.tituloPestana}
          contexto={contextoPlantilla}
          numeroPagina={numeroPagina}
          requerido={true}
          desenfocado={validarPestanaDuplicada}
        />
        <BotonConImagen
          estilosContenedor={'w-10 pt-2'}
          imagen={removeButtonImg}
          medio={true}
          funcionAEjecutar={eliminarPestana}
          textoAlternativo={'eliminar'}
        />
        <BotonConImagen
          estilosContenedor={'w-6 pt-2'}
          imagen={addButtonImg}
          medio={true}
          funcionAEjecutar={agregarPestana}
          textoAlternativo={'agregar'}
        />
        <BotonConImagen
          estilosContenedor={'w-10 pt-2'}
          imagen={expandir}
          medio={true}
          funcionAEjecutar={() => {
            setExpandido(!expandido)
          }}
          rotacion={expandido ? '180' : '0'}
          textoAlternativo={'expandir'}
        />
      </div>
      <div className={expandido ? 'w-full flex flex-wrap' : 'hidden'}>
        {pestana.secciones.map((seccion) => (
          <div className="w-full flex mb-1 pl-10 pr-16">
            <PlantillaSeccion
              numeroPestana={numeroPestana}
              numeroSeccion={seccion.numeroSeccion}
              numeroPagina={numeroPagina}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PlantillaPestana
