import React, {useState} from 'react'
import BotonConImagen from '../BotonConImagen'
import expandir from '../../imagenes/Expandible/flechaDesplegableExpandirBlanco.svg'

const ContenedorDesplegable = ({
  titulo,
  posicionTitulo,
  expandidoInicial,
  deshabilitar,
  ocultarBotonDesplegable,
  children,
}) => {
  const [expandido, setExpandido] = useState(expandidoInicial)

  return (
    <div className={'w-full flex flex-wrap justify-center items-center '}>
      <div
        className={`w-full h-45.2px flex justify-center items-center ${
          deshabilitar
            ? 'bg-cendiatra-gris-4 pointer-events-none'
            : 'bg-cendiatra-verde-7'
        } text-white rounded-lg h-10 px-5 text-14px mb-2`}
      >
        <div
          className={`w-11/12 flex ${
            posicionTitulo ? posicionTitulo : 'justify-start'
          } items-center`}
        >
          <span>{titulo}</span>
        </div>
        <div className={`relative ${ocultarBotonDesplegable ? 'hidden' : ''} `}>
          <BotonConImagen
            estilosContenedor={'w-10 h-10 absolute -top-5'}
            estilosImagen={'w-10'}
            imagen={expandir}
            medio={true}
            funcionAEjecutar={() => {
              setExpandido(!expandido)
            }}
            rotacion={expandido && !deshabilitar ? '180' : '0'}
            textoAlternativo={'expandir'}
          />
        </div>
      </div>
      <div
        className={
          expandido && !deshabilitar
            ? 'w-full flex flex-wrap justify-center items-center'
            : 'hidden'
        }
      >
        {children}
      </div>
    </div>
  )
}

export default ContenedorDesplegable
