import React from 'react'
import {NumericFormat} from 'react-number-format'

const CeldaFormatoDinero = ({
  estilos,
  valor,
  estilosContenedor = 'w-full flex justify-center items-center',
}) => {
  return (
    <div className={estilosContenedor}>
      <NumericFormat
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'$'}
        value={valor}
        className={estilos}
      />
    </div>
  )
}

export default CeldaFormatoDinero
