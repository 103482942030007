import React from 'react'
import Input from './Input.jsx'

export const CeldaInput = ({
  value: initialValue,
  row: {original},
  actualizarDatos,
}) => {
  const [value, setValue] = React.useState('')

  const onChange = (e) => {
    setValue(e.formattedValue)
  }

  const onBlur = (e) => {
    setValue(e.target.value)
    actualizarDatos(e.target.value, original.Id)
  }

  React.useEffect(() => {
    setValue('' + initialValue)
  }, [initialValue])
  return (
    <Input
      estilosContenedor={'w-full mr mb-2'}
      estilosInput={
        'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
      }
      tipo={'text'}
      placeholder={'Descripción (Texto corto)'}
      titulo={''}
      onBlur={onBlur}
      onChange={onChange}
      valor={value}
    />
  )
}

export default CeldaInput