import React, {useState, useEffect} from 'react'
import Boton from './Boton'

const BotonTitilar = ({
  titulo,
  id,
  habilitado,
  listaFilas,
  funcionClick,
  funcionDejoTitilar,
}) => {
  const [botonTitilando, setBotonTitilando] = useState(false)
  const [botonVisible, setBotonVisible] = useState(true)
  const [botonDejoTitilar, setBotonDejoTitilar] = useState(false)

  const manejarClickBoton = (tiempoLlamada) => {
    if (tiempoLlamada == 40) {
      setBotonVisible(true)
      setBotonTitilando(false)
      setBotonDejoTitilar(true)
      return
    } else if (tiempoLlamada == 0) {
      if (!listaFilas.includes(id)) {
        funcionClick(id)
      }
      setBotonTitilando(true)
    }
    setBotonVisible(tiempoLlamada % 2 == 0 ? false : true)
    setTimeout(manejarClickBoton, 500, tiempoLlamada + 1)
  }

  useEffect(() => {
    if (listaFilas.includes(id)) {
      manejarClickBoton(0)
    }
  }, [])

  useEffect(() => {
    if (botonDejoTitilar) {
      funcionDejoTitilar(id)
    }
  }, [botonDejoTitilar])

  return (
    <Boton
      titulo={titulo}
      colorPorDefecto={
        botonVisible ? 'bg-cendiatra-gris-4 pointer-events-none' : ''
      }
      colorActivo={botonVisible ? 'bg-cendiatra-semaforo-amarillo' : ''}
      habilitado={habilitado}
      alto={'h-9'}
      funcionCLick={() => {
        if (!botonTitilando) {
          manejarClickBoton(0)
        }
      }}
    />
  )
}

export default BotonTitilar
