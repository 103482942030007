import React, {useState} from 'react'

const CeldaConTooltipPersonalizable = ({
  texto = '',
  textoAdicional = '',
  estilosPersonalizados = 'text-14px bg-cendiatra text-white mt-8 w-80 ',
  anchoMaximo = '100%',
  align = 'justify-center',
  estilosTextoToolTip = 'text-center text-lg z-50',
}) => {
  const [abrirDescripcion, setAbrirDescripcion] = useState(false)
  return (
    <div
      className={`flex ${align} items-center`}
      onMouseEnter={() => setAbrirDescripcion(true)}
      onMouseLeave={() => setAbrirDescripcion(false)}
    >
      <span className="text-center truncate">{texto}</span>
      <div
        className={
          abrirDescripcion
            ? `flex justify-center items-center ${estilosPersonalizados} px-1 absolute rounded-lg shadow-xl z-50`
            : 'hidden'
        }
        style={{maxWidth: `${anchoMaximo}`}}
      >
        <p>
          <span className={`${estilosTextoToolTip}`}>
            {textoAdicional ? textoAdicional : texto}
          </span>
        </p>
      </div>
    </div>
  )
}

export default CeldaConTooltipPersonalizable
