import React, {useEffect, useRef} from 'react'

const AreaDeTexto = ({
  estilosContenedor,
  estilosAreaDeTexto,
  titulo,
  deshabilitado,
  placeholder,
  valor,
  cambioValor,
  id,
  rows = 4,
  onBlur,
  readOnly = false,
}) => {
  const textAreaRef = useRef()

  const autoTamanoTextArea = () => {
    textAreaRef.current.style.height = 'auto'

    if (textAreaRef.current.scrollHeight > 0) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }

  useEffect(autoTamanoTextArea, [valor])

  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      <label className="block text-13px leading-15px text-cendiatra-verde-2 my-5px">
        {titulo}
      </label>
      <textarea
        className={estilosAreaDeTexto}
        disabled={deshabilitado}
        placeholder={placeholder}
        onChange={cambioValor}
        value={valor}
        id={id}
        ref={textAreaRef}
        rows={rows}
        onBlur={onBlur}
        readOnly={readOnly}
      />
    </div>
  )
}

export default AreaDeTexto
