import {ROLES_APP} from './roles'

export const LEYENDA_AGENDA = {
  MEDICO: [
    {
      colorFondo: 'bg-cendiatra-semaforo-rojo rounded-full',
      texto: 'Examen pendiente por atender',
    },
    {
      colorFondo: 'bg-cendiatra-semaforo-naranja rounded-full',
      texto: 'Atendido pendiente por cerrar',
    },
    {
      colorFondo: 'bg-cendiatra-semaforo-amarillo rounded-full',
      texto: 'Llamado de paciente',
    },
    {
      colorFondo: 'bg-cendiatra-verde-4 rounded-full',
      texto: 'Examen finalizado',
    },
    {
      texto: 'Toma de datos',
      textoAbreviado: 'TD',
    },

    {
      texto: 'Guardado parcial',
      textoAbreviado: 'GP',
    },
    {
      colorFondo: 'bg-alerta bg-contain bg-no-repeat h-4 w-4 ',
      texto: 'Examen en proceso de atención',
      rolDenegado: [ROLES_APP.RECEPCION, ROLES_APP.JEFE_SEDE],
    },
  ],
  BACTERIOLOGO: [
    {
      colorFondo: 'bg-cendiatra-semaforo-rojo rounded-full',
      texto: 'Examen pendiente por atender',
    },
    {
      colorFondo: 'bg-cendiatra-semaforo-naranja rounded-full',
      texto: 'Atendido pendiente por cerrar',
    },
    {
      colorFondo: 'bg-cendiatra-verde-4 rounded-full',
      texto: 'Examen finalizado',
    },
  ],
}
