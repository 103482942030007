import React, {useState, useEffect, useMemo} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes'
import {Input} from '../../componentes/inputs'
import {
  TablaConPaGinacionYFiltros,
  EncabezadoFondo,
} from '../../componentes/Tablas'
import {
  FachadaObtenerCantidadAtencionesPorMesSedeId,
  FachadaObtenerAtencionesPorDiaSedeId,
} from '../../microservicios'
import moment from 'moment'
import {transformarNumero} from '../../utilidades'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const ReporteDeAtencionDiaria = () => {
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  let fechaActual = moment().format('YYYY-MM-DD')
  const [fechaBusqueda, setFechaBusqueda] = useState(fechaActual)
  const [numeroAtencionesDelDia, setNumeroAtencionesDelDia] = useState(0)
  const [numeroAtencionesMes, setNumeroAtencionesMes] = useState(0)
  const [datosAtenciones, setDatosAtenciones] = useState([])

  const sede = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)

  const columns = useMemo(
    () => [
      {
        Header: () => <EncabezadoFondo titulo={'No.'} paddingFondoClaro={''} />,
        accessor: 'id',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-l-lg pl-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 flex justify-center items-center  h-16',
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Número de atención'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'numeroAtencion',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 ',
        disableFilters: false,
        cellStyles:
          'text-13px text-cendiatra-gris-1 flex justify-center items-center h-16',
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Número de documento'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'numeroDocumento',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 ',
        disableFilters: false,
        cellStyles:
          'text-13px text-cendiatra-gris-1 flex justify-center items-center h-16',
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Nombre del paciente'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'nombrePaciente',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 ',
        disableFilters: false,
        cellStyles:
          'text-13px text-cendiatra-gris-1 flex justify-center items-center h-16',
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Número de factura / recibo'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'numeroFactura',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 ',
        disableFilters: false,
        cellStyles:
          'text-13px text-cendiatra-gris-1 flex justify-center items-center h-16',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Empresa'} paddingFondoClaro={''} />
        ),
        accessor: 'empresa',
        className: 'w-2/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg pr-1',
        disableFilters: false,
        cellStyles:
          'text-13px text-cendiatra-gris-1  flex justify-center items-center h-16',
      },
    ],
    [datosAtenciones]
  )

  const organizarDatos = (datos) => {
    if (datos?.data) {
      setNumeroAtencionesDelDia(datos?.data?.length)
      return datos?.data?.map((atencion, index) => {
        return {
          id: transformarNumero(index + 1),
          numeroAtencion: atencion?.atencionId,
          numeroDocumento: atencion?.numeroDocumento,
          nombrePaciente: `${atencion?.nombres} ${atencion?.apellidos} `,
          numeroFactura:
            atencion?.numeroFactura || atencion?.comprobanteAtencion,
          empresa: atencion?.nombreEmpresa,
        }
      })
    }
    setNumeroAtencionesDelDia(0)
    return []
  }

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const atencionesMes =
          await FachadaObtenerCantidadAtencionesPorMesSedeId(sede, fechaActual)
        setNumeroAtencionesMes(atencionesMes?.data)
        const atencionesDiaActual =
          (await FachadaObtenerAtencionesPorDiaSedeId(fechaBusqueda, sede)) ??
          []
        setDatosAtenciones(organizarDatos(atencionesDiaActual))
      } catch (error) {
        console.log(error)
      }
    }
    obtenerDatos()
  }, [fechaBusqueda])

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MEDICO_OCUPACIONAL.REPORTE_ATENCION_DIARIA
      }
    >
      <div className="w-full flex justify-between items-center font-bold">
        <div className="w-5/12 flex justify-start items-center">
          <Input
            estilosContenedor={'w-6/12 text-left'}
            estilosInput={
              'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'date'}
            placeholder={''}
            titulo={'Fecha'}
            onChange={(e) => setFechaBusqueda(e.target.value)}
            valor={fechaBusqueda}
          />
          <Input
            estilosContenedor={'w-4/12 text-left mx-10'}
            estilosInput={
              'appearance-none rounded pointer-events-none text-center text-cendiatra text-2xl font-bold relative block w-full  border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10'
            }
            tipo={'text'}
            placeholder={''}
            titulo={'Atenciones del día'}
            onChange={() => {}}
            valor={numeroAtencionesDelDia}
          />
        </div>
        <Input
          estilosContenedor={'w-2/12 text-left mx-10'}
          estilosInput={
            'appearance-none rounded pointer-events-none text-center text-cendiatra text-2xl font-bold relative block w-full  border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10'
          }
          tipo={'text'}
          placeholder={''}
          titulo={'Atenciones del mes actual'}
          onChange={() => {}}
          valor={numeroAtencionesMes}
        />
      </div>
      <TablaConPaGinacionYFiltros
        columns={columns}
        data={datosAtenciones?.sort((a, b) => b.id - a.id)}
        paginacion={false}
        estilosTablaDatos={
          'border border-cendiatra rounded-lg h-820 overflow-y-auto'
        }
      />
    </ContenedorPagina>
  )
}

export default ReporteDeAtencionDiaria
