import React, {useContext, useState} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import regenerarCierreCaja from '../../imagenes/menuGerenteGeneral/Ver-regenerar_cierre_de_caja.svg'
import FiltroRegenerarCierre from '../../componentes/gerenteGeneral/regenerarCierreCaja/FiltroRegenerarCierre'
import TablaRegenerarCierre from '../../componentes/gerenteGeneral/regenerarCierreCaja/TablaRegenerarCierre'
import {ContextApplication} from '../../contexto'
import {
  FachadaObtenerInformacionCierresPorSedeYFecha,
  GenerarCierresDeCaja,
} from '../../microservicios'
import moment from 'moment'

const RegenerarCierreCaja = () => {
  const contextoApp = useContext(ContextApplication)

  const [datos, setDatos] = useState([])

  const [datosBusqueda, setDatosBusqueda] = useState([])

  const [checkboxSeleccionado, setCheckboxSeleccionado] = useState(false)

  const [datosPayload, setDatosPayload] = useState({
    sedeId: 0,
    fechaCierre: '',
    nombreSede: '',
  })

  const [payloadRegenerar, setPayloadRegenerar] = useState([])

  const funcionBuscarInformacion = async (datos) => {
    setDatosBusqueda(datos)
    funcionLimpiarInformacionTabla()
    const {
      fechaCierreCaja,
      sede: {value: sedeValue, label: nombreSede},
      usuarioRecepcion: {value: correoValue},
    } = datos

    const payload = {
      sedeId: sedeValue || '',
      fecha: fechaCierreCaja || '',
      correoRecepcionista: correoValue || '',
    }

    const respuesta = await FachadaObtenerInformacionCierresPorSedeYFecha(
      payload.sedeId,
      payload.fecha,
      payload.correoRecepcionista
    )

    setDatosPayload({
      sedeId: sedeValue,
      fechaCierre: fechaCierreCaja,
      nombreSede: nombreSede,
    })

    setDatos(respuesta.data)
  }

  const funcionRegenerarCierre = async () => {
    const dataNueva = payloadRegenerar.map((dato) => {
      const payload = {
        cierreId: dato.id === 0 ? null : dato.id,
        correoRecepcionista: dato.correoRecepcionista,
        nombreRecepcionista: dato.nombreRecepcionista,
        ...datosPayload,
      }
      return payload
    })
    try {
      const respuesta = await GenerarCierresDeCaja(dataNueva)
      if (respuesta.status === 200) {
        modalRegenerarCierre(respuesta.data)
      }
    } catch (error) {
      console.error('Error al generar cierres de caja:', error)
    }
  }

  const actualizarSeleccionado = (datosSeleccionados) => {
    handleCheckboxChange(datosSeleccionados)
  }

  const funcionLimpiarInformacionTabla = () => {
    setPayloadRegenerar([])
    setDatosPayload([])
    setDatos([])
    setCheckboxSeleccionado(false)
  }

  const handleCheckboxChange = (datos) => {
    let seleccionados = payloadRegenerar
    datos.forEach((dato) => {
      if (dato.estaActivo) {
        seleccionados.push(dato)
      } else {
        let index = seleccionados.indexOf(dato)
        seleccionados.splice(index, 1)
      }
    })
    setPayloadRegenerar(seleccionados)
    if (seleccionados.length) {
      setCheckboxSeleccionado(true)
    } else {
      setCheckboxSeleccionado(false)
    }
  }

  const modalRegenerarCierre = (data) => {
    contextoApp.setModal({
      abierto: true,
      titulo: 'CIERRE DE CAJA REALIZADO',
      contenido: (
        <>
          <p className="text-cendiatra text-center flex-col w-full -mt-12">
            SE ENVIARÁ A SU CORREO ELECTRÓNICO
          </p>
          <div className="grid grid-cols-1 items-center justify-center mb-5 gap-5">
            {data.map((dato) => (
              <div className="justify-center text-left">
                <p className="text-cendiatra font-bold">
                  CÓDIGO DE CIERRE DE CAJA:{' '}
                  <span className="font-normal">{dato.id}</span>
                </p>
                <p className="text-cendiatra font-bold">
                  FECHA Y HORA DE SOLICITUD:{' '}
                  <span className="font-normal">
                    {dato.fechaSolicitud}
                  </span>
                </p>
                <p className="text-cendiatra font-bold">
                  FECHA DEL CIERRE:{' '}
                  <span className="font-normal">
                    {' '}
                    {dato.fecha}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: cerrarModal,
        },
      ],
    })
  }

  const cerrarModal = () => {
    contextoApp.setModal({abierto: false, botones: []})
    funcionBuscarInformacion(datosBusqueda)
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.GERENTE_GENERAL.REGENERAR_CIERRE_CAJA}
    >
      <div className="flex justify-center w-full py-10">
        <img src={regenerarCierreCaja} alt="Regenerar cierre de caja" />
      </div>

      <p className="font-bold text-red-900 text-center py-5">
        *Ten en cuenta que al usar este filtro la fecha máxima de consulta es de
        30 días atrás de la fecha actual.
      </p>

      <div className="w-full pb-10">
        <FiltroRegenerarCierre
          funcionBuscarInformacion={funcionBuscarInformacion}
          funcionRegenerarCierre={funcionRegenerarCierre}
          funcionLimpiarInformacionTabla={funcionLimpiarInformacionTabla}
          isChecked={checkboxSeleccionado}
        />
      </div>
      <div className="w-full">
        <TablaRegenerarCierre
          datosCierreCaja={datos}
          actualizarSeleccionado={actualizarSeleccionado}
        />
      </div>
    </ContenedorPagina>
  )
}

export default RegenerarCierreCaja
