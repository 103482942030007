import React from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import FormularioGeneradorDeInformes from '../../componentes/recepcionista/FormularioGeneradorDeInformes'

const GeneradorDeInformes = () => {
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA.GENERADOR_DE_INFORMES.TITULO
      }
    >
      <FormularioGeneradorDeInformes />
    </ContenedorPagina>
  )
}

export default GeneradorDeInformes
