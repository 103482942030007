import React, {useEffect, useState} from 'react'
import moment from 'moment/moment'
import {ContenedorPagina} from '../paginas/ContenedorPagina'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import {CeldaFormatoDinero} from '../Celdas'
import {Input} from '../inputs'
import {TEXTO_VISUAL} from '../../constantes'
import {FachadaObtenerEjecucionesEmpresas} from '../../microservicios'
import {CuadroTituloYDato} from '../MostrarInformacion'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const ReporteEjecucionPorEmpresa = () => {
  const [datosTabla, setDatosTabla] = useState([])
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const [totalPacientes, setTotalPacientes] = useState(0)
  const [valorTotal, setValorTotal] = useState(0)
  const [facturadoTotal, setFacturadoTotal] = useState(0)
  const [saldoTotal, setSaldoTotal] = useState(0)

  const sede = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)

  const fechaActual = moment().format('YYYY-MM-DD')
  let fechaMinima = moment().subtract(30, 'days').format('YYYY-MM-DD')

  const [fechaInicial, setFechaInicial] = useState(
    moment().format('YYYY-MM-DD')
  )
  const [fechaFinal, setFechaFinal] = useState(moment().format('YYYY-MM-DD'))

  function handleFechaInicialChange(e) {
    const selectedDate = moment(e.target.value).format('YYYY-MM-DD')
    setFechaInicial(selectedDate)
  }

  function handleFechaFinalChange(e) {
    const selectedDate = moment(e.target.value).format('YYYY-MM-DD')
    setFechaFinal(selectedDate)
  }

  const obtenerDatosReportesEmpresa = () => {
    FachadaObtenerEjecucionesEmpresas(
      sede,
      moment(fechaInicial).format('YYYY-MM-DD'),
      moment(fechaFinal).format('YYYY-MM-DD')
    )
      .then((res) => {
        if (res.status === 204) {
          setDatosTabla({})
        } else {
          setDatosTabla(res.data)
        }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    let componenteMontado = true
    if (componenteMontado && fechaInicial && fechaFinal) {
      obtenerDatosReportesEmpresa()
    }
    return () => {
      componenteMontado = false
    }
  }, [fechaInicial, fechaFinal])

  const actualizarDatosTabla = (filas) => {
    const sumaValoresPrecio = filas.reduce((acumulador, objeto) => {
      return acumulador + objeto.original.valor
    }, 0)
    const sumaValoresCantidadPacientes = filas.reduce((acumulador, objeto) => {
      return acumulador + objeto.original.cantidad
    }, 0)
    const sumaFacturado = filas.reduce((acumulador, objeto) => {
      return acumulador + objeto.original.facturadoCaja
    }, 0)
    const sumaSaldo = filas.reduce((acumulador, objeto) => {
      return acumulador + objeto.original.saldo
    }, 0)

    setValorTotal(sumaValoresPrecio)
    setTotalPacientes(sumaValoresCantidadPacientes)
    setFacturadoTotal(sumaFacturado)
    setSaldoTotal(sumaSaldo)
  }

  const column = [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Empresa'} />,
      accessor: 'empresa',
      className: 'w-1/5',
      headerStyle:
        'flex justify-center items-center bg-cendiatra-verde-5 text-white text-14px rounded-l-lg h-20',
      disableFilters: false,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      tipoTotal: 'titulo',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Total pacientes'} />,
      accessor: 'cantidad',
      className: 'w-1/5',
      headerStyle: 'bg-cendiatra-verde-7 text-white  h-20',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      tipoTotal: 'numero',
    },

    {
      Header: (row) => <EncabezadoFondo titulo={'Valor'} />,
      accessor: 'valor',
      className: 'w-1/5',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={`text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          valor={row?.row?.original?.valor}
        />
      ),
      tipoTotal: 'moneda',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Facturado en caja'} />,
      accessor: 'facturadoCaja',
      className: 'w-1/5',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={`text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          valor={row?.row?.original?.facturadoCaja}
        />
      ),
      tipoTotal: 'moneda',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Saldo'} />,
      accessor: 'saldo',
      className: 'w-1/5',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={`text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          valor={row?.row?.original?.saldo}
        />
      ),
      tipoTotal: 'moneda',
    },
  ]
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_JEFE_DE_SEDE.RESUMEN_POR_ENTIDAD
      }
    >
      <div className="w-full flex justify-start items-center gap-3">
        <Input
          titulo={'Fecha Inicial'}
          estilosInput={
            'appearance-none w-full  relative block px-3 py-2 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
          }
          estilosContenedor={'w-80'}
          funcion={handleFechaInicialChange}
          tipo={'date'}
          valorPorDefecto={fechaInicial}
          fechaMaxima={fechaFinal}
          fechaMinima={fechaMinima}
        />

        <Input
          titulo={'Fecha Final'}
          estilosInput={
            'appearance-none w-full relative block px-4 py-2 border border-cendiatra  text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
          }
          estilosContenedor={'w-80'}
          funcion={handleFechaFinalChange}
          tipo={'date'}
          valorPorDefecto={fechaFinal}
          fechaMaxima={fechaActual}
          fechaMinima={fechaInicial}
        />

        <div className="bg-cendiatra-gris-5 text-cendiatra py-2 px-4 mt-25px">
          {TEXTO_VISUAL.BARRA_NAVEGACION.MENU_JEFE_DE_SEDE.RESUMEN}
        </div>
      </div>
      <div className="w-full flex justify-between items-center my-5">
        <CuadroTituloYDato
          estilosContenedor={'w-3/12 pr-2 text-lg'}
          titulo={'Total de pacientes'}
          informacion={totalPacientes}
        />
        <CuadroTituloYDato
          estilosContenedor={'w-3/12 pl-2'}
          titulo={'Valor total'}
          informacion={
            <CeldaFormatoDinero
              estilos={`text-center appearance-none font-semibold rounded relative block w-full pointer-events-none text-cendiatra-gris-1 text-lg rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
              valor={valorTotal ?? 0}
            />
          }
        />
        <CuadroTituloYDato
          estilosContenedor={'w-3/12 pl-2'}
          titulo={'Facturado total'}
          informacion={
            <CeldaFormatoDinero
              estilos={`text-center appearance-none font-semibold rounded relative block w-full pointer-events-none text-cendiatra-gris-1 text-lg rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
              valor={facturadoTotal ?? 0}
            />
          }
        />
        <CuadroTituloYDato
          estilosContenedor={'w-3/12 pl-2'}
          titulo={'Saldo total'}
          informacion={
            <CeldaFormatoDinero
              estilos={`text-center appearance-none font-semibold rounded relative block w-full pointer-events-none text-cendiatra-gris-1 text-lg rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
              valor={saldoTotal ?? 0}
            />
          }
        />
      </div>
      <TablaConPaginacionYFiltros
        data={datosTabla?.reporteEjecucionesEmpresas ?? []}
        columns={column}
        paginacion={true}
        funcionObtenerDatos={actualizarDatosTabla}
      />
    </ContenedorPagina>
  )
}

export default ReporteEjecucionPorEmpresa
