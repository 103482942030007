import * as htmlToImage from 'html-to-image'
import {SubirArchivo} from '../microservicios/Archivos'

export const dataURLtoFile = async (url, filename, mimeType) => {
  const res = await fetch(url)
  const buf = await res.arrayBuffer()
  return new File([buf], filename, {type: mimeType})
}

export const convertirHtmlAImagen = async (nodo, nombreImagen) => {
  const dataUrl = await htmlToImage.toJpeg(nodo)
  const img = new Image()
  img.src = dataUrl
  const archivo = await dataURLtoFile(
    img.src,
    nombreImagen + '.jpg',
    'image/jpeg'
  )
  return archivo
}

export const transformarImagen = async (nodo, nombreImagen) => {
  try {
    const archivo = await convertirHtmlAImagen(nodo, nombreImagen)
    if (archivo.size === 0) return ''
    const reader = new FileReader()
    reader.readAsDataURL(archivo)

    reader.onload = async () => {
      const formData = new FormData()
      formData.append('Archivo', archivo, archivo.name)
      try {
        const res = await SubirArchivo(archivo.name, formData)
        return res.data
      } catch (error) {
        return ''
      }
    }
  } catch (error) {
    return ''
  }
}

export const subirImagenHTMLApp = async (archivo) => {
  try {
    if (archivo.size === 0) {
      return ''
    }
    const fd = new FormData()
    fd.append('Archivo', archivo, archivo.name)
    const respuesta = await SubirArchivo(archivo.name, fd)
    return respuesta?.data ?? ''
  } catch (error) {
    return ''
  }
}
