import BotonConImagen from '../BotonConImagen'
import IconoEditarActivado from '../../imagenes/EditarVerdeConIconoRecargar.svg'
import IconoEditarDesactivado from '../../imagenes/EditarGrisConIconoRecargar.svg'
import iconoEditar from '../../imagenes/iconoEditarVerde.svg'
import iconoEditarGris from '../../imagenes/iconoEditarGris.svg'

function CeldaModulosControlVersionPlantillas({row, funcionAccion}) {
  return (
    <div className="w-full flex flex-wrap">
      <div className="w-1/2">
        <BotonConImagen
          imagen={
            row.original?.ultimaVersion?.esBorrador
              ? iconoEditar
              : iconoEditarGris
          }
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
          funcionAEjecutar={() => funcionAccion(row, 'borrador')}
          desabilitado={!row.original?.ultimaVersion?.esBorrador}
        />
      </div>
      <div className="w-1/2">
        <BotonConImagen
          imagen={
            !row.original?.ultimaVersion?.esBorrador
              ? IconoEditarActivado
              : IconoEditarDesactivado
          }
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
          funcionAEjecutar={() => funcionAccion(row, 'crear')}
          desabilitado={row.original?.ultimaVersion?.esBorrador}
        />
      </div>
    </div>
  )
}

export default CeldaModulosControlVersionPlantillas
