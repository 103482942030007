import React, {useState, createContext} from 'react'

export const ContextoCierreCaja = createContext({})

export const CierreCajaConContexto = ({children}) => {
  const [inputsHabilitados, setInputsHabilitados] = useState(true)
  const [errorFormulario, setErrorFormulario] = useState(true)
  const [cierreCaja, setCierreCaja] = useState({})
  const [valoresCalculados, setValoresCalculados] = useState({})
  const [actualizar, setActualizar] = useState(false)

  return (
    <ContextoCierreCaja.Provider
      value={{
        inputsHabilitados: inputsHabilitados,
        setInputsHabilitados: setInputsHabilitados,
        errorFormulario: errorFormulario,
        setErrorFormulario: setErrorFormulario,
        cierreCaja: cierreCaja,
        setCierreCaja: setCierreCaja,
        valoresCalculados: valoresCalculados,
        setValoresCalculados: setValoresCalculados,
        actualizar: actualizar,
        setActualizar: setActualizar,
      }}
    >
      {children}
    </ContextoCierreCaja.Provider>
  )
}
