import {getRestApi} from './ApiAxios'

export const registrarPaciente = async (registro) => {
  const apiReq = await getRestApi()
  return await apiReq.put('atencion-function/CrearAtencion', registro)
}

export const ObtenerUltimaDireccionDeAtencionPorUsuarioId = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ObtenerUltimaDireccionDeAtencionPorUsuarioId?usuarioId=' +
      id
  )
}

export const GenerarComprobanteAtencion = async (infoComprobante) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'atencion-function/GenerarComprobanteAtencion',
    infoComprobante
  )
}

export const GenerarComprobanteAtencionEmpresaCredito = async (
  infoComprobante
) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'atencion-function/GenerarComprobanteAtencionEmpresaCredito',
    infoComprobante
  )
}

export const ObtenerInformacionUltimaAtencionPorUsuarioId = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ObtenerInformacionUltimaAtencionPorUsuarioId?usuarioId=' +
      id
  )
}

export const ObtenerAtencionConTiquetera = async (voucher) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ObtenerAtencionConTiquetera?voucher=' + voucher
  )
}

export const ObtenerAtencionConBono = async (bono) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ObtenerAtencionConBono?voucher=' + bono
  )
}

export const ValidarAtencionesPorTarifarioIdYServicioId = async (
  tarifarioId,
  servicioId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ValidarAtencionesPorTarifarioIdYServicioId?tarifarioId=' +
      tarifarioId +
      '&servicioId=' +
      servicioId
  )
}

export const ValidarAtencionesPorTarifarioIdYPaqueteId = async (
  tarifarioId,
  paqueteId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ValidarAtencionesPorTarifarioIdYPaqueteId',
    {
      params: {
        tarifarioId,
        paqueteId,
      },
    }
  )
}

export const CambiarEnProcesoServicioAtencion = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'atencion-function/CambiarEnProcesoServicioAtencion?servicioAtencionId=' +
      servicioAtencionId
  )
}

export const ObtenerLaboratoriosPendientesPorCerrarPorSedeId = async (
  laboratoriosDeHoy,
  sedeId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ObtenerLaboratoriosPendientesPorCerrarPorSedeId?fechaActual=' +
      laboratoriosDeHoy +
      '&sedeId=' +
      sedeId
  )
}

export const CrearSolicitudAtencion = async ({
  atencionId,
  serviciosAtencion,
  sedeId,
  motivo,
}) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'put',
    url: 'atencion-function/CrearSolicitud',
    data: {atencionId, serviciosAtencion, sedeId, motivo},
  })
}

export const RespoderSolicitudModficacion = async (atencion) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'atencion-function/RespoderSolicitudModficacion',
    atencion
  )
}

export const ObtenerEstadoAtencion = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('atencion-function/ObtenerEstadoAtencion', {
    params: {atencionId},
  })
}

export const CambiarEnProcesoCancelacionAtencion = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'atencion-function/CambiarEnProcesoCancelacionAtencion',
    atencionId
  )
}

export const ObtenerReporteEjecucionSedePorFecha = async (sedeId, fecha) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'atencion-function/ObtenerReporteEjecucionSedePorFecha',
    {
      params: {
        sedeId: sedeId,
        fecha,
      },
    }
  )
}
