import React, {useEffect, useState} from 'react'
import {BotonSimple} from '../../Botones'

const PaginacionDinamica = ({
  filasPorPagina,
  opcionesDePaginas,
  index,
  funcionIrAPagina,
  datos,
  canNextPage,
}) => {
  const [conjuntoNumerosPaginacion, setConjuntoNumerosPaginacion] = useState(1)
  const [numerosParaMostrar, setNumerosParaMostrar] = useState([])

  useEffect(() => {
    const crearArrayNumeroDePaginas = () => {
      const inicio = (conjuntoNumerosPaginacion - 1) * filasPorPagina
      const numerosAMostrar = opcionesDePaginas.slice(
        inicio,
        inicio + filasPorPagina
      )
      setNumerosParaMostrar(numerosAMostrar)
    }
    crearArrayNumeroDePaginas()
  }, [datos, opcionesDePaginas, conjuntoNumerosPaginacion])

  const retrocederEnLista = () => {
    if (conjuntoNumerosPaginacion > 1) {
      setConjuntoNumerosPaginacion(conjuntoNumerosPaginacion - 1)
      const inicio = (conjuntoNumerosPaginacion - 2) * filasPorPagina
      const numerosAMostrar = opcionesDePaginas.slice(
        inicio,
        inicio + filasPorPagina
      )
      setNumerosParaMostrar(numerosAMostrar)
    }
  }
  const avanzarEnLista = () => {
    const totalPaginas = Math.ceil(opcionesDePaginas.length / filasPorPagina)
    if (conjuntoNumerosPaginacion < totalPaginas) {
      setConjuntoNumerosPaginacion(conjuntoNumerosPaginacion + 1)
    }
  }
  const irAlInicio = () => {
    setConjuntoNumerosPaginacion(1)
  }

  const irAlFinal = () => {
    const totalPaginas = Math.ceil(opcionesDePaginas.length / filasPorPagina)
    setConjuntoNumerosPaginacion(totalPaginas)
    const inicio = (totalPaginas - 1) * filasPorPagina
    const numerosAMostrar = opcionesDePaginas.slice(
      inicio,
      inicio + filasPorPagina
    )
    setNumerosParaMostrar(numerosAMostrar)
  }

  return (
    <div className="w-full flex justify-center my-5">
      <BotonSimple
        texto={'«'}
        estilosBoton={'text-5xl text-gray-400 pb-2 mx-1'}
        funcion={() => irAlInicio()}
      />
      <BotonSimple
        texto={'‹'}
        estilosBoton={'text-5xl text-gray-400 pb-2 mx-1'}
        funcion={() => retrocederEnLista()}
      />
      {numerosParaMostrar.map((elemento) => (
        <div
          key={elemento}
          className={
            index === elemento
              ? 'z-10 bg-cendiatra border-cendiatra text-white relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full '
              : '  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full'
          }
          onClick={() => funcionIrAPagina(elemento)}
        >
          {elemento + 1}
        </div>
      ))}
      <BotonSimple
        texto={'›'}
        estilosBoton={'text-5xl text-gray-400 pb-2 mx-1'}
        funcion={() => avanzarEnLista()}
        disabled={!canNextPage}
      />
      <BotonSimple
        texto={'»'}
        estilosBoton={'text-5xl text-gray-400 pb-2 mx-1'}
        funcion={() => irAlFinal()}
        disabled={!canNextPage}
      />
    </div>
  )
}

export default PaginacionDinamica
