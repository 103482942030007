import {useForm, Controller} from 'react-hook-form'
import {BotonSimple} from '../../Botones'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import {useState} from 'react'
import {ObtenerEmpresasPorNitONombreFiltro} from '../../../cache/servicios-cache'

const defaultValues = {
  nombreEmpresa: '',
  empresa: null,
}

export default function FormularioBusquedaEmpresa({fnBusqueda, fnResetear}) {
  const form = useForm({mode: 'onChange', defaultValues})
  const [empresasFiltradas, setEmpresasFiltradas] = useState([])
  const nombreEmpresa = form.watch('nombreEmpresa')
  const empresa = form.watch('empresa')

  const filtrarEmpresas = (evt) => {
    form.setValue('nombreEmpresa', evt.target?.value ?? '')
    if (evt.target?.value?.length >= 3) {
      ObtenerEmpresasPorNitONombreFiltro(evt.target.value).then((res) => {
        const nuevoArray = []
        res.forEach((elemento) => {
          let objeto = {
            id: elemento.id,
            nombre: elemento.filtro,
            filtro: elemento.filtro,
          }
          nuevoArray.push(objeto)
        })
        setEmpresasFiltradas(nuevoArray)
      })
    } else {
      if (!evt?.target?.value?.length) {
        form.setValue('empresa', null)
      }
      setEmpresasFiltradas([])
    }
  }

  return (
    <div className="w-full flex flex-wrap gap-2">
      <div className="w-4/12">
        <Controller
          name="empresa"
          control={form.control}
          rules={{
            required: true,
          }}
          render={() => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-12/12'}
              titulo={'Empresa (Nit) o razón social*'}
              estilosInput={
                empresa ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
              }
              tipo={'text'}
              placeholder={'AUTOCOMPLETAR (EDITABLE)'}
              valorDelCampoFiltro={nombreEmpresa}
              desactivarOtroFiltro={''}
              handleChange={filtrarEmpresas}
              handleOptionChange={(opt) => {
                form.setValue('empresa', opt ?? null)
                form.setValue('nombreEmpresa', opt?.nombre ?? '')
              }}
              informacionFiltrada={empresasFiltradas}
            />
          )}
        />
      </div>
      <div className="w-4/12 mt-5">
        <BotonSimple
          texto={'Buscar'}
          deshabilitado={!!!empresa}
          estilosBoton={`w-28 h-8 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-xl text-14px m-2`}
          funcion={() => fnBusqueda(empresa?.id ?? empresa?.Id)}
        />
        <BotonSimple
          deshabilitado={!!!empresa && !nombreEmpresa}
          texto={'Limpiar'}
          estilosBoton={`${
            !!empresa || nombreEmpresa
              ? 'w-28 h-8 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-xl text-14px m-2'
              : 'w-28 h-8 text-white bg-cendiatra-gris-placeholder bg-center bg-cover bg-no-repeat rounded-xl text-14px m-2'
          } `}
          funcion={() => {
            form.reset()
            fnResetear()
            setEmpresasFiltradas([])
          }}
        />
      </div>
    </div>
  )
}
