import {useEffect, useMemo} from 'react'
import {Controller, useForm} from 'react-hook-form'
import TablaDinamica from '../TablaDinamica'
import ErrorAudiometria from './ErrorAudiometria'

export default function TablaAudiometriaTamiz({
  datos = [],
  manejarCambioInputOChecks,
  watch,
  resetear,
  setResetear,
}) {
  const {
    control,
    formState: {errors},
    trigger,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [errors, trigger, manejarCambioInputOChecks])

  const columnas = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'nombre',
        className:
          'w-1/12 flex text-center text-black font-bold justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return row.original.nombre
        },
      },
      {
        Header: '250',
        accessor: 'F250',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F250${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F250${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F250${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                  onChange: (evt) =>
                    manejarCambioInputOChecks(
                      {
                        propiedad: 'F250',
                        valor: evt.target.value,
                      },
                      row.index
                    ),
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F250',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: 'F250SR',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F250SR']}
              name={'250'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F250SR',
                    valor: !row.original['F250SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
      {
        Header: '500',
        accessor: 'F500',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F500${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F500${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F500${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F500',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: 'F500SR',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F500SR']}
              name={'500'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F500SR',
                    valor: !row.original['F500SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
      {
        Header: '1K',
        accessor: 'F1000',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F1000${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F1000${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F1000${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F1000',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: 'F1000SR',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F1000SR']}
              name={'1k'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F1000SR',
                    valor: !row.original['F1000SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
      {
        Header: '2K',
        accessor: 'F2000',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F2000${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F2000${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F2000${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F2000',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: 'F2000SR',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F2000SR']}
              name={'2k'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F2000SR',
                    valor: !row.original['F2000SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
      {
        Header: '3K',
        accessor: 'F3000',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F3000${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F3000${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F3000${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F3000',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: '3k.sr',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F3000SR']}
              name={'3k'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F3000SR',
                    valor: !row.original['F3000SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
      {
        Header: '4K',
        accessor: 'F4000',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F4000${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F4000${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F4000${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F4000',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: '4k.sr',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F4000SR']}
              name={'4k'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F4000SR',
                    valor: !row.original['F4000SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
      {
        Header: '6K',
        accessor: 'F6000',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F6000${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F6000${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F6000${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F6000',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: 'F6000SR',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F6000SR']}
              name={'6k'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F6000SR',
                    valor: !row.original['F6000SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
      {
        Header: '8K',
        accessor: 'F8000',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <>
              <span className="text-red-500 w-full">
                {errors[`F8000${row.index}`]?.type === 'pattern' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar multiplos de 5'}
                  />
                )}

                {errors[`F8000${row.index}`]?.type === 'max' && (
                  <ErrorAudiometria
                    mostrarError={true}
                    error={'Solo puede ingresar hasta 120Db'}
                  />
                )}
              </span>

              <Controller
                name={`F8000${row.index}`}
                control={control}
                rules={{
                  required: false,
                  pattern: /^\d*[05]$/,
                  max: 120,
                }}
                render={({field: {value}}) => (
                  <input
                    type="number"
                    value={value}
                    className="text-center border border-gray-400 rounded-sm w-12 px-3"
                    onChange={(evt) => {
                      manejarCambioInputOChecks(
                        {
                          propiedad: 'F8000',
                          valor: evt.target.value,
                        },
                        row.index
                      )
                    }}
                  />
                )}
              />
            </>
          )
        },
      },
      {
        Header: 'S.R',
        accessor: 'F8000SR',
        className:
          'w-1/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
        Cell: ({row}) => {
          return (
            <input
              type={'checkbox'}
              className="checkboxEspecial personalizado h-22px"
              checked={row.original['F8000SR']}
              name={'8k'}
              onChange={(evt) =>
                manejarCambioInputOChecks(
                  {
                    propiedad: 'F8000SR',
                    valor: !row.original['F8000SR'],
                    tipo: evt.target.type,
                  },
                  row.index
                )
              }
            />
          )
        },
      },
    ]
  }, [errors])

  useEffect(() => {
    const subscripcion = watch((informacion) => {
      if (informacion?.datosAudiograma?.length > 0) {
        for (const key in getValues()) {
          const nombre = key.endsWith('0') ? 'OD' : 'OI'
          let valorFrecuencia =
            informacion?.datosAudiograma?.find(
              (dato) => dato.nombre === nombre
            ) ?? null

          setValue(
            key,
            valorFrecuencia[key.substring(0, key.length - 1)] ?? undefined
          )
        }
      }
    })
    return () => subscripcion.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (resetear) {
      reset()
      setResetear(false)
    }
  }, [datos, resetear])

  return (
    <div className="w-full">
      <TablaDinamica
        columns={columnas}
        data={datos}
        tableClass="Tablaaudiometria"
      />
    </div>
  )
}
