import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
  EncabezadoFondo,
  EncabezadoConSubTitulos,
  TablaConPaGinacionYFiltros,
} from '../Tablas'
import {InputFiltroFecha} from '../inputs'
import {CuadroInformativoConSumatoria} from '../MostrarInformacion'
import {FiltroListaSeleccionar} from '../Filtros'
import BotonConImagen from '../BotonConImagen'
import {
  CeldaDobleTexto,
  CeldaFormatoDinero,
  CeldaConEventoClick,
} from '../Celdas'
import {
  FachadaObtenerFacturas,
  FachadaObtenerConteoDeEstadosFactura,
  FachadaGenerarReciboCajaAtencion,
  GenerarDocumentoFactura,
  ObtenerDocumentoPDF,
} from '../../microservicios'
import {ObtenerEstadoFacturaPorId} from '../../cache/servicios-cache'
import imprimirHabilitado from '../../imagenes/iconoImpresoraCuadradoVerde.svg'
import imprimirDeshabilitado from '../../imagenes/iconoImprimirGrisCuadrado.svg'
import moment from 'moment/moment'
import {RUTAS_APP} from '../../constantes'
import {encabezadosFacturas} from '../CSV/EncabezadosArchivos'
import {base64ABlob} from '../../utilidades'
import {ESTADOS_FACTURA} from '../../constantes'

function CeldaModulosDocumentacion({modulos}) {
  return (
    <div className="w-full flex justify-evenly items-center">
      {modulos.map((modulo) => (
        <div className="w-auto">
          <BotonConImagen
            imagen={
              modulo.habilitado
                ? modulo.imagenHabilitada
                : modulo.imagenDeshabilitada
            }
            textoAlternativo={modulo.textoAlternativo}
            funcionAEjecutar={modulo.funcion}
            estilosImagen={'w-10 h-10 content-start justify-start'}
            estilosContenedor={`h-10 content-start flex-col pb-5 ${
              modulo.habilitado ? '' : 'pointer-events-none'
            }`}
          />
        </div>
      ))}
    </div>
  )
}

const DocumentacionElectronica = () => {
  const [datosFacturas, setDatosFacturas] = useState([])

  const validacionFechaMinima = moment()
    .subtract(30, 'days')
    .format('YYYY-MM-DD')
  const validacionFechaMaxima = moment().format('YYYY-MM-DD')

  const [fechaInicialEstado, setFechaInicialEstado] = useState(
    validacionFechaMinima
  )

  const [fechaFinalEstado, setFechaFinalEstado] = useState(
    validacionFechaMaxima
  )

  const [mensajeFiltro, setMensajeFiltro] = useState(true)

  const [fechaMinima, setFechaMinima] = useState(validacionFechaMinima)
  const [fechaMaxima, setFechaMaxima] = useState(validacionFechaMaxima)

  const [datosCantidadEstadosFactura, setDatosCantidadEstadosFactura] =
    useState([])

  const history = useHistory()

  const abrirDocumentoEnNuevaVentana = (url, nombreDocumento) => {
    const newWindow = window.open(url, '_blank')
    newWindow.addEventListener('load', () => {
      newWindow.document.title = nombreDocumento
    })
  }

  const obtenerDocumentoPDFNotaCredito = async (id, nombreFactura) => {
    try {
      const respuesta = await ObtenerDocumentoPDF(id)
      if (respuesta.status === 200 && respuesta.data?.documento) {
        const base64Content = respuesta.data.documento
        const arrayBuffer = base64ABlob(base64Content)
        const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)

        const newWindow = window.open(url, '_blank')
        newWindow.addEventListener('load', () => {
          newWindow.document.title = nombreFactura
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const obtenerDocumentoPDFDeFactura = async (
    id,
    atencionId,
    estadoFacturaId,
    nombreDocumento
  ) => {
    try {
      let respuesta

      if (estadoFacturaId !== 3) {
        respuesta = await FachadaGenerarReciboCajaAtencion({atencionId})
      } else if (/^CENGT/.test(nombreDocumento) && estadoFacturaId === 3) {
        return await obtenerDocumentoPDFNotaCredito(id, nombreDocumento)
      } else {
        respuesta = await GenerarDocumentoFactura({facturaId: id})
      }

      if (respuesta.status === 200 && respuesta?.data) {
        abrirDocumentoEnNuevaVentana(respuesta.data, nombreDocumento)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const esRangoDeUnMes = (fechaInicial, fechaFinal) => {
    const momentFechaInicial = moment(fechaInicial)
    const momentFechaFinal = moment(fechaFinal)

    const diferenciaEnDias = momentFechaFinal.diff(momentFechaInicial, 'days')

    return diferenciaEnDias <= 30
  }

  const verificarNumeroFactura = (numeroFactura) => {
    return Boolean(/^CENGT/.test(numeroFactura))
  }

  const obtenerTodosLosDatos = () => {
    if (esRangoDeUnMes(fechaInicialEstado, fechaFinalEstado)) {
      FachadaObtenerFacturas(fechaInicialEstado, fechaFinalEstado).then(
        async (res) => {
          if (res.status !== 204) {
            let facturas = await Promise.all(
              res.data.map(async (factura) => {
                const estadoEmision =
                  (await ObtenerEstadoFacturaPorId(factura.estadoFacturaId))
                    .Nombre ?? ''
                return {
                  id: factura.id,
                  atencionId: factura.atencionId,
                  nombreDocumento: factura.numeroFactura,
                  sede: factura.nombreSede,
                  fechaEmision: moment(factura.fechaEmision).format(
                    'YYYY-MM-DD'
                  ),
                  nombreTercero: {
                    Fila1: factura.identificacion,
                    Fila2: factura.nombreCliente,
                  },
                  valorTotal: factura.montoTotal,
                  estadoEmision: estadoEmision,
                  errorTransmision: factura.cantidadErroresFactura,
                  numeroNotaCredito: factura.numeroNotaCredito,
                  modulos: [
                    {
                      imagenHabilitada: imprimirHabilitado,
                      imagenDeshabilitada: imprimirDeshabilitado,
                      textoAlternativo: 'imprimir',
                      funcion: () =>
                        obtenerDocumentoPDFDeFactura(
                          factura.id,
                          factura.atencionId,
                          factura.estadoFacturaId,
                          factura.numeroFactura
                        ),
                      habilitado: Boolean(
                        (!verificarNumeroFactura(factura.numeroFactura) &&
                          factura.estadoFacturaId !==
                            ESTADOS_FACTURA.NO_ENVIADA) ||
                          (verificarNumeroFactura(factura.numeroFactura) &&
                            factura.estadoFacturaId ===
                              ESTADOS_FACTURA.DOCUMENTO_GENERADO)
                      ),
                    },
                  ],
                }
              })
            )
            setDatosFacturas(facturas)
          }
        }
      )
      setMensajeFiltro(true)
    } else {
      setMensajeFiltro(false)
    }
  }

  const obtenerDatosEstadosFactura = () => {
    if (esRangoDeUnMes(fechaInicialEstado, fechaFinalEstado)) {
      FachadaObtenerConteoDeEstadosFactura(
        fechaInicialEstado,
        fechaFinalEstado
      ).then((res) => {
        setDatosCantidadEstadosFactura([
          {
            titulo: 'ENVIADO PENDIENTE DE RESPUESTA',
            cantidad: res.data.enviadoPendienteRespuesta.cantidad ?? 0,
            total: res.data.enviadoPendienteRespuesta.valorTotal ?? 0,
          },
          {
            titulo: 'DOCUMENTO GENERADO',
            cantidad: res.data.documentoGenerado.cantidad ?? 0,
            total: res.data.documentoGenerado.valorTotal ?? 0,
          },
          {
            titulo: 'DOCUMENTO NO GENERADO REQUIERE CORRECCIÓN',
            cantidad: res.data.requiereCorreccion.cantidad ?? 0,
            total: res.data.requiereCorreccion.valorTotal ?? 0,
          },
          {
            titulo: 'DOCUMENTO GENERADO PENDIENTE CUFE (DIAN) ',
            cantidad: res.data.pendienteCUFE.cantidad ?? 0,
            total: res.data.pendienteCUFE.valorTotal ?? 0,
          },
        ])
      })
    }
  }

  useEffect(() => {
    obtenerDatosEstadosFactura()
  }, [fechaInicialEstado, fechaFinalEstado])

  useEffect(() => {
    obtenerTodosLosDatos()
  }, [fechaInicialEstado, fechaFinalEstado])

  const abrirCorregirErroresEnFactura = (id) => {
    history.push(
      RUTAS_APP.GESTION_FACTURACION
        .CORRECCION_ERRORES_TRANSMISION_FACTURA_ELECTRONICA,
      {id: id}
    )
  }

  let columns = [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Documento electrónico'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'nombreDocumento',
      className: 'w-11%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-l-lg pl-1',
      disableFilters: false,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Sede'} />,
      accessor: 'sede',
      className: 'w-11%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Fecha emisión electrónica'} />,
      accessor: 'fechaEmision',
      className: 'w-10%',
      headerStyle:
        "flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px'",
      disableFilters: true,
      filter: 'fechaCustom',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Identificación y nombre tercero'} />
      ),
      accessor: 'nombreTercero',
      className: 'w-12%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 ',
      disableFilters: false,
      filter: 'dobleTexto',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => <CeldaDobleTexto datos={row.row.original.nombreTercero} />,
    },

    {
      Header: (row) => <EncabezadoFondo titulo={'Valor Total'} />,
      accessor: 'valorTotal',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={`text-center appearance-none ${
            row.row.original.errorTransmision > 0 ? 'bg-red-200' : ''
          } rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          valor={row.row.original.valorTotal}
        />
      ),
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Estado de emisión electrónica'} />
      ),
      accessor: 'estadoEmision',
      className: 'w-11%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 ',
      Filter: FiltroListaSeleccionar,
      filter: 'lista',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Descripción error transmisión y/o rechazada'}
        />
      ),
      accessor: 'errorTransmision',
      className: 'w-13% ',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 ',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: ({row: {original}, value}) => (
        <CeldaConEventoClick
          texto={Boolean(value > 0) ? ` Ver ${value} errores` : ''}
          manejarClick={() => abrirCorregirErroresEnFactura(original.id)}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nota Crédito'} />,
      accessor: 'numeroNotaCredito',
      className: 'w-11%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: false,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoConSubTitulos titulo={'Módulos'} subtitulo1={'Imprimir'} />
      ),
      accessor: 'modulos',
      className:
        'w-11% bg-white h-full flex justify-center items-center h-full',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-r-lg',
      disableFilters: true,
      Cell: (row) => (
        <CeldaModulosDocumentacion modulos={row.row.original.modulos} />
      ),
    },
  ]

  const actualizarFecha = (valor, fechaInicial) => {
    if (fechaInicial) {
      setFechaInicialEstado(valor)
      setFechaMaxima(moment().format('YYYY-MM-DD'))
      if (moment(valor).isAfter(fechaFinalEstado)) {
        setFechaFinalEstado('')
        const inputFechaFinal = document.getElementById('fechaFinal')
        inputFechaFinal.value = ''
      }
      setFechaMinima(valor)
    } else {
      setFechaFinalEstado(valor)
      setFechaMinima(moment(valor).subtract(1, 'month').format('YYYY-MM-DD'))
      if (moment(valor).isBefore(fechaInicialEstado)) {
        setFechaInicialEstado('')
        const inputFechaInicial = document.getElementById('fechaInicial')
        inputFechaInicial.value = ''
        setFechaMaxima(valor)
      }
    }
  }

  useEffect(() => {
    const fechaActual = moment()
    setFechaMaxima(fechaActual.format('YYYY-MM-DD'))
  }, [])

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <div className="w-full flex flex-wrap justify-between items-start">
        <div className="w-3/12 flex flex-col gap-3 items-center rounded-lg border border-cendiatra p-3">
          <div className="flex flex-row justify-between w-full mt-5">
            <InputFiltroFecha
              titulo={'Fecha Inicial'}
              funcion={(e) => actualizarFecha(e.target.value, true)}
              fechaMaxima={fechaMaxima}
              valorPorDefecto={fechaInicialEstado}
              id={'fechaInicial'}
            />
            <InputFiltroFecha
              titulo={'Fecha Final'}
              funcion={(e) => actualizarFecha(e.target.value, false)}
              fechaMaxima={fechaMaxima}
              fechaMinima={fechaInicialEstado}
              valorPorDefecto={fechaFinalEstado}
              id={'fechaFinal'}
            />
          </div>
          <p
            className={`${
              mensajeFiltro ? 'text-cendiatra-gris-1' : 'text-red-500'
            } flex flex-row mt-5`}
          >
            <span className=" text-cendiatra text-2xl">*</span>Ten en cuenta que
            al usar este filtro el intervalo máximo de consulta es de 30 días
            entre la fecha inicial y la fecha final¨
          </p>
        </div>
        <div className="w-5/12 flex justify-between items-center">
          <CuadroInformativoConSumatoria datos={datosCantidadEstadosFactura} />
        </div>
        <div className="w-3/12 h-48 flex flex-wrap justify-center content-end text-center"></div>
      </div>
      <TablaConPaGinacionYFiltros
        data={datosFacturas}
        columns={columns}
        paginacion={true}
        rowProps={(row) => ({
          style: {
            backgroundColor: row.original.errorTransmision > 0 ? '#fecaca' : '',
          },
        })}
        habilitarExportarCsv={true}
        encabezados={encabezadosFacturas}
        fechaInicial={fechaInicialEstado}
        fechaFinal={fechaFinalEstado}
      />
    </div>
  )
}

export default DocumentacionElectronica
