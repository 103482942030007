import TablaContabilidad from '../../../componentes/Contabilidad/TablaContabilidad'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../../constantes'

function Contabilidad() {
  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.CONTABILIDAD.TITULO_PAGINA}
      estilosContenedor={'w-95%'}
    >
      <TablaContabilidad />
    </ContenedorPagina>
  )
}

export default Contabilidad
