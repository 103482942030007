import {useEffect, useMemo, useState} from 'react'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'

function FiltroColumnaAutoCompletar({
  column: {filterValue, setFilter, preFilteredRows, id},
  estilos = '',
}) {
  const [valor, setValor] = useState('')
  const [seleccionado, setSeleccionado] = useState(false)

  const opciones = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      if (
        row.values[id]?.toUpperCase().includes(valor.toUpperCase()) &&
        valor
      ) {
        options.add(row.values[id])
      }
    })
    return [...options.values()]
  }, [valor, preFilteredRows])

  useEffect(() => {
    if (filterValue === undefined && seleccionado) {
      setValor('')
      setSeleccionado(false)
    }
  }, [filterValue])

  return (
    <CampoFiltrarEspañol
      titulo={''}
      estilosPersonalizados={'w-11/12'}
      estilosInput={`text-black ${estilos}`}
      placeholder={'DESCRIPCIÓN'}
      tipo="text"
      desactivarOtroFiltro={false}
      informacionFiltrada={opciones.map((opt, idx) => ({
        id: idx + 1,
        filtro: opt,
      }))}
      handleChange={(evt) => setValor(evt.currentTarget.value)}
      valorDelCampoFiltro={valor}
      estilosOpcion="text-black"
      handleOptionChange={(evt) => {
        setFilter(evt?.filtro ?? '')
        setValor(evt?.filtro ?? '')
        setSeleccionado(true)
      }}
    />
  )
}

export default FiltroColumnaAutoCompletar
