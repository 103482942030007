import ReactInputMask from 'react-input-mask'
import Input from './Input'

export default function InputDecimal({
  onValueChange,
  estilosContenedor,
  estilosInput,
  placeholder,
  titulo,
  deshabilitado,
  valor,
  ayudaDeTexto,
  onBlur = () => {},
  readOnly = false,
}) {
  const cambioValorPeroEnviarComoFloatValue = (evt) => {
    onValueChange({floatValue: evt.target.value?.replace(',', '.')})
  }

  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      {titulo ? (
        <label className="block text-13px text-cendiatra my-5px w-full">
          {titulo}
        </label>
      ) : null}
      <ReactInputMask
        mask={'9,99'}
        maskChar={null}
        type="text"
        value={valor}
        className={'w-full'}
        onChange={cambioValorPeroEnviarComoFloatValue}
        disabled={deshabilitado}
        placeholder={placeholder}
        onBlur={onBlur}
        readOnly={readOnly}
      >
        {() => {
          return (
            <Input
              estilosInput={estilosInput}
              estilosContenedor={'w-full'}
              readOnly={readOnly}
            />
          )
        }}
      </ReactInputMask>
      {ayudaDeTexto ? (
        <label className="block text-13px text-cendiatra my-5px">
          {ayudaDeTexto}
        </label>
      ) : null}
    </div>
  )
}
