import React from 'react'

const BarraMensajeInformativo = ({mensaje}) => {
  return (
    <div className="bg-white w-full flex justify-center items-center p-4 rounded rounded-t-3xl rounded-b-3xl border border-opacity-50 border-cendiatra shadow-2xl">
      <div className="w-11/12 flex justify-center items-center bg-white py-4 rounded-t-3xl rounded-b-3xl">
        <span className="w-full text-center text-cendiatra font-bold ">
          {mensaje}
        </span>
      </div>
    </div>
  )
}

export default BarraMensajeInformativo
