import React, {useContext, useEffect} from 'react'
import FormularioSeleccionSede from '../../componentes/Navegacion/FormularioSeleccionSede'
import {ContextApplication} from '../../contexto'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const Seleccion = ({SeleccionSede}) => {
  const contextoAplicacion = useContext(ContextApplication)
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  useEffect(() => {
    const sedeId = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
    const recepcionId = fnObtenerInformacionSessionOLocalStorage(
      'recepcionId',
      rol
    )
    const consultorioId = fnObtenerInformacionSessionOLocalStorage(
      'consultorioId',
      rol
    )
    if (sedeId && (recepcionId || consultorioId)) {
      contextoAplicacion.setSede({
        ...contextoAplicacion.sede,
        sedeId: sedeId,
        consultorioId: consultorioId,
        recepcionId: recepcionId,
      })
    }
  }, [rol])

  let componenteSeleccionSede = null
  switch (SeleccionSede) {
    case 'Consultorio':
      componenteSeleccionSede = (
        <FormularioSeleccionSede
          textoDinamico={'Consultorio'}
          objetoDinamico={'Consultorios'}
        />
      )
      break
    case 'Recepción':
      componenteSeleccionSede = (
        <FormularioSeleccionSede
          textoDinamico={'Recepción'}
          objetoDinamico={'Recepciones'}
        />
      )
      break
    default:
      componenteSeleccionSede = <FormularioSeleccionSede />
      break
  }

  return <div className="w-full">{componenteSeleccionSede}</div>
}

export default Seleccion
