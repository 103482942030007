import React, {useContext} from 'react'
import {ContextoPlantilla} from '../../../contexto'
import removeButtonImg from '../../../imagenes/removeButton.png'
import addButtonImg from '../../../imagenes/addButton.png'
import {InputRegex} from '../../inputs'
import {LabelNumero} from '../../Labels'
import BotonConImagen from '../../BotonConImagen'
import PlantillaCampo from './PlantillaCampo.jsx'

const PlantillaSeccion = ({numeroPestana, numeroSeccion, numeroPagina}) => {
  const contextoPlantilla = useContext(ContextoPlantilla)
  const seccion = contextoPlantilla.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const actualizarTituloSeccion = (nuevoValor) => {
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: contextoPlantilla.plantilla.pestanas.map((pestana) => {
        if (pestana.numeroPestana == numeroPestana) {
          return {
            ...pestana,
            secciones: pestana.secciones.map((seccion) => {
              if (seccion.numeroSeccion == numeroSeccion) {
                return {
                  ...seccion,
                  nombreSeccion: nuevoValor,
                }
              }
              return seccion
            }),
          }
        }
        return pestana
      }),
    })
  }

  const agregarSeccion = () => {
    let nuevaSeccion = {
      nombreSeccion: '',
      numeroSeccion: numeroSeccion + 1,
      campos: [
        {
          tituloCampo: '',
          numeroCampo: 1,
          tipoCampoId: -1,
          requerido: false,
          mostrarEnCertificado: false,
          atributos: {},
        },
      ],
    }
    let listaSecciones = [
      ...contextoPlantilla.plantilla.pestanas.find(
        (x) => x.numeroPestana == numeroPestana
      ).secciones,
    ].map((seccion) => {
      if (seccion.numeroSeccion > numeroSeccion) {
        return {
          ...seccion,
          numeroSeccion: seccion.numeroSeccion + 1,
        }
      }
      return seccion
    })
    listaSecciones.splice(numeroSeccion, 0, nuevaSeccion)
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: contextoPlantilla.plantilla.pestanas.map((pestana) => {
        if (pestana.numeroPestana == numeroPestana) {
          return {
            ...pestana,
            secciones: listaSecciones,
          }
        }
        return pestana
      }),
    })
  }

  const eliminarSeccion = () => {
    let listaSecciones = [
      ...contextoPlantilla.plantilla.pestanas
        .find((x) => x.numeroPestana == numeroPestana)
        .secciones.filter((x) => x.numeroSeccion != numeroSeccion),
    ].map((seccion) => {
      if (seccion.numeroSeccion > numeroSeccion) {
        return {
          ...seccion,
          numeroSeccion: seccion.numeroSeccion - 1,
        }
      }
      return seccion
    })
    if (listaSecciones.length == 0) {
      return
    }
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: contextoPlantilla.plantilla.pestanas.map((pestana) => {
        if (pestana.numeroPestana == numeroPestana) {
          return {
            ...pestana,
            secciones: listaSecciones,
          }
        }
        return pestana
      }),
    })
    contextoPlantilla.setErrores([])
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap">
        <LabelNumero
          numero={numeroPestana + '.' + seccion.numeroSeccion}
          esHeader={false}
        />
        <InputRegex
          id={'nombreSeccion' + numeroPestana + '.' + seccion.numeroSeccion}
          ancho={'w-10/12'}
          cambioValor={actualizarTituloSeccion}
          estilosContenedor={'w-full flex'}
          estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
          tipo={'text'}
          placeholder={'NOMBRE DE LA SECCIÓN'}
          valor={seccion.nombreSeccion}
          contexto={contextoPlantilla}
          numeroPagina={numeroPagina}
          requerido={true}
        />
        <BotonConImagen
          estilosContenedor={'w-10 pt-2'}
          imagen={removeButtonImg}
          medio={true}
          funcionAEjecutar={eliminarSeccion}
          textoAlternativo={'eliminar'}
        />
        <BotonConImagen
          estilosContenedor={'w-6 pt-2'}
          imagen={addButtonImg}
          medio={true}
          funcionAEjecutar={agregarSeccion}
          textoAlternativo={'agregar'}
        />
      </div>
      <div className="w-11/12 flex flex-wrap">
        {seccion.campos.map((campo) => (
          <div className="w-full flex mb-1 pl-10">
            <PlantillaCampo
              numeroPestana={numeroPestana}
              numeroSeccion={numeroSeccion}
              numeroCampo={campo.numeroCampo}
              numeroPagina={numeroPagina}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PlantillaSeccion
