import React from 'react'

const ConfirmacionTarifarioCreado = ({id, nombreEmpresa, nombreTarifario}) => {
  return (
    <div className="w-full flex flex-wrap m-2">
      <span className="w-full text-cendiatra text-xl font-bold">ID: {id}</span>
      <span className="w-full text-cendiatra text-xl font-bold">
        EMPRESA O EMPRESA HIJA: {nombreEmpresa}
      </span>
      <span className="w-full text-cendiatra text-xl font-bold">
        NOMBRE TARIFARIO: {nombreTarifario}
      </span>
    </div>
  )
}
export default ConfirmacionTarifarioCreado
