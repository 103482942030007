import React from 'react'
import Boton from './Boton'
import {UseObtenerRolValido} from '../../utilidades/funcionesComunes'

export default function BotonesFormularioPasos({
  contexto,
  clickGuardar,
  textoBotonUltimaPagina,
  textoBotonUltimaPaginaGerenteGeneral,
  deshabilitarBotonGuardar,
  fnUltimaPaginaGerenteGeneral = () => {},
  mostrarBotonesEspecificos = true,
  esModificacionAtencion = false,
}) {
  const rolValido = esModificacionAtencion ? false : UseObtenerRolValido()

  return (
    <div className=" flex justify-center items-center pointer-events-auto">
      {contexto.numeroPagina > 1 && (
        <button
          className={`opacity-70 group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md ${
            contexto.atencionCreada ? 'text-cendiatra-gris-4' : 'text-cendiatra'
          } bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra font-bold m-2`}
          type="button"
          onClick={() => {
            contexto.setNumeroPagina(contexto.numeroPagina - 1)
            if (contexto?.errores?.length) {
              contexto.setErrores([])
            }
          }}
          disabled={contexto.atencionCreada}
        >
          {' '}
          <u>A</u>trás
        </button>
      )}
      {contexto.numeroPagina < contexto.totalPaginas ? (
        <Boton
          titulo="Siguiente"
          lineaPrimerCaracter={true}
          habilitado={
            !contexto.formularioActualTieneErrores &&
            (!contexto?.errores || contexto?.errores?.length === 0)
          }
          funcionCLick={() => {
            contexto.setNumeroPagina(contexto.numeroPagina + 1)
          }}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
        />
      ) : null}
      {!rolValido &&
      contexto.numeroPagina === contexto.totalPaginas &&
      mostrarBotonesEspecificos ? (
        deshabilitarBotonGuardar ? null : (
          <Boton
            titulo={textoBotonUltimaPagina ? textoBotonUltimaPagina : 'Guardar'}
            funcionCLick={clickGuardar}
            habilitado={
              !contexto.formularioActualTieneErrores &&
              !contexto.validacionBoton &&
              !contexto.validacionBotonCredito &&
              (!contexto?.errores || contexto?.errores?.length == 0) &&
              !contexto.validacionActivarBotonTarifario &&
              !contexto.validacionActivarBotonPortafolio
            }
            colorPorDefecto={'bg-grayColor'}
            colorActivo={'bg-btnBg'}
            tipo={'submit'}
          />
        )
      ) : null}

      {rolValido &&
      contexto.numeroPagina === contexto.totalPaginas &&
      mostrarBotonesEspecificos ? (
        <Boton
          titulo={
            textoBotonUltimaPaginaGerenteGeneral
              ? textoBotonUltimaPaginaGerenteGeneral
              : ''
          }
          ancho={'w-40'}
          funcionCLick={() => {
            contexto.setNumeroPagina(1)
            fnUltimaPaginaGerenteGeneral()
          }}
          colorPorDefecto={'bg-btnBg'}
          habilitado={true}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          id={textoBotonUltimaPaginaGerenteGeneral ? 'btn-regresar-gg' : ''}
        />
      ) : null}
    </div>
  )
}
