import {getRestApi} from './ApiAxios'

export const CrearPlantillaBD = async (plantilla) => {
  const apiReq = await getRestApi()
  return await apiReq.put('plantilla-function/CrearPlantilla', plantilla)
}

export const ModificarPlantilla = async (plantilla) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('plantilla-function/ModificarPlantilla', plantilla)
}

export const ObtenerListaPlantillasDB = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('plantilla-function/ObtenerPlantillas')
}

export const ObtenerPlantillasConServiciosAsignados = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('plantilla-function/ObtenerServiciosPlantilla')
}

export const AsignarServiciosPlantilla = async (plantillaId, serviciosIds) => {
  const apiReq = await getRestApi()
  return await apiReq.put('plantilla-function/AsignarServiciosPlantilla', {
    plantillaId,
    serviciosIds,
  })
}

export const DesvincularServicioPlantilla = async (plantillaServicioId) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    `plantilla-function/DesvincularServicioPlantilla?plantillaServicioId=${plantillaServicioId}`
  )
}

export const ObtenerCamposPreCarga = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('plantilla-function/ObtenerCamposPreCarga')
}

export const ObtenerPlantillaPorServicioId = async (servicioId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('plantilla-function/ObtenerPlantillaPorServicioId', {
    params: {
      servicioId,
    },
  })
}
export const ObtenerImprimeCertificadoPorServiciosIds = async (
  serviciosIds
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'plantilla-function/ObtenerImprimeCertificadoPorServiciosIds',
    {
      params: {
        serviciosIds,
      },
    }
  )
}

export const ComprobarServiciosAsociadosPlantilla = async (serviciosIds) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'plantilla-function/ComprobarServiciosAsociadosAPlantillas',
    serviciosIds
  )
}

export const ObtenerPlantillaPorId = async (plantillaId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('plantilla-function/ObtenerPlantillaPorId', {
    params: {plantillaId},
  })
}

export const ObtenerVersionesPlantillas = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('plantilla-function/ObtenerVersionesPlantillas')
}
