import React from 'react'

const OfflineWarning = () => {
  return (
    <div className="absolute w-full flex justify-center">
      <div className="w-2/3 bg-red-200 rounded text-center">
        <span>
          Estas en modo OffLine, los formularios se cargaran automaticamente al
          volver la conexión
        </span>
      </div>
    </div>
  )
}

export default OfflineWarning
