import React, {useEffect, useState} from 'react'
import {ValidarEstadoTransaccion} from '../../microservicios/Fachada'
import {useLocation} from 'react-router-dom'
import EstadosRecargaSaldo from '../../componentes/recepcionista/EstadosRecargaSaldo'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const FinalizarRecarga = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const pmtId = searchParams.get('pmtId')

  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const idEmpresa =
    fnObtenerInformacionSessionOLocalStorage('empresaIdRecargaSaldo', rol) ?? ''

  const [ip, setIp] = useState('')
  const [ipReady, setIpReady] = useState(false)
  const [transaccionEstadoSaldo, setTransaccionEstadoSaldo] = useState({})

  const obtenerDireccionIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      setIp(data.ip)
      setIpReady(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    obtenerDireccionIP()
  }, [])

  useEffect(() => {
    if (ipReady) {
      const estadoTransaccion = async () => {
        try {
          const res = await ValidarEstadoTransaccion(idEmpresa, pmtId, ip)
          if (res.data) {
            setTransaccionEstadoSaldo(res.data)
          }
        } catch (error) {
          console.log(error)
        }
      }

      estadoTransaccion()
    }
  }, [idEmpresa, pmtId, ip, ipReady])

  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-9/12 flex-col">
          <div className="w-full flex-col justify-center items-center">
            <h4 className="text-center text-cendiatra text-3xl font-bold">
              RECARGA TU CUENTA DE SERVICIOS
            </h4>
          </div>

          <EstadosRecargaSaldo
            transaccionEstadoSaldo={transaccionEstadoSaldo}
          />
        </div>
      </div>
    </div>
  )
}

export default FinalizarRecarga
