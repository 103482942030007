import {useContext} from 'react'
import {ContextApplication} from '../contexto'

export const useModal = () => {
  const contextoApp = useContext(ContextApplication)

  const cerrar = () => {
    contextoApp.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  return [cerrar]
}
