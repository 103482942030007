import { ContenedorPagina } from "../../componentes/paginas/ContenedorPagina"
import TablaTarifarioParticular from "../../componentes/recepcionista/TablaTarifarioParticular"
import { TEXTO_VISUAL } from "../../constantes/textoVisual"

const ConsultaTarifarioParticular = () => {
  return (
    <ContenedorPagina
    tituloPagina={TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.CONSULTA_TARIFARIO_PARTICULAR}
    clasesCssAdicionales={'w-full'}
  >
    <div className="flex w-full justify-center items-start flex-wrap">
      <div className="w-full flex flex-wrap justify-center items-center">
        <div className="w-11/12 py-10">
            <TablaTarifarioParticular/>
        </div>
      </div>
    </div>
  </ContenedorPagina>
  )
}

export default ConsultaTarifarioParticular
