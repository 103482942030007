import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ContextoServicio, ContextApplication} from '../../contexto'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {ContenedorDesplegable} from '../Contenedores'
import {Input, ListaDesplegable, InputPorcentaje} from '../inputs'
import Boton from '../Botones/Boton'
import {
  ObtenerCupsPorCodigoONombre,
  AgregarServiciosACache,
  ObtenerUnicoServicioPorNombre,
  ObtenerUnicaSiglaStickerPorNombre,
  ObtenerClasificacionServicios,
  ObtenerGrupoLaboratorio,
  ObtenerCuentasDeIngreso,
  ObtenerDuracionCita,
  ObtenerObservacionesDePreparacionPorNombre,
} from '../../cache/servicios-cache'

import {ObtenerProximoId} from '../../microservicios'
import {transformarNumero} from '../../utilidades'
import {
  regexBasicoV1,
  regexBasicoV2,
  regexTextoCaracteresBasicos,
} from '../../constantes/regex'
import {TEXTO_VISUAL} from '../../constantes'

const FormularioCrearServicio = ({clickGuardar}) => {
  const {
    control,
    clearErrors,
    setValue,
    reset,
    trigger,
    watch,
    getValues,
    formState: {errors, isValid},
  } = useForm({
    mode: 'onChange',
  })
  const contextoServicio = useContext(ContextoServicio)
  const contextoAplicacion = useContext(ContextApplication)

  const [cups, setCups] = useState([])
  const [clasificacionServicios, setClasificacionServicios] = useState([])
  const [grupoLaboratorio, setGrupoLaboratorio] = useState([])
  const [cuentasDeingreso, setCuentasDeIngreso] = useState([])
  const [duracionCita, setDuracionCita] = useState([])
  const [observacionesPreparacion, setObservacionesPreparacion] = useState([])

  useEffect(() => {
    if (contextoServicio.modoEdicion) {
      reset(contextoServicio.informacionServicio)
    }
    trigger()
  }, [contextoServicio.actualizar])

  const obtenerInformacionInicial = useCallback(async () => {
    await AgregarServiciosACache()
    setClasificacionServicios(await ObtenerClasificacionServicios())
    setGrupoLaboratorio((await ObtenerGrupoLaboratorio()).sort(ordenarLista))
    setCuentasDeIngreso(await ObtenerCuentasDeIngreso())
    setDuracionCita(await ObtenerDuracionCita())
  }, [])

  useEffect(() => {
    obtenerInformacionInicial()
  }, [obtenerInformacionInicial])

  useEffect(() => {
    const subscription = watch(() => {
      contextoServicio.setInformacionServicio(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const ordenarLista = (a, b) => {
    if (a.Nombre < b.Nombre) {
      return -1
    }
    if (a.Nombre > b.Nombre) {
      return 1
    }
    return 0
  }

  const validarNuevoServicio = async (nombre) => {
    if (
      contextoServicio.informacionServicio?.nombreInicial &&
      contextoServicio.informacionServicio?.nombreInicial?.trim() ==
        nombre.trim()
    ) {
      return true
    } else {
      let respuesta = await ObtenerUnicoServicioPorNombre(nombre.trim())
      return respuesta.length > 0 ? false : true
    }
  }

  const validarNombreSticker = async (nombre) => {
    if (
      contextoServicio.informacionServicio?.siglaStickerInicial &&
      contextoServicio.informacionServicio?.siglaStickerInicial?.trim() ==
        nombre.trim()
    ) {
      return true
    } else {
      if (contextoServicio.informacionServicio?.siglaSticker) {
        let respuesta = await ObtenerUnicaSiglaStickerPorNombre(nombre.trim())
        return respuesta.length > 0 ? false : true
      }
    }
  }

  const manejarCambioCups = async (
    valor,
    id,
    traerProximoCodigoInterno,
    codigo
  ) => {
    setValue('cups', valor)
    if (valor.length >= 3) {
      setCups(await ObtenerCupsPorCodigoONombre(valor))
      if (traerProximoCodigoInterno && id) {
        contextoServicio.setCupsInformacion({Id: id, Codigo: codigo})
        ObtenerProximoId('servicio' + id)
          .then((res) => {
            if (res.data) {
              setValue('codigoInterno', transformarNumero(res.data))
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else {
      setCups([])
    }
  }
  const manejarCambioObservacionesPreparacion = async (valor) => {
    setValue('observacionesPreparacion', valor)
    if (valor.length >= 3) {
      contextoAplicacion.setIsLoading(true)
      setObservacionesPreparacion(
        await ObtenerObservacionesDePreparacionPorNombre(valor)
      )
      contextoAplicacion.setIsLoading(false)
    } else {
      setObservacionesPreparacion([])
    }
  }

  const manejarCambioClasificacion = (valor) => {
    if (valor == 6) {
      contextoServicio.setActualizar(!contextoServicio.actualizar)
      trigger()
    } else {
      setValue('grupoLaboratorio', '')
      setValue('siglaSticker', '')
    }
  }

  return (
    <div className="w-11/12 flex flex-wrap justify-center ">
      <div className="w-full border border-cendiatra rounded-xl my-2">
        <ContenedorDesplegable
          titulo={'Seleccionar CUPS para crear el servicio'}
          expandidoInicial={true}
          posicionTitulo={'justify-center'}
          ocultarBotonDesplegable={true}
        >
          <Controller
            name="cups"
            control={control}
            rules={{
              required: true,
              onChange: (e) => {
                manejarCambioCups(e.target.value)
              },
            }}
            render={({field: {onChange, value}}) => (
              <>
                <CampoFiltrarEspañol
                  estilosPersonalizados={'w-11/12 mb-10'}
                  titulo={'CUPS*'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'cups')
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra rounded-lg text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                  }
                  tipo={'text'}
                  placeholder={'INGRESE CODIGO CUPS (AUTOCOMPLETAR)'}
                  valorDelCampoFiltro={value}
                  desactivarOtroFiltro={contextoServicio.modoEdicion}
                  handleChange={onChange}
                  handleOptionChange={(e) => {
                    manejarCambioCups(e.filtro, e.id, true, e.codigo)
                  }}
                  informacionFiltrada={cups}
                />
              </>
            )}
          />
        </ContenedorDesplegable>
      </div>

      <div className="w-11/12 flex justify-between items-center flex-wrap">
        <Controller
          name="codigoInterno"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-3/12'}
              estilosInput={
                'appearance-none rounded-none relative block w-full px-3 py-2 border border-gris bg-gris placeholder-white text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
              }
              tipo={'text'}
              deshabilitado={true}
              titulo={'Código interno*'}
              valor={value}
            />
          )}
        />
        <div className="w-8/12 flex flex-wrap justify-center items-center">
          <Controller
            name="nombreServicio"
            control={control}
            rules={{
              required: true,
              pattern: regexBasicoV2,
              validate: validarNuevoServicio,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'nombreServicio'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'Nombre del servicio*'}
                onChange={onChange}
                valor={value}
              />
            )}
          />
          <span className="text-cendiatra-semaforo-rojo w-full text-13px">
            {errors.nombreServicio?.type === 'pattern' &&
              TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V2}
          </span>
          <span className="text-red-500 w-full">
            {errors.nombreServicio?.type === 'validate' &&
              'El nombre del servicio especificado ya se encuentra registrado'}
          </span>
        </div>
      </div>
      <div className="w-full border border-cendiatra rounded-xl my-2">
        <ContenedorDesplegable
          titulo={'Parámetros de Servicio'}
          expandidoInicial={true}
          posicionTitulo={'justify-center'}
          ocultarBotonDesplegable={true}
          estilosContenedor={'border border-cendiatra rounded-xl mt-10'}
        >
          <div className="w-6/12 flex justify-center items-start h-48 mt-10">
            <Controller
              name="clasificacionServicio"
              control={control}
              rules={{
                required: true,
                onChange: (e) => manejarCambioClasificacion(e.target.value),
              }}
              render={({field: {onChange, value}}) => (
                <ListaDesplegable
                  onChange={onChange}
                  estilosContenedor={'w-8/12'}
                  estilosLista={
                    Object.keys(errors).find(
                      (element) => element === 'clasificacionServicio'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  titulo={'Clasificación de servicio*'}
                  opciones={clasificacionServicios}
                  valor={value}
                />
              )}
            />
          </div>
          <div className="w-6/12 flex flex-wrap justyfy-center items-start h-48 mt-10">
            <Controller
              name="grupoLaboratorio"
              control={control}
              rules={{
                required:
                  contextoServicio.informacionServicio?.clasificacionServicio !=
                  6
                    ? false
                    : true,
              }}
              render={({field: {onChange, value}}) => (
                <ListaDesplegable
                  onChange={onChange}
                  estilosContenedor={'w-7/12'}
                  estilosLista={
                    Object.keys(errors).find(
                      (element) => element === 'grupoLaboratorio'
                    )
                      ? `appearance-none rounded relative block w-full  p-1.5 border ${
                          contextoServicio.informacionServicio
                            ?.clasificacionServicio != 6
                            ? 'border-cendiatra-gris-3 bg-cendiatra-gris-placeholder text-white pointer-events-none'
                            : 'border-cendiatra-rojo-1 text-cendiatra-gris-3'
                        }  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm `
                      : `appearance-none rounded relative block w-full  p-1.5 border ${
                          contextoServicio.informacionServicio
                            ?.clasificacionServicio != 6
                            ? 'border-cendiatra-gris-3 bg-cendiatra-gris-placeholder text-white pointer-events-none'
                            : 'border-cendiatra text-cendiatra-gris-3 '
                        }  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm `
                  }
                  titulo={'Grupo Laboratorio*'}
                  opciones={grupoLaboratorio}
                  valor={value}
                />
              )}
            />
            <div className="w-7/12">
              <Controller
                name="siglaSticker"
                control={control}
                rules={{
                  required:
                    contextoServicio.informacionServicio
                      ?.clasificacionServicio != 6
                      ? false
                      : true,
                  maxLength: 10,
                  pattern: regexTextoCaracteresBasicos,
                  validate: validarNombreSticker,
                }}
                render={({field: {onChange, value}}) => (
                  <Input
                    estilosContenedor={'w-full -mt-10 '}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'siglaSticker'
                      )
                        ? `appearance-none rounded relative block w-full  p-1.5 border ${
                            contextoServicio.informacionServicio
                              ?.clasificacionServicio != 6
                              ? 'border-cendiatra-gris-3 bg-cendiatra-gris-placeholder placeholder-white'
                              : 'border-cendiatra-rojo-1 text-cendiatra-gris-3 '
                          }  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm `
                        : `appearance-none rounded relative block w-full  p-1.5 border ${
                            contextoServicio.informacionServicio
                              ?.clasificacionServicio != 6
                              ? 'border-cendiatra-gris-3 bg-cendiatra-gris-placeholder text-white placeholder-white pointer-events-none'
                              : 'border-cendiatra text-cendiatra-gris-3 '
                          }  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm `
                    }
                    tipo={'text'}
                    placeholder={'DESCRIPCIÓN'}
                    titulo={'Sigla Sticker*'}
                    deshabilitado={
                      !contextoServicio.informacionServicio
                        ?.clasificacionServicio
                    }
                    onChange={onChange}
                    valor={value}
                  />
                )}
              />

              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.siglaSticker?.type === 'pattern' &&
                  'Se permiten estos caracteres + ( ) [ ] , / - _ . '}
              </span>
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.siglaSticker?.type === 'maxLength' &&
                  'Se permiten hasta 10 caracteres'}
              </span>
              <span className="text-red-500 w-full">
                {errors.siglaSticker?.type === 'validate' &&
                  'Las siglas para el laboratorio especificado ya se encuentran registradas'}
              </span>
            </div>
          </div>
          <div className="w-11/12 flex flex-wrap justify-center items-center -mt-10">
            <Controller
              name="observacionesPreparacion"
              control={control}
              rules={{
                required: false,
                pattern: regexBasicoV1,
                onChange: (e) => {
                  manejarCambioObservacionesPreparacion(e.target.value)
                },
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <CampoFiltrarEspañol
                    estilosPersonalizados={'w-full mb-10'}
                    titulo={'Observaciones de preparación'}
                    estilosInput={
                      'appearance-none  rounded relative block w-full h-28 p-1.5 border border-cendiatra rounded-lg text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                    }
                    tipo={'text'}
                    placeholder={'AUTOCOMPLETAR (EDITABLE)'}
                    valorDelCampoFiltro={value}
                    desactivarOtroFiltro={false}
                    handleChange={onChange}
                    handleOptionChange={(e) => {
                      manejarCambioObservacionesPreparacion(e.filtro)
                    }}
                    informacionFiltrada={observacionesPreparacion}
                  />
                </>
              )}
            />
            <span className="w-full text-cendiatra-semaforo-rojo  text-13px -mt-10">
              {errors.observacionesPreparacion?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </div>
        </ContenedorDesplegable>
      </div>
      <div className="w-full border border-cendiatra rounded-xl my-2">
        <ContenedorDesplegable
          titulo={'Parámetros de Contabilidad'}
          expandidoInicial={true}
          posicionTitulo={'justify-center'}
          ocultarBotonDesplegable={true}
          estilosContenedor={'border border-cendiatra rounded-xl mt-10'}
        >
          <div className="w-6/12 flex justify-center items-start h-20 mt-10 mb-5">
            <Controller
              name="cuentaIngreso"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <ListaDesplegable
                  onChange={onChange}
                  estilosContenedor={'w-8/12'}
                  estilosLista={
                    Object.keys(errors).find(
                      (element) => element === 'cuentaIngreso'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  titulo={'Cuenta de Ingreso*'}
                  opciones={cuentasDeingreso}
                  valor={value}
                />
              )}
            />
          </div>
          <div className="w-6/12 flex flex-wrap justyfy-center items-start h-20 mt-10 mb-5">
            <Controller
              name="iva"
              control={control}
              rules={{
                required: false,
                pattern: /^[0-9%,.\s]+$/,
              }}
              render={({field: {onChange, value}}) => (
                <InputPorcentaje
                  estilosContenedor={'w-8/12'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'iva')
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  placeholder={'DESCRIPCIÓN'}
                  titulo={'IVA%'}
                  deshabilitarEscalaDecimal={true}
                  onValueChange={(e) => onChange(e.floatValue)}
                  valor={value}
                  onBlur={(e) =>
                    e.target.value === '%' ? setValue('iva', 0) : null
                  }
                />
              )}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px ">
              {errors.iva?.type === 'pattern' &&
                'Se aceptan números y el simbolo porcentaje(%)'}
            </span>
          </div>
        </ContenedorDesplegable>
      </div>
      <div className="w-full border border-cendiatra rounded-xl my-2">
        <ContenedorDesplegable
          titulo={'Parámetros de Call Center'}
          expandidoInicial={true}
          posicionTitulo={'justify-center'}
          ocultarBotonDesplegable={true}
          estilosContenedor={'border border-cendiatra rounded-xl mt-10'}
        >
          <Controller
            name="tiempoDuracion"
            control={control}
            rules={{
              required: false,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'w-8/12 mb-5'}
                estilosLista={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                titulo={'Tiempo duración cita'}
                opciones={duracionCita}
                valor={value}
              />
            )}
          />
        </ContenedorDesplegable>
      </div>

      <Boton
        titulo={contextoServicio.modoEdicion ? 'Editar' : 'Crear'}
        lineaPrimerCaracter={false}
        funcionCLick={clickGuardar}
        habilitado={isValid}
        colorPorDefecto={'bg-grayColor'}
        colorActivo={'bg-btnBg'}
        tipo={'submit'}
      />
    </div>
  )
}

export default FormularioCrearServicio
