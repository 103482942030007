export const tiposTurno = {
  GENERAL: 1,
  PREFERENCIAL: 2,
  VIP: 3,
}

export const estadosTurno = {
  ACTIVO: 1,
  LLAMANDO: 2,
  ATENDIENDO: 3,
  CANCELADO: 4,
  FINALIZADO: 5,
}
