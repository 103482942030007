import React, {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ListaDesplegable, AreaDeTexto} from '../../../inputs'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {ObtenerConceptosDeAptitud} from '../../../../cache/servicios-cache'

const ConceptoAptitud = ({numeroPagina}) => {
  const {
    control,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    trigger,
    watch,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  const [listaConceptosAptitud, setListaConceptosAptitud] = useState([])

  useEffect(async () => {
    setListaConceptosAptitud(await ObtenerConceptosDeAptitud())
  }, [])

  useEffect(() => {
    reset(contextoMedicoOcupacional.conceptoAptitud)
    trigger()
  }, [contextoMedicoOcupacional.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      trigger()
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, contextoMedicoOcupacional.numeroPagina])

  useEffect(() => {
    const subscription = watch(() => {
      contextoMedicoOcupacional.setConceptoAptitud(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const manejarConceptoAptitud = async (e) => {
    if (e === '') {
      document.getElementById('descripcionConceptoAptitud').focus()
      contextoMedicoOcupacional.setConceptoAptitud('')
      setValue('conceptoAptitud', '')
      setError('conceptoAptitud', {type: 'require', message: ''})
    } else {
      setValue('conceptoAptitud', e)
      clearErrors('conceptoAptitud')
    }
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-cente z-50">
      <Controller
        name="conceptoAptitud"
        control={control}
        rules={{
          required: true,
          onChange: (e) => manejarConceptoAptitud(e.target.value),
        }}
        render={({field: {onChange, value}}) => (
          <ListaDesplegable
            onChange={onChange}
            estilosContenedor={'w-5/12 '}
            estilosLista={
              Object.keys(errors).find(
                (element) => element === 'conceptoAptitud'
              )
                ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
            }
            titulo={'CONCEPTO DE APTITUD*'}
            opciones={listaConceptosAptitud}
            valor={value}
          />
        )}
      />
      <Controller
        name="descripcionConceptoAptitud"
        control={control}
        rules={{
          required: false,
        }}
        render={({field: {onChange, value}}) => (
          <AreaDeTexto
            estilosContenedor={'w-full flex justify-center items-center mb-20'}
            estilosAreaDeTexto={
              'h-20 my-3 rounded w-5/12  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
            }
            titulo={''}
            id="descripcionConceptoAptitud"
            placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
            valor={value}
            cambioValor={onChange}
          />
        )}
      />
    </div>
  )
}

export default ConceptoAptitud
