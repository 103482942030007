import React from 'react'

export const CheckboxTabla = ({row: {original}, actualizarSeleccionado}) => {
  const onChange = () => {
    actualizarSeleccionado(original.Id)
  }

  return (
    <div className="w-full flex justify-center items-center -mt-3">
      <input
        type="checkbox"
        className="checkboxEspecial personalizado"
        onChange={onChange}
        checked={original.EstaActivo}
      />
    </div>
  )
}

export default CheckboxTabla
