import React, {useContext, useEffect} from 'react'
import {AgregarEmpresasACache} from '../../cache/servicios-cache/Empresas'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import FormularioPrecios from '../../componentes/Formularios/FormularioPrecios'
import Pestana from '../../componentes/Pestanas/Pestana'
import {FormularioPortafolio} from '../../componentes/portafolio/FormularioPortafolio'
import TablaAliados from '../../componentes/Tablas/TablaAliados'
import TablaSedes from '../../componentes/Tablas/TablaSedes'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {TIPO_OBTENER_PROXIMO_ID} from '../../constantes/tipoObtenerProximoId'
import {ContextoPortafolio, ContextApplication} from '../../contexto'
import {useGuardarPortafolio} from '../../hooks/portafolios/useGuardarPortafolio'
import {useObtenerSedesMarcadas} from '../../hooks/useObtenerSedesMarcadas'
import {ObtenerProximoId} from '../../microservicios/Referencia'
import {obtenerTitulosDeConstante} from '../../utilidades/obtenerTitulosDeConstante'

const TEXTO_PANTALLA = TEXTO_VISUAL.PORTAFOLIO.CREAR

// Obtener los títulos de las pestañas.
const listaPestanas = obtenerTitulosDeConstante(TEXTO_PANTALLA.PESTANA)

export const CrearPortafolio = () => {
  const contextoPortafolio = useContext(ContextoPortafolio)
  const contextoApp = useContext(ContextApplication)
  const [manejarGuardar] = useGuardarPortafolio(true)

  useObtenerSedesMarcadas(contextoPortafolio)

  useEffect(() => {
    contextoPortafolio.setListaServicios([])
    contextoPortafolio.setListaPaquetes([])
    contextoPortafolio.setFilaSeleccionada(-1)
    contextoPortafolio.setNombrePortafolioValidacion('')
    contextoPortafolio.setActualizar(false)
    contextoPortafolio.setTotalPaginas(4)
    contextoPortafolio.setNumeroPagina(1)
    contextoApp.setIsLoading(true)
    contextoPortafolio.setModoEdicion(false)
    contextoPortafolio.setServiciosIniciales([])
    contextoPortafolio.setPaquetesIniciales([])
    contextoPortafolio.setValidacionActivarBotonPortafolio(false)

    AgregarEmpresasACache()
      .then(() => ObtenerProximoId(TIPO_OBTENER_PROXIMO_ID.TARIFARIO))
      .then((respuestaObtenerProximoId) => {
        contextoPortafolio.setPortafolioId(respuestaObtenerProximoId.data)
      })
      .catch((error) => {
        console.error(error)

        // En caso que falle la operación del caché.
        contextoApp.setIsLoading(false)
      })
  }, [])

  return (
    <ContenedorPagina tituloPagina={TEXTO_PANTALLA.TITULO}>
      <div className="justify-center items-center flex">
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoPortafolio}
        />
      </div>
      <Pestana contexto={contextoPortafolio} numeroPagina={1}>
        <FormularioPortafolio numeroPagina={1} />
      </Pestana>
      <Pestana contexto={contextoPortafolio} numeroPagina={2}>
        <TablaSedes contexto={contextoPortafolio} numeroPagina={2} />
      </Pestana>
      <Pestana contexto={contextoPortafolio} numeroPagina={3}>
        <TablaAliados contexto={contextoPortafolio} numeroPagina={3} />
      </Pestana>
      <Pestana
        contexto={contextoPortafolio}
        numeroPagina={4}
        clickGuardar={manejarGuardar}
      >
        <FormularioPrecios
          contexto={contextoPortafolio}
          numeroPagina={4}
          esPortafolio={true}
        />
      </Pestana>
    </ContenedorPagina>
  )
}
