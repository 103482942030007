import React, {useState, createContext} from 'react'

export const ContextoTarifario = createContext('')

export const TarifarioConContexto = ({children}) => {
  const [idTarifario, setIdTarifario] = useState('')
  const [idEmpresa, setIdEmpresa] = useState()
  const [formularioEmpresa, setFormularioEmpresa] = useState({nombre: ''})
  const [listaServicios, setListaServicios] = useState([])
  const [listaPaquetes, setListaPaquetes] = useState([])
  const [actualizar, setActualizar] = useState(false)
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(3)
  const [formularioActualTieneErrores, setformularioActualTieneErrores] =
    useState(true)
  const [sedesSeleccionadas, setSedesSeleccionadas] = useState([])
  const [aliadosSeleccionados, setAliadosSeleccionados] = useState([])
  const [filtroActivo, setFiltroActivo] = useState(false)
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1)
  const [nombreTarifarioValidacion, setNombreTarifarioValidacion] = useState('')
  const [portafolioSeleccionadoId, setPortafolioSeleccionadoId] = useState('')
  const [modoEdicion, setModoEdicion] = useState(false)
  const [serviciosIniciales, setServiciosIniciales] = useState([])
  const [paquetesIniciales, setPaquetesIniciales] = useState([])
  const [sedesIniciales, setSedesIniciales] = useState([])
  const [aliadosIniciales, setAliadosIniciales] = useState([])
  const [seguroDeCambio, setSeguroDeCambio] = useState(false)
  const [precioServiciosIniciales, setPreciosServiciosIniciales] = useState({})
  const [preciosPaquetesIniciales, setPreciosPaquetesIniciales] = useState({})
  const [arregloMomentoActivacion, setArregloMomentoActivacion] = useState([])
  const [validacionActivarBotonTarifario, setValidacionActivarBotonTarifario] =
    useState(true)

  return (
    <ContextoTarifario.Provider
      value={{
        idTarifario: idTarifario,
        setIdTarifario: setIdTarifario,
        idEmpresa: idEmpresa,
        setIdEmpresa: setIdEmpresa,
        formularioEmpresa: formularioEmpresa,
        setFormularioEmpresa: setFormularioEmpresa,
        listaServicios: listaServicios,
        setListaServicios: setListaServicios,
        listaPaquetes: listaPaquetes,
        setListaPaquetes: setListaPaquetes,
        actualizar: actualizar,
        setActualizar: setActualizar,
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
        formularioActualTieneErrores: formularioActualTieneErrores,
        setformularioActualTieneErrores: setformularioActualTieneErrores,
        sedesSeleccionadas: sedesSeleccionadas,
        setSedesSeleccionadas: setSedesSeleccionadas,
        aliadosSeleccionados: aliadosSeleccionados,
        setAliadosSeleccionados: setAliadosSeleccionados,
        filtroActivo: filtroActivo,
        setFiltroActivo: setFiltroActivo,
        filaSeleccionada: filaSeleccionada,
        setFilaSeleccionada: setFilaSeleccionada,
        nombreTarifarioValidacion: nombreTarifarioValidacion,
        setNombreTarifarioValidacion: setNombreTarifarioValidacion,
        portafolioSeleccionadoId: portafolioSeleccionadoId,
        setPortafolioSeleccionadoId: setPortafolioSeleccionadoId,
        modoEdicion: modoEdicion,
        setModoEdicion: setModoEdicion,
        serviciosIniciales: serviciosIniciales,
        setServiciosIniciales: setServiciosIniciales,
        paquetesIniciales: paquetesIniciales,
        setPaquetesIniciales: setPaquetesIniciales,
        sedesIniciales: sedesIniciales,
        setSedesIniciales: setSedesIniciales,
        aliadosIniciales: aliadosIniciales,
        setAliadosIniciales: setAliadosIniciales,
        seguroDeCambio: seguroDeCambio,
        setSeguroDeCambio: setSeguroDeCambio,
        precioServiciosIniciales: precioServiciosIniciales,
        setPreciosServiciosIniciales: setPreciosServiciosIniciales,
        preciosPaquetesIniciales: preciosPaquetesIniciales,
        setPreciosPaquetesIniciales: setPreciosPaquetesIniciales,
        arregloMomentoActivacion: arregloMomentoActivacion,
        setArregloMomentoActivacion: setArregloMomentoActivacion,
        validacionActivarBotonTarifario: validacionActivarBotonTarifario,
        setValidacionActivarBotonTarifario: setValidacionActivarBotonTarifario,
      }}
    >
      {children}
    </ContextoTarifario.Provider>
  )
}
