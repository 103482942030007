import {useState, useEffect} from 'react'
import {ObtenerUrlHubSignalR} from '../../microservicios/Comunicacion'
import {HubConnectionBuilder} from '@microsoft/signalr'

export const useConexionSignalR = (listaSusCripciones, sedeId) => {
  const [conexion, setConexion] = useState(null)

  const [urlHub, setUrlHub] = useState(null)

  const [montado, setMontado] = useState(false)

  useEffect(() => {
    setMontado(true)
    return () => setMontado(false)
  }, [])

  useEffect(() => {
    inicializarSignalR()
  }, [])

  const inicializarSignalR = async () => {
    setUrlHub((await ObtenerUrlHubSignalR()).data)
  }

  useEffect(() => {
    if (!montado) {
      return
    }
    if (urlHub) {
      const options = {
        accessTokenFactory: () => urlHub.accessToken,
      }
      const nuevaConeccion = new HubConnectionBuilder()
        .withUrl(urlHub.url, options)
        .withAutomaticReconnect()
        .build()

      setConexion(nuevaConeccion)

      return () => {
        nuevaConeccion.stop()
      }
    }
  }, [urlHub])

  useEffect(() => {
    if (!montado) {
      return
    }
    if (conexion) {
      conexion
        .start()
        .then((result) => {
          console.log('Conectado al Hub de SignalR con éxito!', result)
          agregarSubscripciones(listaSusCripciones)
        })
        .catch((e) => console.log('Fallo de conexión con Hub de SignalR: ', e))
    }
  }, [conexion])

  const agregarSubscripciones = (array) => {
    for (const elemento of array) {
      const {nombreConexion, funcion} = elemento
      const nombreCompleto = nombreConexion + sedeId

      conexion.on(nombreCompleto, (message) => {
        funcion(message)
      })
    }
  }

  const eliminarSubscripciones = (array) => {
    for (const elemento of array) {
      const {nombreConexion, funcion} = elemento
      const nombreCompleto = nombreConexion + sedeId

      conexion.off(nombreCompleto)
    }
  }

  return [conexion, agregarSubscripciones, eliminarSubscripciones]
}
