import {useForm} from 'react-hook-form'
import React, {useState, useContext, useEffect} from 'react'
import {ContextApplication} from '../../contexto'
import {NavLink, useHistory} from 'react-router-dom'
import Modal from '../../componentes/Modales/Modal.jsx'
import moment from 'moment'
import Procedures from '../../imagenes/procedures.jpg'
import Laboratory from '../../imagenes/laboratory.jpg'
import Vaccine from '../../imagenes/vaccine.jpg'
import ServicesModal from '../../componentes/ServicesModal.jsx'
import {postNewOrderService} from '../../servicios/Recepcionista'
import {useMsal} from '@azure/msal-react'
import Dexie from 'dexie'
import {ObtenerDepartamentosPorNombre} from '../../cache/servicios-cache/Departamentos.js'

export default function CreateUserService() {
  const ctx = useContext(ContextApplication)
  const [formSection, setFormSection] = useState(1)
  const [showErrors, setShowErrors] = useState(false)
  const [documentCategory, setDocumentCategory] = useState(
    ctx.userFormInfo[0].document_type
  )
  // const [cendiatraSites, setCendiatraSites] = useState([]);
  const [title, setTitle] = useState('')

  const [toolTipPro, setToolTipPro] = useState(false)
  const [toolTipLab, setToolTipLab] = useState(false)
  const [toolTipVac, setToolTipVac] = useState(false)

  const [showoptions, setShowOptions] = useState(false)
  const [filtredClients, setFiltredClients] = useState([])
  const [search, setSearch] = useState('')
  const [totalServicesValue, setTotalServicesValue] = useState('')

  const [showCityOptions, setShowCityOptions] = useState(false)
  const [filtredCities, setFiltredCities] = useState([])
  const [searchCity, setSearchCity] = useState('')

  const [showEPSOptions, setShowEPSOptions] = useState(false)
  const [filtredEPS, setFiltredEPS] = useState([])
  const [searchEPS, setSearchEPS] = useState('')

  const [showStateOptions, setShowStatesOptions] = useState(false)
  const [filtredStates, setFiltredStates] = useState([])
  const [searchStates, setSearchStates] = useState('')

  const [showPensionOptions, setShowPensionOptions] = useState(false)
  const [filtredPension, setFiltredPension] = useState([])
  const [searchPension, setSearchPension] = useState('')

  const [showARLOptions, setShowARLOptions] = useState(false)
  const [filtredARL, setFiltredARL] = useState([])
  const [searchARL, setSearchARL] = useState('')

  const [laboratoryServices, setLaboratoryServices] = useState([])
  const [processServices, setProcessServices] = useState([])
  const [vaccineServices, setVaccineServices] = useState([])
  const [modalMessage, setModalMessage] = useState('')

  let dateTime = moment().format().slice(0, -9)
  let maxDateRegister = moment().subtract(5, 'days').format().slice(0, -15)

  const history = useHistory()
  const {accounts} = useMsal()
  const userRole = accounts[0].idTokenClaims.extension_Role
  useEffect(() => {
    if (userRole === 'Medic') {
      ctx.setShowDoctorMenu(true)
      history.push('/medicalschedule')
    }

    document.getElementById('triggerButton').click()
    setShowErrors(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatter = new Intl.NumericFormat({
    style: 'currency',
    currency: 'USD',
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState: {errors},
  } = useForm({
    defaultValues: {
      // location: ctx.userFormInfo[0].location,
      startDate: dateTime,
      documentNumber: ctx.userFormInfo[0].document_number,
      documentType: ctx.userFormInfo[0].document_type,
      name: ctx.userFormInfo[0].name,
      lastName: ctx.userFormInfo[0].last_name,
      dateOfBirth: ctx.userFormInfo[0].birthday,
      rh: ctx.userFormInfo[0].blood_type,
      gender: ctx.userFormInfo[0].gender,
      idPatient: ctx.userFormInfo[0].id,
      countryOfResidence: 1,
    },
  })

  useEffect(() => {
    const verifyServices = () => {
      if (
        laboratoryServices.length > 0 ||
        processServices.length > 0 ||
        vaccineServices.length > 0
      ) {
        const allServicesArr = processServices.concat(
          laboratoryServices.concat(vaccineServices)
        )
        setValue('services', allServicesArr)
        clearErrors('services')
      } else {
        setValue('services', '')
      }
    }
    verifyServices()
  }, [
    laboratoryServices,
    processServices,
    vaccineServices,
    clearErrors,
    setValue,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const registerService = async (data) => {
    const storedSite = localStorage.getItem('siteId')
    const requestModel = {
      addres: data.address,
      arl: data.arl,
      billing: data.billing,
      birthday: data.dateOfBirth,
      charge: data.charge,
      children_number: data.children,
      city: data.city,
      companion: data.companion,
      companionNumber: data.companionNumber,
      company: data.company,
      country: data.countryOfResidence,
      document_issuance: data.documentIssuancePlace,
      document_number: data.documentNumber,
      document_type: data.documentType,
      disability: data.disability,
      email: data.email,
      eps: data.eps,
      examType: data.examType,
      family_composition: data.family,
      gender: data.gender,
      last_name: data.lastName,
      zone: data.localidad,
      marital_status: data.maritalState,
      cellphone: data.mobileNumber,
      name: data.name,
      nationality: data.nationality,
      pension_fund: data.pension,
      phone_number: data.phoneNumber,
      place_of_birth: data.placeOfBirth,
      race: data.race,
      zone_type: data.residentialZone,
      blood_type: data.rh,
      scholarship: data.scholarship,
      state: data.state,
      stratum: data.stratum,
      services: data.services,
      totalPrice: totalServicesValue,
      idSite: storedSite,
      idCompany: data.company,
      idPacient: data.idPatient,
    }
    if (ctx.offlineMode === true) {
      const cendiatraDbName = 'cendiatra-db'
      let db = await new Dexie(cendiatraDbName).open()

      const form = {
        Id: moment().format(),
        data: requestModel,
      }

      db.table('CreateOrderForm')
        .add(form)
        .then(() => {
          console.log('Order Form saved')
          history.push('/createpatient')
        })
      return
    }

    await postNewOrderService(requestModel)
      .then((response) => {
        if (response.status === 200) {
          setModalMessage(
            `La Orden de Servicio ha sido creada correctamente: ${response.data}`
          )
        } else {
          setModalMessage(`Ocurió un error: ${response.data}`)
        }
      })
      .catch((error) => {
        console.log(error)
        setModalMessage(`Ocurió un error: ${error}`)
      })
  }

  const goToErrorPage = (pageNumber) => {
    setShowErrors(false)
    setFormSection(pageNumber)
  }
  const goToPage = (pageNumber) => {
    setFormSection(pageNumber)
  }

  const addFormSection = () => {
    if (formSection === 5) {
      return
    } else {
      // reset({ ...getValues(), location: "" })
      setFormSection(formSection + 1)
    }
  }

  const removeFormSection = () => {
    if (formSection === 1) {
      return
    } else {
      setFormSection(formSection - 1)
    }
  }

  const cancelCreateService = () => {
    ctx.setUserFormInfo([{}])
    setModalMessage('')
  }

  const changeIdtype = (e) => {
    setDocumentCategory(e.target.value)
    reset({...getValues(), documentNumber: ''})
  }

  const onInvalid = () => {
    setShowErrors(true)
  }

  const selectOption = (option) => {
    setSearch(option.Name)
    setShowOptions(false)
    setValue('company', option.Id)
    clearErrors('company')
  }
  const selectCityOption = (option) => {
    setSearchCity(option.Name)
    setShowCityOptions(false)
    setValue('city', option.Id)
    clearErrors('city')
  }

  const selectEPSOption = (option) => {
    setSearchEPS(option.Name)
    setShowEPSOptions(false)
    setValue('eps', option.Id)
    clearErrors('eps')
  }
  const selectStateOption = (option) => {
    setSearchStates(option.Nombre)
    setShowStatesOptions(false)
    setValue('state', option.Id)
    clearErrors('state')
  }
  const selectPensionOption = (option) => {
    setSearchPension(option.Name)
    setShowPensionOptions(false)
    setValue('pension', option.Id)
    clearErrors('pension')
  }
  const selectARLOption = (option) => {
    setSearchARL(option.Name)
    setShowARLOptions(false)
    setValue('arl', option.Id)
    clearErrors('arl')
  }
  const changeSearch = (e) => {
    setSearch(e.target.value)

    if (e.target.value.length >= 4) {
      //Pendiente: Buscar en caché
      if (showoptions === false) {
        setShowOptions(true)
      }
    } else {
      setShowOptions(false)
    }
  }
  const changeSearchCity = (e) => {
    setSearchCity(e.target.value)

    if (e.target.value.length >= 3) {
      //Pendiente: Buscar en caché
      if (showCityOptions === false) {
        setShowCityOptions(true)
      }
    } else {
      setShowCityOptions(false)
    }
  }
  const changeSearchEPS = (e) => {
    setSearchEPS(e.target.value)

    if (e.target.value.length >= 3) {
      //Pendiente: Buscar en caché
      if (showEPSOptions === false) {
        setShowEPSOptions(true)
      }
    } else {
      setShowEPSOptions(false)
    }
  }

  const changeSearchStates = (e) => {
    setSearchStates(e.target.value)

    if (e.target.value.length >= 3) {
      ObtenerDepartamentosPorNombre(e.target.value).then((res) => {
        if (res) {
          setFiltredStates(res)
        }
      })
      if (showStateOptions === false) {
        setShowStatesOptions(true)
      }
    } else {
      setShowStatesOptions(false)
    }
  }
  const changeSearchPensions = (e) => {
    setSearchPension(e.target.value)

    if (e.target.value.length >= 3) {
      //Pendiente: Buscar en caché
      if (showPensionOptions === false) {
        setShowPensionOptions(true)
      }
    } else {
      setShowPensionOptions(false)
    }
  }

  const changeSearchARL = (e) => {
    setSearchARL(e.target.value)

    if (e.target.value.length >= 3) {
      //Pendiente: Buscar en caché
      if (showPensionOptions === false) {
        setShowARLOptions(true)
      }
    } else {
      setShowARLOptions(false)
    }
  }

  return (
    <>
      <ServicesModal
        open={title}
        title={title}
        setTitle={setTitle}
        setTotalServicesValue={setTotalServicesValue}
        laboratoryServices={laboratoryServices}
        setLaboratoryServices={setLaboratoryServices}
        processServices={processServices}
        setProcessServices={setProcessServices}
        vaccineServices={vaccineServices}
        setVaccineServices={setVaccineServices}
      />
      {modalMessage.includes('correctamente') ? (
        <Modal open={modalMessage.includes('correctamente')}>
          <div className="w-full flex flex-wrap justify-center">
            <div className="w-full text-center text-lg text-cendiatra">
              <span>{modalMessage}</span>
            </div>
            <div className="w-full flex justify-center mt-5">
              <NavLink to="/createpatient">
                <button
                  type="button"
                  className="group relative w-24 h-9 -mt-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => cancelCreateService()}
                >
                  Finalizar
                </button>
              </NavLink>
            </div>
          </div>
        </Modal>
      ) : null}
      {modalMessage.includes('error') ? (
        <Modal open={modalMessage.includes('error')}>
          <div className="w-full flex flex-wrap justify-center">
            <div className="w-full text-center text-lg text-cendiatra">
              <span>{modalMessage}</span>
            </div>
            <div className="w-full flex justify-center mt-5">
              <button
                type="button"
                className="group relative w-24 h-9 -mt-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                onClick={() => setModalMessage('')}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
      <Modal open={showErrors && Object.keys(errors).length > 0}>
        <div className="w-full">
          {Object.keys(errors).find((element) => element === 'location') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.location && 'No se ha ingresado una Sede'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find(
            (element) => element === 'documentNumber'
          ) ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.documentNumber &&
                    'Por favor ingrese el número de documento'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find((element) => element === 'documentType') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.documentType &&
                    'Por favor seleccione un tipo de documento'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'name') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.name && 'Por favor ingrese el nombre del paciente'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'lastName') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.lastName &&
                    'Por favor ingrese los apellidos del paciente'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'dateOfBirth') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.dateOfBirth &&
                    'Por favor seleccione una fecha de nacimiento'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'rh') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.rh && 'Por favor seleccione un grupo sanguíneo'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'gender') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.gender && 'Por favor seleccione un género'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find((element) => element === 'maritalState') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.maritalState &&
                    'Por favor seleccione el estado civil'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'scholarship') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.scholarship && 'Por favor ingrese la escolaridad'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(1)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find(
            (element) => element === 'countryOfResidence'
          ) ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.countryOfResidence &&
                    'Por favor seleccione el país de residencia'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find((element) => element === 'state') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.state && 'Por favor seleccione un departamento'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'city') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.city && 'Por favor seleccione un municipio'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find((element) => element === 'stratum') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.stratum && 'Por favor seleccione un estrato'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'phoneNumber') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.phoneNumber?.type === 'required' &&
                    'Por favor ingrese un teléfono'}
                  {errors.phoneNumber?.type === 'minLength' &&
                    'El teléfono no puede tener menos de 7 números'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'email') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.email && 'Por favor verifica el correo electronico'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'mobileNumber') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.mobileNumber && 'Por favor ingrese un celular'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'eps') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.eps && 'Por favor ingresa una EPS'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(3)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'pension') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.pension && 'Por favor ingrese un fondo de pensiones'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(3)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find((element) => element === 'company') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.company && 'No se ha ingresado una empresa'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(4)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'charge') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.charge && 'Por favor ingrese el cargo'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(4)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find((element) => element === 'billing') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.billing &&
                    'Por favor seleccione el tipo de facturación'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(4)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'examType') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.examType && 'Por favor seleccione el tipo de examen'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(4)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'services') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.services &&
                    'Por favor selecciona al menos 1 servicio'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(4)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
      <div className="min-h-screen flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        <div className="bg-white">
          <h2 className="  text-center text-3xl font-bold text-cendiatra">
            REGISTRAR ORDEN DE SERVICIO
          </h2>
        </div>
        <form
          className="mt-8 space-y-6 w-full rounded border border-opacity-50 border-cendiatra shadow-2xl"
          onSubmit={handleSubmit(registerService)}
          noValidate
        >
          <div className="bg-white p-4 rounded-lg">
            <div className="flex justify-start m-4 ">
              <NavLink to="/createpatient">
                <button
                  type="button"
                  className="group relative w-24 h-9 -mt-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => cancelCreateService()}
                >
                  Cancelar
                </button>
              </NavLink>
            </div>

            <div className="w-full flex flex-wrap justify-center my-5">
              {formSection === 1 ? (
                <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                        1
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-cendiatra font-semibold ">
                        {' '}
                        Inf. Paciente Personal
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                  onClick={() => goToPage(1)}
                >
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                        1
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-gray-400 font-semibold  ">
                        {' '}
                        Inf. Paciente Personal
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {formSection === 2 ? (
                <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                        2
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-cendiatra font-semibold ">
                        Inf.Paciente Dirección
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                  onClick={() => goToPage(2)}
                >
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                        2
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-gray-400 font-semibold  ">
                        Inf.Paciente Dirección
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {formSection === 3 ? (
                <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                        3
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-cendiatra font-semibold ">
                        Inf.General Paciente
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                  onClick={() => goToPage(3)}
                >
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                        3
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-gray-400 font-semibold  ">
                        Inf.General Paciente
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {formSection === 4 ? (
                <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                        4
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-cendiatra font-semibold ">
                        Inf.Orden Servicio
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                  onClick={() => goToPage(4)}
                >
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                        4
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-gray-400 font-semibold  ">
                        Inf.Orden Servicio
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {formSection === 5 ? (
                <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                        5
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-cendiatra font-semibold ">
                        Archivos Adjuntos
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                  onClick={() => goToPage(5)}
                >
                  <div className="flex justify-center  w-full">
                    <div className="w-3/7 flex justify-center items-center">
                      <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                        5
                      </p>
                    </div>
                    <div className="w-4/7 flex justify-start items-center">
                      <p className="text-md text-center  text-gray-400 font-semibold  ">
                        Archivos Adjuntos
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className={
                formSection === 1
                  ? 'bg-white p-5 rounded-lg'
                  : 'bg-white p-5 rounded-lg hidden'
              }
            >
              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Sexo*
                  </label>
                  <span className="text-red-500">
                    {errors.gender && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    //   placeholder="Dirección Email"
                    disabled={true}
                    {...register('gender', {required: true})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="Masculino">
                      Masculino
                    </option>
                    <option key="2" value="Femenino">
                      Femenino
                    </option>
                  </select>
                </div>

                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Grupo sanguíneo*
                  </label>
                  <span className="text-red-500">
                    {errors.rh && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    //   placeholder="Dirección Email"
                    disabled={true}
                    {...register('rh', {required: true})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="O+">
                      O+
                    </option>
                    <option key="2" value="O-">
                      O-
                    </option>
                    <option key="3" value="A+">
                      A+
                    </option>
                    <option key="4" value="A-">
                      A-
                    </option>
                    <option key="5" value="B+">
                      B+
                    </option>
                    <option key="6" value="B-">
                      B-
                    </option>
                    <option key="7" value="AB+">
                      AB+
                    </option>
                    <option key="8" value="AB-">
                      AB-
                    </option>
                    <option key="9" value="No Refiere">
                      No Refiere
                    </option>
                  </select>
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Estado Civil*
                  </label>
                  <span className="text-red-500">
                    {errors.maritalState && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'maritalState'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    {...register('maritalState', {required: true})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="soltero">
                      Soltero
                    </option>
                    <option key="2" value="casado">
                      Casado
                    </option>
                    <option key="3" value="Viudo">
                      Viudo
                    </option>
                    <option key="4" value="Divorciado">
                      Divorciado
                    </option>
                    <option key="5" value="Union libre">
                      Union libre
                    </option>
                    <option key="6" value="No Refiere">
                      No Refiere
                    </option>
                  </select>
                </div>
              </div>
              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Nombres*
                  </label>
                  <span className="text-red-500">
                    {errors.name && 'Por favor ingrese un nombre'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="texto"
                    disabled={true}
                    //autoComplete="email"
                    {...register('name', {required: true})}
                  />
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Apellidos*
                  </label>
                  <span className="text-red-500">
                    {errors.lastName && 'Por favor ingrese los apellidos'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="texto"
                    disabled={true}
                    placeholder="Apellidos*"
                    //autoComplete="email"
                    //   placeholder="Dirección Email"
                    {...register('lastName', {required: true})}
                  />
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Fecha de Nacimiento*
                  </label>
                  <span className="text-red-500">
                    {errors.dateOfBirth && 'Por favor seleccione una fecha'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="date"
                    disabled={true}
                    max={maxDateRegister}
                    {...register('dateOfBirth', {required: true})}
                  />
                </div>
              </div>

              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Tipo de documento*
                  </label>
                  <span className="text-red-500">
                    {errors.documentType && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    {...register('documentType', {required: true})}
                    onChange={(e) => changeIdtype(e)}
                    value={documentCategory}
                    disabled={true}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="Cédula de ciudadanía">
                      Cédula de Ciudadanía
                    </option>
                    <option key="2" value="Cédula de extranjería">
                      Cédula de Extranjería
                    </option>
                    <option key="3" value="Carnet Diplomatico">
                      Carnet Diplomático
                    </option>
                    <option key="4" value="Pasaporte">
                      Pasaporte
                    </option>
                    <option key="5" value="Salvoconducto">
                      Salvoconducto
                    </option>
                    <option key="6" value="Permiso Especial de Permanencia">
                      Permiso Especial de Permanencia
                    </option>
                    <option key="7" value="Registro Civil">
                      Registro Civil
                    </option>
                    <option key="8" value="Tarjeta de Identidad">
                      Tarjeta de Identidad
                    </option>
                    <option key="9" value="Certificado de Nacido Vivo">
                      Certificado de Nacido Vivo
                    </option>
                    <option key="10" value="Adulto sin Identificacíon">
                      Adulto sin Identificacíon
                    </option>
                    <option key="11" value="Menor sin Identificacíon">
                      Menor sin Identificacíon
                    </option>
                    <option key="12" value="Nit">
                      NIT
                    </option>
                  </select>
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    No. Documento*
                  </label>
                  {documentCategory === '' ? (
                    <input
                      className=" appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 bg-gray-300"
                      type="number"
                      min="1"
                      disabled={true}
                      {...register('documentNumber', {
                        required: true,
                        minLength: 4,
                        maxLength: 10,
                      })}
                    />
                  ) : null}

                  {documentCategory === 'Cédula de ciudadanía' ||
                  documentCategory === 'Tarjeta de Identidad' ||
                  documentCategory === 'Salvoconducto' ||
                  documentCategory === 'Nit' ? (
                    <>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 4 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 10 caracteres'}
                      </span>
                      <input
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        disabled={true}
                        min="1"
                        {...register('documentNumber', {
                          required: true,
                          minLength: 4,
                          maxLength: 10,
                        })}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Cédula de extranjería' ? (
                    <>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 6 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 7 caracteres'}
                      </span>
                      <input
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        disabled={true}
                        min="1"
                        {...register('documentNumber', {
                          required: true,
                          minLength: 6,
                          maxLength: 7,
                        })}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Carnet Diplomatico' ||
                  documentCategory === 'Pasaporte' ||
                  documentCategory === 'Certificado de Nacido Vivo' ? (
                    <>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 8 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 9 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'pattern' &&
                          'El documento solo puede contener letras y números'}
                      </span>
                      <input
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="text"
                        disabled={true}
                        min="1"
                        {...register('documentNumber', {
                          required: true,
                          minLength: 8,
                          maxLength: 9,
                          pattern: /^[a-zA-Z0-9-]+$/,
                        })}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Permiso Especial de Permanencia' ? (
                    <>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 15 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 15 caracteres'}
                      </span>
                      <input
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        disabled={true}
                        min="1"
                        {...register('documentNumber', {
                          required: true,
                          minLength: 15,
                          maxLength: 15,
                        })}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Registro Civil' ? (
                    <>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 10 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.documentNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 10 caracteres'}
                      </span>
                      <input
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        disabled={true}
                        min="1"
                        {...register('documentNumber', {
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                        })}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Adulto sin Identificacíon' ||
                  documentCategory === 'Menor sin Identificacíon' ? (
                    <input
                      className=" appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 bg-gray-300"
                      type="number"
                      min="1"
                      disabled={true}
                      {...register('documentNumber', {
                        required: false,
                        minLength: 4,
                        maxLength: 10,
                      })}
                    />
                  ) : null}
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Lugar de nacimiento
                  </label>
                  <span className="text-red-500">
                    {errors.placeOfBirth &&
                      'Por favor ingrese el lugar de nacimiento'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="text"
                    {...register('placeOfBirth', {required: false})}
                  />
                </div>
              </div>

              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap">
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Escolaridad*
                  </label>
                  <span className="text-red-500">
                    {errors.scholarship && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'scholarship'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    //   placeholder="Dirección Email"
                    {...register('scholarship', {required: true})}
                  >
                    <option key="-1" value=""></option>
                    <option key="0" value="No refiere">
                      No refiere
                    </option>
                    <option key="1" value="Analfabeta">
                      Analfabeta
                    </option>
                    <option key="2" value="Primaria">
                      Primaria
                    </option>
                    <option key="3" value="Bachiller">
                      Bachiller
                    </option>
                    <option key="4" value="Aprendiz">
                      Aprendiz
                    </option>
                    <option key="5" value="Técnico">
                      Técnico
                    </option>
                    <option key="6" value="Tecnológico">
                      Tecnológico
                    </option>
                    <option key="7" value="Universitario">
                      Universitario
                    </option>
                    <option key="8" value="Postgrado">
                      Postgrado
                    </option>
                    <option key="9" value="Maestría">
                      Maestría
                    </option>
                    <option key="10" value="Estudiante">
                      Estudiante
                    </option>
                    <option key="11" value="Doctorado">
                      Doctorado
                    </option>
                  </select>
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Nacionalidad
                  </label>
                  <span className="text-red-500">
                    {errors.nationality && 'Por favor seleccione un país'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    {...register('nationality', {required: false})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="1">
                      Colombia
                    </option>
                    <option key="2" value="2">
                      Estados Unidos
                    </option>
                    <option key="3" value="3">
                      Canada
                    </option>
                  </select>
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Lugar de Expedición Documento
                  </label>
                  <span className="text-red-500">
                    {errors.documentIssuancePlace &&
                      'Por favor ingrese el lugar de expedición del documento'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="text"
                    //autoComplete="email"
                    {...register('documentIssuancePlace', {required: false})}
                  />
                </div>
              </div>

              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap"></div>
            </div>

            <div
              className={
                formSection === 2
                  ? 'bg-white p-5 rounded-lg'
                  : 'bg-white p-5 rounded-lg hidden'
              }
            >
              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    País de residencia*
                  </label>
                  <span className="text-red-500">
                    {errors.countryOfResidence &&
                      'Por favor seleccione una opción'}
                  </span>

                  <select
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'countryOfResidence'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    } //   placeholder="Dirección Email"
                    {...register('countryOfResidence', {required: true})}
                    disabled={true}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="1">
                      Colombia
                    </option>
                  </select>
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Departamento*
                  </label>
                  <span className="text-red-500">
                    {errors.state && 'Por favor ingrese un Departamento'}
                  </span>

                  <input
                    type="text"
                    autoComplete="off"
                    className={
                      Object.keys(errors).find((element) => element === 'state')
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    } //   placeholder="Dirección Email"
                    {...register('state', {required: true})}
                    onChange={(e) => changeSearchStates(e)}
                    value={searchStates}
                  />
                  {showStateOptions && (
                    <div className="bg-white border border-cendiatra">
                      {filtredStates.map((item) => (
                        <div
                          className="bg-gray-100"
                          onClick={() => selectStateOption(item)}
                          key={item.Id}
                          tabIndex="0"
                        >
                          <span>{item.Name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Municipio*
                  </label>
                  <span className="text-red-500">
                    {errors.city && 'Por favor ingrese un Municipio'}
                  </span>

                  <input
                    type="text"
                    autoComplete="off"
                    className={
                      Object.keys(errors).find((element) => element === 'city')
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    {...register('city', {required: true})}
                    onChange={(e) => changeSearchCity(e)}
                    value={searchCity}
                  />
                  {showCityOptions && (
                    <div className="bg-white border border-cendiatra">
                      {filtredCities.map((item) => (
                        <div
                          className="bg-gray-100"
                          onClick={() => selectCityOption(item)}
                          key={item.Id}
                          tabIndex="0"
                        >
                          <span>{item.Name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Localidad
                  </label>
                  <span className="text-red-500">
                    {errors.localidad && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    //   placeholder="Dirección Email"
                    {...register('localidad', {required: false})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="1">
                      Antonio Nariño
                    </option>
                    <option key="2" value="2">
                      Barrios Unidos
                    </option>
                    <option key="3" value="3">
                      Chapinero
                    </option>
                    <option key="4" value="4">
                      Fontibon
                    </option>
                    <option key="5" value="5">
                      Puente Aranda
                    </option>
                  </select>
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Zona habitual de residencia
                  </label>
                  <span className="text-red-500">
                    {errors.residentialZone &&
                      'Por favor seleccione una opción'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    //   placeholder="Dirección Email"
                    {...register('residentialZone', {required: false})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="Urbana">
                      Urbana
                    </option>
                    <option key="2" value="Rural">
                      Rural
                    </option>
                  </select>
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Dirección
                  </label>
                  <span className="text-red-500">
                    {errors.address && 'Por favor ingrese una dirección'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="text"
                    {...register('address', {required: false})}
                  />
                </div>
              </div>

              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Estrato*
                  </label>
                  <span className="text-red-500">
                    {errors.stratum && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'stratum'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    } //   placeholder="Dirección Email"
                    {...register('stratum', {required: true})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="1">
                      1
                    </option>
                    <option key="2" value="2">
                      2
                    </option>
                    <option key="3" value="3">
                      3
                    </option>
                    <option key="4" value="4">
                      4
                    </option>
                    <option key="5" value="5">
                      5
                    </option>
                  </select>
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Celular*
                  </label>
                  <span className="text-red-500">
                    {errors.mobileNumber &&
                      'Por favor ingrese un celular valido'}
                  </span>

                  <input
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'mobileNumber'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    type="number"
                    {...register('mobileNumber', {
                      required: true,
                      minLength: 10,
                    })}
                  />
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Correo electrónico*
                  </label>
                  <span className="text-red-500">
                    {errors.email?.type === 'required' &&
                      'Por favor ingrese un correo electrónico'}
                    {errors.email?.type === 'pattern' &&
                      'Por favor verifica el correo electronico'}
                  </span>

                  <input
                    className={
                      Object.keys(errors).find((element) => element === 'email')
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    type="email"
                    {...register('email', {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/,
                    })}
                  />
                </div>
              </div>
              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Teléfono*
                  </label>
                  <span className="text-red-500">
                    {errors.phoneNumber?.type === 'required' &&
                      'Por favor ingrese un teléfono'}
                    {errors.phoneNumber?.type === 'minLength' &&
                      'El teléfono no puede tener menos de 7 números'}
                  </span>

                  <input
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'phoneNumber'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    type="number"
                    min="1"
                    {...register('phoneNumber', {required: true, minLength: 7})}
                  />
                </div>
                <div className="w-full md:w-1/4"></div>
                <div className="w-full md:w-1/4"></div>
              </div>
            </div>

            <div
              className={
                formSection === 3
                  ? 'bg-white p-5 rounded-lg'
                  : 'bg-white p-5 rounded-lg hidden'
              }
            >
              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    EPS*
                  </label>
                  <span className="text-red-500">
                    {errors.eps && 'Por favor ingrese una EPS'}
                  </span>

                  <input
                    autoComplete="off"
                    className={
                      Object.keys(errors).find((element) => element === 'eps')
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    } //   placeholder="Dirección Email"
                    {...register('eps', {required: true})}
                    onChange={(e) => changeSearchEPS(e)}
                    value={searchEPS}
                  />
                  {showEPSOptions && (
                    <div className="bg-white border border-cendiatra">
                      {filtredEPS.map((item) => (
                        <div
                          className="bg-gray-100"
                          onClick={() => selectEPSOption(item)}
                          key={item.Id}
                          tabIndex="0"
                        >
                          <span>{item.Name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    ARL
                  </label>
                  <span className="text-red-500">
                    {errors.arl && 'Por favor seleccione una opción'}
                  </span>

                  <input
                    type="text"
                    autoComplete="off"
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    //   placeholder="Dirección Email"
                    {...register('arl', {required: false})}
                    onChange={(e) => changeSearchARL(e)}
                    value={searchARL}
                  />
                  {showARLOptions && (
                    <div className="bg-white border border-cendiatra">
                      {filtredARL.map((item) => (
                        <div
                          className="bg-gray-100"
                          onClick={() => selectARLOption(item)}
                          key={item.Id}
                          tabIndex="0"
                        >
                          <span>{item.Name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Fondo de pensiones
                  </label>
                  <span className="text-red-500">
                    {errors.pension &&
                      'Por favor ingrese un fondo de pensiones'}
                  </span>

                  <input
                    type="text"
                    autoComplete="off"
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'pension'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    } //   placeholder="Dirección Email"
                    {...register('pension', {required: true})}
                    onChange={(e) => changeSearchPensions(e)}
                    value={searchPension}
                  />
                  {showPensionOptions && (
                    <div className="bg-white border border-cendiatra">
                      {filtredPension.map((item) => (
                        <div
                          className="bg-gray-100"
                          onClick={() => selectPensionOption(item)}
                          key={item.Id}
                          tabIndex="0"
                        >
                          <span>{item.Name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap">
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Raza
                  </label>
                  <span className="text-red-500">
                    {errors.race && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    //   placeholder="Dirección Email"
                    {...register('race', {required: false})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="Blanco o Caucasico">
                      Blanco o Caucasico
                    </option>
                    <option key="2" value="Hispano Americano">
                      Hispano Americano
                    </option>
                    <option key="3" value="Asiatico">
                      Asiatico
                    </option>
                    <option key="4" value="Indígena">
                      Indígena
                    </option>
                    <option key="5" value="Rom-Gitano">
                      Rom-Gitano
                    </option>
                    <option key="6" value="Raizal">
                      Raizal
                    </option>
                    <option key="7" value="Negro">
                      Negro
                    </option>
                    <option key="8" value="No Aplica">
                      No Aplica
                    </option>
                  </select>
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Acompañante
                  </label>
                  <span className="text-red-500">
                    {errors.companion &&
                      'Por favor ingrese el nombre del acompañante'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="text"
                    //autoComplete="email"

                    {...register('companion', {required: false})}
                  />
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Tel Acompañante
                  </label>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="number"
                    min="1"
                    {...register('companionNumber', {
                      required: false,
                      minLength: 7,
                    })}
                  />
                </div>
              </div>

              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Composición familiar
                  </label>
                  <span className="text-red-500">
                    {errors.family &&
                      'Por favor ingrese la composición familiar'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="text"
                    {...register('family', {required: false})}
                  />
                </div>
                <div className=" w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    No. Hijos
                  </label>
                  <span className="text-red-500">
                    {errors.children && 'Por favor ingrese el numero de hijos'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="number"
                    min="0"
                    {...register('children', {required: false})}
                  />
                </div>
                <div className="w-full md:w-1/4">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Discapacidad
                  </label>
                  <span className="text-red-500">
                    {errors.disability && 'Por favor seleccione una opción'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="text"
                    placeholder="Discapacidad"
                    //autoComplete="email"
                    //   placeholder="Dirección Email"
                    {...register('disability', {required: false})}
                  />
                </div>
              </div>
              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className=" w-full md:w-2/5"></div>
              </div>
            </div>

            <div
              className={
                formSection === 4
                  ? 'bg-white p-5 rounded-lg'
                  : 'bg-white p-5 rounded-lg hidden'
              }
            >
              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-2/5">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Empresa Contratante*
                  </label>
                  <span className="text-red-500">
                    {errors.company && 'Por favor ingrese la empresa'}
                  </span>

                  <input
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'company'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    type="text"
                    autoComplete="off"
                    {...register('company', {required: true})}
                    onChange={(e) => changeSearch(e)}
                    value={search}
                  />
                  {showoptions && (
                    <div className="bg-white border border-cendiatra">
                      {filtredClients.map((item) => (
                        <div
                          className="bg-gray-100"
                          onClick={() => selectOption(item)}
                          key={item.Id}
                          tabIndex="0"
                        >
                          <span>{item.Name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className=" w-full md:w-2/5">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Cargo*
                  </label>
                  <span className="text-red-500">
                    {errors.charge && 'Por favor ingrese el cargo'}
                  </span>

                  <input
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'charge'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    }
                    type="text"
                    //autoComplete="email"

                    {...register('charge', {required: true})}
                  />
                </div>
              </div>

              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-2/5">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Tipo de Facturación*
                  </label>
                  <span className="text-red-500">
                    {errors.billing && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'billing'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    } //   placeholder="Dirección Email"
                    {...register('billing', {required: true})}
                  >
                    <option key="-1" value="">
                      Tipo de Facturación*
                    </option>
                    <option key="1" value="Particular">
                      Particular
                    </option>
                    <option key="2" value="Empresa">
                      Empresa
                    </option>
                  </select>
                </div>
                <div className=" w-full md:w-2/5">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Tipo Examen*
                  </label>
                  <span className="text-red-500">
                    {errors.examType && 'Por favor seleccione un opción'}
                  </span>

                  <select
                    className={
                      Object.keys(errors).find(
                        (element) => element === 'examType'
                      )
                        ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                        : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                    } //   placeholder="Dirección Email"
                    {...register('examType', {required: true})}
                  >
                    <option key="-1" value=""></option>
                    <option key="1" value="1">
                      No refiere
                    </option>
                    <option key="2" value="2">
                      Preingreso
                    </option>
                    <option key="3" value="3">
                      Ingreso
                    </option>
                    <option key="4" value="4">
                      Periodico
                    </option>
                    <option key="5" value="5">
                      Egreso
                    </option>
                    <option key="6" value="6">
                      Post Incapacidad
                    </option>
                    <option key="7" value="7">
                      Evaluación Medica Especial
                    </option>
                    <option key="8" value="8">
                      Por cambio de Ocupación
                    </option>
                    <option key="9" value="9">
                      Reintegro Laboral
                    </option>
                    <option key="10" value="10">
                      Examen Medico Dimar Nacional
                    </option>
                    <option key="11" value="11">
                      Examen Medico Dimar Internacional
                    </option>
                    <option key="12" value="12">
                      Otro
                    </option>
                    <option key="13" value="13">
                      Manipulación de Alimentos
                    </option>
                    <option key="14" value="14">
                      Trabajo en Alturas
                    </option>
                  </select>
                </div>
              </div>
              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                  Servicios*
                </label>
                <div className="w-full flex justify-center">
                  <span className="text-red-500">
                    {errors.services &&
                      'Por favor ingrese selecciona al menos 1 servicio'}
                  </span>
                </div>
                <div className="w-full md:w-1/4 flex flex-wrap">
                  <input
                    className="hidden"
                    type="text"
                    {...register('services', {required: true})}
                  />
                  <div
                    className="w-1/3 flex justify-center flex-wrap"
                    onMouseEnter={() => setToolTipPro(true)}
                    onMouseLeave={() => setToolTipPro(false)}
                  >
                    <img
                      className="w-10 h-10 m-2  cursor-pointer"
                      src={Procedures}
                      alt="img"
                      onClick={() => setTitle('PROCEDIMIENTOS')}
                    />
                    <p
                      className="w-full text-cendiatra text-center"
                      style={{fontSize: '10px'}}
                    >
                      Procedimientos
                    </p>
                    <div
                      className={
                        toolTipPro
                          ? 'text-sm bg-gray-600 mt-14 text-gray-100 px-1 absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-gray-600 mt-2 text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      {processServices.map((item) => (
                        <div key={item.itemId}>
                          <p>
                            <span>
                              {item.name}- ${item.price}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="w-1/3 flex justify-center flex-wrap"
                    onMouseEnter={() => setToolTipLab(true)}
                    onMouseLeave={() => setToolTipLab(false)}
                  >
                    <img
                      className="w-8 h-10 m-2  cursor-pointer"
                      src={Laboratory}
                      alt="img"
                      onClick={() => setTitle('LABORATORIOS')}
                    />
                    <p
                      className="w-full text-cendiatra text-center"
                      style={{fontSize: '10px'}}
                    >
                      Laboratorios
                    </p>
                    <div
                      className={
                        toolTipLab
                          ? 'text-sm bg-gray-600 mt-14 text-gray-100 px-1 absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-gray-600 mt-2 text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      {laboratoryServices.map((item) => (
                        <div key={item.itemId}>
                          <p>
                            <span>
                              {item.name}- ${item.price}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="w-1/3 flex justify-center flex-wrap"
                    onMouseEnter={() => setToolTipVac(true)}
                    onMouseLeave={() => setToolTipVac(false)}
                  >
                    <img
                      className="w-10 h-10 m-2  cursor-pointer"
                      src={Vaccine}
                      alt="img"
                      onClick={() => setTitle('VACUNAS')}
                    />
                    <p
                      className="w-full text-cendiatra text-center"
                      style={{fontSize: '10px'}}
                    >
                      Vacunas
                    </p>
                    <div
                      className={
                        toolTipVac
                          ? 'text-sm bg-gray-600 mt-14 text-gray-100 px-1 absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-gray-600 mt-2 text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      {vaccineServices.map((item) => (
                        <div key={item.itemId}>
                          <p>
                            <span>
                              {item.name}- ${item.price}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-full flex flex-wrap justify-center">
                    <span className="text-cendiatra flex text-lg my-5">
                      Valor de Todos los servicios:{' '}
                      <p className="font-bold">
                        {' '}
                        ${formatter.format(totalServicesValue)}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                formSection === 5
                  ? 'bg-white p-5 rounded-lg'
                  : 'bg-white p-5 rounded-lg hidden'
              }
            >
              <div className="rounded-md  -space-y-px flex justify-evenly flex-wrap">
                <div className="w-full md:w-4/5">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Foto del Paciente
                  </label>
                  <span className="text-red-500">
                    {errors.photo && 'Por favor cargue una foto'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="file"
                    // accept="image/*"
                    {...register('photo', {required: false})}
                  />
                </div>
                <div className=" w-full md:w-4/5">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Firma del Paciente
                  </label>
                  <span className="text-red-500">
                    {errors.firm && 'Por favor cargue la firma'}
                  </span>

                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="file"
                    // accept="image/*"
                    {...register('firm', {required: false})}
                  />
                </div>
              </div>

              <div className="flex justify-center my-5">
                <button
                  type="submit"
                  className="hover:opacity-80 group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-btnBg bg-cover bg-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => onInvalid()}
                  id="triggerButton"
                >
                  Registrar
                </button>
              </div>
            </div>

            <div className="rounded-md  -space-y-px flex justify-center flex-wrap my-3 items-center">
              {formSection === 1 ? (
                <button
                  className="opacity-70 group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-cendiatra bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  type="button"
                  disabled={true}
                  onClick={() => removeFormSection()}
                >
                  {' '}
                  Anterior
                </button>
              ) : (
                <button
                  className="group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-cendiatra bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  type="button"
                  onClick={() => removeFormSection()}
                >
                  {' '}
                  Anterior
                </button>
              )}
              <span className=" text-md text-cendiatra mx-10 font-semibold">
                {formSection}/5
              </span>
              {formSection === 5 ? (
                <button
                  className="opacity-70 items-center group relative w-24 h-9 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  type="button"
                  disabled={true}
                  onClick={() => addFormSection()}
                >
                  {' '}
                  Siguiente
                </button>
              ) : (
                <button
                  className="hover:opacity-80 items-center group relative w-24 h-9 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  type="button"
                  onClick={() => addFormSection()}
                >
                  {' '}
                  Siguiente
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
