import {useMemo, useState, useContext} from 'react'
import FiltroDatosBasicosPaciente from '../../componentes/Filtros/FiltroDatosBasicosPaciente'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import TablaAtencionesEjecutivo from '../../componentes/ejecutivoComercial/TablaAtencionesEjecutivo'
import moment from 'moment'
import {FachadaObtenerAtencionesPorDatosBasicosUsuario} from '../../microservicios/Fachada'
import {estadoAtencionConstantes} from '../../constantes/estadoAtencion'
import {ContextApplication} from '../../contexto'

function VerAtencionesEjecutivo() {
  const [datosAtenciones, setDatosAtenciones] = useState([])

  const contextoAplicacion = useContext(ContextApplication)

  const estados = useMemo(() => {
    return Object.entries(estadoAtencionConstantes).reduce(
      (obj, [clave, valor]) => {
        obj[valor] = clave
        return obj
      },
      {}
    )
  }, [])

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const funcionBuscarInformacion = async (datos) => {
    setDatosAtenciones([])
    if ((!datos?.nombres || !datos?.apellidos) && !datos?.numeroDocumento) {
      return contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <div className="text-center w-full">
            <h3 className="text-cendiatra  text-3xl font-bold normal-case">
              La búsqueda debe
            </h3>
            <h3 className="text-cendiatra mb-6 text-3xl font-bold normal-case">
              hacerse con nombre y apellido
            </h3>
            <p className="text-cendiatra-verde-3 text-xl my-10 font-bold normal-case">
              Debe diligenciar los dos campos para realizar la búsqueda
            </p>
          </div>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModal,
          },
        ],
      })
    }
    try {
      const respuesta = await FachadaObtenerAtencionesPorDatosBasicosUsuario({
        numeroDocumento: datos.numeroDocumento,
        nombre: datos.nombres,
        apellidos: datos.apellidos,
      })

      if (respuesta.status === 200 && Array.isArray(respuesta.data)) {
        const atenciones = respuesta.data?.map((item) => {
          const empresa =
            item?.informacionBasicaEmpresa?.empresaPadre?.nombre ??
            item?.informacionBasicaEmpresa?.nombre ??
            ''

          const empresaHija = Boolean(
            item?.informacionBasicaEmpresa?.empresaPadre
          )
            ? item?.informacionBasicaEmpresa?.nombre
            : ''

          return {
            atencionId: item.atencionId,
            fechaAtencion: item.fechaAtencion
              ? moment(item.fechaAtencion).format('DD/MM/YYYY')
              : '',
            nombre: `${item?.nombre ?? ''} ${item?.apellidos ?? ''}`.trim(),
            numeroDocumento: item?.numeroDocumento ?? '',
            empresa,
            empresaHija,
            estado: estados[item.estadoAtencionId] ?? '',
          }
        })
        setDatosAtenciones(atenciones)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_EJECUTIVO_COMERCIAL
          .VER_ATENCION_EMPRESAS
      }
    >
      <div className="w-full flex flex-wrap justify-center items-center">
        <div className="w-10/12 pb-5">
          <FiltroDatosBasicosPaciente
            funcionBuscarInformacion={funcionBuscarInformacion}
            funcionLimpiarInformacion={() => setDatosAtenciones([])}
          />
        </div>
        <div className="w-full">
          <TablaAtencionesEjecutivo datosAtenciones={datosAtenciones} />
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default VerAtencionesEjecutivo
