import React from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import DocumentacionElectronica from '../../componentes/administradorContable/DocumentacionElectronica'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'

const GestionDocumentacionElectronica = () => {
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.TITULO_ADMINISTRADOR_CONTABLE
          .GESTIÓN_DE_DOCUMENTACION_ELECTRONICA
      }
    >
      <DocumentacionElectronica />
    </ContenedorPagina>
  )
}

export default GestionDocumentacionElectronica
