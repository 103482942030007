import {useEffect, useState} from 'react'
import {ObtenerTiposDeDocumento} from '../../cache/servicios-cache'

export const useTiposDocumento = () => {
  const [tiposDocumentos, setTiposDocumentos] = useState([])

  useEffect(() => {
    let componenteMontado = true
    const llamarObtenerTiposDeDocumento = async () => {
      const tiposDocumentos = await ObtenerTiposDeDocumento()

      if (componenteMontado) {
        setTiposDocumentos(tiposDocumentos)
      }
    }

    llamarObtenerTiposDeDocumento()

    return () => {
      componenteMontado = false
    }
  }, [])

  return [tiposDocumentos]
}
