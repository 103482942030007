import React from 'react'

const ErroresInput = ({
  erroresFormulario,
  nombre,
  tipoError,
  mensaje,
  mostrarPorTipo = true,
}) => {
  return (
    <span className="text-cendiatra-semaforo-rojo w-full text-13px">
      {erroresFormulario?.[nombre]?.type === tipoError && mostrarPorTipo
        ? mensaje
        : !mostrarPorTipo
        ? mensaje
        : null}
    </span>
  )
}

export default ErroresInput
