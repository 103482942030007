import React from 'react'
import {ESTADOS_TRANSACCION} from '../../constantes'
import {useHistory} from 'react-router-dom'
import {aplicarFormatoMoneda} from '../../utilidades'

const EstadoTransaccion = ({estado, mensajes}) => (
  <div className="w-full flex-col justify-center items-center text-center text-cendiatra text-3xl my-10 font-bold flex-wrap">
    <p className="w-full mb-10">{estado.toLocaleUpperCase()}</p>
    {mensajes.map((mensaje, index) => (
      <p key={index} className="w-full mb-10">
        {mensaje}
      </p>
    ))}
  </div>
)

const EstadosRecargaSaldo = ({transaccionEstadoSaldo}) => {
  const {estadoTransaccion, valor} = transaccionEstadoSaldo

  const history = useHistory()

  const irRecargarsaldos = () => {
    history.push('/recarga-saldo-en-sede')
  }

  const finalizar = () => {
    history.push('/')
  }

  const estadosMensajes = {
    [ESTADOS_TRANSACCION.RECHAZADA]: [
      'Tu cuenta NO ha sido recargada',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.FALLIDA]: [
      'Tu cuenta NO ha sido recargada',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.APROBADA]: [
      'Tu cuenta ha sido recargada exitosamente con:',
      `${aplicarFormatoMoneda(valor ?? '')}`,
    ],
    [ESTADOS_TRANSACCION.PENDIENTE]: [
      'Tu cuenta esta pendiente por recargar',
      'Espera un lapso de 2 a 24 horas para ver reflejada la recarga si requiere mas información acerca de la transacción, por favor contáctarse con su entidad bancaria.',
    ],
    [ESTADOS_TRANSACCION.EXPIRADA]: [
      'Tu cuenta NO ha sido recargada',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.NO_AUTORIZADA]: [
      'Tu cuenta NO ha sido recargada',
      'comunícate con la entidad bancaria',
      '¿Qué deseas hacer?',
    ],
  }

  return (
    <div>
      {estadoTransaccion in estadosMensajes && (
        <EstadoTransaccion
          estado={estadoTransaccion}
          mensajes={estadosMensajes[estadoTransaccion]}
        />
      )}

      {estadoTransaccion !== ESTADOS_TRANSACCION.APROBADA ? (
        <div className="w-full flex justify-evenly items-center m-5">
          {estadoTransaccion !== ESTADOS_TRANSACCION.PENDIENTE ? (
            <button
              key={'Regresar a recargar cuenta'}
              className="w-40 h-14 rounded-lg bg-cendiatra bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={irRecargarsaldos}
            >
              {'Regresar a recargar cuenta'}
            </button>
          ) : (
            ''
          )}

          <button
            key={'Finalizar'}
            className="w-40 h-14 rounded-lg bg-cendiatra bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
            type="button"
            onClick={finalizar}
          >
            {'Finalizar'}
          </button>
        </div>
      ) : (
        <div className="w-full flex justify-evenly items-center m-5">
          <button
            key={'Finalizar'}
            className="w-40 h-14 rounded-lg bg-cendiatra bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
            type="button"
            onClick={finalizar}
          >
            {'Finalizar'}
          </button>
        </div>
      )}
    </div>
  )
}

export default EstadosRecargaSaldo
