import React, {useContext, useCallback, useEffect, useState} from 'react'
import {ObtenerEstructuras} from '../../../cache/servicios-cache'
import {ContextoPlantilla} from '../../../contexto'
import {BotonOpcion} from '../../Botones'
import {AreaDeTexto, InputRegex, ListaDesplegable} from '../../inputs'

import moment from 'moment'
import {FLAGS_PLUGINS_PESTANNAS_MOTOR} from '../../../constantes/motorPlantilla'

const ParametrosCertificado = ({numeroPagina}) => {
  const contextoPlantilla = useContext(ContextoPlantilla)
  const [tiposEstructura, setTiposEstructura] = useState([])

  const obtenerInformacionInicial = useCallback(async () => {
    setTiposEstructura(await ObtenerEstructuras())
  }, [])

  useEffect(() => {
    if (
      numeroPagina === contextoPlantilla.numeroPagina &&
      !contextoPlantilla.plantilla.imprimeCertificado
    ) {
      contextoPlantilla.setErrores([])
    }
  }, [contextoPlantilla.numeroPagina])

  useEffect(() => {
    if (!contextoPlantilla.plantilla.imprimeCertificado) {
      contextoPlantilla.setErrores([])
    }
  }, [contextoPlantilla.plantilla.imprimeCertificado])

  useEffect(() => {
    obtenerInformacionInicial()
    contextoPlantilla.setErrores([])
  }, [obtenerInformacionInicial])

  const actualizarCampoPlantilla = (campo, valor) => {
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      [campo]: valor,
    })
  }

  const actualizarImprimirCertificado = () => {
    if (!contextoPlantilla.plantilla.imprimeCertificado) {
      actualizarCampoPlantilla('imprimeCertificado', true)
    } else {
      contextoPlantilla.setPlantilla({
        ...contextoPlantilla.plantilla,
        imprimeCertificado: false,
        estructuraId: -1,
        incluirExamenes: false,
        consentimientoCertificado: '',
        codigoCertificado: '',
        fechaCertificado: '',
        versionCertificado: '',
      })
    }
  }

  const actualizarEstructura = (valor) => {
    if (valor == 1) {
      contextoPlantilla.setPlantilla({
        ...contextoPlantilla.plantilla,
        estructuraId: valor,
        consentimientoCertificado:
          'Acepto las anteriores valoraciones Médicas realizadas, hago constar que la información suministrada sobre mi condición de salud es verdadera, y me comprometo a cumplir las recomendaciones y las restricciones a que haya lugar. Cualquier omisión o falsedad será causa justa de finalización de contrato. Artículo 62 CST. La firma de este documento no significa aceptación de los conceptos médicos expresados y de su contenido. Sirve adicionalmente para constatar quien presento la valoración.',
      })
      return
    }
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      estructuraId: valor,
      incluirExamenes: false,
      incluirRecomendaciones: false,
      consentimientoCertificado: '',
      codigoCertificado: '',
      fechaCertificado: '',
      versionCertificado: '',
    })
    if (valor == -1) {
      contextoPlantilla.setErrores(['estructura'])
    } else {
      contextoPlantilla.setErrores([])
    }
  }

  useEffect(() => {
    const pestannaRecomendaciones = contextoPlantilla.plantilla.pestanas.find(
      (pestanna) =>
        pestanna.secciones.some((seccion) =>
          seccion.campos.every(
            (campo) =>
              campo.atributos[FLAGS_PLUGINS_PESTANNAS_MOTOR.RECOMENDACIONES]
          )
        )
    )

    if (
      contextoPlantilla.plantilla.incluirRecomendaciones &&
      !pestannaRecomendaciones
    ) {
      contextoPlantilla.setPlantilla({
        ...contextoPlantilla.plantilla,
        pestanas: [
          ...contextoPlantilla.plantilla.pestanas,
          contextoPlantilla.pestannaRecomendacionMedica,
        ],
      })
    } else if (
      !contextoPlantilla.plantilla.incluirRecomendaciones &&
      pestannaRecomendaciones
    ) {
      contextoPlantilla.setPlantilla({
        ...contextoPlantilla.plantilla,
        pestanas: contextoPlantilla.plantilla.pestanas
          .filter(
            (p) => p.numeroPestana !== pestannaRecomendaciones.numeroPestana
          )
          .map((pestana, index) => ({...pestana, numeroPestana: index + 1})),
      })
    }
  }, [contextoPlantilla.plantilla])

  return (
    <div className="w-full">
      <div className="w-full flex flex-wrap justify-center">
        <div className="flex flex-wrap">
          <BotonOpcion
            id={1}
            titulo={'IMPRIMIR CERTIFICADO'}
            estaActivo={contextoPlantilla.plantilla.imprimeCertificado}
            esHorizontal={true}
            funcionClick={actualizarImprimirCertificado}
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-center">
        <div className="w-full flex flex-wrap justify-center mt-20">
          <ListaDesplegable
            id={'estructura'}
            onChange={(e) => {
              actualizarEstructura(e.target.value)
            }}
            estilosContenedor={'w-2/12 mt-3'}
            estilosLista={`appearance-none rounded relative block w-full  p-1.5 border ${
              !contextoPlantilla.plantilla.imprimeCertificado ||
              (contextoPlantilla.plantilla.estructuraId != '' &&
                contextoPlantilla.plantilla.estructuraId != -1)
                ? 'border-cendiatra'
                : 'border-cendiatra-rojo-1'
            } ${
              !contextoPlantilla.plantilla.imprimeCertificado
                ? 'text-white bg-cendiatra-gris-placeholder'
                : 'text-cendiatra-gris-3'
            }  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
            opciones={tiposEstructura}
            valor={contextoPlantilla.plantilla.estructuraId}
            placeholder={'SELECCIONAR'}
            titulo={'ESTRUCTURA'}
            deshabilitado={!contextoPlantilla.plantilla.imprimeCertificado}
            contexto={contextoPlantilla}
            numeroPagina={numeroPagina}
          />
          <div className="w-10/12 flex">
            <div className="w-6/12 mt-10">
              <BotonOpcion
                id={1}
                titulo={'MOSTRAR EXÁMENES ATENDIDOS EN EL CERTIFICADO'}
                estaActivo={contextoPlantilla.plantilla.incluirExamenes}
                esHorizontal={true}
                funcionClick={() => {
                  actualizarCampoPlantilla(
                    'incluirExamenes',
                    !contextoPlantilla.plantilla.incluirExamenes
                  )
                }}
                deshabilitado={
                  !contextoPlantilla.plantilla.imprimeCertificado ||
                  contextoPlantilla.plantilla.estructuraId != 1
                }
                tamanoDivTitulo="w-8/12 pl-36"
                tamanoDivBoton="w-4/12"
                posicionLabel="text-left"
              />
            </div>
            <div className="w-6/12 mt-10">
              <BotonOpcion
                id={1}
                titulo={'MOSTRAR RECOMENDACIÓN MÉDICA OCUPACIONAL'}
                estaActivo={contextoPlantilla.plantilla.incluirRecomendaciones}
                esHorizontal={true}
                funcionClick={() => {
                  actualizarCampoPlantilla(
                    'incluirRecomendaciones',
                    !contextoPlantilla.plantilla.incluirRecomendaciones
                  )
                }}
                deshabilitado={
                  !contextoPlantilla.plantilla.imprimeCertificado ||
                  contextoPlantilla.plantilla.estructuraId != 1
                }
                posicionLabel="text-left pl-10"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-center">
        <div className="flex flex-wrap w-1/2 mt-20">
          <AreaDeTexto
            estilosContenedor={'w-full'}
            estilosAreaDeTexto={`h-20 my-3 rounded relative block w-full  p-1.5 border border-cendiatra ${
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
                ? 'text-white bg-cendiatra-gris-placeholder'
                : 'text-cendiatra-gris-3'
            } rounded-lg focus:outline-none  sm:text-sm`}
            placeholder={
              'Acepto las anteriores valoraciones Médicas realizadas, hago constar que la información suministrada sobre mi condición de salud es verdadera, y me comprometo a cumplir las recomendaciones y las restricciones a que haya lugar. Cualquier omisión o falsedad será causa justa de finalización de contrato. Artículo 62 CST. La firma de este documento no significa aceptación de los conceptos médicos expresados y de su contenido. Sirve adicionalmente para constatar quien presento la valoración.'
            }
            valor={contextoPlantilla.plantilla.consentimientoCertificado}
            cambioValor={(e) => {
              actualizarCampoPlantilla(
                'consentimientoCertificado',
                e.target.value
              )
            }}
            titulo={'CONSENTIMIENTO DEL CERTIFICADO'}
            deshabilitado={
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-center">
        <span className="  text-center text-14px leading-23px font-bold text-cendiatra my-25px">
          CODIFICACIÓN DEL FORMATO
        </span>
      </div>
      <div className="w-full flex flex-wrap justify-center">
        <div className="flex flex-wrap mt-10">
          <InputRegex
            id="codigo"
            ancho={'w-1/3'}
            cambioValor={(e) => {
              actualizarCampoPlantilla('codigoCertificado', e)
            }}
            estilosContenedor={'w-full flex pr-5'}
            estilosInput={`my-3 rounded relative block w-full p-1.5 border ${
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
                ? 'text-white bg-cendiatra-gris-placeholder'
                : 'text-cendiatra-gris-3'
            } rounded-lg focus:outline-none  sm:text-sm`}
            tipo={'text'}
            placeholder={''}
            titulo={'CÓDIGO'}
            valor={contextoPlantilla.plantilla.codigoCertificado}
            deshabilitado={
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
            }
            contexto={contextoPlantilla}
            numeroPagina={numeroPagina}
            requerido={true}
          />
          <InputRegex
            id="fecha"
            ancho={'w-1/3'}
            cambioValor={(e) => {
              actualizarCampoPlantilla(
                'fechaCertificado',
                e ? moment(e).format('YYYY-MM-DD') : ''
              )
            }}
            estilosContenedor={'w-full flex pr-5'}
            estilosInput={`my-3 rounded relative block w-full p-1.5 border ${
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
                ? 'text-white bg-cendiatra-gris-placeholder'
                : 'text-cendiatra-gris-3'
            } rounded-lg focus:outline-none  sm:text-sm`}
            tipo={'date'}
            placeholder={''}
            titulo={'FECHA'}
            valor={contextoPlantilla.plantilla.fechaCertificado}
            deshabilitado={
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
            }
            contexto={contextoPlantilla}
            numeroPagina={numeroPagina}
            requerido={true}
          />
          <InputRegex
            id="version"
            ancho={'w-1/3'}
            cambioValor={(e) => {
              actualizarCampoPlantilla('versionCertificado', e)
            }}
            estilosContenedor={'w-full flex pr-5'}
            estilosInput={`my-3 rounded relative block w-full p-1.5 border ${
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
                ? 'text-white bg-cendiatra-gris-placeholder'
                : 'text-cendiatra-gris-3'
            } rounded-lg focus:outline-none  sm:text-sm`}
            tipo={'text'}
            placeholder={''}
            titulo={'VERSIÓN'}
            valor={contextoPlantilla.plantilla.versionCertificado}
            deshabilitado={
              !contextoPlantilla.plantilla.imprimeCertificado ||
              contextoPlantilla.plantilla.estructuraId != 1
            }
            contexto={contextoPlantilla}
            numeroPagina={numeroPagina}
            requerido={true}
          />
        </div>
      </div>
    </div>
  )
}

export default ParametrosCertificado
