import React from 'react'
import {useTable, usePagination, useRowSelect} from 'react-table'

const TablaMediosDePago = ({titulo, columns, data, children}) => {
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, page} =
    useTable(
      {
        columns,
        data,
        initialState: {pageIndex: 0},
      },
      usePagination,
      useRowSelect
    )

  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <div
        className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 text-white h-14 rounded-10px"
        style={{lineHeight: '16px'}}
      >
        <span className="text-14px text-white">{titulo}</span>
      </div>
      {children}
      <table className="w-full" {...getTableProps()}>
        <thead className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 h-1 text-white rounded-10px">
          {headerGroups.map((headerGroup) => (
            <tr
              className="w-full flex justify-evenly items-center h-full p-3px"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: `${column.className} h-full flex flex-wrap justify-center items-center content-center  m-3px rounded-9px font-light`,
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="w-full " {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className="w-full flex items-center border-b-2 border-b-gris justify-evenly items-center text-center text-13px text-cendiatra-gris-1  my-5"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{backgroundColor: 'white'}}
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaMediosDePago
