import React, {useEffect, useState, useContext} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {FormularioCrearPaquete} from '../../componentes/Paquetes'
import {ObtenerProximoCodigoInterno} from '../../microservicios'
import {crearNuevoPaquete} from '../../microservicios'
import {ContextoPaquete} from '../../contexto'
import {ContextApplication} from '../../contexto'
import {useModal} from '../../hooks/useModal'
import {useHistory} from 'react-router-dom'
import {RUTAS_APP} from '../../constantes'
import {ObtenerCuentaDeIngresoPorId} from '../../microservicios'
import {
  AgregarPaquetesACache,
  AgregarServiciosACache,
} from '../../cache/servicios-cache'

const CrearPaquete = () => {
  const contextoPaquete = useContext(ContextoPaquete)
  const contextoAplicacion = useContext(ContextApplication)

  const [cerrarModal] = useModal()
  const history = useHistory()

  useEffect(() => {
    const obtenerProximoCodigoInterno = async () => {
      try {
        const respuesta = await ObtenerProximoCodigoInterno('paquete')

        if (respuesta.status === 200) {
          contextoPaquete?.setCodigoPaquete(`P-${respuesta?.data}`)
          contextoPaquete?.setServiciosSeleccionados([])
        }
      } catch (error) {
        console.log(error)
      }
    }
    obtenerProximoCodigoInterno()
  }, [])

  useEffect(() => {
    const obtenerInformacionInicial = async () => {
      try {
        await AgregarPaquetesACache()
        await AgregarServiciosACache()
      } catch (error) {
        console.log(error)
      }
    }
    obtenerInformacionInicial()
  }, [])

  const finalizarCreacion = () => {
    cerrarModal()
    history.push(RUTAS_APP?.MAIN)
  }
  const limpiarFormulario = () => {
    history.go(0)
  }

  const crearPaquete = async () => {
    const idsServicios = contextoPaquete.serviciosSeleccionados?.map(
      (elemento) => elemento.id
    )
    const modelo = {
      codigoInterno: contextoPaquete?.codigoPaquete,
      nombre: contextoPaquete?.informacionPaquete?.nombrePaquete?.toUpperCase(),
      cuentaIngresoId: contextoPaquete?.informacionPaquete?.cuentaIngreso,
      servicios: idsServicios,
      userEmail: '',
    }
    try {
      const respuesta = await crearNuevoPaquete(modelo)
      if (respuesta.status === 200) {
        const nombreCuentaDeIngreso = await ObtenerCuentaDeIngresoPorId(
          JSON.parse(contextoPaquete?.informacionPaquete?.cuentaIngreso)
        )

        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'PAQUETE CREADO',
          contenido: (
            <div className="w-full flex flex-wrap m-2">
              <span className="w-full text-cendiatra text-xl font-bold">
                ID: {respuesta.data}{' '}
              </span>
              <span className="w-full text-cendiatra text-xl font-bold">
                CÓDIGO PAQUETE: {contextoPaquete?.codigoPaquete}{' '}
              </span>
              <span className="w-full text-cendiatra text-xl font-bold">
                NOMBRE PAQUETE:{' '}
                {contextoPaquete?.informacionPaquete?.nombrePaquete?.toUpperCase()}{' '}
              </span>
              <span className="w-full text-cendiatra text-xl font-bold">
                CUENTA DE INGRESO: {nombreCuentaDeIngreso?.Nombre}{' '}
              </span>
            </div>
          ),
          botones: [
            {
              nombre: 'Finalizar',
              click: finalizarCreacion,
            },
            {
              nombre: 'Crear Nuevo',
              click: limpiarFormulario,
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.EJECUTIVO_COMERCIAL.CREAR_PAQUETE}
    >
      <FormularioCrearPaquete
        nombreBoton={'Crear'}
        codigoInterno={contextoPaquete?.codigoPaquete}
        funcionTransaccion={crearPaquete}
      />
    </ContenedorPagina>
  )
}

export default CrearPaquete
