import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {ObtenerTiposCampo} from '../cache/servicios-cache/TipoCampo'
import FormatoInterconsulta from '../componentes/Modales/Formulas/FormatoInterconsulta'
import {GenerarFormulaMedica} from '../componentes/Modales/Formulas/GenerarFormulaMedica'
import {GenerarFormularioOptometrica} from '../componentes/Modales/Formulas/GenerarFormularioOptometrica'
import {estadosExamenesConst} from '../constantes/estadoExamen'
import {
  NO_APLICAR_PARSEO,
  objetoPredeterminadoPlantilla,
} from '../constantes/motorPlantilla'
import {nombreBotonesPlantilla} from '../constantes/plantillaBotones'
import {nombresDeTiposDeCamposConstantes} from '../constantes/tiposDeCampos'
import {
  FachadaGuardarParcialmente,
  FachadaCerrarHistoria,
  FachadaGenerarCertificadoExamen,
  FachadaGenerarDocumentoExamen,
  FachadaCambiarEstadoServicioAtencion,
} from '../microservicios/Fachada'
import {subirImagenHTMLApp} from '../utilidades/htmlAArchivo'
import {ContextApplication} from './'
import {useMsal} from '@azure/msal-react'
import {ROLES_APP} from '../constantes/roles'
import {useObtenerHistoriaClinicaUsuario} from '../hooks/useObtenerHistoriaClinicaUsuario'
import {TEXTO_VISUAL} from '../constantes'
import IncapacidadMedica from '../componentes/Modales/Formulas/IncapacidadMedica'
import SolicitudDeValoracion from '../componentes/Modales/Formulas/SolicitudDeValoracion.jsx'

export const ContextoFormulario = createContext('')

export const FormularioConContexto = ({children}) => {
  const contextoAplicacion = useContext(ContextApplication)
  const {state} = useLocation()
  const history = useHistory()
  const {accounts} = useMsal()
  const rolUsuario = accounts[0]?.idTokenClaims?.roles[0]
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(3)
  const [errores, setErrores] = useState([])
  const [plantilla, setPlantilla] = useState(objetoPredeterminadoPlantilla)
  const [datosAtencion, setDatosAtencion] = useState({})
  const [formularios, setFormularios] = useState([])
  const [valoresCampos, setValoresCampos] = useState({})
  const [valoresObservaciones, setValoresObservaciones] = useState({})
  const [informacionUsuario, setInformacionUsuario] = useState({
    urlFoto: '',
    datosUsuario: [],
  })
  const [parametrosFormulario, setParametrosFormulario] = useState(null)
  const [historiaClinicaUsuario, setHistoriaClinicaUsuario] = useState([])
  const [nodosPorComponente, setNodosPorComponente] = useState({})
  const [habilitarEdicion, setHabilitarEdicion] = useState(false)
  const [examenEnVista, setExamenEnVista] = useState(null)
  const [ultimoExamenCerradoId, setUltimoEstadoCerradoId] = useState(0)
  const [esVistaPrevia, setVistaPrevia] = useState(false)
  const [recargarListadoFormularios, setRecargarListadoFormularios] =
    useState(false)

  const [estadoHabilitadoBotones, setEstadoHabilitadoBotones] = useState({
    [nombreBotonesPlantilla.IMPRIMIRCERTIFICADO]: true,
    [nombreBotonesPlantilla.INCAPACIDADMEDICA]: true,
    [nombreBotonesPlantilla.CERRAREXAMEN]: true,
    [nombreBotonesPlantilla.GUARDADOPARCIAL]: true,
    [nombreBotonesPlantilla.FORMULAMEDICA]: true,
    [nombreBotonesPlantilla.FORMULAOPTOMETRICA]: true,
    [nombreBotonesPlantilla.INTERCONSULTA]: true,
  })

  const [estadoExamen, setEstadoExamen] = useState(0)

  const [examenInicial, setExamenInicial] = useState({})

  const [actualizarExamenInicial, setActualizarExamenInicial] = useState(true)

  const [obtenerHistoriaClinicaPaciente] = useObtenerHistoriaClinicaUsuario()

  const obtenerTipoExamenInformacion = () => {
    const tipoExamen = state?.examenes?.find(
      (examen) => examen.servicioId === state?.servicioId
    )
    return tipoExamen ?? null
  }

  useEffect(() => {
    if (
      estadoHabilitadoBotones[nombreBotonesPlantilla.IMPRIMIRCERTIFICADO] &&
      plantilla?.hasOwnProperty('imprimeCertificado')
    ) {
      if (!plantilla.imprimeCertificado) {
        setPlantilla((prv) => ({
          ...prv,
          botones: prv.botones.map((boton) => {
            if (
              boton.nombreBoton === nombreBotonesPlantilla.IMPRIMIRCERTIFICADO
            ) {
              return {
                ...boton,
                estadoBoton: false,
              }
            } else {
              return boton
            }
          }),
        }))
      }
    }
  }, [plantilla?.imprimeCertificado, estadoHabilitadoBotones])

  const abrirFormulaMedica = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'FORMATO DE FÓRMULA MÉDICA',
      contenido: (
        <>
          <GenerarFormulaMedica />
        </>
      ),
      botones: [],
    })
  }

  const abrirFormulaOptometrica = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'FORMATO DE FÓRMULA OPTOMÉTRICA',
      contenido: (
        <>
          <GenerarFormularioOptometrica />
        </>
      ),
      botones: [],
    })
  }

  const abrirInterconsulta = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'FORMATO DE INTERCONSULTA',
      contenido: (
        <>
          <FormatoInterconsulta />
        </>
      ),
      botones: [],
    })
  }

  const abrirFormatoSolicitudValoraciones = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'FORMATO DE SOLICITUD DE VALORACIONES',
      contenido: (
        <>
          <SolicitudDeValoracion />
        </>
      ),
      botones: [],
    })
  }

  const cambiarEstadoAtencion = (servicioAtencionId, ruta) => {
    if (
      examenEnVista?.estadoExamen !== estadosExamenesConst.CERRADO ||
      examenEnVista?.finalizado
    ) {
      FachadaCambiarEstadoServicioAtencion(servicioAtencionId)
        .then(() => {
          history.push(ruta)
          cerrarModal()
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      history.push(ruta)
      cerrarModal()
    }
  }
  const cerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const noAplicarFormatoDecimal = ['PESO']

  const construirDatosExamenMotor = async ({
    pestanas,
    valoresCampos = {},
    valoresObservaciones = {},
    nodosPorComponente = {},
  }) => {
    try {
      const tiposDeCampos = await ObtenerTiposCampo()
      const camposConSusValores = []

      for (const pestana of pestanas) {
        const nombrePestana = pestana?.id ?? pestana?.tituloPestana ?? ''
        for (const seccion of pestana.secciones) {
          const nombreSeccion = seccion?.id ?? seccion?.nombreSeccion ?? ''
          for (const campo of seccion.campos) {
            const tipoCampo = tiposDeCampos.find(
              (tipo) => tipo.Id === campo.tipoCampoId
            )

            const campoConDatos = {
              campoId: campo.id,
              valor:
                valoresCampos?.[nombrePestana]?.[nombreSeccion]?.[
                  campo.tituloCampo
                ] ?? '',
              observaciones:
                valoresObservaciones?.[nombrePestana]?.[nombreSeccion]?.[
                  campo.tituloCampo
                ] ?? '',
            }

            const contieneImagenes =
              nodosPorComponente?.[nombrePestana]?.[nombreSeccion]?.[
                campo.tituloCampo
              ] ?? null
            const valorCampo = campoConDatos.valor
            try {
              const json = NO_APLICAR_PARSEO.includes(campo?.tipoCampoId)
                ? JSON.parse(valorCampo)
                : valorCampo
              campoConDatos.valor = json
            } catch (error) {
              campoConDatos.valor = valorCampo
            }

            if (Array.isArray(contieneImagenes)) {
              for (const item of contieneImagenes) {
                let imagen = ''
                try {
                  imagen = await subirImagenHTMLApp(item.nodo)
                } catch (error) {
                  imagen = ''
                }

                Object.assign(campoConDatos.valor, {
                  [item.propiedad]: !imagen
                    ? campoConDatos?.valor?.[item.propiedad] ?? ''
                    : imagen,
                })
              }
            }

            if (
              tipoCampo?.Nombre === nombresDeTiposDeCamposConstantes.BOOLEAN &&
              campoConDatos.valor === ''
            ) {
              campoConDatos.valor = false
            }

            campoConDatos.valor =
              tipoCampo?.Nombre === nombresDeTiposDeCamposConstantes.COMPONENTE
                ? JSON.stringify(campoConDatos.valor)
                : tipoCampo?.Nombre ===
                    nombresDeTiposDeCamposConstantes.NUMERICO ||
                  tipoCampo?.Nombre === nombresDeTiposDeCamposConstantes.FORMULA
                ? campoConDatos.valor
                  ? noAplicarFormatoDecimal.includes(campo.tituloCampo)
                    ? campoConDatos.valor
                    : parseFloat(campoConDatos?.valor ?? 0).toFixed(2)
                  : ''
                : campoConDatos.valor?.toString() ?? ''
            camposConSusValores.push(campoConDatos)
          }
        }
      }

      return camposConSusValores ?? []
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const guardadoParcial = useCallback(async () => {
    if (!state?.servicioAtencionId && !state?.servicioId) {
      return
    }
    const examen = obtenerTipoExamenInformacion()

    const pestanas = plantilla?.pestanas ?? []

    const datosExamen = await construirDatosExamenMotor({
      pestanas,
      valoresCampos,
      valoresObservaciones,
      nodosPorComponente,
    })

    if (datosExamen.length > 0) {
      const datos = {
        servicioAtencionId: state.servicioAtencionId,
        servicioId: state.servicioId,
        datosExamen,
      }

      if (datosExamen.every((d) => !Boolean(d.valor))) {
        return true
      }

      try {
        const respuesta = await FachadaGuardarParcialmente(datos)
        if (respuesta.status === 200) {
          if (rolUsuario !== ROLES_APP.BACTERIOLOGO) {
            contextoAplicacion.setModal({
              abierto: true,
              titulo: `La historia del examen ${examen?.tipoExamen ?? ''}`,
              contenido: 'Ha sido guardada parcialmente',
              botones: [
                {
                  nombre: 'Finalizar',
                  click: () => {
                    cambiarEstadoAtencion(state?.servicioAtencionId, '/main')
                  },
                },
                {
                  nombre: 'Regresar a agenda',
                  click: () => {
                    cambiarEstadoAtencion(
                      state?.servicioAtencionId,
                      '/agendamedico'
                    )
                  },
                },
                {
                  nombre: TEXTO_VISUAL.BOTONES.REGRESAR_A_EXAMEN,
                  click: () => {
                    cerrarModal()
                  },
                },
              ],
            })
          }

          setEstadoHabilitadoBotones((prv) => ({
            ...prv,
            [nombreBotonesPlantilla.CERRAREXAMEN]: true,
            [nombreBotonesPlantilla.IMPRIMIRCERTIFICADO]: true,
            [nombreBotonesPlantilla.IMPRIMIREXAMEN]: false,
          }))
          setRecargarListadoFormularios(true)
        }
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    }
    return false
  }, [plantilla, valoresCampos, valoresObservaciones, nodosPorComponente])

  const guardadoTomaDeDatos = useCallback(async () => {
    if (!state?.servicioAtencionId && !state?.servicioId) {
      return
    }
    const examen = obtenerTipoExamenInformacion()

    const pestanas = plantilla?.pestanas ?? []

    const datosExamen = await construirDatosExamenMotor({
      pestanas,
      valoresCampos,
      valoresObservaciones,
      nodosPorComponente,
    })

    if (datosExamen.length > 0) {
      const datos = {
        servicioAtencionId: state.servicioAtencionId,
        servicioId: state.servicioId,
        datosExamen,
        EsTomaDatos: true,
      }

      FachadaGuardarParcialmente(datos)
        .then((respuesta) => {
          if (respuesta.status === 200) {
            contextoAplicacion.setModal({
              abierto: true,
              titulo: `TOMA DE DATOS GUARDADA PARA ${examen?.tipoExamen ?? ''}`,
              contenido: '',
              botones: [
                {
                  nombre: 'Finalizar',
                  click: () => {
                    cambiarEstadoAtencion(state?.servicioAtencionId, '/main')
                  },
                },
                {
                  nombre: 'Regresar a agenda',
                  click: () => {
                    cambiarEstadoAtencion(
                      state?.servicioAtencionId,
                      '/agendamedico'
                    )
                  },
                },
              ],
            })
            setEstadoHabilitadoBotones((prv) => ({
              ...prv,
              [nombreBotonesPlantilla.GUARDARTOMADEDATOS]: false,
            }))
            setRecargarListadoFormularios(true)
          }
        })
        .catch((error) => console.log(error))
    }
  }, [plantilla, valoresCampos, valoresObservaciones, nodosPorComponente])

  const cerrarExamen = useCallback(async () => {
    if (!state?.servicioAtencionId && !state?.servicioId) {
      return
    }

    const examen = obtenerTipoExamenInformacion()

    const todosLosCampos = plantilla.pestanas.reduce((prv, curr) => {
      const campos = curr.secciones.reduce((prv, curr) => {
        prv.push(...curr.campos)

        return prv
      }, [])

      prv.push(...campos)
      return prv
    }, [])

    const pestanas = plantilla?.pestanas ?? []

    const datosExamen = await construirDatosExamenMotor({
      pestanas,
      valoresCampos,
      valoresObservaciones,
      nodosPorComponente,
    })

    const camposPropsValores = todosLosCampos.map((item) => {
      const campoPropiedades = datosExamen.find(
        (campo) => campo.campoId === item.id
      )

      return {
        nombreCampo: item?.tituloCampo ?? 'Campo sin nombre',
        requerido: item?.requerido ?? false,
        valor: campoPropiedades?.valor ?? '',
      }
    })

    const algunCampoRequeridoVacio = camposPropsValores.filter(
      (campo) => campo.requerido && campo.valor === ''
    )

    if (algunCampoRequeridoVacio.length > 0) {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: 'Faltan los siguientes campos obligatorios por diligenciar:',
        contenido: (
          <span className="text-red-600">
            {algunCampoRequeridoVacio
              .map((item) => item.nombreCampo)
              .join(', ')}
          </span>
        ),
        botones: [
          {
            nombre: 'Cerrar',
            click: cerrarModal,
          },
        ],
      })
      return
    }
    const datos = {
      servicioAtencionId: state.servicioAtencionId,
      servicioId: state.servicioId,
      datosExamen,
    }

    if (rolUsuario === ROLES_APP.BACTERIOLOGO) {
      manejarCerrarExamen(datos, examen)
    } else {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: `¿Desea cerrar la historia del examen ${
          examen?.tipoExamen ?? ''
        }?`,
        contenido: '',
        botones: [
          {
            nombre: 'Si',
            click: () => manejarCerrarExamen(datos, examen),
          },
          {
            nombre: 'No',
            click: cerrarModal,
          },
        ],
      })
    }
  }, [plantilla, valoresCampos, valoresObservaciones, nodosPorComponente])

  const manejarCerrarExamen = (datos, examen) => {
    setUltimoEstadoCerradoId(state?.servicioId)
    FachadaCerrarHistoria(datos)
      .then((respuesta) => {
        if (respuesta.status === 200) {
          const stateObjecto = {
            ...state,
            examenes: state.examenes.map((examen) => ({
              ...examen,
              estadoExamen:
                examen?.servicioId === examenEnVista?.servicioId
                  ? estadosExamenesConst.CERRADO
                  : examen.estadoExamen,
            })),
          }

          if (state?.hasOwnProperty('edicionHabilitada')) {
            Object.assign(stateObjecto, {edicionHabilitada: false})
          }

          history.push('/examen', {...stateObjecto})
          contextoAplicacion.setModal({
            abierto: true,
            titulo: `${
              rolUsuario === ROLES_APP.BACTERIOLOGO
                ? `El laboratorio de ${
                    examen?.tipoExamen ?? ''
                  } ha sido cerrado exitosamente `
                : `La historia del examen ${
                    examen?.tipoExamen ?? ''
                  } ha sido cerrada exitosamente `
            }`,
            botones: [
              {
                nombre: 'Finalizar',
                click: () => {
                  cambiarEstadoAtencion(state?.servicioAtencionId, '/main')
                },
              },
              {
                nombre: 'Regresar a agenda',
                click: () => {
                  cambiarEstadoAtencion(
                    state?.servicioAtencionId,
                    '/agendamedico'
                  )
                },
              },
              {
                nombre: TEXTO_VISUAL.BOTONES.REGRESAR_A_EXAMEN,
                click: () => {
                  cerrarModal()
                },
              },
            ],
          })
          setEstadoHabilitadoBotones((prv) => ({
            ...prv,
            [nombreBotonesPlantilla.CERRAREXAMEN]: false,
            [nombreBotonesPlantilla.IMPRIMIRCERTIFICADO]: true,
            [nombreBotonesPlantilla.IMPRIMIREXAMEN]: true,
            [nombreBotonesPlantilla.GUARDADOPARCIAL]: false,
          }))
          setRecargarListadoFormularios(true)
          obtenerHistoriaClinicaPaciente(
            state?.usuarioId,
            setHistoriaClinicaUsuario
          )
        }
      })
      .catch((error) => console.log(error))
  }

  const imprimirCertificadoExamen = async () => {
    const generarFirmaCertificadoCerrado =
      examenEnVista?.estadoExamen === estadosExamenesConst.CERRADO ||
      examenEnVista?.finalizado
    if (state.servicioAtencionId) {
      FachadaGenerarCertificadoExamen(
        state.servicioAtencionId,
        generarFirmaCertificadoCerrado
      ).then((respuesta) => {
        if (respuesta.status === 200) {
          window.open(respuesta.data.replace(/['"]+/g, ''), '_blank').focus()
        }
      })
    }
  }

  const imprimirExamen = async () => {
    if (state.servicioAtencionId) {
      FachadaGenerarDocumentoExamen(state.servicioAtencionId).then(
        (respuesta) => {
          if (respuesta.status === 200) {
            window.open(respuesta.data.replace(/['"]+/g, ''), '_blank').focus()
          }
        }
      )
    }
  }

  const abrirIncapacidadMedica = async () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'FORMATO DE INCAPACIDAD MÉDICA',
      contenido: (
        <>
          <IncapacidadMedica />
        </>
      ),
      botones: [],
    })
  }

  const funcionesDefaultBotones = useMemo(() => {
    return {
      [nombreBotonesPlantilla.FORMULAMEDICA]: abrirFormulaMedica,
      [nombreBotonesPlantilla.FORMULAOPTOMETRICA]: abrirFormulaOptometrica,
      [nombreBotonesPlantilla.INTERCONSULTA]: abrirInterconsulta,
      [nombreBotonesPlantilla.GUARDADOPARCIAL]: guardadoParcial,
      [nombreBotonesPlantilla.CERRAREXAMEN]: cerrarExamen,
      [nombreBotonesPlantilla.IMPRIMIRCERTIFICADO]: imprimirCertificadoExamen,
      [nombreBotonesPlantilla.IMPRIMIREXAMEN]: imprimirExamen,
      [nombreBotonesPlantilla.GUARDARTOMADEDATOS]: guardadoTomaDeDatos,
      [nombreBotonesPlantilla.INCAPACIDADMEDICA]: abrirIncapacidadMedica,
      [nombreBotonesPlantilla.SOLICITUDVALORACION]:
        abrirFormatoSolicitudValoraciones,
    }
  }, [
    plantilla,
    valoresCampos,
    valoresObservaciones,
    nodosPorComponente,
    examenEnVista,
  ])

  return (
    <ContextoFormulario.Provider
      value={{
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
        plantilla: plantilla,
        setPlantilla: setPlantilla,
        errores: errores,
        setErrores: setErrores,
        valoresCampos: valoresCampos,
        setValoresCampos: setValoresCampos,
        informacionUsuario: informacionUsuario,
        setInformacionUsuario: setInformacionUsuario,
        funcionesDefaultBotones: funcionesDefaultBotones,
        formularios: formularios,
        setFormularios: setFormularios,
        parametrosFormulario: parametrosFormulario,
        setParametrosFormulario: setParametrosFormulario,
        valoresObservaciones: valoresObservaciones,
        setValoresObservaciones: setValoresObservaciones,
        historiaClinicaUsuario: historiaClinicaUsuario,
        setHistoriaClinicaUsuario: setHistoriaClinicaUsuario,
        datosAtencion: datosAtencion,
        setDatosAtencion: setDatosAtencion,
        nodosPorComponente: nodosPorComponente,
        setNodosPorComponente: setNodosPorComponente,
        estadoHabilitadoBotones: estadoHabilitadoBotones,
        setEstadoHabilitadoBotones: setEstadoHabilitadoBotones,
        habilitarEdicion: habilitarEdicion,
        setHabilitarEdicion: setHabilitarEdicion,
        examenEnVista: examenEnVista,
        setExamenEnVista: setExamenEnVista,
        estadoExamen: estadoExamen,
        setEstadoExamen: setEstadoExamen,
        examenInicial: examenInicial,
        setExamenInicial: setExamenInicial,
        actualizarExamenInicial: actualizarExamenInicial,
        setActualizarExamenInicial: setActualizarExamenInicial,
        ultimoExamenCerradoId: ultimoExamenCerradoId,
        setUltimoEstadoCerradoId: setUltimoEstadoCerradoId,
        esVistaPrevia: esVistaPrevia,
        setVistaPrevia: setVistaPrevia,
        recargarListadoFormularios: recargarListadoFormularios,
        setRecargarListadoFormularios: setRecargarListadoFormularios,
        guardadoParcial: guardadoParcial,
      }}
    >
      {children}
    </ContextoFormulario.Provider>
  )
}
