import {useMsal} from '@azure/msal-react'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {RUTAS_APP} from '../../constantes'

export const AutorizarComponente = ({children, roles, redirigir = true}) => {
  const {accounts} = useMsal()
  const history = useHistory()
  const [autorizado, setAutorizado] = useState(false)

  useEffect(() => {
    if (typeof roles === 'string' || Array.isArray(roles)) {
      const rolUsuario = accounts[0].idTokenClaims.roles[0]

      if (!roles.includes(rolUsuario)) {
        if (redirigir) {
          history.replace(RUTAS_APP.AUTORIZACION.ACCESO_DENEGADO)
        }
      }

      setAutorizado(true)
    }
  }, [accounts, redirigir, roles])

  if (!autorizado) {
    return null
  }

  return <>{children}</>
}
