import React from 'react'
import {TEXTO_VISUAL} from '../../constantes'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TablaConfirmacionPagos} from '../../componentes/ConfirmacionPagosComponentes'

const ConfirmacionDePagos = () => {
  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.CONFIRMACION_PAGOS.TITULO_PAGINA}
      estilosContenedor={'w-95%'}
    >
      <TablaConfirmacionPagos />
    </ContenedorPagina>
  )
}

export default ConfirmacionDePagos
