import React, {useState, createContext} from 'react'

export const ContextoAgendaRegistro = createContext({})

export const AgendaRegistroConContexto = ({children}) => {
  const [atencionesDiaActual, setAtencionesDiaActual] = useState([])
  const [atencionesPasadas, setAtencionesPasadas] = useState([])

  return (
    <ContextoAgendaRegistro.Provider
      value={{
        atencionesDiaActual: atencionesDiaActual,
        setAtencionesDiaActual: setAtencionesDiaActual,
        atencionesPasadas: atencionesPasadas,
        setAtencionesPasadas: setAtencionesPasadas,
      }}
    >
      {children}
    </ContextoAgendaRegistro.Provider>
  )
}
