import React, {useState, useContext, useEffect, useCallback} from 'react'
import {ContextApplication} from '../../contexto'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {Boton} from '../Botones'
import {CeldaFormatoDinero} from '../Celdas'
import {EncabezadoFondo, TablaConPaginacionYFiltros} from '../Tablas'
import {
  AgregarServiciosACache,
  AgregarPaquetesACache,
  ObtenerPaqueteOServicioPorNombre,
  ObtenerEmpresasYEmpresasHijasPorNombre,
  ObtenerSedePorId,
} from '../../cache/servicios-cache'
import {ObtenerTarifarioPorEmpresaId} from '../../microservicios'

export default function TablaTarifarioParticular() {
  const contextoApp = useContext(ContextApplication)
  const [servicioOPaqueteSeleccionado, setServicioOPaqueteSeleccionado] =
    useState({codigoInterno: '', nombre: ''})
  const [elementosFiltradosNombre, setElementosFiltradosNombre] = useState([])
  const [habilitarBusqueda, sethabilitarBusqueda] = useState(true)
  const [datosTarifarioParticular, setDatosTarifarioParticular] = useState({})
  const [listaTarifarios, setListaTarifarios] = useState([])
  const [paquetes, setPaquetes] = useState([])
  const [servicios, setServicios] = useState([])

  let isMounted = true

  const columnasTablaTarifarioParticular = React.useMemo(() => [
    {
      accessor: 'id',
      className: 'hidden',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Sede'} />,
      accessor: 'sede',
      headerStyle:
        'w-2/4 bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: habilitarBusqueda,
      className: 'text-center w-2/4',
      Cell: ({row: {original}}) => (
        <span className="text-cendiatra">
          {(original?.sede?.toUpperCase() || original?.aliado?.toUpperCase()) ??
            ''}
        </span>
      ),
    },
    {
      Header: () => <EncabezadoFondo titulo={'Precio Actual'} />,
      accessor: 'precio',
      headerStyle: 'w-2/4 bg-cendiatra-verde-7 text-white h-16 rounded-r-lg',
      disableFilters: true,
      className: 'text-center w-2/4',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={`text-center appearance-none text-cendiatra relative block w-full pointer-events-none  p-1.5  focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          valor={row.row.original.precio}
        />
      ),
    },
  ])

  const obtenerInformacionPaquetesYServicios = useCallback(async () => {
    contextoApp.setIsLoading(true)
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    contextoApp.setIsLoading(false)
    if (isMounted) {
      contextoApp.setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    obtenerInformacionPaquetesYServicios().catch(console.error)
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const empresas = await ObtenerEmpresasYEmpresasHijasPorNombre(
          'PARTICULAR'
        )
        if (empresas.length > 0) {
          const empresaId = empresas[0].Id
          const res = await ObtenerTarifarioPorEmpresaId(empresaId)

          const datosActualizadosPaquetes = res.data.paquetes.map(
            ({preciosAliados, ...resto}) => ({...resto})
          )
          const datosActualizadosServicios = res.data.servicios.map(
            ({preciosAliados, ...resto}) => ({...resto})
          )

          setDatosTarifarioParticular({
            ...res.data,
          })

          setPaquetes(datosActualizadosPaquetes)
          setServicios(datosActualizadosServicios)
        }
      } catch (error) {
        console.log(error)
      }
    }

    obtenerDatos()
  }, [])

  useEffect(() => {
    if (servicioOPaqueteSeleccionado.nombre === '') {
      sethabilitarBusqueda(true)
    }
  }, [servicioOPaqueteSeleccionado.nombre])

  const filtrarPorNombre = async (e) => {
    setServicioOPaqueteSeleccionado({
      ...servicioOPaqueteSeleccionado,
      codigoInterno: '',
      nombre: e.target.value,
      tipo: undefined,
    })

    if (e.target.value.length >= 3) {
      try {
        const res = await ObtenerPaqueteOServicioPorNombre(e.target.value)
        if (res && res.length > 0) {
          const elementosNombreFiltrados = res
            .map((elemento) => {
              let elementoFiltrado = {
                id: elemento.id,
                nombre: elemento.nombre,
                filtro: elemento.nombre,
                preciosSedes: [],
              }

              const tipoServicio = 0
              const tipoPaquete = 1

              if (elemento.tipo === tipoPaquete) {
                const paqueteEncontrado = paquetes.find(
                  (paquete) => paquete?.paqueteId === elemento.id
                )

                if (paqueteEncontrado) {
                  elementoFiltrado.preciosSedes = paqueteEncontrado.preciosSedes
                }
              } else if (elemento.tipo === tipoServicio) {
                const servicioEncontrado = servicios.find(
                  (servicio) => servicio?.servicioId === elemento.id
                )

                if (servicioEncontrado) {
                  elementoFiltrado.preciosSedes =
                    servicioEncontrado.preciosSedes
                }
              }

              return elementoFiltrado
            })
            .filter(
              (elemento) =>
                paquetes.some((paquete) => paquete.paqueteId === elemento.id) ||
                servicios.some(
                  (servicio) => servicio.servicioId === elemento.id
                )
            )

          if (isMounted) {
            setElementosFiltradosNombre(elementosNombreFiltrados)
          }
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      if (isMounted) {
        setElementosFiltradosNombre([])
      }
    }
  }

  const seleccionarOpcion = async (opcion) => {
    const preciosSedesPromises = opcion.preciosSedes.map(async (elemento) => {
      const sede = await ObtenerSedePorId(elemento.sedeId)
      return {
        sede: sede?.Nombre ?? '',
        precio: elemento.precio,
      }
    })
    const preciosSedes = await Promise.all(preciosSedesPromises)

    preciosSedes.sort((a, b) => b.sede.localeCompare(a.sede))

    setListaTarifarios([...preciosSedes])
    sethabilitarBusqueda(false)
    setServicioOPaqueteSeleccionado({...opcion})
  }

  const limpiarFiltro = () => {
    sethabilitarBusqueda(true)
    setServicioOPaqueteSeleccionado({codigoInterno: '', nombre: ''})
    setListaTarifarios([])
  }

  return (
    <div
      className={`rounded-md  -space-y-px flex justify-center flex-wrap items-center w-3/4 mx-auto`}
    >
      <div className="rounded-md -space-y-px flex justify-center flex-nowrap w-9/12 items-center">
        <CampoFiltrarEspañol
          titulo={'Nombre del servicio o paquete*'}
          estilosPersonalizados={'w-5/12 mb-8'}
          estilosInput={'border-cendiatra'}
          placeholder={'Descripción (autocompletar y filtrar)'}
          tipo={'text'}
          valorDelCampoFiltro={servicioOPaqueteSeleccionado.nombre}
          desactivarOtroFiltro={''}
          informacionFiltrada={elementosFiltradosNombre}
          handleChange={filtrarPorNombre}
          handleOptionChange={seleccionarOpcion}
        />

        <div className="w-1/12 flex justify-center items-center ml-5 ">
          <Boton
            titulo="Limpiar"
            habilitado={true}
            funcionCLick={() => {
              limpiarFiltro()
            }}
            colorPorDefecto={'bg-grayColor'}
            colorActivo={'bg-btnBg'}
            tipo={'button'}
            alto={'h-9'}
          />
        </div>
      </div>
      <div className="w-full">
        <TablaConPaginacionYFiltros
          columns={columnasTablaTarifarioParticular}
          data={listaTarifarios}
        />
      </div>
    </div>
  )
}
