import {dbComun} from '../bases-datos/dbComun'

export const ObtenerClasificacionExamenFisicoPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun.table('ClasificacionExamenFisico').get({Nombre: nombre})
  )
}

export const ObtenerClasificacionExamenFisicoPorId = async (id) => {
  return Promise.resolve(
    dbComun.table('ClasificacionExamenFisico').get({Id: id})
  )
}
