import React, {useContext, useMemo} from 'react'
import {ContextoFormulario} from '../../../contexto'

const CampoFecha = ({numeroCampo, numeroPestana, numeroSeccion, readOnly}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const valorCampo =
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  const manejarCambioValor = (evt) => {
    if (readOnly) return

    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: evt.target.value,
        },
      },
    })
  }
  return (
    <div className="w-full flex justify-start flex-wrap">
      <label className="block text-md font-medium text-cendiatra">
        {campo?.tituloCampo?.toUpperCase() ?? ''}
      </label>
      <input
        className={`appearance-none  relative block w-full px-3 py-2 border ${
          campo?.requerido && !valorCampo
            ? 'border-cendiatra-rojo-1'
            : 'border-cendiatra'
        } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2`}
        type="date"
        value={valorCampo}
        onChange={manejarCambioValor}
        readOnly={readOnly}
      />
    </div>
  )
}

export default CampoFecha
