function CuadroInformacionUsuarioV2({foto, datos, estilosContenedor = ''}) {
  return (
    <>
      <div className={`${estilosContenedor}`}>
        <div className="w-full justify-center items-center ">
          <div className="w-full flex justify-center items-center pb-4">
            <img
              className="h-56 "
              src={foto ? foto : ''}
              alt="imagen usuario"
              style={{borderRadius: '50%'}}
            />
          </div>
          <div className="w-full flex flex-wrap justify-center items-center">
            <div className="w-10/12 flex flex-wrap">
            {datos.map((dato) => (
              <div className="w-4/12 flex flex-wrap py-2 text-13px">
                <div className="w-12/12 flex flex-wrap justify-center items-center">
                  <span className="w-full text-13px text-start text-cendiatra font-semibold">
                    {dato.titulo}
                  </span>
                  <span className="w-full uppercase  text-gray-400 text-start text-13px">
                    {dato.contenido}
                  </span>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CuadroInformacionUsuarioV2
