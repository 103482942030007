import React from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import FormularioGeneradorDeReportes from '../../componentes/Administrador/formularios/FormularioGeneradorDeReportes'

const CreadorDeReportes = () => {
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.ADMINISTRADOR_GATO.GENERADOR_DE_REPORTES.TITULO
      }
    >
      <FormularioGeneradorDeReportes />
    </ContenedorPagina>
  )
}

export default CreadorDeReportes
