import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {BotonOpcionConObservaciones} from '../../Botones'

const CampoBooleano = ({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const valor = contextoFormulario.valoresCampos?.[accesoPestana]?.[
    accesoSeccion
  ]?.[campo?.tituloCampo]
    ? JSON.parse(
        contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
          campo?.tituloCampo
        ]
      )
    : false

  const observacions =
    contextoFormulario.valoresObservaciones?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  useEffect(() => {
    setEstaActivo(valor)
  }, [valor])

  useEffect(() => {
    if (observacions) {
      setObservaciones(observacions)
    }
  }, [observacions])

  const [estaActivo, setEstaActivo] = useState(valor)
  const [observaciones, setObservaciones] = useState('')

  const manejarCambioActivo = () => {
    if (readOnly) return

    setEstaActivo(!estaActivo)
    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: !estaActivo,
        },
      },
    })
    manejarCambioObservaciones('')
  }

  const manejarCambioObservaciones = (valor) => {
    if (readOnly) return

    setObservaciones(valor)
    contextoFormulario.setValoresObservaciones({
      ...contextoFormulario.valoresObservaciones,
      [accesoPestana]: {
        ...(contextoFormulario.valoresObservaciones?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresObservaciones?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: valor,
        },
      },
    })
  }

  return (
    <BotonOpcionConObservaciones
      id={campo.numeroCampo}
      titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
      estaActivo={estaActivo}
      observaciones={observaciones}
      funcionClick={manejarCambioActivo}
      cambioObservaciones={manejarCambioObservaciones}
      readOnly={readOnly}
    />
  )
}

export default CampoBooleano
