import React, {useState, createContext} from 'react'

export const ContextoPaquete = createContext('')

export const PaqueteConContexto = ({children}) => {
  const [informacionPaquete, setInformacionPaquete] = useState({})
  const [codigoPaquete, setCodigoPaquete] = useState(0)
  const [modoEdicion, setModoEdicion] = useState(false)
  const [actualizar, setActualizar] = useState(false)
  const [serviciosSeleccionados, setServiciosSeleccionados] = useState([])

  return (
    <ContextoPaquete.Provider
      value={{
        informacionPaquete: informacionPaquete,
        setInformacionPaquete: setInformacionPaquete,
        codigoPaquete: codigoPaquete,
        setCodigoPaquete: setCodigoPaquete,
        modoEdicion: modoEdicion,
        setModoEdicion: setModoEdicion,
        actualizar: actualizar,
        setActualizar: setActualizar,
        serviciosSeleccionados: serviciosSeleccionados,
        setServiciosSeleccionados: setServiciosSeleccionados,
      }}
    >
      {children}
    </ContextoPaquete.Provider>
  )
}
