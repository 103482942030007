import React, {useEffect, useState, useContext} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import moment from 'moment'
import {ContextApplication} from '../../contexto'
import {TEXTO_VISUAL, motivosModificacion, RUTAS_APP} from '../../constantes'
import {ContenedorTituloResaltado} from '../Contenedores'
import {ContenedorPagina} from '../paginas/ContenedorPagina'
import {CuadroInformacion} from '../MostrarInformacion'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {Input, AreaDeTexto} from '../inputs'
import {BotonSimple} from '../Botones'
import {
  FachadaObtenerAtencion,
  FachadaModificarAtencion,
  FachadaFinalizarModificacionAtencion,
  FachadaAgregarMotivo,
  ObtenerEmpresaPorIdDB,
  ExportarEmpresasHijas,
} from '../../microservicios'
import {
  ObtenerTipoFacturacionPorId,
  ObtenerSedePorId,
} from '../../cache/servicios-cache'
import {aplicarFormatoMoneda, capitalizarPalabras} from '../../utilidades'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const ModificarOCancelarAtencion = () => {
  const {state} = useLocation()
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const [datosUsuario, setDatosUsuario] = useState([])

  const contextoAplicacion = useContext(ContextApplication)

  const [valorMotivo, setValorMotivo] = useState('')

  const [cargo, setCargo] = useState('')

  const sede = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)

  const history = useHistory()

  const [empresasFiltradas, setEmpresasFiltradas] = useState([])

  const [empresasHijas, setEmpresasHijas] = useState([])

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState({
    id: 0,
    nombre: '',
  })

  const [idEmpresa, setIdEmpresa] = useState(0)

  const cerrarModal = (irAInicio) => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })

    if (irAInicio) {
      history.push('/')
    }
  }

  useEffect(() => {
    if (state?.idAtencion && !state?.cancelar && idEmpresa !== 0) {
      const ObtenerEmpresasHijas = async () => {
        const empresasHijas = (
          await ExportarEmpresasHijas(idEmpresa)
        )?.data?.map((empresaHija) => ({
          id: empresaHija?.id ?? 0,
          filtro:
            empresaHija?.nit +
            ' - ' +
            (empresaHija?.nombre?.toUpperCase() ?? ''),
          nit: empresaHija?.nit ?? 0,
          nombre: empresaHija?.nombre?.toUpperCase() ?? '',
        }))
        setEmpresasHijas(empresasHijas)
      }
      ObtenerEmpresasHijas()
    }
  }, [idEmpresa])

  useEffect(() => {
    if (state?.idAtencion) {
      const obtenerDatosIniciales = async () => {
        try {
          const respuesta = await FachadaObtenerAtencion(state?.idAtencion)
          if (respuesta?.status === 200) {
            setDatosUsuario([
              {
                titulo: 'Fecha de Atención',
                contenido: moment(respuesta?.data?.fechaAtencion).format(
                  'DD/MM/YYYY' ?? ''
                ),
              },
              {
                titulo: 'Realizado por',
                contenido: respuesta?.data?.responsable ?? '',
                estiloTexto: 'lowercase',
              },
              {
                titulo: 'Tipo de facturación',
                contenido: capitalizarPalabras(
                  (
                    await ObtenerTipoFacturacionPorId(
                      respuesta?.data?.tipoFacturacionId ?? 0
                    )
                  )?.Nombre ?? ''
                ),
                estiloTexto: 'normal-case',
              },

              {
                titulo: 'Número factura',
                contenido: respuesta?.data?.numeroDocumento ?? '',
              },
              {
                titulo: 'Valor Total',
                contenido:
                  aplicarFormatoMoneda(respuesta?.data?.valorTotal ?? 0) ?? '',
              },
              {
                titulo: 'Sede',
                contenido: capitalizarPalabras(
                  (await ObtenerSedePorId(respuesta?.data?.sedeId ?? 0))
                    ?.Nombre ?? ''
                ),
                estiloTexto: 'normal-case',
              },
            ])
          }

          const informacionEmpresa = await ObtenerEmpresaPorIdDB(
            respuesta?.data?.empresaId
          )

          if (informacionEmpresa.status === 200) {
            if (informacionEmpresa?.data?.empresaPadreId === 0) {
              setIdEmpresa(respuesta?.data?.empresaId)
            } else {
              setIdEmpresa(informacionEmpresa?.data?.empresaPadreId)
            }
          }
        } catch (error) {
          console.log(error)
        }
      }

      obtenerDatosIniciales()
    }
  }, [])

  const retornarUiCorrespondiente = () => {
    if (state?.cancelar) {
      return (
        <div className="w-full flex flex-wrap justify-center items-center">
          <AreaDeTexto
            valor={valorMotivo}
            titulo="Motivo de cancelación*"
            cambioValor={(evt) => {
              setValorMotivo(evt.currentTarget?.value)
            }}
            estilosContenedor={'w-full'}
            estilosAreaDeTexto={`      ${
              !valorMotivo ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
            } rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
            placeholder="descripción"
            rows={10}
          />
          <div className="w-full flex justify-center items-center mt-10">
            <BotonSimple
              texto={'Continuar'}
              estilosBoton={`w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
                Boolean(valorMotivo !== '')
                  ? ' bg-btnBg '
                  : ' bg-cendiatra-gris-placeholder'
              }`}
              funcion={cancelarAtencion}
              deshabilitado={Boolean(valorMotivo === '')}
            />
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div className="w-full flex justify-center items-center">
            <CampoFiltrarEspañol
              titulo={'Empresa hija'}
              estilosPersonalizados={'w-4/12 mx-5'}
              estilosInput={'border-cendiatra'}
              tipo={'text'}
              placeholder={'Descripción (autocompletar)'}
              valorDelCampoFiltro={empresaSeleccionada.nombre}
              desactivarOtroFiltro={''}
              informacionFiltrada={empresasFiltradas}
              handleChange={manejarEmpresaCambiada}
              handleOptionChange={manejarEmpresaSeleccionada}
            />
            <Input
              funcion={null}
              estilosContenedor={'w-4/12 mx-5'}
              onChange={(e) => setCargo(e.target.value)}
              estilosInput={
                'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'Cargo'}
              valor={cargo}
            />
          </div>
          <div className="w-full flex justify-center items-center mt-16 mb-32">
            <BotonSimple
              texto={'Guardar'}
              estilosBoton={`w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
                empresaSeleccionada?.id !== 0 || cargo !== ''
                  ? ' bg-btnBg '
                  : ' bg-cendiatra-gris-placeholder'
              }`}
              funcion={manejarGuardar}
              deshabilitado={Boolean(
                empresaSeleccionada?.nombre === '' && cargo === ''
              )}
            />
            <BotonSimple
              texto={'Regresar'}
              estilosBoton={`w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 bg-btnBg mx-5`}
              funcion={regresarPantallaInicial}
            />
          </div>
        </>
      )
    }
  }

  const cancelarAtencion = async () => {
    try {
      const respuesta = await FachadaAgregarMotivo({
        atencionId: state?.idAtencion ?? 0,
        sedeId: sede ?? 0,
        tipoMotivoId: motivosModificacion.CANCELACION,
        motivo: valorMotivo,
      })
      if (respuesta.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: (
            <div className="w-full flex flex-wrap justify-center items-center">
              <span className="w-full text-center my-2">
                ATENCIÓN #{state?.idAtencion}
              </span>
              <span className="w-full text-center ">CANCELADA</span>
            </div>
          ),
          contenido: '',
          botones: [
            {
              nombre: 'Finalizar',
              click: () => {
                cerrarModal(true)
              },
            },
            {
              nombre: 'Regresar',
              click: () => {
                regresarPantallaInicial(true)
              },
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
      cerrarModal()
    }
  }

  const ObtenerEmpresasHijasPorNitONombre = ({Nit = 0, Nombre = ''}) => {
    if (Nit === 0) {
      return empresasHijas.filter(
        ({nombre}) => nombre.toUpperCase().indexOf(Nombre.toUpperCase()) > -1
      )
    } else {
      return empresasHijas.filter(
        ({nit}) => nit.toUpperCase().indexOf(Nit.toUpperCase()) > -1
      )
    }
  }

  const manejarEmpresaCambiada = async (e) => {
    if (e.target.value === '') {
      setEmpresaSeleccionada({
        id: 0,
        nombre: '',
      })
    } else {
      setEmpresaSeleccionada({
        ...empresaSeleccionada,
        nombre: e.target.value,
      })
    }
    let empresas
    if (e.target.value.length >= 3) {
      if (isNaN(e.target.value)) {
        empresas = ObtenerEmpresasHijasPorNitONombre({nombre: e.target.value})
      } else {
        empresas = ObtenerEmpresasHijasPorNitONombre({nit: e.target.value})
      }
      setEmpresasFiltradas(
        empresas.map((empresa) => ({
          id: empresa?.id ?? 0,
          filtro: empresa?.nit + '-' + (empresa?.nombre?.toUpperCase() ?? ''),
          nit: empresa?.nit ?? 0,
        }))
      )
    } else {
      setEmpresasFiltradas([])
    }
  }

  const manejarEmpresaSeleccionada = async (empresaSeleccionada) => {
    setEmpresaSeleccionada({
      empresaSeleccionada,
      id: empresaSeleccionada.id,
      nombre: empresaSeleccionada.filtro,
      nit: empresaSeleccionada.nit,
    })
    setEmpresasFiltradas([])
  }

  const manejarGuardar = async () => {
    const payload = {
      atencionId: state?.idAtencion ?? 0,
      tipoMotivoId: motivosModificacion.MODIFICACION_EMPRESA_CARGO,
      cargo: cargo ?? '',
      facturacion: {
        empresaId:
          empresaSeleccionada?.id !== 0 ? empresaSeleccionada?.id : idEmpresa,
      },
    }
    try {
      const actualizarAtencion = await FachadaModificarAtencion(payload)

      if (actualizarAtencion?.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: (
            <div className="w-full flex flex-wrap justify-center items-center">
              <span className="w-full flex justify-center items-center">
                ATENCIÓN #{state?.idAtencion}
              </span>
              <span className="w-full flex justify-center items-center">
                MODIFICADA
              </span>
            </div>
          ),
          contenido: '',
          botones: [
            {
              nombre: 'Finalizar',
              click: () => {
                cerrarModal(true)
              },
            },
            {
              nombre: 'Regresar',
              click: () => {
                regresarPantallaInicial()
              },
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const regresarPantallaInicial = async (noLlamarServicio) => {
    if (noLlamarServicio) {
      cerrarModal()
      return history.push(RUTAS_APP.RECEPCION.MODIFICAR_ATENCION_EJECUTADA)
    }
    try {
      let cambiarEstadoAtencion = null

      cambiarEstadoAtencion = await FachadaFinalizarModificacionAtencion({
        atencionId: state?.idAtencion ?? 0,
        enviarCorreo: false,
      })

      if (cambiarEstadoAtencion?.status === 200) {
        contextoAplicacion.setModal({
          abierto: false,
          titulo: '',
          contenido: '',
          botones: [],
        })
        history.push(RUTAS_APP.RECEPCION.MODIFICAR_ATENCION_EJECUTADA)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={
        state?.cancelar
          ? TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA
              .MODIFICAR_ATENCION_EJECUTADA.TITULO_CANCELAR
          : TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA
              .MODIFICAR_ATENCION_EJECUTADA.TUTULO_MODIFICAR_EMPRESA_HIJA_CARGO
      }
      clasesCssAdicionales={'w-full'}
      funcionBotonCerrar={() => regresarPantallaInicial()}
    >
      <ContenedorTituloResaltado
        titulo={'INFORMACIÓN DE LA ATENCIÓN'}
        paddingContenedor={'bg-white px-5 mb-2 w-full'}
        margenContenedor={'my-10 h-full'}
      >
        <div className="w-full flex flex-wrap justify-center items-center">
          <CuadroInformacion
            estilosContenedor={
              'w-full my-5 flex flex-wrap justify-center items-center  '
            }
            titulo={''}
            datos={datosUsuario}
          />
        </div>
      </ContenedorTituloResaltado>
      {retornarUiCorrespondiente()}
    </ContenedorPagina>
  )
}

export default ModificarOCancelarAtencion
