export const nombreBotonesPlantilla = {
  IMPRIMIRCERTIFICADO: 'Imprimir certificado',
  CERRAREXAMEN: 'Cerrar Exámen',
  GUARDADOPARCIAL: 'Guardado parcial',
  FORMULAMEDICA: 'Fórmula médica',
  FORMULAOPTOMETRICA: 'Fórmula optométrica',
  IMPRIMIRATENCION: 'Imprimir Atención',
  INTERCONSULTA: 'Interconsulta',
  INCAPACIDADMEDICA: 'Incapacidad médica',
  GUARDARTOMADEDATOS: 'Guardar toma datos',
  IMPRIMIREXAMEN: 'Imprimir Exámen',
  IMPRIMIRRESULTADO: 'Imprimir resultado',
  SOLICITUDVALORACION: 'Solicitud de valoración',
}
