import React from 'react'
import {BotonSimple} from '../Botones'

const CeldaConBotonSimple = ({texto, estilosBoton, funcion, deshabilitado}) => {
  return (
    <div className="w-full flex justify-center items-center align-top">
      <BotonSimple
        texto={texto}
        estilosBoton={estilosBoton}
        funcion={() => funcion()}
        deshabilitado={deshabilitado}
      />
    </div>
  )
}

export default CeldaConBotonSimple
