import {useMemo} from 'react'
import {
  EncabezadoFondo,
  EncabezadoConSubTitulos,
  TablaConPaginacionYFiltros,
  FiltroColumnaAutoCompletar,
  CeldaModulosGestionUsuariosPortalCliente,
} from '../Tablas'
import {FiltroListaSeleccionar} from '../Filtros'
import {ComponenteAlerta} from '../Visuales'

function TablaGestionUsuarios({data, funcionRecargaDatos = () => {}}) {
  const columnas = useMemo(
    () => [
      {
        Header: (row) => <EncabezadoFondo titulo={'Nombre'} />,
        accessor: 'nombres',
        className: 'w-3/12',
        headerStyle:
          'bg-cendiatra-verde-7 text-white h-24 border-l-4 border-cendiatra rounded-l-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Filter: FiltroColumnaAutoCompletar,
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Usuario'} />,
        accessor: 'usuario',
        className: 'w-2/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Filter: FiltroColumnaAutoCompletar,
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Empresa'} />,
        accessor: 'empresa',
        className: 'w-2/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Filter: (cell) => (
          <FiltroColumnaAutoCompletar column={cell.column} estilos="p-1" />
        ),
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Rol'} />,
        accessor: 'rol',
        className: 'w-2/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
        Filter: (cell) => (
          <FiltroListaSeleccionar
            column={cell.column}
            estilos="w-11/12 px-3 py-2 mt-4"
          />
        ),
        filter: 'lista',
      },
      {
        Header: (row) => (
          <EncabezadoConSubTitulos
            titulo={'Módulos'}
            subtitulo1={'Editar'}
            subtitulo2={'Activar'}
          />
        ),
        accessor: 'activo',
        className: 'w-3/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px' border-r-4 border-cendiatra rounded-r-lg",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
        disableFilters: true,
        Cell: ({row}) =>
          row?.original?.puedeActivar ? (
            <CeldaModulosGestionUsuariosPortalCliente
              row={row}
              funcionRecargaDatos={funcionRecargaDatos}
            />
          ) : (
            <ComponenteAlerta
              mensaje={
                <p>No se pueden gestionar usuarios <br /> de una empresa inactiva.</p>
              }
              mensajeClassNames='normal-case bg-[#770018]'
              contenedorPrincipal='w-full'
              background='bg-gray-200'
              colorMensaje='text-red-800'
            />
          ),
      },
    ],
    []
  )

  return (
    <TablaConPaginacionYFiltros
      columns={columnas}
      data={data}
      estilosRow="w-full flex justify-center items-center border border-cendiatra rounded-lg py-2 mt-2"
      paginacion={true}
      usarSort={false}
    />
  )
}

export default TablaGestionUsuarios
