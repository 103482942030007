import React from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import FormularioRecargarSaldoEnSede from '../../componentes/recepcionista/FormularioRecargarSaldoEnSede'

const RecargarSaldoEnSede = () => {
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.RECARGAR_EN_SEDE
      }
    >
      <FormularioRecargarSaldoEnSede />
    </ContenedorPagina>
  )
}

export default RecargarSaldoEnSede
