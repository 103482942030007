import {useEffect, useState, useContext} from 'react'
import {NumericFormat} from 'react-number-format'
import {ImagenEstandar} from './MostrarInformacion'
import iconoIformacion from '../imagenes/informacion-fondo-blanco.svg'
import {ContextoTarifario, ContextoPortafolio} from '../contexto'
import {obtenerCeroSiEsNullOVacio} from '../utilidades/funcionesComunes'
import {eliminarDuplicadosPorPropiedad} from '../utilidades/funcionesComunes'
import {GRUPO_PRECIOS} from '../constantes'

export const CeldaEditable = ({
  value: valorExterno,
  row: {index, original},
  column: {id, deshabilitado, esPortafolio},
  actualizarDatos,
  titulo,
}) => {
  const contextoTarifario = useContext(ContextoTarifario)

  const contextoPortafolio = useContext(ContextoPortafolio)

  const contextoTransaccion = esPortafolio
    ? contextoPortafolio
    : contextoTarifario

  const [valorInterno, setValorInterno] = useState(0)

  useEffect(() => {
    let nuevoValorInterno = valorExterno

    if (
      typeof valorExterno === 'string' &&
      nuevoValorInterno.startsWith('$0')
    ) {
      nuevoValorInterno = nuevoValorInterno.substring(2)
    }

    setValorInterno(nuevoValorInterno)
  }, [valorExterno])

  const onChange = (e) => {
    if (e.value.trim().length === 0) {
      setValorInterno(0)
    } else {
      setValorInterno(e.formattedValue)
    }
  }

  const onBlur = () => {
    actualizarDatos(index, id, valorInterno, titulo)
  }

  const validarServicioNuevo = (data) => {
    const arrayInicial =
      data.tipo === 'servicio'
        ? contextoTransaccion.serviciosIniciales
        : contextoTransaccion.paquetesIniciales

    return Boolean(
      arrayInicial?.find((elemento) => elemento === data.idGeneral)
    )
  }
  const validarSedeOAliadoNuevo = (data) => {
    const resultado = Boolean(
      contextoTransaccion?.[
        data?.grupo === GRUPO_PRECIOS.ALIADOS
          ? 'aliadosIniciales'
          : 'sedesIniciales'
      ].find((x) =>
        data?.grupo === GRUPO_PRECIOS.ALIADOS
          ? x === data?.aliadoId ?? 0
          : x === data?.sedeId ?? 0
      )
    )

    if (!resultado && id === 'precio') {
      return false
    }
    if (!resultado && id === 'nuevoPrecio') {
      return true
    }
    if (resultado) {
      return true
    }
  }

  const validarExistenciaArrayInicial = (contexto) => {
    return Boolean(
      contexto.serviciosIniciales?.length > 0 ||
        contexto.paquetesIniciales?.length > 0
    )
  }

  const validacionGeneral = (data) => {
    const resultado = Boolean(
      contextoTransaccion?.[
        data?.grupo === GRUPO_PRECIOS.ALIADOS
          ? 'aliadosIniciales'
          : 'sedesIniciales'
      ].find((x) =>
        data?.grupo === GRUPO_PRECIOS.ALIADOS
          ? x === data?.aliadoId ?? 0
          : x === data?.sedeId ?? 0
      )
    )
    return resultado
  }
  const validarExistenciaPrecio = (datosPrecio) => {
    let estadoABuscar =
      datosPrecio?.tipo === 'servicio'
        ? contextoTransaccion.precioServiciosIniciales
        : contextoTransaccion.preciosPaquetesIniciales

    let grupoObjetivo = datosPrecio?.grupo?.includes('SEDE')
      ? 'preciosSedes'
      : 'preciosAliados'

    let precioExistia = estadoABuscar[grupoObjetivo]?.find(
      (precio) => precio?.id === datosPrecio?.idPrecio
    )

    if (precioExistia && precioExistia?.fechaFinal !== null) {
      return true
    }

    return false
  }

  useEffect(() => {
    const validarExistenciaNuevoPrecio = () => {
      let estadoABuscar =
        original?.tipo === 'servicio'
          ? contextoTransaccion.precioServiciosIniciales
          : contextoTransaccion.preciosPaquetesIniciales

      let grupoObjetivo = original?.grupo?.includes('SEDE')
        ? 'preciosSedes'
        : 'preciosAliados'

      let precioExistia = estadoABuscar[grupoObjetivo]?.find(
        (precio) => precio?.id === original?.idPrecio
      )

      if (
        (precioExistia &&
          precioExistia?.precioAModificarse !==
            obtenerCeroSiEsNullOVacio(original?.nuevoPrecio)) ||
        typeof original?.nuevoPrecio === 'string'
      ) {
        const precioParaAgregar = {
          idGeneral: original?.idGeneral,
          idPrecio: original?.idPrecio,
          tipo: original?.tipo,
          grupo: original?.grupo,
        }

        const cambioEnSedes =
          contextoTransaccion?.arregloMomentoActivacion?.find(
            (elemento) =>
              elemento?.tipoCambioSedes &&
              elemento?.idGeneral === original?.idGeneral &&
              elemento?.grupo?.includes('SEDE')
          )

        const cambioEnAliados =
          contextoTransaccion?.arregloMomentoActivacion?.find(
            (elemento) =>
              elemento?.tipoCambioAliados &&
              elemento?.idGeneral === original?.idGeneral &&
              elemento?.grupo === original?.grupo
          )

        if (cambioEnSedes && cambioEnSedes?.grupo !== GRUPO_PRECIOS.ALIADOS) {
          precioParaAgregar.tipoCambioSedes = cambioEnSedes?.tipoCambioSedes
        } else if (
          cambioEnAliados &&
          cambioEnAliados?.grupo === GRUPO_PRECIOS.ALIADOS
        ) {
          precioParaAgregar.tipoCambioAliados =
            cambioEnAliados?.tipoCambioAliados
        }

        const valoresAAgregar = eliminarDuplicadosPorPropiedad(
          [...contextoTransaccion.arregloMomentoActivacion, precioParaAgregar],
          'idPrecio'
        )
        contextoTransaccion.setArregloMomentoActivacion(valoresAAgregar)
      } else if (
        precioExistia?.precioAModificarse ===
        obtenerCeroSiEsNullOVacio(original?.nuevoPrecio)
      ) {
        let nuevaLista = contextoTransaccion.arregloMomentoActivacion?.filter(
          (x) => {
            return x?.idPrecio !== original?.idPrecio
          }
        )
        contextoTransaccion.setArregloMomentoActivacion(nuevaLista)
      }
    }
    validarExistenciaNuevoPrecio()
  }, [original])

  return (
    <div className="w-full flex justify-start items-center relative">
      <NumericFormat
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'$'}
        value={valorInterno}
        onValueChange={onChange}
        onBlur={onBlur}
        className={`px-1 py-1 border border-cendiatra w-36
        ${
          validacionGeneral(original) &&
          Boolean(
            (deshabilitado && validarServicioNuevo(original)) ||
              (!deshabilitado && !validarServicioNuevo(original))
          ) &&
          validarExistenciaArrayInicial(contextoTransaccion)
            ? 'bg-cendiatra-gris-placeholder text-white pointer-events-none'
            : !validacionGeneral(original) && validarSedeOAliadoNuevo(original)
            ? 'bg-cendiatra-gris-placeholder text-white pointer-events-none'
            : 'placeholder-cendiatra-gris-1 text-cendiatra-gris-1'
        }  
        rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra z-10 sm:text-sm text-center`}
        onKeyDown={(evt) => {
          if (Boolean(contextoTarifario.portafolioSeleccionadoId)) {
            contextoTarifario.setSeguroDeCambio(true)
            evt.preventDefault()
          }
        }}
      />

      {Boolean(validarExistenciaPrecio(original) && !deshabilitado) ? (
        <div
          className={`w-full ${
            Boolean(
              original?.nuevoPrecio &&
                validarServicioNuevo(original) &&
                !deshabilitado
            )
              ? 'flex absolute left-20 flex-wrap'
              : 'hidden'
          } `}
        >
          <span
            className={`w-full flex justify-center items-center text-xs uppercase `}
          >
            a partir
          </span>
          <span
            className={`w-full flex justify-center items-center text-xs uppercase `}
          >
            de mañana
          </span>
        </div>
      ) : (
        <ImagenEstandar
          estilosContenedor={`w-full  ${
            Boolean(
              original?.nuevoPrecio &&
                validarServicioNuevo(original) &&
                !deshabilitado
            )
              ? 'flex'
              : 'hidden'
          } justify-center items-center`}
          imagen={iconoIformacion}
          estilosImagen={'w-6 h-6'}
          textoAlternativo={'imagen indicativa el nuevo precio fue ingresado'}
        />
      )}
    </div>
  )
}
