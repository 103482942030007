import {TEXTO_VISUAL} from '../../constantes'
import iconoAlerta from '../../imagenes/alerta.svg'

export const ContenedorErrores = ({margenContenedor = 'mt-4', errores}) => {
  return (
    <div
      className={`flex flex-row w-full border bg-cendiatra-gris-5 rounded-xl text-red-800 ${margenContenedor}`}
    >
      <div className="flex w-1/12 items-center justify-center">
        <img src={iconoAlerta} />
      </div>
      <div className="flex flex-col w-11/12 py-3 px-2">
        <h4 className="font-bold">{TEXTO_VISUAL.CONTENEDOR_ERRORES.TITULO}</h4>
        {errores && (
          <div className="flex flex-wrap">
            {errores.map((error, indice) => (
              <p key={`error-${indice}:${error}`} className="w-1/3 mt-2 px-4">
                - {error}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ContenedorErrores