export const ESTADOS_REPORTES = {
  PROCESO_PENDIENTE: 1,
  EN_EJECUCIÓN: 2,
  ERROR: 3,
  GENERADO: 4,
}

export const ESTADO_REPORTES_STRING = {
  1: 'EN EJECUCIÓN',
  2: 'PROCESO PENDIENTE',
  3: 'ERROR',
  4: 'GENERADO',
}
