import React from 'react'
import AreaDeTexto from './AreaDeTexto'
import ListaDesplegable from './ListaDesplegable'

const ListaDesplegableConObservaciones = ({
  id,
  titulo,
  opciones,
  valor,
  valorNoObservaciones,
  observaciones,
  cambioLista,
  cambioObservaciones,
}) => {
  return (
    <div className={`w-full flex justify-start items-center flex-wrap`}>
      <ListaDesplegable
        funcion={(e) => {
          cambioLista(e.target.value, id)
        }}
        estilosContenedor={'w-full'}
        estilosLista={
          'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
        }
        titulo={titulo}
        opciones={opciones}
        valor={valor}
        deshabilitado={false}
        deshabilitarElegir={true}
      />
      <AreaDeTexto
        estilosContenedor={`w-full ${
          valor && valor != valorNoObservaciones ? '' : 'hidden'
        }`}
        estilosAreaDeTexto={
          'h-20 my-3 rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
        }
        placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
        valor={observaciones}
        cambioValor={(e) => {
          cambioObservaciones(e.target.value, id)
        }}
      />
    </div>
  )
}

export default ListaDesplegableConObservaciones
