import React, {useState, createContext} from 'react'

export const ContextoNotaCredito = createContext({})

export const NotaCreditoConContexto = ({children}) => {
  const [informacionFactura, setInformacionFactura] = useState(0)
  const [datosFormulario, setDatosFormulario] = useState({
    causa: '',
    nota: '',
  })
  const [arregloArchivos, setArregloArchivos] = useState([])

  return (
    <ContextoNotaCredito.Provider
      value={{
        datosFormulario: datosFormulario,
        setDatosFormulario: setDatosFormulario,
        informacionFactura: informacionFactura,
        setInformacionFactura: setInformacionFactura,
        arregloArchivos: arregloArchivos,
        setArregloArchivos: setArregloArchivos,
      }}
    >
      {children}
    </ContextoNotaCredito.Provider>
  )
}
