import React from 'react'

const BotonLimpiarFiltro = ({titulo, reiniciarFiltro}) => {
  return (
    <div className="w-1/12 flex justify-center items-center ">
      <button
        className="group relative w-12 h-9 mt-6 flex justify-center py-2 px-4 border border-transparent text-xs font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
        onClick={reiniciarFiltro}
        type="button"
      >
        {titulo}
      </button>
    </div>
  )
}

export default BotonLimpiarFiltro
