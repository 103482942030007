import React, {useContext} from 'react'
import {CeldaConPunto, CeldaConBoton, TablaInformacionPaquete} from './index'
import BotonQuitar from '../../imagenes/removeButton.png'
import {ContextoRegistro} from '../../contexto'
import {CeldaFormatoDinero, CeldaConTooltipPersonalizable} from '../Celdas'

const FilaPaquete = ({fila, posicion}) => {
  const contextoRegistro = useContext(ContextoRegistro)

  const borrarExamen = (codigo) => {
    let nuevaLista = contextoRegistro.examenesSeleccionados.filter((x) => {
      return x.codigo !== codigo
    })
    contextoRegistro.setExamenesSeleccionados(nuevaLista)
  }

  const columns = React.useMemo(() => [
    {
      Header: fila.codigo,
      accessor: 'codigo',
      className: 'w-3/12 uppercase',
    },
    {
      Header: () => (
        <CeldaConTooltipPersonalizable
          texto={fila.nombre}
          estilosPersonalizados={'text-14px bg-cendiatra text-white mt-8 w-80'}
        />
      ),
      accessor: 'nombre',
      className: 'w-3/12 uppercase',
      Cell: ({row}) => (
        <CeldaConTooltipPersonalizable texto={row.original?.nombre} />
      ),
    },
    {
      Header: '',
      accessor: 'habilitado',
      className: 'w-3/12 uppercase',
      Cell: ({row}) => <CeldaConPunto mostrar={row.original.habilitado} />,
    },
    {
      Header: () => (
        <CeldaFormatoDinero
          estilos={
            'text-center appearance-none bg-cendiatra-verde-3 rounded relative block w-full pointer-events-none  p-1.5 text-white text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
          }
          valor={fila.precio}
        />
      ),
      accessor: 'precio',
      className: 'w-3/12 mr-1 uppercase',
    },
    {
      Header: (row) => (
        <CeldaConBoton
          funcion={() => borrarExamen(fila.codigo)}
          imagen={BotonQuitar}
        />
      ),
      id: 'botonQuitar',
      className: 'w-12 bg-white-verde-7 ml-1 -mr-12 z-50  ',
    },
  ])

  return (
    <div className="w-full flex justify-center items-center">
      <TablaInformacionPaquete
        data={fila.examenes}
        columns={columns}
        posicion={posicion}
      />
    </div>
  )
}

export default FilaPaquete
