import React, {useEffect, useContext, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useHistory, useLocation} from 'react-router-dom'
import moment from 'moment'
import {ContextApplication} from '../../../contexto'
import {InputSubirArchivo} from '../../inputs'
import {Boton} from '../../Botones'
import {ActualizarMedico} from '../../../microservicios'

import {
  PATRON_INPUT_TEXTO,
  TEXTO_VISUAL,
  regexSoloLetrasYNumeros,
} from '../../../constantes'

export default function FormularioGestionFirma({datosMedico}) {
  const {state} = useLocation()
  const history = useHistory()
  const contextoApp = useContext(ContextApplication)

  const {nombre, fechaVencimientoLicencia, urlFirmaSello} = datosMedico

  const [nombreArchivoServicio, setNombreArchivoServicio] =
    useState(urlFirmaSello)
  const [archivoRequerido, setArchivoRequerido] = useState(false)

  useEffect(() => {
    const validarExistenciaArchivo = () => {
      if (urlFirmaSello) {
        setArchivoRequerido(false)
      } else {
        setArchivoRequerido(true)
      }
    }
    validarExistenciaArchivo()
  }, [urlFirmaSello])

  const actualizarMedico = async (event) => {
    event.preventDefault()
    const medico = getValues()
    let payload = {
      correo: medico.correo,
      documento: medico.documento,
      fechaVencimientoLicencia: medico.fechaVencimientoLicencia,
      id: medico.id,
      licencia: medico.licencia,
      nombre: medico.nombre,
      registroMedico: medico.registroMedico,
      rol: medico.rol,
      urlFirmaSello: contextoApp.urlCargaImagen,
    }
    await ActualizarMedico(payload)
      .then((res) => {
        if (res.status === 200) {
          contextoApp.setUrlCargaImagen(urlFirmaSello)
          contextoApp.setModal({
            abierto: true,
            titulo: (
              <>
                <span className="font-bold">
                  LA FIRMA DE HISTORIA CLINICA PARA EL USUARIO MEDICO
                </span>
                <br />
                <span className="uppercase">{nombre}</span>
                <br />
                <br />
                <p>HA SIDO GUARDADO EXITOSAMENTE</p>
              </>
            ),
            contenido: '',
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarCerrarModalConfirmacion,
              },
              {
                nombre: 'Regresar',
                click: regresarGestionFirma,
              },
            ],
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoApp.setModal({abierto: false, botones: []})
    contextoApp.setUrlCargaImagen(urlFirmaSello)
    history.push('/main')
  }

  const regresarGestionFirma = () => {
    contextoApp.setModal({abierto: false, botones: []})
    contextoApp.setUrlCargaImagen(urlFirmaSello)
    history.push('/gestionfirma')
  }

  const {
    register,
    trigger,
    setValue,
    reset,
    getValues,
    formState: {errors, isValid},
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
    setNombreArchivoServicio(urlFirmaSello)
    setValue('urlFirmaSello', contextoApp.urlCargaImagen)
  }, [contextoApp.urlCargaImagen, setValue, trigger, urlFirmaSello])

  useEffect(() => {
    reset(datosMedico)
    if (fechaVencimientoLicencia) {
      let fechaFormato = moment(fechaVencimientoLicencia).format('YYYY-MM-DD')
      setValue('fechaVencimientoLicencia', fechaFormato)
    }
  }, [fechaVencimientoLicencia, setValue, reset, datosMedico])

  return (
    <form
      className="w-2/3 flex flex-wrap"
      onSubmit={actualizarMedico}
      noValidate
    >
      <span className="w-1/2 pr-4">
        <label className="block text-sm font-medium text-cendiatra">
          Nombre de usuario
        </label>

        <input
          className="appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 bg-cendiatra-gris-placeholder text-white"
          type="text"
          placeholder="Descripción"
          {...register('nombre', {
            required: false,
          })}
          readOnly
        />
      </span>

      <span className="w-1/2 pr-4">
        <label className="block text-sm font-medium text-cendiatra">
          Cargo
        </label>

        <input
          className="appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 bg-cendiatra-gris-placeholder text-white "
          type="text"
          placeholder="Descripción"
          {...register('rol', {
            required: false,
          })}
          readOnly
        />
      </span>
      <span className="w-1/2 pr-4">
        <label className="block text-sm font-medium text-cendiatra">
          Número de documento*
        </label>
        <span className="text-red-500 w-full">
          {errors.documento?.type === 'pattern' &&
            'Se permiten letras y números'}
        </span>
        <input
          className={
            Object.keys(errors).find((element) => element === 'documento')
              ? 'appearance-none  relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
              : 'appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
          }
          type="text"
          placeholder="Descripción"
          {...register('documento', {
            required: true,
            pattern: regexSoloLetrasYNumeros,
          })}
          disabled={!state.editable}
        />
      </span>
      <span className="w-1/2 pr-4">
        <label className="block text-sm font-medium text-cendiatra">
          Número registro medico*
        </label>
        <span className="text-red-500 w-full">
          {errors.registroMedico?.type === 'pattern'
            ? TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_TEXTO
            : ''}
        </span>
        <input
          className={
            Object.keys(errors).find((element) => element === 'registroMedico')
              ? 'appearance-none  relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
              : 'appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
          }
          type="text"
          placeholder="Descripción"
          {...register('registroMedico', {
            required: true,
            pattern: PATRON_INPUT_TEXTO,
          })}
          disabled={!state.editable}
        />
      </span>
      <span className="w-1/2 pr-4">
        <label className="block text-sm font-medium text-cendiatra">
          Número licencia salud ocupacional*
        </label>
        <span className="text-red-500 w-full">
          {errors.licencia?.type === 'pattern'
            ? TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_TEXTO
            : ''}
        </span>
        <input
          className={
            Object.keys(errors).find((element) => element === 'licencia')
              ? 'appearance-none  relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
              : 'appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
          }
          type="text"
          placeholder="Descripción"
          {...register('licencia', {
            required: true,
            pattern: PATRON_INPUT_TEXTO,
          })}
          disabled={!state.editable}
        />
      </span>

      <span className="w-1/2 pr-4">
        <label className="block text-sm font-medium  text-cendiatra">
          Fecha vencimiento licencia salud ocupacional*
        </label>
        <input
          className={
            Object.keys(errors).find(
              (element) => element === 'fechaVencimientoLicencia'
            )
              ? 'appearance-none  relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
              : 'appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
          }
          type="date"
          placeholder="Descripción"
          {...register('fechaVencimientoLicencia', {required: true})}
          disabled={!state.editable}
        />
      </span>

      <InputSubirArchivo
        editable={state.editable}
        tamanoMaximoArchivo={2}
        nombreLabel={'Subir sello y firma*'}
        errorLabel={'el sello y firma'}
        TamanoMaximo={'2MB'}
        nombreArchivoServicio={nombreArchivoServicio}
        archivoRequerido={archivoRequerido}
        archivoLimpiado={() => {
          setNombreArchivoServicio('')
          setArchivoRequerido(true)
        }}
        archivoCargado={() => setArchivoRequerido(false)}
      />

      <div
        className={`${
          state.editable ? '' : 'hidden'
        } w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex`}
      >
        <Boton
          titulo="Guardar"
          habilitado={
            isValid && !contextoApp.errorTamano && contextoApp.cargaImagen
          }
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
        />
      </div>
    </form>
  )
}
