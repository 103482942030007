import React, {useState, useEffect, useMemo} from 'react'
import {useTable, usePagination} from 'react-table'
import moment from 'moment'
import {FiltroTexto} from '../Agenda'
import {
  useFilters,
  useSortBy,
  useRowSelect,
} from 'react-table/dist/react-table.development'
import {BotonSimple, ExportarCSV} from '../Botones'
import {NOMBRE_REFERENCIAS} from '../../constantes'
import {CeldaFormatoDinero} from '../Celdas'
import PaginacionDinamica from './paginacion/PaginacionDinamica'
import PaginacionConBackend from './paginacion/PaginacionConBackend'
import {CheckboxIndeterminado, EncabezadoFondo} from './index'

const TablaConPaginacionYFiltros = ({
  data,
  estilosPersonalizados = 'w-full',
  columns,
  paginacion = false,
  paginacionBackend = false,
  filasPorPagina = 10,
  rowProps = () => {},
  habilitarExportarCsv,
  encabezados,
  fechaInicial = '',
  fechaFinal = '',
  maximoDePaginasparaMostrar,
  columnaFiltroEspecial = '',
  children = '',
  deshabilitarBordeFila = false,
  funcionLimpiarFiltro = () => {},
  funcionCambiarPagina = {},
  usarSort = true,
  sortBy = usarSort ? [NOMBRE_REFERENCIAS.FECHA_EMISION, true] : [],
  habilitarTotalesEnColumnas = false,
  estilosRow = `w-full flex justify-center items-center ${
    deshabilitarBordeFila ? '' : 'border-b border-cendiatra-gris-placeholder'
  } rounded-lg py-2`,
  funcionFilaSeleccionada = () => {},
  parametrosCheckbox = null,
  estilosTablaDatos = '',
  funcionObtenerDatos = null,
  parametrosPaginacionConBackEnd = {},
  quitarBordeIzquiero = true,
}) => {
  const [existenFiltros, setExistenFiltros] = useState(false)

  const initialStateMemo = useMemo(() => {
    if (paginacion) {
      return {pageIndex: 0, pageSize: filasPorPagina}
    }
    return {pageIndex: 0}
  }, [paginacion])

  const filterTypes = React.useMemo(
    () => ({
      dobleTexto: (page, id, filterValue) => {
        return page.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue.Fila1)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase()) ||
                String(rowValue.Fila2)
                  .toLowerCase()
                  .includes(String(filterValue).toLowerCase())
            : true
        })
      },
      fecha: (page, id, filterValue) => {
        return page.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? moment(rowValue).format('DD/MM/YYYY') ==
                moment(filterValue).format('DD/MM/YYYY')
            : true
        })
      },
      lista: (page, id, filterValue) => {
        if (!filterValue) return page
        return page.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined ? rowValue == filterValue : false
        })
      },
      fechaCustom: (rows, id, filterValues) => {
        let sd = moment(filterValues[0]).format('YYYY-MM-DD')
        let ed = moment(filterValues[1]).format('YYYY-MM-DD')

        return rows.filter((r) => {
          var time = moment(r.values[id]).format('YYYY-MM-DD')
          if (filterValues.length === 0) return rows

          if (filterValues[0] && !filterValues[1]) {
            return time >= sd
          }
          if (!filterValues[0] && filterValues[1]) {
            return time <= ed
          }
          if (filterValues[0] && filterValues[1]) {
            return time >= sd && time <= ed
          }
          return rows
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: FiltroTexto,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
    rows,
    pageOptions,
    state: {pageIndex, filters},
    setAllFilters,
    setFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        ...initialStateMemo,
        sortBy,
      },
      stateReducer: (newState, action) => {
        if (action.type === 'toggleRowSelected') {
          newState.selectedRowIds = {
            [action.id]: true,
          }
        }
        return newState
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (parametrosCheckbox) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: 'checkbox',
            className: parametrosCheckbox?.estilosColumna,
            Header: () => (
              <EncabezadoFondo
                titulo={parametrosCheckbox?.titulo}
                paddingFondoClaro={''}
              />
            ),
            headerStyle: parametrosCheckbox?.estilosEncabezado,
            disableFilters: true,
            cellStyles: parametrosCheckbox?.estilosCelda,
            Cell: ({row}) => (
              <CheckboxIndeterminado {...row.getToggleRowSelectedProps()} />
            ),
          },
        ])
      }
    }
  )

  useEffect(() => {
    if (funcionObtenerDatos) {
      funcionObtenerDatos(rows)
    }
  }, [rows])

  useEffect(() => {
    const idFilaActual =
      selectedFlatRows[0]?.original['id'] || selectedFlatRows[0]?.original['Id']
    if (idFilaActual) {
      funcionFilaSeleccionada(selectedFlatRows[0])
    }
  }, [selectedFlatRows[0]?.id])

  const datos = useMemo(() => {
    return paginacion ? page : rows
  }, [paginacion, page, rows])

  useEffect(() => {
    if (headerGroups[0].headers.find((header) => header.canFilter === true)) {
      setExistenFiltros(true)
    } else {
      setExistenFiltros(false)
    }
  }, [])

  const sumarColumna = (rows, accessor) => {
    return rows.reduce((sum, row) => {
      return sum + row.original[accessor]
    }, 0)
  }

  const caracteristicasValor = useMemo(() => {
    if (habilitarTotalesEnColumnas && rows.length > 0) {
      return columns.map((column) => ({
        tipoTotal: column.tipoTotal ?? '',
        estilos: column.className,
        valorTotal: sumarColumna(rows, column.accessor),
      }))
    }
  }, [columns, data, rows])

  const totalFilas = useMemo(() => {
    return {
      id: 'totales',
      propiedadesValor: caracteristicasValor,
    }
  }, [caracteristicasValor])

  const establecerComponenteParaTotal = (caracteristicasTotal) => {
    switch (caracteristicasTotal.tipoTotal) {
      case 'titulo':
        return (
          <div
            className={`${caracteristicasTotal.estilos}flex justify-center items-center`}
          >
            <div className="text-center text-cendiatra text-21px font-bold w-2/12">
              TOTAL:
            </div>
          </div>
        )
      case 'numero':
        return (
          <div
            className={`text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px  focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          >
            {caracteristicasTotal.valorTotal}
          </div>
        )
      case 'moneda':
        return (
          <CeldaFormatoDinero
            estilos={` text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
            valor={caracteristicasTotal.valorTotal}
          />
        )
      default:
        return <div className={`${caracteristicasTotal.estilos}`}></div>
    }
  }

  const manejarObtenerDatosExportar = () => {
    let informacionCSV = rows.map((row) => {
      return {
        ...row.original,
        nombreTercero:
          row.original.nombreTercero.Fila1 +
          ' - ' +
          row.original.nombreTercero.Fila2,
      }
    })
    return informacionCSV
  }

  useEffect(() => {
    if (columnaFiltroEspecial) {
      setFilter(columnaFiltroEspecial, [fechaInicial, fechaFinal])
    }
  }, [fechaInicial, fechaFinal])

  const limpiarFiltros = () => {
    setAllFilters([])
    funcionLimpiarFiltro()
  }

  return (
    <div className={`${estilosPersonalizados}`}>
      <div
        className={`w-full ${
          habilitarExportarCsv ? 'flex' : 'hidden'
        } flex-wrap justify-between items-start -mt-20`}
      >
        <div className="w-3/12 flex justify-evenly items-center"></div>
        <div className="w-5/12 flex justify-between items-center"></div>
        <div className="w-3/12 h-auto flex flex-wrap justify-center content-end text-center">
          <ExportarCSV
            encabezados={encabezados}
            obtenerDatos={manejarObtenerDatosExportar}
            nombreArchivo="Documentacion_electronica.csv"
          />

          <span className="text-gray-400 text-xs w-full">Exportar Excel</span>
        </div>
      </div>
      <div className="flex justify-center items-center w-full my-5 h-full flex-wrap">
        <table className="flex flex-wrap w-full " {...getTableProps()}>
          <thead className="flex w-full flex-wrap text-14px">
            {headerGroups.map((headerGroup, idx) => (
              <tr
                className="flex w-full flex-wrap text-center justify-center items-center"
                {...headerGroup.getHeaderGroupProps()}
                key={idx}
              >
                {headerGroup.headers.map((header, idxHeader) => {
                  return (
                    <th
                      {...header.getHeaderProps({
                        className: `${header.className} ${header.headerStyle} ${
                          header?.disableBackground
                            ? ''
                            : 'bg-cendiatra-verde-7'
                        } flex flex-wrap justify-center items-center content-center`,
                      })}
                      key={idxHeader}
                    >
                      {header.canFilter ? (
                        <div
                          className="w-full flex-wrap flex bg-cendiatra-verde-5 rounded-md text-14px h-90% "
                          style={{paddingTop: '5px'}}
                        >
                          <div className="w-full flex justify-center items-center  rounded-md text-14px">
                            {header.render('Header')}
                          </div>
                          <div
                            className="w-full flex justify-evenly items-center mb-2 text-13px  "
                            style={{margin: ''}}
                          >
                            {header.render('Filter')}
                          </div>
                        </div>
                      ) : (
                        header.render('Header')
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody
            className={`flex w-full flex-wrap text-13px content-start ${estilosTablaDatos}`}
            {...getTableBodyProps()}
          >
            {datos.map((row, index) => {
              {
                prepareRow(row)

                return (
                  <>
                    <div className={estilosRow} key={index}>
                      <tr
                        className={`flex w-full h-full flex-wrap text-center justify-center items-center ${
                          quitarBordeIzquiero ? 'rounded-l-3xl' : 'rounded-3xl'
                        }`}
                        {...row.getRowProps(rowProps(row))}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                className: `${cell.column.className} ${cell.column.cellStyles} uppercase `,
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    </div>
                  </>
                )
              }
            })}
          </tbody>
          {habilitarTotalesEnColumnas && rows.length > 0 ? (
            <tfoot className="w-full flex justify-center items-center">
              <tr className={'w-full flex justify-center items-center'}>
                {totalFilas.propiedadesValor.map((caracteristicas, index) => (
                  <td
                    className={`${caracteristicas.estilos} flex justify-evenly items-center`}
                    key={index}
                  >
                    {establecerComponenteParaTotal(caracteristicas)}
                  </td>
                ))}
              </tr>
            </tfoot>
          ) : null}

          {children}
          {!columns.every((c) => c?.disableFilters) ? (
            <div
              className={`w-full ${
                existenFiltros ? 'flex' : 'hidden'
              } justify-end items-center -mb-5 mt-5`}
            >
              <BotonSimple
                texto={'Limpiar filtros'}
                estilosBoton={
                  Boolean(!filters.find((filtro) => filtro.value !== ''))
                    ? 'w-32 h-40px p-1.5 rounded-lg bg-cendiatra-gris-4 bg-cover bg-no-repeat bg-center text-white font-bold  text-14px mx-25px pointer-events-none	'
                    : 'w-32 h-40px p-1.5 rounded-lg bg-btnBg bg-cover bg-no-repeat bg-center text-white  font-bold text-14px mx-25px'
                }
                funcion={limpiarFiltros}
                deshabilitado={Boolean(
                  !filters.find((filtro) => filtro.value !== '')
                )}
              />
            </div>
          ) : null}
        </table>
        {data.length > filasPorPagina && paginacion ? (
          <PaginacionDinamica
            filasPorPagina={filasPorPagina}
            opcionesDePaginas={pageOptions}
            index={pageIndex}
            funcionIrAPagina={(e) => gotoPage(e)}
            datos={data}
          />
        ) : null}
        {filasPorPagina && paginacionBackend ? (
          <PaginacionConBackend
            totalPaginas={parametrosPaginacionConBackEnd?.totalPaginas}
            paginaActual={parametrosPaginacionConBackEnd?.paginaActual}
            funcionIrAPagina={funcionCambiarPagina}
            maximoDePaginasparaMostrar={maximoDePaginasparaMostrar}
          />
        ) : null}
      </div>
    </div>
  )
}

export default TablaConPaginacionYFiltros
