import {useEffect} from 'react'
import {ObtenerTodasLasSedes} from '../cache/servicios-cache/Sedes'

export const useObtenerSedesMarcadas = (contexto) => {
  useEffect(() => {
    ObtenerTodasLasSedes().then((res) => {
      const activo = {Activo: true}

      contexto.setSedesSeleccionadas(
        res.map((elemento) => {
          return {
            ...elemento,
            ...activo,
          }
        })
      )
    })
  }, [])
}
