import {useEffect, useMemo, useState} from 'react'
import {nombresDeComponentes} from '../../../constantes/tiposDeCampos'
import {Boton} from '../../Botones'
import ListaDesplegable from '../../inputs/ListaDesplegable'

export default function EditarPropiedadesComponentes({
  campo,
  funcionRegresar,
  funcionGuardar,
}) {
  const [atributos, setAtributos] = useState(campo?.atributos)
  const [componenteSeleccionado, setComponenteSeleccionado] = useState(0)
  const listadoDeComponentes = useMemo(
    () => [{Id: 1, Nombre: nombresDeComponentes.AUDIOMETRIATAMIZ}],
    []
  )

  const actualizarAtributos = (id) => {
    const componente = listadoDeComponentes.find((l) => l.Id === id) ?? null

    if (!componente) {
      setComponenteSeleccionado(0)
      setAtributos({})
      return
    }

    setAtributos({componente: componente.Nombre})
  }

  useEffect(() => {
    const componente =
      listadoDeComponentes.find(
        (item) => item.Nombre === campo.atributos?.componente
      ) ?? null

    if (componente) {
      setComponenteSeleccionado(componente.Id)
    }
  }, [campo])

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full flex flex-wrap justify-center items-center">
        <div className="w-full flex flex-wrap">
          <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2">
            <label className="flex flex-wrap w-full align-middle content-center">
              SELECCIONA EL COMPONENTE
            </label>
          </div>
          <ListaDesplegable
            estilosContenedor={'w-1/2 mt-3'}
            estilosLista={`appearance-none rounded relative block w-full  p-1.5 border ${
              componenteSeleccionado > 0
                ? 'border-cendiatra'
                : 'border-cendiatra-rojo-1'
            } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
            opciones={listadoDeComponentes}
            placeholder={'SELECCIONE UNA OPCIÓN'}
            funcion={(evt) => {
              const valor = parseInt(evt.target.value)
              const valorFixeado = isNaN(valor) ? 0 : valor
              setComponenteSeleccionado(valorFixeado)
              actualizarAtributos(valorFixeado)
            }}
            valor={componenteSeleccionado}
          />
        </div>
        <Boton
          titulo="Regresar"
          lineaPrimerCaracter={false}
          habilitado={true}
          funcionCLick={() => {
            funcionRegresar()
          }}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          tamanoTexto={'text-sm'}
          ancho={'w-2/12'}
          alto={'h-10 my-10'}
        />
        <Boton
          titulo="Guardar"
          lineaPrimerCaracter={false}
          habilitado={true}
          funcionCLick={() => {
            funcionGuardar(false, false, false, atributos)
          }}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          tamanoTexto={'text-sm'}
          ancho={'w-2/12'}
          alto={'h-10 my-10'}
        />
      </div>
    </div>
  )
}
