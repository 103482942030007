import React, {useState, useContext, useEffect, useLayoutEffect} from 'react'
import {useForm} from 'react-hook-form'
import Modal from '../../componentes/Modales/Modal.jsx'
import {useMsal} from '@azure/msal-react'
import {useHistory} from 'react-router-dom'
import {ContextApplication} from '../../contexto'
import {postNewPatientHistory} from '../../servicios/Medico.js'
import moment from 'moment'
import Dexie from 'dexie'

const MedicForm = () => {
  const ctx = useContext(ContextApplication)

  const [formSection, setFormSection] = useState(1)
  const [changeFieldType, setChangeFieldType] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  //Tab 5
  const [workAccident, setWorkAccident] = useState(false)
  const [workAccidentObs, setWorkAccidentObs] = useState(false)

  const [workPrecedent, setWorkPrecedent] = useState(false)
  const [workPrecedentObs, setWorkPrecedentObs] = useState(false)

  const [workIllness, setWorkIllness] = useState(false)
  const [workIllnessObs, setWorkIllnessObs] = useState(false)

  //Tab 5

  //Tab 6 row 1
  const [surgicalPrecedent, setSurgicalPrecedent] = useState(false)
  const [surgicalPrecedentObs, setSurgicalPrecedentObs] = useState(false)

  const [allergyPrecedent, setAllergyPrecedent] = useState(false)
  const [allergyPrecedentObs, setAllergyPrecedentObs] = useState(false)

  const [familyPrecedent, setFamilyPrecedent] = useState(false)
  const [familyPrecedentObs, setFamilyPrecedentObs] = useState(false)

  const [herniaPrecedent, setHerniaPrecedent] = useState(false)
  const [herniaPrecedentObs, setHerniaPrecedentObs] = useState(false)

  const [auditoryPrecedent, setAuditoryPrecedent] = useState(false)
  const [auditoryPrecedentObs, setAuditoryPrecedentObs] = useState(false)

  const [diabetesPrecedent, setDiabetesPrecedent] = useState(false)
  const [diabetesPrecedentObs, setDiabetesPrecedentObs] = useState(false)
  //Tab 6 row 1

  //Tab 6 Row 2
  const [pathologicalPrecedent, setPathologicalPrecedent] = useState(false)
  const [pathologicalPrecedentObs, setPathologicalPrecedentObs] =
    useState(false)

  const [epilepsyPrecedent, setEpilepsyPrecedent] = useState(false)
  const [epilepsyPrecedentObs, setEpilepsyPrecedentObs] = useState(false)

  const [gastritisPrecedent, setGastritisPrecedent] = useState(false)
  const [gastritisPrecedentObs, setGastritisPrecedentObs] = useState(false)

  const [traumaticPrecedent, setTraumaticPrecedent] = useState(false)
  const [traumaticPrecedentObs, setTraumaticPrecedentObs] = useState(false)

  const [carpalTunnelPrecedent, setCarpalTunnelPrecedent] = useState(false)
  const [carpalTunnelPrecedentObs, setCarpalTunnelPrecedentObs] =
    useState(false)

  const [varicosePrecedent, setVaricosePrecedent] = useState(false)
  const [varicosePrecedentObs, setVaricosePrecedentObs] = useState(false)
  //Tab 6 Row 2

  //Tab 6 Row 3
  const [lumbagoPrecedent, setLumbagoPrecedent] = useState(false)
  const [lumbagoPrecedentObs, setLumbagoPrecedentObs] = useState(false)

  const [mentalPrecedent, setMentalPrecedent] = useState(false)
  const [mentalPrecedentObs, setMentalPrecedentObs] = useState(false)

  const [hypertensionPrecedent, setHypertensionPrecedent] = useState(false)
  const [hypertensionPrecedentObs, setHypertensionPrecedentObs] =
    useState(false)

  const [visualPrecedent, setVisualPrecedent] = useState(false)
  const [visualPrecedentObs, setVisualPrecedentObs] = useState(false)

  const [skinPrecedent, setSkinPrecedent] = useState(false)
  const [skinPrecedentObs, setSkinPrecedentObs] = useState(false)
  //Tab 6 Row 3

  const [gestationPrecedent, setGestationPrecedent] = useState(false)
  const [gestationPrecedentObs, setGestationPrecedentObs] = useState(false)

  const [abortionPrecedent, setAbortionPrecedent] = useState(false)
  const [abortionPrecedentObs, setAbortionPrecedentObs] = useState(false)

  const [fumPrecedent, setFumPrecedent] = useState(false)
  const [fumPrecedentObs, setFumPrecedentObs] = useState(false)

  const [laborPrecedent, setLaborPrecedent] = useState(false)
  const [laborPrecedentObs, setLaborPrecedentObs] = useState(false)

  const [tobaccoPrecedent, setTobaccoPrecedent] = useState(false)
  const [tobaccoPrecedentObs, setTobaccoPrecedentObs] = useState(false)

  const [alcoholPrecedent, setAlcoholPrecedent] = useState(false)
  const [alcoholPrecedentObs, setAlcoholPrecedentObs] = useState(false)

  const [psychoactivePrecedent, setPsychoactivePrecedent] = useState(false)
  const [psychoactivePrecedentObs, setPsychoactivePrecedentObs] =
    useState(false)

  const [medicinePrecedent, setMedicinePrecedent] = useState(false)
  const [medicinePrecedentObs, setMedicinePrecedentObs] = useState(false)

  const history = useHistory()
  const {accounts} = useMsal()

  // CALCULATE IMC
  const [patientWeight, setPatientWeight] = useState('')
  const [patientHeight, setPatientHeight] = useState('')
  const [IMC, setIMC] = useState('')

  const [headNeckObservation, setHeadNeckObservation] = useState(false)
  const [eyesObservation, setEyesObservation] = useState(false)
  const [closeVisionRightEyeObservation, setCloseVisionRightEyeObservation] =
    useState(false)
  const [
    distantVisionRightEyeObservation,
    setDistantVisionRightEyeObservation,
  ] = useState(false)
  const [closeVisionLefttEyeObservation, setCloseVisionLefttEyeObservation] =
    useState(false)
  const [distantVisionLeftEyeObservation, setDistantVisionLeftEyeObservation] =
    useState(false)
  const [noseObservation, setNoseObservation] = useState(false)
  const [earsObservation, setEarsObservation] = useState(false)
  const [pharynxObservation, setPharynxObservation] = useState(false)
  const [thoraxObservation, setThoraxObservation] = useState(false)
  const [cardioObservation, setCardioObservation] = useState(false)
  const [abdomenObservation, setAbdomenObservation] = useState(false)
  const [spineObservation, setSpineObservation] = useState(false)
  const [backObservation, setBackObservation] = useState(false)
  const [skinObservation, setSkinObservation] = useState(false)
  const [nervous_systemObservation, setNervous_systemObservation] =
    useState(false)
  const [limbsObservation, setLimbsObservation] = useState(false)
  const [varicoseObservation, setVaricoseObservation] = useState(false)
  const [genitalsObservation, setGenitalsObservation] = useState(false)

  const [tinnelRightObservation, setTinnelRightObservation] = useState(false)
  const [tinnelLeftObservation, setTinnelLeftObservation] = useState(false)
  const [phanelRightObservation, setPhanelRightObservation] = useState(false)
  const [phanelLeftObservation, setPhanelLeftObservation] = useState(false)

  const [conceptObservation, setConceptObservation] = useState(false)
  const [suspectedIllnessObservation, setSuspectedIllnessObservation] =
    useState(false)
  const [limitationsObservation, setLimitationsObservation] = useState(false)

  const [avoidOverloadObservation, setAvoidOverloadObservation] =
    useState(false)
  const [doctorsAppointmentObservation, setDoctorsAppointmentObservation] =
    useState(false)
  const [changeDietObservation, setChangeDietObservation] = useState(false)
  const [optometryTestObservation, setOptometryTestObservation] =
    useState(false)
  const [EPSValuationObservation, setEPSValuationObservation] = useState(false)
  const [otherRecommendationsObservation, setOtherRecommendationsObservation] =
    useState(false)

  const [visualConditionObservation, setVisualConditionObservation] =
    useState(false)
  const [auditoryConditionObservation, setAuditoryConditionObservation] =
    useState(false)
  const [biomechanicRiskObservation, setBiomechanicRiskObservation] =
    useState(false)
  const [cardiovascularObservation, setCardiovascularObservation] =
    useState(false)
  const [biologicalObservation, setBiologicalObservation] = useState(false)
  const [chemicalRiskObservation, setChemicalRiskObservation] = useState(false)
  const [psychoSocialObservation, setPsychoSocialObservation] = useState(false)
  const [muscularRiskObservation, setMuscularRiskObservation] = useState(false)
  const [traumaObservation, setTraumaObservation] = useState(false)

  //Diagnosis
  const [showDiagnosis1, setShowDiagnosis1] = useState(false)
  const [filtredDiagnosis1, setFiltredDiagnosis1] = useState([])
  const [searchDiagnosis1, setSearchDiagnosis1] = useState('')

  const [showDiagnosis2, setShowDiagnosis2] = useState(false)
  const [filtredDiagnosis2, setFiltredDiagnosis2] = useState([])
  const [searchDiagnosis2, setSearchDiagnosis2] = useState('')

  const [showDiagnosis3, setShowDiagnosis3] = useState(false)
  const [filtredDiagnosis3, setFiltredDiagnosis3] = useState([])
  const [searchDiagnosis3, setSearchDiagnosis3] = useState('')

  const [showDiagnosis4, setShowDiagnosis4] = useState(false)
  const [filtredDiagnosis4, setFiltredDiagnosis4] = useState([])
  const [searchDiagnosis4, setSearchDiagnosis4] = useState('')

  const [showDiagnosis5, setShowDiagnosis5] = useState(false)
  const [filtredDiagnosis5, setFiltredDiagnosis5] = useState([])
  const [searchDiagnosis5, setSearchDiagnosis5] = useState('')

  const [showDiagnosis6, setShowDiagnosis6] = useState(false)
  const [filtredDiagnosis6, setFiltredDiagnosis6] = useState([])
  const [searchDiagnosis6, setSearchDiagnosis6] = useState('')

  const [showDiagnosis7, setShowDiagnosis7] = useState(false)
  const [filtredDiagnosis7, setFiltredDiagnosis7] = useState([])
  const [searchDiagnosis7, setSearchDiagnosis7] = useState('')

  const [showDiagnosis8, setShowDiagnosis8] = useState(false)
  const [filtredDiagnosis8, setFiltredDiagnosis8] = useState([])
  const [searchDiagnosis8, setSearchDiagnosis8] = useState('')

  const changeDiagnosisSearch = (e, search, filter, showState, showSet) => {
    search(e.target.value)

    if (e.target.value.length >= 5) {
      //Pendiente: Buscar en chaché
      if (showState === false) {
        showSet(true)
      }
    } else {
      showSet(false)
    }
  }

  const selectedOption = (option, search, showSet, fieldName) => {
    search(option.Name)
    showSet(false)
    setValue(fieldName, option.Name)
    clearErrors(fieldName)
  }

  const userRole = accounts[0].idTokenClaims.extension_Role

  useEffect(() => {
    if (userRole !== 'Medic') {
      ctx.setShowDoctorMenu(false)
      history.push('/createpatient')
    }

    document.getElementById('triggerButton').click()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToErrorPage = (pageNumber) => {
    setShowErrors(false)
    setFormSection(pageNumber)
  }
  const goToPage = (pageNumber) => {
    setFormSection(pageNumber)
  }

  const addFormSection = () => {
    if (formSection === 14) {
      return
    } else {
      // reset({ ...getValues(), location: "" })
      setFormSection(formSection + 1)
    }
  }

  const removeFormSection = () => {
    if (formSection === 1) {
      return
    } else {
      setFormSection(formSection - 1)
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: {errors},
  } = useForm({
    defaultValues: {
      businessName: ctx.patientAgendaInfo.nameClient,
      charge: ctx.patientAgendaInfo.charge,
      examType: ctx.patientAgendaInfo.examType,
    },
  })

  const changeState = (changeState, state) => {
    if (state) {
      changeState(false)
    } else {
      changeState(true)
    }
  }
  const onInvalid = () => {
    setShowErrors(true)
  }

  const calculateIMC = (weight, height) => {
    if (weight === '' && height === '') {
      document.getElementById('triggerButton').click()
    }
    if (weight === '' || height === '') {
      setIMC('')
      setValue('classification', '')
      setValue('IMC', '')

      return
    }

    clearErrors(['weightKG', 'heightCM', 'classification', 'IMC'])
    const IMC = ((weight / Math.pow(height, 2)) * 10000).toFixed(1)

    if (IMC < 18.5) {
      setIMC(IMC)
      setValue('IMC', IMC)
      setValue('classification', 'Peso inferior al normal')
      clearErrors(['weightKG', 'heightCM', 'classification', 'IMC'])
    }
    if (IMC >= 18.5 && IMC <= 24.9) {
      setIMC(IMC)
      setValue('IMC', IMC)
      setValue('classification', 'Normal')
      clearErrors(['weightKG', 'heightCM', 'classification', 'IMC'])
    }
    if (IMC >= 25.0 && IMC <= 29.9) {
      setIMC(IMC)
      setValue('IMC', IMC)
      setValue('classification', 'Peso superior al normal')
      clearErrors(['weightKG', 'heightCM', 'classification', 'IMC'])
    }
    if (IMC >= 30.0) {
      setIMC(IMC)
      setValue('IMC', IMC)
      setValue('classification', 'Obesidad')
      clearErrors(['weightKG', 'heightCM', 'classification', 'IMC'])
    }
  }

  const changeWeightOrHeight = (e, setFunction, name) => {
    setFunction(e.target.value)
    setValue(name, e.target.value)
  }
  useLayoutEffect(() => {
    calculateIMC(patientWeight, patientHeight)
  }, [patientWeight, patientHeight]) // eslint-disable-line react-hooks/exhaustive-deps

  const submitMedicForm = async (data) => {
    
    const requestModel = {
      idOrder: ctx.patientAgendaInfo.idOrder,
      idPacient: ctx.patientAgendaInfo.idPacient,
      EPSValuation: data.EPSValuation,
      FC_MIN: data.FC_MIN,
      IMC: data.IMC,
      TA_MMG: data.TA_MMG,
      abdomen: data.abdomen,
      accidentAtWork: data.accidentAtWork,
      accidentAtWorkObservation: data.accidentAtWorkObservation,
      ageTime: data.ageTime,
      audiometric: data.audiometric,
      auditoryCondition: data.auditoryCondition,
      auditoryScreening: data.auditoryScreening,
      avoidOverload: data.avoidOverload,
      back: data.back,
      biological: data.biological,
      biomechanicRisk: data.biomechanicRisk,
      bodyDiscoveries: data.bodyDiscoveries,
      brigadeDate: data.brigadeDate,
      businessName: data.businessName,
      cardio: data.cardio,
      cardiovascular: data.cardiovascular,
      changeDiet: data.changeDiet,
      charge: data.charge,
      chemicalRisk: data.chemicalRisk,
      childrenNumber: data.childrenNumber,
      classification: data.classification,
      closeVisionLefttEye: data.closeVisionLefttEye,
      closeVisionRightEye: data.closeVisionRightEye,
      complementaryExams: data.complementaryExams,
      concept: data.concept,
      diagnosis1: data.diagnosis1,
      diagnosis2: data.diagnosis2,
      diagnosis3: data.diagnosis3,
      diagnosis4: data.diagnosis4,
      diagnosis5: data.diagnosis5,
      diagnosis6: data.diagnosis6,
      diagnosis7: data.diagnosis7,
      diagnosis8: data.diagnosis8,
      discovery: data.discovery,
      distantVisionLeftEye: data.distantVisionLeftEye,
      distantVisionRightEye: data.distantVisionRightEye,
      doctorsAppointment: data.doctorsAppointment,
      ears: data.ears,
      economicActivity: data.economicActivity,
      equipment: data.equipment,
      examType: data.examType,
      eyes: data.eyes,
      funtions: data.funtions,
      genitals: data.genitals,
      head_neck: data.head_neck,
      healthState: data.healthState,
      heightCM: data.heightCM,
      illnessForWork: data.illnessForWork,
      illnessForWorkObservation: data.illnessForWorkObservation,
      laboratoriesDiscoveries: data.laboratoriesDiscoveries,
      lastLabor: data.lastLabor,
      limbs: data.limbs,
      limitations: data.limitations,
      link: data.link,
      monthTime: data.monthTime,
      muscularRisk: data.muscularRisk,
      nationality: data.nationality,
      nervous_system: data.nervous_system,
      nose: data.nose,
      occupation: data.occupation,
      optometry: data.optometry,
      optometryTest: data.optometryTest,
      otherRecommendations: data.otherRecommendations,
      otherRisk: data.otherRisk,
      otherTest: data.otherTest,
      phanelLeft: data.phanelLeft,
      phanelRight: data.phanelRight,
      pharynx: data.pharynx,
      placeOfResidence: data.placeOfResidence,
      precedentAtWork: data.precedentAtWork,
      precedentAtWorkObservation: data.precedentAtWorkObservation,
      previousAbortion: data.previousAbortion,
      previousAbortionObservation: data.previousAbortionObservation,
      previousAlcohol: data.previousAlcohol,
      previousAlcoholObservation: data.previousAlcoholObservation,
      previousAllergy: data.previousAllergy,
      previousAllergyObservation: data.previousAllergyObservation,
      previousAuditory: data.previousAuditory,
      previousAuditoryObservation: data.previousAuditoryObservation,
      previousCarpalTunnel: data.previousCarpalTunnel,
      previousCarpalTunnelObservation: data.previousCarpalTunnelObservation,
      previousDiabetes: data.previousDiabetes,
      previousDiabetesObservation: data.previousDiabetesObservation,
      previousEpilepsy: data.previousEpilepsy,
      previousEpilepsyObservation: data.previousEpilepsyObservation,
      previousFUM: data.previousFUM,
      previousFUMObservation: data.previousFUMObservation,
      previousFamily: data.previousFamily,
      previousFamilyObservation: data.previousFamilyObservation,
      previousGastritis: data.previousGastritis,
      previousGastritisObservation: data.previousGastritisObservation,
      previousGestation: data.previousGestation,
      previousGestationObservation: data.previousGestationObservation,
      previousHernia: data.previousHernia,
      previousHerniaObservation: data.previousHerniaObservation,
      previousHypertension: data.previousHypertension,
      previousHypertensionObservation: data.previousHypertensionObservation,
      previousLabor: data.previousLabor,
      previousLaborObservation: data.previousLaborObservation,
      previousLumbago: data.previousLumbago,
      previousLumbagoObservation: data.previousLumbagoObservation,
      previousMedicine: data.previousMedicine,
      previousMedicineObservation: data.previousMedicineObservation,
      previousMentaObservation: data.previousMentaObservation,
      previousMental: data.previousMental,
      previousPathology: data.previousPathology,
      previousPathologyObservation: data.previousPathologyObservation,
      previousPsychoactive: data.previousPsychoactive,
      previousPsychoactiveObservation: data.previousPsychoactiveObservation,
      previousSkin: data.previousSkin,
      previousSkinObservation: data.previousSkinObservation,
      previousSurgical: data.previousSurgical,
      previousSurgicalObservation: data.previousSurgicalObservation,
      previousTobacco: data.previousTobacco,
      previousTobaccoObservation: data.previousTobaccoObservation,
      previousTraumatic: data.previousTraumatic,
      previousTraumaticObservation: data.previousTraumaticObservation,
      previousVaricose: data.previousVaricose,
      previousVaricoseObservation: data.previousVaricoseObservation,
      previousVisual: data.previousVisual,
      previousVisualObservation: data.previousVisualObservation,
      psychoSocial: data.psychoSocial,
      race: data.race,
      rawMaterial: data.rawMaterial,
      relationship: data.relationship,
      responsibleName: data.responsibleName,
      restrictionTime: data.restrictionTime,
      restrictionType: data.restrictionType,
      skin: data.skin,
      spine: data.spine,
      spirometry: data.spirometry,
      suspectedIllness: data.suspectedIllness,
      thorax: data.thorax,
      tinnelLeft: data.tinnelLeft,
      tinnelRight: data.tinnelRight,
      toxicologicalObservation: data.toxicologicalObservation,
      trauma: data.trauma,
      varicose: data.varicose,
      visiometry: data.visiometry,
      visualCondition: data.visualCondition,
      voice: data.voice,
      weightKG: data.weightKG,
      workArea: data.workArea,
      workTime: data.workTime,
      head_neckObservation: data.head_neckObservation,
      eyesObservation: data.eyesObservation,
      closeVisionRightEyeObservation: data.closeVisionRightEyeObservation,
      distantVisionRightEyeObservation: data.distantVisionRightEyeObservation,
      closeVisionLefttEyeObservation: data.closeVisionLefttEyeObservation,
      distantVisionLeftEyeObservation: data.distantVisionLeftEyeObservation,
      earsObservation: data.earsObservation,
      pharynxObservation: data.pharynxObservation,
      thoraxObservation: data.thoraxObservation,
      cardioObservation: data.cardioObservation,
      abdomenObservation: data.abdomenObservation,
      spineObservation: data.spineObservation,
      backObservation: data.backObservation,
      skinObservation: data.skinObservation,
      nervous_systemObservation: data.nervous_systemObservation,
      limbsObservation: data.limbsObservation,
      varicoseObservation: data.varicoseObservation,
      genitalsObservation: data.genitalsObservation,
      tinnelRightObservation: data.tinnelRightObservation,
      tinnelLeftObservation: data.tinnelLeftObservation,
      phanelRightObservation: data.phanelRightObservation,
      phanelLeftObservation: data.phanelLeftObservation,
      conceptObservation: data.conceptObservation,
      suspectedIllnessObservation: data.suspectedIllnessObservation,
      limitationsObservation: data.limitationsObservation,
      avoidOverloadObservation: data.avoidOverloadObservation,
      doctorsAppointmentObservation: doctorsAppointmentObservation,
      changeDietObservation: data.changeDietObservation,
      optometryTestObservation: data.optometryTestObservation,
      EPSValuationObservation: data.EPSValuationObservation,
      otherRecommendationsObservation: data.otherRecommendationsObservation,
      visualConditionObservation: data.visualConditionObservation,
      auditoryConditionObservation: data.auditoryConditionObservation,
      biomechanicRiskObservation: data.biomechanicRiskObservation,
      cardiovascularObservation: data.cardiovascularObservation,
      biologicalObservation: data.biologicalObservation,
      chemicalRiskObservation: data.chemicalRiskObservation,
      psychoSocialObservation: data.psychoSocialObservation,
      muscularRiskObservation: data.muscularRiskObservation,
      traumaObservation: data.traumaObservation,
    }
    if (ctx.offlineMode === true) {
      ctx.setIsLoading(true)
      const cendiatraDbName = 'cendiatra-db'
      let db = await new Dexie(cendiatraDbName).open()

      const form = {
        Id: moment().format(),
        data: requestModel,
      }

      db.table('CreateMedicForm')
        .add(form)
        .then(() => {
          console.log('Order Form saved')
          history.push('/medicalschedule')
        })
        .finally(() => {
          ctx.setIsLoading(false)
        })
      return
    }

    await postNewPatientHistory(requestModel)
      .then((res) => {
        if (res.status === 200) {
          console.warn('Se subio correctamente la historia')
          setModalMessage('La Historia ha sido creada correctamente')
        }
      })
      .catch((error) => {
        setModalMessage(`Ocurió un error: ${error}`)
        console.warn(error)
      })
  }
  const finishHistory = () => {
    ctx.setPatientAgendaInfo([])
    history.push('/medicalschedule')
  }

  return (
    <>
      {modalMessage.includes('correctamente') ? (
        <Modal open={modalMessage.includes('correctamente')}>
          <div className="w-full flex flex-wrap justify-center">
            <div className="w-full text-center text-lg text-cendiatra">
              <span>{modalMessage}</span>
            </div>
            <div className="w-full flex justify-center mt-5">
              <button
                type="button"
                className="group relative w-24 h-9 -mt-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                onClick={() => finishHistory()}
              >
                Finalizar
              </button>
            </div>
          </div>
        </Modal>
      ) : null}

      {modalMessage.includes('error') ? (
        <Modal open={modalMessage.includes('error')}>
          <div className="w-full flex flex-wrap justify-center">
            <div className="w-full text-center text-lg text-cendiatra">
              <span>{modalMessage}</span>
            </div>
            <div className="w-full flex justify-center mt-5">
              <button
                type="button"
                className="group relative w-24 h-9 -mt-1 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                onClick={() => setModalMessage('')}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal>
      ) : null}

      <Modal open={showErrors && Object.keys(errors).length > 0}>
        <div className="w-full">
          {Object.keys(errors).find((element) => element === 'examType') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.examType && 'No se ha ingresado un tipo de examen'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(2)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'businessName') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.businessName && 'No se ha ingresado la razón social'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(3)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'charge') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.charge && 'No se ha ingresado el Cargo'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(3)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}

          {Object.keys(errors).find((element) => element === 'workTime') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.workTime && 'No se ha ingresado el turno de trabajo'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(3)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'healthState') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.healthState &&
                    'No se ha ingresado el estado de salud'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(4)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'weightKG') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.weightKG && 'No se ha ingresado el peso'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(7)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'heightCM') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.heightCM && 'No se ha ingresado la estatura'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(7)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'IMC') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.IMC && 'No se ha ingresado el IMC'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(7)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'TA_MMG') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.TA_MMG && 'No se ha ingresado el TA (MMG)'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(7)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'FC_MIN') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.FC_MIN && 'No se ha ingresado el FC(MIN) '}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(7)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find(
            (element) => element === 'classification'
          ) ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.classification &&
                    'No se ha ingresado la clasificación '}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(7)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'diagnosis1') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.diagnosis1 &&
                    'No se ha ingresado al menos 1 diagnostico '}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(9)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'concept') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.concept && 'No se ha ingresado el concepto '}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(10)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find(
            (element) => element === 'suspectedIllness'
          ) ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.suspectedIllness &&
                    'No se ha especificado si hay sospecha de enfermedad laboral'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(10)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
          {Object.keys(errors).find((element) => element === 'limitations') ? (
            <div className="w-full flex flex-wrap justify-evenly items-center my-2">
              <div className="w-1/2 flex justify-center">
                <span className="text-red-500">
                  {errors.limitations &&
                    'No se ha especificado si existen limitaciones'}
                </span>
              </div>
              <div className="w-1/2 flex justify-center">
                <button
                  type="button"
                  className="group relative w-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                  onClick={() => goToErrorPage(10)}
                >
                  Ir
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
      <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        <div className="bg-white w-5/6 pb-4 rounded border  border-opacity-50 border-cendiatra shadow-2xl mt-20">
          <div className="bg-white p-4 rounded-lg">
            <div className="w-full flex flex-wrap justify-center my-5">
              <div className="flex items-center"></div>
              {formSection < 6 ? (
                <>
                  {formSection === 1 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            1
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Datos Usuario
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(1)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            1
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Datos Usuario
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              {formSection < 7 ? (
                <>
                  {formSection === 2 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            2
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Exámenes
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(2)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            2
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Exámenes
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              {formSection < 8 ? (
                <>
                  {formSection === 3 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            3
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Datos Empresa
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(3)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            3
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Datos Empresa
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              {formSection < 9 ? (
                <>
                  {formSection === 4 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            4
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Estado de Salud
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(4)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            4
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Estado de Salud
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              {formSection < 10 ? (
                <>
                  {formSection === 5 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            5
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Riesgos
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(5)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            5
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Riesgos
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 5 && formSection < 11 ? (
                <>
                  {formSection === 6 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            6
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Antecedentes
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(6)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            6
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Antecedentes
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 6 && formSection < 12 ? (
                <>
                  {formSection === 7 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            7
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Exámenes Fisicos
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(7)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            7
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Exámenes Fisicos
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 7 && formSection < 13 ? (
                <>
                  {formSection === 8 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            8
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Exámenes Paraclínicos
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(8)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            8
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Exámenes Paraclínicos
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 8 && formSection < 14 ? (
                <>
                  {formSection === 9 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            9
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Diagnóstico
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(9)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            9
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Diagnóstico
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 9 && formSection < 15 ? (
                <>
                  {formSection === 10 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            10
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Concepto de Aptitud
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(10)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            10
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Concepto de Aptitud
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 10 ? (
                <>
                  {formSection === 11 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            11
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Recomendaciones
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(11)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            11
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Recomendaciones
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 11 ? (
                <>
                  {formSection === 12 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            12
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Ingreso Programa Epidemiológico
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(12)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            12
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Ingreso Programa Epidemiológico
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 12 ? (
                <>
                  {formSection === 13 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            13
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Restricciones
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(13)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            13
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Restricciones
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formSection > 13 ? (
                <>
                  {formSection === 14 ? (
                    <div className="w-3/4 md:w-1/6 flex flex-wrap items-center border-b-4 border-cendiatra m-1">
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-cendiatra text-white font-bold m-1">
                            14
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-cendiatra font-semibold ">
                            Historia Manual
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-1/6 hidden md:flex flex-wrap items-center border-b-2 border-gray-400 m-1"
                      onClick={() => goToPage(14)}
                    >
                      <div className="flex justify-center  w-full">
                        <div className="w-3/7 flex justify-center items-center">
                          <p className="w-8 h-8 rounded-full text-center text-lg bg-gray-400 text-white font-bold m-1">
                            14
                          </p>
                        </div>
                        <div className="w-4/7 flex justify-start items-center">
                          <p className="text-md text-center  text-gray-400 font-semibold  ">
                            Historia Manual
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            {formSection === 1 ? (
              <div className="rounded-md -space-y-px flex justify-evenly flex-wrap my-5 bg-dataBg bg-center bg-cover bg-no-repeat">
                <div className="w-full flex justify-center my-5">
                  <span className="text-cendiatra font-bold text-2xl">
                    DATOS USUARIO
                  </span>
                </div>

                <div className="w-full md:w-2/6 flex flex-wrap justify-center">
                  <div className="w-2/4 bg-gray-300 h-44 rounded-3xl flex justify-center items-center">
                    <span className="text-black">Espacio para foto</span>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra -mt-1">
                      Nombre:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.name}{' '}
                      </span>
                    </label>
                  </div>
                </div>

                <div className=" w-full md:w-2/6 flex flex-wrap justify-center md:justify-start">
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra">
                      Fecha:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.date}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra">
                      Lugar de Nacimiento:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.placeOfBirth}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra">
                      Fecha de Nacimiento:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.birthday.slice(0, -8)}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra">
                      Documento:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.documentNumber}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2">
                      Género{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.gender}
                      </span>
                    </label>
                    <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2">
                      Edad{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.age}
                      </span>
                    </label>
                  </div>
                </div>

                <div className=" w-full md:w-2/6 flex flex-wrap justify-center">
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra">
                      EPS:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.eps}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra">
                      ARL:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.arl}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra">
                      Fondo de pensión:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.pensionFund}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start ">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2">
                      Estado Civil:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.maritalState}
                      </span>
                    </label>
                    <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2">
                      Estrato:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.stratum}
                      </span>
                    </label>
                  </div>
                  <div className="w-full flex  items-center my-3 justify-center md:justify-start">
                    <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2">
                      Escolaridad:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.scholarship}
                      </span>
                    </label>
                    <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2 text-center">
                      RH:{' '}
                      <span className="w-full text-center text-md text-black font-light">
                        {ctx.patientAgendaInfo.rh}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            ) : null}
            <form
              className="w-full"
              onSubmit={handleSubmit(submitMedicForm)}
              noValidate
            >
              <div className={formSection === 2 ? 'w-full' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5">
                  <span className="text-cendiatra font-bold text-2xl">
                    EXÁMENES
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/3 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Tipo Examen*
                    </label>
                    <span className="text-red-500 w-full">
                      {errors.examType && 'Por favor seleccione una opción'}
                    </span>
                    <select
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'examType'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      disabled={true}
                      {...register('examType', {required: true})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No refiere
                      </option>
                      <option key="2" value="2">
                        Preingreso
                      </option>
                      <option key="3" value="3">
                        Ingreso
                      </option>
                      <option key="4" value="4">
                        Periodico
                      </option>
                      <option key="5" value="5">
                        Egreso
                      </option>
                      <option key="6" value="6">
                        Post Incapacidad
                      </option>
                      <option key="7" value="7">
                        Evaluación Medica Especial
                      </option>
                      <option key="8" value="8">
                        Por cambio de Ocupación
                      </option>
                      <option key="9" value="9">
                        Reintegro Laboral
                      </option>
                      <option key="10" value="10">
                        Examen Medico Dimar Nacional
                      </option>
                      <option key="11" value="11">
                        Examen Medico Dimar Internacional
                      </option>
                      <option key="12" value="12">
                        Otro
                      </option>
                      <option key="13" value="13">
                        Manipulación de Alimentos
                      </option>
                      <option key="14" value="14">
                        Trabajo en Alturas
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/3 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Nacionalidad
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('nationality', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Colombia
                      </option>
                    </select>
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4 ">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Lugar de Residencia
                    </label>
                    <span className="text-red-500">
                      {errors.placeOfResidence && 'Por favor ingrese un lugar'}
                    </span>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('placeOfResidence', {required: false})}
                    />
                  </div>
                  <div className=" w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Ocupación
                    </label>
                    <span className="text-red-500">
                      {errors.occupation && 'Por favor ingrese los apellidos'}
                    </span>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //autoComplete="email"
                      //   placeholder="Dirección Email"
                      {...register('occupation', {required: false})}
                    />
                  </div>
                  <div className=" w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Tipo de Vinculación
                    </label>
                    <span className="text-red-500">
                      {errors.link && 'Por favor seleccione una fecha'}
                    </span>

                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('link', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Cotizante
                      </option>
                      <option key="2" value="2">
                        Subsidiario
                      </option>
                      <option key="3" value="3">
                        Contributivo
                      </option>
                      <option key="4" value="4">
                        Beneficiario
                      </option>
                      <option key="5" value="5">
                        Otro
                      </option>
                    </select>
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Nombre del Responsable
                    </label>
                    <span className="text-red-500">
                      {errors.responsibleName && 'Por favor ingrese un lugar'}
                    </span>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('responsibleName', {required: false})}
                    />
                  </div>
                  <div className=" w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Teléfono del Responsable
                    </label>
                    <span className="text-red-500">
                      {errors.responsibleName && 'Por favor ingrese el nombre'}
                    </span>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('responsibleName', {required: false})}
                    />
                  </div>
                  <div className=" w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Parentesco
                    </label>
                    <span className="text-red-500">
                      {errors.relationship && 'Por favor seleccione una fecha'}
                    </span>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('relationship', {required: false})}
                    />
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/3 flex justify-start flex flex-wrap">
                    <label className="block w-full text-sm font-medium text-gray-700 text-cendiatra">
                      Raza
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('race', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="Blanco o Caucasico">
                        Blanco o Caucasico
                      </option>
                      <option key="2" value="Hispano Americano">
                        Hispano Americano
                      </option>
                      <option key="3" value="Asiatico">
                        Asiatico
                      </option>
                      <option key="4" value="Indígena">
                        Indígena
                      </option>
                      <option key="5" value="Rom-Gitano">
                        Rom-Gitano
                      </option>
                      <option key="6" value="Raizal">
                        Raizal
                      </option>
                      <option key="7" value="Negro">
                        Negro
                      </option>
                      <option key="8" value="No Aplica">
                        No Aplica
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/3 flex justify-start flex flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Número de Hijos
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="number"
                      min="0"
                      {...register('childrenNumber', {required: false})}
                    />
                  </div>
                </div>
              </div>

              <div className={formSection === 3 ? 'w-full' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5">
                  <span className="text-cendiatra font-bold text-2xl">
                    DATOS EMPRESA
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Razón Social*
                    </label>
                    <span className="text-red-500 w-full">
                      {errors.businessName && 'Por favor ingrese un nombre'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'businessName'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      disabled={true}
                      type="text"
                      {...register('businessName', {required: true})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Actividad Económica
                    </label>
                    <span className="text-red-500">
                      {errors.economicActivity && 'Por favor ingrese un lugar'}
                    </span>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="text"
                      {...register('economicActivity', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Fecha de Brigada
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type={changeFieldType ? 'date' : 'text'}
                      onFocus={() => setChangeFieldType(true)}
                      {...register('brigadeDate', {required: false})}
                    />
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block w-full text-sm font-medium text-gray-700 text-cendiatra">
                      Cargo*
                    </label>
                    <span className="text-red-500">
                      {errors.charge && 'Por favor ingrese el cargo'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'charge'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="text"
                      disabled={true}
                      {...register('charge', {required: true})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Área de Trabajo
                    </label>
                    <span className="text-red-500">
                      {errors.workArea && 'Por favor ingrese un lugar'}
                    </span>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="text"
                      {...register('workArea', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Turno de Trabajo*
                    </label>
                    <span className="text-red-500 w-full">
                      {errors.workTime && 'Por favor ingrese un lugar'}
                    </span>
                    <select
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'workTime'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      {...register('workTime', {required: true})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Diurno
                      </option>
                      <option key="2" value="2">
                        Nocturno
                      </option>
                      <option key="3" value="3">
                        Mixto
                      </option>
                    </select>
                  </div>
                </div>
                <div className="w-full flex justify-center mt-20 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    INFORMACIÓN OCUPACIONAL DEL CARGO
                  </span>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Tiempo en el Cargo (Años)
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="number"
                      min="1"
                      {...register('ageTime', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Tiempo en el Cargo (Meses)
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="number"
                      min="1"
                      {...register('monthTime', {required: false})}
                    />
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Maquinaria, Equipos Utilizados y Herramientas
                    </label>
                    <textarea
                      rows="6"
                      cols="50"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="textbox"
                      min="1"
                      {...register('equipment', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Descripción de Funciones
                    </label>
                    <textarea
                      rows="6"
                      cols="50"
                      className="appearance-none rounded-none h-full relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      min="1"
                      {...register('funtions', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Materia Prima Utilizada
                    </label>
                    <textarea
                      rows="6"
                      cols="50"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="textbox"
                      min="1"
                      {...register('rawMaterial', {required: false})}
                    />
                  </div>
                </div>
              </div>
              <div className={formSection === 4 ? 'w-full' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5">
                  <span className="text-cendiatra font-bold text-2xl">
                    ESTADO DE SALUD ACTUAL
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full flex justify-start mx-5 flex-wrap">
                    {/* <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                                            Este campo es obligatorio*
                                        </label> */}
                    <span className="text-red-500 w-full">
                      {errors.healthState &&
                        'Por favor ingrese el estado de salud el paciente'}
                    </span>
                    <textarea
                      rows="6"
                      cols="50"
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'healthState'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="textbox"
                      placeholder="Este campo es obligatorio*"
                      min="1"
                      {...register('healthState', {required: true})}
                    />
                  </div>
                </div>
              </div>

              <div className={formSection === 5 ? 'w-full' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5">
                  <span className="text-cendiatra font-bold text-2xl">
                    RIESGOS OCUPACIONALES EN EMPRESAS ANTERIORES
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center my-14">
                  <div className="w-full md:w-1/4 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Accidente Laboral</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !workAccident
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('accidentAtWork', {required: false})}
                        onClick={() =>
                          changeState(setWorkAccident, workAccident)
                        }
                        onMouseEnter={() => setWorkAccidentObs(true)}
                        onMouseLeave={() => setWorkAccidentObs(false)}
                      />
                      <label
                        className={
                          !workAccident
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setWorkAccident, workAccident)
                        }
                      ></label>
                      <div
                        className={
                          workAccident && workAccidentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setWorkAccidentObs(false)}
                            onMouseEnter={() => setWorkAccidentObs(true)}
                            {...register('accidentAtWorkObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/4 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Antecedentes Ocupacionales</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !workPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('precedentAtWork', {required: false})}
                        onMouseLeave={() => setWorkPrecedentObs(false)}
                        onMouseEnter={() => setWorkPrecedentObs(true)}
                        onClick={() =>
                          changeState(setWorkPrecedent, workPrecedent)
                        }
                      />
                      <label
                        className={
                          !workPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setWorkPrecedent, workPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          workPrecedent && workPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setWorkPrecedentObs(false)}
                            onMouseEnter={() => setWorkPrecedentObs(true)}
                            {...register('precedentAtWorkObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/4 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Enfermedad Laboral</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !workIllness
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('illnessForWork', {required: false})}
                        onMouseLeave={() => setWorkIllnessObs(false)}
                        onMouseEnter={() => setWorkIllnessObs(true)}
                        onClick={() => changeState(setWorkIllness, workIllness)}
                      />
                      <label
                        className={
                          !workIllness
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() => changeState(setWorkIllness, workIllness)}
                      ></label>
                      <div
                        className={
                          workIllness && workIllnessObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setWorkIllnessObs(false)}
                            onMouseEnter={() => setWorkIllnessObs(true)}
                            {...register('illnessForWorkObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/4 flex justify-center">
                    <button
                      className="hover:opacity-80 items-center group relative w-24 h-9 flex justify-center py-2 px-4 border border-transparent text-xs font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                      type="button"
                    >
                      {' '}
                      Antecedentes Ocupacionales
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={
                  formSection === 6
                    ? 'w-full bg-precedentMainBg bg-top bg-contain bg-no-repeat'
                    : 'w-full hidden'
                }
              >
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    ANTECEDENTES PERSONALES
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center my-14 ">
                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Quirúrgicos</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !surgicalPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousSurgical', {required: false})}
                        onMouseLeave={() => setSurgicalPrecedentObs(false)}
                        onMouseEnter={() => setSurgicalPrecedentObs(true)}
                        onClick={() =>
                          changeState(setSurgicalPrecedent, surgicalPrecedent)
                        }
                      />
                      <label
                        className={
                          !surgicalPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setSurgicalPrecedent, surgicalPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          surgicalPrecedent && surgicalPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setSurgicalPrecedentObs(false)}
                            onMouseEnter={() => setSurgicalPrecedentObs(true)}
                            {...register('previousSurgicalObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Alergias</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !allergyPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        onMouseLeave={() => setAllergyPrecedentObs(false)}
                        onMouseEnter={() => setAllergyPrecedentObs(true)}
                        {...register('previousAllergy', {required: false})}
                        onClick={() =>
                          changeState(setAllergyPrecedent, allergyPrecedent)
                        }
                      />
                      <label
                        className={
                          !allergyPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setAllergyPrecedent, allergyPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          allergyPrecedent && allergyPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setAllergyPrecedentObs(false)}
                            onMouseEnter={() => setAllergyPrecedentObs(true)}
                            {...register('previousAllergyObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Ante. Familiares</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !familyPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        onMouseLeave={() => setFamilyPrecedentObs(false)}
                        onMouseEnter={() => setFamilyPrecedentObs(true)}
                        onClick={() =>
                          changeState(setFamilyPrecedent, familyPrecedent)
                        }
                        {...register('previousFamily', {required: false})}
                      />
                      <label
                        className={
                          !familyPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setFamilyPrecedent, familyPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          familyPrecedent && familyPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setFamilyPrecedentObs(false)}
                            onMouseEnter={() => setFamilyPrecedentObs(true)}
                            {...register('previousFamilyObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Hernias</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !herniaPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousHernia', {required: false})}
                        onMouseLeave={() => setHerniaPrecedentObs(false)}
                        onMouseEnter={() => setHerniaPrecedentObs(true)}
                        onClick={() =>
                          changeState(setHerniaPrecedent, herniaPrecedent)
                        }
                      />
                      <label
                        className={
                          !herniaPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setHerniaPrecedent, herniaPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          herniaPrecedent && herniaPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setHerniaPrecedentObs(false)}
                            onMouseEnter={() => setHerniaPrecedentObs(true)}
                            {...register('previousHerniaObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Trast. Auditivos</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !auditoryPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousAuditory', {required: false})}
                        onMouseLeave={() => setAuditoryPrecedentObs(false)}
                        onMouseEnter={() => setAuditoryPrecedentObs(true)}
                        onClick={() =>
                          changeState(setAuditoryPrecedent, auditoryPrecedent)
                        }
                      />
                      <label
                        className={
                          !auditoryPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setAuditoryPrecedent, auditoryPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          auditoryPrecedent && auditoryPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setAuditoryPrecedentObs(false)}
                            onMouseEnter={() => setAuditoryPrecedentObs(true)}
                            {...register('previousAuditoryObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Diabetes</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !diabetesPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousDiabetes', {required: false})}
                        onMouseLeave={() => setDiabetesPrecedentObs(false)}
                        onMouseEnter={() => setDiabetesPrecedentObs(true)}
                        onClick={() =>
                          changeState(setDiabetesPrecedent, diabetesPrecedent)
                        }
                      />
                      <label
                        className={
                          !diabetesPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setDiabetesPrecedent, diabetesPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          diabetesPrecedent && diabetesPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setDiabetesPrecedentObs(false)}
                            onMouseEnter={() => setDiabetesPrecedentObs(true)}
                            {...register('previousDiabetesObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center my-14">
                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Patológicos</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !pathologicalPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousPathology', {required: false})}
                        onMouseLeave={() => setPathologicalPrecedentObs(false)}
                        onMouseEnter={() => setPathologicalPrecedentObs(true)}
                        onClick={() =>
                          changeState(
                            setPathologicalPrecedent,
                            pathologicalPrecedent
                          )
                        }
                      />
                      <label
                        className={
                          !pathologicalPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(
                            setPathologicalPrecedent,
                            pathologicalPrecedent
                          )
                        }
                      ></label>
                      <div
                        className={
                          pathologicalPrecedent && pathologicalPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() =>
                              setPathologicalPrecedentObs(false)
                            }
                            onMouseEnter={() =>
                              setPathologicalPrecedentObs(true)
                            }
                            {...register('previousPathologyObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Epilepsias</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !epilepsyPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousEpilepsy', {required: false})}
                        onMouseLeave={() => setEpilepsyPrecedentObs(false)}
                        onMouseEnter={() => setEpilepsyPrecedentObs(true)}
                        onClick={() =>
                          changeState(setEpilepsyPrecedent, epilepsyPrecedent)
                        }
                      />
                      <label
                        className={
                          !epilepsyPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setEpilepsyPrecedent, epilepsyPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          epilepsyPrecedent && epilepsyPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setEpilepsyPrecedentObs(false)}
                            onMouseEnter={() => setEpilepsyPrecedentObs(true)}
                            {...register('previousEpilepsyObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Gastritis</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !gastritisPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousGastritis', {required: false})}
                        onMouseLeave={() => setGastritisPrecedentObs(false)}
                        onMouseEnter={() => setGastritisPrecedentObs(true)}
                        onClick={() =>
                          changeState(setGastritisPrecedent, gastritisPrecedent)
                        }
                      />
                      <label
                        className={
                          !gastritisPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setGastritisPrecedent, gastritisPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          gastritisPrecedent && gastritisPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setGastritisPrecedentObs(false)}
                            onMouseEnter={() => setGastritisPrecedentObs(true)}
                            {...register('previousGastritisObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Traumáticos</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !traumaticPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousTraumatic', {required: false})}
                        onMouseLeave={() => setTraumaticPrecedentObs(false)}
                        onMouseEnter={() => setTraumaticPrecedentObs(true)}
                        onClick={() =>
                          changeState(setTraumaticPrecedent, traumaticPrecedent)
                        }
                      />
                      <label
                        className={
                          !traumaticPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setTraumaticPrecedent, traumaticPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          traumaticPrecedent && traumaticPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setTraumaticPrecedentObs(false)}
                            onMouseEnter={() => setTraumaticPrecedentObs(true)}
                            {...register('previousTraumaticObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Tunel Carpiano</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !carpalTunnelPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousCarpalTunnel', {required: false})}
                        onMouseLeave={() => setCarpalTunnelPrecedentObs(false)}
                        onMouseEnter={() => setCarpalTunnelPrecedentObs(true)}
                        onClick={() =>
                          changeState(
                            setCarpalTunnelPrecedent,
                            carpalTunnelPrecedent
                          )
                        }
                      />
                      <label
                        className={
                          !carpalTunnelPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(
                            setCarpalTunnelPrecedent,
                            carpalTunnelPrecedent
                          )
                        }
                      ></label>
                      <div
                        className={
                          carpalTunnelPrecedent && carpalTunnelPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() =>
                              setCarpalTunnelPrecedentObs(false)
                            }
                            onMouseEnter={() =>
                              setCarpalTunnelPrecedentObs(true)
                            }
                            {...register('previousCarpalTunnelObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap ">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Várices</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !varicosePrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousVaricose', {required: false})}
                        onMouseLeave={() => setVaricosePrecedentObs(false)}
                        onMouseEnter={() => setVaricosePrecedentObs(true)}
                        onClick={() =>
                          changeState(setVaricosePrecedent, varicosePrecedent)
                        }
                      />
                      <label
                        className={
                          !varicosePrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setVaricosePrecedent, varicosePrecedent)
                        }
                      ></label>
                      <div
                        className={
                          varicosePrecedent && varicosePrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setVaricosePrecedentObs(false)}
                            onMouseEnter={() => setVaricosePrecedentObs(true)}
                            {...register('previousVaricoseObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center my-14 ">
                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Lumbago</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !lumbagoPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousLumbago', {required: false})}
                        onMouseLeave={() => setLumbagoPrecedentObs(false)}
                        onMouseEnter={() => setLumbagoPrecedentObs(true)}
                        onClick={() =>
                          changeState(setLumbagoPrecedent, lumbagoPrecedent)
                        }
                      />
                      <label
                        className={
                          !lumbagoPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setLumbagoPrecedent, lumbagoPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          lumbagoPrecedent && lumbagoPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setLumbagoPrecedentObs(false)}
                            onMouseEnter={() => setLumbagoPrecedentObs(true)}
                            {...register('previousLumbagoObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Trastornos Mentales</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !mentalPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousMental', {required: false})}
                        onMouseLeave={() => setMentalPrecedentObs(false)}
                        onMouseEnter={() => setMentalPrecedentObs(true)}
                        onClick={() =>
                          changeState(setMentalPrecedent, mentalPrecedent)
                        }
                      />
                      <label
                        className={
                          !mentalPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setMentalPrecedent, mentalPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          mentalPrecedent && mentalPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setMentalPrecedentObs(false)}
                            onMouseEnter={() => setMentalPrecedentObs(true)}
                            {...register('previousMentaObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Hipertensión</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !hypertensionPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousHypertension', {required: false})}
                        onMouseLeave={() => setHypertensionPrecedentObs(false)}
                        onMouseEnter={() => setHypertensionPrecedentObs(true)}
                        onClick={() =>
                          changeState(
                            setHypertensionPrecedent,
                            hypertensionPrecedent
                          )
                        }
                      />
                      <label
                        className={
                          !hypertensionPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(
                            setHypertensionPrecedent,
                            hypertensionPrecedent
                          )
                        }
                      ></label>
                      <div
                        className={
                          hypertensionPrecedent && hypertensionPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() =>
                              setHypertensionPrecedentObs(false)
                            }
                            onMouseEnter={() =>
                              setHypertensionPrecedentObs(true)
                            }
                            {...register('previousHypertensionObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Trastornos Visuales</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !visualPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousVisual', {required: false})}
                        onMouseLeave={() => setVisualPrecedentObs(false)}
                        onMouseEnter={() => setVisualPrecedentObs(true)}
                        onClick={() =>
                          changeState(setVisualPrecedent, visualPrecedent)
                        }
                      />
                      <label
                        className={
                          !visualPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setVisualPrecedent, visualPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          visualPrecedent && visualPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setVisualPrecedentObs(false)}
                            onMouseEnter={() => setVisualPrecedentObs(true)}
                            {...register('previousVisualObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap">
                    <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                      <span>Lesión Piel</span>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        className={
                          !skinPrecedent
                            ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                            : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                        }
                        {...register('previousSkin', {required: false})}
                        onMouseLeave={() => setSkinPrecedentObs(false)}
                        onMouseEnter={() => setSkinPrecedentObs(true)}
                        onClick={() =>
                          changeState(setSkinPrecedent, skinPrecedent)
                        }
                      />
                      <label
                        className={
                          !skinPrecedent
                            ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                            : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                        }
                        onClick={() =>
                          changeState(setSkinPrecedent, skinPrecedent)
                        }
                      ></label>
                      <div
                        className={
                          skinPrecedent && skinPrecedentObs
                            ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setSkinPrecedentObs(false)}
                            onMouseEnter={() => setSkinPrecedentObs(true)}
                            {...register('previousSkinObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/6 flex justify-center flex-wrap"></div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center my-14 border-t-2 border-gray-300">
                  <div className="w-full md:w-1/2 flex justify-center flex-wrap border-r-2 border-gray-300 pr-2 bg-precedentSmallBg bg-center bg-cover">
                    <div className="w-full flex justify-center my-5">
                      <span className="text-cendiatra font-bold text-2xl -mt-4">
                        ANTECEDENTES GINECOBSTETRICOS
                      </span>
                    </div>

                    <div className="w-full md:w-1/3 flex justify-center flex-wrap ">
                      <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                        <span>Gestaciones</span>
                      </div>
                      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          className={
                            !gestationPrecedent
                              ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                              : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                          }
                          {...register('previousGestation', {required: false})}
                          onMouseLeave={() => setGestationPrecedentObs(false)}
                          onMouseEnter={() => setGestationPrecedentObs(true)}
                          onClick={() =>
                            changeState(
                              setGestationPrecedent,
                              gestationPrecedent
                            )
                          }
                        />
                        <label
                          className={
                            !gestationPrecedent
                              ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                              : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                          }
                          onClick={() =>
                            changeState(
                              setGestationPrecedent,
                              gestationPrecedent
                            )
                          }
                        ></label>
                        <div
                          className={
                            gestationPrecedent && gestationPrecedentObs
                              ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                              : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                          }
                        >
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                              placeholder="Observación"
                              type="textbox"
                              onMouseLeave={() =>
                                setGestationPrecedentObs(false)
                              }
                              onMouseEnter={() =>
                                setGestationPrecedentObs(true)
                              }
                              {...register('previousGestationObservation', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/3 flex justify-center flex-wrap">
                      <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                        <span>Abortos</span>
                      </div>
                      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          className={
                            !abortionPrecedent
                              ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                              : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                          }
                          {...register('previousAbortion', {required: false})}
                          onClick={() =>
                            changeState(setAbortionPrecedent, abortionPrecedent)
                          }
                          onMouseLeave={() => setAbortionPrecedentObs(false)}
                          onMouseEnter={() => setAbortionPrecedentObs(true)}
                        />
                        <label
                          className={
                            !abortionPrecedent
                              ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                              : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                          }
                          onClick={() =>
                            changeState(setAbortionPrecedent, abortionPrecedent)
                          }
                        ></label>
                        <div
                          className={
                            abortionPrecedent && abortionPrecedentObs
                              ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                              : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                          }
                        >
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                              placeholder="Observación"
                              type="textbox"
                              onMouseLeave={() =>
                                setAbortionPrecedentObs(false)
                              }
                              onMouseEnter={() => setAbortionPrecedentObs(true)}
                              {...register('previousAbortionObservation', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/3 flex justify-center flex-wrap">
                      <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                        <span>FUM</span>
                      </div>
                      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          className={
                            !fumPrecedent
                              ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                              : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                          }
                          {...register('previousFUM', {required: false})}
                          onMouseLeave={() => setFumPrecedentObs(false)}
                          onMouseEnter={() => setFumPrecedentObs(true)}
                          onClick={() =>
                            changeState(setFumPrecedent, fumPrecedent)
                          }
                        />
                        <label
                          className={
                            !fumPrecedent
                              ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                              : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                          }
                          onClick={() =>
                            changeState(setFumPrecedent, fumPrecedent)
                          }
                        ></label>
                        <div
                          className={
                            fumPrecedent && fumPrecedentObs
                              ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                              : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                          }
                        >
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                              placeholder="Observación"
                              type="textbox"
                              onMouseLeave={() => setFumPrecedentObs(false)}
                              onMouseEnter={() => setFumPrecedentObs(true)}
                              {...register('previousFUMObservation', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-center items-center">
                      <div className="w-full md:w-1/3 flex justify-center flex-wrap">
                        <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                          <span>Parto</span>
                        </div>
                        <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            className={
                              !laborPrecedent
                                ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                                : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                            }
                            {...register('previousLabor', {required: false})}
                            onMouseLeave={() => setLaborPrecedentObs(false)}
                            onMouseEnter={() => setLaborPrecedentObs(true)}
                            onClick={() =>
                              changeState(setLaborPrecedent, laborPrecedent)
                            }
                          />
                          <label
                            className={
                              !laborPrecedent
                                ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                                : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                            }
                            onClick={() =>
                              changeState(setLaborPrecedent, laborPrecedent)
                            }
                          ></label>
                          <div
                            className={
                              laborPrecedent && laborPrecedentObs
                                ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                                : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                            }
                          >
                            <div>
                              <textarea
                                rows="4"
                                cols="50"
                                className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                                placeholder="Observación"
                                type="textbox"
                                onMouseLeave={() => setLaborPrecedentObs(false)}
                                onMouseEnter={() => setLaborPrecedentObs(true)}
                                {...register('previousLaborObservation', {
                                  required: false,
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/3 flex justify-start flex-wrap">
                        <label className=" mt-10 block text-sm font-medium text-gray-700 text-cendiatra">
                          Ultimo Parto en años
                        </label>
                        <input
                          className="appearance-none  rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                          type="number"
                          min="0"
                          {...register('lastLabor', {required: false})}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 flex justify-center flex-wrap bg-precedentSmallBg bg-center bg-cover">
                    <div className="w-full flex justify-center my-5">
                      <span className="text-cendiatra font-bold text-2xl">
                        ANTECEDENTES TOXICOLÓGICOS
                      </span>
                    </div>

                    <div className="w-full md:w-1/4 flex justify-center flex-wrap">
                      <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                        <span>Consumo de Tabaco</span>
                      </div>
                      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          className={
                            !tobaccoPrecedent
                              ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                              : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                          }
                          {...register('previousTobacco', {required: false})}
                          onMouseLeave={() => setTobaccoPrecedentObs(false)}
                          onMouseEnter={() => setTobaccoPrecedentObs(true)}
                          onClick={() =>
                            changeState(setTobaccoPrecedent, tobaccoPrecedent)
                          }
                        />
                        <label
                          className={
                            !tobaccoPrecedent
                              ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                              : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                          }
                          onClick={() =>
                            changeState(setTobaccoPrecedent, tobaccoPrecedent)
                          }
                        ></label>
                        <div
                          className={
                            tobaccoPrecedent && tobaccoPrecedentObs
                              ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                              : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                          }
                        >
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                              placeholder="Observación"
                              type="textbox"
                              onMouseLeave={() => setTobaccoPrecedentObs(false)}
                              onMouseEnter={() => setTobaccoPrecedentObs(true)}
                              {...register('previousTobaccoObservation', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/4 flex justify-center flex-wrap">
                      <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                        <span>Consumo de Licor</span>
                      </div>
                      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          className={
                            !alcoholPrecedent
                              ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                              : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                          }
                          {...register('previousAlcohol', {required: false})}
                          onMouseLeave={() => setAlcoholPrecedentObs(false)}
                          onMouseEnter={() => setAlcoholPrecedentObs(true)}
                          onClick={() =>
                            changeState(setAlcoholPrecedent, alcoholPrecedent)
                          }
                        />
                        <label
                          className={
                            !alcoholPrecedent
                              ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                              : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                          }
                          onClick={() =>
                            changeState(setAlcoholPrecedent, alcoholPrecedent)
                          }
                        ></label>
                        <div
                          className={
                            alcoholPrecedent && alcoholPrecedentObs
                              ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                              : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                          }
                        >
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                              placeholder="Observación"
                              type="textbox"
                              onMouseLeave={() => setAlcoholPrecedentObs(false)}
                              onMouseEnter={() => setAlcoholPrecedentObs(true)}
                              {...register('previousAlcoholObservation', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/4 flex justify-center flex-wrap">
                      <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                        <span>Sustancias Psicoactivas</span>
                      </div>
                      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          className={
                            !psychoactivePrecedent
                              ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                              : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                          }
                          {...register('previousPsychoactive', {
                            required: false,
                          })}
                          onMouseLeave={() =>
                            setPsychoactivePrecedentObs(false)
                          }
                          onMouseEnter={() => setPsychoactivePrecedentObs(true)}
                          onClick={() =>
                            changeState(
                              setPsychoactivePrecedent,
                              psychoactivePrecedent
                            )
                          }
                        />
                        <label
                          className={
                            !psychoactivePrecedent
                              ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                              : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                          }
                          onClick={() =>
                            changeState(
                              setPsychoactivePrecedent,
                              psychoactivePrecedent
                            )
                          }
                        ></label>
                        <div
                          className={
                            psychoactivePrecedent && psychoactivePrecedentObs
                              ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                              : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                          }
                        >
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                              placeholder="Observación"
                              type="textbox"
                              onMouseLeave={() =>
                                setPsychoactivePrecedentObs(false)
                              }
                              onMouseEnter={() =>
                                setPsychoactivePrecedentObs(true)
                              }
                              {...register('previousPsychoactiveObservation', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-1/4 flex justify-center flex-wrap">
                      <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                        <span>Medicamentos Cronicos</span>
                      </div>
                      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          className={
                            !medicinePrecedent
                              ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                              : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                          }
                          {...register('previousMedicine', {required: false})}
                          onMouseLeave={() => setMedicinePrecedentObs(false)}
                          onMouseEnter={() => setMedicinePrecedentObs(true)}
                          onClick={() =>
                            changeState(setMedicinePrecedent, medicinePrecedent)
                          }
                        />
                        <label
                          className={
                            !medicinePrecedent
                              ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                              : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                          }
                          onClick={() =>
                            changeState(setMedicinePrecedent, medicinePrecedent)
                          }
                        ></label>
                        <div
                          className={
                            medicinePrecedent && medicinePrecedentObs
                              ? 'text-sm bg-white -ml-14 mt-2 w-40 text-gray-100  absolute rounded  shadow-xl z-50'
                              : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                          }
                        >
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                              placeholder="Observación"
                              type="textbox"
                              onMouseLeave={() =>
                                setMedicinePrecedentObs(false)
                              }
                              onMouseEnter={() => setMedicinePrecedentObs(true)}
                              {...register('previousMedicineObservation', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-4/5 mt-4">
                      <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                        Observaciones
                      </label>
                      <textarea
                        rows="3"
                        cols="2"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="textbox"
                        min="1"
                        {...register('toxicologicalObservation', {
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={formSection === 7 ? 'w-full ' : 'w-full hidden'}>
                <div className=" bg-vitalSignsBg bg-cover bg-center">
                  <div className="w-full flex justify-center my-5 ">
                    <span className="text-cendiatra font-bold text-2xl">
                      SIGNOS VITALES
                    </span>
                  </div>

                  <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                    <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                      <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                        Peso (KG)*
                      </label>
                      <span className="text-red-500 w-full">
                        {errors.weightKG && 'Por favor ingrese el peso'}
                      </span>

                      <input
                        className={
                          Object.keys(errors).find(
                            (element) => element === 'weightKG'
                          )
                            ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                            : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                        }
                        type="number"
                        {...register('weightKG', {required: true})}
                        onChange={(e) =>
                          changeWeightOrHeight(e, setPatientWeight, 'weightKG')
                        }
                        value={patientWeight}
                      />
                    </div>

                    <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                      <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                        Talla (CM)*
                      </label>
                      <span className="text-red-500 w-full">
                        {errors.heightCM && 'Por favor ingrese la estatura'}
                      </span>

                      <input
                        className={
                          Object.keys(errors).find(
                            (element) => element === 'heightCM'
                          )
                            ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                            : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                        }
                        type="number"
                        min="0"
                        {...register('heightCM', {required: true})}
                        onChange={(e) =>
                          changeWeightOrHeight(e, setPatientHeight, 'heightCM')
                        }
                        value={patientHeight}
                      />
                    </div>

                    <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                      <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                        IMC (M2)*
                      </label>
                      <span className="text-red-500 w-full">
                        {errors.IMC && 'Por favor ingrese el IMC'}
                      </span>

                      <input
                        className={
                          Object.keys(errors).find(
                            (element) => element === 'IMC'
                          )
                            ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                            : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                        }
                        type="number"
                        min="0"
                        disabled={true}
                        value={IMC}
                        {...register('IMC', {required: true})}
                      />
                    </div>
                  </div>

                  <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                    <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                      <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                        TA (MMG)*
                      </label>
                      <span className="text-red-500 w-full">
                        {errors.TA_MMG && 'Por favor ingrese el MMG'}
                      </span>

                      <input
                        className={
                          Object.keys(errors).find(
                            (element) => element === 'TA_MMG'
                          )
                            ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                            : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                        }
                        type="number"
                        min="0"
                        {...register('TA_MMG', {required: true})}
                      />
                    </div>

                    <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                      <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                        FC (MIN)*
                      </label>
                      <span className="text-red-500 w-full">
                        {errors.FC_MIN && 'Por favor ingrese el FC'}
                      </span>

                      <input
                        className={
                          Object.keys(errors).find(
                            (element) => element === 'FC_MIN'
                          )
                            ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                            : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                        }
                        type="number"
                        min="0"
                        {...register('FC_MIN', {required: true})}
                      />
                    </div>

                    <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                      <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                        Clasificación*
                      </label>
                      <span className="text-red-500 w-full">
                        {errors.classification &&
                          'Por favor ingrese la Clasificación'}
                      </span>

                      <input
                        className={
                          Object.keys(errors).find(
                            (element) => element === 'classification'
                          )
                            ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                            : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                        }
                        type="text"
                        disabled={true}
                        {...register('classification', {required: true})}
                      />
                    </div>
                  </div>
                  <div className="rounded-md -space-y-px flex justify-start flex-wrap items-center  px-16 border-b-2 border-gray-300">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Descripción Hallazgos Encontrados
                    </label>

                    <textarea
                      rows="6"
                      cols="50"
                      className="mb-10 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="textbox"
                      {...register('discovery', {required: false})}
                    />
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                  <div className="w-full flex justify-center my-5 ">
                    <span className="text-cendiatra font-bold text-2xl">
                      ÓRGANO O SISTEMA
                    </span>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        headNeckObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setHeadNeckObservation(false)}
                          onMouseEnter={() => setHeadNeckObservation(true)}
                          {...register('head_neckObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setHeadNeckObservation(false)}
                      onMouseEnter={() => setHeadNeckObservation(true)}
                    >
                      Cabeza y Cuello
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"

                      {...register('head_neck', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        eyesObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setEyesObservation(false)}
                          onMouseEnter={() => setEyesObservation(true)}
                          {...register('eyesObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setEyesObservation(false)}
                      onMouseEnter={() => setEyesObservation(true)}
                    >
                      Ojos
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('eyes', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        closeVisionRightEyeObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setCloseVisionRightEyeObservation(false)
                          }
                          onMouseEnter={() =>
                            setCloseVisionRightEyeObservation(true)
                          }
                          {...register('closeVisionRightEyeObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() =>
                        setCloseVisionRightEyeObservation(false)
                      }
                      onMouseEnter={() =>
                        setCloseVisionRightEyeObservation(true)
                      }
                    >
                      Visión Cercana OD
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('closeVisionRightEye', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        distantVisionRightEyeObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setDistantVisionRightEyeObservation(false)
                          }
                          onMouseEnter={() =>
                            setDistantVisionRightEyeObservation(true)
                          }
                          {...register('distantVisionRightEyeObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() =>
                        setDistantVisionRightEyeObservation(false)
                      }
                      onMouseEnter={() =>
                        setDistantVisionRightEyeObservation(true)
                      }
                    >
                      Visión Lejana OD
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('distantVisionRightEye', {required: false})}
                    />
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        closeVisionLefttEyeObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setCloseVisionLefttEyeObservation(false)
                          }
                          onMouseEnter={() =>
                            setCloseVisionLefttEyeObservation(true)
                          }
                          {...register('closeVisionLefttEyeObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() =>
                        setCloseVisionLefttEyeObservation(false)
                      }
                      onMouseEnter={() =>
                        setCloseVisionLefttEyeObservation(true)
                      }
                    >
                      Visión Cercana OI
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('closeVisionLefttEye', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        distantVisionLeftEyeObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setDistantVisionLeftEyeObservation(false)
                          }
                          onMouseEnter={() =>
                            setDistantVisionLeftEyeObservation(true)
                          }
                          {...register('distantVisionLeftEyeObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() =>
                        setDistantVisionLeftEyeObservation(false)
                      }
                      onMouseEnter={() =>
                        setDistantVisionLeftEyeObservation(true)
                      }
                    >
                      Visión Lejana OI
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //autoComplete="email"
                      //   placeholder="Dirección Email"
                      {...register('distantVisionLeftEye', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        noseObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setNoseObservation(false)}
                          onMouseEnter={() => setNoseObservation(true)}
                          {...register('noseObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setNoseObservation(false)}
                      onMouseEnter={() => setNoseObservation(true)}
                    >
                      Nariz
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('nose', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        earsObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setEarsObservation(false)}
                          onMouseEnter={() => setEarsObservation(true)}
                          {...register('earsObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setEarsObservation(false)}
                      onMouseEnter={() => setEarsObservation(true)}
                    >
                      Oídos
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('ears', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        pharynxObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setPharynxObservation(false)}
                          onMouseEnter={() => setPharynxObservation(true)}
                          {...register('pharynxObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setPharynxObservation(false)}
                      onMouseEnter={() => setPharynxObservation(true)}
                    >
                      Orofaringe
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('pharynx', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        thoraxObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setThoraxObservation(false)}
                          onMouseEnter={() => setThoraxObservation(true)}
                          {...register('thoraxObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setThoraxObservation(false)}
                      onMouseEnter={() => setThoraxObservation(true)}
                    >
                      Tórax
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('thorax', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        cardioObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setCardioObservation(false)}
                          onMouseEnter={() => setCardioObservation(true)}
                          {...register('cardioObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setCardioObservation(false)}
                      onMouseEnter={() => setCardioObservation(true)}
                    >
                      Cardio Respiratorio
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('cardio', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        abdomenObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setAbdomenObservation(false)}
                          onMouseEnter={() => setAbdomenObservation(true)}
                          {...register('abdomenObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setAbdomenObservation(false)}
                      onMouseEnter={() => setAbdomenObservation(true)}
                    >
                      Abdomen
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('abdomen', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        spineObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setSpineObservation(false)}
                          onMouseEnter={() => setSpineObservation(true)}
                          {...register('spineObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setSpineObservation(false)}
                      onMouseEnter={() => setSpineObservation(true)}
                    >
                      Columna Vertebral
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('spine', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        backObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setBackObservation(false)}
                          onMouseEnter={() => setBackObservation(true)}
                          {...register('backObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setBackObservation(false)}
                      onMouseEnter={() => setBackObservation(true)}
                    >
                      Dorso
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('back', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-center flex-wrap">
                    <div
                      className={
                        skinObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setSkinObservation(false)}
                          onMouseEnter={() => setSkinObservation(true)}
                          {...register('skinObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setSkinObservation(false)}
                      onMouseEnter={() => setSkinObservation(true)}
                    >
                      Piel Anexos
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('skin', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        nervous_systemObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setNervous_systemObservation(false)
                          }
                          onMouseEnter={() =>
                            setNervous_systemObservation(true)
                          }
                          {...register('nervous_systemObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setNervous_systemObservation(false)}
                      onMouseEnter={() => setNervous_systemObservation(true)}
                    >
                      Sistema Nervioso
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('nervous_system', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <div
                      className={
                        limbsObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setLimbsObservation(false)}
                          onMouseEnter={() => setLimbsObservation(true)}
                          {...register('limbsObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setLimbsObservation(false)}
                      onMouseEnter={() => setLimbsObservation(true)}
                    >
                      Extremidades
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('limbs', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <div
                      className={
                        varicoseObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setVaricoseObservation(false)}
                          onMouseEnter={() => setVaricoseObservation(true)}
                          {...register('varicoseObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setVaricoseObservation(false)}
                      onMouseEnter={() => setVaricoseObservation(true)}
                    >
                      Várices
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('varicose', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <div
                      className={
                        genitalsObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setGenitalsObservation(false)}
                          onMouseEnter={() => setGenitalsObservation(true)}
                          {...register('genitalsObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setGenitalsObservation(false)}
                      onMouseEnter={() => setGenitalsObservation(true)}
                    >
                      Genitourinario
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('genitals', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Normal
                      </option>
                      <option key="2" value="2">
                        Anormal
                      </option>
                      <option key="3" value="3">
                        Nse
                      </option>
                    </select>
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-start flex-wrap items-center  px-10">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Descripción Hallazgos Encontrados
                  </label>
                  <textarea
                    rows="6"
                    cols="50"
                    className="mb-10 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="textbox"
                    {...register('bodyDiscoveries', {required: false})}
                  />
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center  ">
                  <div className="w-full md:w-1/2 flex justify-evenly flex-wrap">
                    <div className="w-full flex justify-center my-5 ">
                      <span className="text-cendiatra font-bold text-2xl">
                        TINNEL
                      </span>
                    </div>
                    <div className="w-full md:w-1/3 flex justify-start flex-wrap">
                      <div
                        className={
                          tinnelRightObservation
                            ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() =>
                              setTinnelRightObservation(false)
                            }
                            onMouseEnter={() => setTinnelRightObservation(true)}
                            {...register('tinnelRightObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                      <label
                        className="block text-sm font-medium text-gray-700 text-cendiatra"
                        onMouseLeave={() => setTinnelRightObservation(false)}
                        onMouseEnter={() => setTinnelRightObservation(true)}
                      >
                        Tinnel Derecho
                      </label>
                      <select
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        //   placeholder="Dirección Email"
                        {...register('tinnelRight', {required: false})}
                      >
                        <option key="-1" value=""></option>
                        <option key="1" value="1">
                          Negativo
                        </option>
                        <option key="2" value="2">
                          Positivo
                        </option>
                      </select>
                    </div>
                    <div className="w-full md:w-1/3 flex justify-start flex-wrap">
                      <div
                        className={
                          tinnelLeftObservation
                            ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setTinnelLeftObservation(false)}
                            onMouseEnter={() => setTinnelLeftObservation(true)}
                            {...register('tinnelLeftObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                      <label
                        className="block text-sm font-medium text-gray-700 text-cendiatra"
                        onMouseLeave={() => setTinnelLeftObservation(false)}
                        onMouseEnter={() => setTinnelLeftObservation(true)}
                      >
                        Tinnel Izquierdo
                      </label>
                      <select
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        //   placeholder="Dirección Email"
                        {...register('tinnelLeft', {required: false})}
                      >
                        <option key="-1" value=""></option>
                        <option key="1" value="1">
                          Negativo
                        </option>
                        <option key="2" value="2">
                          Positivo
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 flex justify-evenly flex-wrap">
                    <div className="w-full flex justify-center my-5 ">
                      <span className="text-cendiatra font-bold text-2xl">
                        PHALEN
                      </span>
                    </div>
                    <div className="w-full md:w-1/3 flex justify-start flex-wrap">
                      <div
                        className={
                          phanelRightObservation
                            ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() =>
                              setPhanelRightObservation(false)
                            }
                            onMouseEnter={() => setPhanelRightObservation(true)}
                            {...register('phanelRightObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                      <label
                        className="block text-sm font-medium text-gray-700 text-cendiatra"
                        onMouseLeave={() => setPhanelRightObservation(false)}
                        onMouseEnter={() => setPhanelRightObservation(true)}
                      >
                        Phanel Derecho
                      </label>
                      <select
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        //   placeholder="Dirección Email"
                        {...register('phanelRight', {required: false})}
                      >
                        <option key="-1" value=""></option>
                        <option key="1" value="1">
                          Negativo
                        </option>
                        <option key="2" value="2">
                          Positivo
                        </option>
                      </select>
                    </div>
                    <div className="w-full md:w-1/3 flex justify-start flex-wrap">
                      <div
                        className={
                          phanelLeftObservation
                            ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                            : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                        }
                      >
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                            placeholder="Observación"
                            type="textbox"
                            onMouseLeave={() => setPhanelLeftObservation(false)}
                            onMouseEnter={() => setPhanelLeftObservation(true)}
                            {...register('phanelLeftObservation', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                      <label
                        className="block text-sm font-medium text-gray-700 text-cendiatra"
                        onMouseLeave={() => setPhanelLeftObservation(false)}
                        onMouseEnter={() => setPhanelLeftObservation(true)}
                      >
                        Phanel Izquierdo
                      </label>
                      <select
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        //   placeholder="Dirección Email"
                        {...register('phanelLeft', {required: false})}
                      >
                        <option key="-1" value=""></option>
                        <option key="1" value="1">
                          Negativo
                        </option>
                        <option key="2" value="2">
                          Positivo
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  formSection === 8
                    ? 'w-full bg-examBg bg-cover bg-center'
                    : 'w-full hidden'
                }
              >
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    EXÁMENES DE LABORATORIO
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-start flex-wrap items-center  px-10">
                  <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                    Descripción Hallazgos Encontrados
                  </label>

                  <textarea
                    rows="6"
                    cols="50"
                    className="mb-10 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    type="textbox"
                    {...register('laboratoriesDiscoveries', {required: false})}
                  />
                </div>
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    EXÁMENES AUXILIARES
                  </span>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Audiometría Tamiz
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('audiometric', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Tamizaje Auditivo
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('auditoryScreening', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Optometría
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('optometry', {required: false})}
                    />
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Visiometría
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('visiometry', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Espirometría
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('spirometry', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Evaluacíon de la Voz
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('voice', {required: false})}
                    />
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Exámenes Complementarios
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('complementaryExams', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Otros
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('otherTest', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4"></div>
                </div>
              </div>
              <div className={formSection === 9 ? 'w-full ' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    DIAGNÓSTICO
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 1
                    </label>
                    <span className="text-red-500 w-full">
                      {errors.diagnosis1 && 'Por favor ingrese un diagnóstico'}
                    </span>
                    <input
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'diagnosis1'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('diagnosis1', {required: true})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis1,
                          setFiltredDiagnosis1,
                          showDiagnosis1,
                          setShowDiagnosis1
                        )
                      }
                      value={searchDiagnosis1}
                    />
                    {showDiagnosis1 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis1.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis1,
                                setShowDiagnosis1,
                                'diagnosis1'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 2
                    </label>

                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('diagnosis2', {required: false})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis2,
                          setFiltredDiagnosis2,
                          showDiagnosis2,
                          setShowDiagnosis2
                        )
                      }
                      value={searchDiagnosis2}
                    />
                    {showDiagnosis2 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis2.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis2,
                                setShowDiagnosis2,
                                'diagnosis2'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 3
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      //   placeholder="Dirección Email"
                      {...register('diagnosis3', {required: false})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis3,
                          setFiltredDiagnosis3,
                          showDiagnosis3,
                          setShowDiagnosis3
                        )
                      }
                      value={searchDiagnosis3}
                    />
                    {showDiagnosis3 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis3.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis3,
                                setShowDiagnosis3,
                                'diagnosis3'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 4
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('diagnosis4', {required: false})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis4,
                          setFiltredDiagnosis4,
                          showDiagnosis4,
                          setShowDiagnosis4
                        )
                      }
                      value={searchDiagnosis4}
                    />
                    {showDiagnosis4 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis4.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis4,
                                setShowDiagnosis4,
                                'diagnosis4'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 5
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('diagnosis5', {required: false})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis5,
                          setFiltredDiagnosis5,
                          showDiagnosis5,
                          setShowDiagnosis5
                        )
                      }
                      value={searchDiagnosis5}
                    />
                    {showDiagnosis5 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis5.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis5,
                                setShowDiagnosis5,
                                'diagnosis5'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 6
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('diagnosis6', {required: false})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis6,
                          setFiltredDiagnosis6,
                          showDiagnosis6,
                          setShowDiagnosis6
                        )
                      }
                      value={searchDiagnosis6}
                    />
                    {showDiagnosis6 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis6.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis6,
                                setShowDiagnosis6,
                                'diagnosis6'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 7
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('diagnosis7', {required: false})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis7,
                          setFiltredDiagnosis7,
                          showDiagnosis7,
                          setShowDiagnosis7
                        )
                      }
                      value={searchDiagnosis7}
                    />
                    {showDiagnosis7 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis7.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis7,
                                setShowDiagnosis7,
                                'diagnosis7'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-1/4">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Diagnóstico 8
                    </label>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="texto"
                      {...register('diagnosis8', {required: false})}
                      onChange={(e) =>
                        changeDiagnosisSearch(
                          e,
                          setSearchDiagnosis8,
                          setFiltredDiagnosis8,
                          showDiagnosis8,
                          setShowDiagnosis8
                        )
                      }
                      value={searchDiagnosis8}
                    />
                    {showDiagnosis8 && (
                      <div className="bg-white h-20 border border-cendiatra fixed z-50 overflow-y-scroll overscroll-none">
                        {filtredDiagnosis8.map((item) => (
                          <div
                            className="bg-gray-100"
                            onClick={() =>
                              selectedOption(
                                item,
                                setSearchDiagnosis8,
                                setShowDiagnosis8,
                                'diagnosis8'
                              )
                            }
                            key={item.Id}
                            tabIndex="0"
                          >
                            <span>{item.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={formSection === 10 ? 'w-full ' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    CONCEPTO DE APTITUD
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <div
                      className={
                        conceptObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setConceptObservation(false)}
                          onMouseEnter={() => setConceptObservation(true)}
                          {...register('conceptObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setConceptObservation(false)}
                      onMouseEnter={() => setConceptObservation(true)}
                    >
                      Concepto de Aptitud*
                    </label>
                    <span className="text-red-500 w-full">
                      {errors.concept && 'Por favor seleccione un concepto'}
                    </span>
                    <select
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'concept'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      {...register('concept', {required: true})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        Sin restricciones para el cargo
                      </option>
                      <option key="2" value="2">
                        Apto(a) con patologías que no impiden su desempeño
                      </option>
                      <option key="3" value="3">
                        Con restricciones temporales para el cargo
                      </option>
                      <option key="4" value="4">
                        Con restricción absoluta para el cargo
                      </option>
                      <option key="5" value="5">
                        Apto(a) con recomendaciones
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <div
                      className={
                        suspectedIllnessObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setSuspectedIllnessObservation(false)
                          }
                          onMouseEnter={() =>
                            setSuspectedIllnessObservation(true)
                          }
                          {...register('suspectedIllnessObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setSuspectedIllnessObservation(false)}
                      onMouseEnter={() => setSuspectedIllnessObservation(true)}
                    >
                      Sospecha de Enfermedad Profesional*
                    </label>
                    <span className="text-red-500 w-full">
                      {errors.suspectedIllness &&
                        'Por favor seleccione una opción'}
                    </span>
                    <select
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'suspectedIllness'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      {...register('suspectedIllness', {required: true})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <div
                      className={
                        limitationsObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setLimitationsObservation(false)}
                          onMouseEnter={() => setLimitationsObservation(true)}
                          {...register('limitationsObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setLimitationsObservation(false)}
                      onMouseEnter={() => setLimitationsObservation(true)}
                    >
                      Tipo de restricción o limitaciones*
                    </label>
                    <span className="text-red-500 w-full">
                      {errors.limitations && 'Por favor seleccione una opción'}
                    </span>
                    <select
                      className={
                        Object.keys(errors).find(
                          (element) => element === 'limitations'
                        )
                          ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                          : 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      }
                      {...register('limitations', {required: true})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className={formSection === 11 ? 'w-full ' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    RECOMENDACIONES
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-2/6 flex justify-start flex-wrap">
                    <div
                      className={
                        avoidOverloadObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setAvoidOverloadObservation(false)
                          }
                          onMouseEnter={() => setAvoidOverloadObservation(true)}
                          {...register('avoidOverloadObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setAvoidOverloadObservation(false)}
                      onMouseEnter={() => setAvoidOverloadObservation(true)}
                    >
                      Evitar sobrecargas mayores a 25KG
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('avoidOverload', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>

                  <div className="w-full md:w-2/6 flex justify-start flex-wrap">
                    <div
                      className={
                        doctorsAppointmentObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setDoctorsAppointmentObservation(false)
                          }
                          onMouseEnter={() =>
                            setDoctorsAppointmentObservation(true)
                          }
                          {...register('doctorsAppointmentObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() =>
                        setDoctorsAppointmentObservation(false)
                      }
                      onMouseEnter={() =>
                        setDoctorsAppointmentObservation(true)
                      }
                    >
                      Acudir a consulta medica en su EPS para manejo y control
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('doctorsAppointment', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-2/6 flex justify-start flex-wrap">
                    <div
                      className={
                        changeDietObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setChangeDietObservation(false)}
                          onMouseEnter={() => setChangeDietObservation(true)}
                          {...register('changeDietObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setChangeDietObservation(false)}
                      onMouseEnter={() => setChangeDietObservation(true)}
                    >
                      Nutricionales - Cambios de estilo de vida
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      {...register('changeDiet', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-2/6 flex justify-start flex-wrap">
                    <div
                      className={
                        optometryTestObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setOptometryTestObservation(false)
                          }
                          onMouseEnter={() => setOptometryTestObservation(true)}
                          {...register('optometryTestObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setOptometryTestObservation(false)}
                      onMouseEnter={() => setOptometryTestObservation(true)}
                    >
                      Valoración Optométrica
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('optometryTest', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-2/6 flex justify-start flex-wrap">
                    <div
                      className={
                        EPSValuationObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setEPSValuationObservation(false)}
                          onMouseEnter={() => setEPSValuationObservation(true)}
                          {...register('EPSValuationObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setEPSValuationObservation(false)}
                      onMouseEnter={() => setEPSValuationObservation(true)}
                    >
                      Valoración EPS
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      {...register('EPSValuation', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-2/6 flex justify-start flex-wrap">
                    <div
                      className={
                        otherRecommendationsObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setOtherRecommendationsObservation(false)
                          }
                          onMouseEnter={() =>
                            setOtherRecommendationsObservation(true)
                          }
                          {...register('otherRecommendationsObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() =>
                        setOtherRecommendationsObservation(false)
                      }
                      onMouseEnter={() =>
                        setOtherRecommendationsObservation(true)
                      }
                    >
                      Otras
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('otherRecommendations', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No presenta
                      </option>
                      <option key="2" value="2">
                        No
                      </option>
                      <option key="3" value="3">
                        Si
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={formSection === 12 ? 'w-full ' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    INGRESO PROGRAMA EPIDEMIOLÓGICO
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        visualConditionObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setVisualConditionObservation(false)
                          }
                          onMouseEnter={() =>
                            setVisualConditionObservation(true)
                          }
                          {...register('visualConditionObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setVisualConditionObservation(false)}
                      onMouseEnter={() => setVisualConditionObservation(true)}
                    >
                      Condición Visual
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('visualCondition', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        auditoryConditionObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setAuditoryConditionObservation(false)
                          }
                          onMouseEnter={() =>
                            setAuditoryConditionObservation(true)
                          }
                          {...register('auditoryConditionObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() =>
                        setAuditoryConditionObservation(false)
                      }
                      onMouseEnter={() => setAuditoryConditionObservation(true)}
                    >
                      Condición auditiva
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('auditoryCondition', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        biomechanicRiskObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setBiomechanicRiskObservation(false)
                          }
                          onMouseEnter={() =>
                            setBiomechanicRiskObservation(true)
                          }
                          {...register('biomechanicRiskObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setBiomechanicRiskObservation(false)}
                      onMouseEnter={() => setBiomechanicRiskObservation(true)}
                    >
                      Riesgo Biomecánico
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('biomechanicRisk', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        cardiovascularObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() =>
                            setCardiovascularObservation(false)
                          }
                          onMouseEnter={() =>
                            setCardiovascularObservation(true)
                          }
                          {...register('cardiovascularObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setCardiovascularObservation(false)}
                      onMouseEnter={() => setCardiovascularObservation(true)}
                    >
                      Cardiovascular
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('cardiovascular', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        biologicalObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setBiologicalObservation(false)}
                          onMouseEnter={() => setBiologicalObservation(true)}
                          {...register('biologicalObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setBiologicalObservation(false)}
                      onMouseEnter={() => setBiologicalObservation(true)}
                    >
                      Biológicos
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('biological', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        chemicalRiskObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setChemicalRiskObservation(false)}
                          onMouseEnter={() => setChemicalRiskObservation(true)}
                          {...register('chemicalRiskObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setChemicalRiskObservation(false)}
                      onMouseEnter={() => setChemicalRiskObservation(true)}
                    >
                      Riesgo Químico
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('chemicalRisk', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <div
                      className={
                        psychoSocialObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setPsychoSocialObservation(false)}
                          onMouseEnter={() => setPsychoSocialObservation(true)}
                          {...register('psychoSocialObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setPsychoSocialObservation(false)}
                      onMouseEnter={() => setPsychoSocialObservation(true)}
                    >
                      Psicosocial
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('psychoSocial', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-1/5 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Otros
                    </label>
                    <input
                      type="text"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      {...register('otherRisk', {required: false})}
                    />
                  </div>
                </div>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-2/5 flex justify-start flex-wrap">
                    <div
                      className={
                        muscularRiskObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setMuscularRiskObservation(false)}
                          onMouseEnter={() => setMuscularRiskObservation(true)}
                          {...register('muscularRiskObservation', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setMuscularRiskObservation(false)}
                      onMouseEnter={() => setMuscularRiskObservation(true)}
                    >
                      Prevención de Alteraciones Osteomusculares
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('muscularRisk', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-2/5 flex justify-start flex-wrap">
                    <div
                      className={
                        traumaObservation
                          ? 'text-sm bg-white  -mt-20 w-52 text-gray-100  absolute rounded  shadow-xl'
                          : 'text-sm hidden bg-white  text-gray-100 px-1 absolute rounded bg-opacity-50 shadow-xl'
                      }
                    >
                      <div>
                        <textarea
                          rows="4"
                          cols="50"
                          className=" appearance-none rounded-none relative block w-full px-2 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                          placeholder="Observación"
                          type="textbox"
                          onMouseLeave={() => setTraumaObservation(false)}
                          onMouseEnter={() => setTraumaObservation(true)}
                          {...register('traumaObservation', {required: false})}
                        />
                      </div>
                    </div>
                    <label
                      className="block text-sm font-medium text-gray-700 text-cendiatra"
                      onMouseLeave={() => setTraumaObservation(false)}
                      onMouseEnter={() => setTraumaObservation(true)}
                    >
                      Deterioro por trauma acumulativo (D.T.A)
                    </label>
                    <select
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      //   placeholder="Dirección Email"
                      {...register('trauma', {required: false})}
                    >
                      <option key="-1" value=""></option>
                      <option key="1" value="1">
                        No
                      </option>
                      <option key="2" value="2">
                        Si
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className={formSection === 13 ? 'w-full ' : 'w-full hidden'}>
                <div className="w-full flex justify-center my-5 ">
                  <span className="text-cendiatra font-bold text-2xl">
                    RESTRICCIONES
                  </span>
                </div>

                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Tipo
                    </label>
                    <textarea
                      rows="4"
                      cols="50"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="textbox"
                      {...register('restrictionType', {required: false})}
                    />
                  </div>
                  <div className="w-full md:w-1/4 flex justify-start flex-wrap">
                    <label className="block text-sm font-medium text-gray-700 text-cendiatra">
                      Duración
                    </label>
                    <textarea
                      rows="4"
                      cols="50"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="textbox"
                      {...register('restrictionTime', {required: false})}
                    />
                  </div>
                </div>
              </div>

              <div className={formSection === 14 ? 'w-full ' : 'w-full hidden'}>
                <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
                  <div className="w-full md:w-1/3 flex justify-center flex-wrap">
                    <div className="w-full flex justify-center my-5 ">
                      <span className="text-cendiatra font-bold text-2xl">
                        Historia Manual
                      </span>
                    </div>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="file"
                      // accept="image/*"
                      // {...register("history", { required: false })}
                    />
                  </div>
                  <div className="w-full md:w-1/3 flex justify-center flex-wrap">
                    <div className="w-full flex justify-center my-5 ">
                      <span className="text-cendiatra font-bold text-2xl">
                        Certificado Manual
                      </span>
                    </div>
                    <input
                      className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                      type="file"
                      // accept="image/*"
                      // {...register("certificate", { required: false })}
                    />
                  </div>
                  <div className="w-full flex justify-center">
                    <button
                      type="submit"
                      onClick={() => onInvalid()}
                      className="mt-10 hover:opacity-80 items-center group relative w-24 h-9 flex justify-center py-2 px-4 border border-transparent text-xs font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                    >
                      Registrar
                    </button>
                  </div>
                  <div className="w-full flex justify-center hidden">
                    <button
                      type="submit"
                      id="triggerButton"
                      className="mt-10 hover:opacity-80 items-center group relative w-24 h-9 flex justify-center py-2 px-4 border border-transparent text-xs font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                    >
                      Registrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {formSection === 1 ? (
          <div className="bg-transparent w-5/6 mt-5 flex flex-wrap justify-between ">
            <div className="bg-white p-4 rounded-lg  w-full md:w-custom border border-opacity-70 border-cendiatra shadow-2xl bg-locationBg bg-center bg-cover">
              <div className="w-full flex justify-center my-5">
                <span className="text-cendiatra font-bold text-2xl">
                  UBICACIÓN
                </span>
              </div>
              <div className="w-full flex  items-center my-5 justify-center">
                <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/3 text-center">
                  País:{' '}
                  <span className="w-full text-center text-md text-black font-light">
                    {ctx.patientAgendaInfo.country}
                  </span>
                </label>
                <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/3 text-center">
                  Departamento:{' '}
                  <span className="w-full text-center text-md text-black font-light">
                    {ctx.patientAgendaInfo.state}
                  </span>
                </label>
                <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/3 text-center">
                  Municipio:{' '}
                  <span className="w-full text-center text-md text-black font-light">
                    {ctx.patientAgendaInfo.city}
                  </span>
                </label>
              </div>
              <div className="w-full flex  items-center my-5 justify-center">
                <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2 text-center">
                  Dirección:{' '}
                  <span className="w-full text-center text-md text-black font-light">
                    {ctx.patientAgendaInfo.address}
                  </span>
                </label>
                <label className="block text-md font-medium text-gray-700 text-cendiatra w-1/2 text-center">
                  Teléfono:{' '}
                  <span className="w-full text-center text-md text-black font-light">
                    {ctx.patientAgendaInfo.phone}
                  </span>
                </label>
              </div>
            </div>
            <div className=" p-4 rounded-lg  w-full md:w-custom border border-opacity-50 border-cendiatra shadow-2xl bg-partnerBg bg-center bg-cover">
              <div className="w-full flex justify-center my-5">
                <span className="text-cendiatra font-bold text-2xl">
                  ACOMPAÑANTE
                </span>
              </div>
              <div className="w-full flex  items-center my-5 justify-center">
                <label className="block text-md font-medium text-gray-700 text-cendiatra w-full text-center">
                  Nombre Acompañante:{' '}
                  <span className="w-full text-center text-md text-black font-light">
                    {ctx.patientAgendaInfo.accompanistName}
                  </span>
                </label>
              </div>
              <div className="w-full flex  items-center my-5 justify-center">
                <label className="block text-md font-medium text-gray-700 text-cendiatra w-full text-center">
                  Telefono Acompañante:{' '}
                  <span className="w-full text-center text-md text-black font-light">
                    {ctx.patientAgendaInfo.accompanistPhone}
                  </span>
                </label>
              </div>
            </div>
          </div>
        ) : null}

        <div className="rounded-md  -space-y-px flex justify-center flex-wrap my-3 items-center w-full flex justify-center">
          {formSection === 1 ? (
            <button
              className="opacity-70 group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-cendiatra bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              type="button"
              disabled={true}
              onClick={() => removeFormSection()}
            >
              {' '}
              Anterior
            </button>
          ) : (
            <button
              className="group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-cendiatra bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              type="button"
              onClick={() => removeFormSection()}
            >
              {' '}
              Anterior
            </button>
          )}
          <span className=" text-md text-cendiatra mx-10 font-semibold">
            {formSection}/14
          </span>
          {formSection === 14 ? (
            <button
              className="opacity-70 items-center group relative w-24 h-9 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              type="button"
              disabled={true}
              onClick={() => addFormSection()}
            >
              {' '}
              Siguiente
            </button>
          ) : (
            <button
              className="hover:opacity-80 items-center group relative w-24 h-9 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              type="button"
              onClick={() => addFormSection()}
            >
              {' '}
              Siguiente
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default MedicForm
