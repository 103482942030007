import React, {useEffect, useState} from 'react'
import {ContenedorPagina} from '../paginas/ContenedorPagina'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import {CeldaFormatoDinero} from '../Celdas'
import {FiltroFecha} from '../Filtros'
import {TEXTO_VISUAL} from '../../constantes'
import moment from 'moment'
import {ObtenerReporteEjecucionSedePorFecha} from '../../microservicios'
import {CuadroTituloYDato} from '../MostrarInformacion'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const ReporteEjecucionSede = () => {
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const sede = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)

  const [datosTabla, setDatosTabla] = useState([])
  const [valorTotal, setValorTotal] = useState(0)
  const [totalPacientes, setTotalPacientes] = useState(0)

  let fechaActual = moment()
  let fechaConsulta = fechaActual.subtract(30, 'days').format('YYYY-MM-DD')

  useEffect(() => {
    let componenteMontado = true
    const obtenerDatosReportes = async () => {
      try {
        const respuesta = await ObtenerReporteEjecucionSedePorFecha(
          sede,
          fechaConsulta
        )
        if (respuesta.status === 204) {
          setDatosTabla({})
        } else {
          const datosMapeados = respuesta.data?.reporteEjecucionesSedes.map(
            (elemento, index) => {
              return {
                id: index + 1,
                fecha: moment(elemento?.fecha).format('DD-MM-YYYY'),
                fechaSinFormato: elemento?.fecha,
                cantidad: elemento?.cantidad,
                valor: elemento?.valor,
              }
            }
          )
          setDatosTabla(datosMapeados)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (componenteMontado) {
      obtenerDatosReportes()
    }

    return () => {
      componenteMontado = false
    }
  }, [])

  const column = [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Fecha atención'} />,
      accessor: 'fecha',
      className: 'w-4/12',
      headerStyle:
        'hidden justify-center items-center bg-cendiatra-verde-5 text-white text-14px rounded-l-lg h-20 pl-1',
      disableFilters: false,
      Filter: FiltroFecha,
      filter: 'fecha',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      establecerFechaFinal: true,
      establecerFechaInicial: true,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Fecha atención'} />,
      accessor: 'fechaSinFormato',
      className: 'w-4/12',
      headerStyle:
        'flex justify-center items-center bg-cendiatra-verde-5 text-white text-14px rounded-l-lg h-20 pl-1',
      disableFilters: false,
      Filter: FiltroFecha,
      filter: 'fecha',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center hidden',
      establecerFechaFinal: true,
      establecerFechaInicial: true,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Total pacientes'} />,
      accessor: 'cantidad',
      className: 'w-4/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },

    {
      Header: (row) => <EncabezadoFondo titulo={'Valor Total'} />,
      accessor: 'valor',
      className: 'w-4/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg  pr-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={`text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          valor={row.row.original.valor}
        />
      ),
    },
  ]

  const actualizarDatosCuadros = (filas) => {
    const sumaValoresPrecio = filas.reduce((acumulador, objeto) => {
      return acumulador + objeto.original.valor
    }, 0)
    const sumaValoresCantidadPacientes = filas.reduce((acumulador, objeto) => {
      return acumulador + objeto.original.cantidad
    }, 0)
    setValorTotal(sumaValoresPrecio)
    setTotalPacientes(sumaValoresCantidadPacientes)
  }
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_JEFE_DE_SEDE
          .REPORTE_EJECUCION_DIARIA_POR_SEDE
      }
    >
      <div className="w-full flex justify-start items-center">
        <span className="px-5 text-cendiatra font-semibold bg-cendiatra-gris-5">
          ¡Este resumen muestra la información de los últimos 30 días!
        </span>
      </div>
      <div className="w-full flex justify-between items-center">
        <CuadroTituloYDato
          estilosContenedor={'w-1/2 pr-2 text-lg'}
          titulo={'Total de pacientes'}
          informacion={totalPacientes}
        />
        <CuadroTituloYDato
          estilosContenedor={'w-1/2 pl-2 text-lg'}
          titulo={'Valor total'}
          informacion={
            <CeldaFormatoDinero
              estilos={`text-center appearance-none font-semibold rounded relative block w-full pointer-events-none text-cendiatra-gris-1 text-lg rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
              valor={valorTotal ?? 0}
            />
          }
        />
      </div>

      <TablaConPaginacionYFiltros
        data={
          datosTabla?.sort((a, b) => {
            return b?.id - a?.id
          }) ?? []
        }
        columns={column}
        paginacion={true}
        funcionObtenerDatos={actualizarDatosCuadros}
      ></TablaConPaginacionYFiltros>
    </ContenedorPagina>
  )
}

export default ReporteEjecucionSede
