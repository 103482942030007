export const NUMERO_CARACTERES_DOCUMENTO = {
  1: {
    minimo: 6,
    maximo: 10,
    mensaje: 'Para este tipo de documento solo se permiten 6,7,8 y 10 números',
    tipoCampo: 'number',
  },
  2: {
    minimo: 6,
    maximo: 16,
    mensaje: 'Para este tipo de documento solo se permiten de 6 a 16 números',
    tipoCampo: 'number',
  },
  3: {
    minimo: 11,
    maximo: 11,
    mensaje: 'Para este tipo de documento solo se permiten 11 caracteres',
    tipoCampo: 'text',
  },
  4: {
    minimo: 4,
    maximo: 16,
    mensaje:
      'Para este tipo de documento solo se permiten de 4 a 16 caracteres',
    tipoCampo: 'text',
  },
  5: {
    minimo: 9,
    maximo: 9,
    mensaje: 'Para este tipo de documento solo se permiten 9 caracteres',
    tipoCampo: 'text',
  },
  6: {
    minimo: 15,
    maximo: 15,
    mensaje: 'Para este tipo de documento solo se permiten 15 números',
    tipoCampo: 'number',
  },
  7: {
    minimo: 10,
    maximo: 11,
    mensaje: 'Para este tipo de documento solo se permiten 10 a 11 caracteres',
    tipoCampo: 'text',
  },
  8: {
    minimo: 10,
    maximo: 11,
    mensaje: 'Para este tipo de documento solo se permiten 10 a 11 números',
    tipoCampo: 'number',
  },
  9: {
    minimo: 9,
    maximo: 9,
    mensaje: 'Para este tipo de documento solo se permiten 9 números',
    tipoCampo: 'number',
  },
  10: {
    minimo: 10,
    maximo: 10,
    mensaje: 'Para este tipo de documento solo se permiten 10 caracteres',
    tipoCampo: 'text',
  },
  11: {
    minimo: 12,
    maximo: 12,
    mensaje: 'Para este tipo de documento solo se permiten 12 caracteres',
    tipoCampo: 'text',
  },
  12: {
    minimo: 9,
    maximo: 9,
    mensaje: 'Para este tipo de documento solo se permiten 9 números',
    tipoCampo: 'text',
  },
  13: {
    minimo: 12,
    maximo: 12,
    mensaje: 'Para este tipo de documento solo se permiten 12 caracteres',
    tipoCampo: 'text',
  },
  14: {
    minimo: 10,
    maximo: 10,
    mensaje: 'Para este tipo de documento solo se permiten 10 caracteres',
    tipoCampo: 'text',
  },
  15: {
    minimo: 7,
    maximo: 9,
    mensaje: 'Para este tipo de documento solo se permiten de 7 a 9 números',
    tipoCampo: 'number',
  },
}
