import React, {useContext} from 'react'
import {ContextoFormulario} from '../../contexto'
import BaseSeccion from './BaseSeccion'

const BasePestana = ({numeroPestana}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  return (
    <div className="w-full">
      {pestana.secciones.map((seccion, key) => (
        <BaseSeccion
          numeroPestana={numeroPestana}
          numeroSeccion={seccion.numeroSeccion}
          key={key}
        />
      ))}
    </div>
  )
}

export default BasePestana
