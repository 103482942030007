import {useState} from 'react'
import {ObtenerDepartamentosPorNombre} from '../../cache/servicios-cache'

export const useAutocompletarDepartamento = () => {
  const [departamentosFiltrados, setDepartamentosFiltrados] = useState([])

  const filtrarDepartamentos = async (textoEscrito) => {
    if (typeof textoEscrito === 'string' && textoEscrito.length >= 3) {
      textoEscrito = textoEscrito.toLocaleUpperCase()

      const departamentos = await ObtenerDepartamentosPorNombre(textoEscrito)

      setDepartamentosFiltrados(
        departamentos.map((departamento) => ({
          id: departamento.Id,
          filtro: departamento.Nombre,
        }))
      )
    } else {
      setDepartamentosFiltrados([])
    }
  }

  return [filtrarDepartamentos, departamentosFiltrados]
}
