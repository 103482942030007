import React from 'react'

const FiltroListaSeleccionar = ({
  column: {filterValue, setFilter, preFilteredRows, id},
  estilos = 'p-1',
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      if (row.values[id]) options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      className={`flex w-11/12 justify-evenly items-center rounded-md text-cendiatra ${estilos} focus:outline-none`}
      value={filterValue ?? ''}
      onChange={(e) => {
        setFilter(e.target.value)
      }}
    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export default FiltroListaSeleccionar
