import React from 'react'

const CeldaConBotonDinamica = ({
  habilitado,
  estilosImagen,
  imagenHabilitado,
  imagenDeshabilitado,
  funcion,
}) => {
  return (
    <div
      className="w-full flex justify-center items-center cursor-pointer"
      onClick={habilitado ? funcion : null}
    >
      <img
        className={`${estilosImagen}`}
        src={habilitado ? imagenHabilitado : imagenDeshabilitado}
        alt="Agregar"
      />
    </div>
  )
}

export default CeldaConBotonDinamica
