import React from 'react'
import addButtonImg from '../../../../imagenes/addButton.png'
import removeButtonImg from '../../../../imagenes/removeButton.png'
import {InputRegex} from '../../../inputs'
import BotonConImagen from '../../../BotonConImagen'

const ListaDesplegableOpcion = ({
  id,
  valorOpcion,
  actualizarValorOpcion,
  eliminarOpcion,
  agregarOpcion,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-wrap">
        <InputRegex
          ancho={'w-10/12'}
          cambioValor={(e) => {
            actualizarValorOpcion(e, id)
          }}
          estilosContenedor={'w-full flex'}
          estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
          tipo={'text'}
          placeholder={'INGRESE UNA OPCIÓN'}
          valor={valorOpcion}
          requerido={true}
        />
        <BotonConImagen
          estilosContenedor={'w-10 pt-2'}
          imagen={removeButtonImg}
          medio={true}
          funcionAEjecutar={() => {
            eliminarOpcion(id)
          }}
          textoAlternativo={'eliminar'}
        />
        <BotonConImagen
          estilosContenedor={'w-6 pt-2'}
          imagen={addButtonImg}
          medio={true}
          funcionAEjecutar={() => {
            agregarOpcion(id)
          }}
          textoAlternativo={'agregar'}
        />
      </div>
    </div>
  )
}

export default ListaDesplegableOpcion
