import {dbComun} from '../bases-datos/dbComun'

export const ObtenerCuentasDeIngreso = async () => {
  return Promise.resolve(dbComun.table('CuentaDeIngreso').toArray()).then(
    (res) => {
      return res.map((elemento) => ({
        Id: elemento.Id,
        Nombre: elemento.Codigo + '-' + elemento.Nombre,
      }))
    }
  )
}

export const ObtenerCuentaDeIngresoPorId = async (id) => {
  return Promise.resolve(dbComun.table('CuentaDeIngreso').get({Id: id}))
}
