import React, {useEffect, useContext} from 'react'
import logoImg from '../imagenes/cendiatraLogo.svg'
import {ContextApplication} from '../contexto'

const MainPage = () => {
  const contextoAplicacion = useContext(ContextApplication)
  useEffect(() => {
    contextoAplicacion.setDeshabilitarMenu(false)
    contextoAplicacion.setMostrarModalMenu(false)
  }, [])
  return (
    <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
      <img className="w-4/12 border-opacity-50" src={logoImg} alt="service" />
    </div>
  )
}

export default MainPage
