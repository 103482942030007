import React from 'react'

const Fila = ({
  ancho,
  alineacionHorizontal,
  alineacionVertical = 'items-center',
  flexWrap,
  children,
}) => {
  return (
    <div
      className={`${ancho} ${alineacionHorizontal} flex ${
        flexWrap ? 'flex-wrap' : 'flex-nowrap'
      } ${alineacionVertical}`}
    >
      {children}
    </div>
  )
}

export default Fila
