import React, {useEffect, useContext} from 'react'
import {ContenedorDesplegable} from '../../Contenedores'
import {TablaConCheckbox} from '../../Tablas'
import {CheckboxTabla, CeldaInput} from '../../inputs'
import {ContextoMedicoOcupacional} from '../../../contexto'

const TablaRiesgos = ({
  listaRiesgos,
  tituloContenedor,
  expandidoInicial,
  actualizarEstado,
  deshabilitar,
  numeroPagina,
}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  const manejarCambioObservaciones = (valor, id) => {
    actualizarEstado(
      listaRiesgos.map((riesgo) => {
        if (riesgo.Id == id) {
          return {
            ...riesgo,
            Observaciones: valor,
          }
        }
        return riesgo
      })
    )
  }

  const manejarCambioRiesgo = (id) => {
    actualizarEstado(
      listaRiesgos.map((riesgo) => {
        if (riesgo.Id == id) {
          return {
            ...riesgo,
            EstaActivo: !riesgo.EstaActivo,
          }
        }
        return riesgo
      })
    )
  }
  useEffect(() => {
    if (
      numeroPagina == contextoMedicoOcupacional.numeroPagina &&
      !contextoMedicoOcupacional.primerEmpleo
    ) {
      contextoMedicoOcupacional.setRiesgoSeleccionado(
        listaRiesgos.some((x) => x.EstaActivo == true)
      )
    }
  }, [listaRiesgos])

  const columnasRiesgos = React.useMemo(() => [
    {
      Header: 'DESCRIPCIÓN',
      accessor: 'Nombre',
      className:
        'w-4/12 flex text-left justify-start items-center content-center h-16 text-cendiatra-gris-3',
      headerStyle: 'w-4/12  text-cendiatra rounded-l-md h-14 justify-start',
    },
    {
      Header: 'OBSERVACIONES',
      className:
        'w-6/12 flex text-center justify-center items-center content-center h-16 text-cendiatra-gris-3',
      headerStyle: 'w-6/12 text-cendiatra  text-white h-14 justify-start',
      accessor: 'Observaciones',
      Cell: CeldaInput,
    },
    {
      Header: 'SELECCIONE',
      headerStyle: 'w-2/12 text-cendiatra flex justify-center',
      id: 'checkbox',
      className: 'w-2/12',
      Cell: CheckboxTabla,
    },
  ])
  return (
    <div className="w-full flex justify-center items-center">
      <ContenedorDesplegable
        titulo={tituloContenedor}
        expandidoInicial={expandidoInicial}
        deshabilitar={deshabilitar}
      >
        <div className="w-full flex justify-center items-center mx-20 ">
          <TablaConCheckbox
            data={listaRiesgos}
            columns={columnasRiesgos}
            estilosCheckbox={'w-2/12'}
            actualizarDatos={manejarCambioObservaciones}
            actualizarSeleccionado={manejarCambioRiesgo}
          />
        </div>
      </ContenedorDesplegable>
    </div>
  )
}

export default TablaRiesgos
