import React, {useContext, useEffect} from 'react'
import {ContextoPlantilla} from '../../../contexto/ContextoPlantilla.js'
import InputRegex from '../../inputs/InputRegex.jsx'
import BotonOpcion from '../../Botones/BotonOpcion.jsx'
import BotonesPlantillas from '../BotonesPlantillas.jsx'

const FormularioNombrarPlantilla = ({numeroPagina}) => {
  const contextoPlantilla = useContext(ContextoPlantilla)

  useEffect(() => {
    if (numeroPagina === contextoPlantilla.numeroPagina) {
      contextoPlantilla.setErrores([])
    }
  }, [contextoPlantilla.numeroPagina])

  const actualizarValorNombre = (valor) => {
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      nombrePlantilla: valor,
    })
  }

  const actualizarValorPosicionFormularios = () => {
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      formulariosFinalPagina:
        !contextoPlantilla.plantilla.formulariosFinalPagina,
    })
  }

  return (
    <div className="w-11/12 flex flex-wrap justify-center items-center ml-16">
      <InputRegex
        id="nombre"
        ancho={'w-2/3'}
        cambioValor={actualizarValorNombre}
        estilosContenedor={'w-full flex'}
        estilosInput={`my-3 rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        tipo={'text'}
        placeholder={'DESCRIPCIÓN'}
        titulo={'Nombre de la plantilla'}
        valor={contextoPlantilla.plantilla.nombrePlantilla}
        contexto={contextoPlantilla}
        numeroPagina={numeroPagina}
        requerido={true}
      />
      <div className="w-full flex-wrap flex">
        <div className="w-1/3 flex"></div>
        <div className="w-2/3 flex-wrap flex">
          <BotonOpcion
            id="botonFormularios"
            titulo="Ubicar formularios al final de página?"
            estaActivo={contextoPlantilla.plantilla.formulariosFinalPagina}
            esHorizontal={true}
            funcionClick={actualizarValorPosicionFormularios}
          />
        </div>
      </div>
      <div className="w-2/3">
        <BotonesPlantillas contexto={contextoPlantilla} />
      </div>
    </div>
  )
}

export default FormularioNombrarPlantilla
