import React from 'react'

const CeldaConCheckbox = ({valor = false, funcion, deshabilitado = false}) => {
  return (
    <>
      <input
        className={`${
          deshabilitado ? 'checkboxEspecialDeshabilitado' : 'checkboxEspecial'
        }  personalizado`}
        type="checkbox"
        onChange={funcion}
        checked={valor}
        disabled={deshabilitado}
      />
    </>
  )
}

export default CeldaConCheckbox
