import React, {useEffect, useState, useContext} from 'react'
import {useTable, usePagination, useRowSelect} from 'react-table'
import iconoEditar from '../../imagenes/iconoEditarVerde.svg'

const TablaDeServicios = ({data, columns, funcion, filtroActivo}) => {
  const [numeroInicio, setNumeroInicio] = useState(0)

  const [numeroFinal, setNumeroFinal] = useState(10)

  const [numerosParaMostrar, setNumerosparaMostrar] = useState([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    state: {pageIndex},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0},
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          Header: 'Modulo',
          className: 'w-2/12',
          Cell: ({row}) => (
            <div className="w-full  flex justify-center">
              <img
                onClick={() => funcion(row)}
                className="cursor-pointer w-10 h 10"
                src={iconoEditar}
                alt="editSvg"
              />
            </div>
          ),
        },
      ])
    }
  )

  useEffect(() => {
    const crearArregloNumeros = () => {
      if (pageOptions.length > 10) {
        let numeros = pageOptions.slice(numeroInicio, numeroFinal)
        setNumerosparaMostrar(numeros)
      } else {
        setNumerosparaMostrar(pageOptions)
      }
    }
    crearArregloNumeros()
  }, [data, pageOptions, numeroInicio])

  const retrocederEnLista = () => {
    if (numeroInicio - 10 > 0) {
      setNumeroInicio(numeroInicio - 10)
      setNumeroFinal(numeroFinal - 10)
    } else {
      setNumeroInicio(0)
      setNumeroFinal(10)
    }
  }
  const avanzarEnLista = () => {
    if (numeroFinal + 10 < pageOptions.length) {
      setNumeroInicio(numeroInicio + 10)
      setNumeroFinal(numeroFinal + 10)
    } else {
      setNumeroInicio(pageOptions.length - 10)
      setNumeroFinal(pageOptions.length)
    }
  }

  const irAlInicio = () => {
    setNumeroInicio(0)
    setNumeroFinal(10)
  }

  const irAlFinal = () => {
    setNumeroInicio(pageOptions.length - 10)
    setNumeroFinal(pageOptions.length)
  }

  return (
    <>
      <table className="w-11/12" {...getTableProps()}>
        <thead
          className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 text-white h-10 rounded-10px text-14px"
          style={{lineHeight: '16px'}}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              className="w-full flex justify-evenly items-center"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="w-full " {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className="w-full flex items-center border-b-2 border-b-gris justify-evenly text-center text-13px text-cendiatra-gris-1  my-5"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: `${cell.column.className} uppercase `,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {filtroActivo ? (
        <div className="w-full flex justify-center my-5">
          <button
            className="text-3xl text-cendiatra-gris-1 pb-2 mr-2 "
            onClick={irAlInicio}
            disabled={!canPreviousPage}
          >
            {'«'}
          </button>{' '}
          <button
            className="text-3xl text-cendiatra-gris-1 pb-2 mr-2"
            onClick={retrocederEnLista}
            disabled={!canPreviousPage}
          >
            {'‹'}
          </button>{' '}
          {numerosParaMostrar.map((elemento) => (
            <div
              key={elemento}
              className={
                pageIndex === elemento
                  ? 'z-10 bg-cendiatra-verde-7  border-cendiatra-verde-7 text-white  inline-flex h-10 items-center px-4 py-2 border text-13px  cursor-pointer rounded-full '
                  : '  text-cendiatra-gris-1   hover:bg-gray-50 inline-flex items-center px-4 h-10 text-13px cursor-pointer rounded-full'
              }
              onClick={() => gotoPage(elemento)}
            >
              {elemento + 1}
            </div>
          ))}
          <button
            className="text-3xl text-cendiatra-gris-1 pb-2 ml-2"
            onClick={avanzarEnLista}
            disabled={!canNextPage}
          >
            {'›'}
          </button>{' '}
          <button
            className="text-3xl text-cendiatra-gris-1 pb-2 ml-2"
            onClick={irAlFinal}
            disabled={!canNextPage}
          >
            {'»'}
          </button>{' '}
        </div>
      ) : null}
    </>
  )
}
export default TablaDeServicios
