import {dbComun} from '../bases-datos/dbComun'

export const ObtenerCupsPorCodigoONombre = async (filtro) => {
  let cupsPorNombre = (
    await dbComun
      .table('Cups')
      .filter(
        ({Nombre, Codigo}) =>
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1 ||
          Codigo.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Codigo + ' - ' + elemento.Nombre,
    codigo: elemento.Codigo,
  }))
  return Promise.resolve(cupsPorNombre)
}

export const ObtenerCupPorId = async (id) => {
  return Promise.resolve(dbComun.table('Cups').get({Id: id}))
}
