import React from 'react'

const ConfirmacionEmpresaCreada = ({id, codigoCliente, nombreEmpresa}) => {
  const trasnformarNumero = (numero) => {
    if (parseInt(numero) < 10) {
      return `0${numero}`
    } else {
      return numero
    }
  }
  return (
    <div className="w-full flex flex-wrap m-2">
      <span className="w-full text-cendiatra text-lg font-normal">
        ID: {trasnformarNumero(id)}{' '}
      </span>
      <span className="w-full text-cendiatra text-lg font-normal">
        CÓDIGO CLIENTE: {codigoCliente}{' '}
      </span>
      <span className="w-full text-cendiatra text-lg font-normal">
        NOMBRE EMPRESA: {nombreEmpresa}{' '}
      </span>
    </div>
  )
}
export default ConfirmacionEmpresaCreada
