import React, {useEffect, useContext, useState} from 'react'
import BotonConImagen from '../../componentes/BotonConImagen'
import CuadroInformacionUsuario from '../../componentes/MostrarInformacion/CuadroInformacionUsuario'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import Pestana from '../../componentes/Pestanas/Pestana'
import ListaFormularios from '../../componentes/MostrarInformacion/ListaFormularios'
import {useLocation} from 'react-router-dom'
import {ContextApplication, ContextoMedicoOcupacional} from '../../contexto'
import ContenedorDesplegable from '../../componentes/Contenedores/ContenedorDesplegable'
import TablaHistoriaClinica from '../../componentes/Tablas/TablaHistoriaClinica'
import {
  AgregarServiciosACache,
  ObtenerServicioPorId,
} from '../../cache/servicios-cache/Servicios'
import {
  ObtenerHistoriaClinica,
  ObtenerInformacionPaciente,
  FachadaGenerarCertificadoExamenPreIngreso,
  FachadaGuardadoParcialExamenPreIngreso,
} from '../../microservicios/Fachada'
import DatosEmpresa from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/DatosEmpresa'
import RiesgosOcupacionalesAnteriores from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/RiesgosOcupacionalesAnteriores'
import BarraBotones from '../../componentes/Botones/BarraBotones'
import IconoGenerarFormula from '../../imagenes/iconoGenerarFormula.svg'
import IconoGenerarInterconsulta from '../../imagenes/iconoGenerarInterconsulta.svg'
import EstadoDeSaludActual from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/EstadoDeSaludActual'
import AntecedentesPersonales from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/AntecedentesPersonales'
import AntecedentesGinecobstetricos from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/AntecedentesGinecobstetricos'
import AntecedentesToxicologicos from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/AntecedentesToxicologicos'
import ExamenesParaclinicos from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/ExamenesParaclinicos'
import Diagnosticos from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/Diagnosticos'
import ExamenFisico from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/ExamenFisico'
import Recomendaciones from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/Recomendaciones'
import VigilanciasEpidemiologicas from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/VigilanciasEpidemiologicas'
import Restricciones from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/Restricciones'
import ConceptoAptitud from '../../componentes/Medico/ExamenMedicoOcupacional/FormularioMedicoOcupacionalPreingreso/ConceptoAptitud'
import {
  FachadaCerrarHistoriaExamenPreIngreso,
  FachadaCambiarAPendienteServicioAtencion,
} from '../../microservicios/Fachada'
import {ObtenerEstadoCivilPorId} from '../../cache/servicios-cache/EstadoCivil'
import {ObtenerEscolaridadPorId} from '../../cache/servicios-cache/Escolaridad'
import {
  ObtenerClasificacionExamenFisicoPorNombre,
  ObtenerClasificacionExamenFisicoPorId,
} from '../../cache/servicios-cache/ClasificacionExamenFisico'
import {ObtenerTipoExamenPorId} from '../../cache/servicios-cache/TipoExamen'
import {ObtenerInformacionUltimaAtencionPorUsuarioId} from '../../microservicios/Atencion.js'
import {ObtenerEmpresaPorId} from '../../cache/servicios-cache/Empresas'
import {ObtenerOrdenPorId} from '../../microservicios/Orden.js'
import {ObtenerExamenPorServicioAtencionId} from '../../microservicios/Examen'
import {ObtenerRiesgosFisicos} from '../../cache/servicios-cache/ExamenPreingreso/RiesgosFisicos'
import {ObtenerRiesgosBiologicos} from '../../cache/servicios-cache/ExamenPreingreso/RiesgosBiologicos'
import {ObtenerRiesgosErgonomicos} from '../../cache/servicios-cache/ExamenPreingreso/RiesgosErgonomicos'
import {ObtenerRiesgosNaturales} from '../../cache/servicios-cache/ExamenPreingreso/RiesgosNaturales'
import {ObtenerRiesgosLocativos} from '../../cache/servicios-cache/ExamenPreingreso/RiesgosLocativos'
import {ObtenerRiesgosSeguridad} from '../../cache/servicios-cache/ExamenPreingreso/RiesgosSeguridad'
import {ObtenerOtrosRiesgos} from '../../cache/servicios-cache/ExamenPreingreso/OtrosRiesgos'
import {ObtenerAntecedentes} from '../../cache/servicios-cache/ExamenPreingreso/Antecedentes'
import {ObtenerAntecedentesToxicologicos} from '../../cache/servicios-cache/ExamenPreingreso/AntecedentesToxicologicos.js'
import {ObtenerOrganosSistema} from '../../cache/servicios-cache/ExamenPreingreso/OrganosSistema'
import {ObtenerRecomendaciones} from '../../cache/servicios-cache/ExamenPreingreso/Recomendaciones'
import {ObtenerVigilanciasEpidemiologicas} from '../../cache/servicios-cache/ExamenPreingreso/VigilanciasEpdemiologicas'
import {ObtenerRestricciones} from '../../cache/servicios-cache/ExamenPreingreso/Restricciones'
import imagenBotonCerrar from '../../imagenes/Salir.svg'
import {TEXTO_VISUAL} from '../../constantes'

const ExamenMedicoPreingreso = () => {
  let fechaActual = moment().format('DD/MM/YYYY')

  let horaActual = moment().format('LT')

  const contextoAplicacion = useContext(ContextApplication)

  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  const history = useHistory()

  const [formularios, setFormularios] = useState([])

  const {state} = useLocation()

  useEffect(() => {
    if (state.examenes) {
      setFormularios(
        state.examenes.map((examen) => {
          return {
            nombre: examen.tipoExamen,

            estado:
              examen.servicioAtencionId === state.servicioAtencionId
                ? 5
                : examen.estadoExamen,
          }
        })
      )
    }
  }, [])

  useEffect(async () => {
    if (state && state.usuarioId) {
      await AgregarServiciosACache()
      ObtenerHistoriaClinica(state.usuarioId)
        .then(async (res) => {
          if (res.data) {
            contextoMedicoOcupacional.setListaServiciosHistoriaClinica(
              await Promise.all(
                res.data.historialServicios.map(async (serv) => ({
                  id: serv.servicioId,
                  servicio:
                    (
                      await ObtenerServicioPorId(serv.servicioId)
                    )?.Nombre?.toUpperCase() ?? '',
                  fechaYHoraCreacion: serv.fechaAtencion,
                  fechaYHoraCierre: serv.fechaCierre,
                  medico: serv.medico.toUpperCase(),
                  urlCertificado: serv.urlCertificado,
                  urlHistoria: serv.urlHistoria,
                }))
              )
            )
          } else {
            contextoMedicoOcupacional.setListaServicios([])
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  useEffect(() => {
    const obtenerDatosUsuario = async () => {
      const informacionPaciente = await ObtenerInformacionPaciente(
        state.usuarioId,
        true
      ).catch((error) => {
        console.log(error)
        return
      })
      const informacionAtencion =
        await ObtenerInformacionUltimaAtencionPorUsuarioId(
          state.usuarioId
        ).catch((error) => {
          console.log(error)
          return
        })
      contextoMedicoOcupacional.setInformacionUsuario({
        urlFotoUsuario: informacionPaciente.data.urlFoto,
        datosUsuario: [
          {
            titulo: 'Nombre',
            contenido:
              informacionPaciente.data.nombre +
              ' ' +
              informacionPaciente.data.apellidos,
          },
          {
            titulo: 'Documento',
            contenido: informacionPaciente.data.documento,
          },
          {
            titulo: 'Fecha primera atención',
            contenido: moment(
              informacionPaciente.data.fechaPrimeraAtencion
            ).format('DD / MM / YYYY'),
          },
          {
            titulo: 'Género',
            contenido:
              informacionPaciente.data.sexoId == 1 ? 'MUJER' : 'HOMBRE',
          },
          {
            titulo: 'Fecha nacimiento',
            contenido: moment(informacionPaciente.data.fechaNacimiento).format(
              'DD / MM / YYYY'
            ),
          },
          {
            titulo: 'Edad',
            contenido: moment().diff(
              informacionPaciente.data.fechaNacimiento,
              'years'
            ),
          },
          {
            titulo: 'Lugar de nacimiento',
            contenido: informacionPaciente.data.lugarNacimiento,
          },
          {
            titulo: 'Estrato',
            contenido: informacionPaciente.data.direccion.estratoId,
          },
          {
            titulo: 'Escolaridad',
            contenido: (
              await ObtenerEscolaridadPorId(
                informacionPaciente.data.escolaridadId
              )
            ).Nombre,
          },
          {
            titulo: 'Estado civil',
            contenido: (
              await ObtenerEstadoCivilPorId(
                informacionPaciente.data.estadoCivilId
              )
            ).Nombre,
          },
          {
            titulo: 'Nacionalidad',
            contenido: informacionPaciente.data.nacionalidad,
          },
          {
            titulo: 'Tipo examen',
            contenido: (
              await ObtenerTipoExamenPorId(
                informacionPaciente.data.tipoExamenId
              )
            ).Nombre,
          },
          {
            titulo: 'Empresa',
            contenido: (
              await ObtenerEmpresaPorId(informacionAtencion.data.empresaId)
            ).Nombre,
          },
          {
            titulo: 'Cargo',
            contenido: informacionPaciente.data.cargo,
          },
          {
            titulo: 'Actividad Económica',
            contenido: informacionPaciente.data.actividadEconomica,
          },
        ],
      })

      if (informacionAtencion.data.ordenId !== 0) {
        ObtenerOrdenPorId(informacionAtencion.data.ordenId)
          .then((res) => {
            if (res.status === 200 && res.data.observacionesMedicas) {
              contextoAplicacion.setModal({
                abierto: true,
                titulo: (
                  <div className="w-full flex justify-center items-center flex-wrap ">
                    <span className="font-bold w-full my-1">
                      OBSERVACION MEDICA
                    </span>
                    <span className="font-bold w-full my-1">
                      PORTAL CLIENTE
                    </span>
                    <span className="font-bold w-full my-1">
                      ORDEN {res.data.id}
                    </span>
                  </div>
                ),
                contenido: (
                  <span className="font-bold w-full">
                    {res.data.observacionesMedicas}
                  </span>
                ),
                botones: [
                  {
                    nombre: 'Continuar',
                    click: manejaCerrarModal,
                  },
                ],
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
    obtenerDatosUsuario()
  }, [])

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const manejarFinalizar = () => {
    cambiarEstadoAtencion(state.servicioAtencionId, '/main')
  }

  const manejarIrAAgendaMedico = () => {
    cambiarEstadoAtencion(state.servicioAtencionId, '/agendamedico')
  }

  const manejarIrAAgendaMedicoCerrarHistoria = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    history.push('/agendamedico')
  }

  const manejarFinalizarCerrarHistoria = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    history.push('/main')
  }

  const cambiarEstadoAtencion = (servicioAtencionId, ruta) => {
    FachadaCambiarAPendienteServicioAtencion(servicioAtencionId)
      .then(() => {
        contextoAplicacion.setModal({
          abierto: false,
          titulo: '',
          contenido: '',
          botones: [],
        })
        history.push(ruta)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const listaPestanas = [
    'Datos de la empresa',
    'Riesgos ocupacionales en empresas anteriores',
    'Estado de salud actual',
    'Antecedentes personales',
    'Antecedentes ginecobstétricos',
    'Antecedentes toxicológicos',
    'Exámen físico',
    'Exámenes paraclínicos prácticados',
    'Diagnóstico',
    'Concepto de aptitud',
    'Recomendaciones',
    'Ingreso programa vigilancia epidemiológica',
    'Restricciones',
  ]

  const {
    datosEmpresa,
    primerEmpleo,
    riesgosOcupacionales,
    listaRiesgosFisicos,
    listaRiesgosBiologicos,
    listaRiesgosErgonomicos,
    listaRiesgosLocativos,
    listaRiesgosNaturales,
    listaRiesgosSeguridad,
    listaOtrosRiesgos,
    estadoDeSaludActual,
    antecedentesGinecobstetricos,
    listaAntecedentes,
    listaAntecedentesToxicologicos,
    examenFisico,
    listaTinnelYPhalen,
    examenesParaclinicos,
    diagnosticos,
    conceptoAptitud,
    listaRecomendaciones,
    listaVigilanciasEpidemiologicas,
    listaRestricciones,
    listaOrganos,
    observacionesAntecedentesPersonales,
    observacionesAntecedentesToxicologicos,
  } = contextoMedicoOcupacional

  const opcionesSeleccionadas = (array) => {
    return array
      .filter((respuesta) => respuesta.EstaActivo)
      .map((objeto) => ({
        observacionId: objeto.Id,
        observaciones:
          objeto.Observaciones !== '' ? objeto.Observaciones : null,
      }))
  }

  const organosSeleccionados = (array) => {
    return array.map((objeto) => ({
      organoSistemaId: objeto.Id,
      estadoId: objeto.EstadoOrganoId,
      observaciones: objeto.Observaciones !== '' ? objeto.Observaciones : null,
    }))
  }

  const generarPayload = async () => {
    let payloadExamenPreIngreso = {
      servicioAtencionId: state.servicioAtencionId,
      turnoTrabajoId:
        datosEmpresa.turnoTrabajo !== '' ? datosEmpresa.turnoTrabajo : null,
      antecedentesOcupacionales: {
        tieneAntecedentes: !primerEmpleo,
        descripcionAntecedentesOcupacionales:
          !primerEmpleo && riesgosOcupacionales.antecedentesTiempoAnoMes
            ? riesgosOcupacionales.antecedentesTiempoAnoMes
            : null,
        enfermedadProfesional:
          !primerEmpleo && riesgosOcupacionales.enfermedadProfesional
            ? riesgosOcupacionales.enfermedadProfesional
            : null,
        accidenteDeTrabajo:
          !primerEmpleo && riesgosOcupacionales.accidenteDeTrabajo
            ? riesgosOcupacionales.accidenteDeTrabajo
            : null,
        riesgosFisicos: !primerEmpleo
          ? opcionesSeleccionadas(listaRiesgosFisicos)
          : null,
        riesgosBiologicos: !primerEmpleo
          ? opcionesSeleccionadas(listaRiesgosBiologicos)
          : null,
        riesgosErgonomicos: !primerEmpleo
          ? opcionesSeleccionadas(listaRiesgosErgonomicos)
          : null,
        riesgosLocativos: !primerEmpleo
          ? opcionesSeleccionadas(listaRiesgosLocativos)
          : null,
        riesgosNaturales: !primerEmpleo
          ? opcionesSeleccionadas(listaRiesgosNaturales)
          : null,
        riesgosDeSeguridad: !primerEmpleo
          ? opcionesSeleccionadas(listaRiesgosSeguridad)
          : null,
        otrosRiesgos: !primerEmpleo
          ? opcionesSeleccionadas(listaOtrosRiesgos)
          : null,
      },
      estadoDeSaludActual: estadoDeSaludActual.sintomatologia,
      antecedentesPersonales: {
        observaciones:
          observacionesAntecedentesPersonales !== ''
            ? observacionesAntecedentesPersonales
            : null,
        listaAntecedentes: opcionesSeleccionadas(listaAntecedentes),
      },
      antecedentesGinecobstreticos: {
        gpa: antecedentesGinecobstetricos.gpa
          ? antecedentesGinecobstetricos.gpa
          : null,
        ultimoParto: antecedentesGinecobstetricos.ultimoParto
          ? antecedentesGinecobstetricos.ultimoParto
          : null,
        fum: antecedentesGinecobstetricos.fum
          ? antecedentesGinecobstetricos.fum
          : null,
        planificacion: antecedentesGinecobstetricos.planificacion
          ? antecedentesGinecobstetricos.planificacion
          : null,
      },
      antecedentesToxicologicos: {
        observaciones:
          observacionesAntecedentesToxicologicos !== ''
            ? observacionesAntecedentesToxicologicos
            : null,
        listaAntecedentes: opcionesSeleccionadas(
          listaAntecedentesToxicologicos
        ),
      },
      examenFisico: {
        peso: examenFisico.peso !== '' ? examenFisico.peso : null,
        talla: examenFisico.talla !== '' ? examenFisico.talla : null,
        imc: examenFisico.imc ? examenFisico.imc : null,
        fc: examenFisico.fc !== '' ? examenFisico.fc : null,
        ta: examenFisico.ta !== '' ? examenFisico.ta : null,
        clasificacionId: examenFisico.clasificacion
          ? (
              await ObtenerClasificacionExamenFisicoPorNombre(
                examenFisico.clasificacion
              )
            ).Id
          : null,
        hallazgos:
          examenFisico.hallazgos !== '' ? examenFisico.hallazgos : null,
        estadoTinnelDerechoId:
          listaTinnelYPhalen.tinnelDerecho !== 0
            ? listaTinnelYPhalen.tinnelDerecho
            : null,
        estadoTinnelIzquierdoId:
          listaTinnelYPhalen.tinnelIzquierdo !== 0
            ? listaTinnelYPhalen.tinnelIzquierdo
            : null,
        estadoPhalenDerechoId:
          listaTinnelYPhalen.phalenDerecho !== 0
            ? listaTinnelYPhalen.phalenDerecho
            : null,
        estadoPhalenIzquierdoId:
          listaTinnelYPhalen.phalenIzquierdo !== 0
            ? listaTinnelYPhalen.phalenIzquierdo
            : null,
        organosOSistemas: organosSeleccionados(listaOrganos),
      },
      examenesParaclinicos:
        examenesParaclinicos !== '' ? examenesParaclinicos : null,
      diagnostico: [
        diagnosticos.diagnostico1,
        diagnosticos.diagnostico2 ? diagnosticos.diagnostico2 : '',
        diagnosticos.diagnostico3 ? diagnosticos.diagnostico3 : '',
        diagnosticos.diagnostico4 ? diagnosticos.diagnostico4 : '',
        diagnosticos.diagnostico5 ? diagnosticos.diagnostico5 : '',
      ],
      conceptoDeAptitud: {
        ConceptoDeAptitudId: conceptoAptitud.conceptoAptitud
          ? conceptoAptitud.conceptoAptitud
          : null,
        observaciones: conceptoAptitud.descripcionConceptoAptitud
          ? conceptoAptitud.descripcionConceptoAptitud
          : null,
      },
      recomendaciones: opcionesSeleccionadas(listaRecomendaciones),
      vigilanciaEpidemiologica: opcionesSeleccionadas(
        listaVigilanciasEpidemiologicas
      ),
      restricciones: opcionesSeleccionadas(listaRestricciones),
    }
    return payloadExamenPreIngreso
  }

  const guardadoParcial = async () => {
    let payload = await generarPayload()
    FachadaGuardadoParcialExamenPreIngreso(payload)
      .then(() => {
        contextoMedicoOcupacional.setHabilitarImprimirCertificado(true)
        contextoAplicacion.setModal({
          abierto: true,
          titulo: (
            <div className="w-full flex justify-center items-center flex-wrap ">
              <span className="font-bold w-full my-1">
                LA HISTORIA DEL EXAMEN
              </span>
              <span className="font-bold w-full my-1">
                MEDICO OCUPACIONAL DE PRE INGRESO
              </span>
            </div>
          ),
          contenido: (
            <span className="w-full">HA SIDO GUARDADA PARCIALMENTE</span>
          ),
          botones: [
            {
              nombre: 'Finalizar',
              click: manejarFinalizar,
            },
            {
              nombre: 'Regresar a agenda',
              click: manejarIrAAgendaMedico,
            },
            {
              nombre: TEXTO_VISUAL.BOTONES.REGRESAR_A_EXAMEN,
              click: manejaCerrarModal,
            },
          ],
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const abrirModalCerrarHistoriaClinica = async () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <div className="w-full flex justify-center items-center flex-wrap ">
          <span className="font-bold w-full my-1">
            ¿DESEA CERRAR LA HISTORIA DEL EXAMEN
          </span>
          <span className="font-bold w-full my-1">
            MEDICO OCUPACIONAL DE PRE INGRESO?
          </span>
        </div>
      ),
      botones: [
        {
          nombre: 'Si',
          click: cerrarHistoriaClinica,
        },
        {
          nombre: 'No',
          click: manejaCerrarModal,
        },
      ],
    })
  }

  const cerrarHistoriaClinica = async () => {
    let payload = await generarPayload()
    FachadaCerrarHistoriaExamenPreIngreso(payload)
      .then((res) => {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: (
            <div className="w-full flex justify-center items-center flex-wrap ">
              <span className="font-bold w-full my-1">
                LA HISTORIA DEL EXAMEN
              </span>
              <span className="font-bold w-full my-1">
                MEDICO OCUPACIONAL DE PREINGRESO HA SIDO CERRADA
              </span>
              <span className="font-bold w-full my-1">EXITOSAMENTE</span>
            </div>
          ),
          botones: [
            {
              nombre: 'Finalizar',
              click: manejarFinalizarCerrarHistoria,
            },
            {
              nombre: 'Imprimir Certificado',
              click: imrpimirCertificado,
            },
            {
              nombre: 'Regresar a agenda',
              click: manejarIrAAgendaMedicoCerrarHistoria,
            },
          ],
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const imrpimirCertificado = () => {
    FachadaGenerarCertificadoExamenPreIngreso(state.servicioAtencionId)
      .then((res) => {
        window.open(res.data.replace(/['"]+/g, ''), '_blank').focus()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const botonesFormulario = [
    {
      nombre: 'Guardar Toma Datos',
      funcion: null,
      habilitado: contextoMedicoOcupacional.estadoBotones.tomaDatos,
    },
    {
      nombre: 'Guardar Parcial',
      funcion: guardadoParcial,
      habilitado: contextoMedicoOcupacional.estadoBotones.guardadoParcial,
    },
    {
      nombre: 'Cerrar Historia',
      funcion: abrirModalCerrarHistoriaClinica,
      habilitado:
        contextoMedicoOcupacional.totalPaginas ==
        contextoMedicoOcupacional.numeroPagina,
    },
    {
      nombre: 'Imprimir Certificado',
      funcion: imrpimirCertificado,
      habilitado: contextoMedicoOcupacional.habilitarImprimirCertificado,
    },
    {
      nombre: 'Imprimir Historia',
      funcion: null,
      habilitado: contextoMedicoOcupacional.estadoBotones.imprimirHistoria,
    },
  ]
  const botonesLaterales = [
    {
      nombre: 'Generar Formula',
      funcion: null,
      imagen: IconoGenerarFormula,
      habilitado: contextoMedicoOcupacional.estadoBotones.tomaDatos,
    },
    {
      nombre: 'Generar Interconsulta',
      funcion: null,
      imagen: IconoGenerarInterconsulta,
      habilitado: contextoMedicoOcupacional.estadoBotones.tomaDatos,
    },
  ]

  useEffect(() => {
    ObtenerExamenPorServicioAtencionId(state.servicioAtencionId)
      .then(async (res) => {
        if (res.data) {
          contextoMedicoOcupacional.setHabilitarImprimirCertificado(true)
          contextoMedicoOcupacional.setDatosEmpresa({
            turnoTrabajo: res.data.turnoTrabajoId,
          })

          contextoMedicoOcupacional.setRiesgosOcupacionales({
            accidenteDeTrabajo:
              res.data.antecedentesOcupacionales.accidenteDeTrabajo,
            antecedentesOcupacionales:
              res.data.antecedentesOcupacionales.tieneAntecedentes == true
                ? '1'
                : '2',
            antecedentesTiempoAnoMes:
              res.data.antecedentesOcupacionales
                .descripcionAntecedentesOcupacionales,
            enfermedadProfesional:
              res.data.antecedentesOcupacionales.enfermedadProfesional,
          })

          if (res.data.antecedentesOcupacionales.tieneAntecedentes == false) {
            contextoMedicoOcupacional.setPrimerEmpleo(true)
            contextoMedicoOcupacional.setRiesgoSeleccionado(true)
          }

          contextoMedicoOcupacional.setEstadoDeSaludActual({
            sintomatologia: res.data.estadoDeSaludActual,
          })

          contextoMedicoOcupacional.setListaRiesgosFisicos(
            (await ObtenerRiesgosFisicos()).map((elemento) => {
              let riesgo =
                res.data.antecedentesOcupacionales.riesgosFisicos.find(
                  (riesgo) => riesgo.observacionId == elemento.Id
                )
              if (riesgo) contextoMedicoOcupacional.setRiesgoSeleccionado(true)
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: riesgo ? true : false,
                Observaciones:
                  riesgo && riesgo.observaciones !== null
                    ? riesgo.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaRiesgosBiologicos(
            (await ObtenerRiesgosBiologicos()).map((elemento) => {
              let riesgo =
                res.data.antecedentesOcupacionales.riesgosBiologicos.find(
                  (riesgo) => riesgo.observacionId == elemento.Id
                )
              if (riesgo) contextoMedicoOcupacional.setRiesgoSeleccionado(true)
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: riesgo ? true : false,
                Observaciones:
                  riesgo && riesgo.observaciones !== null
                    ? riesgo.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaRiesgosErgonomicos(
            (await ObtenerRiesgosErgonomicos()).map((elemento) => {
              let riesgo =
                res.data.antecedentesOcupacionales.riesgosErgonomicos.find(
                  (riesgo) => riesgo.observacionId == elemento.Id
                )
              if (riesgo) contextoMedicoOcupacional.setRiesgoSeleccionado(true)
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: riesgo ? true : false,
                Observaciones:
                  riesgo && riesgo.observaciones !== null
                    ? riesgo.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaRiesgosLocativos(
            (await ObtenerRiesgosLocativos()).map((elemento) => {
              let riesgo =
                res.data.antecedentesOcupacionales.riesgosLocativos.find(
                  (riesgo) => riesgo.observacionId == elemento.Id
                )
              if (riesgo) contextoMedicoOcupacional.setRiesgoSeleccionado(true)
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: riesgo ? true : false,
                Observaciones:
                  riesgo && riesgo.observaciones !== null
                    ? riesgo.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaRiesgosNaturales(
            (await ObtenerRiesgosNaturales()).map((elemento) => {
              let riesgo =
                res.data.antecedentesOcupacionales.riesgosNaturales.find(
                  (riesgo) => riesgo.observacionId == elemento.Id
                )
              if (riesgo) contextoMedicoOcupacional.setRiesgoSeleccionado(true)
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: riesgo ? true : false,
                Observaciones:
                  riesgo && riesgo.observaciones !== null
                    ? riesgo.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaRiesgosSeguridad(
            (await ObtenerRiesgosSeguridad()).map((elemento) => {
              let riesgo =
                res.data.antecedentesOcupacionales.riesgosDeSeguridad.find(
                  (riesgo) => riesgo.observacionId == elemento.Id
                )
              if (riesgo) contextoMedicoOcupacional.setRiesgoSeleccionado(true)
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: riesgo ? true : false,
                Observaciones:
                  riesgo && riesgo.observaciones !== null
                    ? riesgo.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaOtrosRiesgos(
            (await ObtenerOtrosRiesgos()).map((elemento) => {
              let riesgo = res.data.antecedentesOcupacionales.otrosRiesgos.find(
                (riesgo) => riesgo.observacionId == elemento.Id
              )
              if (riesgo) contextoMedicoOcupacional.setRiesgoSeleccionado(true)
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: riesgo ? true : false,
                Observaciones:
                  riesgo && riesgo.observaciones !== null
                    ? riesgo.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaAntecedentes(
            (await ObtenerAntecedentes()).map((elemento) => {
              let antecedente =
                res.data.antecedentesPersonales.listaAntecedentes.find(
                  (antecedente) => antecedente.observacionId == elemento.Id
                )
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: antecedente ? true : false,
                Observaciones:
                  antecedente && antecedente.observaciones !== null
                    ? antecedente.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setObservacionesAntecedentesPersonales(
            res.data.antecedentesPersonales.observaciones
          )

          contextoMedicoOcupacional.setAntecedentesGinecobstetricos({
            fum: res.data.antecedentesGinecobstreticos.fum,
            gpa: res.data.antecedentesGinecobstreticos.gpa,
            planificacion: res.data.antecedentesGinecobstreticos.planificacion,
            ultimoParto: res.data.antecedentesGinecobstreticos.ultimoParto,
          })

          if (
            contextoMedicoOcupacional.listaAntecedentesToxicologicos.length > 0
          ) {
            contextoMedicoOcupacional.setListaAntecedentesToxicologicos(
              contextoMedicoOcupacional.listaAntecedentesToxicologicos.map(
                (elemento) => {
                  let antecedente =
                    res.data.antecedentesToxicologicos.listaAntecedentes.find(
                      (antecedente) => antecedente.observacionId == elemento.Id
                    )
                  if (antecedente) {
                    return {
                      ...elemento,
                      EstaActivo: true,
                      Observaciones: antecedente.observaciones,
                    }
                  }
                  return elemento
                }
              )
            )
          }
          contextoMedicoOcupacional.setListaAntecedentesToxicologicos(
            (await ObtenerAntecedentesToxicologicos()).map((elemento) => {
              let antecedente =
                res.data.antecedentesToxicologicos.listaAntecedentes.find(
                  (antecedente) => antecedente.observacionId == elemento.Id
                )
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: antecedente ? true : false,
                Observaciones:
                  antecedente && antecedente.observaciones !== null
                    ? antecedente.observaciones
                    : '',
              }
            })
          )
          contextoMedicoOcupacional.setObservacionesAntecedentesToxicologicos(
            res.data.antecedentesToxicologicos.observaciones
          )

          contextoMedicoOcupacional.setExamenFisico({
            hallazgos: res.data.examenFisico.hallazgos,
            fc: res.data.examenFisico.fc,
            peso: res.data.examenFisico.peso,
            ta: res.data.examenFisico.ta,
            talla: res.data.examenFisico.talla,
            imc: res.data.examenFisico.imc,
            clasificacion: res.data.examenFisico.clasificacionId
              ? (
                  await ObtenerClasificacionExamenFisicoPorId(
                    res.data.examenFisico.clasificacionId
                  )
                ).Nombre
              : '',
          })

          contextoMedicoOcupacional.setListaTinnelYPhalen({
            phalenDerecho: res.data.examenFisico.estadoPhalenDerechoId,
            phalenIzquierdo: res.data.examenFisico.estadoPhalenIzquierdoId,
            tinnelDerecho: res.data.examenFisico.estadoTinnelDerechoId,
            tinnelIzquierdo: res.data.examenFisico.estadoTinnelIzquierdoId,
          })

          contextoMedicoOcupacional.setListaOrganos(
            (await ObtenerOrganosSistema()).map((elemento) => {
              let organo = res.data.examenFisico.organosOSistemas.find(
                (organo) => organo.organoSistemaId == elemento.Id
              )
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstadoOrganoId: organo ? organo.estadoId : 1,
                Observaciones: organo ? organo.observaciones : '',
              }
            })
          )

          contextoMedicoOcupacional.setExamenesParaclinicos(
            res.data.examenesParaclinicos
          )

          contextoMedicoOcupacional.setDiagnosticos({
            diagnostico1: res.data.diagnostico[0],
            diagnostico2: res.data.diagnostico[1],
            diagnostico3: res.data.diagnostico[2],
            diagnostico4: res.data.diagnostico[3],
            diagnostico5: res.data.diagnostico[4],
          })

          contextoMedicoOcupacional.setConceptoAptitud({
            conceptoAptitud: res.data.conceptoDeAptitud.conceptoDeAptitudId,
            descripcionConceptoAptitud:
              res.data.conceptoDeAptitud.observaciones,
          })

          contextoMedicoOcupacional.setListaRecomendaciones(
            (await ObtenerRecomendaciones()).map((elemento) => {
              let recomendacion = res.data.recomendaciones.find(
                (recomendacion) => recomendacion.observacionId == elemento.Id
              )
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: recomendacion ? true : false,
                Observaciones:
                  recomendacion && recomendacion.observaciones !== null
                    ? recomendacion.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaVigilanciasEpidemiologicas(
            (await ObtenerVigilanciasEpidemiologicas()).map((elemento) => {
              let programa = res.data.vigilanciaEpidemiologica.find(
                (programa) => programa.observacionId == elemento.Id
              )
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: programa ? true : false,
                Observaciones:
                  programa && programa.observaciones !== null
                    ? programa.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setListaRestricciones(
            (await ObtenerRestricciones()).map((elemento) => {
              let restriccion = res.data.restricciones.find(
                (restriccion) => restriccion.observacionId == elemento.Id
              )
              return {
                Nombre: elemento.Nombre,
                Id: elemento.Id,
                EstaActivo: restriccion ? true : false,
                Observaciones:
                  restriccion && restriccion.observaciones !== null
                    ? restriccion.observaciones
                    : '',
              }
            })
          )

          contextoMedicoOcupacional.setActualizar(
            !contextoMedicoOcupacional.actualizar
          )
        } else {
          await setValoresPorDefecto()
        }
      })
      .catch(async (error) => {
        console.log(error)
        await setValoresPorDefecto()
      })
  }, [])

  const setValoresPorDefecto = async () => {
    contextoMedicoOcupacional.setHabilitarImprimirCertificado(false)
    contextoMedicoOcupacional.setListaRiesgosFisicos(
      (await ObtenerRiesgosFisicos()).map((riesgo) => ({
        Nombre: riesgo.Nombre,
        Id: riesgo.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaRiesgosBiologicos(
      (await ObtenerRiesgosBiologicos()).map((riesgo) => ({
        Nombre: riesgo.Nombre,
        Id: riesgo.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaRiesgosErgonomicos(
      (await ObtenerRiesgosErgonomicos()).map((riesgo) => ({
        Nombre: riesgo.Nombre,
        Id: riesgo.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaRiesgosLocativos(
      (await ObtenerRiesgosLocativos()).map((riesgo) => ({
        Nombre: riesgo.Nombre,
        Id: riesgo.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaRiesgosNaturales(
      (await ObtenerRiesgosNaturales()).map((riesgo) => ({
        Nombre: riesgo.Nombre,
        Id: riesgo.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaRiesgosSeguridad(
      (await ObtenerRiesgosSeguridad()).map((riesgo) => ({
        Nombre: riesgo.Nombre,
        Id: riesgo.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaOtrosRiesgos(
      (await ObtenerOtrosRiesgos()).map((riesgo) => ({
        Nombre: riesgo.Nombre,
        Id: riesgo.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaAntecedentes(
      (await ObtenerAntecedentes()).map((x) => ({
        Nombre: x.Nombre,
        Id: x.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaAntecedentesToxicologicos(
      (await ObtenerAntecedentesToxicologicos()).map((x) => ({
        Nombre: x.Nombre,
        Id: x.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaOrganos(
      (await ObtenerOrganosSistema()).map((x) => ({
        Nombre: x.Nombre,
        Id: x.Id,
        EstadoOrganoId: 1,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaRecomendaciones(
      (await ObtenerRecomendaciones()).map((x) => ({
        Nombre: x.Nombre,
        Id: x.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaVigilanciasEpidemiologicas(
      (await ObtenerVigilanciasEpidemiologicas()).map((x) => ({
        Nombre: x.Nombre,
        Id: x.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )

    contextoMedicoOcupacional.setListaRestricciones(
      (await ObtenerRestricciones()).map((x) => ({
        Nombre: x.Nombre,
        Id: x.Id,
        EstaActivo: false,
        Observaciones: '',
      }))
    )
  }

  return (
    <>
      <div className="min-h-screen w-full flex flex-wrap items-center justify-center px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        <div className="flex w-93% h-full justify-center items-start flex-wrap">
          <div className="w-full flex flex-wrap justify-center items-center">
            <span className="  text-center text-21px leading-23px font-bold text-cendiatra m-25px ">
              EXAMEN MEDICO OCUPACIONAL DE PRE INGRESO
            </span>
          </div>
          <div className="bg-white w-full  rounded rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl">
            <div className="flex justify-end h-7 mr-3 ">
              <BotonConImagen
                imagen={imagenBotonCerrar}
                textoAlternativo={'botonCerrar'}
                estilosImagen={'w-7 h-7'}
                funcionAEjecutar={() =>
                  cambiarEstadoAtencion(state.servicioAtencionId, '/')
                }
              />
            </div>
            <div className="w-full flex justify-center items-center flex-wrap">
              <CuadroInformacionUsuario
                estilosContenedor={
                  'w-11/12 h-280 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 shadow-2xl '
                }
                titulo={'DATOS PACIENTE'}
                tituloImagen={'Fecha y hora de atención'}
                descripcionPrimeraLinea={fechaActual}
                descripcionSegundaLinea={horaActual}
                datos={
                  contextoMedicoOcupacional.informacionUsuario.datosUsuario
                }
                urlFoto={
                  contextoMedicoOcupacional.informacionUsuario.urlFotoUsuario
                }
              />
              <div
                className={`w-11/12 flex justify-center items-center mt-10 flex-wrap mx-43px`}
              >
                <BarraEncabezadosPestana
                  titulos={listaPestanas}
                  contexto={contextoMedicoOcupacional}
                  deshabilitarValidaciones={true}
                />

                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={1}>
                  <DatosEmpresa numeroPagina={1} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={2}>
                  <RiesgosOcupacionalesAnteriores numeroPagina={2} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={3}>
                  <EstadoDeSaludActual numeroPagina={3} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={4}>
                  <AntecedentesPersonales numeroPagina={4} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={5}>
                  <AntecedentesGinecobstetricos numeroPagina={5} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={6}>
                  <AntecedentesToxicologicos numeroPagina={6} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={7}>
                  <ExamenFisico numeroPagina={7} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={8}>
                  <ExamenesParaclinicos numeroPagina={8} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={9}>
                  <Diagnosticos numeroPagina={9} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={10}>
                  <ConceptoAptitud numeroPagina={10} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={11}>
                  <Recomendaciones numeroPagina={11} />
                </Pestana>
                <Pestana contexto={contextoMedicoOcupacional} numeroPagina={12}>
                  <VigilanciasEpidemiologicas numeroPagina={12} />
                </Pestana>
                <Pestana
                  contexto={contextoMedicoOcupacional}
                  numeroPagina={13}
                  deshabilitarBotonGuardar={true}
                >
                  <Restricciones numeroPagina={13} />
                </Pestana>
              </div>
              <BarraBotones
                estilosContenedor={
                  'w-11/12 py-2 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 my-5'
                }
                listaBotones={botonesFormulario}
                listaBotonesLaterales={botonesLaterales}
              />
              <div className="w-11/12 flex justify-center items-center flex-wrap ">
                <ListaFormularios
                  estilosContenedor={
                    'w-full flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 my-5'
                  }
                  datos={formularios}
                />

                <ContenedorDesplegable
                  titulo={'HISTORIA CLINICA PACIENTE'}
                  posicionTitulo={'justify-center'}
                  expandidoInicial={false}
                >
                  <TablaHistoriaClinica
                    data={
                      contextoMedicoOcupacional.listaServiciosHistoriaClinica
                    }
                  />
                </ContenedorDesplegable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExamenMedicoPreingreso
