import React from 'react'

export const IndicadorContador = ({titulo, valor}) => {
  return (
    <div className="flex flex-col align-top text-center w-2/12">
      <h5 className="text-cendiatra-verde-2">{titulo}</h5>
      <span className="text-cendiatra-rojo-1">{valor}</span>
    </div>
  )
}
