import React, {useState, useEffect, useContext} from 'react'
import TablaDeServicios from './TablaServicio'
import {ExportarServicios} from '../../microservicios'
import {ContextApplication} from '../../contexto'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {ExportarCSV} from '../Botones'
import {
  AgregarServiciosACache,
  ObtenerServiciosPorCodigo,
  ObtenerServiciosPorNombre,
  ObtenerTodosLosServicios,
} from '../../cache/servicios-cache'
import {ContenedorPagina} from '../paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes'
import {regexBasicoV1} from '../../constantes/regex'

const SeleccionarServicio = ({funcionCargarDatos}) => {
  const headers = [
    {label: 'Id', key: 'id'},
    {label: 'Codigo Interno', key: 'codigoInterno'},
    {label: 'CUPS', key: 'cups'},
    {label: 'Nombre', key: 'nombre'},
    {label: 'Cuenta De Ingreso', key: 'cuentaIngreso'},
    {label: 'Tiempo Duracion', key: 'tiempoDuracion'},
    {label: 'IVA', key: 'iva'},
    {label: 'Observaciones', key: 'observaciones'},
    {label: 'Clasificación de Servicio', key: 'clasificacion'},
  ]

  const ctx = useContext(ContextApplication)
  const [todosLosServicio, setTodosLosServicios] = useState([])
  const [datosServicios, setDatosServicios] = useState([])
  const [buscarCodigo, setBuscarCodigo] = useState('')
  const [opcionesFiltradasCodigo, setOpcionesFiltradasCodigo] = useState([])
  const [filtroActivo, setFiltroActivo] = useState(true)

  const [buscarNombreServicio, setBuscarNombreServicio] = useState('')
  const [opcionesFiltradasNombre, setOpcionesFiltradasNombre] = useState([])
  const columns = React.useMemo(() => [
    {
      Header: 'Código de servicio',
      accessor: 'CodigoCompleto',
      className: 'w-5/12 text-center',
    },
    {
      Header: 'Nombre del servicio',
      accessor: 'Nombre',
      className: 'w-5/12 text-center',
    },
  ])

  useEffect(async () => {
    ctx.setIsLoading(true)
    await AgregarServiciosACache()
    ObtenerTodosLosServicios()
      .then((res) => {
        if (res) {
          ctx.setIsLoading(false)
          setTodosLosServicios(res)
          setDatosServicios(res)
        }
      })
      .catch((error) => {
        console.warn(error)
        ctx.setIsLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validarCampo = (caracter) => {
    let Validacion = new RegExp(/[0-9--.\s]/, '')

    return !Validacion.test(caracter)
  }

  const filterCodes = (e) => {
    if (e.target.value === '') {
      setBuscarCodigo('')
      setBuscarNombreServicio('')
      setOpcionesFiltradasNombre([])
      setOpcionesFiltradasCodigo([])
      return
    }

    if (validarCampo(e.target.value.slice(-1))) {
      window.alert('Se aceptan numeros, puntos(.) y guiones(-)')
      return
    } else {
      setBuscarCodigo(e.target.value)
      if (e.target.value.length >= 2) {
        setBuscarNombreServicio('')
        ObtenerServiciosPorCodigo(e.target.value).then((res) => {
          setOpcionesFiltradasCodigo(res)
        })
      } else {
        setBuscarNombreServicio('')
        setOpcionesFiltradasNombre([])
        setOpcionesFiltradasCodigo([])
      }
    }
  }

  const validarCaracteres = (opcion) => {
    let Validacion = new RegExp(regexBasicoV1, '')
    return !Validacion.test(opcion)
  }

  const filterNames = (e) => {
    if (e.target.value === '') {
      setBuscarCodigo('')
      setBuscarNombreServicio('')
      setOpcionesFiltradasNombre([])
      setOpcionesFiltradasCodigo([])
      return
    }

    if (validarCaracteres(e.target.value.slice(-1))) {
      window.alert(TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1)
    } else {
      setBuscarNombreServicio(e.target.value)

      if (e.target.value.length >= 2) {
        setBuscarCodigo('')
        ObtenerServiciosPorNombre(e.target.value).then((res) => {
          setOpcionesFiltradasNombre(res)
        })
      } else {
        setBuscarCodigo('')
        setOpcionesFiltradasCodigo([])
        setOpcionesFiltradasNombre([])
      }
    }
  }

  const selectedIdOption = (option) => {
    setBuscarCodigo(option.filtro)
    const selectedService = todosLosServicio.filter(
      (element) => element.CodigoCompleto === option.filtro
    )
    setDatosServicios(selectedService)
    setFiltroActivo(false)
  }

  const selectedServiceNameOption = (option) => {
    setBuscarNombreServicio(option.filtro)
    const selectedService = todosLosServicio.filter(
      (element) => element.Nombre === option.filtro
    )
    setDatosServicios(selectedService)
    setFiltroActivo(false)
  }

  const resetFilter = () => {
    setDatosServicios(todosLosServicio)
    setBuscarNombreServicio('')
    setBuscarCodigo('')
    setFiltroActivo(true)
    setOpcionesFiltradasNombre([])
    setOpcionesFiltradasCodigo([])
  }

  const manejarObtenerDatosExportar = async () => {
    return (await ExportarServicios()).data
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.EJECUTIVO_COMERCIAL.VER_SERVICIOS}
    >
      <div className="flex justify-center items-center flex-wrap">
        <div className="rounded-md  flex justify-evenly flex-wrap w-11/12 ">
          <CampoFiltrarEspañol
            titulo={'Código de servicios'}
            estilosPersonalizados={'w-4/5 md:w-4/12 m-5'}
            estilosInput={'border-cendiatra'}
            placeholder={'(Autocompletar)'}
            tipo={'text'}
            valorDelCampoFiltro={buscarCodigo}
            desactivarOtroFiltro={''}
            informacionFiltrada={opcionesFiltradasCodigo}
            handleChange={filterCodes}
            handleOptionChange={selectedIdOption}
            id={1}
          />

          <CampoFiltrarEspañol
            titulo={'Nombre del servicio'}
            estilosPersonalizados={'w-4/5 md:w-4/12 m-5'}
            estilosInput={'border-cendiatra'}
            placeholder={'Descripción (Autocompletar y filtrar)'}
            tipo={'text'}
            valorDelCampoFiltro={buscarNombreServicio}
            desactivarOtroFiltro={''}
            informacionFiltrada={opcionesFiltradasNombre}
            handleChange={filterNames}
            handleOptionChange={selectedServiceNameOption}
            id={2}
          />

          <div className="w-1/12 flex justify-center items-center -mt-2">
            <button
              className="group relative w-20 h-9 mt-5  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              onClick={() => resetFilter()}
            >
              Limpiar
            </button>
          </div>

          <div className="w-4/5 md:w-2/12 flex justify-center flex-wrap">
            <div className="w-full flex justify-center items-center">
              <ExportarCSV
                encabezados={headers}
                obtenerDatos={manejarObtenerDatosExportar}
                nombreArchivo="Lista_De_Servicios.csv"
              ></ExportarCSV>
            </div>
            <div className="w-full text-center">
              <span className="text-gray-400 text-sm">
                Exportar listado servicios
              </span>
            </div>
          </div>
        </div>
        <TablaDeServicios
          data={datosServicios}
          columns={columns}
          funcion={funcionCargarDatos}
          filtroActivo={filtroActivo}
        />
      </div>
    </ContenedorPagina>
  )
}

export default SeleccionarServicio
