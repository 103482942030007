import React, {useState, useContext} from 'react'
import {BotonSimple} from '../../componentes/Botones'
import {AreaDeTexto} from '../../componentes/inputs'
import {ContextApplication} from '../../contexto'
import {CrearSolicitudAtencion} from '../../microservicios'

const MotivoSolicitudEdicionComponente = ({
  atencionId,
  serviciosAtencion = [],
  sedeId,
  funcionCerrar,
  history,
}) => {
  const contextoAplicacion = useContext(ContextApplication)
  const [motivo, setMotivo] = useState('')

  const manejarFinalizar = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/main')
  }
  const crearSolicitudDeEdicionDeServiciosAtencion = async () => {
    try {
      const response = await CrearSolicitudAtencion({
        atencionId,
        serviciosAtencion,
        sedeId,
        motivo,
      })
      if (response.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'Solicitud edición de exámenes enviada',
          contenido: (
            <>
              <span className="text-cendiatra w-full font-bold mb-1">
                ESPERE A QUE EL JEFE DE SEDE ACEPTE SU SOLICITUD
              </span>
              <span className="text-cendiatra w-full font-bold mt-1">
                PARA PODER EDITARLA
              </span>
            </>
          ),
          botones: [
            {
              nombre: 'Finalizar',
              click: manejarFinalizar,
            },
            {
              nombre: 'Regresar',
              click: () => {
                funcionCerrar()
                history.goBack()
              },
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full flex flex-wrap">
      <AreaDeTexto
        valor={motivo}
        titulo="Motivo*"
        cambioValor={(evt) => {
          setMotivo(evt.currentTarget?.value)
        }}
        estilosContenedor={'w-full'}
        estilosAreaDeTexto={`      ${
          !Boolean(motivo) ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
        } rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        placeholder="descripción"
      />
      <div className="w-full flex justify-center items-center mt-10">
        <BotonSimple
          texto={'Enviar'}
          estilosBoton={`w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            Boolean(motivo) ? ' bg-btnBg ' : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={crearSolicitudDeEdicionDeServiciosAtencion}
          deshabilitado={!Boolean(motivo)}
        />
        <BotonSimple
          texto={'Cerrar'}
          estilosBoton={
            'w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2  bg-btnBg '
          }
          funcion={funcionCerrar}
          deshabilitado={false}
        />
      </div>
    </div>
  )
}

export default MotivoSolicitudEdicionComponente
