import React, {useState, useMemo} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import FiltroDatosBasicosPaciente from '../../componentes/Filtros/FiltroDatosBasicosPaciente'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import TablaModificarAtencionEjecutada from '../../componentes/recepcionista/TablaModificarAtencionEjecutada'
import {FachadaObtenerAtencionesUsuario} from '../../microservicios/Fachada'
import moment from 'moment'
import {ObtenerTipoFacturacionPorId} from '../../cache/servicios-cache/TipoFacturacion'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const ModificarAtencionEjecutada = () => {
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const [datosExamenes, setDatosExamenes] = useState([])

  const sedeId = useMemo(
    () => fnObtenerInformacionSessionOLocalStorage('sedeId', rol),
    [rol]
  )

  const funcionBuscarInformacion = async (datos) => {
    try {
      setDatosExamenes([])

      const respuesta = await FachadaObtenerAtencionesUsuario({
        numeroDocumento: datos.numeroDocumento,
        nombre: datos.nombres,
        apellidos: datos.apellidos,
        sedeId,
        diasAtencion: 30,
      })
      if (respuesta.status === 200) {
        if (respuesta.data?.length) {
          setDatosExamenes(
            await Promise.all(
              respuesta.data.map(async (atencion) => {
                return {
                  atencionId: atencion?.atencionId ?? 0,
                  fechaAtencion:
                    moment(atencion?.fechaAtencion).format('DD/MM/YYYY') ?? '',
                  tipoFacturacion:
                    (
                      await ObtenerTipoFacturacionPorId(
                        atencion?.tipoFacturacion ?? 0
                      )
                    )?.Nombre ?? '',
                  documentosElectronicos: atencion?.documentosElectronicos,
                  numeroDocumento: atencion?.numeroDocumento ?? 0,

                  nombre: `${atencion?.nombre ?? ''} ${
                    atencion?.apellidos ?? ''
                  }`,
                  estadoAtencionId: atencion?.estadoAtencionId,
                }
              })
            )
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="w-full flex flex-wrap justify-center items-center">
        <ContenedorPagina
          tituloPagina={
            TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA
              .MODIFICAR_ATENCION_EJECUTADA.TITULO
          }
          mensaje={
            TEXTO_VISUAL.BARRA_NAVEGACION.RECEPCIONISTA
              .MODIFICAR_ATENCION_EJECUTADA.TEXTO_BARRA
          }
        >
          <div className="w-full pb-10">
            <FiltroDatosBasicosPaciente
              funcionBuscarInformacion={funcionBuscarInformacion}
              funcionLimpiarInformacion={() => setDatosExamenes([])}
            />
          </div>
          <div className="w-full">
            <TablaModificarAtencionEjecutada datosExamenes={datosExamenes} />
          </div>
        </ContenedorPagina>
      </div>
    </>
  )
}

export default ModificarAtencionEjecutada
