export const ROLES_PORTAL_CLIENTE = [
  {
    id: 'Administrativo RRHH',
    nombre: 'administrativo',
  },
  {
    id: 'Médico Ocupacional',
    nombre: 'medico',
  },
]
