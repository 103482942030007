import React, {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ObtenerCie10PorNombreOCodigo} from '../../../../cache/servicios-cache'
import CampoFiltrarEspañol from '../../../CampoFiltrarEspañol'
import {
  ContextApplication,
  ContextoMedicoOcupacional,
} from '../../../../contexto'
import {regexBasicoV1} from '../../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../../constantes'

const Diagnosticos = ({numeroPagina}) => {
  const {
    trigger,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    setError,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [])

  const contextoApp = useContext(ContextApplication)
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  const [diagnostico1Filtrado, setDiagnostico1Filtrado] = useState([])
  const [diagnostico2Filtrado, setDiagnostico2Filtrado] = useState([])
  const [diagnostico3Filtrado, setDiagnostico3Filtrado] = useState([])
  const [diagnostico4Filtrado, setDiagnostico4Filtrado] = useState([])
  const [diagnostico5Filtrado, setDiagnostico5Filtrado] = useState([])

  useEffect(() => {
    reset(contextoMedicoOcupacional.diagnosticos)
    trigger()
  }, [contextoMedicoOcupacional.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      trigger()
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, contextoMedicoOcupacional.numeroPagina])

  useEffect(() => {
    const subscription = watch(() => {
      contextoMedicoOcupacional.setDiagnosticos(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const manejarCambioDiagnostico = async (
    campo,
    funcionSet,
    valor,
    verificarErrores
  ) => {
    setValue(campo, valor)
    if (valor.length >= 3) {
      contextoApp.setIsLoading(true)
      funcionSet(await ObtenerCie10PorNombreOCodigo(valor))
      contextoApp.setIsLoading(false)
    } else {
      funcionSet([])
    }
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <Controller
        name="diagnostico1"
        control={control}
        rules={{
          required: true,
          pattern: regexBasicoV1,
          onChange: (e) => {
            manejarCambioDiagnostico(
              'diagnostico1',
              setDiagnostico1Filtrado,
              e.target.value
            )
          },
        }}
        render={({field: {onChange, value}}) => (
          <div className="flex flex-wrap w-full my-2">
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-full'}
              titulo={'DIAGNÓSTICO 1*'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'diagnostico1'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'Descripción'}
              desactivarOtroFiltro={false}
              valorDelCampoFiltro={value}
              handleChange={onChange}
              handleOptionChange={(e) => {
                manejarCambioDiagnostico(
                  'diagnostico1',
                  setDiagnostico1Filtrado,
                  e.filtro
                )
              }}
              informacionFiltrada={diagnostico1Filtrado}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.diagnostico1?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </div>
        )}
      />
      <Controller
        name="diagnostico2"
        control={control}
        rules={{
          required: false,
          pattern: regexBasicoV1,
          onChange: (e) => {
            manejarCambioDiagnostico(
              'diagnostico2',
              setDiagnostico2Filtrado,
              e.target.value
            )
          },
        }}
        render={({field: {onChange, value}}) => (
          <div className="flex flex-wrap w-full my-2">
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-full'}
              titulo={'DIAGNÓSTICO 2'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'diagnostico2'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'Descripción'}
              desactivarOtroFiltro={false}
              valorDelCampoFiltro={value}
              handleChange={onChange}
              handleOptionChange={(e) => {
                manejarCambioDiagnostico(
                  'diagnostico2',
                  setDiagnostico2Filtrado,
                  e.filtro
                )
              }}
              informacionFiltrada={diagnostico2Filtrado}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.diagnostico2?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </div>
        )}
      />
      <Controller
        name="diagnostico3"
        control={control}
        rules={{
          required: false,
          pattern: regexBasicoV1,
          onChange: (e) => {
            manejarCambioDiagnostico(
              'diagnostico3',
              setDiagnostico3Filtrado,
              e.target.value
            )
          },
        }}
        render={({field: {onChange, value}}) => (
          <div className="flex flex-wrap w-full my-2">
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-full'}
              titulo={'DIAGNÓSTICO 3'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'diagnostico3'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'Descripción'}
              desactivarOtroFiltro={false}
              valorDelCampoFiltro={value}
              handleChange={onChange}
              handleOptionChange={(e) => {
                manejarCambioDiagnostico(
                  'diagnostico3',
                  setDiagnostico3Filtrado,
                  e.filtro
                )
              }}
              informacionFiltrada={diagnostico3Filtrado}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.diagnostico3?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </div>
        )}
      />
      <Controller
        name="diagnostico4"
        control={control}
        rules={{
          required: false,
          pattern: regexBasicoV1,
          onChange: (e) => {
            manejarCambioDiagnostico(
              'diagnostico4',
              setDiagnostico4Filtrado,
              e.target.value
            )
          },
        }}
        render={({field: {onChange, value}}) => (
          <div className="flex flex-wrap w-full my-2">
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-full'}
              titulo={'DIAGNÓSTICO 4'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'diagnostico4'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'Descripción'}
              desactivarOtroFiltro={false}
              valorDelCampoFiltro={value}
              handleChange={onChange}
              handleOptionChange={(e) => {
                manejarCambioDiagnostico(
                  'diagnostico4',
                  setDiagnostico4Filtrado,
                  e.filtro
                )
              }}
              informacionFiltrada={diagnostico4Filtrado}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.diagnostico4?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </div>
        )}
      />
      <Controller
        name="diagnostico5"
        control={control}
        rules={{
          required: false,
          pattern: regexBasicoV1,
          onChange: (e) => {
            manejarCambioDiagnostico(
              'diagnostico5',
              setDiagnostico5Filtrado,
              e.target.value
            )
          },
        }}
        render={({field: {onChange, value}}) => (
          <div className="flex flex-wrap w-full my-2">
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-full'}
              titulo={'DIAGNÓSTICO 5'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'diagnostico5'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'Descripción'}
              desactivarOtroFiltro={false}
              valorDelCampoFiltro={value}
              handleChange={onChange}
              handleOptionChange={(e) => {
                manejarCambioDiagnostico(
                  'diagnostico5',
                  setDiagnostico5Filtrado,
                  e.filtro
                )
              }}
              informacionFiltrada={diagnostico5Filtrado}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.diagnostico5?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </div>
        )}
      />
    </div>
  )
}

export default Diagnosticos
