import React, {useState, useEffect} from 'react'
import {BotonSimple} from '../Botones'
import {AreaDeTexto} from '../inputs'
import {FachadaAgregarMotivo} from '../../microservicios'
import {RUTAS_APP, casosAtencionEjecutada} from '../../constantes'
import {ObtenerMotivoModificacionAtencionPorNombre} from '../../cache/servicios-cache'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const MotivoComponenteModificarAtencionEjecutada = ({
  funcionCerrar,
  tipoMotivo,
  datosAtencion,
  history,
}) => {
  const [valorMotivo, setValorMotivo] = useState('')
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const sede = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)

  const manejarIrAModificarAtencion = async () => {
    try {
      const respuesta = await FachadaAgregarMotivo({
        atencionId: datosAtencion?.atencionId ?? 0,
        sedeId: sede,
        tipoMotivoId:
          (
            await ObtenerMotivoModificacionAtencionPorNombre(tipoMotivo ?? '')
          )?.Id ?? 0,
        motivo: valorMotivo,
      })
      if (respuesta.status === 200) {
        funcionCerrar()
        history.push(
          tipoMotivo === casosAtencionEjecutada.MODIFICACION_EMPRESA_CARGO
            ? RUTAS_APP.RECEPCION.MODIFICAR_CANCELAR_ATENCION_EMPRESA_HIJA_CARGO
            : RUTAS_APP.RECEPCION.REGISTRAR_PACIENTE,
          {
            idAtencion: datosAtencion.atencionId,
            modificacion: true,
          }
        )
      }
    } catch (error) {
      console.log(error)
      funcionCerrar()
    }
  }

  return (
    <div className="w-full flex flex-wrap">
      <AreaDeTexto
        valor={valorMotivo}
        titulo="Motivo*"
        cambioValor={(evt) => {
          setValorMotivo(evt.currentTarget?.value)
        }}
        estilosContenedor={'w-full'}
        estilosAreaDeTexto={`      ${
          !valorMotivo ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
        } rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
        placeholder="descripción"
      />
      <div className="w-full flex justify-center items-center mt-10">
        <BotonSimple
          texto={'Continuar'}
          estilosBoton={`w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            Boolean(valorMotivo !== '')
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={manejarIrAModificarAtencion}
          deshabilitado={Boolean(valorMotivo === '')}
        />
        <BotonSimple
          texto={'Regresar'}
          estilosBoton={
            'w-28 h-12 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2  bg-btnBg '
          }
          funcion={funcionCerrar}
          deshabilitado={false}
        />
      </div>
    </div>
  )
}

export default MotivoComponenteModificarAtencionEjecutada
