import {useContext, useEffect, useState} from 'react'
import {ContextApplication, ContextoModales} from '../../../contexto'
import {Controller, useForm} from 'react-hook-form'
import {Input} from '../../inputs'
import moment from 'moment'
import {BotonSimple} from '../../Botones'
import {FachadaGenerarPDFIncapacidadMedica} from '../../../microservicios/Fachada'
import {
  GenerarIncapacidadMedica,
  ObtenerIncapacidadMedicaPorServicioAtencionId,
} from '../../../microservicios/Examen'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import {
  ObtenerCie11Lista,
  ObtenerCiePorId,
} from '../../../cache/servicios-cache'

export default function IncapacidadMedica() {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoModales = useContext(ContextoModales)
  const [informacion, setInformacion] = useState([])
  const [cie11, setCie11] = useState([])
  const [diffError, setDiffError] = useState(false)

  const [incapacidadTieneDatos, setIncapacidadTieneDatos] = useState(false)
  const {
    control,
    trigger,
    watch,
    setValue,
    formState: {isValid},
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  })

  const cieSeleccionado = watch('idCodigoCie')
  const valorCampoFiltro = watch('filtro.nombreCodigoCie')

  useEffect(() => {
    trigger()
    const obtenerCie10 = async () => {
      setCie11(await ObtenerCie11Lista())
    }
    const obtenerInformacionIncapacidadMedica = async () => {
      try {
        const response = await ObtenerIncapacidadMedicaPorServicioAtencionId(
          contextoModales.parametrosFormulario.servicioAtencionId
        )
        if (response.status === 204) {
          setIncapacidadTieneDatos(false)
        } else {
          const cie = await ObtenerCiePorId(response.data.idCodigoCie)
          const valoresFormulario = {
            idCodigoCie: cie?.Id ?? 0,
            filtro: {
              nombreCodigoCie: `${cie?.Cie10} - ${cie?.Nombre} ` ?? '',
            },
            fechaDesde: moment(response?.data?.fechaDesde).format('YYYY-MM-DD'),
            fechaHasta: moment(response?.data?.fechaHasta).format('YYYY-MM-DD'),
            numeroDias: response?.data?.numeroDias,
          }

          reset(valoresFormulario)
          contextoModales.setIncapacidadMedica({
            ...contextoModales.incapacidadMedica,
            valoresFormulario,
            guardado: true,
          })
          setIncapacidadTieneDatos(true)
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (!!contextoModales?.parametrosFormulario?.servicioAtencionId) {
      obtenerInformacionIncapacidadMedica()
    }
    obtenerCie10()

    return () => contextoModales.setIncapacidadMedica({})
  }, [])

  useEffect(() => {
    const subscripcion = watch(() => {
      let valoresFormulario = getValues()
      contextoModales.setIncapacidadMedica({
        ...contextoModales.incapacidadMedica,
        valoresFormulario,
      })
    })
    return () => subscripcion.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (!!valorCampoFiltro) {
      const obtenerOpciones = async () => {
        const opciones = cie11.filter(
          ({filtro}) =>
            filtro.toUpperCase().indexOf(valorCampoFiltro.toUpperCase()) > -1
        )
        setInformacion(opciones)
      }

      obtenerOpciones()
    }
  }, [valorCampoFiltro])

  const funcionCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const funcionGuardar = async () => {
    try {
      const formulario = getValues()

      let payload = {
        servicioAtencionId:
          contextoModales.parametrosFormulario.servicioAtencionId,
        idCodigoCie: formulario.idCodigoCie,
        numeroDias: formulario.numeroDias,
        fechaDesde: formulario.fechaDesde,
        fechaHasta: formulario.fechaHasta,
      }
      const respuesta = await GenerarIncapacidadMedica(payload)

      if (respuesta.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'Formato de incapacidad médica guardado',
          contenido: '',
          botones: [
            {
              nombre: 'Regresar',
              click: funcionCerrarModal,
            },
            {
              nombre: 'Imprimir',
              click: imprimirPDFIncapacidadMedica,
            },
          ],
        })
        contextoModales.setFormulaMedica({
          ...contextoModales.incapacidadMedica,
          guardado: true,
        })
        setIncapacidadTieneDatos(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const imprimirPDFIncapacidadMedica = async () => {
    try {
      const response = await FachadaGenerarPDFIncapacidadMedica(
        contextoModales.parametrosFormulario.servicioAtencionId
      )

      if (response.status === 200) {
        const fileURL = response.data
        window.open(fileURL, '_blank')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <div
        className={`w-8/12 pb-5 ${
          incapacidadTieneDatos ? 'pointer-events-none' : ''
        }`}
      >
        <div className="w-full pb-5 flex flex-wrap space-x-10">
          <Controller
            name="fechaDesde"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <Input
                estilosContenedor="w-4/12 text-left	"
                estilosInput={
                  error || diffError
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                mostrarErrores={true}
                tipo={'date'}
                titulo={'Fecha desde'}
                valor={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="fechaHasta"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <Input
                estilosContenedor="w-4/12 text-left	"
                estilosInput={
                  error || diffError
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                mostrarErrores={true}
                tipo={'date'}
                titulo={'Fecha hasta'}
                valor={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="numeroDias"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <Input
                estilosContenedor="w-2/12 text-left	"
                estilosInput={
                  error
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                mostrarErrores={true}
                titulo={'Días'}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-full">
          <Controller
            name="filtro.nombreCodigoCie"
            control={control}
            rules={{
              required: false,
            }}
            render={({field: {value}, fieldState: {error}}) => (
              <CampoFiltrarEspañol
                estilosPersonalizados={'w-full text-black'}
                estilosInput={`rounded relative block w-full border ${
                  !cieSeleccionado
                    ? 'border-cendiatra-rojo-1'
                    : 'border-cendiatra'
                } text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
                tipo={'text'}
                placeholder={'Autocompletar'}
                valorDelCampoFiltro={value}
                titulo={'Diagnóstico'}
                handleOptionChange={(e) => {
                  setValue('idCodigoCie', e?.id ?? e?.Id ?? '')
                  setValue(
                    'filtro.nombreCodigoCie',
                    e?.filtro ?? e?.Filtro ?? ''
                  )
                }}
                desactivarOtroFiltro={false}
                handleChange={(e) => {
                  setValue('filtro.nombreCodigoCie', e.target.value)
                  setValue('idCodigoCie', e.target.value)
                }}
                informacionFiltrada={informacion}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full">
        <BotonSimple
          texto={'Guardar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            isValid && !incapacidadTieneDatos && cieSeleccionado
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          funcion={funcionGuardar}
          deshabilitado={!isValid || incapacidadTieneDatos || diffError}
        />
        <BotonSimple
          texto={'Regresar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 bg-btnBg`}
          funcion={funcionCerrarModal}
        />
        <BotonSimple
          texto={'Imprimir'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
            incapacidadTieneDatos
              ? ' bg-btnBg '
              : ' bg-cendiatra-gris-placeholder'
          }`}
          deshabilitado={!incapacidadTieneDatos}
          funcion={imprimirPDFIncapacidadMedica}
        />
      </div>
    </div>
  )
}
