import React, {useContext, useMemo} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {InputNumerico, InputDecimal} from '../../inputs'

const CampoEntero = ({numeroCampo, numeroPestana, numeroSeccion, readOnly}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const valorCampoNumerico = parseFloat(
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? '0'
  )

  const valorCampoString =
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  const manejarCambioValor = (valor) => {
    if (readOnly) return

    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: valor?.floatValue ?? 0,
        },
      },
    })
  }

  return campo?.tituloCampo?.toUpperCase() !== 'TALLA' ? (
    <InputNumerico
      estilosContenedor={'w-full'}
      estilosInput={`appearance-none rounded relative block w-full p-1.5 border ${
        campo?.requerido &&
        (valorCampoNumerico === 0 || isNaN(valorCampoNumerico))
          ? 'border-cendiatra-rojo-1'
          : 'border-cendiatra'
      } rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm `}
      placeholder={''}
      titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
      deshabilitado={false}
      onValueChange={manejarCambioValor}
      valor={valorCampoNumerico}
      ayudaDeTexto={
        campo?.atributos?.unidadMedida ? (
          <p style={{textTransform: 'uppercase'}}>
            <span className="font-bold">Unidad medida: </span>
            {campo.atributos.unidadMedida}
          </p>
        ) : null
      }
      usarDecimal={!campo?.tituloCampo?.toUpperCase()?.includes('PESO')}
      readOnly={readOnly}
    />
  ) : (
    <InputDecimal
      ayudaDeTexto={
        campo?.atributos?.unidadMedida ? (
          <p style={{textTransform: 'uppercase'}}>
            <span className="font-bold">Unidad medida: </span>
            {campo.atributos.unidadMedida}
          </p>
        ) : null
      }
      titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
      estilosContenedor={'w-full'}
      estilosInput={`appearance-none rounded relative block w-full p-1.5 border ${
        campo?.requerido && valorCampoString === ''
          ? 'border-cendiatra-rojo-1'
          : 'border-cendiatra'
      } rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm `}
      onValueChange={manejarCambioValor}
      valor={valorCampoString}
      onBlur={(evt) => {
        const tamanoCadena = evt.target.value?.length ?? 0
        if (tamanoCadena !== 4) {
          if (tamanoCadena === 0) {
            evt.preventDefault()
          } else {
            manejarCambioValor({
              floatValue:
                evt.target.value
                  ?.concat(tamanoCadena === 3 ? '0' : '00')
                  ?.replace(',', '.') ?? '0.00',
            })
          }
        }
      }}
      readOnly={readOnly}
    />
  )
}

export default CampoEntero
