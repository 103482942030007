import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import TablaAgenda from '../../componentes/Agenda/TablaAgenda'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import {CeldaDobleTexto} from '../../componentes/Celdas/CeldaDobleTexto'
import ExamenesPaciente from '../../componentes/Agenda/ExamenesPaciente'
import CeldaTipoPaciente from '../../componentes/Agenda/CeldaTipoPaciente.jsx'
import {ContextApplication, ContextoAgendaRegistro} from '../../contexto'
import {
  ObtenerEmpresaPorId,
  AgregarEmpresasACache,
} from '../../cache/servicios-cache/Empresas'
import {ObtenerServicioPorId} from '../../cache/servicios-cache/Servicios'
import {ObtenerEstadoServicioAtencionPorId} from '../../cache/servicios-cache/EstadoServicioAtencion'
import moment from 'moment'
import {AgregarTarifariosACache} from '../../cache/servicios-cache/Tarifarios'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import {AgregarPaquetesACache} from '../../cache/servicios-cache/Paquetes'
import {FachadaObtenerAtencionesAbiertas} from '../../microservicios/Fachada'
import LeyendasAgenda from '../../componentes/Agenda/LeyendasAgenda'
import AtencionesAMostrar from '../../componentes/Agenda/AtencionesAMostrar'
import {LEYENDA_AGENDA} from '../../constantes/leyendaAgendas'
import {estadosExamenesConst} from '../../constantes/estadoExamen'
import {useConexionSignalR} from '../../hooks/signalR/useConexionSignalR'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL, TEXTO_AGENDAS} from '../../constantes/textoVisual'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils.js'

const AgendaRegistro = () => {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoAgendaRegistro = useContext(ContextoAgendaRegistro)
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const [atencionesDeHoy, setAtencionesDeHoy] = useState(true)

  const sedeId = parseInt(
    fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
  )

  const [montado, setMontado] = useState(false)

  useEffect(() => {
    setMontado(true)
    return () => setMontado(false)
  }, [])

  const manejarMostrarTipoAtenciones = () => {
    setAtencionesDeHoy(!atencionesDeHoy)
  }

  const manejarEstadoServicioAtencion = async (servicios) => {
    if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId === estadosExamenesConst.LLAMANDO
      )
    ) {
      return (
        (
          await ObtenerEstadoServicioAtencionPorId(
            servicios?.find(
              (servicio) =>
                servicio?.estadoServicioId === estadosExamenesConst.LLAMANDO
            )?.estadoServicioId ?? 0
          )
        )?.Nombre ?? ''
      )
    } else if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO &&
          servicio?.esLaboratorio
      )
    ) {
      return TEXTO_AGENDAS?.LABORATORIO_CLINICO
    } else if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO
      )
    ) {
      return (
        (
          await ObtenerServicioPorId(
            servicios?.find(
              (servicio) =>
                servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO
            )?.servicioId
          )
        )?.Nombre ?? ''
      )
    } else if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId ===
            estadosExamenesConst.IMPRIMIENDO_STICKER && servicio?.esLaboratorio
      )
    ) {
      return TEXTO_AGENDAS?.LABORATORIO_CLINICO
    } else {
      return TEXTO_AGENDAS.EN_SALA
    }
  }
  const validarExistenciaAlias = async (servicio) => {
    if (servicio.alias) {
      return servicio.alias ?? ''
    } else {
      return (await ObtenerServicioPorId(servicio.servicioId))?.Nombre ?? ''
    }
  }

  const validarNombreMedico = (servicios, nombreMedico) => {
    const servicioLlamando = servicios.find(
      (servicio) => servicio.estadoServicioId === estadosExamenesConst.LLAMANDO
    )
    if (servicioLlamando) {
      return servicioLlamando?.medicoLlamando?.nombre
    }
    const servicioEnProceso = servicios.find(
      (servicio) =>
        servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO ||
        servicio?.estadoServicioId === estadosExamenesConst.IMPRIMIENDO_STICKER
    )
    if (servicioEnProceso) {
      return servicioEnProceso?.nombreMedico
    }
    return ''
  }

  const obtenerInformacionAgenda = useCallback(async () => {
    FachadaObtenerAtencionesAbiertas(true, sedeId).then(async (res) => {
      if (res.status == 200) {
        contextoAgendaRegistro.setAtencionesDiaActual(
          ordenarListaAtenciones(
            await Promise.all(
              res.data.map(async (atencion) => ({
                id: atencion.atencionId,
                numeroOrdenyFechaCreacion: {
                  Fila1: moment(atencion.fechaCreacion).format('LT'),
                  Fila2: moment(atencion.fechaCreacion).format('DD/MM/YYYY'),
                },
                nombresyDocumento: {
                  Fila1: atencion.nombreCompleto.toUpperCase(),
                  Fila2: atencion.numeroDocumento,
                },
                empresa: (
                  await ObtenerEmpresaPorId(atencion.empresaId)
                ).Nombre.toUpperCase(),
                tipoPaciente: atencion.tipoPacienteId,
                examenes: await Promise.all(
                  atencion.servicios.map(async (servicio) => {
                    return {
                      tipoExamen: servicio.esLaboratorio
                        ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                        : await validarExistenciaAlias(servicio),
                      estadoExamen: servicio.estadoServicioId,
                      medico:
                        servicio.estadoServicioId ===
                          estadosExamenesConst.PENDIENTE_POR_CERRAR ||
                        servicio.estadoServicioId ===
                          estadosExamenesConst.EN_PROCESO
                          ? (servicio.informacionUltimoGuardado?.tipoGuardado ??
                              '') +
                            ' ' +
                            (servicio.informacionUltimoGuardado?.nombreMedico ??
                              servicio.nombreMedico ??
                              '')
                          : '',
                      enCurso: servicio.enCurso,
                      servicioAtencionId: servicio.servicioAtencionId,
                      esLaboratorio: servicio.esLaboratorio,
                      esVacunacion: servicio.esVacunacion,
                    }
                  })
                ),
                ocupadoPor: {
                  Fila1: validarNombreMedico(
                    atencion.servicios,
                    atencion.nombreMedico
                  ),
                  Fila2: await manejarEstadoServicioAtencion(
                    atencion.servicios
                  ),
                },
                fechaCreacion: atencion?.fechaCreacion,
                fechaUltimoLlamado: atencion.fechaUltimoLLamado,
              }))
            )
          )
        )
      } else {
        contextoAgendaRegistro.setAtencionesDiaActual([])
      }
    })

    FachadaObtenerAtencionesAbiertas(false, sedeId).then(async (res) => {
      if (res.status == 200) {
        contextoAgendaRegistro.setAtencionesPasadas(
          ordenarListaAtenciones(
            await Promise.all(
              res.data.map(async (atencion) => ({
                id: atencion.atencionId,
                numeroOrdenyFechaCreacion: {
                  Fila1: moment(atencion.fechaCreacion).format('LT'),
                  Fila2: moment(atencion.fechaCreacion).format('DD/MM/YYYY'),
                },
                nombresyDocumento: {
                  Fila1: atencion.nombreCompleto.toUpperCase(),
                  Fila2: atencion.numeroDocumento,
                },
                empresa: (
                  await ObtenerEmpresaPorId(atencion.empresaId)
                ).Nombre.toUpperCase(),
                tipoPaciente: atencion.tipoPacienteId,
                examenes: await Promise.all(
                  atencion.servicios.map(async (servicio) => {
                    return {
                      tipoExamen: servicio.esLaboratorio
                        ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                        : await validarExistenciaAlias(servicio),
                      estadoExamen: servicio.estadoServicioId,
                      medico:
                        servicio.estadoServicioId ===
                          estadosExamenesConst.PENDIENTE_POR_CERRAR ||
                        servicio.estadoServicioId ===
                          estadosExamenesConst.EN_PROCESO
                          ? (servicio.informacionUltimoGuardado?.tipoGuardado ??
                              '') +
                            ' ' +
                            (servicio.informacionUltimoGuardado?.nombreMedico ??
                              servicio.nombreMedico ??
                              '')
                          : '',
                      enCurso: servicio.enCurso,
                      servicioAtencionId: servicio.servicioAtencionId,
                      esLaboratorio: servicio.esLaboratorio,
                      esVacunacion: servicio.esVacunacion,
                    }
                  })
                ),
                ocupadoPor: {
                  Fila1: '',
                  Fila2: 'PENDIENTE POR CERRAR',
                },
                fechaCreacion: atencion?.fechaCreacion,
                fechaUltimoLlamado: atencion.fechaUltimoLLamado,
              }))
            )
          )
        )
      } else {
        contextoAgendaRegistro.setAtencionesPasadas([])
      }
    })
  }, [])

  useEffect(() => {
    let componenteMontado = true
    const AgregarDatosACache = async () => {
      await AgregarEmpresasACache()
      await AgregarTarifariosACache()
      await AgregarServiciosACache()
      await AgregarPaquetesACache()
      if (componenteMontado) {
        manejarObtenerDatos().catch((error) => {
          console.log(error)
        })
      }
    }
    AgregarDatosACache()
    return () => {
      componenteMontado = false
    }
  }, [])

  const manejarObtenerDatos = useCallback(async () => {
    obtenerInformacionAgenda()
  }, [])

  const ordenarListaAtenciones = (listaAtenciones) => {
    let listaAtencionesOrdenada = []

    listaAtencionesOrdenada = listaAtenciones.sort((a, b) => {
      if (a.tipoPaciente == 1 && b.tipoPaciente == 2 && a.id - b.id) {
        return 1
      }
      if (a.tipoPaciente == 1 && b.tipoPaciente == 3 && a.id - b.id) {
        return 1
      }
      if (a.tipoPaciente == 2 && b.tipoPaciente == 1 && b.id - a.id) {
        return -1
      }
      if (a.tipoPaciente == 2 && b.tipoPaciente == 3 && b.id - a.id) {
        return -1
      }
      if (a.tipoPaciente == 3 && b.tipoPaciente == 1 && b.id - a.id) {
        return -1
      }
      if (a.tipoPaciente == 3 && b.tipoPaciente == 2 && a.id - b.id) {
        return 1
      }
      return 0
    })
    return listaAtencionesOrdenada
  }
  const manejarAgregarAtencionSede = async (message) => {
    let mensajeAtencionAgregado = JSON.parse(message)

    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensajeAtencionAgregado.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        await Promise.all(
          contextoAgendaRegistro.atencionesDiaActual.map(async (atencion) => {
            if (atencion.id === mensajeAtencionAgregado.atencionId) {
              const nuevosExamenes = await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (laboratorio) => {
                  return {
                    tipoExamen: await validarExistenciaAlias(laboratorio),
                    estadoExamen: laboratorio.estadoServicioId,
                    medico: laboratorio.nombreMedico,
                    enCurso: laboratorio.enCurso,
                    especialidadMedico: true,
                    servicioId: laboratorio.servicioId,
                    servicioAtencionId: laboratorio.servicioAtencionId,
                  }
                })
              )

              return {
                ...atencion,
                examenes: nuevosExamenes,
              }
            }
            return atencion
          })
        )
      )
      return
    }

    if (
      contextoAgendaRegistro.atencionesPasadas.find(
        (x) => x.id == mensajeAtencionAgregado.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesPasadas(
        await Promise.all(
          contextoAgendaRegistro.atencionesPasadas.map(async (atencion) => {
            if (atencion.id === mensajeAtencionAgregado.atencionId) {
              const nuevosExamenes = await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (laboratorio) => {
                  return {
                    tipoExamen: await validarExistenciaAlias(laboratorio),
                    estadoExamen: laboratorio.estadoServicioId,
                    medico: laboratorio.nombreMedico,
                    enCurso: laboratorio.enCurso,
                    especialidadMedico: true,
                    servicioId: laboratorio.servicioId,
                    servicioAtencionId: laboratorio.servicioAtencionId,
                  }
                })
              )

              return {
                ...atencion,
                examenes: nuevosExamenes,
              }
            }
            return atencion
          })
        )
      )
      return
    }
    let atencionAAgregar = {
      id: mensajeAtencionAgregado.atencionId,
      numeroOrdenyFechaCreacion: {
        Fila1: moment(mensajeAtencionAgregado.fechaCreacion).format('LT'),
        Fila2: moment(mensajeAtencionAgregado.fechaCreacion).format(
          'DD/MM/YYYY'
        ),
      },
      nombresyDocumento: {
        Fila1: mensajeAtencionAgregado.nombreCompleto.toUpperCase(),
        Fila2: mensajeAtencionAgregado.numeroDocumento,
      },
      empresa: (
        await ObtenerEmpresaPorId(mensajeAtencionAgregado.empresaId)
      ).Nombre.toUpperCase(),
      tipoPaciente: mensajeAtencionAgregado.tipoPacienteId,
      examenes: await Promise.all(
        mensajeAtencionAgregado.servicios.map(async (servicio) => {
          return {
            tipoExamen: servicio.esLaboratorio
              ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
              : await validarExistenciaAlias(servicio),
            estadoExamen: servicio.estadoServicioId,
            medico:
              servicio.estadoServicioId ===
                estadosExamenesConst.PENDIENTE_POR_CERRAR ||
              servicio.estadoServicioId === estadosExamenesConst.EN_PROCESO
                ? (servicio.informacionUltimoGuardado?.tipoGuardado ?? '') +
                  ' ' +
                  (servicio.informacionUltimoGuardado?.nombreMedico ??
                    servicio.nombreMedico ??
                    '')
                : '',
            enCurso: servicio.enCurso,
            servicioAtencionId: servicio.servicioAtencionId,
            esLaboratorio: servicio.esLaboratorio,
            esVacunacion: servicio.esVacunacion,
          }
        })
      ),
      ocupadoPor: {
        Fila1: '',
        Fila2: await manejarEstadoServicioAtencion(
          mensajeAtencionAgregado.servicios
        ),
      },
      fechaCreacion: mensajeAtencionAgregado?.fechaCreacion,
      fechaUltimoLlamado: mensajeAtencionAgregado?.fechaUltimoLLamado,
    }

    const fechaActual = moment().format('YYYY/MM/DD')
    const fechaAtencion = moment(mensajeAtencionAgregado.fechaCreacion).format(
      'YYYY/MM/DD'
    )
    if (fechaActual > fechaAtencion) {
      contextoAgendaRegistro.setAtencionesPasadas((prevLaboratorios) =>
        ordenarListaAtenciones([...prevLaboratorios, atencionAAgregar])
      )
    } else {
      contextoAgendaRegistro.setAtencionesDiaActual((prevLaboratorios) =>
        ordenarListaAtenciones([...prevLaboratorios, atencionAAgregar])
      )
    }
  }

  const manejarServicioAtencionEnProceso = async (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        ordenarListaAtenciones(
          contextoAgendaRegistro.atencionesDiaActual.map((atencion) => {
            if (atencion.id == mensaje.atencionId) {
              return {
                ...atencion,
                examenes: atencion.examenes.map((examen) => {
                  if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                    return {
                      ...examen,
                      estadoExamen: estadosExamenesConst.EN_PROCESO,
                      medico: mensaje.nombreMedico,
                    }
                  }
                  return examen
                }),
                ocupadoPor: {
                  Fila1: mensaje.nombreMedico,
                  Fila2:
                    atencion.examenes
                      .find(
                        (examen) =>
                          examen.servicioAtencionId ==
                          mensaje.servicioAtencionId
                      )
                      .tipoExamen?.toUpperCase() ?? '',
                },
                fechaUltimoLlamado: moment().format(),
              }
            }
            return atencion
          })
        )
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        ordenarListaAtenciones(
          contextoAgendaRegistro.atencionesPasadas.map((atencion) => {
            if (atencion.id == mensaje.atencionId) {
              return {
                ...atencion,
                examenes: atencion.examenes.map((examen) => {
                  if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                    return {
                      ...examen,
                      estadoExamen: estadosExamenesConst.PENDIENTE_POR_CERRAR,
                    }
                  }
                  return examen
                }),
              }
            }
            return atencion
          })
        )
      )
    }
  }
  const manejarServicionAtencionFinalizadoSede = (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        ordenarListaAtenciones(
          contextoAgendaRegistro.atencionesDiaActual.map((atencion) => {
            if (atencion.id == mensaje.atencionId) {
              return {
                ...atencion,
                examenes: atencion.examenes.map((examen) => {
                  if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                    return {
                      ...examen,
                      estadoExamen: estadosExamenesConst.CERRADO,
                      medico: '',
                    }
                  }
                  return examen
                }),
                ocupadoPor: {
                  Fila1: '',
                  Fila2: TEXTO_AGENDAS.EN_SALA,
                },
              }
            }
            return atencion
          })
        )
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        contextoAgendaRegistro.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensaje.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                    medico: '',
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarServicioAtencionPendientePorCerrarSede = async (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaRegistro.atencionesDiaActual.map(async (atencion) => {
              if (atencion.id == mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: await Promise.all(
                    mensaje.servicios.map(async (servicio) => {
                      return {
                        tipoExamen: servicio.esLaboratorio
                          ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                          : await validarExistenciaAlias(servicio),
                        estadoExamen: servicio.estadoServicioId,
                        medico:
                          servicio.estadoServicioId ===
                            estadosExamenesConst.PENDIENTE_POR_CERRAR ||
                          servicio.estadoServicioId ===
                            estadosExamenesConst.EN_PROCESO
                            ? (servicio.informacionUltimoGuardado
                                ?.tipoGuardado ?? '') +
                              ' ' +
                              (servicio.informacionUltimoGuardado
                                ?.nombreMedico ??
                                servicio.nombreMedico ??
                                '')
                            : '',
                        enCurso: servicio.enCurso,
                        especialidadMedico: false,
                        servicioAtencionId: servicio.servicioAtencionId,
                        esLaboratorio: servicio.esLaboratorio,
                        esVacunacion: servicio.esVacunacion,
                      }
                    })
                  ),
                  ocupadoPor: {
                    Fila1: '',
                    Fila2: TEXTO_AGENDAS.EN_SALA,
                  },
                  fechaUltimoLlamado: moment().format(),
                }
              }
              return atencion
            })
          )
        )
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaRegistro.atencionesPasadas.map(async (atencion) => {
              if (atencion.id == mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: await Promise.all(
                    mensaje.servicios.map(async (servicio) => {
                      return {
                        tipoExamen: servicio.esLaboratorio
                          ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                          : await validarExistenciaAlias(servicio),
                        estadoExamen: servicio.estadoServicioId,
                        medico:
                          servicio.estadoServicioId ===
                            estadosExamenesConst.PENDIENTE_POR_CERRAR ||
                          servicio.estadoServicioId ===
                            estadosExamenesConst.EN_PROCESO
                            ? (servicio.informacionUltimoGuardado
                                ?.tipoGuardado ?? '') +
                              ' ' +
                              (servicio.informacionUltimoGuardado
                                ?.nombreMedico ??
                                servicio.nombreMedico ??
                                '')
                            : '',
                        enCurso: servicio.enCurso,
                        especialidadMedico: false,
                        servicioAtencionId: servicio.servicioAtencionId,
                        esLaboratorio: servicio.esLaboratorio,
                        esVacunacion: servicio.esVacunacion,
                      }
                    })
                  ),
                }
              }
              return atencion
            })
          )
        )
      )
    }
  }

  const manejarAtencionFinalizadaSede = (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      let atencionesfiltradas =
        contextoAgendaRegistro.atencionesDiaActual.filter(
          (x) => x.id != mensaje.atencionId
        )
      contextoAgendaRegistro.setAtencionesDiaActual(atencionesfiltradas)
    } else {
      let atencionesfiltradas = contextoAgendaRegistro.atencionesPasadas.filter(
        (x) => x.id != mensaje.atencionId
      )
      contextoAgendaRegistro.setAtencionesPasadas(atencionesfiltradas)
    }
  }

  const manejarAtencionLlamandoPaciente = (message) => {
    let mensaje = JSON.parse(message)

    const fechaActual = moment().format('YYYY/MM/DD')
    const fechaAtencion = moment(mensaje.fechaCreacion).format('YYYY/MM/DD')

    if (fechaActual === fechaAtencion) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        contextoAgendaRegistro.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensaje.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.LLAMANDO,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: mensaje.nombreMedico,
                Fila2: 'Llamando paciente...',
              },
              fechaUltimoLlamado: moment().format(),
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        contextoAgendaRegistro.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensaje.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.LLAMANDO,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarAtencionPendientePorAtender = async (message) => {
    let mensajeAtencionAgregado = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensajeAtencionAgregado.atencionId
      )
    ) {
      let atenciones = await Promise.all(
        contextoAgendaRegistro.atencionesDiaActual.map(async (atencion) => {
          if (atencion.id === mensajeAtencionAgregado.atencionId) {
            return {
              ...atencion,
              examenes: await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (servicio) => {
                  return {
                    tipoExamen: servicio.esLaboratorio
                      ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                      : await validarExistenciaAlias(servicio),
                    estadoExamen: servicio.estadoServicioId,
                    medico:
                      servicio.estadoServicioId ===
                        estadosExamenesConst.PENDIENTE_POR_CERRAR ||
                      servicio.estadoServicioId ===
                        estadosExamenesConst.EN_PROCESO
                        ? (servicio.informacionUltimoGuardado?.tipoGuardado ??
                            '') +
                          ' ' +
                          (servicio.informacionUltimoGuardado?.nombreMedico ??
                            servicio.nombreMedico ??
                            '')
                        : '',
                    enCurso: servicio.enCurso,
                    especialidadMedico: false,
                    servicioAtencionId: servicio.servicioAtencionId,
                    esLaboratorio: servicio.esLaboratorio,
                    esVacunacion: servicio.esVacunacion,
                  }
                })
              ),
              ocupadoPor: {
                Fila1: '',
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
              fechaUltimoLlamado: moment().format(),
            }
          } else {
            return atencion
          }
        })
      )
      contextoAgendaRegistro.setAtencionesDiaActual(
        ordenarListaAtenciones(atenciones)
      )
    } else if (
      contextoAgendaRegistro.atencionesPasadas.find(
        (x) => x.id === mensajeAtencionAgregado.atencionId
      )
    ) {
      let atenciones = await Promise.all(
        contextoAgendaRegistro.atencionesPasadas.map(async (atencion) => {
          if (atencion.id === mensajeAtencionAgregado.atencionId) {
            return {
              ...atencion,
              examenes: await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (servicio) => {
                  return {
                    tipoExamen: servicio.esLaboratorio
                      ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                      : await validarExistenciaAlias(servicio),
                    estadoExamen: servicio.estadoServicioId,
                    medico:
                      servicio.estadoServicioId ===
                        estadosExamenesConst.PENDIENTE_POR_CERRAR ||
                      servicio.estadoServicioId ===
                        estadosExamenesConst.EN_PROCESO
                        ? (servicio.informacionUltimoGuardado?.tipoGuardado ??
                            '') +
                          ' ' +
                          (servicio.informacionUltimoGuardado?.nombreMedico ??
                            servicio.nombreMedico ??
                            '')
                        : '',
                    enCurso: servicio.enCurso,
                    especialidadMedico: false,
                    servicioAtencionId: servicio.servicioAtencionId,
                    esLaboratorio: servicio.esLaboratorio,
                    esVacunacion: servicio.esVacunacion,
                  }
                })
              ),
              ocupadoPor: {
                Fila1: '',
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
              fechaUltimoLlamado: moment().format(),
            }
          } else {
            return atencion
          }
        })
      )
      contextoAgendaRegistro.setAtencionesPasadas(
        ordenarListaAtenciones(atenciones)
      )
    } else {
      let atencionAAgregar = {
        id: mensajeAtencionAgregado.atencionId,
        numeroOrdenyFechaCreacion: {
          Fila1: moment(mensajeAtencionAgregado.fechaCreacion).format('LT'),
          Fila2: moment(mensajeAtencionAgregado.fechaCreacion).format(
            'DD/MM/YYYY'
          ),
        },
        nombresyDocumento: {
          Fila1: mensajeAtencionAgregado.nombreCompleto.toUpperCase(),
          Fila2: mensajeAtencionAgregado.numeroDocumento,
        },
        empresa: (
          await ObtenerEmpresaPorId(mensajeAtencionAgregado.empresaId)
        ).Nombre.toUpperCase(),
        tipoPaciente: mensajeAtencionAgregado.tipoPacienteId,
        examenes: await Promise.all(
          mensajeAtencionAgregado.servicios.map(async (servicio) => {
            return {
              tipoExamen: servicio.esLaboratorio
                ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                : await validarExistenciaAlias(servicio),
              estadoExamen: servicio.estadoServicioId,
              medico:
                servicio.estadoServicioId ===
                  estadosExamenesConst.PENDIENTE_POR_CERRAR ||
                servicio.estadoServicioId === estadosExamenesConst.EN_PROCESO
                  ? (servicio.informacionUltimoGuardado?.tipoGuardado ?? '') +
                    ' ' +
                    (servicio.informacionUltimoGuardado?.nombreMedico ??
                      servicio.nombreMedico ??
                      '')
                  : '',
              enCurso: servicio.enCurso,
              servicioAtencionId: servicio.servicioAtencionId,
              esLaboratorio: servicio.esLaboratorio,
              esVacunacion: servicio.esVacunacion,
            }
          })
        ),
        ocupadoPor: {
          Fila1: '',
          Fila2: await manejarEstadoServicioAtencion(
            mensajeAtencionAgregado.servicios
          ),
        },
        fechaCreacion: mensajeAtencionAgregado?.fechaCreacion,
        fechaUltimoLlamado: mensajeAtencionAgregado?.fechaUltimoLLamado,
      }

      const fechaActual = moment().format('YYYY/MM/DD')
      const fechaAtencion = moment(
        mensajeAtencionAgregado.fechaCreacion
      ).format('YYYY/MM/DD')
      if (fechaActual > fechaAtencion) {
        contextoAgendaRegistro.setAtencionesPasadas((prevLaboratorios) =>
          ordenarListaAtenciones([...prevLaboratorios, atencionAAgregar])
        )
      } else {
        contextoAgendaRegistro.setAtencionesDiaActual((prevLaboratorios) =>
          ordenarListaAtenciones([...prevLaboratorios, atencionAAgregar])
        )
      }
    }
  }
  const manejarLaboratorioPendientePorCerrar = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        contextoAgendaRegistro.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.PENDIENTE_POR_CERRAR,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: '',
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        contextoAgendaRegistro.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.PENDIENTE_POR_CERRAR,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: '',
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarLaboratorioFinalizadoSede = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        contextoAgendaRegistro.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                    medico: '',
                    tipoGuardado: '',
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: '',
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        contextoAgendaRegistro.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: '',
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarLaboratorioLlamandoSede = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        contextoAgendaRegistro.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.LLAMANDO,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: mensajeLaboratorioParamodificar.nombreMedico,
                Fila2: 'Laboratorio clínico',
              },
              fechaUltimoLlamado: moment().format(),
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        contextoAgendaRegistro.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: atencion.nombreMedico,
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarLaboratorioAtendiendoSede = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaRegistro.setAtencionesDiaActual(
        contextoAgendaRegistro.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.EN_PROCESO,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: mensajeLaboratorioParamodificar.nombreMedico,
                Fila2: '',
              },
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaRegistro.setAtencionesPasadas(
        contextoAgendaRegistro.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.EN_PROCESO,
                  }
                }
                return examen
              }),
              ocupadoPor: {
                Fila1: atencion.nombreMedico,
                Fila2: TEXTO_AGENDAS.EN_SALA,
              },
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarRetirarAtencionAgenda = (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaRegistro.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      let atencionesfiltradas =
        contextoAgendaRegistro.atencionesDiaActual.filter(
          (x) => x.id != mensaje.atencionId
        )
      contextoAgendaRegistro.setAtencionesDiaActual(atencionesfiltradas)
    } else {
      let atencionesfiltradas = contextoAgendaRegistro.atencionesPasadas.filter(
        (x) => x.id != mensaje.atencionId
      )
      contextoAgendaRegistro.setAtencionesPasadas(atencionesfiltradas)
    }
  }

  const listaMensajesYFunciones = [
    {
      nombreConexion: 'atencionAgregadaSede',
      funcion: manejarAgregarAtencionSede,
    },
    {
      nombreConexion: 'registroServicioAtencionEnProcesoSede',
      funcion: manejarServicioAtencionEnProceso,
    },
    {
      nombreConexion: 'registroServicionAtencionFinalizadoSede',
      funcion: manejarServicionAtencionFinalizadoSede,
    },
    {
      nombreConexion: 'registroServicioAtencionPendientePorCerrarSede',
      funcion: manejarServicioAtencionPendientePorCerrarSede,
    },
    {
      nombreConexion: 'registroAtencionFinalizadaSede',
      funcion: manejarAtencionFinalizadaSede,
    },
    {
      nombreConexion: 'registroServicioAtencionLlamandoSede',
      funcion: manejarAtencionLlamandoPaciente,
    },
    {
      nombreConexion: 'registroServicioAtencionPendienteAtenderSede',
      funcion: manejarAtencionPendientePorAtender,
    },
    {
      nombreConexion: 'registroLaboratorioPendientePorCerrarSede',
      funcion: manejarLaboratorioPendientePorCerrar,
    },
    {
      nombreConexion: 'registroLaboratorioFinalizadoSede',
      funcion: manejarLaboratorioFinalizadoSede,
    },
    {
      nombreConexion: 'registroLaboratorioLlamandoSede',
      funcion: manejarLaboratorioLlamandoSede,
    },
    {
      nombreConexion: 'registroLaboratorioAtendiendoSede',
      funcion: manejarLaboratorioAtendiendoSede,
    },
    {
      nombreConexion: 'registroRetirarAtencion',
      funcion: manejarRetirarAtencionAgenda,
    },
  ]
  const [conexion, agregarSubscripciones, eliminarSubscripciones] =
    useConexionSignalR(listaMensajesYFunciones, contextoAplicacion.sede.sedeId)

  useEffect(() => {
    if (!montado) {
      return
    }
    if (conexion) {
      eliminarSubscripciones(listaMensajesYFunciones)
      agregarSubscripciones(listaMensajesYFunciones)
    }
  }, [
    contextoAgendaRegistro.atencionesDiaActual,
    contextoAgendaRegistro.atencionesPasadas,
  ])

  let columns = useMemo(
    () => [
      {
        Header: (row) => <EncabezadoFondo titulo={'Número de atención'} />,
        accessor: 'id',
        className: 'w-6% ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1  h-32 flex justify-center items-center rounded-l-lg ',
      },
      {
        Header: (row) => (
          <EncabezadoFondo titulo={'Hora llegada y fecha de registro'} />
        ),
        accessor: 'numeroOrdenyFechaCreacion',
        className: 'w-7%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: (row) => (
          <CeldaDobleTexto datos={row.row.original.numeroOrdenyFechaCreacion} />
        ),
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: 'Nombres ó cédula',
        accessor: 'nombresyDocumento',
        className: 'w-15%',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 text-white text-14px'",
        filter: 'dobleTexto',
        Cell: (row) => (
          <CeldaDobleTexto datos={row.row.original.nombresyDocumento} />
        ),
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Empresa'} />,
        accessor: 'empresa',
        className: 'w-7%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Tipo paciente'} />,
        accessor: 'tipoPaciente',
        className: 'w-8%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
        Cell: (row) => (
          <CeldaTipoPaciente
            tipoUsurio={row.row.original.tipoPaciente}
            listaServicios={row?.row?.original?.examenes}
            atencion={atencionesDeHoy ? row.row.original : ''}
          />
        ),
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Exámenes'} />,
        accessor: 'examenes',
        className: 'w-42%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
        Cell: (row) => (
          <ExamenesPaciente
            datos={row.row.original.examenes}
            ocultarWarning={true}
          />
        ),
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Ocupado por '} />,
        accessor: 'ocupadoPor',
        className: 'w-15%',
        headerStyle: 'bg-cendiatra-verde-7 text-white rounded-r-lg h-16 pr-1',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center rounded-r-lg',
        Cell: (row) => (
          <CeldaDobleTexto
            datos={row.row.original.ocupadoPor}
            color={'text-cendiatra-verde-4'}
          />
        ),
        disableFilters: true,
      },
    ],
    [
      contextoAgendaRegistro.atencionesDiaActual,
      contextoAgendaRegistro.atencionesPasadas,
      atencionesDeHoy,
    ]
  )

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITULOS_RECEPCIONISTA.AGENDA_REGISTRO}
    >
      <div
        className="bg-white pl-8 pr-5 rounded-t-3xl rounded-b-3xl"
        style={{minHeight: '80vh'}}
      >
        <div className="w-full flex justify-center items-center">
          <div className="w-full flex justify-between items-center mt-8">
            <AtencionesAMostrar
              estilosContenedor={
                'w-4/12 flex flex-wrap justify-center items-center border border-cendiatra py-5px rounded-lg h-20'
              }
              seleccionado={atencionesDeHoy}
              funcion={manejarMostrarTipoAtenciones}
              tituloLeyendaSuperior={'Atenciones pendientes hoy'}
              tituloLeyendaInferior={
                'Atenciones pendientes por cerrar de días anteriores'
              }
              valorLeyendaSuperior={
                contextoAgendaRegistro.atencionesDiaActual.length
              }
              valorLeyendaInferior={
                contextoAgendaRegistro.atencionesPasadas.length
              }
            />

            <div className="w-4/12 flex justify-center content-between h-20">
              <div className="w-full flex flex-wrap">
                <LeyendasAgenda leyendas={LEYENDA_AGENDA.MEDICO} />
              </div>
            </div>
          </div>
        </div>
        <TablaAgenda
          data={
            atencionesDeHoy
              ? contextoAgendaRegistro.atencionesDiaActual
              : contextoAgendaRegistro.atencionesPasadas
          }
          columns={columns}
        />
      </div>
    </ContenedorPagina>
  )
}

export default AgendaRegistro
