import {getRestApi} from './ApiAxios'
export const ObtenerRoles = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('rol-function/ObtenerRoles')
}

export const ObtenerServiciosRol = async (rolId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('rol-function/ObtenerServiciosRol?rolId=' + rolId)
}

export const ActualizarServiciosRol = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('rol-function/ActualizarServiciosRol', payload)
}

export const ObtenerPermisosServiciosPorRol = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('rol-function/ObtenerPermisosServiciosPorRol')
}

export const ObtenerUsuariosRecepcion = async () => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'gato-api-function/ObtenerUsuariosRecepcion',
  })
}
