import moment from 'moment'
import {useContext, useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import BotonConImagen from '../../componentes/BotonConImagen'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {CeldaResolucionFirmaElectronicaSemaforizada} from '../../componentes/resolucionFirmaElectronica/CeldaResolucionFirmaElectronicaSemaforizada'
import {SemaforoResolucionesFirmaElectronica} from '../../componentes/resolucionFirmaElectronica/SemaforoResolucionesFirmaElectronica'
import {RUTAS_APP} from '../../constantes/rutas/rutasApp'
import {FORMATO_FECHA_GENERAL} from '../../constantes/formatos'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextoResolucionFirmaElectronica} from '../../contexto'

import imagenCrear from '../../imagenes/addButtonWhite.svg'
import imagenEditarGris from '../../imagenes/iconoEditarGris.svg'
import imagenEditarVerde from '../../imagenes/iconoEditarVerde.svg'
import {analizarResolucion} from '../../utilidades/utilidadesResolucionFirmaElectronica'
import {obtenerResoluciones} from '../../microservicios/ResolucionFirmaElectronica'
import TablaConPaginacionYFiltros from '../../componentes/Tablas/TablaConPaginacionYFiltros'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'

const TEXTO_PANTALLA = TEXTO_VISUAL.RESOLUCION_FIRMA_ELECTRONICA

export const VerResolucionesFirmaElectronica = () => {
  const history = useHistory()
  const contextoResolucionFirmaElectronica = useContext(
    ContextoResolucionFirmaElectronica
  )

  const [resoluciones, setResoluciones] = useState([])
  const [filtroNumeroResolucion, setFiltroNumeroResolucion] = useState('')
  const [numerosResolucionSugeridos, setNumerosResolucionSugeridos] = useState(
    []
  )
  const [informacionFiltrada, setInformacionFiltrada] = useState([])

  const navegarADefinirResolucion = (resolucionId) => {
    contextoResolucionFirmaElectronica.setResolucionId(resolucionId)

    if (resolucionId === undefined) {
      history.push(RUTAS_APP.RESOLUCIONES_FIRMA_ELECTRONICA.CARGAR)
    } else {
      history.push(RUTAS_APP.RESOLUCIONES_FIRMA_ELECTRONICA.MODIFICAR)
    }
  }

  const filtrarPorNumeroResolucion = (e) => {
    const textoEscrito = e.target.value.trim()

    setFiltroNumeroResolucion(textoEscrito)

    const analisisInformacion = resoluciones.reduce(
      (analisis, resolucion, indice) => {
        if (
          textoEscrito.length === 0 ||
          (textoEscrito.length > 0 &&
            resolucion.numeroResolucion.includes(textoEscrito))
        ) {
          analisis.resolucionesFiltradas.push(resolucion)

          analisis.sugerencias.push({
            id: `${indice}-${resolucion.numeroResolucion}`,
            filtro: resolucion.numeroResolucion,
          })
        }

        return analisis
      },
      {sugerencias: [], resolucionesFiltradas: []}
    )

    setNumerosResolucionSugeridos(analisisInformacion.sugerencias)
    setInformacionFiltrada(analisisInformacion.resolucionesFiltradas)
  }

  const manejarNumeroResolucionSeleccionado = (
    numeroResolucionSeleccionado
  ) => {
    const valorFiltro = numeroResolucionSeleccionado.filtro
    const informacionFiltrada = resoluciones.filter(
      (resolucion) => resolucion.numeroResolucion === valorFiltro
    )

    setFiltroNumeroResolucion(valorFiltro)
    setInformacionFiltrada(informacionFiltrada)
  }

  const columnas = useMemo(
    () => [
      {
        Header: () => (
          <EncabezadoFondo
            titulo={TEXTO_PANTALLA.COLUMNA.CODIGO_RESOLUCION.TITULO}
          />
        ),
        accessor: 'id',
        headerStyle:
          'w-2/12 flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px rounded-l-lg',
        cellStyles:
          'w-2/12 z-0 text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
        disableFilters: true,
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={TEXTO_PANTALLA.COLUMNA.NUMERO_RESOLUCION.TITULO}
          />
        ),
        accessor: 'numeroResolucion',
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px w-3/12',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center w-3/12',
        Cell: ({column: {id}, row: {values, original}, value}) => (
          <CeldaResolucionFirmaElectronicaSemaforizada
            resolucion={values}
            nombreCampo={id}
            valorMostrar={value}
            informacionFila={original}
          />
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={TEXTO_PANTALLA.COLUMNA.FECHA_INICIAL.TITULO}
          />
        ),
        accessor: 'fechaInicial',
        className: '',
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px w-1/12',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center w-1/12 ',
        disableFilters: true,
        Cell: ({value}) => moment(value).format(FORMATO_FECHA_GENERAL),
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={TEXTO_PANTALLA.COLUMNA.FECHA_FINAL.TITULO} />
        ),
        accessor: 'fechaFinal',
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px w-1/12',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center w-1/12',
        disableFilters: true,

        Cell: ({column: {id}, row: {values}, value}) => (
          <CeldaResolucionFirmaElectronicaSemaforizada
            resolucion={values}
            nombreCampo={id}
            valorMostrar={moment(value).format(FORMATO_FECHA_GENERAL)}
          />
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={TEXTO_PANTALLA.COLUMNA.NUMERACION_INICIAL.TITULO}
          />
        ),
        accessor: 'numeracionInicial',
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px w-1/12',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center w-1/12',
        disableFilters: true,
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={TEXTO_PANTALLA.COLUMNA.NUMERACION_FINAL.TITULO}
          />
        ),
        accessor: 'numeracionFinal',
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px w-1/12',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center w-1/12',
        disableFilters: true,
        Cell: ({column: {id}, row: {values}, value}) => (
          <CeldaResolucionFirmaElectronicaSemaforizada
            resolucion={values}
            nombreCampo={id}
            valorMostrar={value}
          />
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={TEXTO_PANTALLA.COLUMNA.NUMERACION_ACTUAL.TITULO}
          />
        ),
        accessor: 'numeracionActual', // TODO En respuesta de endpoint no viene este campo, mapearlo según indicaciones del backend.
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px w-1/12',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center w-1/12',
        disableFilters: true,
      },
      {
        id: 'editar',
        Header: () => (
          <EncabezadoFondo
            titulo={
              TEXTO_VISUAL.PORTAFOLIO.EDITAR.PESTANA.SELECCIONAR_PORTAFOLIO
                .COLUMNA.EDITAR.TITULO
            }
          />
        ),
        headerStyle:
          ' w-2/12 flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px rounded-r-lg',
        cellStyles:
          'w-2/12 text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
        disableFilters: true,
        Cell: ({row: {values}}) => {
          const deshabilitado = analizarResolucion(values).estaVencida

          return (
            <BotonConImagen
              ancho="w-9"
              desabilitado={deshabilitado}
              medio
              imagen={deshabilitado ? imagenEditarGris : imagenEditarVerde}
              funcionAEjecutar={() => navegarADefinirResolucion(values.id)}
            />
          )
        },
      },
    ],
    [filtroNumeroResolucion]
  )

  const datosTabla = useMemo(
    () => (informacionFiltrada.length > 0 ? informacionFiltrada : resoluciones),
    [resoluciones, informacionFiltrada]
  )

  useEffect(() => {
    obtenerResoluciones().then((resoluciones) => {
      setResoluciones(resoluciones)
    })
  }, [])

  return (
    <ContenedorPagina tituloPagina={TEXTO_PANTALLA.TITULO.VER_RESOLUCIONES}>
      <div className="flex flex-row mt-5">
        <div className="w-1/2">
          <BotonConImagen
            ancho="w-48"
            estilosContenedor="content-start flex-col py-2 bg-cendiatra text-white rounded-lg mb-5"
            estilosImagen="mb-2"
            medio
            imagen={imagenCrear}
            texto={TEXTO_PANTALLA.BOTON.CREAR_RESOLUCION.TITULO}
            funcionAEjecutar={() => navegarADefinirResolucion()}
          />
        </div>
        <div className="flex justify-end w-1/2 pr-5">
          <SemaforoResolucionesFirmaElectronica />
        </div>
      </div>
      <TablaConPaginacionYFiltros
        columns={columnas}
        data={datosTabla}
        paginacion={true}
      />
    </ContenedorPagina>
  )
}
