import {getRestApi} from './ApiAxios'

export const ObtenerInformacionCuentaPorEmpresaId = async (empresaId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'GET',
    url: 'cuenta-function/ObtenerInformacionCuentaPorEmpresaId',
    params: {empresaId},
  })
}

export const CrearMovimiento = async (movimiento) => {
  const apiReq = await getRestApi()
  return await apiReq.put('cuenta-function/CrearMovimiento', movimiento)
}

export const GenerarReciboDeCaja = async (recibo) => {
  const apiReq = await getRestApi()
  return await apiReq.post('cuenta-function/GenerarReciboCaja', recibo)
}

export const ObtenerUltimosMovimientosPorEmpresaId = async (empresaId) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'cuenta-function/ObtenerUltimosMovimientosPorEmpresaId',
    {params: {empresaId}}
  )
}

export const ObtenerInformacionCuentaPorUsuarioId = async (usuarioId) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'cuenta-function/ObtenerInformacionCuentaPorUsuarioId',
    {params: {usuarioId}}
  )
}
