import {dbComun} from '../bases-datos/dbComun'

export const ObtenerObservacionesDePreparacionPorNombre = async (filtro) => {
  let observacionesDePeparacionPorNombre = (
    await dbComun
      .table('ObservacionesPreparacion')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
  }))
  return Promise.resolve(observacionesDePeparacionPorNombre)
}
