import React from 'react'
import InputRadio from '../inputs/InputRadio'

const LeyendaConInputRadioInverso = ({
  seleccionado,
  funcion = null,
  titulo = '',
}) => {
  return (
    <div className="w-full flex justify-center items-center my-2.5px">
      <div className="w-5/6 flex justify-end items-center">
        <span className="text-cendiatra-gris-3 text-13px ">{titulo}</span>
      </div>
      <div className="w-1/6 flex justify-center items-center">
        <InputRadio
          estilos={seleccionado ? 'pointer-events-none' : ''}
          seleccionado={seleccionado}
          funcion={funcion}
        />
      </div>
    </div>
  )
}

export default LeyendaConInputRadioInverso
