import React from 'react'

const CuadroInformacion = ({estilosContenedor, titulo, datos}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <div className="w-full flex justify-center items-center text-center ">
        <span className="text-17px text-cendiatra font-bold">{titulo}</span>
      </div>
      <div className="w-full flex justify-center items-center">
        <div className="w-full flex justify-start items-center flex-wrap text-center ml-20">
          {datos.map((item) => (
            <div
              key={item?.titulo}
              className="w-1/3 flex justify-start items-center flex-wrap my-2"
            >
              <span className="text-cendiatra text-13px">
                {`${item?.titulo}:`}
              </span>
              <span
                className={`text-cendiatra-gris-1 text-13px ml-1 ${
                  item?.estiloTexto ? item?.estiloTexto : 'uppercase'
                } `}
              >
                {item?.contenido}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CuadroInformacion
