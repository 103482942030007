import React, {useEffect, useState} from 'react'
import ListaDesplegable from './ListaDesplegable'
import {Controller, useForm} from 'react-hook-form'
import {GRUPO_PRECIOS} from '../../constantes'

const ListaDesplegableTipoGuardado = ({
  estilosContenedor,
  nombre,
  idFila,
  tipoElemento,
  listaOpciones,
  contexto,
  grupo,
}) => {
  const {control, setValue, watch} = useForm({})

  const [validacionLista, setValidacionLista] = useState(true)

  const [elementoAntiguo, setElementoAntiguo] = useState(false)

  const valorLista = watch(nombre)

  useEffect(() => {
    if (valorLista === '') {
      validarExisteNuevoPrecio(tipoElemento, null)
    }
  }, [valorLista])

  useEffect(() => {
    const validarServicioOPaqueteNuevo = (id, tipoElemento) => {
      const arrayInicial = Boolean(tipoElemento === 'Servicio')
        ? contexto.serviciosIniciales
        : contexto.paquetesIniciales
      return Boolean(arrayInicial.find((elemento) => elemento === id))
    }
    setElementoAntiguo(validarServicioOPaqueteNuevo(idFila, tipoElemento))
  }, [])

  useEffect(() => {
    const validacionLista = Boolean(
      contexto?.arregloMomentoActivacion?.find(
        (elemento) =>
          elemento?.idGeneral === idFila &&
          elemento?.tipo.toLowerCase() === tipoElemento?.toLowerCase() &&
          ((grupo === elemento?.grupo && !grupo.includes('SEDE')) ||
            (grupo?.includes('SEDE') &&
              elemento?.grupo !== GRUPO_PRECIOS.ALIADOS))
      )
    )

    setValidacionLista(validacionLista)
    if (!validacionLista && contexto?.arregloMomentoActivacion.length === 0) {
      setValue(nombre, '')
    }
  }, [contexto?.arregloMomentoActivacion])

  const validarExisteNuevoPrecio = (tipoElemento, valor) => {
    const nombrePropiedad =
      grupo === GRUPO_PRECIOS.ALIADOS ? 'tipoCambioAliados' : 'tipoCambioSedes'

    if (tipoElemento?.toLowerCase() === 'servicio') {
      contexto.setListaServicios(
        contexto.listaServicios.map((servicio) => {
          if (servicio.servicio_id === idFila) {
            return {
              ...servicio,
              [nombrePropiedad]: valor ? valor : null,
            }
          }
          return servicio
        }) ?? []
      )
    } else if (tipoElemento?.toLowerCase() === 'paquete') {
      contexto.setListaPaquetes(
        contexto.listaPaquetes.map((paquete) => {
          if (paquete.paquete_id === idFila) {
            return {
              ...paquete,
              [nombrePropiedad]: valor ? valor : null,
            }
          }
          return paquete
        }) ?? []
      )
    }
    if (valor !== null) {
      contexto.setArregloMomentoActivacion(
        contexto?.arregloMomentoActivacion?.map((elemento) => {
          if (
            elemento?.idGeneral === idFila &&
            elemento?.tipo?.toLowerCase() === tipoElemento?.toLowerCase() &&
            grupo.includes('SEDES') &&
            elemento?.grupo.includes('SEDES')
          ) {
            return {
              ...elemento,
              tipoCambioSedes: valor ? valor : null,
            }
          } else if (
            elemento?.idGeneral === idFila &&
            elemento?.tipo?.toLowerCase() === tipoElemento?.toLowerCase() &&
            grupo === GRUPO_PRECIOS.ALIADOS &&
            elemento?.grupo === GRUPO_PRECIOS.ALIADOS
          ) {
            return {
              ...elemento,
              tipoCambioAliados: valor ? valor : null,
            }
          }
          return elemento
        })
      )
    }
  }

  return (
    <div className={`${estilosContenedor}`}>
      <Controller
        name={nombre}
        control={control}
        rules={{
          required: true,
          onChange: (e) =>
            validarExisteNuevoPrecio(tipoElemento, e.target.value),
        }}
        render={({field: {onChange, value}}) =>
          elementoAntiguo ? (
            <ListaDesplegable
              estilosContenedor={'w-full'}
              onChange={onChange}
              estilosLista={
                validacionLista
                  ? `appearance-none rounded w-full  p-1.5 border ${
                      !value ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
                    } text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`
                  : 'appearance-none rounded relative bg-cendiatra-gris-2  pointer-events-none block w-full p-1.5 border border-cendiatra !text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'PRECIO A PARTIR DE'}
              opciones={listaOpciones}
              valor={value}
              tamanoTextoLabel={
                'text-17px uppercase font-bold leading-15px text-cendiatra-verde-2 -mt-1 mb-3'
              }
            />
          ) : (
            <div className={'w-full flex justify-start items-center flex-wrap'}>
              <label
                className={`block  my-5px text-17px uppercase font-bold leading-15px text-cendiatra-verde-2 -mt-1 mb-3`}
              >
                PRECIO A PARTIR DE
              </label>
              <select
                className={`appearance-none relative bg-cendiatra-gris-2  pointer-events-none block w-full p-1.5 border border-cendiatra text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
              >
                <option key="-1" value="">
                  {'INMEDIATO'}
                </option>
              </select>
            </div>
          )
        }
      />
    </div>
  )
}

export default ListaDesplegableTipoGuardado
