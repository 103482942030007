import React, {useContext, useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import {ROLES_APP, estadosExamenesConst} from '../../constantes'
import {ContextoFormulario, ContextApplication} from '../../contexto'
import {
  FachadaCambiarEstadoServicioAtencion,
  CambiarEnProcesoServicioAtencion,
  ObtenerPermisosServiciosPorRol,
} from '../../microservicios'
import {ObtenerServicioPorId} from '../../cache/servicios-cache'
import {CeldaConTooltipPersonalizable} from '../Celdas'

const ListaFormularios = ({estilosContenedor, datos}) => {
  const {state} = useLocation()
  const {push} = useHistory()
  const {accounts} = useMsal()
  const contextoFormulario = useContext(ContextoFormulario)
  const [idsEspecialidades, setIdsEspecialidades] = useState([])

  const rolUsuario = accounts[0]?.idTokenClaims?.roles[0]

  useEffect(() => {
    if (
      rolUsuario !== ROLES_APP.ADMINISTRADOR &&
      rolUsuario !== ROLES_APP.JEFE_SEDE
    ) {
      obtenerPermisosPorRol()
    }
  }, [])

  const obtenerPermisosPorRol = async () => {
    setIdsEspecialidades(
      (await ObtenerPermisosServiciosPorRol()).data?.serviciosIds
    )
  }

  const validarAtencionCorrespondeARol = (servicioPlantilla) => {
    return Boolean(
      idsEspecialidades.find(
        (servicioId) => servicioId === servicioPlantilla
      ) ?? null
    )
  }

  const contextoAplicacion = useContext(ContextApplication)
  const data = datos.map((resp) => {
    if (
      resp.estadoExamen === estadosExamenesConst.EN_PROCESO &&
      state?.servicioAtencionId === resp.servicioAtencionId
    ) {
      resp = {
        ...resp,
        orden: 1,
      }
    }
    if (
      resp.estadoExamen === estadosExamenesConst.EN_PROCESO &&
      state?.servicioAtencionId !== resp.servicioAtencionId
    ) {
      resp = {
        ...resp,
        orden: 3,
      }
    }
    if (resp.estadoExamen === estadosExamenesConst.PENDIENTE_POR_ATENDER) {
      resp = {
        ...resp,
        orden: 2,
      }
    }
    if (resp.estadoExamen === estadosExamenesConst.PENDIENTE_POR_CERRAR) {
      resp = {
        ...resp,
        orden: 3,
      }
    }
    if (resp.estadoExamen === estadosExamenesConst.CERRADO) {
      resp = {
        ...resp,
        orden: 4,
      }
    }
    if (state?.servicioAtencionId === resp.servicioAtencionId) {
      resp = {
        ...resp,
        orden: 1,
      }
    }
    return resp
  })

  data.sort((a, b) => a.orden - b.orden)

  const establecerColor = (estadoFormulario) => {
    switch (estadoFormulario) {
      case estadosExamenesConst.PENDIENTE_POR_ATENDER:
        return 'bg-cendiatra-semaforo-rojo'
      case estadosExamenesConst.PENDIENTE_POR_CERRAR:
        return 'bg-cendiatra-semaforo-naranja'
      case estadosExamenesConst.LLAMANDO:
        return 'bg-cendiatra-semaforo-amarillo'
      case estadosExamenesConst.CERRADO:
        return 'bg-cendiatra-semaforo-verde'
      case estadosExamenesConst.EN_PROCESO:
        return 'bg-white'
      default:
        return 'bg-cendiatra-semaforo-naranja'
    }
  }

  const cerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const manejarCambiarDeExamen = async (
    servicioId,
    servicioAtencionId,
    estadoExamen
  ) => {
    if (
      (!validarAtencionCorrespondeARol(servicioId) &&
        rolUsuario === ROLES_APP.MEDICO_OCUPACIONAL) ||
      estadoExamen === estadosExamenesConst.CERRADO
    ) {
      const newState = {
        usuarioId: state?.usuarioId,
        examenes: state?.examenes,
        ordenId: state?.ordenId,
        servicioAtencionId,
        atencionId: state?.atencionId,
        registrarResultados: state?.registrarResultados,
        servicioId,
        estadoExamen,
      }
      push('/examen', {...newState})
      cerrarModal()
    } else {
      try {
        await FachadaCambiarEstadoServicioAtencion(
          contextoFormulario.examenInicial.servicioAtencionId,
          false
        )
      } catch (error) {
        console.log(error)
      }

      const clasificacionServicio = (await ObtenerServicioPorId(servicioId))
        ?.ClasificacionId

      if (clasificacionServicio === 6) {
        const newState = {
          usuarioId: state?.usuarioId,
          examenes: state?.examenes,
          ordenId: state?.ordenId,
          servicioAtencionId,
          atencionId: state?.atencionId,
          servicioId,
          estadoExamen,
        }
        push('/examen', {...newState})
        cerrarModal()
        contextoFormulario.setActualizarExamenInicial(
          !contextoFormulario.actualizarExamenInicial
        )
      } else {
        try {
          await FachadaCambiarEstadoServicioAtencion(
            contextoFormulario.examenInicial.servicioAtencionId
          )

          const respuesta = await CambiarEnProcesoServicioAtencion(
            servicioAtencionId
          )
          if (respuesta.status === 200) {
            const newState = {
              usuarioId: state?.usuarioId,
              examenes: state?.examenes,
              ordenId: state?.ordenId,
              servicioAtencionId,
              atencionId: state?.atencionId,
              servicioId,
              estadoExamen,
            }
            push('/examen', {...newState})
            cerrarModal()
            contextoFormulario.setActualizarExamenInicial(
              !contextoFormulario.actualizarExamenInicial
            )
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  const validarExamenSeleccionado = (formulario) => {
    if (
      formulario?.estadoExamen === estadosExamenesConst.EN_PROCESO &&
      formulario?.servicioId !== state?.servicioId
    ) {
      return 2
    } else {
      return formulario?.estadoExamen
    }
  }
  const manejarAbrirModalConfirmacionCambioDeExamen = (
    servicioId,
    servicioAtencionId,
    estadoExamen
  ) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full text-center">
            {' '}
            Vas a cambiar de examen, al continuar se perderá la información que
            no haya guardado,
          </span>
          <span className="w-full text-center mt-5"> ¿Desea continuar?</span>
        </div>
      ),
      contenido: '',
      botones: [
        {
          nombre: 'Continuar',
          click: () =>
            manejarCambiarDeExamen(
              servicioId,
              servicioAtencionId,
              estadoExamen
            ),
        },

        {
          nombre: 'No',
          click: () => {
            cerrarModal()
          },
        },
      ],
    })
  }

  const redireccionarANuevoExamen = (
    servicioId,
    servicioAtencionId,
    estadoExamen
  ) => {
    const newState = {
      usuarioId: state?.usuarioId,
      examenes: state?.examenes,
      ordenId: state?.ordenId,
      servicioAtencionId,
      atencionId: state?.atencionId,
      servicioId,
      estadoExamen,
    }

    push('/examen', {...newState})
  }

  const redireccionarExamen = async ({
    servicioId,
    servicioAtencionId,
    estadoExamen,
  }) => {
    const examenActualCorrespondeARol = validarAtencionCorrespondeARol(
      state.servicioId
    )
    const examenNuevoCorrespondeARol =
      validarAtencionCorrespondeARol(servicioId)

    if (rolUsuario === ROLES_APP.BACTERIOLOGO) {
      manejarCambiarDeExamen(servicioId, servicioAtencionId, estadoExamen)
      return
    }

    if (
      examenActualCorrespondeARol &&
      !examenNuevoCorrespondeARol &&
      contextoFormulario.ultimoExamenCerradoId === state?.servicioId
    ) {
      redireccionarANuevoExamen(servicioId, servicioAtencionId, estadoExamen)
      return
    }
    if (
      examenActualCorrespondeARol &&
      examenNuevoCorrespondeARol &&
      contextoFormulario.ultimoExamenCerradoId === state?.servicioId
    ) {
      manejarCambiarDeExamen(servicioId, servicioAtencionId, estadoExamen)
      return
    }
    if (
      !examenActualCorrespondeARol &&
      contextoFormulario.examenInicial.servicioAtencionId !== servicioAtencionId
    ) {
      manejarCambiarDeExamen(servicioId, servicioAtencionId, estadoExamen)
      return
    }
    if (
      state?.estadoExamen === estadosExamenesConst.CERRADO &&
      contextoFormulario.examenInicial.servicioAtencionId === servicioAtencionId
    ) {
      redireccionarANuevoExamen(servicioId, servicioAtencionId, estadoExamen)
      return
    }
    if (
      state?.estadoExamen === estadosExamenesConst.CERRADO &&
      contextoFormulario.examenInicial.servicioAtencionId !== servicioAtencionId
    ) {
      manejarCambiarDeExamen(servicioId, servicioAtencionId, estadoExamen)
      return
    }
    if (examenActualCorrespondeARol && examenNuevoCorrespondeARol) {
      manejarAbrirModalConfirmacionCambioDeExamen(
        servicioId,
        servicioAtencionId,
        estadoExamen
      )
      return
    }

    if (examenActualCorrespondeARol && !examenNuevoCorrespondeARol) {
      manejarAbrirModalConfirmacionCambioDeExamen(
        servicioId,
        servicioAtencionId,
        estadoExamen
      )
      return
    }
    if (
      (!examenActualCorrespondeARol && !examenNuevoCorrespondeARol) ||
      (!examenActualCorrespondeARol && examenNuevoCorrespondeARol)
    ) {
      redireccionarANuevoExamen(servicioId, servicioAtencionId, estadoExamen)
      return
    }
  }

  const servicioIdExamenEsIgualAlServicioIdState = (formulario) => {
    return formulario?.servicioId === state?.servicioId
  }

  const calcularMargenPorNumeroDeCaracteres = (numeroDeCaracteres) => {
    if (numeroDeCaracteres > 100) {
      return '-mt-60'
    } else if (numeroDeCaracteres > 50) {
      return '-mt-28'
    } else if (numeroDeCaracteres > 30) {
      return '-mt-16'
    }
    return '-mt-10'
  }

  return (
    <>
      <div className={`${estilosContenedor}`}>
        <span className="w-full text-cendiatra text-14px text-left ml-43px mt-2">
          FORMULARIOS
        </span>
        <div className="w-full mx-40px flex justify-start items-center flex-wrap mt-3">
          {data.map((formulario, key) => {
            if (
              !formulario.esLaboratorio &&
              rolUsuario === ROLES_APP.BACTERIOLOGO
            ) {
              return null
            }
            return (
              <div
                className={`w-19% h-8 flex rounded-full p-0.5 text-center justify-center items-center mx-3.5px my-4.6px ${
                  servicioIdExamenEsIgualAlServicioIdState(formulario)
                    ? 'border border-cendiatra-gris-1'
                    : ''
                }  ${establecerColor(
                  servicioIdExamenEsIgualAlServicioIdState(formulario)
                    ? estadosExamenesConst.EN_PROCESO
                    : validarExamenSeleccionado(formulario)
                )} ${
                  formulario?.especialidadMedico ||
                  rolUsuario === ROLES_APP.MEDICO_OCUPACIONAL
                    ? 'cursor-pointer'
                    : ''
                }`}
                key={key}
                onClick={
                  (formulario?.especialidadMedico ||
                    rolUsuario === ROLES_APP.MEDICO_OCUPACIONAL) &&
                  formulario?.servicioAtencionId !== state?.servicioAtencionId
                    ? () => {
                        redireccionarExamen({
                          servicioId: formulario?.servicioId,
                          servicioAtencionId: formulario?.servicioAtencionId,
                          estadoExamen: formulario?.estadoExamen,
                        })
                      }
                    : null
                }
              >
                <div
                  className={` text-13px text-center w-full ${
                    servicioIdExamenEsIgualAlServicioIdState(formulario)
                      ? 'text-cendiatra'
                      : 'text-white'
                  } uppercase ${
                    (formulario?.especialidadMedico &&
                      !servicioIdExamenEsIgualAlServicioIdState(formulario)) ||
                    rolUsuario === ROLES_APP.MEDICO_OCUPACIONAL
                      ? 'underline'
                      : ''
                  }`}
                >
                  <CeldaConTooltipPersonalizable
                    texto={
                      formulario.alias
                        ? formulario?.alias
                        : formulario?.tipoExamen ?? ''
                    }
                    anchoMaximo={'80%'}
                    estilosPersonalizados={`
                      'text-14px bg-cendiatra text-white ${calcularMargenPorNumeroDeCaracteres(
                        formulario.alias
                          ? formulario?.alias?.length
                          : formulario?.tipoExamen?.length ?? ''
                      )} w-80`}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ListaFormularios
