import {useEffect, useMemo, useState} from 'react'
import DetalleAtencion from '../../componentes/Atencion/DetalleAtencion'
import {useHistory, useLocation} from 'react-router-dom'

import userImg from '../../imagenes/usuarioImg.png'
import {LISTADO_GENEROS} from '../../constantes/generos'
import {ObtenerTipoDocumentoPorId} from '../../cache/servicios-cache/TipoDocumento'
import BotonConImagen from '../../componentes/BotonConImagen'

import IconoImprimirBloqueado from '../../imagenes/iconoBloqueoCuadradoRojo.svg'
import IconoImprimirVerde from '../../imagenes/iconoImpresoraCuadradoVerde.svg'
import IconoImprimirGris from '../../imagenes/iconoImprimirGrisCuadrado.svg'
import BotonSimple from '../../componentes/Botones/BotonSimple'
import {ObtenerSedePorId} from '../../cache/servicios-cache/Sedes'
import {ObtenerDepartamentoPorId} from '../../cache/servicios-cache/Departamentos'
import moment from 'moment'

function ComponenteImprimir({URI, funcionImprimir, esCertificado = true}) {
  const validacionIconoAMostrar = () => {
    if (Boolean(URI)) {
      return IconoImprimirVerde
    }

    if (!Boolean(URI) && esCertificado) {
      return IconoImprimirBloqueado
    }

    return IconoImprimirGris
  }

  return (
    <BotonConImagen
      imagen={validacionIconoAMostrar()}
      estilosImagen={'w-9 h-10'}
      medio={true}
      funcionAEjecutar={() => {
        if (Boolean(URI)) {
          funcionImprimir(URI)
        }
      }}
    />
  )
}

function DetalleAtencionEjecutivo() {
  const {state} = useLocation()
  const history = useHistory()
  const [informacion, setInformacion] = useState({
    datos: [],
    fechaAtencion: '',
    horaAtencion: '',
    URIFotoPaciente: userImg,
    examenes: [],
  })

  const imprimirURL = (url) => {
    window.open(url, '_blank')
  }

  const columnas = useMemo(
    () => [
      {
        Header: 'Exámenes',
        accessor: 'examen',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-l-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        className: 'w-4/12',
      },
      {
        Header: 'Médico',
        accessor: 'medico',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        className: 'w-4/12',
      },
      {
        Header: 'Imprimir Certificado',
        accessor: 'urlCertificado',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        className: 'w-2/12',
        Cell: ({row}) => {
          return (
            <ComponenteImprimir
              URI={row?.original?.urlCertificado ?? ''}
              funcionImprimir={imprimirURL}
            />
          )
        },
      },
      {
        Header: 'Imprimir Exámen',
        accessor: 'urlDocumentoExamen',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        className: 'w-2/12',
        Cell: ({row}) => {
          return (
            <ComponenteImprimir
              URI={row?.original?.urlDocumentoExamen ?? ''}
              funcionImprimir={imprimirURL}
              esCertificado={false}
            />
          )
        },
      },
    ],
    []
  )

  const agregarInformacionAlState = async () => {
    const {
      tipoDocumentoId,
      nombre,
      sexoId,
      apellidos,
      documento,
      edad,
      urlFoto,
      direccion,
      nacionalidad,
      fechaAtencion,
      fechaNacimiento,
    } = state.usuario

    const tipoDocumento = await ObtenerTipoDocumentoPorId(tipoDocumentoId ?? 0)
    const sede = await ObtenerSedePorId(state?.sedeId ?? 0)
    const departamento = await ObtenerDepartamentoPorId(
      direccion?.departamentoId ?? ''
    )

    let momentEdad = `${edad ?? 0} años`
    if (fechaNacimiento) {
      const fecha = moment(fechaNacimiento)
      const hoy = moment()

      const difff = hoy.diff(fecha, 'years months days')

      momentEdad = `${moment.duration(difff).years()} años ${moment
        .duration(difff)
        .months()} meses ${moment.duration(difff).days()} días`
    }

    setInformacion((prv) => ({
      examenes:
        state?.serviciosAtencion?.map((servicio) => ({
          examen: servicio?.alias
            ? servicio.alias
            : servicio?.nombreServicio ?? '',
          medico: servicio?.nombreMedico ?? '',
          urlCertificado: servicio?.urlCertificado,
          urlDocumentoExamen: servicio?.urlDocumentoExamen,
          estadoServicioId: servicio?.estadoServicioId,
        })) ?? [],
      datos: [
        {
          titulo: 'Tipo de documento',
          contenido: tipoDocumento?.Nombre ?? '',
        },
        {titulo: 'No. de documento', contenido: documento},
        {titulo: 'Nombres', contenido: `${nombre ?? ''} ${apellidos ?? ''}`},
        {
          titulo: 'Sexo',
          contenido: LISTADO_GENEROS[sexoId] ?? '',
        },
        {titulo: 'Edad', contenido: momentEdad},
        {titulo: 'Celular', contenido: direccion?.celular ?? ''},
        {titulo: 'País', contenido: 'COLOMBIA'},
        {titulo: 'Departamento', contenido: departamento?.Nombre ?? ''},
        {titulo: 'Dirección', contenido: direccion?.direccion ?? ''},
        {titulo: 'Correo electrónico', contenido: direccion?.correo ?? ''},
        {
          titulo: 'Fecha y número de atención',
          contenido: `${
            fechaAtencion ? moment(fechaAtencion).format('YYYY-MM-DD') : ''
          } / ${state.atencionId}`,
        },
        {titulo: 'Sede o aliado', contenido: sede?.Nombre ?? ''},
      ],
      URIFotoPaciente: urlFoto,
    }))
  }

  useEffect(() => {
    if (Object.entries(state?.usuario).length > 0) {
      agregarInformacionAlState()
    }
  }, [state])

  return (
    <DetalleAtencion
      columnas={columnas}
      informacion={informacion}
      opcionesTabla={{paginacion: true}}
    >
      <div className="w-full flex justify-center items-center">
        <BotonSimple
          texto={'Volver'}
          estilosBoton={`${'w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 bg-btnBg'}`}
          funcion={() => {
            history.goBack()
          }}
        />
      </div>
    </DetalleAtencion>
  )
}

export default DetalleAtencionEjecutivo
