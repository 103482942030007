import React, {useState, useEffect, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ListaDesplegable, AreaDeTexto} from '../../../inputs'
import TablaRiesgos from '../TablaRiesgos'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {regexBasicoV1} from '../../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../../constantes'

const RiesgosOcupacionalesAnteriores = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  const {
    register,
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  useEffect(() => {
    reset(contextoMedicoOcupacional.riesgosOcupacionales)
    trigger()
  }, [contextoMedicoOcupacional.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      trigger()
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        !isValid ||
          Object.keys(errors).length != 0 ||
          !contextoMedicoOcupacional.riesgoSeleccionado
      )
    }
  }, [
    isValid,
    contextoMedicoOcupacional.numeroPagina,
    contextoMedicoOcupacional.riesgoSeleccionado,
  ])

  useEffect(() => {
    const subscription = watch(() => {
      contextoMedicoOcupacional.setRiesgosOcupacionales(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const manejarCambioLista = (e) => {
    if (e == 2) {
      contextoMedicoOcupacional.setPrimerEmpleo(true)
      contextoMedicoOcupacional.setRiesgoSeleccionado(true)
    } else {
      contextoMedicoOcupacional.setPrimerEmpleo(false)
      contextoMedicoOcupacional.setRiesgoSeleccionado(
        [
          ...contextoMedicoOcupacional.listaRiesgosFisicos,
          ...contextoMedicoOcupacional.listaRiesgosBiologicos,
          ...contextoMedicoOcupacional.listaRiesgosErgonomicos,
          ...contextoMedicoOcupacional.listaRiesgosLocativos,
          ...contextoMedicoOcupacional.listaRiesgosNaturales,
          ...contextoMedicoOcupacional.listaRiesgosSeguridad,
          ...contextoMedicoOcupacional.listaOtrosRiesgos,
        ].some((x) => x.EstaActivo == true)
      )
    }
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <Controller
        name="antecedentesOcupacionales"
        control={control}
        rules={{
          required: true,
          onChange: (e) => manejarCambioLista(e.target.value),
        }}
        render={({field: {onChange, value}}) => (
          <ListaDesplegable
            onChange={onChange}
            estilosContenedor={'w-7/12 mb-20'}
            estilosLista={
              Object.keys(errors).find(
                (element) => element === 'antecedentesOcupacionales'
              )
                ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
            }
            titulo={'Antecedentes Ocupacionales*'}
            opciones={[
              {Id: 1, Nombre: 'Sí'},
              {Id: 2, Nombre: 'No, Primer Empleo'},
            ]}
            valor={value}
          />
        )}
      />
      <Controller
        name="antecedentesTiempoAnoMes"
        control={control}
        rules={{
          required: contextoMedicoOcupacional.primerEmpleo ? false : true,
          pattern: regexBasicoV1,
        }}
        render={({field: {onChange, value}}) => (
          <>
            <AreaDeTexto
              estilosContenedor={'w-full'}
              placeholder={'DESCRIPCION (TEXTO LARGO)'}
              estilosAreaDeTexto={
                Object.keys(errors).find(
                  (element) => element === 'antecedentesTiempoAnoMes'
                )
                  ? `h-20 mt-3 rounded relative ${
                      contextoMedicoOcupacional.primerEmpleo
                        ? 'bg-cendiatra-gris-4 pointer-events-none placeholder-white'
                        : ''
                    } block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`
                  : `h-20 mt-3 rounded relative ${
                      contextoMedicoOcupacional.primerEmpleo
                        ? 'bg-cendiatra-gris-4 pointer-events-none placeholder-white'
                        : ''
                    } block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`
              }
              titulo={
                'ANTECEDENTES OCUPACIONALES EN EMPRESAS ANTERIORES (DESCRIBA TIEMPO AÑO Y MESES)'
              }
              valor={value}
              cambioValor={onChange}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px my-1">
              {errors.antecedentesTiempoAnoMes?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </>
        )}
      />
      <Controller
        name="enfermedadProfesional"
        control={control}
        rules={{
          required: false,
          pattern: regexBasicoV1,
        }}
        render={({field: {onChange, value}}) => (
          <>
            <AreaDeTexto
              estilosContenedor={'w-full'}
              placeholder={'DESCRIPCION (TEXTO LARGO)'}
              estilosAreaDeTexto={
                Object.keys(errors).find(
                  (element) => element === 'enfermedadProfesional'
                )
                  ? `h-20 mt-3 rounded relative ${
                      contextoMedicoOcupacional.primerEmpleo
                        ? 'bg-cendiatra-gris-4 pointer-events-none placeholder-white'
                        : ''
                    } block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`
                  : `h-20 mt-3 rounded relative ${
                      contextoMedicoOcupacional.primerEmpleo
                        ? 'bg-cendiatra-gris-4 pointer-events-none placeholder-white'
                        : ''
                    } block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`
              }
              titulo={'ENFERMEDAD PROFESIONAL'}
              valor={value}
              cambioValor={onChange}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px my-1">
              {errors.enfermedadProfesional?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </>
        )}
      />
      <Controller
        name="accidenteDeTrabajo"
        control={control}
        rules={{
          required: false,
          pattern: regexBasicoV1,
        }}
        render={({field: {onChange, value}}) => (
          <>
            <AreaDeTexto
              estilosContenedor={'w-full'}
              placeholder={'DESCRIPCION (TEXTO LARGO)'}
              estilosAreaDeTexto={
                Object.keys(errors).find(
                  (element) => element === 'accidenteDeTrabajo'
                )
                  ? `h-20 mt-3 rounded relative ${
                      contextoMedicoOcupacional.primerEmpleo
                        ? 'bg-cendiatra-gris-4 pointer-events-none placeholder-white'
                        : ''
                    } block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`
                  : `h-20 mt-3 rounded relative ${
                      contextoMedicoOcupacional.primerEmpleo
                        ? 'bg-cendiatra-gris-4 pointer-events-none placeholder-white'
                        : ''
                    } block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`
              }
              titulo={'ACCIDENTE DE TRABAJO'}
              valor={value}
              cambioValor={onChange}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px my-1">
              {errors.accidenteDeTrabajo?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </>
        )}
      />
      <span
        className={`w-full ${
          contextoMedicoOcupacional.primerEmpleo
            ? 'text-cendiatra-gris-2'
            : 'text-cendiatra-rojo-1'
        } my-2 text-14px`}
      >
        SELECCIONE MINIMO UN RIESGO CON RESPECTO AL CARGO DESEMPEÑADO EN LA
        ULTIMA EMPRESA QUE LABORO*
      </span>
      <TablaRiesgos
        listaRiesgos={contextoMedicoOcupacional.listaRiesgosFisicos}
        actualizarEstado={contextoMedicoOcupacional.setListaRiesgosFisicos}
        tituloContenedor={'RIESGOS FISICOS'}
        expandidoInicial={false}
        deshabilitar={contextoMedicoOcupacional.primerEmpleo}
        numeroPagina={2}
      />
      <TablaRiesgos
        listaRiesgos={contextoMedicoOcupacional.listaRiesgosBiologicos}
        actualizarEstado={contextoMedicoOcupacional.setListaRiesgosBiologicos}
        tituloContenedor={'RIESGOS BIOLÓGICOS'}
        expandidoInicial={false}
        deshabilitar={contextoMedicoOcupacional.primerEmpleo}
        numeroPagina={2}
      />
      <TablaRiesgos
        listaRiesgos={contextoMedicoOcupacional.listaRiesgosErgonomicos}
        actualizarEstado={contextoMedicoOcupacional.setListaRiesgosErgonomicos}
        tituloContenedor={'RIESGOS ERGONOMICOS'}
        expandidoInicial={false}
        deshabilitar={contextoMedicoOcupacional.primerEmpleo}
        numeroPagina={2}
      />
      <TablaRiesgos
        listaRiesgos={contextoMedicoOcupacional.listaRiesgosLocativos}
        actualizarEstado={contextoMedicoOcupacional.setListaRiesgosLocativos}
        tituloContenedor={'RIESGOS LOCATIVOS'}
        expandidoInicial={false}
        deshabilitar={contextoMedicoOcupacional.primerEmpleo}
        numeroPagina={2}
      />
      <TablaRiesgos
        listaRiesgos={contextoMedicoOcupacional.listaRiesgosNaturales}
        actualizarEstado={contextoMedicoOcupacional.setListaRiesgosNaturales}
        tituloContenedor={'RIESGOS NATURALES'}
        expandidoInicial={false}
        deshabilitar={contextoMedicoOcupacional.primerEmpleo}
        numeroPagina={2}
      />
      <TablaRiesgos
        listaRiesgos={contextoMedicoOcupacional.listaRiesgosSeguridad}
        actualizarEstado={contextoMedicoOcupacional.setListaRiesgosSeguridad}
        tituloContenedor={'RIESGOS DE SEGURIDAD'}
        expandidoInicial={false}
        deshabilitar={contextoMedicoOcupacional.primerEmpleo}
        numeroPagina={2}
      />
      <TablaRiesgos
        listaRiesgos={contextoMedicoOcupacional.listaOtrosRiesgos}
        actualizarEstado={contextoMedicoOcupacional.setListaOtrosRiesgos}
        tituloContenedor={'OTROS RIESGOS'}
        expandidoInicial={false}
        deshabilitar={contextoMedicoOcupacional.primerEmpleo}
        numeroPagina={2}
      />
    </div>
  )
}

export default RiesgosOcupacionalesAnteriores
