import React, {useState, useEffect, useContext, useCallback} from 'react'
import {ContextoEmpresa} from '../../contexto'
import {
  ObtenerCiudadPorId,
  ObtenerDepartamentoPorId,
  AgregarEmpresasACache,
  ObtenerEmpresaPorId,
  ObtenerEmpresasPorNit,
  ObtenerEmpresasPorNombre,
} from '../../cache/servicios-cache'
import {
  ObtenerEmpresaPorIdDB,
  ObtenerProximoCodigoInterno,
} from '../../microservicios'
import {BotonesFormularioPasos} from '../Botones'
import BotonLimpiarFiltro from '../BotonLimpiarFiltro'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import {validarTipoFacturacion, validarFecha} from '../../utilidades'
import moment from 'moment'

const SeleccionEmpresaPadre = ({numPag, mostrarImportar}) => {
  const contextoEmpresa = useContext(ContextoEmpresa)

  const [busquedaNit, setBusquedaNit] = useState('')
  const [empresasFiltradasNit, setEmpresasFiltradasNit] = useState([])
  const [busquedaRazonSocial, setBusquedaRazonSocial] = useState('')
  const [empresasFiltradasRazonSocial, setEmpresasFiltradasRazonSocial] =
    useState([])

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState({})
  const [importarDatosPadre, setImportarDatosPadre] = useState(false)

  useEffect(() => {
    if (contextoEmpresa.numeroPagina === numPag) {
      obtenerInformacionInicial().catch(console.error)
      contextoEmpresa.setformularioActualTieneErrores(
        empresaSeleccionada.Id === undefined
      )
    }
  }, [contextoEmpresa.numeroPagina])

  const obtenerInformacionInicial = useCallback(async () => {
    await AgregarEmpresasACache()
  }, [])

  const filtrarPorNit = async (e) => {
    setBusquedaNit(e.target.value)
    if (e.target.value.length >= 3) {
      await ObtenerEmpresasPorNit(e.target.value).then((res) => {
        setEmpresasFiltradasNit(
          res.map((empresa) => ({
            id: empresa.Id,
            filtro: empresa.Nit,
            tipo: 'nit',
          }))
        )
      })
    } else {
      setEmpresasFiltradasNit([])
    }
  }

  const filtrarPorRazonSocial = async (e) => {
    setBusquedaRazonSocial(e.target.value)
    if (e.target.value.length >= 3) {
      await ObtenerEmpresasPorNombre(e.target.value).then((res) => {
        setEmpresasFiltradasRazonSocial(
          res.map((empresa) => ({
            id: empresa.Id,
            filtro: empresa.Nombre,
            tipo: 'nombre',
          }))
        )
      })
    } else {
      setEmpresasFiltradasRazonSocial([])
    }
  }

  const seleccionarEmpresa = async (e) => {
    setBusquedaRazonSocial(e.tipo == 'nit' ? '' : e.filtro)
    setBusquedaNit(e.tipo == 'nit' ? e.filtro : '')
    await ObtenerEmpresaPorId(e.id).then(async (res) => {
      setEmpresaSeleccionada(res)
      setEmpresasFiltradasRazonSocial([])
      setEmpresasFiltradasNit([])
      contextoEmpresa.setformularioActualTieneErrores(false)
      let codigoInternoEmpresaHija = await ObtenerProximoCodigoInterno(
        'empresaHijaId:' + e.id
      )
      contextoEmpresa.setcodigoInternoHija(
        res.Nit + '-' + codigoInternoEmpresaHija.data
      )
      contextoEmpresa.setIdEmpresaPadre(res.Id)
    })
  }

  const activarImportarDatosPadre = async () => {
    if (!importarDatosPadre) {
      await ObtenerEmpresaPorIdDB(empresaSeleccionada.Id).then(async (res) => {
        let ciudad = await ObtenerCiudadPorId(res.data.ciudad)
        let departamento = await ObtenerDepartamentoPorId(
          ciudad[0].DepartamentoId
        )
        contextoEmpresa.setformularioDatos({
          direccion: res.data.direccion,
          pais: 1,
          departamento: departamento.Nombre,
          ciudad: ciudad[0].Nombre,
          telefono: res.data.telefono,
          correo: res.data.correo,
        })
        contextoEmpresa.setformularioFacturacion({
          factura: res.data.facturacionEmpresa?.razonSocial,
          RUT53: res.data.facturacionEmpresa?.rut53,
          RUT54: res.data.facturacionEmpresa?.rut54,
          codigoPostal: res.data.facturacionEmpresa?.codigoPostal ?? 0,
          actividadEconomica: res.data.facturacionEmpresa?.actividadEconomica,
          matriculaMercantil: res.data.facturacionEmpresa?.matriculaMercantil,
          correoFacturacion: res.data.facturacionEmpresa?.correoFacturacion,
          valorContrato: res.data.facturacionEmpresa?.valorContrato,
          condicionesPago: res.data.facturacionEmpresa?.condicionPago ?? null,
          descuento:
            res.data.facturacionEmpresa?.descuento === 0
              ? ''
              : res.data.facturacionEmpresa?.descuento,
          impuestos: res.data.facturacionEmpresa?.impuesto,
          tipoFacturacion: validarTipoFacturacion(
            res.data.facturacionEmpresa?.tipoFacturacion
          ),
          fechaCorte: validarFecha(res.data.facturacionEmpresa?.fechaCorte)
            ? moment(res.data.facturacionEmpresa?.fechaCorte).format(
                'YYYY-MM-DD'
              )
            : '',

          observacionesFacturacion: res.data.facturacionEmpresa?.observaciones,
          nombreContacto: res.data.facturacionEmpresa?.nombreContacto,
          correoContacto: res.data.facturacionEmpresa?.correoContacto,
          telefonoContacto: res.data.facturacionEmpresa?.telefonoContacto,
          prefactura: res.data.facturacionEmpresa?.prefactura ?? false,
          modalidadFacturacion:
            res.data.facturacionEmpresa?.modalidadFacturacion ?? null,
          condicionesPrefactura:
            res.data.facturacionEmpresa?.condicionesPrefactura ?? null,
          licitacion: res.data.facturacionEmpresa?.licitacion ?? false,
        })

        contextoEmpresa.setformularioAdicional({
          estaActiva: res.data.estaActiva,
          motivoDesactivacion: res.data.motivoDesactivacion,
          ejecutivoComercial: res.data.ejecutivoComercial,
          observaciones: res.data.observaciones,
        })

        contextoEmpresa.setActualizar(!contextoEmpresa.actualizar)
      })
    } else {
      contextoEmpresa.setformularioDatos({
        razonSocial: '',
        nit: '',
        nombreEmpresaHC: '',
        direccion: '',
        pais: 1,
        departamento: '',
        ciudad: '',
        telefono: '',
        correo: '',
      })
      contextoEmpresa.setformularioFacturacion({
        factura: '',
        RUT53: '',
        RUT54: '',
        codigoPostal: '',
        actividadEconomica: '',
        matriculaMercantil: '',
        correoFacturacion: '',
        valorContrato: '',
        condicionesPago: '',
        descuento: '',
        impuestos: '',
        tipoFacturacion: '',
        observacionesFacturacion: '',
        nombreContacto: '',
        correoContacto: '',
        telefonoContacto: '',
      })
      contextoEmpresa.setformularioAdicional({
        estaActiva: true,
        motivoDesactivacion: '',
        ejecutivoComercial: '',
        observaciones: '',
      })
      contextoEmpresa.setActualizar(!contextoEmpresa.actualizar)
    }
    setImportarDatosPadre(!importarDatosPadre)
  }

  const resetFilter = () => {
    setBusquedaNit('')
    setBusquedaRazonSocial('')
    setEmpresaSeleccionada({})
    setEmpresasFiltradasRazonSocial([])
    setEmpresasFiltradasNit([])
    setImportarDatosPadre(false)
  }

  const columns = React.useMemo(() => [
    {
      Header: () => <EncabezadoFondo titulo={'NIT'} paddingFondoClaro={''} />,
      accessor: 'Nit',
      className: 'w-4/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Razón Social'} paddingFondoClaro={''} />
      ),
      accessor: 'Nombre',
      className: 'w-4/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Activa o Inactiva'} paddingFondoClaro={''} />
      ),
      accessor: 'EstaActiva',
      className: 'w-4/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <div className="w-full flex justify-center items-center">
          {row.row.original.EstaActiva ? 'ACTIVA' : 'INACTIVA'}
        </div>
      ),
    },
  ])

  return (
    <>
      <form className="w-full flex justify-center flex-wrap items-center">
        <div className="flex justify-evenly flex-wrap items-center w-7/12">
          <CampoFiltrarEspañol
            titulo={'NIT'}
            estilosPersonalizados={'w-4/5 md:w-4/12 m-5'}
            estilosInput={'border-cendiatra'}
            placeholder={'Ingrese código (autocompletar)'}
            tipo={'number'}
            valorDelCampoFiltro={busquedaNit}
            desactivarOtroFiltro={''}
            informacionFiltrada={empresasFiltradasNit}
            handleChange={filtrarPorNit}
            handleOptionChange={seleccionarEmpresa}
            id={1}
          />
          <CampoFiltrarEspañol
            titulo={'Razón social'}
            estilosPersonalizados={'w-4/5 md:w-4/12 m-5'}
            estilosInput={'border-cendiatra'}
            placeholder={'Descripción (autocompletar)'}
            tipo={'text'}
            valorDelCampoFiltro={busquedaRazonSocial}
            desactivarOtroFiltro={''}
            informacionFiltrada={empresasFiltradasRazonSocial}
            handleChange={filtrarPorRazonSocial}
            handleOptionChange={seleccionarEmpresa}
            id={2}
          />
          <BotonLimpiarFiltro
            titulo={'Limpiar'}
            reiniciarFiltro={() => resetFilter()}
          />
        </div>

        <TablaConPaginacionYFiltros
          columns={columns}
          data={empresaSeleccionada.Id ? [empresaSeleccionada] : []}
        />
        <div className={empresaSeleccionada.Id ? 'w-full' : 'h-80'}>
          <div
            className={
              empresaSeleccionada.Id && mostrarImportar ? 'w-full' : 'hidden'
            }
          >
            <div className="rounded-md -space-y-px flex justify-evenly flex-wrap w-full my-10 items-center ">
              <div className="w-11/12 bg-cendiatra-verde-3  rounded-xl flex flex-wrap font-bold h-10 items-center">
                <div className="w-full text-center text-xl ">
                  <span className="text-white">IMPORTAR</span>
                </div>
              </div>
            </div>
            <div className="rounded-md -space-y-px flex justify-center flex-wrap w-full mb-10 items-center ">
              <div className="border border-cendiatra rounded-custom w-9/12 2xl:w-6/12 h-28 flex justify-center items-center">
                <div className="text-cendiatra text-xl font-bold w-10/12">
                  <span>Desea importar los datos de la empresa padre</span>
                </div>

                <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    className={
                      !importarDatosPadre
                        ? ' absolute block w-8 h-8 rounded-full bg-noBg bg-center bg-cover appearance-none cursor-pointer'
                        : ' -right-0 border-newCendiatra absolute block w-8 h-8 rounded-full bg-yesBg bg-center bg-cover border-4 appearance-none cursor-pointer'
                    }
                    onClick={activarImportarDatosPadre}
                  />
                  <label
                    className={
                      !importarDatosPadre
                        ? 'block overflow-hidden h-4 w-12 mt-2  rounded-full bg-gray-300 cursor-pointer'
                        : 'block overflow-hidden h-4 w-12 mt-2 rounded-full bg-toggleActive cursor-pointer'
                    }
                    onClick={activarImportarDatosPadre}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="pt-6 w-full flex justify-center items-center">
        <BotonesFormularioPasos contexto={contextoEmpresa} />
      </div>
    </>
  )
}

export default SeleccionEmpresaPadre
