// Librerías y hooks externos
import React, {useContext, useMemo, useState} from 'react'

// Constantes y utilidades

import {
  TEXTO_VISUAL,
  campoDeConfiguracionDeComponentes,
  regexTextoConPreguntaYOtrosCaracteresMas,
} from '../../../constantes'

// Imágenes y recursos
import removeButtonImg from '../../../imagenes/removeButton.png'
import addButtonImg from '../../../imagenes/addButton.png'

// Contexto
import {ContextApplication, ContextoPlantilla} from '../../../contexto'

// Componentes internos - Botones
import {Boton} from '../../Botones'
import BotonConImagen from '../../BotonConImagen'
import {ListaDesplegable} from '../../inputs'
import {LabelNumero} from '../../Labels'
import EditarPropiedades from './EditarPropiedades.jsx'
import EditarPropiedadesComponentes from './EditarPropiedadesComponentes.jsx'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol.jsx'

const PlantillaCampo = ({
  numeroPestana,
  numeroSeccion,
  numeroCampo,
  numeroPagina,
}) => {
  const contextoPlantilla = useContext(ContextoPlantilla)
  const contextoAplicacion = useContext(ContextApplication)
  const campo = contextoPlantilla.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  let idCampo =
    'nombreCampo' +
    numeroPestana +
    '.' +
    numeroSeccion +
    '.' +
    campo.numeroCampo

  const [valoresFiltrados, setValoresFiltrados] = useState([])

  const actualizarPropiedadCampo = (plantillaActual, propiedad, nuevoValor) => {
    return {
      ...plantillaActual,
      pestanas: plantillaActual.pestanas.map((pestana) => {
        if (pestana.numeroPestana == numeroPestana) {
          return {
            ...pestana,
            secciones: pestana.secciones.map((seccion) => {
              if (seccion.numeroSeccion == numeroSeccion) {
                return {
                  ...seccion,
                  campos: seccion.campos.map((campo) => {
                    if (campo.numeroCampo == numeroCampo) {
                      return {
                        ...campo,
                        [propiedad]: nuevoValor,
                      }
                    }
                    return campo
                  }),
                }
              }
              return seccion
            }),
          }
        }
        return pestana
      }),
    }
  }

  const actualizarTituloCampo = (nuevoValor) => {
    contextoPlantilla.setPlantilla(
      actualizarPropiedadCampo(
        contextoPlantilla.plantilla,
        'tituloCampo',
        nuevoValor.target.value?.toUpperCase() ?? ''
      )
    )

    if (
      nuevoValor.target.value.length >= 3 &&
      contextoPlantilla.camposPreCarga &&
      contextoPlantilla.camposPreCarga.length > 0
    ) {
      setValoresFiltrados(
        contextoPlantilla.camposPreCarga
          .filter(
            (x) =>
              x.tituloCampo
                .toUpperCase()
                .indexOf(nuevoValor.target.value?.toUpperCase()) > -1
          )
          .map((campoPreCarga, posicion) => {
            const tipoDeCampo = contextoPlantilla?.tiposCampo.find(
              (tipo) => tipo.Id === campoPreCarga.tipoCampoId
            )
            return {
              id: posicion,
              filtro: `${campoPreCarga.tituloCampo} ${
                tipoDeCampo ? `| ${tipoDeCampo.Nombre}` : ''
              }`,
            }
          })
      )
    } else {
      setValoresFiltrados([])
    }
  }

  const seleccionarOpcionCampo = (opcion) => {
    const nombreDeCampo = opcion?.filtro?.split('|')[0]?.trim() ?? ''
    let campoPreCarga = contextoPlantilla.camposPreCarga.find(
      (x) => x.tituloCampo.toUpperCase() === nombreDeCampo?.toUpperCase()
    )
    if (campoPreCarga) {
      let actualizarTituloCampo = actualizarPropiedadCampo(
        contextoPlantilla.plantilla,
        'tituloCampo',
        campoPreCarga.tituloCampo
      )
      let actualizarTipoCampo = actualizarPropiedadCampo(
        actualizarTituloCampo,
        'tipoCampoId',
        campoPreCarga.tipoCampoId
      )
      let actualizarRequerido = actualizarPropiedadCampo(
        actualizarTipoCampo,
        'requerido',
        campoPreCarga.requerido
      )
      let actualizarReplicable = actualizarPropiedadCampo(
        actualizarRequerido,
        'replicable',
        campoPreCarga.replicable
      )
      let actualizarMostrarEnCertificado = actualizarPropiedadCampo(
        actualizarReplicable,
        'mostrarEnCertificado',
        campoPreCarga.mostrarEnCertificado
      )
      let actualizarAtributos = actualizarPropiedadCampo(
        actualizarMostrarEnCertificado,
        'atributos',
        campoPreCarga.atributos
      )
      contextoPlantilla.setPlantilla(actualizarAtributos)
    }
  }

  const actualizarTipoCampo = (e) => {
    let plantillaSinAtributos = actualizarPropiedadCampo(
      contextoPlantilla.plantilla,
      'atributos',
      {}
    )
    contextoPlantilla.setPlantilla(
      actualizarPropiedadCampo(
        plantillaSinAtributos,
        'tipoCampoId',
        e.target.value
      )
    )
  }

  const agregarCampo = () => {
    let nuevoCampo = {
      tituloCampo: '',
      numeroCampo: numeroCampo + 1,
      tipoCampoId: -1,
      requerido: false,
      mostrarEnCertificado: false,
      atributos: {},
      replicable: false,
    }
    let listaCampos = [
      ...contextoPlantilla.plantilla.pestanas
        .find((x) => x.numeroPestana == numeroPestana)
        .secciones.find((x) => x.numeroSeccion == numeroSeccion).campos,
    ].map((campo) => {
      if (campo.numeroCampo > numeroCampo) {
        return {
          ...campo,
          numeroCampo: campo.numeroCampo + 1,
        }
      }
      return campo
    })
    listaCampos.splice(numeroCampo, 0, nuevoCampo)
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: contextoPlantilla.plantilla.pestanas.map((pestana) => {
        if (pestana.numeroPestana == numeroPestana) {
          return {
            ...pestana,
            secciones: pestana.secciones.map((seccion) => {
              if (seccion.numeroSeccion == numeroSeccion) {
                return {
                  ...seccion,
                  campos: listaCampos,
                }
              }
              return seccion
            }),
          }
        }
        return pestana
      }),
    })
  }

  const eliminarCampo = () => {
    let listaCampos = [
      ...contextoPlantilla.plantilla.pestanas
        .find((x) => x.numeroPestana == numeroPestana)
        .secciones.find((x) => x.numeroSeccion == numeroSeccion)
        .campos.filter((x) => x.numeroCampo != numeroCampo),
    ].map((campo) => {
      if (campo.numeroCampo > numeroCampo) {
        return {
          ...campo,
          numeroCampo: campo.numeroCampo - 1,
        }
      }
      return campo
    })
    if (listaCampos.length == 0) {
      return
    }
    contextoPlantilla.setPlantilla({
      ...contextoPlantilla.plantilla,
      pestanas: contextoPlantilla.plantilla.pestanas.map((pestana) => {
        if (pestana.numeroPestana == numeroPestana) {
          return {
            ...pestana,
            secciones: pestana.secciones.map((seccion) => {
              if (seccion.numeroSeccion == numeroSeccion) {
                return {
                  ...seccion,
                  campos: listaCampos,
                }
              }
              return seccion
            }),
          }
        }
        return pestana
      }),
    })
    contextoPlantilla.setErrores([])
  }

  const manejarGuardar = (
    requerido,
    mostrarEnCertificado,
    replicable,
    atributos
  ) => {
    let plantillaConRequerido = actualizarPropiedadCampo(
      contextoPlantilla.plantilla,
      'requerido',
      requerido
    )

    let plantillaConMostrarEnCertificado = actualizarPropiedadCampo(
      plantillaConRequerido,
      'mostrarEnCertificado',
      mostrarEnCertificado
    )

    let plantillaConEsReplicable = actualizarPropiedadCampo(
      plantillaConMostrarEnCertificado,
      'replicable',
      replicable
    )

    let plantillaConAtributos = actualizarPropiedadCampo(
      plantillaConEsReplicable,
      'atributos',
      atributos
    )
    contextoPlantilla.setPlantilla(plantillaConAtributos)
    contextoAplicacion.setModal({abierto: false, titulo: '', botones: []})
  }

  const manejarRegresar = () => {
    contextoAplicacion.setModal({abierto: false, titulo: '', botones: []})
  }

  const componenteEditarPropiedades = useMemo(() => {
    const tipoCampo =
      contextoPlantilla.tiposCampo?.find(
        (t) => t.Id === parseInt(campo.tipoCampoId)
      )?.Nombre ?? ''

    const debeConfigurarComponente =
      campoDeConfiguracionDeComponentes[tipoCampo] ?? false

    return !debeConfigurarComponente ? (
      <EditarPropiedades
        campo={campo}
        funcionGuardar={manejarGuardar}
        funcionRegresar={manejarRegresar}
        numeroPagina={numeroPagina}
      />
    ) : (
      <EditarPropiedadesComponentes
        campo={campo}
        funcionGuardar={manejarGuardar}
        funcionRegresar={manejarRegresar}
        numeroPagina={numeroPagina}
      />
    )
  }, [campo, contextoPlantilla.plantilla])

  const editarPropiedades = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo:
        'PROPIEDADES ' +
        contextoPlantilla.tiposCampo.find((x) => x.Id == campo.tipoCampoId)
          ?.Nombre,
      contenido: componenteEditarPropiedades,
      botones: [],
    })
  }

  const validarAtributo = (atributo) => {
    return atributo != null && atributo != undefined && atributo != ''
  }

  const validarOpciones = (listaOpciones, fuenteDatos = '') => {
    if (
      fuenteDatos?.toUpperCase()?.trim() ===
        TEXTO_VISUAL.TEXTO_GENERAL.ELEGIR_MANUALMENTE.trim() &&
      !listaOpciones?.length
    ) {
      return false
    }

    if (!listaOpciones) {
      return true
    }
    let validacion = true
    listaOpciones.forEach((opcion) => {
      validacion = !validacion ? validacion : validarAtributo(opcion?.valor)
    })
    return validacion
  }

  const validarRangos = (listaRangos) => {
    if (!listaRangos) {
      return false
    }
    let validacion = true
    listaRangos.forEach((rango) => {
      validacion = !validacion
        ? validacion
        : validarAtributo(rango?.rangoInferior) &&
          validarAtributo(rango?.rangoSuperior) &&
          validarAtributo(rango?.valor)
    })
    return validacion
  }

  const validarAtributos = () => {
    let validacion = true
    const validaciones = {
      3:
        validarAtributo(campo.atributos?.fuenteDatos) &&
        validarOpciones(
          campo.atributos?.listaOpciones,
          campo.atributos?.fuenteDatos ?? ''
        ),
      4:
        validarAtributo(campo.atributos?.fuenteDatos) &&
        validarOpciones(
          campo.atributos?.listaOpciones,
          campo.atributos?.fuenteDatos ?? ''
        ),
      9: validarAtributo(campo.atributos?.formula),
      10:
        validarAtributo(campo.atributos?.campoReferencia) &&
        validarRangos(campo.atributos?.listaRangos),
      11: validarAtributo(campo.atributos?.componente),
    }

    validacion = validaciones[Number(campo.tipoCampoId)] ?? true

    let error = idCampo + 'propiedades'
    if (validacion && contextoPlantilla.errores.includes(error)) {
      contextoPlantilla.setErrores(
        contextoPlantilla.errores.filter((x) => x != error)
      )
    }
    if (!validacion && !contextoPlantilla.errores.includes(error)) {
      contextoPlantilla.setErrores([...contextoPlantilla.errores, error])
    }
    return validacion
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap space-x-2">
        <LabelNumero
          numero={numeroPestana + '.' + numeroSeccion + '.' + campo.numeroCampo}
          esHeader={false}
        />
        <CampoFiltrarEspañol
          id={idCampo + 'filtro'}
          estilosPersonalizados={'w-5/12'}
          estilosInput={`mb-2 ${
            contextoPlantilla.errores.includes(idCampo + 'regex') ||
            !campo.tituloCampo ||
            (contextoPlantilla.errores &&
              contextoPlantilla.errores.includes(idCampo))
              ? 'border-cendiatra-rojo-1'
              : 'border-cendiatra'
          }`}
          tipo={'text'}
          placeholder={'NOMBRE DEL CAMPO'}
          valorDelCampoFiltro={campo.tituloCampo}
          handleChange={actualizarTituloCampo}
          handleOptionChange={seleccionarOpcionCampo}
          desactivarOtroFiltro={''}
          informacionFiltrada={valoresFiltrados}
          validarRegex={true}
          contexto={contextoPlantilla}
          numeroPagina={numeroPagina}
          requerido={true}
          regex={regexTextoConPreguntaYOtrosCaracteresMas}
          mensajeErrorRegex="Se permiten estos caracteres: + ( ) [ ] ¿ ? : < > % , / - . "
        />
        <ListaDesplegable
          id={idCampo + 'lista'}
          onChange={actualizarTipoCampo}
          estilosContenedor={'w-3/12'}
          estilosLista={`appearance-none rounded relative block w-full  p-1.5 border ${
            campo.tipoCampoId != '' && campo.tipoCampoId != -1
              ? 'border-cendiatra'
              : 'border-cendiatra-rojo-1'
          } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
          opciones={contextoPlantilla.tiposCampo}
          valor={campo.tipoCampoId}
          placeholder={'SELECCIONAR TIPO DE CAMPO'}
          contexto={contextoPlantilla}
          numeroPagina={numeroPagina}
        />
        <Boton
          titulo="EDITAR PROPIEDADES"
          lineaPrimerCaracter={false}
          habilitado={campo.tipoCampoId && campo.tipoCampoId !== -1}
          funcionCLick={editarPropiedades}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={`${
            validarAtributos() ? 'bg-btnBg' : 'bg-cendiatra-rojo-1'
          } `}
          tipo={'button'}
          tamanoTexto={'text-sm'}
          ancho={'w-2/12'}
          alto={`h-10 content-end items-end border ${
            validarAtributos() ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
          } `}
        />
        <BotonConImagen
          estilosContenedor={'w-10 pt-2'}
          imagen={removeButtonImg}
          medio={true}
          funcionAEjecutar={eliminarCampo}
          textoAlternativo={'eliminar'}
        />
        <BotonConImagen
          estilosContenedor={'w-6 pt-2'}
          imagen={addButtonImg}
          medio={true}
          funcionAEjecutar={agregarCampo}
          textoAlternativo={'agregar'}
        />
      </div>
    </div>
  )
}

export default PlantillaCampo
