import {useMemo, useState, useContext} from 'react'
import FiltroDatosBasicosPaciente from '../../componentes/Filtros/FiltroDatosBasicosPaciente'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import TablaInformacionExamenesAtendidos from '../../componentes/VerModificarExamenesAtendidos/TablaInformacionExamenesAtendidos'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {FachadaObtenerAtencionesUsuarioFinalizadas} from '../../microservicios/Fachada'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'
import {ContextApplication} from '../../contexto'

export default function VerModificarExamenesAtendidos() {
  const {accounts} = useMsal()
  const contextoAplicacion = useContext(ContextApplication)
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const sedeId = useMemo(
    () => fnObtenerInformacionSessionOLocalStorage('sedeId', rol),
    [rol]
  )

  const [datosExamenes, setDatosExamenes] = useState([])

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const funcionBuscarInformacion = async (datos) => {
    if ((!datos?.nombres || !datos?.apellidos) && !datos?.numeroDocumento) {
      return contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <div className="text-center w-full">
            <h3 className="text-cendiatra  text-3xl font-bold normal-case">
              La búsqueda debe
            </h3>
            <h3 className="text-cendiatra mb-6 text-3xl font-bold normal-case">
              hacerse con nombre y apellido
            </h3>
            <p className="text-cendiatra-verde-3 text-xl my-10 font-bold normal-case">
              Debe diligenciar los dos campos para realizar la búsqueda
            </p>
          </div>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModal,
          },
        ],
      })
    }
    try {
      setDatosExamenes([])
      const respuesta = await FachadaObtenerAtencionesUsuarioFinalizadas({
        numeroDocumento: datos.numeroDocumento,
        nombre: datos.nombres,
        apellidos: datos.apellidos,
        sedeId,
      })
      if (respuesta.status === 200) {
        if (respuesta.data?.length || !respuesta.data?.length) {
          setDatosExamenes(respuesta.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full">
      <ContenedorPagina
        tituloPagina={
          TEXTO_VISUAL.BARRA_NAVEGACION.MEDICO_OCUPACIONAL
            .VER_MODIFICAR_EXAMENES_ATENDIDOS.TITULO
        }
      >
        <div className="w-full pb-10">
          <FiltroDatosBasicosPaciente
            funcionBuscarInformacion={funcionBuscarInformacion}
            funcionLimpiarInformacion={() => setDatosExamenes([])}
          />
        </div>
        <div className="w-full">
          <TablaInformacionExamenesAtendidos datosExamenes={datosExamenes} />
        </div>
      </ContenedorPagina>
    </div>
  )
}
