import {getRestApi} from './ApiAxios'

export const ObtenerExamenPorServicioAtencionId = async (Id) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'examen-function/ObtenerExamenPorServicioAtencionId?servicioAtencionId=' +
      Id
  )
}

export const GuardadoParcialExamenPreIngreso = async (examenPreingreso) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'examen-function/GuardarParcialmente',
    examenPreingreso
  )
}

export const ObtenerInformacionInterconsultaPorServicioAtencionId = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'examen-function/ObtenerInformacionInterconsultaPorServicioAtencionId',
    {params: {servicioAtencionId}}
  )
}

export const ObtenerInformacionFormulaMedicaPorServicioAtencionId = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'examen-function/ObtenerInformacionFormulaMedicaPorServicioAtencionId',
    {params: {servicioAtencionId}}
  )
}

export const ObtenerInformacionFormulaOptometricaPorServicioAtencionId = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'examen-function/ObtenerInformacionFormulaOptometricaPorServicioAtencionId',
    {params: {servicioAtencionId}}
  )
}

export const ObtenerDatosExamenPorServicioAtencionId = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'examen-function/ObtenerDatosExamenPorServicioAtencionId',
    {params: {servicioAtencionId}}
  )
}

export const GenerarIncapacidadMedica = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'patch',
    url: 'examen-function/GuardarIncapacidadMedica',
    data: payload,
  })
}

export const ObtenerIncapacidadMedicaPorServicioAtencionId = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'examen-function/ObtenerIncapacidadMedicaPorServicioAtencionId',
    {params: {servicioAtencionId}}
  )
}

export const ObtenerSolicitudValoracionPorServicioAtencionId = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'examen-function/ObtenerSolicitudValoracionPorServicioAtencionId',
    {params: {servicioAtencionId}}
  )
}

export const GuardarSolicitudValoracion = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'patch',
    url: 'examen-function/GuardarSolicitudValoracion',
    data: payload,
  })
}
