import {getRestApi} from './ApiAxios'

export const getPatientInfo = async (patientData) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    '/GetPacient?document_type=' +
      patientData.document_type +
      '&document_number=' +
      patientData.document_number
  )
}

export const registerNewPatient = async (data) => {
  const apiReq = await getRestApi()
  return await apiReq.post('/PostPacient', data)
}

export const postNewOrderService = async (serviceData) => {
  const apiReq = await getRestApi()
  return await apiReq.post('/PostOrderService', serviceData)
}
