import {dbComun} from '../bases-datos/dbComun'

export const ObtenerLocalidadPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('Localidades')
      .filter(
        ({Nombre}) =>
          Nombre.toLocaleUpperCase().indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerLocalidadPorId = async (id) => {
  return Promise.resolve(dbComun.table('Localidades').get({Id: id}))
}
