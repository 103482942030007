import React from 'react'
import {BotonesFormularioPasos} from '../Botones'
import {useHistory} from 'react-router-dom'
import {RUTAS_APP} from '../../constantes'

export default function Pestana({
  children,
  contexto,
  numeroPagina,
  clickGuardar,
  textoBotonUltimaPagina,
  textoBotonUltimaPaginaGerenteGeneral,
  deshabilitarBotonGuardar,
  renderOnValidacionPagina = false,
  idAtencion,
  esModificacion,
  mostrarBotonesEspecificos = true,
  esModificacionAtencion = false,
}) {
  const history = useHistory()

  const irAResumenTransaccionModificar = () => {
    history.push(RUTAS_APP.JEFE_DE_SEDE.RESUMEN_TRANSACCION_MODIFICAR, {
      idAtencion,
      modificacion: true,
    })
  }

  return (
    <div
      className={`w-full rounded-md ${
        contexto.numeroPagina === numeroPagina ? '' : 'hidden'
      } my-10 flex justify-between flex-wrap items-center`}
    >
      {!renderOnValidacionPagina
        ? children
        : renderOnValidacionPagina && contexto.numeroPagina === numeroPagina
        ? children
        : null}
      <div className="w-full flex justify-center items-center">
        {contexto.numeroPagina === 1 && esModificacion ? (
          <button
            className={`opacity-70 text-cendiatra group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-bold rounded-md  bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra m-2`}
            type="button"
            onClick={() => {
              irAResumenTransaccionModificar()
            }}
          >
            {' '}
            <u>A</u>trás
          </button>
        ) : null}
        <BotonesFormularioPasos
          contexto={contexto}
          clickGuardar={clickGuardar}
          textoBotonUltimaPagina={textoBotonUltimaPagina}
          textoBotonUltimaPaginaGerenteGeneral={
            textoBotonUltimaPaginaGerenteGeneral
          }
          deshabilitarBotonGuardar={deshabilitarBotonGuardar}
          mostrarBotonesEspecificos={mostrarBotonesEspecificos}
          esModificacionAtencion={esModificacionAtencion}
        />
      </div>
    </div>
  )
}
