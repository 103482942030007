import React from 'react'
import BotonSeleccionarSi from '../../imagenes/BotonSeleccionarSi.svg'
import BotonSeleccionarNo from '../../imagenes/BotonSeleccionarNo.svg'

const BotonOpcion = ({
  id,
  titulo,
  estaActivo,
  funcionClick,
  esHorizontal,
  deshabilitado,
  estilosContenedorImagen,
  centrarLabel = false,
  tamanoDivTitulo = 'w-1/2',
  tamanoDivBoton = 'w-1/2',
  posicionLabel = ''
}) => {
  return (
    <div className="w-full flex flex-wrap">
      <div className={`w-full flex flex-wrap justify-center items-center`}>
        <div
          className={`pl-2 flex flex-wrap ${
            esHorizontal ? tamanoDivTitulo : 'w-full'
          }  text-cendiatra-verde-2 text-14px text-center`}
        >
          <label className={`${centrarLabel ? 'w-full text-center' : posicionLabel}`}>
            {titulo}
          </label>
        </div>
        <div
          className={`flex items-center justify-center ${
            esHorizontal ? tamanoDivBoton : 'w-full'
          } align-middle select-none transition duration-200 ease-in`}
          onClick={() => {
            if (!deshabilitado) funcionClick(id)
          }}
        >
          <div
            className={`flex items-center ${
              esHorizontal ? 'justify-start' : 'justify-center'
            } w-full`}
            style={estilosContenedorImagen}
          >
            {estaActivo && !deshabilitado ? (
              <img
                className="h-12 cursor-pointer"
                src={BotonSeleccionarSi}
                alt="SI"
              />
            ) : (
              <img
                className="h-12 cursor-pointer"
                src={BotonSeleccionarNo}
                alt="NO"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BotonOpcion
