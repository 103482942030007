import React, {useContext} from 'react'
import FormularioCrearNotaCredito from '../../componentes/administradorContable/FormularioCrearNotaCredito'
import {ContextoNotaCredito, ContextApplication} from '../../contexto'
import {EmitirNotaCredito} from '../../microservicios/Facturacion'
import {useHistory} from 'react-router-dom'

const CrearNotaCredito = () => {
  const contextoNotaCredito = useContext(ContextoNotaCredito)

  const contextoAplicacion = useContext(ContextApplication)

  const history = useHistory()

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    contextoNotaCredito.setArregloArchivos([])
    history.go(0)
  }
  const manejarFinalizar = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    history.push('/main')
  }

  const crearNotaCredito = () => {
    const urlArchivos = contextoNotaCredito?.arregloArchivos.map(
      (archivo) => archivo.urlArchivo
    )
    let payload = {
      facturaId: contextoNotaCredito?.informacionFactura?.idFactura ?? 0,
      causaId: contextoNotaCredito?.datosFormulario?.causa ?? 0,
      nota: contextoNotaCredito?.datosFormulario?.nota ?? '',
      urlArchivos: urlArchivos ?? [],
    }
    EmitirNotaCredito(payload)
      .then((res) => {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: `NOTA CREDITO TOTAL: ${res.data} CREADA`,
          contenido: 'SE TRANSMITIRA AUTOMATICAMENTE',
          botones: [
            {
              nombre: 'Finalizar',
              click: manejarFinalizar,
            },
            {
              nombre: 'Regresar',
              click: manejaCerrarModal,
            },
          ],
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return <FormularioCrearNotaCredito funcionGuardar={crearNotaCredito} />
}

export default CrearNotaCredito
