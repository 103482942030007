import React from 'react'
import {LeyendaSemaforoConTooltip} from '../leyendas'

const ExamenesPaciente = ({
  datos,
  funcion,
  ocultarWarning = false,
  ocultarNombreTrabajador = false,
}) => {
  const establecerColor = (estadoExamen) => {
    switch (estadoExamen) {
      case 1:
        return 'bg-cendiatra-semaforo-rojo rounded-full h-3 w-3'
      case 2:
        return 'bg-cendiatra-semaforo-naranja rounded-full h-3 w-3'
      case 3:
        return 'bg-cendiatra-semaforo-amarillo rounded-full h-3 w-3'
      case 4:
        return 'bg-cendiatra-semaforo-verde rounded-full h-3 w-3'
      case 5:
      case 6:
        return manejarColorEstadoAtencionOcupado()
      default:
        return 'bg-cendiatra-semaforo-naranja rounded-full h-3 w-3'
    }
  }

  const manejarColorEstadoAtencionOcupado = () => {
    if (ocultarWarning) {
      return 'bg-cendiatra-semaforo-naranja rounded-full h-3 w-3'
    } else {
      return 'bg-alerta bg-contain bg-no-repeat h-4 w-4 -ml-1'
    }
  }

  return (
    <div className="w-full h-90% py-1 border border-cendiatra-verde-4 rounded-lg ">
      <div className="w-full h-full overflow-scroll overflow-x-hidden px-1">
        <div className="w-full flex items-center flex-wrap justify-start">
          {datos.map((examen) => (
            <div
              key={examen.servicioAtencionId}
              className="w-1/3 flex flex-wrap justify-start items-center bg-white"
            >
              <LeyendaSemaforoConTooltip
                colorFondo={`${establecerColor(examen.estadoExamen)}`}
                texto={examen?.tipoExamen?.toUpperCase() ?? ''}
                nombreTrabajador={examen.medico}
                redireccion={examen.especialidadMedico}
                estadoServicio={examen.estadoExamen}
                admiteTomaDeDatos={examen.admiteTomaDeDatos}
                funcion={() =>
                  funcion(examen.servicioAtencionId, examen.servicioId)
                }
                ocultarNombreTrabajador={ocultarNombreTrabajador}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExamenesPaciente
