import React from 'react'
import {CeldaConTooltipPersonalizable} from '../../Celdas'

export const DatosPaciente = ({datos}) => {
  return (
    <div className="w-full flex  items-center flex-wrap">
      {datos.map((item, index) => (
        <div key={index} className="w-1/4 flex-wrap">
          <div className="text-cendiatra text-xl font-bold mt-5">
            {item.titulo}
          </div>
          <div className="my-4 text-cendiatra-gris-1 text-xl uppercase line-clamp-1 w-3/4 h-6">
            {item.mostrarToolTip ? (
              <CeldaConTooltipPersonalizable
                align={'justify-start'}
                texto={item.contenido}
              />
            ) : (
              item.contenido
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default DatosPaciente
