import {useHistory} from 'react-router-dom'
import {useContext, useMemo} from 'react'
import BotonConImagen from '../BotonConImagen'
import IconoEditarActivado from '../../imagenes/iconoEditarVerde.svg'
import {BotonOpcion} from '../Botones'
import {ContextApplication} from '../../contexto'
import {RUTAS_APP, ROLES_PORTAL_CLIENTE} from '../../constantes'
import {ActualizarUsuarioB2C} from '../../microservicios'

function CeldaModulosGestionUsuariosPortalCliente({row, funcionRecargaDatos}) {
  const contextoApp = useContext(ContextApplication)
  const history = useHistory()

  const titulos = useMemo(
    () => ({
      ACTIVAR: 'Esta seguro de desactivar a:',
      DESACTIVAR: 'Esta seguro de activar a:',
    }),
    []
  )

  const preguntaProceso = (accion) => {
    contextoApp.setModal({
      abierto: true,
      titulo: titulos[accion] ?? '',
      contenido: (
        <div className="w-full">
          <h3>{row.original.nombres}</h3>
          <h3>
            USUARIO:{' '}
            {row.original?.userPrincipalName ?? row.original?.correoPersonal}
          </h3>
          <h3>EMPRESA: {row.original.empresa}</h3>
        </div>
      ),
      botones: [
        {
          nombre: 'No',
          click: () => {
            contextoApp.cerrarAppModal()
            history.push(
              RUTAS_APP.EJECUTIVA_COMERCIAL.GESTION_USUARIOS_PORTAL_CLIENTE
            )
          },
        },
        {
          nombre: 'Si',
          click: () => ejecutarAccion(!Boolean(row.original.activo)),
        },
      ],
    })
  }

  const ejecutarAccion = async (activo) => {
    try {
      const response = await ActualizarUsuarioB2C({
        guid: row.original?.guid,
        nombres: row.original?.nombres ?? null,
        rol:
          ROLES_PORTAL_CLIENTE.find(
            (x) =>
              x.id === row?.original?.rol || x.nombre === row?.original?.rol
          )?.nombre ?? '',
        activo: activo,
        correo: row.original?.correo ?? row.original?.correoPersonal ?? null,
        userPrincipalName: row.original?.userPrincipalName ?? null,
        cargo: row.original?.cargo ?? null,
        empresaId: row?.original?.empresaId ?? 0,
        contrasena: null,
      })

      if (response.status === 200) {
        funcionRecargaDatos()
        contextoApp.cerrarAppModal()
        history.push(
          RUTAS_APP.EJECUTIVA_COMERCIAL.GESTION_USUARIOS_PORTAL_CLIENTE
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-1/2">
        <BotonConImagen
          imagen={IconoEditarActivado}
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
          funcionAEjecutar={() =>
            history.push(
              RUTAS_APP.EJECUTIVA_COMERCIAL.CREAR_USUARIO_PORTAL_CLIENTE,
              {edicion: true, id: row.original.id}
            )
          }
        />
      </div>
      <div className="w-1/2">
        <BotonOpcion
          estaActivo={row.original.activo}
          funcionClick={() =>
            preguntaProceso(row.original.activo ? 'ACTIVAR' : 'DESACTIVAR')
          }
        />
      </div>
    </div>
  )
}

export default CeldaModulosGestionUsuariosPortalCliente
