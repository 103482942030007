import React, {useEffect, useContext, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {
  ObtenerEstadosOrganoSistema,
  ObtenerEstadosTunelCarpiano,
} from '../../../../cache/servicios-cache'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {ContenedorDesplegable} from '../../../Contenedores'
import {
  AreaDeTexto,
  Input,
  InputNumerico,
  ListaDesplegable,
  ListaDesplegableConObservaciones,
} from '../../../inputs'
import {regexBasicoV1} from '../../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../../constantes'

const ExamenFisico = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)
  const [opcionesOrganos, setOpcionesOrganos] = useState([])
  const [opcionesTinnel, setOpcionesTinnel] = useState([])

  const {
    setValue,
    watch,
    trigger,
    control,
    reset,
    getValues,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {
      peso: '',
      talla: '',
      imc: '',
      clasificacion: '',
      fc: '',
      ta: '',
    },
    mode: 'onChange',
  })

  useEffect(async () => {
    setOpcionesOrganos(await ObtenerEstadosOrganoSistema())
    setOpcionesTinnel(await ObtenerEstadosTunelCarpiano())
    trigger()
  }, [])

  useEffect(() => {
    reset(contextoMedicoOcupacional.examenFisico)
    trigger()
  }, [contextoMedicoOcupacional.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      trigger()
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, contextoMedicoOcupacional.numeroPagina])

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name == 'peso' || name == 'talla') {
        let imc = value.peso / (value.talla / 100) ** 2
        setValue('imc', imc)
        setValue('clasificacion', calcularClasificacion(imc))
      }
      contextoMedicoOcupacional.setExamenFisico(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const calcularClasificacion = (imc) => {
    if (imc < 18) {
      return 'BAJO PESO'
    } else if (imc <= 24) {
      return 'NORMAL'
    } else if (imc <= 29) {
      return 'SOBREPESO'
    } else if (imc <= 34) {
      return 'OBESIDAD GRADO I'
    } else if (imc <= 39) {
      return 'OBESIDAD GRADO II'
    } else {
      return 'OBESIDAD MÓRBIDA'
    }
  }

  const manejarCambioOrgano = (valor, id) => {
    contextoMedicoOcupacional.setListaOrganos(
      contextoMedicoOcupacional.listaOrganos.map((organo) => {
        if (organo.Id == id) {
          return {
            ...organo,
            EstadoOrganoId: valor,
          }
        }
        return organo
      })
    )
  }

  const manejarcambioObservaciones = (valor, id) => {
    contextoMedicoOcupacional.setListaOrganos(
      contextoMedicoOcupacional.listaOrganos.map((organo) => {
        if (organo.Id == id) {
          return {
            ...organo,
            Observaciones: valor,
          }
        }
        return organo
      })
    )
  }

  const manejarCambioTinnelDerecho = (valor) => {
    contextoMedicoOcupacional.setListaTinnelYPhalen({
      ...contextoMedicoOcupacional.listaTinnelYPhalen,
      tinnelDerecho: valor,
    })
  }

  const manejarCambioTinnelIzquierdo = (valor) => {
    contextoMedicoOcupacional.setListaTinnelYPhalen({
      ...contextoMedicoOcupacional.listaTinnelYPhalen,
      tinnelIzquierdo: valor,
    })
  }

  const manejarCambioPhalenDerecho = (valor) => {
    contextoMedicoOcupacional.setListaTinnelYPhalen({
      ...contextoMedicoOcupacional.listaTinnelYPhalen,
      phalenDerecho: valor,
    })
  }

  const manejarCambioPhalenIzquierdo = (valor) => {
    contextoMedicoOcupacional.setListaTinnelYPhalen({
      ...contextoMedicoOcupacional.listaTinnelYPhalen,
      phalenIzquierdo: valor,
    })
  }

  return (
    <div className="w-11/12 flex flex-wrap justify-start items-center ml-16">
      <ContenedorDesplegable
        titulo={'SIGNOS VITALES'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
      >
        <div className="w-3/5 flex flex-wrap justify-start items-center">
          <Controller
            name="peso"
            control={control}
            rules={{
              required: true,
              pattern: /^[0-9]+$/,
            }}
            render={({field: {onChange, value}}) => (
              <div className="w-1/2 pr-10 mb-2">
                <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.peso?.type === 'pattern' &&
                    'Solo se permiten números'}
                </span>
                <Input
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'peso')
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'text'}
                  placeholder={'NUMÉRICO'}
                  titulo={'PESO (KG)*'}
                  onChange={onChange}
                  valor={value}
                />
              </div>
            )}
          />
          <Controller
            name="talla"
            control={control}
            rules={{
              required: true,
              pattern: /^[0-9]+$/,
            }}
            render={({field: {onChange, value}}) => (
              <div className="w-1/2 pr-10 mb-2">
                <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.talla?.type === 'pattern' &&
                    'Solo se permiten números'}
                </span>
                <Input
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'talla')
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'text'}
                  placeholder={'NUMÉRICO'}
                  titulo={'TALLA (CM)*'}
                  onChange={onChange}
                  valor={value}
                />
              </div>
            )}
          />
          <Controller
            name="imc"
            control={control}
            render={({field: {onChange, value}}) => (
              <div className="w-1/2 pr-10 mb-2">
                <InputNumerico
                  estilosContenedor={'w-full'}
                  estilosInput={
                    'appearance-none rounded relative block w-full p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  placeholder={'Calcula fórmula automática numérica'}
                  titulo={'IMC (M2)'}
                  onValueChange={onChange}
                  valor={value}
                  deshabilitado={true}
                />
              </div>
            )}
          />
          <Controller
            name="clasificacion"
            control={control}
            render={({field: {onChange, value}}) => (
              <div className="w-1/2 pr-10 mb-2">
                <Input
                  estilosContenedor={'w-full'}
                  estilosInput={
                    'appearance-none rounded relative block w-full p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'text'}
                  placeholder={'Calcula fórmula automática texto'}
                  titulo={'CLASIFICACIÓN'}
                  onChange={onChange}
                  valor={value}
                  deshabilitado={true}
                />
              </div>
            )}
          />
          <Controller
            name="fc"
            control={control}
            rules={{
              required: true,
              pattern: /^[0-9]+$/,
            }}
            render={({field: {onChange, value}}) => (
              <div className="w-1/2 pr-10 mb-2">
                <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.fc?.type === 'pattern' && 'Solo se permiten números'}
                </span>
                <Input
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'fc')
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'text'}
                  placeholder={'NUMÉRICO'}
                  titulo={'FC (MIN)*'}
                  onChange={onChange}
                  valor={value}
                />
              </div>
            )}
          />
          <Controller
            name="ta"
            control={control}
            rules={{
              required: true,
              pattern: regexBasicoV1,
            }}
            render={({field: {onChange, value}}) => (
              <div className="w-1/2 pr-10 mb-2">
                <span className="flex text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.ta?.type === 'pattern' &&
                    'Se permiten estos caracteres:'}
                </span>
                <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.ta?.type === 'pattern' &&
                    TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1.split(
                      ':'
                    )[1].trim()}
                </span>
                <Input
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'ta')
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'text'}
                  placeholder={'ALFANUMÉRICO'}
                  titulo={'TA (MMHG)*'}
                  onChange={onChange}
                  valor={value}
                />
              </div>
            )}
          />
        </div>
      </ContenedorDesplegable>
      <ContenedorDesplegable
        titulo={'ÓRGANO O SISTEMA'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
      >
        <div className="w-11/12 flex flex-wrap justify-start items-center">
          {contextoMedicoOcupacional.listaOrganos.map((organo, posicion) => (
            <div
              className={`w-1/3 flex pb-3 ${
                (posicion + 1) % 3 != 0 ? 'pr-10' : ''
              }`}
            >
              <ListaDesplegableConObservaciones
                id={organo.Id}
                titulo={organo.Nombre}
                opciones={opcionesOrganos}
                valor={organo.EstadoOrganoId}
                valorNoObservaciones={1}
                observaciones={organo.Observaciones}
                cambioLista={manejarCambioOrgano}
                cambioObservaciones={manejarcambioObservaciones}
              />
            </div>
          ))}
          <Controller
            name="hallazgos"
            control={control}
            rules={{
              required: false,
              pattern: regexBasicoV1,
            }}
            render={({field: {onChange, value}}) => (
              <AreaDeTexto
                estilosContenedor={`w-full`}
                estilosAreaDeTexto={
                  Object.keys(errors).find((element) => element === 'hallazgos')
                    ? 'h-20 mt-3 rounded w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
                    : 'h-20 mt-3 rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
                }
                titulo={'DESCRIPCIÓN DE HALLAZGOS ENCONTRADOS'}
                placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
                cambioValor={onChange}
                valor={value}
              />
            )}
          />
          <span className="text-cendiatra-semaforo-rojo w-full text-13px my-2 ">
            {errors.hallazgos?.type === 'pattern' &&
              TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
          </span>
        </div>
      </ContenedorDesplegable>
      <ContenedorDesplegable
        titulo={'TINNEL'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
      >
        <div className="w-3/5 flex flex-wrap justify-start items-center">
          <ListaDesplegable
            funcion={(e) => {
              manejarCambioTinnelDerecho(e.target.value)
            }}
            estilosContenedor={'w-1/2 pr-10'}
            estilosLista={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
            }
            titulo={'TINNEL DERECHO'}
            opciones={opcionesTinnel}
            valor={contextoMedicoOcupacional.listaTinnelYPhalen.tinnelDerecho}
            deshabilitado={false}
          />
          <ListaDesplegable
            funcion={(e) => {
              manejarCambioTinnelIzquierdo(e.target.value)
            }}
            estilosContenedor={'w-1/2 pr-10'}
            estilosLista={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
            }
            titulo={'TINNEL IZQUIERDO'}
            opciones={opcionesTinnel}
            valor={contextoMedicoOcupacional.listaTinnelYPhalen.tinnelIzquierdo}
            deshabilitado={false}
          />
        </div>
      </ContenedorDesplegable>
      <ContenedorDesplegable
        titulo={'PHALEN'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
      >
        <div className="w-3/5 flex flex-wrap justify-start items-center">
          <ListaDesplegable
            funcion={(e) => {
              manejarCambioPhalenDerecho(e.target.value)
            }}
            estilosContenedor={'w-1/2 pr-10'}
            estilosLista={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
            }
            titulo={'PHALEN DERECHO'}
            opciones={opcionesTinnel}
            valor={contextoMedicoOcupacional.listaTinnelYPhalen.phalenDerecho}
            deshabilitado={false}
          />
          <ListaDesplegable
            funcion={(e) => {
              manejarCambioPhalenIzquierdo(e.target.value)
            }}
            estilosContenedor={'w-1/2 pr-10'}
            estilosLista={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
            }
            titulo={'PHALEN IZQUIERDO'}
            opciones={opcionesTinnel}
            valor={contextoMedicoOcupacional.listaTinnelYPhalen.phalenIzquierdo}
            deshabilitado={false}
          />
        </div>
      </ContenedorDesplegable>
    </div>
  )
}

export default ExamenFisico
