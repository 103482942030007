import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {MsalProvider} from '@azure/msal-react'
import {initializeMsal, getMsalInstance} from './msalService'

initializeMsal()
  .then(() => {
    const msalInstance = getMsalInstance()

    ReactDOM.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  })
  .catch((error) => {
    console.error('Failed to load app configuration', error)
    // Manejo del error o mostrar una pantalla de error
  })

//Disable service worker mean while, we're not using this
// ServiceWorker()
