import React from 'react'
import {LeyendaConInputRadioInverso} from '../leyendas'

const CambioDeInformacionRadial = ({
  estilosContenedor = 'w-full flex justify-center items-center flex-wrap',
  tituloLeyendaSuperior = '',
  tituloLeyendaInferior = '',
  funcionRadialSuperior = null,
  funcionRadialInferior = null,
  seleccionado = false,
}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <LeyendaConInputRadioInverso
        seleccionado={seleccionado}
        funcion={funcionRadialSuperior}
        titulo={tituloLeyendaSuperior}
      />
      <LeyendaConInputRadioInverso
        seleccionado={!seleccionado}
        funcion={funcionRadialInferior}
        titulo={tituloLeyendaInferior}
      />
    </div>
  )
}

export default CambioDeInformacionRadial
