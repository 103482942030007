import React, {useContext, useState, useEffect, useMemo} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {InputNumerico} from '../../inputs'

const CampoFormula = ({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const [camposFormulaValores, setCamposFormulaValores] = useState({})
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const [valorCampo, setValorCampo] = useState('')

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  useEffect(() => {
    const variables = campo.atributos.formula?.toUpperCase().match(/@{(.*?)}/g)
    for (const variable of variables) {
      let nombreCampo = variable.replace('@{', '').replace('}', '')
      const seccionesPestana = contextoFormulario.valoresCampos?.[accesoPestana]
      for (const key in seccionesPestana) {
        for (const campoSeccion in seccionesPestana[key]) {
          if (campoSeccion.toUpperCase() === nombreCampo.toUpperCase()) {
            setCamposFormulaValores((campitos) => ({
              ...campitos,
              [campoSeccion]: parseFloat(
                contextoFormulario.valoresCampos?.[accesoPestana]?.[key]?.[
                  nombreCampo
                ] ?? 0
              ),
            }))
          }
        }
      }
    }
  }, [campo, contextoFormulario.valoresCampos])

  const isInfinite = (n) => n === n / 0

  const Parser = require('expr-eval').Parser

  useEffect(() => {
    let expresion = campo.atributos.formula?.toUpperCase() ?? ''
    let variables = campo.atributos.formula?.toUpperCase().match(/@{(.*?)}/g)

    variables.forEach((variable) => {
      let nombreCampo = variable.replace('@{', '').replace('}', '')
      let valorCampo = parseFloat(camposFormulaValores[nombreCampo] ?? 0)
      expresion = expresion.replace(variable, valorCampo ? valorCampo : '0')
    })
    const parser = new Parser()
    let expr = parser.parse(expresion)
    if (expresion.includes('undefined')) {
      setValorCampo('')
    } else {
      let nuevoValor = expr.evaluate()
      nuevoValor = isNaN(nuevoValor) || isInfinite(nuevoValor) ? 0 : nuevoValor
      setValorCampo(nuevoValor)
      if (
        contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
          campo?.tituloCampo
        ] != nuevoValor
      ) {
        contextoFormulario.setValoresCampos({
          ...contextoFormulario.valoresCampos,
          [accesoPestana]: {
            ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
            [accesoSeccion]: {
              ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
                accesoSeccion
              ] ?? {}),
              [campo.tituloCampo]: nuevoValor,
            },
          },
        })
      }
    }
  }, [campo, camposFormulaValores])

  return (
    <div className="w-full flex flex-wrap">
      <InputNumerico
        estilosContenedor={'w-full'}
        estilosInput={
          'appearance-none rounded relative block w-full p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
        }
        placeholder={''}
        titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
        valor={valorCampo}
        deshabilitado={true}
        readOnly={readOnly}
      />
      <div className="flex flex-wrap w-full text-11px text-start">
        {campo.atributos?.unidadMedida ? (
          <div className="w-full flex flex-wrap">
            <label className="flex  text-cendiatra font-bold">
              UNIDAD DE MEDIDA:
            </label>
            <label className="flex  text-cendiatra ml-1">
              {campo.atributos?.unidadMedida?.toUpperCase() ?? ''}
            </label>
          </div>
        ) : null}
        {campo.atributos?.valorReferencia ? (
          <div className="w-full flex flex-wrap">
            <label className="flex  text-cendiatra  font-bold">
              VALOR DE REFERENCIA:
            </label>
            <label className="flex  text-cendiatra ml-1">
              {campo.atributos?.valorReferencia?.toUpperCase() ?? ''}
            </label>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CampoFormula
