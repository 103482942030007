import {ObtenerPaquetes} from '../../microservicios/Servicio'
import {dbComercial} from '../bases-datos/dbComercial'
import Dexie from 'dexie'

export const AgregarPaquetesACache = async () => {
  await dbComercial.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbComercial.table('FechasActualizacion').get({Tabla: 'Paquetes'})
    )?.FechaUltimaActualizacion
    await ObtenerPaquetes(fechaUltimaActualizacion).then((res) => {
      dbComercial.Paquetes.bulkPut(
        res.data.map((paquete) => ({
          Id: paquete.id,
          CodigoInterno: paquete.codigoInterno,
          Nombre: paquete.nombre,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar paquetes al caché comercial: ' + (e.stack || e)
        )
      })
      dbComercial.FechasActualizacion.put({
        Tabla: 'Paquetes',
        FechaUltimaActualizacion: res.headers.tiemposervidor,
      })
    })
  })
}

export const ObtenerTodosLosPaquetes = async () => {
  return Promise.resolve(dbComercial.table('Paquetes').toArray())
}

export const ObtenerPaquetesPorNombre = async (filtro) => {
  let paquetesPorNombre = (
    await dbComercial
      .table('Paquetes')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
  }))
  return Promise.resolve(paquetesPorNombre)
}

export const ObtenerPaquetesPorCodigo = async (filtro) => {
  let paquetesPorCodigo = (
    await dbComercial
      .table('Paquetes')
      .filter(
        ({CodigoInterno}) =>
          CodigoInterno.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.CodigoInterno,
  }))
  return Promise.resolve(paquetesPorCodigo)
}

export const ObtenerPaqueteOServicioPorCodigo = async (codigo) => {
  let paquetes = (
    await dbComercial
      .table('Paquetes')
      .filter(
        ({CodigoInterno}) =>
          CodigoInterno.toUpperCase().indexOf(codigo.toUpperCase()) > -1
      )
      .toArray()
  ).map((paquete) => ({
    id: paquete.Id,
    nombre: paquete.Nombre,
    codigoInterno: paquete.CodigoInterno,
    tipo: 1,
  }))

  let servicios = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({CodigoCompleto}) =>
          CodigoCompleto.toUpperCase().indexOf(codigo.toUpperCase()) > -1
      )
      .toArray()
  ).map((servicio) => ({
    id: servicio.Id,
    nombre: servicio.Nombre,
    codigoInterno: servicio.CodigoCompleto,
    tipo: 0,
  }))

  return Promise.resolve(paquetes.concat(servicios))
}

export const ObtenerPaqueteOServicioPorNombre = async (nombre) => {
  let paquetes = (
    await dbComercial
      .table('Paquetes')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1
      )
      .toArray()
  ).map((paquete) => ({
    id: paquete.Id,
    nombre: paquete.Nombre,
    codigoInterno: paquete.CodigoInterno,
    tipo: 1,
  }))

  let servicios = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1
      )
      .toArray()
  ).map((servicio) => ({
    id: servicio.Id,
    nombre: servicio.Nombre,
    codigoInterno: servicio.CodigoCompleto,
    tipo: 0,
  }))

  return Promise.resolve(servicios.concat(paquetes))
}

export const ObtenerPaquetePorNombre = async (nombre) => {
  return Promise.resolve(dbComercial.table('Paquetes').get({Nombre: nombre}))
}

export const obtenerPaquetesPorIds = (ids) => {
  if (Array.isArray(ids)) {
    return dbComercial
      .table('Paquetes')
      .filter((paquete) => ids.includes(paquete.Id))
      .toArray()
  }

  return Promise.resolve([])
}

export const ObtenerPaquetePorId = async (id) => {
  return Promise.resolve(dbComercial.table('Paquetes').get({Id: id}))
}
