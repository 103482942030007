import moment from 'moment'
import {dbComercial} from '../bases-datos/dbComercial'
import {NOMBRE_TABLA} from '../../constantes/nombreTabla'
import {obtenerPortafolios} from '../../microservicios/Portafolio'
import {
  actualizarUltimaFechaActualizacion,
  obtenerUltimaFechaActualizacion,
} from './FechasActualizacion'

export const AgregarPortafoliosACache = async () => {
  try {
    await dbComercial.open()
    const fechaUltimaActualizacion = await obtenerUltimaFechaActualizacion(
      NOMBRE_TABLA.PORTAFOLIOS
    )

    const respuesta = await obtenerPortafolios(fechaUltimaActualizacion)
    const {data: portafoliosRespuesta} = respuesta
    const protafoliosBd = portafoliosRespuesta.map((portafolio) => {
      const FechaCreacion = moment(portafolio.creado).format('YYYY-MM-DD')

      return {
        Id: portafolio.id,
        Nombre: portafolio.nombre,
        FechaCreacion,
        FechaModificacion: portafolio.actualizado
          ? moment(portafolio.actualizado).format('YYYY-MM-DD')
          : FechaCreacion,
        EmpresasAsignado: portafolio.cantidadAsignaciones,
        FechaInicial: moment(portafolio.fechaInicial).format('YYYY-MM-DD'),
        FechaFinal: portafolio.fechaFinal
          ? moment(portafolio.fechaFinal).format('YYYY-MM-DD')
          : null,
      }
    })

    dbComercial[NOMBRE_TABLA.PORTAFOLIOS].bulkPut(protafoliosBd)

    actualizarUltimaFechaActualizacion(
      NOMBRE_TABLA.PORTAFOLIOS,
      respuesta.headers.tiemposervidor
    )
  } catch (e) {
    console.error(
      `Error al agregar portafolios al caché comercial: ${e.stack || e}`
    )
  }
}

export const ObtenerTodosLosPortafolios = () =>
  dbComercial.table(NOMBRE_TABLA.PORTAFOLIOS).toArray()

export const ExistePortafolioPorNombre = async (nombre) => {
  nombre = nombre.trim()

  const cantidad = await dbComercial
    .table(NOMBRE_TABLA.PORTAFOLIOS)
    .where('Nombre')
    .equalsIgnoreCase(nombre)
    .count()

  return cantidad > 0
}

export const ObtenerPortafolioPorId = async (id) => {
  return Promise.resolve(
    dbComercial.table(NOMBRE_TABLA.PORTAFOLIOS).get({Id: id})
  )
}
