import React from 'react'
import TextoBorrar from '../../imagenes/textoBorrar.png'
import Borrador from '../../imagenes/borrador.png'

const BotonPerifericos = ({
  textoBoton,
  funcionCapturar,
  funcionBorrar,
  funcionRecargar,
  textoRecargar,
  recargar,
  deshabilitado,
}) => {
  return (
    <div className="w-full flex justify-center items-center">
      <button
        className="bg-cendiatra w-48 text-white px-1.5 py-3 rounded-lg text-17px"
        onClick={recargar ? funcionRecargar : funcionCapturar}
        disabled={deshabilitado}
      >
        {recargar ? textoRecargar : textoBoton}
      </button>
      <div className="ml-2">
        <button onClick={funcionBorrar}>
          <img className="h-8" src={Borrador} alt="borrador" />
        </button>
        <img className="h-3" src={TextoBorrar} alt="borrador" />
      </div>
    </div>
  )
}

export default BotonPerifericos
