import {useContext, useEffect} from 'react'
import {AgregarEmpresasACache} from '../../cache/servicios-cache/Empresas'
import {AgregarPaquetesACache} from '../../cache/servicios-cache/Paquetes'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import FormularioPrecios from '../../componentes/Formularios/FormularioPrecios'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import Pestana from '../../componentes/Pestanas/Pestana'
import {FormularioPortafolio} from '../../componentes/portafolio/FormularioPortafolio'
import {SeleccionPortafolio} from '../../componentes/portafolio/SeleccionPortafolio'
import TablaAliados from '../../componentes/Tablas/TablaAliados'
import TablaSedes from '../../componentes/Tablas/TablaSedes'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextoPortafolio} from '../../contexto'
import {useGuardarPortafolio} from '../../hooks/portafolios/useGuardarPortafolio'
import {obtenerTitulosDeConstante} from '../../utilidades/obtenerTitulosDeConstante'

const TEXTO_PANTALLA = TEXTO_VISUAL.PORTAFOLIO.EDITAR

// Obtener los títulos de las pestañas.
const listaPestanas = obtenerTitulosDeConstante(TEXTO_PANTALLA.PESTANA)

export const EditarPortafolio = () => {
  const contextoPortafolio = useContext(ContextoPortafolio)
  const [manejarGuardar] = useGuardarPortafolio(false)

  useEffect(() => {
    contextoPortafolio.setTotalPaginas(5)
    contextoPortafolio.setNumeroPagina(1)
    contextoPortafolio.setModoEdicion(true)

    Promise.all([
      AgregarEmpresasACache(),
      AgregarServiciosACache(),
      AgregarPaquetesACache(),
    ]).catch(console.error)
  }, [])

  useEffect(() => {
    const validarExistenciaListaSinValor = () => {
      const existenListas = contextoPortafolio?.arregloMomentoActivacion.find(
        (elemento) => !elemento?.tipoCambioSedes && !elemento?.tipoCambioAliados
      )
      contextoPortafolio.setValidacionActivarBotonPortafolio(
        Boolean(existenListas)
      )
    }
    validarExistenciaListaSinValor()
  }, [contextoPortafolio?.arregloMomentoActivacion])

  return (
    <ContenedorPagina tituloPagina={TEXTO_PANTALLA.TITULO}>
      <div className="justify-center items-center flex">
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoPortafolio}
        />
      </div>
      <>
        <Pestana contexto={contextoPortafolio} numeroPagina={1}>
          <SeleccionPortafolio />
        </Pestana>
        <Pestana contexto={contextoPortafolio} numeroPagina={2}>
          <FormularioPortafolio numeroPagina={2} />
        </Pestana>
        <Pestana contexto={contextoPortafolio} numeroPagina={3}>
          <TablaSedes contexto={contextoPortafolio} numeroPagina={3} />
        </Pestana>
        <Pestana contexto={contextoPortafolio} numeroPagina={4}>
          <TablaAliados contexto={contextoPortafolio} numeroPagina={4} />
        </Pestana>
        <Pestana
          contexto={contextoPortafolio}
          numeroPagina={5}
          clickGuardar={manejarGuardar}
        >
          <FormularioPrecios
            contexto={contextoPortafolio}
            numeroPagina={5}
            esPortafolio={true}
          />
        </Pestana>
      </>
    </ContenedorPagina>
  )
}
