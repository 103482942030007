import React from 'react'
import {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ContextoTarifario} from '../../contexto'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import Fila from '../Fila'
import moment from 'moment'
import {
  ObtenerEmpresaPorId,
  ObtenerEmpresasYEmpresasHijasPorNombre,
  ObtenerTarifarioPorEmpresaId,
  ObtenerTarifarioPorNombre,
} from '../../cache/servicios-cache'
import {useObtenerSedesMarcadas} from '../../hooks/useObtenerSedesMarcadas'
import {Input} from '../inputs'
import {regexBasicoV1} from '../../constantes/regex'
import {ROLES_APP, TEXTO_VISUAL} from '../../constantes'

export default function FormularioTarifario({numeroPagina, claseBloqueo}) {
  const contextoTarifario = useContext(ContextoTarifario)

  const [empresasFiltradas, setEmpresasFiltradas] = useState([])
  const [minimoFechaFinal, setMinimoFechaFinal] = useState('')

  const {
    register,
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [])

  useObtenerSedesMarcadas(contextoTarifario)

  useEffect(() => {
    if (numeroPagina == contextoTarifario.numeroPagina) {
      trigger()
      contextoTarifario.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
    contextoTarifario.setFormularioEmpresa(getValues())
  }, [isValid, contextoTarifario.numeroPagina])

  useEffect(() => {
    reset(contextoTarifario.formularioEmpresa)
    trigger()
    if (contextoTarifario.formularioEmpresa.empresaId !== undefined) {
      ObtenerEmpresaPorId(contextoTarifario.formularioEmpresa.empresaId).then(
        (res) => {
          contextoTarifario.setFormularioEmpresa((estado) => ({
            ...estado,
            empresa: res?.Nombre ?? '',
          }))
          setValue('empresa', res?.Nombre ?? '')
          setMinimoFechaFinal(
            moment(contextoTarifario.formularioEmpresa.fechaInicial)
              .add(1, 'days')
              .format('YYYY-MM-DD')
          )
          clearErrors('empresa')
        }
      )
      contextoTarifario.setIdEmpresa(
        contextoTarifario.formularioEmpresa.empresaId
      )
      contextoTarifario.setIdTarifario(contextoTarifario.formularioEmpresa.id)
    }
  }, [contextoTarifario.actualizar])

  const verificarCaracteres = (opcion) => {
    if (opcion === '') {
      return false
    }
    let Validacion = new RegExp(
      /[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,&\[/\]/)(+-//\s]+/,
      ''
    )
    return !Validacion.test(opcion)
  }

  const filtrarEmpresa = (e) => {
    if (verificarCaracteres(e.target.value.slice(-1))) {
      setError('empresa', {
        type: 'pattern',
        message: 'Solo se permiten estos caracteres + ( ) [ ] , / - . _ &',
      })
      contextoTarifario.setFormularioEmpresa((estado) => ({
        ...estado,
        empresa: e.target.value,
      }))
    } else {
      contextoTarifario.setFormularioEmpresa((estado) => ({
        ...estado,
        empresa: e.target.value,
      }))

      if (e.target.value.length >= 3) {
        ObtenerEmpresasYEmpresasHijasPorNombre(e.target.value).then((res) => {
          const nuevoArray = []
          res.forEach((elemento) => {
            let objeto = {
              id: elemento.Id,
              nombre: elemento.Nombre,
              filtro: elemento.Nombre,
            }
            nuevoArray.push(objeto)
          })
          setEmpresasFiltradas(nuevoArray)
        })
      } else {
        setError('empresa', {type: 'require', message: ''})
        setValue('empresa')
        setEmpresasFiltradas([])
      }
    }
  }

  const empresaSeleccionada = async (opcion) => {
    contextoTarifario.setFormularioEmpresa((estado) => ({
      ...estado,
      empresa: opcion.nombre,
    }))
    setValue('empresa', opcion.nombre)
    await ObtenerTarifarioPorEmpresaId(opcion.id).then((res) => {
      if (!res) {
        contextoTarifario.setIdEmpresa(opcion.id)
        clearErrors('empresa')
        setEmpresasFiltradas([])
      } else {
        setError('empresa', {
          type: 'existent',
          message: opcion.nombre + ' ya tiene un tarifario asignado.',
        })
        // setValue('empresa', '')
        setEmpresasFiltradas([])
      }
    })
  }

  const validarNombre = async (nombre) => {
    nombre = nombre.trim().toLowerCase()

    if (nombre === contextoTarifario.nombreTarifarioValidacion) {
      return true
    } else {
      let tarifarioExiste = await ObtenerTarifarioPorNombre(nombre)
      return !tarifarioExiste
    }
  }

  const restringirFechaFinal = (fecha) => {
    if (fecha == '') {
      setMinimoFechaFinal('')
      setValue('fechaFinal', '')
    } else {
      setMinimoFechaFinal(moment(fecha).add(1, 'days').format('YYYY-MM-DD'))
      setValue('fechaFinal', '')
    }
  }

  useEffect(() => {
    restringirFechaFinal(contextoTarifario?.formularioEmpresa?.fechaInicial)
  }, [contextoTarifario?.formularioEmpresa?.fechaInicial])

  return (
    <div className={`${claseBloqueo} w-full`}>
      <Fila ancho={'w-full  justify-evenly flex-wrap '}>
        <Controller
          name="empresa"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-4/12'}
              titulo={'Empresa o empresa hija*'}
              estilosInput={
                Object.keys(errors).find((element) => element === 'empresa')
                  ? 'border-red-600'
                  : 'border-cendiatra'
              }
              tipo={'text'}
              placeholder={'AUTOCOMPLETAR (EDITABLE)'}
              valorDelCampoFiltro={contextoTarifario.formularioEmpresa.empresa}
              desactivarOtroFiltro={''}
              handleChange={filtrarEmpresa}
              handleOptionChange={empresaSeleccionada}
              informacionFiltrada={empresasFiltradas}
            />
          )}
        />

        <Input
          estilosContenedor={'w-3/12'}
          estilosInput={
            'appearance-none  relative block w-full px-3 py-2 border border-gris bg-gris placeholder-white text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
          }
          tipo={'number'}
          placeholder={'Descripción'}
          titulo={'Código del tarifario'}
          valor={contextoTarifario.idTarifario}
          deshabilitado={true}
        />
        <Controller
          name="nombre"
          control={control}
          rules={{
            required: true,
            validate: validarNombre,
            pattern: regexBasicoV1,
          }}
          render={({field: {value, onChange}}) => {
            return (
              <Input
                estilosContenedor={'w-4/12'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'nombre')
                    ? 'appearance-none  relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                    : 'appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nombre tarifario*'}
                valor={value}
                onChange={onChange}
                onBlur={() =>
                  contextoTarifario.setFormularioEmpresa(getValues())
                }
              />
            )
          }}
        />
      </Fila>
      <Fila ancho={'w-full justify-evenly flex-wrap '}>
        <div className="w-4/12">
          <span className="text-red-500 w-full">
            {errors.empresa?.type === 'existent' && errors.empresa?.message}
          </span>
          <span className="text-red-500 w-full">
            {errors.empresa?.type === 'pattern' && errors.empresa?.message}
          </span>
        </div>
        <div className="w-3/12"></div>
        <div className="w-4/12">
          <span className="text-red-500 w-full">
            {errors.nombre?.type === 'validate' &&
              'El nombre del tarifario especificado ya se encuentra registrado.'}
            <span className="text-red-500 w-full">
              {errors.nombre?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
            </span>
          </span>
        </div>
      </Fila>

      <Fila ancho={'w-full my-12 justify-evenly flex-wrap'}>
        <Controller
          name={'fechaInicial'}
          control={control}
          rules={{
            required: true,
            onChange: (e) => restringirFechaFinal(e.target.value),
          }}
          render={({field: {value, onChange}}) => {
            return (
              <Input
                estilosContenedor={'w-4/12 flex justify-start flex-wrap'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'fechaInicial'
                  )
                    ? 'appearance-none relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                    : 'appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                }
                tipo={'date'}
                placeholder={'Descripción'}
                titulo={'Fecha inicial*'}
                valor={value}
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          name={'fechaFinal'}
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {value, onChange}}) => {
            return (
              <Input
                estilosContenedor={'w-4/12 flex justify-start flex-wrap'}
                estilosInput={
                  'appearance-none  relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                }
                tipo={'date'}
                deshabilitado={minimoFechaFinal == ''}
                placeholder={'Descripción'}
                titulo={'Fecha final'}
                valor={value}
                fechaMinima={minimoFechaFinal}
                onChange={onChange}
              />
            )
          }}
        />
      </Fila>
    </div>
  )
}
