import React from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {CargaArchivosBancos} from '../../componentes/tesorero'
import descargarPlantilla from '../../imagenes/tesorero/DescargarPlantilla.svg'

const CargaBancos = () => {
  const urlArchivo =
    'https://prodcendiatra.blob.core.windows.net/plantillas/confirmacion-pagos.xlsx'

  const parametro = {
    imagen: descargarPlantilla,
    textoBoton: 'Descargar plantilla',
    textoAlternativo: 'Descargar plantilla',
    funcion: () => window.open(urlArchivo, '_self'),
  }

  return (
    <ContenedorPagina
      estilosContenedor="w-1/2"
      tituloPagina={TEXTO_VISUAL.TESORERO.CARGA_ARCHIVOS.TITULO}
    >
      <CargaArchivosBancos parametro={parametro} />
    </ContenedorPagina>
  )
}

export default CargaBancos
