import React from 'react'
import {NumericFormat} from 'react-number-format'

const CuadroInformativoConSumatoria = ({datos}) => {
  return (
    <div className="w-full flex flex-wrap justify-center items-center rounded-lg border border-cendiatra p-3">
      <span className="w-6/12"></span>{' '}
      <span className="w-3/12 text-13px text-center text-cendiatra">
        Cantidad
      </span>{' '}
      <span className="w-3/12 text-13px text-center text-cendiatra">
        Valor total
      </span>
      <div className="w-full flex flex-wrap justify-center items-center text-13px">
        {datos.map((fila) => (
          <div className="w-full flex flex-wrap justify-center items-center">
            <div className="w-6/12 flex justify-start items-center text-cendiatra  text-left my-1">
              {fila.titulo}
            </div>
            <div className="w-3/12 flex justify-center items-center text-cendiatra-gris-2 my-1">
              {fila.cantidad}
            </div>
            <div className="w-3/12 flex justify-center items-center text-cendiatra-gris-2 my-1 pointer-events-none">
              <NumericFormat
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={0}
                fixedDecimalScale={true}
                prefix={'$'}
                value={fila.total}
                defaultValue={0}
                className={'w-full text-cendiatra-gris-2 text-center'}
                placeholder={''}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex flex-wrap justify-center items-center border-t border-t-cendiatra-gris-2 mt-2 text-13px ">
        <div className="w-6/12 flex justify-start items-center text-cendiatra  text-left my-1">
          TOTAL
        </div>
        <div className="w-3/12 flex justify-center items-center text-cendiatra-gris-2 my-1">
          {datos
            .map((item) => item.cantidad)
            .reduce((prev, curr) => prev + curr, 0)}
        </div>
        <div className="w-3/12 flex justify-center items-center text-cendiatra-gris-2 my-1 pointer-events-none">
          <NumericFormat
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
            fixedDecimalScale={true}
            prefix={'$'}
            value={datos
              .map((item) => item.total)
              .reduce((prev, curr) => prev + curr, 0)}
            defaultValue={0}
            className={'w-full text-cendiatra-gris-2 text-center'}
            placeholder={''}
          />
        </div>
      </div>
    </div>
  )
}

export default CuadroInformativoConSumatoria
