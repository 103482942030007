import React, {useContext, useEffect, useRef, useState} from 'react'
import moment from 'moment/moment'
import {ContextoRegistro} from '../../contexto'
import {SubirArchivo} from '../../microservicios'
import {InputCargarArchivo} from '../inputs'
import Borrador from '../../imagenes/borrador.png'
import TextoBorrar from '../../imagenes/textoBorrar.png'
import ClipImg from '../../imagenes/clip.svg'

function ElementosLector(modificar) {
  const contextoRegistro = useContext(ContextoRegistro)
  const refParaCargarImagen = useRef(null)
  const [nombreUsuario, setNombreUsuario] = useState({
    nombre: '',
    apellidos: '',
  })
  const AbrirCargarFirma = (e) => {
    refParaCargarImagen.current.querySelector('input').click()
  }

  useEffect(() => {
    setNombreUsuario({
      nombre: contextoRegistro.informacionPersonal.nombre,
      apellidos: contextoRegistro.informacionPersonal.apellidos,
    })
  }, [
    contextoRegistro.informacionPersonal.nombre,
    contextoRegistro.informacionPersonal.apellidos,
  ])

  const btnRestore = useRef()
  const chkB64 = useRef()
  const chkSigText = useRef()
  const firstName = useRef('')
  const lastName = useRef('')
  const imageBox = useRef()
  const txtSignature = useRef()
  const txtDisplay = useRef()

  const [chkValue, setChecked] = useState(false)

  const setRestoreButtonState = (buttonState) => {
    setChecked(buttonState)
    document.getElementById('Restore').disabled = buttonState
    setChecked(!buttonState)
  }

  useEffect(() => {
    document.addEventListener('imagenFirmaCreada', GuardarFirma)
    document.getElementById('Restore').disabled = true // Default the Restore button to disabled at start

    return () => {
      // Importante dejar de escuchar el evento para evitar escuchas indeseadas.
      document.removeEventListener('imagenFirmaCreada', GuardarFirma)
    }
  }, [])

  const borrarImagen = () => {
    contextoRegistro.setFirma('')
    if (window.imageBox.firstChild) {
      window.clearSignature()
    }
  }

  const GuardarFirma = ({detail: urlImagen}) => {
    const dataURLtoBlob = (dataurl) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {type: mime})
    }
    const screenshot = urlImagen?.image?.currentSrc ?? ''
    const blob = dataURLtoBlob(screenshot)
    const fecha = moment().format('YYYYMMDDHHmmssSSS')
    let formData = new FormData()
    formData.append('Archivo', blob, fecha + '.webp')
    SubirArchivo(fecha + '.webp', formData, 'paciente')
      .then((res) => {
        contextoRegistro.setFirma(res.data)
      })
      .catch((error) => {
        console.log(error, 'ERROR AL TOMAR LA FIRMA')
      })
  }

  const cargarFirma = (e) => {
    const archivoLogo = e.target.files[0]
    const tamanoArchivo = archivoLogo.size / 1024 / 1024 // Conversion en  MiB
    if (tamanoArchivo > 2) {
      return
    }
    var reader = new FileReader()
    reader.readAsDataURL(archivoLogo)
    reader.onload = function (e) {
      var imagen = new Image()
      imagen.src = e.target.result
      imagen.onload = function () {
        let formData = new FormData()
        const fecha = moment().unix()
        formData.append('Archivo', archivoLogo, fecha + archivoLogo.name)
        SubirArchivo(fecha + archivoLogo.name, formData).then((res) => {
          contextoRegistro.setFirma(res.data)
        })
      }
    }
  }

  return (
    <div className="w-full h-full flex items-center flex-wrap justify-center ">
      <div
        className={`${
          contextoRegistro.firma !== ''
            ? 'w-80 h-56  flex flex-wrap justify-center items-center border-2 border-cendiatra rounded-custom mb-20 bg-contain bg-no-repeat bg-center '
            : 'hidden'
        } `}
        style={{backgroundImage: `url(${contextoRegistro.firma})`}}
      ></div>

      <div
        className={`${
          contextoRegistro.firma !== ''
            ? 'hidden'
            : 'w-80 h-56 p-3 flex flex-wrap justify-center items-center border-2 border-cendiatra-semaforo-rojo rounded-custom mb-20 bg-contain bg-no-repeat bg-center'
        } `}
        id="imageBox"
        ref={imageBox}
        title="Double-click a signature to display its details"
      ></div>
      {modificar ? (
        <div className="w-full flex flex-wrap justify-center items-center -mt-20">
          <div className="w-full flex flex-wrap justify-center items-center ">
            <div className="w-60 flex justify-start items-center my-2 ">
              <span className="text-13 text-cendiatra">Tamaño maximo 2Mb</span>
            </div>
            <div className="w-full flex items-center justify-center my-5 ">
              <button
                className="w-full flex justify-center items-center "
                onClick={(e) => AbrirCargarFirma(e)}
              >
                <img className="h-6" src={ClipImg} alt="borrador" />
                <span className="text-13 text-cendiatra ml-2">Adjuntar</span>
              </button>
              <div ref={refParaCargarImagen}>
                <InputCargarArchivo
                  funcion={cargarFirma}
                  estilosInput={'hidden'}
                />
              </div>
              <div className="border h-6 border-cendiatra-gris-4"></div>
              <button
                className="w-full flex justify-center items-center"
                onClick={borrarImagen}
              >
                <img
                  className="h-8 cursor-pointer"
                  src={Borrador}
                  value="Clear"
                />
                <span className="text-13 text-cendiatra ml-2">Borrar</span>
              </button>
            </div>
            <button
              className={`${
                contextoRegistro.firma !== ''
                  ? 'bg-cendiatra-gris-2'
                  : 'bg-cendiatra'
              } text-white px-1.5 py-3 rounded-lg text-17px `}
              value="Capture"
              onClick={() =>
                window.capture(
                  firstName.current,
                  lastName.current,
                  imageBox.current,
                  txtSignature.current,
                  chkSigText.current,
                  chkB64.current
                )
              }
              disabled={contextoRegistro.firma !== ''}
            >
              CAPTURAR FIRMA
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center ">
          <button
            className="bg-cendiatra text-white px-1.5 py-3 rounded-lg text-17px w-48"
            value="Capture"
            onClick={() =>
              window.capture(
                firstName.current,
                lastName.current,
                imageBox.current,
                txtSignature.current,
                chkSigText.current,
                chkB64.current
              )
            }
          >
            CAPTURAR FIRMA
          </button>
          <div className="ml-2">
            <img
              className="h-8 cursor-pointer"
              src={Borrador}
              onClick={borrarImagen}
              value="Clear"
            />
            <img className="h-3" src={TextoBorrar} alt="borrador" />
          </div>
        </div>
      )}

      <button
        value="Clear"
        className="hidden"
        title="Clears the signature"
        onClick={() => window.clearSignature()}
      >
        Clear
      </button>
      <button
        value="Restore"
        id="Restore"
        className="hidden"
        ref={btnRestore}
        title="Restores the signature from the SigText data. To use this function please tick Output SigText to form"
        onClick={() => window.setSignatureText()}
      >
        Restore
      </button>
      <input
        id="fname"
        className="hidden"
        ref={firstName}
        defaultValue={
          nombreUsuario.nombre === undefined
            ? `${contextoRegistro.modificarPacienteInformacionPersonal.nombre}`
            : `${nombreUsuario.nombre}`
        }
      />
      <input
        id="lname"
        className="hidden"
        ref={lastName}
        defaultValue={
          nombreUsuario.apellidos === undefined
            ? `${contextoRegistro.modificarPacienteInformacionPersonal.apellidos}`
            : `${nombreUsuario.apellidos}`
        }
      />
      <input
        type="checkbox"
        id="chkUseB64Image"
        ref={chkB64}
        className="hidden"
      />
      <input
        type="checkbox"
        id="chkShowSigText"
        onChange={() => setRestoreButtonState(chkValue)}
        className="hidden"
        ref={chkSigText}
      />
      <textarea
        className="hidden"
        cols="125"
        rows="15"
        id="txtDisplay"
        ref={txtDisplay}
      ></textarea>

      <textarea
        className="hidden"
        cols="125"
        rows="15"
        id="txtSignature"
        ref={txtSignature}
      ></textarea>
    </div>
  )
}

const LectorDeFirmas = ({modificar}) => {
  useEffect(() => {
    window.bodyOnLoad(
      document.getElementById('txtDisplay'),
      document.getElementById('chkShowSigText'),
      document.getElementById('Restore')
    )
  }, [])
  return ElementosLector(modificar)
}

export default LectorDeFirmas
