import React, {useEffect, useContext} from 'react'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {BotonOpcionConObservaciones} from '../../../Botones'
import {AreaDeTexto} from '../../../inputs'
import {regexBasicoV1} from '../../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../../constantes'

const AntecedentesToxicologicos = ({numeroPagina}) => {
  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  var regex = regexBasicoV1
  var errorObservaciones = regex.test(
    contextoMedicoOcupacional.observacionesAntecedentesToxicologicos
  )
    ? null
    : TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        errorObservaciones !== null
      )
    }
  }, [errorObservaciones, contextoMedicoOcupacional.numeroPagina])

  const manejarCambioAntecedente = (id) => {
    contextoMedicoOcupacional.setListaAntecedentesToxicologicos(
      contextoMedicoOcupacional.listaAntecedentesToxicologicos.map(
        (antecedente) => {
          if (antecedente.Id == id) {
            return {
              ...antecedente,
              EstaActivo: !antecedente.EstaActivo,
            }
          }
          return antecedente
        }
      )
    )
  }

  const manejarCambioObservaciones = (valor, id) => {
    contextoMedicoOcupacional.setListaAntecedentesToxicologicos(
      contextoMedicoOcupacional.listaAntecedentesToxicologicos.map(
        (antecedente) => {
          if (antecedente.Id == id) {
            return {
              ...antecedente,
              Observaciones: valor,
            }
          }
          return antecedente
        }
      )
    )
  }

  const actualizarValorobservaciones = (e) => {
    contextoMedicoOcupacional.setObservacionesAntecedentesToxicologicos(
      e.target.value
    )
  }

  return (
    <div className="w-11/12 flex flex-wrap justify-start items-center ml-16">
      {contextoMedicoOcupacional.listaAntecedentesToxicologicos.map(
        (antecedente) => (
          <div className="w-1/3 flex mb-2 p-5">
            <BotonOpcionConObservaciones
              id={antecedente.Id}
              titulo={antecedente.Nombre.toUpperCase()}
              estaActivo={antecedente.EstaActivo}
              observaciones={antecedente.Observaciones}
              funcionClick={manejarCambioAntecedente}
              cambioObservaciones={manejarCambioObservaciones}
            />
          </div>
        )
      )}

      <div className="w-full flex items-center justify-center flex-wrap">
        <AreaDeTexto
          estilosContenedor={`w-full`}
          estilosAreaDeTexto={`h-20 my-3 rounded relative block w-full  p-1.5 border ${
            errorObservaciones ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
          } text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
          titulo={'OBSERVACIONES'}
          placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
          cambioValor={(e) => actualizarValorobservaciones(e)}
          valor={
            contextoMedicoOcupacional.observacionesAntecedentesToxicologicos
          }
        />

        <span className={`flex text-cendiatra-semaforo-rojo w-full text-13px `}>
          {errorObservaciones !== null && errorObservaciones}
        </span>
      </div>
    </div>
  )
}

export default AntecedentesToxicologicos
