import {useEffect, useState} from 'react'
import {ContenedorPagina} from '../paginas/ContenedorPagina'
import {TablaConPaginacionYFiltros} from '../Tablas'
import {CuadroInformacionUsuarioV2} from '../MostrarInformacion'

// SI NECESITA AÑADIR ALGÚN BOTÓN O ALGO DESPUÉS DE LA TABLA USAR EL CHILDREN
function DetalleAtencion({
  columnas = [],
  informacion,
  children,
  opcionesTabla,
}) {
  const [informacionPaciente, setInformacionPaciente] = useState({
    datos: [],
    fechaAtencion: '',
    horaAtencion: '',
    URIFotoPaciente: '',
    examenes: [],
  })

  useEffect(() => {
    if (Object.entries(informacion).length > 0) {
      setInformacionPaciente(informacion)
    }
  }, [informacion])

  return (
    <ContenedorPagina tituloPagina={'DETALLE DE LA ATENCIÓN'}>
      <div className="w-full space-y-10">
        <div className="w-full flex flex-wrap justify-center">
          <CuadroInformacionUsuarioV2
            foto={informacionPaciente.URIFotoPaciente}
            datos={informacionPaciente.datos}
            estilosContenedor={
              'w-12/12 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 shadow-2xl py-8'
            }
          />
        </div>
        <div className="w-full">
          <TablaConPaginacionYFiltros
            columns={columnas}
            data={informacionPaciente.examenes}
            {...opcionesTabla}
          />
        </div>
        {children ? children : null}
      </div>
    </ContenedorPagina>
  )
}

export default DetalleAtencion
