import {useContext} from 'react'
import {InformacionPreciosEnCero} from '../componentes/Modales/modalPreciosEnCero/InformacionPreciosEnCero'
import {TEXTO_VISUAL} from '../constantes/textoVisual'
import {ContextApplication} from '../contexto'
import {obtenerServiciosOPaquetesConPrecioNulo} from '../utilidades/utilidadesTarifario'
import {useModal} from './useModal'

const TEXTO_MODAL = TEXTO_VISUAL.MODAL.PRECIOS_EN_CERO

export const useModalPreciosEnCero = (contexto, manejadorGuardar) => {
  const contextoApp = useContext(ContextApplication)
  const [cerrarModal] = useModal()

  const mostrarModalPreciosEnCeroOGuardar = async () => {
    let serviciosOpaquetesNoValidos =
      obtenerServiciosOPaquetesConPrecioNulo(contexto)

    if (serviciosOpaquetesNoValidos.length > 0) {
      contextoApp.setModal({
        abierto: true,
        titulo: TEXTO_MODAL.TITULO,
        contenido: (
          <InformacionPreciosEnCero
            serviciosOpaquetesNoValidos={serviciosOpaquetesNoValidos}
          />
        ),
        botones: [
          {
            nombre: TEXTO_VISUAL.BOTONES.SI,
            click: manejadorGuardar,
          },
          {
            nombre: TEXTO_VISUAL.BOTONES.NO,
            click: cerrarModal,
          },
        ],
      })
    } else {
      await manejadorGuardar()
    }
  }

  return [mostrarModalPreciosEnCeroOGuardar]
}
