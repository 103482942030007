import React, {useEffect, useState, useContext, useRef} from 'react'
import {ContextApplication} from '../contexto'
import {useHistory} from 'react-router-dom'
import GATO from '../imagenes/GATO.png'
import {useMsal} from '@azure/msal-react'
import Spinner from './Spinner.jsx'

const ChooseLocation = () => {
  const [cendiatraSites, setCendiatraSites] = useState([])
  const [errorLocation, setErrorLocation] = useState(false)
  const [location, setLocation] = useState('')
  const timer = useRef()
  const ctx = useContext(ContextApplication)
  const {accounts} = useMsal()

  const userRole = accounts[0].idTokenClaims.extension_Role

  const history = useHistory()

  const cendiatraSitesData = () => {
    const SiteName = localStorage.getItem('siteName')
    if (SiteName) {
      SolveUserRoleUrl()
      clearInterval(timer.current)
    }
  }

  useEffect(() => {
    timer.current = setInterval(cendiatraSitesData, 2000) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (cendiatraSites.length > 0) {
      clearInterval(timer.current)
    }
  }, [cendiatraSites])

  const selectedLocation = (event) => {
    if (!event) {
      setErrorLocation(true)
      return
    } else if (event === undefined || event.target.value === '') {
      setErrorLocation(true)
      return
    } else {
      localStorage.setItem('siteId', event.target.value)
      localStorage.setItem(
        'siteName',
        event.target[event.target.selectedIndex].text
      )
      const storedName = localStorage.getItem('siteName')
      setErrorLocation(false)
      ctx.setSiteName(storedName)
      SolveUserRoleUrl()
    }
  }

  const SolveUserRoleUrl = () => {
    if (userRole && userRole === 'Medic') {
      ctx.setShowDoctorMenu(true)
      history.push('/medicalschedule')
    } else {
      ctx.setShowDoctorMenu(false)
      history.push('/createpatient')
    }
  }

  return (
    <>
      {cendiatraSites.length === 0 ? (
        <Spinner message={ctx.loadingMessage} />
      ) : null}

      <div className="min-h-screen flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        <form className="mt-8 space-y-6 sm:w-3/5 md:w-2/5 lg:w-1/3">
          <div className="bg-white pb-4 rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl">
            <div className="bg-white rounded-t-3xl">
              <div className="flex justify-center  flex-wrap justify-center ">
                <div className="flex flex-wrap justify-evenly items-center w-full bg-bannerImg bg-cover py-4 rounded-3xl">
                  <img
                    className=" h-24 w-auto rounded-full border-2 border-white"
                    src={GATO}
                    alt="gato"
                  />
                  <p className="text-5xl w-1/2 font-mono text-white">GATO</p>
                </div>
                <div className="w-full text-center">
                  <p className=" font-sans text-cendiatra text-lg mt-3">
                    Gestión Administrativa Tecnológica Ocupacional
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-md -space-y-px flex justify-center flex-wrap">
              <div className="w-4/5 my-4">
                {errorLocation ? (
                  <span className="text-red-500">
                    Por favor ingrese una opción
                  </span>
                ) : null}

                <select
                  className={
                    cendiatraSites.length === 0
                      ? 'appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      : 'appearance-none hidden rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  }
                  //   placeholder="Dirección Email"
                  onChange={(e) => setLocation(e)}
                >
                  <option key="-1" value="">
                    Sede*
                  </option>
                </select>
                <select
                  className={
                    cendiatraSites.length === 0
                      ? 'appearance-none hidden rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                      : 'appearance-none  rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
                  }
                  placeholder="Dirección Email"
                  onChange={(e) => setLocation(e)}
                >
                  <option key="-1" value="">
                    Sede*
                  </option>
                  {cendiatraSites.map((item) => (
                    <option key={item.Id} value={item.Id}>
                      {item.SiteName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="rounded-md -space-y-px flex justify-center flex-wrap">
              <div className="w-4/5  mb-4"></div>
            </div>

            <div className="flex justify-center my-5">
              <button
                type="button"
                className="items-center group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                onClick={() => selectedLocation(location)}
              >
                Seleccionar
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChooseLocation
