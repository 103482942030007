import React, {useContext, useState} from 'react'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink} from 'react-router-dom'
import FormularioAsignarTurno from '../../componentes/recepcionista/FormularioAsignarTurno'
import {ContextApplication, ContextoAsignarTurno} from '../../contexto'
import {useHistory} from 'react-router-dom'
import {FachadaAsignarTurno} from '../../microservicios/Fachada'
import {ObtenerSedePorId} from '../../cache/servicios-cache/Sedes'
import {GenerarPDFTurno} from '../../microservicios/Turno.js'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'

const AsignarTurno = () => {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoAsignarTurno = useContext(ContextoAsignarTurno)
  const history = useHistory()

  const sedeId = parseInt(sessionStorage.getItem('sedeId'))

  const [resetearFormulario, setResetearFormulario] = useState(false)

  const manejarImprimirTurno = async (codigo) => {
    let informacionSede = await ObtenerSedePorId(sedeId)
    let payloadInfoTurno = {
      turno: codigo,
      sede: {
        sedeId: sedeId,
        nombre: informacionSede.Nombre,
        direccion: informacionSede.Direccion,
        telefono: informacionSede.Telefono,
      },
    }
    GenerarPDFTurno(payloadInfoTurno)
      .then((res) => {
        const file = new Blob([res.data], {type: 'application/pdf'})
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, '_blank')
        setResetearFormulario((prev) => !prev)
      })
      .catch((error) => {
        console.log(error)
      })
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }
  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    setResetearFormulario((prev) => !prev)
  }

  const manejarGuardar = async () => {
    const payloadTurno = {
      sedeId: sedeId,
      tipoTurnoId: contextoAsignarTurno.formularioAsignarTurno.tipoTurno,
      usuario: {
        id: contextoAsignarTurno.usuarioId ?? null,
        numeroDocumento:
          contextoAsignarTurno.formularioAsignarTurno.numeroDocumento,
        tipoDocumentoId:
          contextoAsignarTurno.formularioAsignarTurno.tipoDocumento,
        apellidos: contextoAsignarTurno.formularioAsignarTurno.apellidos,
        nombres: contextoAsignarTurno.formularioAsignarTurno.nombre,
        grupoSanguineoId:
          contextoAsignarTurno.formularioAsignarTurno.grupoSanguineo ?? null,
        fechaNacimiento:
          contextoAsignarTurno.formularioAsignarTurno.fechaNacimiento,
        lugarNacimiento:
          contextoAsignarTurno.formularioAsignarTurno.lugarNacimiento,
        sexoId: contextoAsignarTurno.formularioAsignarTurno.sexo,
        indicativo:
          contextoAsignarTurno.formularioAsignarTurno.indicativoCelular ?? null,
        celular: contextoAsignarTurno.formularioAsignarTurno.celular ?? null,
        nacionalidad:
          contextoAsignarTurno.formularioAsignarTurno.nacionalidad ?? null,
      },
    }
    FachadaAsignarTurno(payloadTurno).then((res) => {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: (
          <>
            <span className="font-bold">TURNO ASIGNADO</span>
          </>
        ),
        contenido: (
          <>
            <div className="w-full flex justify-center items-center flex-wrap text-cendiatra">
              <span className="w-full text-cendiatra text-lg">
                <b>NÚMERO DE TURNO:</b> {res.data.replace(/['"]+/g, '')}
              </span>
            </div>
          </>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejaCerrarModal,
          },
          {
            nombre: 'Imprimir',
            click: () => {
              manejarImprimirTurno(res.data.replace(/['"]+/g, ''))
            },
          },
        ],
      })
    })
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITULOS_RECEPCIONISTA.ASIGNAR_TURNO}
    >
      <FormularioAsignarTurno
        manejarGuardar={manejarGuardar}
        resetearFormulario={resetearFormulario}
      />
    </ContenedorPagina>
  )
}

export default AsignarTurno
