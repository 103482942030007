export const TIPO_EXAMEN = [
  {id: 1, Ingreso: 'INGRESO', seleccionado: true},
  {id: 2, Alimentos: 'ALIMENTOS', seleccionado: true},
  {id: 3, Retiro: 'RETIRO', seleccionado: true},
  {id: 4, Periodico: 'PERIÓDICO', seleccionado: true},
  {id: 5, PostIncapacidad: 'POST INCAPACIDAD', seleccionado: true},
  {id: 6, Alturas: 'ALTURAS', seleccionado: true},
  {id: 7, EspaciosConfinados: 'ESPACIOS CONFINADOS', seleccionado: true},
]


