import React, {useEffect, useState} from 'react'
import ElementoTabla from '../ElementoTabla'
import Fila from '../Fila'
import { ROLES_APP } from '../../constantes'

const TablaSedes = ({numeroPagina, contexto, claseBloqueo}) => {
  const [sedes, setSedes] = useState([])

  useEffect(() => {
    const agruparPorSedes = contexto.sedesSeleccionadas.reduce(
      (grupoSedes, elemento) => {
        const {Grupo} = elemento

        grupoSedes[Grupo] = grupoSedes[Grupo] ?? []
        grupoSedes[Grupo].push({...elemento})

        return grupoSedes
      },
      {}
    )

    setSedes(agruparPorSedes)
  }, [contexto.sedesSeleccionadas])

  useEffect(() => {
    if (numeroPagina == contexto.numeroPagina) {
      contexto.setformularioActualTieneErrores(
        contexto.sedesSeleccionadas.filter((x) => x.Activo).length == 0
      )
    }
  }, [contexto.numeroPagina])

  const opcionSeleccionada = (item, seleccionado) => {
    const {
      sedesSeleccionadas,
      sedesIniciales,
      portafolioSeleccionadoId,
      setSeguroDeCambio,
      setSedesSeleccionadas,
      setformularioActualTieneErrores,
    } = contexto

    const nuevoEstado = sedesSeleccionadas.map((elemento) =>
      elemento.Id === item.Id ? {...elemento, Activo: seleccionado} : elemento
    )

    const cantidadActivos = nuevoEstado.filter((x) => x.Activo).length

    if (
      cantidadActivos !== sedesIniciales.length &&
      Boolean(portafolioSeleccionadoId)
    ) {
      setSeguroDeCambio(true)
      return
    }

    setSedesSeleccionadas(nuevoEstado)
    setformularioActualTieneErrores(cantidadActivos === 0)
  }

  return (
    <div className={`${claseBloqueo} w-full rounded flex justify-center items-center flex-wrap`}>
      {Object.keys(sedes)
        .sort()
        .map((sede) => (
          <div
            key={sedes[`${sede}`]}
            className="w-full flex justify-center items-center flex-wrap"
          >
            <label className="text-cendiatra text-lg  my-5">{sede}</label>
            <hr className="w-11/12" />
            <Fila
              ancho={'w-full'}
              alineacionHorizontal={'justify-center'}
              flexWrap={'flex-wrap'}
            >
              <ElementoTabla
                datos={sedes[`${sede}`]}
                funcion={opcionSeleccionada}
              />
            </Fila>
          </div>
        ))}
    </div>
  )
}

export default TablaSedes
