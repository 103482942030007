import React, {useState, useContext, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import Modal from '../../componentes/Modales/Modal.jsx'
import GATO from '../../imagenes/GATO.png'
import Seach from '../../imagenes/search.png'
import {ContextApplication} from '../../contexto'
import {NavLink, useHistory} from 'react-router-dom'
import {getPatientInfo} from '../../servicios/Recepcionista.js'
import CreatePatient from './CreatePatient.jsx'
import {useMsal} from '@azure/msal-react'

const ConsultPatient = () => {
  const ctx = useContext(ContextApplication)
  const [registerPatient, setRegisterPatient] = useState(false)
  const [openServiceRegister, setOpenServiceRegister] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const [documentCategory, setDocumentCategory] = useState('')
  const [documentNumber, setDocumentNumber] = useState('')
  const [inputTyeDate, setInputTypeDate] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm()
  const history = useHistory()

  const {accounts} = useMsal()
  const userRole = accounts[0].idTokenClaims.extension_Role

  useEffect(() => {
    if (userRole === 'Medic') {
      ctx.setShowDoctorMenu(true)
      history.push('/medicalschedule')
    }
  })

  const consultPatient = async (data) => {
    reset()
    const requestModel = {
      document_type: data.idType,
      document_number: data.idNumber,
    }

    if (
      documentCategory === 'Adulto sin Identificacíon' ||
      documentCategory === 'Menor sin Identificacíon'
    ) {
      openRegister()
    } else {
      await getPatientInfo(requestModel)
        .then((response) => {
          if (response.status === 200) {
            ctx.setUserFormInfo(JSON.parse(response.data))
            history.push('/createservice')
          } else if (response.status === 204) {
            openRegister()
          }
        })
        .catch((error) => {
          setModalMessage(`Ocurió un error: ${error}`)
        })
    }
  }

  const changeIdtype = (e) => {
    setDocumentCategory(e.target.value)
    reset({idNumber: ''})
  }

  const openRegister = () => {
    setRegisterPatient(true)
    setModalMessage('')
  }

  const cancelCreatePatient = () => {
    reset()
    setDocumentCategory('')
    setDocumentNumber('')
    setRegisterPatient(false)
    setModalMessage('')
    setInputTypeDate(false)
    ctx.setIsLoading(false)
  }

  const updateField = (e) => {
    setDocumentNumber(e.target.value)
    setValue('idNumber', e.target.value)
  }

  return (
    <>
      <Modal open={modalMessage !== ''}>
        <div className="flex items-center flex-wrap justify-center">
          <span className=" text-cendiatra text-4xl my-5">{modalMessage}</span>
          <div className="flex justify-evenly my-5 w-full">
            <button
              type="button"
              className="group relative w-1/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              onClick={() => cancelCreatePatient()}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={openServiceRegister}>
        <div className="flex items-center flex-wrap justify-center">
          <span className=" text-cendiatra text-4xl my-5">
            El paciente se ha registrado correctamente
          </span>
          <div className="flex justify-evenly my-5 w-full">
            <NavLink to="/createservice">
              <button
                type="button"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cendiatra hover:bg-cendiatra focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                onClick={() => setOpenServiceRegister(false)}
              >
                Continuar
              </button>
            </NavLink>
          </div>
        </div>
      </Modal>

      <div className="min-h-screen flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        {registerPatient === false ? (
          <form
            className="mt-8 space-y-6 sm:w-3/5 md:w-2/5 lg:w-1/3"
            onSubmit={handleSubmit(consultPatient)}
            onKeyDown={(e) => e.code === 'Enter'}
          >
            <div className="bg-white pb-4 rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl">
              <div className="bg-white rounded-t-3xl">
                <div className="flex justify-center  flex-wrap justify-center ">
                  <div className="flex flex-wrap justify-evenly items-center w-full bg-bannerImg bg-cover py-4 rounded-3xl">
                    <img
                      className=" h-24 w-auto rounded-full border-2 border-white"
                      src={GATO}
                      alt="gato"
                    />
                    <p className="text-5xl w-1/2 font-mono text-white">GATO</p>
                  </div>
                  <div className="w-full text-center">
                    <p className=" font-sans text-cendiatra text-lg mt-3">
                      Gestión Administrativa Tecnológica Ocupacional
                    </p>
                  </div>
                </div>
                <div className="w-full mb-2 mt-10 flex justify-center items-center flex-wrap">
                  <img className=" h-16 w-auto" src={Seach} alt="search" />
                  <p className="w-full font-sans text-center text-newCendiatra mt-3 font-bold text-lg">
                    Consultar Paciente
                  </p>
                </div>
              </div>

              <div className="rounded-md -space-y-px flex justify-center flex-wrap">
                <div className="w-4/5 my-4">
                  <span className="text-red-500">
                    {errors.idType && 'Por favor seleccione una opción'}
                  </span>

                  <select
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-500 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                    //   placeholder="Dirección Email"
                    {...register('idType', {required: true})}
                    onChange={(e) => changeIdtype(e)}
                    value={documentCategory}
                  >
                    <option key="-1" value="">
                      Tipo De Documento
                    </option>
                    <option key="1" value="Cédula de ciudadanía">
                      Cédula de Ciudadanía
                    </option>
                    <option key="2" value="Cédula de extranjería">
                      Cédula de Extranjería
                    </option>
                    <option key="3" value="Carnet Diplomatico">
                      Carnet Diplomático
                    </option>
                    <option key="4" value="Pasaporte">
                      Pasaporte
                    </option>
                    <option key="5" value="Salvoconducto">
                      Salvoconducto
                    </option>
                    <option key="6" value="Permiso Especial de Permanencia">
                      Permiso Especial de Permanencia
                    </option>
                    <option key="7" value="Registro Civil">
                      Registro Civil
                    </option>
                    <option key="8" value="Tarjeta de Identidad">
                      Tarjeta de Identidad
                    </option>
                    <option key="9" value="Certificado de Nacido Vivo">
                      Certificado de Nacido Vivo
                    </option>
                    <option key="10" value="Adulto sin Identificacíon">
                      Adulto sin Identificacíon
                    </option>
                    <option key="11" value="Menor sin Identificacíon">
                      Menor sin Identificacíon
                    </option>
                    <option key="12" value="Nit">
                      NIT
                    </option>
                  </select>
                </div>
              </div>

              <div className="rounded-md -space-y-px flex justify-center flex-wrap">
                <div className="w-4/5  mb-4">
                  {documentCategory === '' ? (
                    <input
                      className=" text-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 bg-gray-300"
                      type="number"
                      min="1"
                      placeholder="Número De Documento"
                      disabled={true}
                      {...register('idNumber', {
                        required: true,
                        minLength: 4,
                        maxLength: 10,
                      })}
                    />
                  ) : null}

                  {documentCategory === 'Cédula de ciudadanía' ||
                  documentCategory === 'Tarjeta de Identidad' ||
                  documentCategory === 'Salvoconducto' ||
                  documentCategory === 'Nit' ? (
                    <>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 4 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 10 caracteres'}
                      </span>
                      <input
                        className=" text-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        placeholder="Número De Documento"
                        min="1"
                        {...register('idNumber', {
                          required: true,
                          minLength: 4,
                          maxLength: 10,
                        })}
                        onChange={(e) => updateField(e)}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Cédula de extranjería' ? (
                    <>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 6 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 7 caracteres'}
                      </span>
                      <input
                        className=" text-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        min="1"
                        placeholder="Número De Documento"
                        {...register('idNumber', {
                          required: true,
                          minLength: 6,
                          maxLength: 7,
                        })}
                        onChange={(e) => updateField(e)}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Carnet Diplomatico' ||
                  documentCategory === 'Pasaporte' ||
                  documentCategory === 'Certificado de Nacido Vivo' ? (
                    <>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 8 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 9 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'pattern' &&
                          'El documento solo puede contener letras y números'}
                      </span>
                      <input
                        className="text-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="text"
                        min="1"
                        placeholder="Número De Documento"
                        {...register('idNumber', {
                          required: true,
                          minLength: 8,
                          maxLength: 9,
                          pattern: /^[a-zA-Z0-9-]+$/,
                        })}
                        onChange={(e) => updateField(e)}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Permiso Especial de Permanencia' ? (
                    <>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 15 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 15 caracteres'}
                      </span>
                      <input
                        className="text-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        min="1"
                        placeholder="Número De Documento"
                        {...register('idNumber', {
                          required: true,
                          minLength: 15,
                          maxLength: 15,
                        })}
                        onChange={(e) => updateField(e)}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Registro Civil' ? (
                    <>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'required' &&
                          'Por favor ingrese el número de documento'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'minLength' &&
                          'El documento no peude tener menos de 10 caracteres'}
                      </span>
                      <span className="text-red-500">
                        {errors.idNumber?.type === 'maxLength' &&
                          'El documento no peude tener mas de 10 caracteres'}
                      </span>
                      <input
                        className="text-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2"
                        type="number"
                        min="1"
                        placeholder="Número De Documento"
                        {...register('idNumber', {
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                        })}
                        onChange={(e) => updateField(e)}
                      />
                    </>
                  ) : null}

                  {documentCategory === 'Adulto sin Identificacíon' ||
                  documentCategory === 'Menor sin Identificacíon' ? (
                    <input
                      className="text-gray-500 appearance-none rounded-none relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 bg-gray-300"
                      type="number"
                      min="1"
                      placeholder="Número De Documento"
                      disabled={true}
                      {...register('idNumber', {
                        required: false,
                        minLength: 4,
                        maxLength: 10,
                      })}
                      onChange={(e) => updateField(e)}
                    />
                  ) : null}
                </div>
              </div>

              <div className="flex justify-center my-5">
                <button
                  type="submit"
                  className="items-center group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
                >
                  Consultar
                </button>
              </div>
            </div>
          </form>
        ) : (
          <CreatePatient
            documentCategory={documentCategory}
            documentNumber={documentNumber}
            setDocumentCategory={setDocumentCategory}
            setDocumentNumber={setDocumentNumber}
            setOpenServiceRegister={setOpenServiceRegister}
            cancelCreatePatient={() => cancelCreatePatient()}
            inputTyeDate={inputTyeDate}
            setInputTypeDate={setInputTypeDate}
          />
        )}
      </div>
    </>
  )
}

export default ConsultPatient
