import React, {useEffect, useState, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import moment from 'moment'

import {Input, ListaDesplegable} from '../inputs'
import {BotonSimple} from '../Botones'
import {
  ObtenerTodasLasSedes,
  ObtenerSedePorId,
} from '../../cache/servicios-cache'
import {
  ReporteAtencionDetalleEntidad,
  ReporteResumenPorEntidad,
} from '../../microservicios'
import {ContextApplication} from '../../contexto'
import {useModal} from '../../hooks/useModal'
import {TIPO_REPORTE} from '../../constantes'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const FormularioGeneradorDeInformes = () => {
  let fechaActual = moment().format('YYYY-MM-DD')
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const sedeId = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
  const contextoAplicacion = useContext(ContextApplication)
  const [cerrarModal] = useModal()
  const {
    trigger,
    reset,
    handleSubmit,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {
      fechaInforme: fechaActual,
      sede: sedeId,
      tipoReporte: '',
    },
    mode: 'onChange',
  })

  const [sedes, setSedes] = useState([])

  useEffect(() => {
    let componenteMontado = true
    trigger()
    const obtenerDatosIniciales = async () => {
      const todasLasSedes = await ObtenerTodasLasSedes()
      setSedes(todasLasSedes)
    }
    if (componenteMontado) {
      obtenerDatosIniciales()
    }

    return () => {
      componenteMontado = false
    }
  }, [])

  const LISTA_TIPO_INFORMES = [
    {
      Id: 1,
      Nombre: 'RESUMEN POR ENTIDAD',
    },
    {
      Id: 2,
      Nombre: 'DETALLE DE ATENCIÓN POR PACIENTE',
    },
  ]

  const manejarAbrirEnOtraPestaña = (url) => {
    cerrarModal()
    if (url !== '') {
      window.open(JSON.parse(url), '_blank')
    }
  }

  const manejarCrearReporte = async (data) => {
    const sede = await ObtenerSedePorId(parseInt(data?.sede))

    try {
      const respuesta =
        parseInt(data?.tipoReporte) ===
        TIPO_REPORTE?.DETALLE_DE_ATENCION_POR_PACIENTE
          ? await ReporteAtencionDetalleEntidad(
              data?.fechaInforme,
              sede?.Id,
              sede?.Nombre
            )
          : await ReporteResumenPorEntidad(
              data?.fechaInforme,
              sede?.Id,
              sede?.Nombre
            )
      if (respuesta.status === 200 && respuesta?.data !== '') {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'INFORME GENERADO Y DESCARGADO',
          contenido: null,
          botones: [
            {
              nombre: 'Imprimir Informe',
              click: () => manejarAbrirEnOtraPestaña(respuesta?.data),
            },
          ],
        })
      } else {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'NO HAY DATOS PARA ESTE DÍA',
          contenido: null,
          botones: [
            {
              nombre: 'Regresar',
              click: () => cerrarModal(),
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const limpiarFormulario = () => {
    reset()
  }

  return (
    <form
      className="w-full flex justify-center items-center flex-wrap"
      onSubmit={handleSubmit(manejarCrearReporte)}
    >
      <div className="w-full flex justify-center gap-5 items-center">
        <Controller
          name="fechaInforme"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              estilosContenedor="w-1/4"
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'fechaInforme'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              mostrarErrores={true}
              tipo={'date'}
              titulo={'Fecha de informe*'}
              fechaMaxima={fechaActual}
              valor={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name="sede"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-1/4'}
              estilosLista={
                'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder pointer-events-none text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
              }
              titulo={'Sede'}
              opciones={sedes}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-center items-center mt-6">
        <Controller
          name="tipoReporte"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-1/4 mx-10'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'tipoReporte')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Tipo de informe*'}
              opciones={LISTA_TIPO_INFORMES}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-center items center my-10">
        <BotonSimple
          texto={'Generar'}
          estilosBoton={`w-24 h-8 text-white ${
            isValid
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={null}
          tipoDeBoton={'submit'}
        />
        <BotonSimple
          texto={'Limpiar'}
          estilosBoton={`w-24 h-8 text-white ${
            true
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={limpiarFormulario}
        />
      </div>
    </form>
  )
}

export default FormularioGeneradorDeInformes
