import moment from 'moment'
import {FORMATO_FECHA_GENERAL, TEXTO_VISUAL} from '../../constantes'
import {aplicarFormatoMoneda} from '../../utilidades'
import {ContenedorTituloResaltado} from '../Contenedores'
import Fila from '../Fila'
import {LabelConValor} from '../Labels'

const TEXTO_PANTALLA =
  TEXTO_VISUAL.GESTION_FACTURACION
    .CORRECCION_ERRORES_TRANSMISION_FIRMA_ELECTRONICA

export const InformacionFactura = ({facturaConErrores}) => {
  return (
    <ContenedorTituloResaltado
      paddingContenedor="py-3 px-4"
      titulo={TEXTO_PANTALLA.INFORMACION_FACTURA.TITULO}
    >
      <Fila alineacionHorizontal="justify-start" ancho="w-full">
        <LabelConValor
          ancho="w-1/3"
          textoLabel={TEXTO_PANTALLA.INFORMACION_FACTURA.FECHA_FACTURA}
          textoValor={
            facturaConErrores.fechaEmision &&
            moment(facturaConErrores.fechaEmision).format(FORMATO_FECHA_GENERAL)
          }
        />
        <LabelConValor
          ancho="w-1/3"
          textoLabel={TEXTO_PANTALLA.INFORMACION_FACTURA.REALIZADO_POR}
          textoValor={facturaConErrores?.nombreResponsable}
        />
        <LabelConValor
          ancho="w-1/3"
          textoLabel={TEXTO_PANTALLA.INFORMACION_FACTURA.VALOR_TOTAL_FACTURA}
          textoValor={
            facturaConErrores.montoTotal &&
            aplicarFormatoMoneda(facturaConErrores.montoTotal)
          }
        />
      </Fila>
      <Fila alineacionHorizontal="justify-start" ancho="w-full mt-4">
        <LabelConValor
          ancho="w-1/3"
          textoLabel={TEXTO_PANTALLA.INFORMACION_FACTURA.NUMERO_FACTURA}
          textoValor={facturaConErrores?.numeroFactura}
        />
        <LabelConValor
          ancho="w-1/3"
          textoLabel={TEXTO_PANTALLA.INFORMACION_FACTURA.SEDE}
          textoValor={facturaConErrores?.nombreSede}
        />
      </Fila>
    </ContenedorTituloResaltado>
  )
}
