import {createContext, useState} from 'react'

export const ContextoGestionFacturacion = createContext('')

export const GestionFacturacionConContexto = ({children}) => {
  const [facturaId, setFacturaId] = useState()
  const [facturaFiltrada, setFacturaFiltrada] = useState([])

  return (
    <ContextoGestionFacturacion.Provider
      value={{
        facturaId,
        setFacturaId,
        facturaFiltrada,
        setFacturaFiltrada,
      }}
    >
      {children}
    </ContextoGestionFacturacion.Provider>
  )
}
