import React from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {FormularioGeneradorDeReportesVersionAntigua} from '../../componentes/Administrador/formularios'

const GeneradorDeReportesVersionAntigua = () => {
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.ADMINISTRADOR_GATO.GENERADOR_DE_REPORTES.TITULO
      }
    >
      <FormularioGeneradorDeReportesVersionAntigua />
    </ContenedorPagina>
  )
}

export default GeneradorDeReportesVersionAntigua
