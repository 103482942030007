import {getRestApi} from './ApiAxios'

export const CrearUsuario = async (nuevoUsuario) => {
  const apiReq = await getRestApi()
  return await apiReq.put('usuario-function/CrearUsuario', nuevoUsuario)
}

export const ActualizarUsuario = async (nuevoUsuario) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('usuario-function/ActualizarUsuario', nuevoUsuario)
}

export const ObtenerUsuarioPorTipoDocumentoYDocumento = async (
  numeroDocumento,
  tipoDocumento
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'usuario-function/ObtenerUsuarioPorTipoDocumentoYDocumento?tipoDocumento=' +
      tipoDocumento +
      '&numeroDocumento=' +
      numeroDocumento
  )
}

export const ObtenerUsuarioPorId = async (idUsuario) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'usuario-function/ObtenerUsuarioPorId?usuarioId=' + idUsuario
  )
}

export const ValidarDocumentoUsuarioId = async (
  tipoDocumento,
  numeroDocumento,
  usuarioId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'usuario-function/ValidarDocumentoUsuarioId?tipoDocumento=' +
      tipoDocumento +
      '&numeroDocumento=' +
      numeroDocumento +
      '&usuarioId=' +
      usuarioId
  )
}
