import {useContext, useEffect, useState} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {useHistory, useLocation} from 'react-router-dom'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import FormularioUsuario from '../../componentes/ejecutivoComercial/FormularioUsuario'
import {ContextApplication} from '../../contexto'
import {
  ActualizarUsuarioB2C,
  ObtenerUsuarioPorIdB2C,
} from '../../microservicios/B2C'
import {FachadaCrearUsuarioB2C} from '../../microservicios'
import {ObtenerEmpresaPorId} from '../../cache/servicios-cache/Empresas'
import {ROLES_PORTAL_CLIENTE} from '../../constantes/rolesPortalCliente'
import {RUTAS_APP} from '../../constantes/rutas/rutasApp'

function CrearUsuarioPortalCliente() {
  const contextApp = useContext(ContextApplication)
  const [esEdicion, setEsEdicion] = useState(false)
  const [datos, setDatos] = useState({filtros: {}, informacion: {}})
  const history = useHistory()
  const {state} = useLocation()

  const obtenerUsuario = async () => {
    try {
      const response = await ObtenerUsuarioPorIdB2C(state?.id)

      if (response.status === 200) {
        const {data} = response

        const empresa = await ObtenerEmpresaPorId(data?.empresaId)

        setDatos(() => ({
          filtros: {empresa: empresa?.Nombre ?? '', rol: ''},
          informacion: {
            empresaId: data?.empresaId,
            activo: data?.activo,
            correoPersonal: data?.correo ?? '',
            userPrincipalName: data?.userPrincipalName ?? '',
            nombres: data?.name ?? data?.nombres ?? '',
            cargo: data?.cargo ?? '',
            rol:
              ROLES_PORTAL_CLIENTE.find((x) => x.nombre === data?.rol)?.id ??
              '',
            guid: data?.guid,
            fechaCreacion: data?.fechaCreacion,
          },
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const goBack = () => {
    contextApp.cerrarAppModal()
    history.push(RUTAS_APP.EJECUTIVA_COMERCIAL.GESTION_USUARIOS_PORTAL_CLIENTE)
  }

  useEffect(() => {
    setEsEdicion(Boolean(state?.edicion))
  }, [state])

  useEffect(() => {
    if (state?.id) obtenerUsuario()
  }, [state?.id])

  const funcionGuardarInformacion = async (datos) => {
    try {
      const response = await FachadaCrearUsuarioB2C(datos)
      if (response.status === 200) {
        const empresas = {}

        const empresasId = response.data.map((usuario) => usuario.empresaId)

        for (const empresaId of empresasId) {
          const empresa = await ObtenerEmpresaPorId(datos?.empresaId)
          if (empresa) empresas[empresaId] = empresa
        }

        contextApp.setModal({
          abierto: true,
          titulo: (
            <div className="w-full">
              <h3 className="text-cendiatra uppercase">Usuario creado</h3>
              <h3 className="text-cendiatra uppercase">
                Correo enviado correctamente
              </h3>
            </div>
          ),
          contenido: (
            <div className="grid grid-cols-1 gap-2">
              {response.data.map((usuario) => {
                return (
                  <ComponenteInformativoUsuario
                    key={usuario.guid}
                    id={usuario.guid}
                    nombreUsuario={usuario.userPrincipalName}
                    empresa={empresas[usuario.empresaId]?.Nombre ?? ''}
                    contrasena={usuario.contrasena}
                    activarBordes={response?.data?.length > 1}
                  />
                )
              })}
            </div>
          ),
          botones: [
            {
              nombre: 'Regresar',
              click: goBack,
            },
            {
              nombre: 'Finalizar',
              click: () => {
                contextApp.cerrarAppModal()
                history.push('/main')
              },
            },
          ],
        })
      }
    } catch (error) {}
  }

  const funcionActualizarInformacion = async (datos) => {
    try {
      const response = await ActualizarUsuarioB2C(datos)

      if (response?.status === 200) {
        const empresa = await ObtenerEmpresaPorId(datos?.empresaId ?? 0)
        contextApp.setModal({
          abierto: true,
          titulo: (
            <div className="w-full">
              <h3 className="text-cendiatra uppercase">
                Datos del usuario actualizados
              </h3>
              <h3 className="text-cendiatra uppercase">
                Correo enviado correctamente
              </h3>
            </div>
          ),
          contenido: (
            <ComponenteInformativoUsuario
              id={datos?.guid ?? ''}
              nombreUsuario={
                response?.data?.userPrincipalName ?? response?.data?.correo
              }
              empresa={empresa?.Nombre ?? ''}
            />
          ),
          botones: [
            {
              nombre: 'Regresar',
              click: goBack,
            },
            {
              nombre: 'Finalizar',
              click: () => {
                contextApp.cerrarAppModal()
                history.push('/main')
              },
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={
        !esEdicion
          ? TEXTO_VISUAL.EJECUTIVO_COMERCIAL.CREAR_USUARIO_PORTAL_CLIENTE
          : TEXTO_VISUAL.EJECUTIVO_COMERCIAL.EDITAR_USUARIO_PORTAL_CLIENTE
      }
    >
      <FormularioUsuario
        funcionGuardar={
          !esEdicion ? funcionGuardarInformacion : funcionActualizarInformacion
        }
        datosUsuario={datos}
        creacion={!esEdicion}
      />
    </ContenedorPagina>
  )
}

export function ComponenteInformativoUsuario({
  id,
  nombreUsuario,
  empresa,
  contrasena = '',
  activarBordes = false,
}) {
  return (
    <div className={`w-full ${activarBordes ? 'border-b pb-2' : ''}`}>
      <h3 className="text-cendiatra uppercase">ID: {id}</h3>
      <h3 className="text-cendiatra uppercase">USUARIO: {nombreUsuario}</h3>
      <h3 className="text-cendiatra uppercase">EMPRESA: {empresa}</h3>
      {contrasena ? (
        <>
          <br />
          <h3 className="text-cendiatra uppercase">
            <strong>
              LA SIGUIENTE CONTRASEÑA FUE GENERADA POR EL SISTEMA, GUARDELA
              ANTES DE CERRAR ESTA VENTANA.
            </strong>
          </h3>
          <br />
          <h3 className="text-cendiatra normal-case">
            CONTRASEÑA: {contrasena}
          </h3>
        </>
      ) : null}
    </div>
  )
}

export default CrearUsuarioPortalCliente
