import {useMsal} from '@azure/msal-react'
import React, {useState, createContext} from 'react'
import {fnObtenerInformacionSessionOLocalStorage} from '../utilidades/window.utils'

export const ContextoAgendaMedico = createContext({})

export const AgendaMedicoConContexto = ({children}) => {
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const sedeId = parseInt(
    fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
  )

  const [atencionesDiaActual, setAtencionesDiaActual] = useState([])
  const [atencionesPasadas, setAtencionesPasadas] = useState([])
  const [sedeSeleccionada, setSedeSeleccionada] = useState(sedeId)
  const [sedeAnterior, setSedeAnterior] = useState(sedeId)
  const [motivoCambioSede, setMotivoCambioSede] = useState('')
  const [actualizarDatos, setActualizarDatos] = useState(true)
  const [ocultarTablaDatos, setOcultarTablaDatos] = useState(false)
  return (
    <ContextoAgendaMedico.Provider
      value={{
        atencionesDiaActual: atencionesDiaActual,
        setAtencionesDiaActual: setAtencionesDiaActual,
        atencionesPasadas: atencionesPasadas,
        setAtencionesPasadas: setAtencionesPasadas,
        sedeSeleccionada: sedeSeleccionada,
        setSedeSeleccionada: setSedeSeleccionada,
        motivoCambioSede: motivoCambioSede,
        setMotivoCambioSede: setMotivoCambioSede,
        sedeAnterior: sedeAnterior,
        setSedeAnterior: setSedeAnterior,
        actualizarDatos: actualizarDatos,
        setActualizarDatos: setActualizarDatos,
        ocultarTablaDatos: ocultarTablaDatos,
        setOcultarTablaDatos: setOcultarTablaDatos,
      }}
    >
      {children}
    </ContextoAgendaMedico.Provider>
  )
}
