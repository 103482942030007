import React from 'react'
import {TEXTO_VISUAL} from '../../constantes'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TablaFacturacionConfirmacionPagos} from '../../componentes/FacturacionConfirmacionDePagos'

const FacturacionConfirmacionDePagos = () => {
  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.FACTURACION_CONFIRMACION_PAGOS.TITULO_PAGINA}
      estilosContenedor={'w-95%'}
    >
      <TablaFacturacionConfirmacionPagos />
    </ContenedorPagina>
  )
}

export default FacturacionConfirmacionDePagos
