import React, {useState, createContext} from 'react'

export const ContextoControlVersionPlantillas = createContext({})

export const ControlVersionPlantillasConContexto = ({children}) => {
  const [plantilla, setPlantilla] = useState({})

  return (
    <ContextoControlVersionPlantillas.Provider
      value={{
        plantilla,
        setPlantilla,
      }}
    >
      {children}
    </ContextoControlVersionPlantillas.Provider>
  )
}
