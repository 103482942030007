import {TEXTO_VISUAL} from '../../constantes'

const TEXTO_MODAL = TEXTO_VISUAL.PORTAFOLIO.MODAL.PORTAFOLIO_GUARDADO()

export const ConfirmacionPortafolioCreado = ({
  id,
  codigoPortafolio,
  nombrePortafolio,
}) => {
  return (
    <div className="w-full flex flex-wrap m-2 text-cendiatra text-xl font-bold uppercase">
      <p className="w-full">
        {TEXTO_MODAL.ID}: {id}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.CODIGO_PORTAFOLIO}: {codigoPortafolio}
      </p>
      <p className="w-full">
        {TEXTO_MODAL.NOMBRE_PORTAFOLIO}: {nombrePortafolio}
      </p>
    </div>
  )
}
