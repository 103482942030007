import React from 'react'
import {regexFormula, regexTextoCaracteresBasicos} from '../../constantes/regex'
import Input from './Input'

const InputRegex = ({
  id,
  cambioValor,
  estilosContenedor,
  ancho,
  estilosInput,
  tipo,
  placeholder,
  titulo,
  deshabilitado,
  valor,
  esFormula,
  contexto,
  numeroPagina,
  requerido,
  desenfocado,
  longitudMaxima,
  regex = !esFormula ? regexTextoCaracteresBasicos : regexFormula,
  mensajeRegex = !esFormula
    ? 'Se permiten estos caracteres: + ( ) [ ] , / - . '
    : 'Se permiten estos caracteres: + ( ) [ ] , / - . @ { } ^ *',
  readOnly = false,
}) => {
  var errorRegex = regex.test(valor) ? null : mensajeRegex

  var errorLongitud =
    longitudMaxima && valor && valor.length > longitudMaxima
      ? 'La longitud máxima del campo es: ' + longitudMaxima
      : null

  var validacionRegex = id + 'regex'

  if (contexto && contexto.numeroPagina === numeroPagina && !deshabilitado) {
    if (
      regex.test(valor) &&
      valor &&
      errorLongitud == null &&
      contexto.errores.includes(validacionRegex)
    ) {
      contexto.setErrores(contexto.errores.filter((x) => x != validacionRegex))
    }
    if (
      (!regex.test(valor) || errorLongitud != null || (requerido && !valor)) &&
      !contexto.errores.includes(validacionRegex)
    ) {
      contexto.setErrores([...contexto.errores, validacionRegex])
    }
  }

  const actualizarValorNombre = (e) => {
    cambioValor(e.target.value)
  }

  const campoDesenfocado = (e) => {
    if (desenfocado) {
      desenfocado(e.target.value)
    }
  }

  return (
    <div className={`flex flex-wrap ${ancho}`}>
      <span className={`flex w-full text-cendiatra-semaforo-rojo text-13px`}>
        {(errorRegex !== null && errorRegex) ||
          (errorLongitud !== null && errorLongitud)}
      </span>
      <Input
        onChange={actualizarValorNombre}
        onBlur={campoDesenfocado}
        estilosContenedor={estilosContenedor}
        estilosInput={`${estilosInput} ${
          !deshabilitado &&
          (errorRegex ||
            errorLongitud ||
            (requerido && !valor) ||
            (contexto && contexto.errores && contexto.errores.includes(id)))
            ? 'border-cendiatra-rojo-1'
            : 'border-cendiatra'
        }`}
        tipo={tipo}
        placeholder={placeholder}
        titulo={titulo}
        valor={valor}
        deshabilitado={deshabilitado}
        readOnly={readOnly}
      />
    </div>
  )
}

export default InputRegex
