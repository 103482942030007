import {getRestApi} from './ApiAxios'

//#region GET
export const ObtenerTarifarios = async (fechaUltimaActualizacion) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'tarifario-function/ObtenerTarifarios?fecha=' + fechaUltimaActualizacion
  )
}
export const ObtenerTarifarioPorEmpresaId = async (idEmpresa) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'tarifario-function/ObtenerTarifarioPorEmpresa?empresaId=' + idEmpresa
  )
}

export const ObtenerTarifarioPorEmpresaIdBD = async (Id) => {
  const apiReq = await getRestApi()
  return await apiReq.get('tarifario-function/ObtenerTarifarioPorId?Id=' + Id)
}
//#endregion

//#region PUT
export const CrearTarifarioBD = async (tarifario) => {
  const apiReq = await getRestApi()
  return await apiReq.put('tarifario-function/CrearTarifario', tarifario)
}
//#endregion

//#region PATCH
export const EditarTarifarioBD = async (tarifario) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('tarifario-function/ModificarTarifarios', tarifario)
}
//#endregion
