import React, {useEffect, useState} from 'react'
import TablaDinamica from '../../TablaDinamica'
import {aplicarFormatoMoneda} from '../../../utilidades'
import {ObtenerMediosDePagoPorId} from '../../../cache/servicios-cache'

export const MediosPago = ({medioPago}) => {
  const [total, setTotal] = useState(0)
  const [medioPagoTransformado, setMedioPagoTransformado] = useState([])

  useEffect(() => {
    const fetchMedioPagoTransformado = async () => {
      if (medioPago) {
        const nuevoTotal = medioPago.reduce(
          (acc, element) => acc + element.valor,
          0
        )
        setTotal(nuevoTotal)

        const promises = medioPago.map((element) =>
          ObtenerMediosDePagoPorId(element.medioPagoId).then(
            (medio) => medio.Nombre
          )
        )

        const medios = await Promise.all(promises)
        setMedioPagoTransformado(medios)
      }
    }

    fetchMedioPagoTransformado()
  }, [medioPago])

  const columns = React.useMemo(
    () => [
      {
        Header: 'MEDIO DE PAGO',
        accessor: 'medioPagoTransformado',
        className: 'w-2/12',
        Cell: (row) => <div>{medioPagoTransformado[row.row.index]}</div>,
      },
      {
        Header: '',
        accessor: 'empty1',
        className: 'w-3/12',
      },
      {
        Header: '',
        accessor: 'empty2',
        className: 'w-20%',
      },
      {
        Header: '',
        accessor: 'empty3',
        className: 'w-2/12',
      },
      {
        Header: 'VALOR',
        accessor: 'valor',
        className: 'w-2/12',
        Cell: (row) => (
          <span>{aplicarFormatoMoneda(row.row.original.valor)}</span>
        ),
      },
    ],
    [medioPagoTransformado]
  )

  return (
    <div className="w-full mb-4">
      <div className="w-full flex justify-between items-center my-6">
        <div className="w-full flex-col">
          <TablaDinamica
            estilosHeader="bg-white text-cendiatra border-cendiatra border rounded-xl font-bold text-lg justify-around"
            data={medioPago}
            columns={columns}
          >
            <div className="grid grid-cols-5 w-full">
              <div className="col-start-5 col-span-1 flex items-center justify-center">
                <div className="flex gap-10 mr-16 text-cendiatra-gris-3 font-bold text-xl">
                  <span>TOTAL</span>
                  <span>{aplicarFormatoMoneda(total)}</span>
                </div>
              </div>
            </div>
          </TablaDinamica>
        </div>
      </div>
    </div>
  )
}

export default MediosPago
