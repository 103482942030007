import React, {useEffect, useState} from 'react'
import Select from 'react-select'

const ListaDesplegableConFiltro = ({
  opciones,
  valor,
  deshabilitado,
  titulo,
  estilosContenedor = 'w-30%',
  colorTextoLabel = 'text-cendiatra-verde-2',
  tamanoTextoLabel = 'text-13px',
  estilosLista,
  placeholder,
  funcion,
  onChange,
  required,
  selectInputRef,
  valorPorDefecto,
  limpio,
}) => {
  const [estilos, setEstilos] = useState('')

  useEffect(() => {
    if (limpio) {
      setEstilos(noEsValido)
    } else {
      setEstilos(esValido)
    }
  }, [limpio])

  const validacionEstilos = !valor?.label && required

  useEffect(() => {
    const estiloRefValue = validacionEstilos ? noEsValido : esValido
    setEstilos(estiloRefValue)
  }, [valor])

  const opcionesTransformada = opciones.map((item) => {
    return {value: item?.value, label: item?.label}
  })

  const cambioDeColor = () => {
    if (validacionEstilos) {
      setEstilos(noEsValido)
    } else {
      setEstilos(esValido)
    }
  }

  let borderValid = () => ({
    border: '1px solid #007377',
    borderRadius: '8px',
  })

  let borderInvalid = () => ({
    border: '1px solid #f57470',
    borderRadius: '8px',
  })

  const esValido = {
    control: (styles) => ({...styles, ...borderValid()}),
  }

  const noEsValido = {
    control: (styles) => ({...styles, ...borderInvalid()}),
  }

  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      {titulo ? (
        <label
          className={`block ${tamanoTextoLabel} leading-15px ${colorTextoLabel} my-5px`}
        >
          {titulo}
        </label>
      ) : null}
      <Select
        ref={selectInputRef}
        styles={estilos}
        className={estilosLista}
        isSearchable={true}
        onChange={funcion ? (e) => funcion(e) : onChange}
        onBlur={cambioDeColor}
        placeholder={placeholder}
        isDisabled={deshabilitado}
        options={opcionesTransformada}
        value={valorPorDefecto}
      ></Select>
    </div>
  )
}

export default ListaDesplegableConFiltro
