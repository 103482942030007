import {useEffect, useState, useRef} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import GraficaAudiometria from './GraficaAudiometria'
import TablaAudiometriaTamiz from './TablaAudiometriaTamiz'
import {valoresPredeterminadosTablaAudiometria} from './Predeterminado/ValoresPredeterminados'
import moment from 'moment'
import {convertirHtmlAImagen} from '../../utilidades'

export default function AudiometriaTamiz({
  escucharFormulario = () => {},
  informacion,
  agregarNodos = () => {},
  readOnly = false,
}) {
  const {setValue, watch, control, getValues, reset} = useForm({
    defaultValues: {
      datosAudiograma: valoresPredeterminadosTablaAudiometria,
    },
  })
  const [calculosAudiometria, setCalculosAudiometria] = useState({})
  const datos = watch('datosAudiograma')
  const formulario = useWatch({control})
  const [recargado, setRecargado] = useState(false)
  const [resetearTabla, setResetearTabla] = useState(false)

  const oidoDerechoRef = useRef(null)

  useEffect(() => {
    escucharFormulario({
      ...formulario,
      ...calculosAudiometria,
    })
  }, [formulario])

  const calculosAudiometriaFn = (datos) => {
    setCalculosAudiometria(datos)
  }

  const manejarCambioInputOChecks = (target, index) => {
    if (readOnly) return

    setValue(`datosAudiograma.${index}.${target.propiedad}`, target.valor)
    escucharFormulario({
      ...getValues(),
      ...calculosAudiometria,
    })
  }

  const fechaSerializada = moment().format('YYYYMMDDHHmmss')

  const asignarNodos = async () => {
    agregarNodos([
      {
        archivo: `oidoDerecho${moment(new Date()).format('ddMMyyyyHHmmss')}`,
        nodo: await convertirHtmlAImagen(
          document.getElementById('oidoDerecho'),
          `oidoDerecho${fechaSerializada}`
        ),
        propiedad: 'URL_Audiograma_OD',
      },
      {
        archivo: `oidoIzquierdo${moment(new Date()).format('ddMMyyyyHHmmss')}`,
        nodo: await convertirHtmlAImagen(
          document.getElementById('oidoIzquierdo'),
          `oidoIzquierdo${fechaSerializada}`
        ),
        propiedad: 'URL_Audiograma_OI',
      },
    ])
  }

  useEffect(() => {
    oidoDerechoRef.current = document.getElementById('oidoDerecho')

    const observer = new IntersectionObserver(async ([entry]) => {
      if (entry.isIntersecting) {
        asignarNodos()
      }
    })
    if (oidoDerechoRef.current) {
      observer.observe(oidoDerechoRef.current)
    }
    return () => {
      if (oidoDerechoRef.current) {
        observer.unobserve(oidoDerechoRef.current)
      }
    }
  }, [])

  useEffect(() => {
    asignarNodos()
  }, [formulario])

  useEffect(() => {
    if (
      !recargado &&
      informacion?.hasOwnProperty('URL_Audiograma_OD') &&
      informacion?.hasOwnProperty('URL_Audiograma_OI')
    ) {
      if (informacion?.URL_Audiograma_OD && informacion?.URL_Audiograma_OI) {
        reset({
          datosAudiograma:
            informacion?.datosAudiograma ??
            valoresPredeterminadosTablaAudiometria,
        })
        setRecargado(true)
      }
    } else {
      if (Object.entries(informacion).length === 0) {
        reset({datosAudiograma: valoresPredeterminadosTablaAudiometria})
        setResetearTabla(true)
      }
    }
    return () => setRecargado(false)
  }, [informacion])

  return (
    <div
      className={`w-12/12 flex flex-wrap ${
        readOnly ? 'pointer-events-none' : ''
      }`}
    >
      <div className="w-full">
        <TablaAudiometriaTamiz
          datos={datos}
          manejarCambioInputOChecks={manejarCambioInputOChecks}
          resetear={resetearTabla}
          setResetear={setResetearTabla}
          watch={watch}
        />
      </div>
      <div className="w-full">
        <GraficaAudiometria
          dataOD={datos[0]}
          dataOI={datos[1]}
          calculosAudiometria={calculosAudiometriaFn}
        />
      </div>
    </div>
  )
}
