import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  useMemo,
} from 'react'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import AtencionesAMostrar from '../../componentes/Agenda/AtencionesAMostrar'
import LeyendasAgenda from '../../componentes/Agenda/LeyendasAgenda'
import {CeldaDobleTexto} from '../../componentes/Celdas/CeldaDobleTexto'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import TablaAgenda from '../../componentes/Agenda/TablaAgenda'
import ExamenesPaciente from '../../componentes/Agenda/ExamenesPaciente'
import {LEYENDA_AGENDA} from '../../constantes/leyendaAgendas'
import {
  AgregarEmpresasACache,
  ObtenerEmpresaPorId,
} from '../../cache/servicios-cache/Empresas'
import {ContextoBacteriologo} from '../../contexto'
import moment from 'moment/moment'
import {ObtenerServicioPorId} from '../../cache/servicios-cache/Servicios'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import {useHistory} from 'react-router-dom'
import {ObtenerUrlHubSignalR} from '../../microservicios/Comunicacion'
import {HubConnectionBuilder} from '@microsoft/signalr'
import {ObtenerPermisosServiciosPorRol} from '../../microservicios/Rol'
import {FachadaObtenerLaboratoriosPendientesPorCerrarPorSedeId} from '../../microservicios/Fachada'
import {useConexionSignalR} from '../../hooks/signalR/useConexionSignalR'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const RegistrarResultados = () => {
  const [atencionesDeHoy, setAtencionesDeHoy] = useState(true)
  const history = useHistory()
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const contextoBacteriologo = useContext(ContextoBacteriologo)

  const [montado, setMontado] = useState(false)

  const sede = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)

  useEffect(() => {
    let componenteMontado = true
    const AgregarDatosACache = async () => {
      await AgregarEmpresasACache()
      await AgregarServiciosACache()
      if (componenteMontado) {
        manejarObtenerDatos().catch((error) => {
          console.log(error)
        })
      }
    }
    AgregarDatosACache()
    return () => {
      componenteMontado = false
    }
  }, [])

  const manejarObtenerDatos = useCallback(async () => {
    ObtenerInformacionLaboratorios()
  }, [])

  useEffect(() => {
    setMontado(true)
    return () => setMontado(false)
  }, [])

  const manejarServicioAtencionEnProceso = (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoBacteriologo.laboratoriosDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      let atencionesfiltradas =
        contextoBacteriologo.laboratoriosDiaActual.filter(
          (x) => x.id != mensaje.atencionId
        )
      contextoBacteriologo.setLaboratoriosDiaActual(atencionesfiltradas)
    } else {
      let atencionesfiltradas =
        contextoBacteriologo.laboratoriosDiasPasados.filter(
          (x) => x.id != mensaje.atencionId
        )
      contextoBacteriologo.setLaboratoriosDiasPasados(atencionesfiltradas)
    }
  }

  const validarAtencionCorrespondeARol = async (atencion) => {
    let idsEspecialidades = (await ObtenerPermisosServiciosPorRol()).data
      .serviciosIds
    let existenServicios = atencion.filter((element) =>
      idsEspecialidades?.includes(element.servicioId)
    )
    return existenServicios
  }

  const validarExistenciaAlias = async (servicio) => {
    if (servicio.alias) {
      return servicio.alias ?? ''
    } else {
      return (await ObtenerServicioPorId(servicio.servicioId))?.Nombre ?? ''
    }
  }

  const manejarAgregarAtencionSede = async (message) => {
    let mensajeAtencionAgregado = JSON.parse(message)
    if (
      (await validarAtencionCorrespondeARol(mensajeAtencionAgregado.servicios))
        .length < 1
    ) {
      return
    }

    if (
      contextoBacteriologo.laboratoriosDiaActual.find(
        (x) => x.id == mensajeAtencionAgregado.atencionId
      )
    ) {
      contextoBacteriologo.setLaboratoriosDiaActual(
        await Promise.all(
          contextoBacteriologo.laboratoriosDiaActual.map(async (atencion) => {
            if (atencion.id === mensajeAtencionAgregado.atencionId) {
              const nuevosExamenes = await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (laboratorio) => {
                  return {
                    tipoExamen: await validarExistenciaAlias(laboratorio),
                    estadoExamen: laboratorio.estadoServicioId,
                    medico: laboratorio.nombreMedico,
                    enCurso: laboratorio.enCurso,
                    especialidadMedico: true,
                    servicioId: laboratorio.servicioId,
                    servicioAtencionId: laboratorio.servicioAtencionId,
                  }
                })
              )

              return {
                ...atencion,
                examenes: nuevosExamenes,
              }
            }
            return atencion
          })
        )
      )
      return
    } else if (
      contextoBacteriologo.laboratoriosDiasPasados.find(
        (x) => x.id == mensajeAtencionAgregado.atencionId
      )
    ) {
      contextoBacteriologo.setLaboratoriosDiasPasados(
        await Promise.all(
          contextoBacteriologo.laboratoriosDiasPasados.map(async (atencion) => {
            if (atencion.id === mensajeAtencionAgregado.atencionId) {
              const nuevosExamenes = await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (laboratorio) => {
                  return {
                    tipoExamen: await validarExistenciaAlias(laboratorio),
                    estadoExamen: laboratorio.estadoServicioId,
                    medico: laboratorio.nombreMedico,
                    enCurso: laboratorio.enCurso,
                    especialidadMedico: true,
                    servicioId: laboratorio.servicioId,
                    servicioAtencionId: laboratorio.servicioAtencionId,
                  }
                })
              )

              return {
                ...atencion,
                examenes: nuevosExamenes,
              }
            }
            return atencion
          })
        )
      )
      return
    } else {
      let atencionAAgregar = {
        id: mensajeAtencionAgregado.atencionId,
        idOrden: mensajeAtencionAgregado.ordenId,
        usuarioId: mensajeAtencionAgregado.usuarioId,
        numeroOrdenyFechaCreacion: {
          Fila1: moment(mensajeAtencionAgregado.fechaCreacion).format('LT'),
          Fila2: moment(mensajeAtencionAgregado.fechaCreacion).format(
            'DD/MM/YYYY'
          ),
        },
        nombresyDocumento: {
          Fila1: mensajeAtencionAgregado?.nombreCompleto?.toUpperCase() ?? '',
          Fila2: mensajeAtencionAgregado.numeroDocumento,
        },
        empresa:
          (
            await ObtenerEmpresaPorId(mensajeAtencionAgregado.empresaId)
          )?.Nombre?.toUpperCase() ?? '',
        examenes: await Promise.all(
          mensajeAtencionAgregado.servicios.map(async (laboratorio) => {
            return {
              tipoExamen: await validarExistenciaAlias(laboratorio),
              estadoExamen: laboratorio.estadoServicioId,
              medico: laboratorio.nombreMedico,
              enCurso: laboratorio.enCurso,
              especialidadMedico: true,
              servicioId: laboratorio.servicioId,
              servicioAtencionId: laboratorio.servicioAtencionId,
            }
          })
        ),
      }
      const fechaActual = moment().format('YYYY/MM/DD')
      const fechaAtencion = moment(
        mensajeAtencionAgregado.fechaCreacion
      ).format('YYYY/MM/DD')
      if (fechaActual > fechaAtencion) {
        contextoBacteriologo.setLaboratoriosDiasPasados((prevLaboratorios) => [
          ...prevLaboratorios,
          atencionAAgregar,
        ])
      } else {
        contextoBacteriologo.setLaboratoriosDiaActual((prevLaboratorios) => [
          ...prevLaboratorios,
          atencionAAgregar,
        ])
      }
    }
  }

  const listaMensajesYFunciones = [
    {
      nombreConexion: 'atencionAgregadaLaboratorioSede',
      funcion: manejarAgregarAtencionSede,
    },
    {
      nombreConexion: 'medicoRetirarAtencion',
      funcion: manejarServicioAtencionEnProceso,
    },
  ]

  const [conexion, agregarSubscripciones, eliminarSubscripciones] =
    useConexionSignalR(listaMensajesYFunciones, sede)

  useEffect(() => {
    if (!montado) {
      return
    }
    if (conexion) {
      eliminarSubscripciones(listaMensajesYFunciones)
      agregarSubscripciones(listaMensajesYFunciones)
    }
  }, [
    contextoBacteriologo.laboratoriosDiaActual,
    contextoBacteriologo.laboratoriosDiasPasados,
  ])

  let columns = useMemo(
    () => [
      {
        Header: (row) => <EncabezadoFondo titulo={'Número de atención'} />,
        accessor: 'id',
        className: 'w-12% ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center rounded-l-lg',
      },
      {
        Header: (row) => (
          <EncabezadoFondo titulo={'Hora llegada y fecha de registro'} />
        ),
        accessor: 'numeroOrdenyFechaCreacion',
        className: 'w-15%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 ',
        Cell: (row) => (
          <CeldaDobleTexto datos={row.row.original.numeroOrdenyFechaCreacion} />
        ),
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: 'Nombres ó cédula',
        accessor: 'nombresyDocumento',
        className: 'w-22%',
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 text-white text-14px',
        filter: 'dobleTexto',
        Cell: (row) => (
          <CeldaDobleTexto datos={row.row.original.nombresyDocumento} />
        ),
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center uppercase',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Empresa'} />,
        accessor: 'empresa',
        className: 'w-17%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center uppercase',
      },

      {
        Header: (row) => <EncabezadoFondo titulo={'Exámenes'} />,
        accessor: 'examenes',
        className: 'w-34%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
        Cell: (row) => (
          <ExamenesPaciente
            datos={row.row.original.examenes}
            funcion={(servicioAtencionId, servicioId) =>
              abrirPlantillaServicio(
                row.row.original.usuarioId,
                row.row.original.id,
                row.row.original.examenes,
                servicioAtencionId,
                servicioId
              )
            }
            ocultarNombreTrabajador={true}
          />
        ),
      },
    ],
    [
      contextoBacteriologo.laboratoriosDiaActual,
      contextoBacteriologo.laboratoriosDiasPasados,
      atencionesDeHoy,
    ]
  )

  const abrirPlantillaServicio = (
    usuarioId,
    atencionId,
    examenes,
    servicioAtencionId,
    servicioId
  ) => {
    history.push('/examen', {
      usuarioId: usuarioId,
      atencionId: atencionId,
      servicioAtencionId: servicioAtencionId,
      examenes: examenes,
      servicioId: servicioId,
      registrarResultados: true,
    })
  }

  const manejarMostrarTipoAtenciones = () => {
    setAtencionesDeHoy(!atencionesDeHoy)
  }

  const sedeId = parseInt(
    fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
  )
  const ObtenerInformacionLaboratorios = () => {
    FachadaObtenerLaboratoriosPendientesPorCerrarPorSedeId(true, sedeId).then(
      async (res) => {
        if (res.data) {
          contextoBacteriologo.setLaboratoriosDiaActual(
            await Promise.all(
              res.data.map(async (atencion) => {
                return {
                  id: atencion.atencionId,
                  usuarioId: atencion.usuarioId,
                  numeroOrdenyFechaCreacion: {
                    Fila1: moment(atencion.fechaCreacion).format('LT'),
                    Fila2: moment(atencion.fechaCreacion).format('DD/MM/YYYY'),
                  },
                  nombresyDocumento: {
                    Fila1: atencion?.nombreCompleto?.toUpperCase() ?? '',
                    Fila2: atencion?.numeroDocumento ?? '',
                  },
                  empresa:
                    (
                      await ObtenerEmpresaPorId(atencion.empresaId)
                    )?.Nombre.toUpperCase() ?? '',
                  examenes: await Promise.all(
                    atencion.servicios.map(async (laboratorio) => {
                      return {
                        tipoExamen: await validarExistenciaAlias(laboratorio),
                        estadoExamen: laboratorio.estadoServicioId,
                        medico: laboratorio.nombreMedico,
                        enCurso: laboratorio.enCurso,
                        especialidadMedico: true,
                        servicioId: laboratorio.servicioId,
                        servicioAtencionId: laboratorio.servicioAtencionId,
                      }
                    })
                  ),
                }
              })
            )
          )
        }
      }
    )
    FachadaObtenerLaboratoriosPendientesPorCerrarPorSedeId(false, sedeId).then(
      async (res) => {
        if (res.data) {
          contextoBacteriologo.setLaboratoriosDiasPasados(
            await Promise.all(
              res.data.map(async (atencion) => {
                return {
                  id: atencion.atencionId,
                  usuarioId: atencion.usuarioId,
                  numeroOrdenyFechaCreacion: {
                    Fila1: moment(atencion.fechaCreacion).format('LT'),
                    Fila2: moment(atencion.fechaCreacion).format('DD/MM/YYYY'),
                  },
                  nombresyDocumento: {
                    Fila1: atencion?.nombreCompleto?.toUpperCase() ?? '',
                    Fila2: atencion?.numeroDocumento ?? '',
                  },
                  empresa:
                    (
                      await ObtenerEmpresaPorId(atencion.empresaId)
                    )?.Nombre.toUpperCase() ?? '',
                  examenes: await Promise.all(
                    atencion.servicios.map(async (laboratorio) => {
                      return {
                        tipoExamen: await validarExistenciaAlias(laboratorio),
                        estadoExamen: laboratorio.estadoServicioId,
                        medico: laboratorio.nombreMedico,
                        enCurso: laboratorio.enCurso,
                        especialidadMedico: true,
                        servicioId: laboratorio.servicioId,
                        servicioAtencionId: laboratorio.servicioAtencionId,
                      }
                    })
                  ),
                }
              })
            )
          )
        }
      }
    )
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.BACTERIOLOGIA.RESULTADOS_EXAMEN}
    >
      <div className="w-full flex justify-between items-center mt-8 flex-wrap">
        <div className="w-3/12 flex flex-wrap justify-center items-center border border-cendiatra py-5px rounded-lg h-20">
          <AtencionesAMostrar
            seleccionado={atencionesDeHoy}
            funcion={manejarMostrarTipoAtenciones}
            tituloLeyendaSuperior={'Atenciones pendientes hoy'}
            tituloLeyendaInferior={
              'Atenciones pendientes por cerrar de días anteriores'
            }
            valorLeyendaSuperior={
              contextoBacteriologo.laboratoriosDiaActual.length
            }
            valorLeyendaInferior={
              contextoBacteriologo.laboratoriosDiasPasados.length
            }
          />
        </div>

        <div className="w-9/12 flex justify-end content-between h-20 ">
          <div className="w-5/12 -mr-28">
            <LeyendasAgenda leyendas={LEYENDA_AGENDA.BACTERIOLOGO} />
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="w-full flex justify-center items-center">
            <TablaAgenda
              ocultarTabla={false}
              data={
                atencionesDeHoy
                  ? contextoBacteriologo.laboratoriosDiaActual
                  : contextoBacteriologo.laboratoriosDiasPasados
              }
              columns={columns}
            />
          </div>
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default RegistrarResultados
