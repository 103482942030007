import {createContext, useState} from 'react'

export const ContextoPortafolio = createContext('')

export const PortafolioConContexto = ({children}) => {
  const [formularioPortafolio, setFormularioPortafolio] = useState({})
  const [formularioActualTieneErrores, setformularioActualTieneErrores] =
    useState(true)
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [sedesSeleccionadas, setSedesSeleccionadas] = useState([])
  const [totalPaginas, setTotalPaginas] = useState(4)
  const [aliadosSeleccionados, setAliadosSeleccionados] = useState([])
  const [listaServicios, setListaServicios] = useState([])
  const [listaPaquetes, setListaPaquetes] = useState([])
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1)
  const [nombrePortafolioValidacion, setNombrePortafolioValidacion] =
    useState('')
  const [precioServiciosIniciales, setPreciosServiciosIniciales] = useState({})
  const [preciosPaquetesIniciales, setPreciosPaquetesIniciales] = useState({})

  const [actualizar, setActualizar] = useState(false)
  const [portafolioId, setPortafolioId] = useState('')

  const [modoEdicion, setModoEdicion] = useState(false)

  const [serviciosIniciales, setServiciosIniciales] = useState([])
  const [paquetesIniciales, setPaquetesIniciales] = useState([])

  const [sedesIniciales, setSedesIniciales] = useState([])
  const [aliadosIniciales, setAliadosIniciales] = useState([])

  const [arregloMomentoActivacion, setArregloMomentoActivacion] = useState([])
  const [
    validacionActivarBotonPortafolio,
    setValidacionActivarBotonPortafolio,
  ] = useState(true)

  return (
    <ContextoPortafolio.Provider
      value={{
        formularioPortafolio,
        setFormularioPortafolio,
        formularioActualTieneErrores,
        setformularioActualTieneErrores,
        numeroPagina,
        setNumeroPagina,
        sedesSeleccionadas,
        setSedesSeleccionadas,
        totalPaginas,
        setTotalPaginas,
        aliadosSeleccionados,
        setAliadosSeleccionados,
        listaServicios,
        setListaServicios,
        listaPaquetes,
        setListaPaquetes,
        filaSeleccionada,
        setFilaSeleccionada,
        nombrePortafolioValidacion,
        setNombrePortafolioValidacion,
        actualizar,
        setActualizar,
        portafolioId,
        setPortafolioId,
        modoEdicion: modoEdicion,
        setModoEdicion: setModoEdicion,
        serviciosIniciales: serviciosIniciales,
        setServiciosIniciales: setServiciosIniciales,
        paquetesIniciales: paquetesIniciales,
        setPaquetesIniciales: setPaquetesIniciales,
        sedesIniciales: sedesIniciales,
        setSedesIniciales: setSedesIniciales,
        aliadosIniciales: aliadosIniciales,
        setAliadosIniciales: setAliadosIniciales,
        precioServiciosIniciales: precioServiciosIniciales,
        setPreciosServiciosIniciales: setPreciosServiciosIniciales,
        preciosPaquetesIniciales: preciosPaquetesIniciales,
        setPreciosPaquetesIniciales: setPreciosPaquetesIniciales,
        arregloMomentoActivacion: arregloMomentoActivacion,
        setArregloMomentoActivacion: setArregloMomentoActivacion,
        validacionActivarBotonPortafolio: validacionActivarBotonPortafolio,
        setValidacionActivarBotonPortafolio:
          setValidacionActivarBotonPortafolio,
      }}
    >
      {children}
    </ContextoPortafolio.Provider>
  )
}
