import {useContext} from 'react'
import {ContextoFormulario} from '../contexto'
import moment from 'moment'
import {ObtenerHistoriaClinica} from '../microservicios/Fachada'
import {ObtenerServicioPorId} from '../cache/servicios-cache/Servicios'

export const useObtenerHistoriaClinicaUsuario = (idUsuario) => {
  const contextoFormulario = useContext(ContextoFormulario)

  const ordenarHistoriaClinicaPaciente = (listaAtenciones) => {
    let listaHistoriaClinica = []
    listaHistoriaClinica = listaAtenciones.sort((a, b) => {
      return moment(a.fechaYHoraCreacion).isBefore(moment(b.fechaYHoraCreacion))
        ? -1
        : 1
    })
    return listaHistoriaClinica
  }

  const obtenerHistoriaClinicaPaciente = (idUsuario, estado = '') => {
    const estadoParaActualizar = estado
      ? estado
      : contextoFormulario.setHistoriaClinicaUsuario
    ObtenerHistoriaClinica(idUsuario).then(async (respuesta) => {
      if (respuesta.status === 200) {
        const informacion = ordenarHistoriaClinicaPaciente(
          await Promise.all(
            respuesta.data.historialServicios.map(async (serv) => ({
              id: serv.servicioId ?? '',
              servicio:
                (
                  await ObtenerServicioPorId(serv.servicioId)
                )?.Nombre?.toUpperCase() ?? '',
              fechaYHoraCreacion: serv.fechaAtencion ?? '',
              fechaYHoraCierre: serv.fechaCierre ?? '',
              medico: serv?.medico?.toUpperCase() ?? '',
              urlCertificado: serv.urlCertificado ?? '',
              urlHistoria: serv.urlHistoria ?? '',
            }))
          )
        )
        estadoParaActualizar(informacion)
      } else {
        estadoParaActualizar([])
      }
    })
  }
  return [obtenerHistoriaClinicaPaciente]
}
