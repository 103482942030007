import {getRestApi} from './ApiAxios'

//#region PUT
export const crearNuevoPaquete = async (paquete) => {
  const apiReq = await getRestApi()
  return await apiReq.put('servicio-function/CrearPaquete', paquete)
}

export const CrearNuevoServicio = async (servicio) => {
  const apiReq = await getRestApi()
  return await apiReq.put('servicio-function/CrearServicio', servicio)
}
//#endregion

//#region GET
export const ExportarPaquetes = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('servicio-function/ExportarPaquetes')
}

export const ObtenerPaquetes = async (fechaUltimaActualizacion) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'servicio-function/ObtenerPaquetes?fecha=' + fechaUltimaActualizacion
  )
}

export const ObtenerPaquetesPorNombreDB = async (nombre) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'servicio-function/ObtenerPaquetesPorNombre?nombre=' + nombre
  )
}

export const ObtenerPaquetePorId = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get('servicio-function/ObtenerPaquetePorId?id=' + id)
}

export const ProximoCodigoInternoServicio = async (cupsId) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'servicio-function/ProximoCodigoInternoServicio?cupsId=' + cupsId
  )
}

export const ExportarServicios = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('servicio-function/ExportarServicios')
}

export const ObtenerServicios = async (fechaUltimaActualizacion) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'servicio-function/ObtenerServicios?fecha=' + fechaUltimaActualizacion
  )
}

export const ObtenerServiciosPorNombre = async (nombre) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'servicio-function/ObtenerServiciosPorNombre?nombre=' + nombre
  )
}

export const ObtenerServicioPorId = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get('servicio-function/ObtenerServicioPorId?id=' + id)
}

export const ObtenerCuentasDeIngreso = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('servicio-function/ObtenerCuentaIngresos')
}
//#endregion

//#region PATCH
export const ModificarServicio = async (servicio) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('servicio-function/ModificarServicio', servicio)
}

export const ModificarPaquete = async (paquete) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('servicio-function/ModificarPaquete', paquete)
}
//#endregion

//#region POST

export const ObtenerServiciosActivosPorSede = async (modelo) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'servicio-function/ObtenerServiciosActivosPorSedeId',
    modelo
  )
}
//#endregion
