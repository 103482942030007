import React, {useCallback, useEffect, useState, useContext} from 'react'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import LeyendasAgenda from '../../componentes/Agenda/LeyendasAgenda'
import {CeldaDobleTexto} from '../../componentes/Celdas/CeldaDobleTexto'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import TablaAgenda from '../../componentes/Agenda/TablaAgenda'
import {LEYENDA_AGENDA} from '../../constantes/leyendaAgendas'
import BotonConImagen from '../../componentes/BotonConImagen'
import IconoImprimirActivado from '../../imagenes/iconoImpresoraCuadradoVerde.svg'
import IconoImprimirDesactivado from '../../imagenes/iconoImprimirGrisCuadrado.svg'
import LaboratoriosPaciente from '../../componentes/bacteriologia/LaboratoriosPaciente'
import {
  imprimirStickerLaboratorio,
  FachadaObtenerLaboratoriosPorAtencionId,
  FachadaValidarServiciosLaboratorioPorAtencionId,
} from '../../microservicios/Fachada'
import {useLocation} from 'react-router-dom'
import moment from 'moment/moment'
import {ObtenerEmpresaPorId} from '../../cache/servicios-cache/Empresas'
import {ObtenerServicioPorId} from '../../cache/servicios-cache/Servicios'
import {useHistory} from 'react-router-dom'
import Boton from '../../componentes/Botones/Boton'
import {ContextApplication} from '../../contexto'
import {useConexionSignalR} from '../../hooks/signalR/useConexionSignalR'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import ImagenEstandar from '../../componentes/MostrarInformacion/ImagenEstandar'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const TomaDeMuestra = () => {
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const [informacionLaboratorios, setInformacionLaboratorios] = useState([])

  const [urlFotoUsuario, setUrlFotoUsuario] = useState('')

  const [seleccionarTodo, setSeleccionarTodo] = useState(false)

  const contextoAplicacion = useContext(ContextApplication)

  const {state} = useLocation()

  const history = useHistory()

  const sede = fnObtenerInformacionSessionOLocalStorage('sedeId', rol)

  useEffect(() => {
    contextoAplicacion.setDeshabilitarMenu(true)
  }, [])

  const manejarCambiarEstadoLaboratorio = (message) => {
    let mensaje = JSON.parse(message)
    let examenesIds = []
    mensaje.map((elemento) => {
      examenesIds.push(elemento.servicioAtencionId)
    })
    setInformacionLaboratorios(
      informacionLaboratorios.map((atencion) => {
        if (mensaje.filter((elemento) => elemento.atencionId == atencion.id)) {
          return {
            ...atencion,
            examenes: atencion.examenes.map((examen) => {
              if (examenesIds.find((id) => id === examen.servicioAtencionId)) {
                return {
                  ...examen,
                  estadoExamen: 2,
                }
              }
              return examen
            }),
          }
        }
        return atencion
      })
    )
  }

  const listaMensajesYFunciones = [
    {
      nombreConexion: 'bacteriologoServicioAtencionPendienteCerrarSede',
      funcion: manejarCambiarEstadoLaboratorio,
    },
  ]

  const [conexion, agregarSubscripciones, eliminarSubscripciones] =
    useConexionSignalR(listaMensajesYFunciones, sede)

  useEffect(() => {
    if (conexion) {
      eliminarSubscripciones(listaMensajesYFunciones)
      agregarSubscripciones(listaMensajesYFunciones)
    }
  }, [informacionLaboratorios])

  const validarExistenciaAlias = async (servicio) => {
    if (servicio?.alias) {
      return servicio?.alias ?? ''
    }
    return (await ObtenerServicioPorId(servicio.servicioId))?.Nombre ?? ''
  }

  const obtenerInformacionAtencion = useCallback(() => {
    FachadaObtenerLaboratoriosPorAtencionId(state.atencionId).then(
      async (res) => {
        if (res.status === 200) {
          setUrlFotoUsuario(res?.data?.fotoURL)
          setInformacionLaboratorios([
            {
              id: res.data.atencionId,
              idOrden: res.ordenId,
              usuarioId: res.data.usuarioId,
              numeroOrdenyFechaCreacion: {
                Fila1: moment(res.data.fechaCreacion).format('LT'),
                Fila2: moment(res.data.fechaCreacion).format('DD/MM/YYYY'),
              },
              nombresyDocumento: {
                Fila1: res.data.nombreCompleto.toUpperCase(),
                Fila2: res.data.numeroDocumento,
              },
              empresa: (
                await ObtenerEmpresaPorId(res.data.empresaId)
              ).Nombre.toUpperCase(),
              tipoPaciente: res.data.tipoPacienteId,
              examenes: await Promise.all(
                res.data.servicios.map(async (servicio) => {
                  return {
                    tipoExamen:
                      (await validarExistenciaAlias(servicio))?.toUpperCase() ??
                      '',
                    estadoExamen: servicio.estadoServicioId,
                    medico:
                      servicio.estadoServicioId === 2 ||
                      servicio.estadoServicioId === 5
                        ? servicio.tipoGuardado + ' ' + servicio.nombreMedico
                        : '',
                    enCurso: servicio.enCurso,
                    especialidadMedico: servicio.accesoPermitido,
                    servicioAtencionId: servicio.servicioAtencionId,
                    servicioId: servicio.servicioId,
                  }
                })
              ),
              ocupadoPor: {
                Fila1: '',
                Fila2: 'PENDIENTE POR CERRAR',
              },
            },
          ])
        }
      }
    )
  }, [])

  useEffect(() => {
    obtenerInformacionAtencion()
  }, [obtenerInformacionAtencion])

  const manejarSeleccionarLaboratorios = (id, todoSeleccionado) => {
    setInformacionLaboratorios(
      informacionLaboratorios.map((elemento) => {
        return {
          ...elemento,
          examenes: elemento.examenes.map((examen) => {
            if (todoSeleccionado) {
              setSeleccionarTodo(!seleccionarTodo)
            }
            if (examen.servicioAtencionId === id || todoSeleccionado) {
              return {
                ...examen,
                seleccionado: todoSeleccionado
                  ? !seleccionarTodo
                  : !examen.seleccionado,
              }
            }
            return examen
          }),
        }
      })
    )
  }
  const imprimirSticker = () => {
    const servicios = informacionLaboratorios[0].examenes
      .filter((laboratorio) => laboratorio.seleccionado === true)
      .map((elemento) => {
        return elemento.servicioAtencionId
      })
    const laboratorios = informacionLaboratorios[0].examenes
      .filter((laboratorio) => laboratorio.seleccionado === true)
      .map((elemento) => {
        return elemento.servicioId
      })
    imprimirStickerLaboratorio(
      informacionLaboratorios[0].id,
      servicios,
      laboratorios
    ).then((res) => {
      const file = new Blob([res.data], {type: 'application/pdf'})
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank')
    })
  }

  let columns = [
    {
      Header: (row) => <EncabezadoFondo titulo={'Número de atención'} />,
      accessor: 'id',
      className: 'w-12% ',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center h-52 rounded-l-lg',
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Hora llegada y fecha de registro'} />
      ),
      accessor: 'numeroOrdenyFechaCreacion',
      className: 'w-12%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
      Cell: (row) => (
        <CeldaDobleTexto datos={row.row.original.numeroOrdenyFechaCreacion} />
      ),
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center h-52',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nombres ó cédula'} />,
      accessor: 'nombresyDocumento',
      className: 'w-15%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
      disableFilters: true,
      Cell: (row) => (
        <CeldaDobleTexto datos={row.row.original.nombresyDocumento} />
      ),
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center h-52',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Empresa'} />,
      accessor: 'empresa',
      className: 'w-15%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center h-52',
    },

    {
      Header: (row) => <EncabezadoFondo titulo={'Exámenes'} />,
      accessor: 'examenes',
      className: 'w-34%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14 ',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center h-52',

      Cell: (row) => (
        <LaboratoriosPaciente
          datos={row.row.original.examenes}
          funcionCheckbox={manejarSeleccionarLaboratorios}
          valorSeleccionarTodo={seleccionarTodo}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Imprimir sticker'} />,
      accessor: 'sticker',
      className: 'w-12%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-r-lg',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center h-52 rounded-r-lg',
      Cell: (row) => (
        <BotonConImagen
          imagen={
            informacionLaboratorios[0].examenes.find(
              (elemento) => elemento.seleccionado === true
            )
              ? IconoImprimirActivado
              : IconoImprimirDesactivado
          }
          textoAlternativo={'Imprimir'}
          funcionAEjecutar={imprimirSticker}
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
          desabilitado={
            !informacionLaboratorios[0].examenes.find(
              (elemento) => elemento.seleccionado === true
            )
          }
        />
      ),
    },
  ]

  const redireccionarBacteriologo = (atencionId, ruta) => {
    FachadaValidarServiciosLaboratorioPorAtencionId(atencionId)
      .then(() => {
        history.push(ruta)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.BACTERIOLOGIA.TOMA_DE_MUESTRAS}
      funcionBotonCerrar={() =>
        redireccionarBacteriologo(state.atencionId, '/agendamedico')
      }
    >
      <div className="w-full flex justify-between items-center mt-8 flex-wrap">
        <div className="w-full h-238 flex flex-wrap justify-between items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 shadow-2xl">
          <div className="w-5/12 flex justify-start items-center flex-wrap text-center ml-20">
            <ImagenEstandar
              imagen={urlFotoUsuario}
              estilosContenedor={'w-full flex justify-start items-center my-3'}
              estilosImagen={'w-40 h-40 rounded-full'}
              textoAlternativo={'foto del usuario'}
            />
          </div>
          <div className="w-5/12 -mr-52 -mt-20">
            <LeyendasAgenda leyendas={LEYENDA_AGENDA.BACTERIOLOGO} />
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="w-full flex justify-center items-center">
            <TablaAgenda
              ocultarTabla={false}
              data={informacionLaboratorios}
              columns={columns}
            />
          </div>
        </div>

        <div className="w-full flex justify-center items-center mb-5">
          <Boton
            titulo={'Finalizar'}
            funcionCLick={() =>
              redireccionarBacteriologo(state.atencionId, '/')
            }
            habilitado={true}
            colorPorDefecto={'bg-grayColor'}
            colorActivo={'bg-btnBg'}
            tipo={'button'}
          />
          <Boton
            titulo={'Regresar'}
            funcionCLick={() =>
              redireccionarBacteriologo(state.atencionId, '/agendamedico')
            }
            habilitado={true}
            colorPorDefecto={'bg-grayColor'}
            colorActivo={'bg-btnBg'}
            tipo={'button'}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default TomaDeMuestra
