import React, {useContext, useState, useEffect} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import EncabezadoHistoriaClinica from '../../componentes/recepcionista/EncabezadoHistoriaClinica'
import {ObtenerHistoriaClinica} from '../../microservicios/Fachada'
import {ContextApplication} from '../../contexto'
import {
  AgregarServiciosACache,
  ObtenerServicioPorId,
} from '../../cache/servicios-cache/Servicios'
import TablaHistoriaClinica from '../../componentes/Tablas/TablaHistoriaClinica'
import imagenUsuarioPorDefecto from '../../imagenes/usuarioImagen.svg'

const HistoriaClinica = () => {
  const {state} = useLocation()
  const contextoAplicacion = useContext(ContextApplication)

  const [informacionUsuario, setInformacionUsuario] = useState({})
  const [listaServicios, setListaServicios] = useState([])

  useEffect(async () => {
    if (state && state.idUsuario) {
      await AgregarServiciosACache()
      ObtenerHistoriaClinica(state.idUsuario)
        .then(async (res) => {
          if (res.data) {
            setInformacionUsuario(res.data)
            setListaServicios(
              await Promise.all(
                res.data.historialServicios.map(async (serv) => ({
                  id: serv.servicioId,
                  servicio:
                    (
                      await ObtenerServicioPorId(serv.servicioId)
                    )?.Nombre?.toUpperCase() ?? '',
                  fechaYHoraCreacion: serv.fechaAtencion,
                  fechaYHoraCierre: serv.fechaCierre,
                  medico: serv?.medico?.toUpperCase() ?? '',
                  urlCertificado: serv.urlCertificado,
                  urlHistoria: serv.urlHistoria,
                }))
              )
            )
          } else {
            setInformacionUsuario({})
            setListaServicios([])
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  return (
    <>
      <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
        <div className="flex w-10/12 justify-center items-start flex-wrap">
          <div className="w-full flex flex-wrap justify-center items-center">
            <span className="  text-center text-21px leading-23px font-bold text-cendiatra my-25px">
              HISTORIA CLÍNICA
            </span>
          </div>
          <div className="bg-white w-full  rounded rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl mt-5">
            <div className="flex justify-end h-6 mt-2 mr-2">
              <NavLink to="/modificarpacientes">
                <img className="h-7" src={closeImg} alt="close" />
              </NavLink>
            </div>
            <EncabezadoHistoriaClinica
              foto={
                informacionUsuario.urlFoto
                  ? informacionUsuario.urlFoto
                  : imagenUsuarioPorDefecto
              }
              noDocumento={informacionUsuario.numeroDocumento}
              apellidos={informacionUsuario.apellidos}
              nombre={informacionUsuario.nombres}
            />
            <div className="w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex flex-wrap mb-16 mt-10">
              <TablaHistoriaClinica data={listaServicios} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HistoriaClinica
