import React, {useEffect} from 'react'
import ElementoTabla from '../ElementoTabla'
import Fila from '../Fila'
import {
  ObtenerTodosLosAliados,
  ObtenerInformacionCiudadPorId,
} from '../../cache/servicios-cache'
import {ROLES_APP} from '../../constantes'

const TablaAliados = ({contexto, numeroPagina, claseBloqueo}) => {
  useEffect(() => {
    ObtenerTodosLosAliados().then((res) => {
      const nuevoArray = []
      res.forEach(async (elemento) => {
        let objeto = {
          Id: elemento.Id,
          Nombre:
            (await ObtenerInformacionCiudadPorId(elemento.CiudadId)).Nombre +
            ' - ' +
            elemento.Nombre,
          Activo: false,
        }
        nuevoArray.push(objeto)
      })

      contexto.setAliadosSeleccionados(nuevoArray)
    })
  }, [])

  useEffect(() => {
    if (numeroPagina === contexto.numeroPagina) {
      contexto.setformularioActualTieneErrores(false)
    }
  }, [contexto.numeroPagina])

  const opcionSeleccionada = (item, seleccionado) => {
    let nuevoEstado = contexto.aliadosSeleccionados.map((elemento) => {
      if (elemento.Id === item.Id) {
        return {
          ...elemento,
          Activo: seleccionado,
        }
      }
      return elemento
    })

    if (
      nuevoEstado.filter((x) => x.Activo).length !==
        contexto.aliadosIniciales.length &&
      Boolean(contexto.portafolioSeleccionadoId)
    ) {
      contexto?.setSeguroDeCambio(true)
      return
    }

    contexto.setAliadosSeleccionados(nuevoEstado)
  }

  return (
    <div
      className={`${
        claseBloqueo
      } w-full rounded flex justify-center items-center flex-wrap`}
    >
      <label className="text-cendiatra text-lg border-b-gray-100 my-5 text-center">
        ALIADOS
        <br />
        CIUDAD - NOMBRE IPS
      </label>
      <hr className="w-11/12" />
      <Fila
        ancho={'w-full'}
        alineacionHorizontal={'justify-center'}
        flexWrap={'flex-wrap'}
      >
        <ElementoTabla
          datos={contexto.aliadosSeleccionados}
          funcion={opcionSeleccionada}
        />
      </Fila>
    </div>
  )
}

export default TablaAliados
