import React, {useRef, useState, useContext} from 'react'
import {CSVLink} from 'react-csv'
import {ContextApplication} from '../../contexto'
import dowloadSvg from '../../imagenes/dowload.svg'

const ExportarCSV = ({encabezados, obtenerDatos, nombreArchivo}) => {
  const contextoApp = useContext(ContextApplication)
  const [datos, setDatos] = useState([{}])
  const csvLinkEl = useRef()

  const manejarObtenerDatos = async (e) => {
    contextoApp.setIsLoading(true)
    e.stopPropagation()
    setDatos(await obtenerDatos())
    csvLinkEl.current.link.click()
    contextoApp.setIsLoading(false)
  }

  const csvReport = {
    data: datos,
    headers: encabezados,
    filename: nombreArchivo,
  }

  return (
    <div>
      <input
        type="image"
        value="Export to CSV (Async)"
        onClick={(e) => manejarObtenerDatos(e)}
        src={dowloadSvg}
        className="w-12"
        alt="dowload"
      ></input>
      <CSVLink {...csvReport} ref={csvLinkEl}></CSVLink>
    </div>
  )
}

export default ExportarCSV
