import React, {useState} from 'react'

const LeyendaSemaforoConCheckbox = ({
  colorFondo,
  texto,
  id,
  nombreTrabajador,
  redireccion,
  seleccionado,
  funcionClickAbrirFormulario,
  funcionCheckbox,
}) => {
  const [abrirDescripcion, setAbrirDescripcion] = useState(false)

  return (
    <div className="w-full flex flex-wrap  justify-center items-center relative bg-white">
      <div
        className="w-full flex flex-wrap justify-between items-center my-2.5px relative"
        onMouseEnter={() => setAbrirDescripcion(true)}
        onMouseLeave={() => setAbrirDescripcion(false)}
      >
        <div className="w-2/12 flex justify-center items-center">
          <input
            type="checkbox"
            className="checkboxEspecial personalizado -mt-2"
            onChange={() => funcionCheckbox(id)}
            checked={seleccionado}
            readOnly
          />
        </div>
        <div className="w-1/12 justify-center items-center">
          <div className={`h-3 w-3 rounded-full ${colorFondo}`}></div>
        </div>
        <div className="w-8/12 flex  justify-center items-center leading-15px z-40">
          <span
            className={`w-full whitespace-nowrap text-left text-13px  truncate ${
              redireccion
                ? 'underline text-cendiatra cursor-pointer'
                : 'text-cendiatra-gris-1'
            }`}
            onClick={redireccion ? funcionClickAbrirFormulario : null}
          >
            {texto}
          </span>
        </div>
      </div>
      <div className="w-1/12 justify-center items-center"></div>
      <div className="w-11/12 flex flex-wrap justify-center items-center leading-15px">
        <span className="w-full justify-start items-center text-left text-13px text-cendiatra-verde-4">
          {nombreTrabajador}
        </span>
      </div>
      <div className="w-full flex justify-start items-center">
        <div
          className={
            abrirDescripcion
              ? 'w-auto flex flex-wrap justify-start items-center text-sm bg-cendiatra text-gray-100 px-1 absolute rounded  shadow-xl top-2 mt-3  z-50'
              : 'hidden'
          }
        >
          <p>
            <span className="text-center text-md z-50">{texto}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LeyendaSemaforoConCheckbox
