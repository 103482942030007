import {dbComun} from '../bases-datos/dbComun'

export const ObtenerDepartamentoPorId = async (id) => {
  return Promise.resolve(dbComun.table('Departamentos').get({Id: id}))
}

export const ObtenerDepartamentoPorCiudad = async (nombreCiudad) => {
  let ciudad = await dbComun.table('Ciudades').get({Nombre: nombreCiudad})
  return Promise.resolve(
    dbComun.table('Departamentos').get({Id: ciudad.DepartamentoId})
  )
}

export const ObtenerDepartamentosPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('Departamentos')
      .filter(({Nombre}) => Nombre.indexOf(nombre.toLocaleUpperCase()) > -1)
      .toArray()
  )
}

export const ObtenerTodosDepartamentos = async () => {
  return Promise.resolve(dbComun.table('Departamentos').toArray())
}
