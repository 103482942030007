import React from 'react'

const HeaderTablaPrecios = (value) => {
  return (
    <div className="w-full">
      <div className="w-full text-center text-md text-cendiatra items-center justify-center font-bold h-10">
        <label>{value}</label>
      </div>
      <hr className="w-full mb-5" />
    </div>
  )
}
export default HeaderTablaPrecios
