import {getRestApi} from './ApiAxios'

//#region PUT
export const AgregarLog = async (log) => {
  const apiReq = await getRestApi()
  return await apiReq.put('servicio-function/AgregarLog', log)
}
export const RegistrarJustificacionCambioSede = async (datosJustificacion) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'auditoria-function/RegistrarJustificacionCambioSede',
    datosJustificacion
  )
}

//#endregion
