import moment from 'moment'
import {useContext, useEffect, useMemo, useState} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import BotonConImagen from '../../componentes/BotonConImagen'
import CuadroInformacionUsuario from '../../componentes/MostrarInformacion/CuadroInformacionUsuario'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import userImg from '../../imagenes/usuarioImg.png'
import IconoResultados from '../../imagenes/iconoResultadosVerde.svg'
import IconoImprimirBloqueado from '../../imagenes/iconoBloqueoCuadradoRojo.svg'
import imagenEditarVerde from '../../imagenes/iconoEditarVerde.svg'
import BotonSimple from '../../componentes/Botones/BotonSimple'
import {ContextApplication} from '../../contexto'
import {ObtenerTipoDocumentoPorId} from '../../cache/servicios-cache/TipoDocumento'
import {ObtenerEpsPorId} from '../../cache/servicios-cache/Eps'
import {ObtenerArlPorId} from '../../cache/servicios-cache/Arl'
import {LISTADO_GENEROS} from '../../constantes/generos'
import IconoImprimirVerde from '../../imagenes/iconoImpresoraCuadradoVerde.svg'
import MotivoSolicitudEdicionComponente from '../../componentes/MostrarInformacion/MotivoSolicitudEdicionComponente'
import TablaConPaginacionYFiltros from '../../componentes/Tablas/TablaConPaginacionYFiltros'

export default function SolicitarModificacionDeExamen() {
  const {state} = useLocation()
  const contextoAplicacion = useContext(ContextApplication)
  const history = useHistory()
  const [informacionPaciente, setInformacionPaciente] = useState({
    datos: [],
    fechaAtencion: '',
    horaAtencion: '',
    examenes: [],
  })
  const [urlFotoPaciente, setUrlFotoPaciente] = useState(userImg)

  useEffect(() => {
    if (state?.usuario) {
      ;(async () => {
        const {
          tipoDocumentoId,
          nombre,
          sexoId,
          epsId,
          arlId,
          apellidos,
          documento,
          edad,
          urlFoto,
        } = state.usuario

        const {fechaAtencion} = state

        const tipoDocumento = await ObtenerTipoDocumentoPorId(
          tipoDocumentoId ?? 0
        )
        const arl = await ObtenerArlPorId(arlId ?? 0)
        const eps = await ObtenerEpsPorId(epsId ?? 0)

        setInformacionPaciente((prv) => ({
          ...prv,
          datos: [
            {
              titulo: 'Tipo de documento',
              contenido: tipoDocumento?.Nombre ?? '',
            },
            {titulo: 'Nombre', contenido: nombre ?? ''},
            {
              titulo: 'Genero',
              contenido: LISTADO_GENEROS[sexoId] ?? '',
            },
            {titulo: 'EPS', contenido: eps?.Nombre ?? ''},
            {titulo: 'Apellidos', contenido: apellidos},
            {titulo: 'No de documento', contenido: documento},
            {titulo: 'Edad', contenido: `${edad ?? 0} años`},
            {titulo: 'ARL', contenido: arl?.Nombre ?? ''},
          ],
        }))

        if (urlFoto) {
          setUrlFotoPaciente(urlFoto)
        }

        if (fechaAtencion) {
          setInformacionPaciente((prv) => ({
            ...prv,
            fechaAtencion: moment(fechaAtencion).format('DD/MM/YYYY'),
            horaAtencion: moment(fechaAtencion).format('LT'),
          }))
        }
      })()
    }
  }, [state?.usuario])

  const imprimirCertificado = (url) => {
    window.open(url, '_blank')
  }

  useEffect(() => {
    if (Array.isArray(state?.serviciosAtencion)) {
      ;(async () => {
        const serviciosPaciente = state.serviciosAtencion.map(async (info) => {
          const {
            servicioId,
            servicioAtencionId,
            fechaCreacion,
            fechaActualizacion,
            medicoId,
            estadoServicioId,
            urlCertificado,
            urlDocumentoExamen,
            nombreMedico,
            edicionHabilitada,
            alias,
            nombreServicio,
          } = info

          return {
            servicioId,
            servicioAtencionId,
            nombreServicio: alias ?? nombreServicio ?? '',
            fechaCreacion: moment(fechaCreacion ?? new Date()).format(
              'DD/MM/YYYY hh:mm A'
            ),
            fechaCierre: moment(fechaActualizacion ?? new Date()).format(
              'DD/MM/YYYY hh:mm A'
            ),
            nombreMedico,
            medicoId,
            estadoServicioId,
            urlCertificado,
            urlDocumentoExamen,
            edicionHabilitada,
          }
        })

        const examenes = await Promise.all(serviciosPaciente)

        setInformacionPaciente((prv) => ({
          ...prv,
          examenes,
        }))
      })()
    }
  }, [state])

  useEffect(() => {
    return () => {
      setInformacionPaciente({
        datos: [],
        fechaAtencion: '',
        horaAtencion: '',
        examenes: [],
      })
    }
  }, [])

  const chequearRowExamen = (index) => {
    setInformacionPaciente((prv) => ({
      ...prv,
      examenes: prv.examenes.map((examen, idx) =>
        index === idx
          ? {
              ...examen,
              chequeado: !examen?.chequeado,
            }
          : {...examen}
      ),
    }))
  }

  const cerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      botones: [],
    })
  }

  const preguntarPorElMotivoQueDeseaEditar = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'Cual es el motivo para editar los exámenes',
      contenido: (
        <MotivoSolicitudEdicionComponente
          atencionId={state?.atencionId}
          serviciosAtencion={informacionPaciente?.examenes
            .filter((x) => x?.chequeado)
            .map((x) => x.servicioAtencionId)}
          sedeId={state?.sedeId}
          funcionCerrar={cerrarModal}
          history={history}
        />
      ),
      botones: [],
    })
  }

  const manejarVisualizarExamen = async (
    informacionFila,
    verPlantilla = false
  ) => {
    history.push('/examen', {
      usuarioId: state?.usuarioId,
      ordenId: 0,
      atencionId: state?.atencionId,
      servicioAtencionId: informacionFila.servicioAtencionId,
      estadoServicioId: informacionFila.estadoServicioId,
      examenes: [],
      servicioId: informacionFila.servicioId,
      deshabilitarTodo: verPlantilla,
      edicionHabilitada: !verPlantilla
        ? informacionFila.edicionHabilitada
        : false,
      habilitarBotonesModificarAtencion: true,
    })
  }

  const columnas = useMemo(
    () => [
      {
        Header: 'Exámen',
        accessor: 'nombreServicio',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-l-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: 'Hora creación',
        accessor: 'fechaCreacion',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: 'Hora cierre',
        accessor: 'fechaCierre',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: 'Médico',
        accessor: 'nombreMedico',
        className: 'w-3/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: 'Ver Plantilla',
        accessor: '',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({row}) => {
          return (
            <BotonConImagen
              imagen={IconoResultados}
              estilosImagen={'w-13 h-12'}
              medio={true}
              funcionAEjecutar={() =>
                manejarVisualizarExamen(row.original, true)
              }
            />
          )
        },
      },
      {
        Header: 'Imprimir Certificado',
        accessor: 'urlCertificado',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({row}) => {
          return (
            <BotonConImagen
              imagen={
                Boolean(row?.original?.urlCertificado)
                  ? IconoImprimirVerde
                  : IconoImprimirBloqueado
              }
              estilosImagen={'w-13 h-12'}
              medio={true}
              funcionAEjecutar={() => {
                if (Boolean(row?.original?.urlCertificado)) {
                  imprimirCertificado(row.original.urlCertificado)
                }
              }}
            />
          )
        },
      },
      {
        Header: 'Imprimir Examen',
        accessor: 'urlDocumentoExamen',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({row}) => {
          return (
            <BotonConImagen
              imagen={
                Boolean(row?.original?.urlDocumentoExamen)
                  ? IconoImprimirVerde
                  : IconoImprimirBloqueado
              }
              estilosImagen={'w-13 h-12'}
              medio={true}
              funcionAEjecutar={() => {
                if (Boolean(row?.original?.urlDocumentoExamen)) {
                  imprimirCertificado(row.original.urlDocumentoExamen)
                }
              }}
            />
          )
        },
      },
      {
        Header: 'Seleccionar para editar',
        accessor: 'edicionHabilitada',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-r-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({row}) => {
          return !row.original.edicionHabilitada ? (
            <input
              type={'checkbox'}
              className={'checkboxEspecial personalizado'}
              checked={Boolean(row?.original?.chequeado)}
              onClick={() => {
                chequearRowExamen(row.index)
              }}
            />
          ) : (
            <BotonConImagen
              estilosImagen={'w-13 h-12'}
              medio={true}
              imagen={imagenEditarVerde}
              funcionAEjecutar={() => manejarVisualizarExamen(row.original)}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <ContenedorPagina tituloPagina={''}>
      <div className="w-full space-y-10">
        <div className="w-full flex flex-wrap justify-center">
          <CuadroInformacionUsuario
            titulo={'DATOS PACIENTE'}
            tituloImagen={'Fecha y hora de atención'}
            descripcionPrimeraLinea={informacionPaciente.fechaAtencion}
            descripcionSegundaLinea={informacionPaciente.horaAtencion}
            estilosContenedor={
              'w-12/12 h-280 flex flex-wrap justify-center items-center  rounded rounded-t-3xl  rounded-b-3xl border-2  border-opacity-50 border-cendiatra-gris-4 shadow-2xl '
            }
            datos={informacionPaciente.datos}
            urlFoto={urlFotoPaciente}
            deshabilitarTiempo={true}
          />
        </div>
        <div className="w-full">
          <TablaConPaginacionYFiltros
            columns={columnas}
            data={informacionPaciente.examenes}
          />
        </div>
        <div className="w-full flex flex-wrap items-center justify-center ">
          <BotonSimple
            texto={'Editar'}
            estilosBoton={`${
              informacionPaciente.examenes.every((examen) => !examen?.chequeado)
                ? 'bg-cendiatra-gris-4 w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6'
                : 'w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 bg-btnBg'
            }`}
            deshabilitado={informacionPaciente.examenes.every(
              (examen) => !examen?.chequeado
            )}
            funcion={preguntarPorElMotivoQueDeseaEditar}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}
