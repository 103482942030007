import {useContext, useMemo} from 'react'
import {nombresDeComponentes} from '../../../constantes'
import {ContextoFormulario} from '../../../contexto'
import {AudiometriaTamiz} from '../../AudiometriaTamiz'

export default function CampoComponente({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const valorComponente = useMemo(() => {
    const item =
      contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
        campo?.tituloCampo
      ]

    if (typeof item === 'string' && item) {
      if (item === '[object Object]') {
        return {}
      }

      return JSON.parse(item)
    }

    if (typeof item === 'object' && item) {
      return item
    }

    return {}
  }, [contextoFormulario.valoresCampos])

  const valoresCamposParaComponente = (datos) => {
    contextoFormulario.setValoresCampos((prv) => ({
      ...prv,
      [accesoPestana]: {
        ...(prv?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(prv?.[accesoPestana]?.[accesoSeccion] ?? {}),
          [campo.tituloCampo]: JSON.stringify(datos),
        },
      },
    }))
  }

  const agregarNodos = (nodos) => {
    contextoFormulario.setNodosPorComponente((prv) => ({
      ...prv,
      [accesoPestana]: {
        ...(prv?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(prv?.[accesoPestana]?.[accesoSeccion] ?? {}),
          [campo.tituloCampo]: nodos,
        },
      },
    }))
  }

  const extensionComponente = useMemo(() => {
    const extensiones = {
      [nombresDeComponentes.AUDIOMETRIATAMIZ]: (
        <AudiometriaTamiz
          escucharFormulario={valoresCamposParaComponente}
          informacion={valorComponente}
          agregarNodos={agregarNodos}
          readOnly={readOnly}
        />
      ),
    }
    return extensiones[campo?.atributos?.componente] ?? null
  }, [valorComponente])

  return (
    <div className="w-full">
      {valorComponente && extensionComponente ? extensionComponente : null}
    </div>
  )
}
