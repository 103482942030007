import React from 'react'

const CuadroTituloYDato = ({estilosContenedor, titulo, informacion}) => {
  return (
    <div className={estilosContenedor}>
      <div className=" border rounded-lg flex border-cendiatra w-full p-2 flex-wrap">
        <span className="px-5 text-white bg-cendiatra-verde-2 text-center text-21px font-semibold w-full">
          {titulo}
        </span>
        <span className="px-5 text-cendiatra-gris-1 text-center font-semibold w-full">
          {informacion}
        </span>
      </div>
    </div>
  )
}

export default CuadroTituloYDato
