import {dbComun} from '../bases-datos/dbComun'

export const ObtenerFondoDePensiones = async () => {
  return Promise.resolve(dbComun.table('FondoPension').toArray())
}

export const ObtenerFondoDePensionPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('FondoPension')
      .filter(
        ({Nombre}) =>
          Nombre.toLocaleUpperCase().indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerFondoDePensionPorId = async (id) => {
  return Promise.resolve(dbComun.table('FondoPension').get({Id: id}))
}
