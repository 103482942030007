import React from 'react'
import {BotonSimple} from '../Botones'

const ReportePowerBi = () => {
  const refrescarReporte = () => {
    window.location.reload()
  }

  return (
    <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
      <div
        className="bg-white w-11/12 p-4 rounded rounded-t-3xl rounded-b-3xl border border-opacity-50 border-cendiatra shadow-2xl"
        style={{minHeight: '800px'}}
      >
        <iframe
          title="ReporteVentasGerencial"
          style={{width: '100%', minHeight: '770px'}}
          src="https://app.powerbi.com/view?r=eyJrIjoiMTQ2M2JmNTEtYWY1NC00MjliLWIwNzQtZDAzNjZhOWRkYzk4IiwidCI6IjAwMzA5Njc5LWE3Y2QtNDcxNS1iNzZmLWQ3NjgxZjg0NzVhYiJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
        <div className="w-full flex justify-end items-center">
          <BotonSimple
            texto={'Actualizar'}
            estilosBoton={`w-28 h-10 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-3 ml-2 bg-btnBg`}
            funcion={refrescarReporte}
          />
        </div>
      </div>
    </div>
  )
}

export default ReportePowerBi
