export const DOCUMENTO = {
  'CEDULA DE CIUDADANÍA': 'C.C',
  'CEDULA DE EXTRANJERÍA': 'C.E',
  'CARNET DIPLOMÁTICO': 'C.D',
  PASAPORTE: 'P.A',
  SALVOCONDUCTO: 'S.C',
  'PERMISO ESPECIAL DE PERMANENCIA': 'P.E',
  'REGISTRO CIVIL': 'R.C',
  'TARJETA DE IDENTIDAD': 'T.I',
  'CERTIFICADO DE NACIDO VIVO': 'C.N',
  'ADULTO SIN IDENTIFICACIÓN': 'A.S',
  'MENOR SIN IDENTIFICACIÓN': 'M.S',
  NIT: 'NIT',
  'INDÍGENAS MAYORES DE EDAD': 'A.I.S',
  'INDÍGENAS MENORES DE EDAD': 'I.M.S',
  'PERMISO POR PROTECCIÓN TEMPORAL': 'P.P.P.T',
}

export const DOCUMENTOS_NUMERO_STRING = {
  CEDULA_CIUDADANIA: '1',
}

export const LONGITUD_CARACTERES = {
  LONGITUD_OCHO_CARACTERES: 8,
}
