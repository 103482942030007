import React from 'react'
import Input from './Input'

const InputFiltroFecha = ({
  titulo,
  funcion,
  fechaMinima,
  fechaMaxima,
  id,
  valorPorDefecto,
}) => {
  return (
    <div className="w-36 flex flex-wrap bg-cendiatra-verde-3 justify-center items-center text-center rounded-xl">
      <span className="w-full text-white mt-3 text-13px">{titulo}</span>
      <Input
        tipo={'date'}
        estilosContenedor={'my-2 w-full flex justify-center items-center'}
        estilosInput={'text-cendiatra-gris-3 rounded-lg h-8 w-11/12'}
        funcion={funcion}
        fechaMaxima={fechaMaxima}
        fechaMinima={fechaMinima}
        id={id}
        valorPorDefecto={valorPorDefecto}
      />
    </div>
  )
}

export default InputFiltroFecha
