import {dbComercial} from '../bases-datos/dbComercial'

export const obtenerUltimaFechaActualizacion = async (tabla) =>
  (await dbComercial.table('FechasActualizacion').get({Tabla: tabla}))
    ?.FechaUltimaActualizacion

export const actualizarUltimaFechaActualizacion = (
  tabla,
  fechaUltimaActualizacion
) =>
  dbComercial.FechasActualizacion.put({
    Tabla: tabla,
    FechaUltimaActualizacion: fechaUltimaActualizacion,
  })
