import React from 'react'
import {LeyendaConInputRadio} from '../../componentes/leyendas'

const AtencionesAMostrar = ({
  estilosContenedor,
  seleccionado,
  funcion,
  tituloLeyendaSuperior,
  tituloLeyendaInferior,
  valorLeyendaSuperior,
  valorLeyendaInferior,
}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <LeyendaConInputRadio
        seleccionado={seleccionado}
        funcion={funcion}
        titulo={tituloLeyendaSuperior}
        valor={valorLeyendaSuperior}
      />
      <LeyendaConInputRadio
        seleccionado={!seleccionado}
        funcion={funcion}
        titulo={tituloLeyendaInferior}
        valor={valorLeyendaInferior}
      />
    </div>
  )
}

export default AtencionesAMostrar
