import moment from 'moment'
import React, {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ExistePortafolioPorNombre} from '../../cache/servicios-cache'
import Fila from '../Fila'
import {Input} from '../inputs'
import {PATRON_INPUT_TEXTO, TEXTO_VISUAL} from '../../constantes'
import {ContextoPortafolio} from '../../contexto'
import {construirEstilosInput, transformarNumero} from '../../utilidades'

const TEXTO_PANTALLA = TEXTO_VISUAL.PORTAFOLIO.CREAR.PESTANA.NOMBRAR_PORTAFOLIO

export const FormularioPortafolio = ({numeroPagina}) => {
  const contextoPortafolio = useContext(ContextoPortafolio)
  const [minimoFechaFinal, setMinimoFechaFinal] = useState('')
  const {
    trigger,
    setValue,
    getValues,
    control,
    formState: {errors, isValid},
    reset,
  } = useForm({
    defaultValues: {
      id: '',
      nombre: '',
      fechaInicial: '',
      fechaFinal: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (contextoPortafolio.numeroPagina > numeroPagina) {
      contextoPortafolio.setFormularioPortafolio(getValues())
    }
  }, [numeroPagina, contextoPortafolio.numeroPagina])

  useEffect(() => {
    if (numeroPagina === contextoPortafolio.numeroPagina) {
      trigger()
      contextoPortafolio.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, errors, contextoPortafolio.numeroPagina])

  useEffect(() => {
    setValue('id', transformarNumero(contextoPortafolio.portafolioId))
  }, [contextoPortafolio.portafolioId])

  useEffect(() => {
    reset(contextoPortafolio.formularioPortafolio)
    trigger()

    setMinimoFechaFinal(
      moment(contextoPortafolio.formularioPortafolio.fechaInicial)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    )

    contextoPortafolio.setPortafolioId(
      contextoPortafolio.formularioPortafolio.id
    )
  }, [contextoPortafolio.actualizar])

  const restringirFechaFinal = (fecha) => {
    if (fecha == '') {
      setMinimoFechaFinal('')
    } else {
      setMinimoFechaFinal(moment(fecha).add(1, 'days').format('YYYY-MM-DD'))
    }

    setValue('fechaFinal', '')
  }

  const validarNombre = async (nombre) => {
    nombre = nombre.trim().toLowerCase()

    if (nombre !== contextoPortafolio.nombrePortafolioValidacion) {
      const portafolioExistente = await ExistePortafolioPorNombre(nombre)

      if (portafolioExistente) {
        return TEXTO_PANTALLA.FORMULARIO.NOMBRE_PORTAFOLIO.ERROR.REPETIDO
      }
    }

    return true
  }

  return (
    <div className="w-full justify-cenet items-center mb-10">
      <Fila
        ancho="w-full"
        alineacionHorizontal="justify-evenly"
        alineacionVertical="items-start"
        flexWrap={true}
        clasesCssAdicionales="mt-12"
      >
        <div className="w-5/12 flex justify-start flex-wrap">
          <Controller
            name="id"
            control={control}
            rules={{required: false}}
            render={({field: {name, onChange, value}}) => (
              <Input
                deshabilitado={true}
                estilosContenedor="w-full"
                estilosInput={construirEstilosInput(errors, name, true)}
                nombre={name}
                placeholder={
                  TEXTO_PANTALLA.FORMULARIO.CODIGO_PORTAFOLIO.PLACEHOLDER
                }
                titulo={TEXTO_PANTALLA.FORMULARIO.CODIGO_PORTAFOLIO.LABEL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-5/12 flex justify-start flex-wrap">
          <Controller
            name="nombre"
            control={control}
            rules={{
              pattern: {
                value: PATRON_INPUT_TEXTO,
                message: TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_TEXTO,
              },
              validate: validarNombre,
              required: true,
            }}
            render={({field: {name, onChange, value}}) => (
              <Input
                deshabilitado={false}
                erroresFormulario={errors}
                estilosContenedor="w-full"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                placeholder={
                  TEXTO_PANTALLA.FORMULARIO.NOMBRE_PORTAFOLIO.PLACEHOLDER
                }
                titulo={TEXTO_PANTALLA.FORMULARIO.NOMBRE_PORTAFOLIO.LABEL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </Fila>
      <Fila
        ancho="w-full"
        alineacionHorizontal="justify-evenly"
        alineacionVertical="items-start"
        flexWrap={true}
        clasesCssAdicionales="mt-12"
      >
        <div className="w-5/12 flex justify-start flex-wrap">
          <Controller
            name="fechaInicial"
            control={control}
            rules={{
              required: true,
              onChange: (e) => restringirFechaFinal(e.target.value),
            }}
            render={({field: {name, onChange, value}}) => (
              <Input
                deshabilitado={false}
                erroresFormulario={errors}
                estilosContenedor="w-full"
                estilosInput={construirEstilosInput(errors, name, false)}
                mostrarErrores={true}
                nombre={name}
                tipo="date"
                titulo={TEXTO_PANTALLA.FORMULARIO.FECHA_INICIAL.LABEL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="w-5/12 flex justify-start flex-wrap">
          <Controller
            name="fechaFinal"
            control={control}
            rules={{
              min: {
                value: minimoFechaFinal,
                message: TEXTO_VISUAL.FORMULARIO.FECHA_FINAL_ES_MENOR_INICIAL,
              },
            }}
            render={({field: {name, onChange, value}}) => (
              <Input
                deshabilitado={minimoFechaFinal === ''}
                erroresFormulario={errors}
                estilosContenedor="w-full"
                estilosInput={construirEstilosInput(errors, name, false)}
                fechaMinima={minimoFechaFinal}
                mostrarErrores={true}
                nombre={name}
                tipo="date"
                titulo={TEXTO_PANTALLA.FORMULARIO.FECHA_FINAL.LABEL}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </Fila>
    </div>
  )
}
