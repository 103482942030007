import React from 'react'
import BotonConImagen from '../BotonConImagen'
import IconoEditarActivado from '../../imagenes/iconoEditarVerde.svg'
import IconoResultadosActivado from '../../imagenes/iconoResultadosVerde.svg'

const BotonesModificarPaciente = ({funcionEditar, funcionHistoriaClinica}) => {
  return (
    <div
      className="w-full flex justify-center items-center text-cendiatra"
      style={{lineHeight: '15px'}}
    >
      <div className="w-1/2">
        <span className="w-full flex justify-evenly items-center">Editar</span>
        <BotonConImagen
          imagen={IconoEditarActivado}
          textoAlternativo={'Editar'}
          funcionAEjecutar={funcionEditar}
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
        />
      </div>
      <div className="w-1/2">
        <span className="w-full flex justify-evenly items-center">
          Historia clínica
        </span>
        <BotonConImagen
          imagen={IconoResultadosActivado}
          textoAlternativo={'Borrar'}
          funcionAEjecutar={funcionHistoriaClinica}
          estilosImagen={'w-10 h-10 content-start justify-start'}
          estilosContenedor={' h-10 content-start flex-col pb-5'}
        />
      </div>
    </div>
  )
}

export default BotonesModificarPaciente
