import React, {useEffect, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {AreaDeTexto} from '../../../inputs'
import {ContextoMedicoOcupacional} from '../../../../contexto'
import {regexBasicoV1} from '../../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../../constantes'

const EstadoDeSaludActual = ({numeroPagina}) => {
  const {
    getValues,
    reset,
    control,
    trigger,
    watch,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [])

  const contextoMedicoOcupacional = useContext(ContextoMedicoOcupacional)

  useEffect(() => {
    reset(contextoMedicoOcupacional.estadoDeSaludActual)
    trigger()
  }, [contextoMedicoOcupacional.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoMedicoOcupacional.numeroPagina) {
      trigger()
      contextoMedicoOcupacional.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, contextoMedicoOcupacional.numeroPagina])

  useEffect(() => {
    const subscription = watch(() => {
      contextoMedicoOcupacional.setEstadoDeSaludActual(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div className="w-full flex items-center justify-center flex-wrap">
      <>
        <Controller
          name="sintomatologia"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <AreaDeTexto
              estilosContenedor={'w-11/12'}
              estilosAreaDeTexto={
                Object.keys(errors).find(
                  (element) => element === 'sintomatologia'
                )
                  ? 'h-20 mt-3 rounded w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
                  : 'h-20 mt-3 rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
              }
              titulo={'SINTOMATOLOGÍA*'}
              valor={value}
              cambioValor={onChange}
            />
          )}
        />
        <span className="text-cendiatra-semaforo-rojo w-full text-13px my-2 ml-16">
          {errors.sintomatologia?.type === 'pattern' &&
            TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
        </span>
      </>
    </div>
  )
}

export default EstadoDeSaludActual
