import React, {useState, useContext, useEffect} from 'react'
import {NavLink, useLocation, useHistory} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import Boton from '../../componentes/Botones/Boton'
import Input from '../../componentes/inputs/Input'
import CampoFiltrarEspañol from '../../componentes/CampoFiltrarEspañol'
import BotonConImagen from '../../componentes/BotonConImagen'
import addButtonImg from '../../imagenes/addButton.png'
import removeButtonImg from '../../imagenes/removeButton.png'
import TablaServiciosRol from '../../componentes/Administrador/TablaServiciosRol'
import {ContextApplication} from '../../contexto'
import {
  ActualizarServiciosRol,
  ObtenerServiciosRol,
} from '../../microservicios/Rol'
import {
  AgregarServiciosACache,
  ObtenerServicioPorId,
  ObtenerServiciosPorCodigo,
  ObtenerServiciosPorNombre,
} from '../../cache/servicios-cache/Servicios'
import CeldaConTooltip from '../../componentes/CeldaConTooltip'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {RUTAS_APP, TEXTO_VISUAL} from '../../constantes'

const EditarRol = () => {
  const {state} = useLocation()
  const history = useHistory()

  const contextoAplicacion = useContext(ContextApplication)

  const [busquedaServicio, setBusquedaServicio] = useState('')
  const [listaServicios, setListaServicios] = useState([])
  const [serviciosFiltrados, setServiciosFiltrados] = useState([])
  const [servicioSeleccionado, setServicioSeleccionado] = useState()

  useEffect(async () => {
    await AgregarServiciosACache()
    ObtenerServiciosRol(state.idRol)
      .then(async (res) => {
        if (res.data) {
          setListaServicios(
            await Promise.all(
              res.data.serviciosIds.map(async (servicioId) => ({
                id: servicioId,
                codigo:
                  (await ObtenerServicioPorId(servicioId))?.CodigoCompleto ??
                  '',
                nombre:
                  (
                    await ObtenerServicioPorId(servicioId)
                  )?.Nombre?.toUpperCase() ?? '',
              }))
            )
          )
        } else {
          setListaServicios([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const eliminarServicio = async (rolId) => {
    let serviciosFiltrados = listaServicios.filter((x) => x.id != rolId)
    setListaServicios(serviciosFiltrados)
  }

  const filtrarPorServicio = async (e) => {
    setBusquedaServicio(e.target.value)
    if (e.target.value.length >= 3) {
      ObtenerServiciosPorNombre(e.target.value).then((res) => {
        if (res && res.length > 0) {
          setServiciosFiltrados(res)
        } else {
          ObtenerServiciosPorCodigo(e.target.value).then((resCod) => {
            if (resCod && resCod.length > 0) {
              setServiciosFiltrados(resCod)
            }
          })
        }
      })
    } else {
      setServiciosFiltrados([])
    }
  }

  const seleccionarServicio = (e) => {
    setServicioSeleccionado(e)
    setBusquedaServicio(e.filtro)
  }

  const agregarServicio = async () => {
    if (!listaServicios.map((x) => x.id).includes(servicioSeleccionado.id)) {
      let nuevoServicio = await ObtenerServicioPorId(servicioSeleccionado.id)
      setListaServicios([
        ...listaServicios,
        {
          id: nuevoServicio.Id,
          codigo: nuevoServicio.CodigoCompleto,
          nombre: nuevoServicio.Nombre.toUpperCase(),
        },
      ])
      setBusquedaServicio('')
      setServiciosFiltrados([])
    }
  }

  const guardarRol = () => {
    let payloadGuardar = {
      rolId: state.idRol,
      serviciosIds: listaServicios.map((servicio) => servicio.id),
    }
    ActualizarServiciosRol(payloadGuardar)
      .then(async (res) => {
        if (res.status == 200) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'SE HA REALIZADO LA ASIGNACIÓN EXITOSAMENTE',
            contenido: '',
            botones: [
              {
                nombre: 'Regresar',
                click: manejarCerrarModalConfirmacion,
              },
              {
                nombre: 'Finalizar',
                click: manejarFinalizar,
              },
            ],
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/gestionroles')
  }

  const manejarFinalizar = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  const columnasServicios = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Código del servicio'} />,
      accessor: 'codigo',
      className:
        'w-5/12 flex text-center justify-center items-center content-center border-gris border-b h-10 text-cendiatra-gris-1',
      headerStyle: 'w-5/12 bg-cendiatra-verde-7 text-white rounded-l-md h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nombre del servicio'} />,
      accessor: 'nombre',
      className:
        'w-5/12 flex text-center justify-center items-center content-center border-gris border-b h-10 text-cendiatra-gris-1',
      headerStyle: 'w-5/12 bg-cendiatra-verde-7 text-white rounded-r-md h-14',
      Cell: CeldaConTooltip,
    },
    {
      Header: '',
      accessor: 'eliminar',
      className:
        'w-1/12 flex text-center justify-center items-center content-center h-10',
      headerStyle: 'hidden',
      Cell: (row) => (
        <BotonConImagen
          estilosContenedor={`${state.editable ? '' : 'hidden'}`}
          ancho={'w-full'}
          medio={true}
          imagen={removeButtonImg}
          textoAlternativo={'Boton Eliminar'}
          funcionAEjecutar={() => {
            eliminarServicio(row.row.original.id)
          }}
        />
      ),
    },
  ])

  return (
    <>
      <ContenedorPagina
        tituloPagina={
          TEXTO_VISUAL.TITILO_ADMINISTRADOR_GATO.ASIGNAR_SERVICIOS_A_ROL
        }
        navegacion={RUTAS_APP.ADMINISTRADOR_GATO.GESTION_ROLES}
      >
        <div className="w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex mb-16 mt-10">
          <Input
            estilosContenedor={'flex-col w-4/12 md:w-4/12'}
            estilosInput={
              'w-full border border-cendiatra rounded-md text-center bg-cendiatra-gris-placeholder text-white'
            }
            align={'items-start'}
            tipo={'text'}
            placeholder={'Rol'}
            titulo={'Rol'}
            deshabilitado={true}
            valor={state.nombreRol}
          />
        </div>
        <div
          className={`w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex mb-16 mt-10`}
        >
          <CampoFiltrarEspañol
            titulo={'Seleccionar los servicios'}
            estilosPersonalizados={`w-4/12 md:w-4/12 ${
              state.editable ? 'ml-8%' : ''
            }`}
            estilosInput={`${
              state.editable ? '' : 'bg-cendiatra-gris-placeholder'
            } border-cendiatra`}
            placeholder={'DESCRIPCIÓN'}
            tipo={'text'}
            valorDelCampoFiltro={busquedaServicio}
            desactivarOtroFiltro={`${state.editable ? '' : true}`}
            informacionFiltrada={serviciosFiltrados}
            handleChange={filtrarPorServicio}
            handleOptionChange={seleccionarServicio}
            id={1}
          />
          <BotonConImagen
            ancho={'w-1/12'}
            estilosContenedor={`${state.editable ? '' : 'hidden'}`}
            imagen={addButtonImg}
            textoAlternativo={'Boton Agregar'}
            funcionAEjecutar={() => {
              agregarServicio()
            }}
          />
        </div>
        <div className="w-full flex flex-wrap pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex mb-16 mt-10 ml-5%">
          <label className="w-8/12 block text-13px leading-15px text-cendiatra-verde-2 my-5px">
            Agregar mínimo un servicio*
          </label>
          <TablaServiciosRol
            columns={columnasServicios}
            data={listaServicios}
          />
        </div>
        <div
          className={`${
            state.editable ? '' : 'hidden'
          } w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex mb-16 mt-10`}
        >
          <Boton
            titulo="Guardar"
            habilitado={listaServicios.length != 0}
            funcionCLick={guardarRol}
            colorPorDefecto={'bg-grayColor'}
            colorActivo={'bg-btnBg'}
            tipo={'button'}
          />
        </div>
      </ContenedorPagina>
    </>
  )
}

export default EditarRol
