import React from 'react'
import {ErroresDeCampo} from '../Formularios/ErroresDeCampo'

const Input = ({
  onChange,
  funcion,
  onBlur,
  estilosContenedor,
  estilosInput,
  align,
  tipo,
  placeholder,
  titulo,
  deshabilitado,
  fechaMinima,
  fechaMaxima,
  valor,
  onFocus,
  tabIndex,
  id,
  valorPorDefecto,
  mostrarErrores = false,
  erroresFormulario,
  nombre,
  longitudMaxima = '',
  onPaste = () => {},
  readOnly = false,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-start ${
        align ? align : 'items-center'
      } flex-wrap`}
    >
      {titulo ? (
        <label className="block text-13px w-full leading-15px text-cendiatra-verde-2 my-5px">
          {titulo}
        </label>
      ) : null}
      <input
        className={` ${estilosInput} ${
          estilosInput?.includes('bg')
            ? 'placeholder-white'
            : 'placeholder-cendiatra-gris-placeholder'
        } `}
        type={tipo}
        placeholder={placeholder}
        disabled={deshabilitado}
        onChange={funcion ? (e) => funcion(e) : onChange}
        value={valor}
        min={fechaMinima}
        max={fechaMaxima}
        onBlur={onBlur}
        onFocus={onFocus}
        tabIndex={tabIndex}
        id={id}
        defaultValue={valorPorDefecto}
        name={nombre}
        maxLength={longitudMaxima}
        onPaste={onPaste}
        readOnly={readOnly}
      />
      {mostrarErrores && (
        <ErroresDeCampo erroresFormulario={erroresFormulario} nombre={nombre} />
      )}
    </div>
  )
}

export default Input
