import React, {useContext, useEffect, useRef} from 'react'
import DatosPaciente from './DatosPaciente'
import ServiciosPaquetes from './ServiciosPaquetes'
import Factura from './Factura'
import {ContextoRegistro} from '../../../../contexto'
import {ObtenerTipoDocumentoPorId} from '../../../../cache/servicios-cache'
import MediosPago from './MediosPago'
import {CeldaConTooltipPersonalizable} from '../../../Celdas'
import {DOCUMENTO} from '../../../../constantes'

const ConfirmarDatos = () => {
  const contextoRegistro = useContext(ContextoRegistro)

  const {tipoFacturacion} = contextoRegistro.empresaSeleccionada

  const {fechaComprobante} = contextoRegistro.informacionFacturacion
  const {nombre, apellidos, tipoDocumento, numeroDocumento} =
    contextoRegistro.informacionPersonal

  const examenesSeleccionados = contextoRegistro.examenesSeleccionados

  const medioPagoSeleccionado = contextoRegistro.mediosDePagoSeleccionados

  const nombreEmpresaPadre = `${contextoRegistro.empresaSeleccionada?.nombre}`

  const nombreEmpresaHija = `${contextoRegistro.empresaHijaSeleccionada?.nombre}`

  const tipoDocumentoTransformado = useRef('')

  const obtenerTipoDocumento = async () => {
    try {
      const tipoDocumentoId = parseInt(`${tipoDocumento ?? ''}`)
      const data = await ObtenerTipoDocumentoPorId(tipoDocumentoId)
      tipoDocumentoTransformado.current = data.Nombre
    } catch (error) {
      console.error('Error al obtener el tipo de documento:', error)
    }
  }

  useEffect(() => {
    if (tipoDocumento) {
      obtenerTipoDocumento()
    }
  }, [tipoDocumento])

  const datosUsuario = [
    {
      titulo: 'Tipo De facturación',
      contenido: tipoFacturacion,
    },
    {
      titulo: 'Fecha',
      contenido: fechaComprobante,
    },
    {
      titulo: 'Empresa (NIT)*',
      contenido: nombreEmpresaPadre,
      mostrarToolTip: true,
    },
    {
      titulo: 'Empresa hija (NIT)',
      contenido: nombreEmpresaHija,
      mostrarToolTip: true,
    },
    {
      titulo: 'Nombre*',
      contenido: nombre,
    },
    {
      titulo: 'Apellidos*',
      contenido: apellidos,
    },
    {
      titulo: 'Tipo de documento*',
      contenido: DOCUMENTO[tipoDocumentoTransformado.current],
    },
    {
      titulo: 'No. Documento*',
      contenido: numeroDocumento,
    },
  ]

  return (
    <>
      <DatosPaciente datos={datosUsuario} />
      <ServiciosPaquetes examenes={examenesSeleccionados} />
      <Factura />
      <MediosPago
        medioPago={medioPagoSeleccionado}
        tipoFacturacion={tipoFacturacion}
      />
    </>
  )
}

export default ConfirmarDatos
