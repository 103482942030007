import React, {useEffect, useContext} from 'react'
import {ContextApplication} from '../../contexto'
import FilaPrecios from './filas/FilaPrecios'
import {
  ValidarOrdenesPorTarifarioIdYPaqueteId,
  ValidarAtencionesPorTarifarioIdYPaqueteId,
} from '../../microservicios'

export default function TablaPaquetes({contexto, esPortafolio = false}) {
  const contextoApp = useContext(ContextApplication)

  useEffect(() => {
    contexto.listaPaquetes.forEach((paquete, index, listaPaquetes) => {
      listaPaquetes[index].precios_sedes = contexto.sedesSeleccionadas
        .filter((x) => x.Activo)
        .map((sede) => ({
          id: 'paquete:' + sede.Id,
          nombre: sede.Nombre,
          grupo: sede.Grupo,
          precio:
            paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id) ==
            undefined
              ? 0
              : paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id)
                  .precio,
          idPrecio:
            paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id) ==
            undefined
              ? 0
              : paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id)
                  .idPrecio,
          tipo: 'paquete',
          idGeneral: paquete?.paquete_id,
          sedeId: sede.Id,
          nuevoPrecio:
            paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id) ==
            undefined
              ? 0
              : paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id)
                  .nuevoPrecio,
        }))
    })
  }, [contexto.sedesSeleccionadas])

  useEffect(() => {
    contexto.listaPaquetes.forEach((paquete, index, listaPaquetes) => {
      listaPaquetes[index].precios_aliados = contexto.aliadosSeleccionados
        .filter((x) => x.Activo)
        .map((aliado) => ({
          id: 'aliado:' + aliado.Id,
          nombre: aliado.Nombre,
          grupo: 'ALIADOS',
          precio:
            paquete.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : paquete.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).precio,
          idPrecio:
            paquete.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : paquete.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).idPrecio,
          tipo: 'paquete',
          idGeneral: paquete?.paquete_id,
          aliadoId: aliado.Id,
          nuevoPrecio:
            paquete.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : paquete.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).nuevoPrecio,
        }))
    })
  }, [contexto.aliadosSeleccionados])

  const manejarCerrarModalEliminar = () => {
    contextoApp.setModal({abierto: false, botones: []})
  }

  const eliminarPaquete = async (idPaquete) => {
    if (Boolean(contexto.portafolioSeleccionadoId)) {
      contexto.setSeguroDeCambio(true)
      return
    }

    let eliminarLocalmente = true

    const id = contexto.idTarifario || contexto.portafolioId

    const [{data: ordenConRegistros}, {data: atencionConRegistros}] =
      await Promise.all([
        ValidarOrdenesPorTarifarioIdYPaqueteId(id, idPaquete),
        ValidarAtencionesPorTarifarioIdYPaqueteId(id, idPaquete),
      ])

    if (ordenConRegistros || atencionConRegistros) {
      eliminarLocalmente = false

      contextoApp.setModal({
        abierto: true,
        titulo: '',
        contenido: (
          <p className="text-cendiatra text-3xl font-bold">
            ESTE SERVICIO Y/O PAQUETE YA TIENE <br /> REGISTROS NO SE PUEDE
            QUITAR
          </p>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModalEliminar,
          },
        ],
      })
    }

    if (eliminarLocalmente) {
      var filtro = contexto.listaPaquetes.filter((paquete) => {
        return paquete.paquete_id !== idPaquete
      })
      contexto.setListaPaquetes(filtro)
    }
  }

  const validarElementoNuevo = (id) => {
    return Boolean(
      contexto.paquetesIniciales.find((elemento) => elemento === id)
    )
  }

  const validarColumnaAEditar = (paqueteId, datos) => {
    const sedeOAliadoNuevo = validacionGeneral(datos)
    if (sedeOAliadoNuevo) {
      const nombrePropiedad =
        contexto.modoEdicion && validarElementoNuevo(paqueteId)
          ? 'nuevoPrecio'
          : 'precio'
      return nombrePropiedad
    }
    return 'precio'
  }

  const validacionGeneral = (data) => {
    const resultado = Boolean(
      contexto?.[
        data?.grupo === 'ALIADOS' ? 'aliadosIniciales' : 'sedesIniciales'
      ].find((x) =>
        data?.grupo === 'ALIADOS'
          ? x === data?.aliadoId ?? 0
          : x === data?.sedeId ?? 0
      )
    )
    return resultado
  }

  const actualizarPrecios = (idSede, value, idFila) => {
    contexto.setListaPaquetes(
      contexto.listaPaquetes.map((paquete) => {
        if (paquete.paquete_id == idFila) {
          return {
            ...paquete,
            precios_sedes: paquete.precios_sedes.map((elementoPrecio) => {
              if (elementoPrecio.id == idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(paquete.paquete_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
            precios_aliados: paquete.precios_aliados.map((elementoPrecio) => {
              if (elementoPrecio.id == idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(paquete.paquete_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
          }
        }
        return paquete
      })
    )
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      {contexto.listaPaquetes.map((paquete, posicion) => (
        <FilaPrecios
          contexto={contexto}
          idFila={paquete.paquete_id}
          nombre={paquete.nombre}
          precios={paquete.precios_sedes.concat(paquete.precios_aliados)}
          actualizarPrecios={actualizarPrecios}
          eliminarFila={eliminarPaquete}
          posicion={contexto.listaServicios.length + posicion}
          key={posicion}
          tipoElemento={'Paquete'}
          esPortafolio={esPortafolio}
        />
      ))}
    </div>
  )
}
