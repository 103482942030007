import {getRestApi} from './ApiAxios'

export const obtenerPortafolios = async (fechaUltimaActualizacion) => {
  const apiReq = await getRestApi()
  return await apiReq.get('tarifario-function/ObtenerPortafolios', {
    params: {fecha: fechaUltimaActualizacion},
  })
}

export const obtenerPortafolioPorId = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get('tarifario-function/ObtenerPortafolioPorId', {
    params: {Id: id},
  })
}

export const crearPortafolio = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.put('tarifario-function/CrearPortafolio', datos)
}

export const modificarPortafolio = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('tarifario-function/ModificarPortafolio', datos)
}
