import {useEffect, useMemo, useState} from 'react'

function EstadisticasUsuarios({activos = 0, creados = 0}) {
  const [estadisticas, setEstadisticas] = useState({
    creados: 0,
    activos: 0,
  })

  useEffect(() => {
    setEstadisticas({creados, activos})
  }, [activos, creados])

  const listado = useMemo(
    () => [
      {propiedad: 'creados', nombre: 'Usuarios creados'},
      {propiedad: 'activos', nombre: 'Usuarios activos'},
    ],
    []
  )

  return (
    <div className="w-full flex flex-wrap space-x-4 justify-end">
      {listado.map((item, idx) => {
        return (
          <div className="w-20% items-end" key={idx}>
            <h3 className="uppercase text-center text-cendiatra font-bold">
              {item.nombre}
            </h3>
            <p className="text-center border border-cendiatra rounded-md text-cendiatra py-4 font-bold font-size-2">
              {estadisticas[item.propiedad]}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default EstadisticasUsuarios
