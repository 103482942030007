import {useContext, useEffect, useState} from 'react'
import {Input} from '../../inputs'

import {Controller, useForm} from 'react-hook-form'
import AreaDeTexto from '../../inputs/AreaDeTexto'
import BotonSimple from '../../Botones/BotonSimple'
import {ContextApplication, ContextoModales} from '../../../contexto'
import {
  FachadaGuardarFormatoInterconsulta,
  GenerarFormatoInterconsulta,
} from '../../../microservicios/Fachada'
import {ObtenerInformacionInterconsultaPorServicioAtencionId} from '../../../microservicios/Examen'
import {TEXTO_VISUAL} from '../../../constantes'

export default function FormatoInterconsulta() {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoModales = useContext(ContextoModales)

  const {
    control,
    watch,
    getValues,
    reset,
    trigger,
    formState: {isValid},
  } = useForm({
    mode: 'onChange',
  })

  const funcionCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  useEffect(() => {
    trigger()
    if (!!contextoModales?.parametrosFormulario?.servicioAtencionId) {
      ObtenerInformacionInterconsultaPorServicioAtencionId(
        contextoModales.parametrosFormulario.servicioAtencionId
      )
        .then((res) => {
          if (res.status === 204) {
            reset(contextoModales.interconsulta)
          } else {
            reset(res.data)
            contextoModales.setInterConsulta({
              ...contextoModales.interconsulta,
              guardado: true,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return () => contextoModales.setInterConsulta({})
  }, [])

  useEffect(() => {
    const subscripcion = watch(() => {
      let valoresFormulario = getValues()
      contextoModales.setInterConsulta({
        ...contextoModales.interconsulta,
        valoresFormulario,
      })
    })
    return () => subscripcion.unsubscribe()
  }, [watch])

  const funcionGuardarFormatoInterConsulta = () => {
    const datosFormulario = getValues()

    let payload = {
      servicioAtencionId:
        contextoModales.parametrosFormulario.servicioAtencionId,
      Servicio: datosFormulario.nombreServicio,
      motivoRemision: datosFormulario.motivoRemision,
      servicioId: contextoModales.parametrosFormulario.servicioId,
    }
    FachadaGuardarFormatoInterconsulta(payload)
      .then((res) => {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'Formato de interconsulta guardado',
          contenido: '',
          botones: [
            {
              nombre: TEXTO_VISUAL.BOTONES.REGRESAR_A_EXAMEN,
              click: funcionCerrarModal,
            },
            {
              nombre: 'Imprimir',
              click: imrpimirFormatoInterConsulta,
            },
          ],
        })
        contextoModales.setInterConsulta({
          ...contextoModales.interconsulta,
          guardado: true,
        })
      })

      .catch((error) => {})
  }

  const imrpimirFormatoInterConsulta = () => {
    GenerarFormatoInterconsulta(
      contextoModales.parametrosFormulario.servicioAtencionId
    )
      .then((res) => {
        if (res?.data) {
          window.open(res?.data?.replace(/['"]+/g, ''), '_blank').focus()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="w-full">
      <div className="w-full pb-5">
        <div
          className={`w-full pb-10 ${
            contextoModales.interconsulta.guardado ? 'pointer-events-none' : ''
          }`}
        >
          <div className="w-1/3">
            <Controller
              name="nombreServicio"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <Input
                    estilosContenedor={'w-full text-left normal-case'}
                    estilosInput={
                      false
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'DESCRIPCIÓN'}
                    titulo={'Servicio Solicitado'}
                    valor={value}
                    onChange={onChange}
                  />
                )
              }}
            />
          </div>
          <div className="w-full">
            <Controller
              name="motivoRemision"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {value, onChange}}) => {
                return (
                  <AreaDeTexto
                    titulo={'Motivo de remisión'}
                    estilosContenedor={'w-full normal-case'}
                    estilosAreaDeTexto={
                      'rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm'
                    }
                    placeholder={'Descripción'}
                    rows={6}
                    cambioValor={onChange}
                    valor={value}
                  />
                )
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <BotonSimple
            texto={'Guardar'}
            estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
              isValid && !contextoModales.interconsulta.guardado
                ? ' bg-btnBg '
                : ' bg-cendiatra-gris-placeholder'
            }`}
            funcion={funcionGuardarFormatoInterConsulta}
            deshabilitado={!isValid || contextoModales.interconsulta.guardado}
          />
          <BotonSimple
            texto={'Regresar'}
            estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 bg-btnBg`}
            funcion={funcionCerrarModal}
          />
          <BotonSimple
            texto={'Imprimir'}
            estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px m-2 ${
              contextoModales.interconsulta.guardado
                ? ' bg-btnBg '
                : ' bg-cendiatra-gris-placeholder'
            }`}
            funcion={imrpimirFormatoInterConsulta}
            deshabilitado={!contextoModales.interconsulta.guardado}
          />
        </div>
      </div>
    </div>
  )
}
