import React from 'react'

const ElementoTabla = ({datos, funcion}) => {
  return (
    <div className="w-full grid grid-cols-2 justify-center items-center flex-wrap">
      {datos?.map((item) => (
        <div
          key={item.Id}
          className="flex justify-center items-center my-5 "
        >
          <div className="w-8/12 text-center text-gray-500 text-lg">
            {item.Nombre}
          </div>
          <div className="w-4/12 flex justify-center items-center ">
            <input
              type="checkbox"
              className="checkboxEspecial personalizado -mt-2"
              onChange={(e) => funcion(item, e.target.checked)}
              checked={item.Activo}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default ElementoTabla
