import React, {useContext, useEffect, useState, useRef} from 'react'
import {ContextoRegistro} from '../../../contexto'
import {SubirArchivo} from '../../../microservicios'
import {AMBIENTES_SIN_FIRMA} from '../../../constantes'
import {CamaraWeb, LectorDeFirmas} from '../../Perifericos'
import {BotonPerifericos} from '../../Botones'

import Borrador from '../../../imagenes/borrador.png'
import Huella from '../../../imagenes/huella.png'
import TextoBorrar from '../../../imagenes/textoBorrar.png'
import Camara from '../../../imagenes/camara.png'
import IconoAlerta from '../../../imagenes/alerta.svg'
import moment from 'moment'
import {obtenerNombreAmbiente} from '../../../config'

const FirmaFotoHuella = ({
  numeroPagina,
  modificarPaciente = false,
  deshabilitarEdicion,
}) => {
  const [camaraActiva, setCamaraActiva] = useState('')
  const [nombreAmbiente, setNombreAmbiente] = useState('')
  const contextoRegistro = useContext(ContextoRegistro)

  useEffect(() => {
    ;(async () => {
      const ambiente = await obtenerNombreAmbiente()

      if (!!ambiente) {
        setNombreAmbiente(ambiente)
      } else {
        console.error('No se encontró nombre de ambiente')
      }
    })()
  }, [])

  useEffect(() => {
    if (numeroPagina == contextoRegistro.numeroPagina) {
      contextoRegistro.setformularioActualTieneErrores(
        !AMBIENTES_SIN_FIRMA.includes(nombreAmbiente)
          ? contextoRegistro.foto === '' || contextoRegistro.firma === ''
          : contextoRegistro.foto === ''
      )
    }
    contextoRegistro.setErroresModificarFormulario({
      ...contextoRegistro.erroresModificarFormulario,
      firmaFotoHuella: contextoRegistro.foto == '',
    })
  }, [
    contextoRegistro.foto,
    contextoRegistro.numeroPagina,
    contextoRegistro.firma,
  ])

  const borrarImagen = () => {
    contextoRegistro.setFoto('')
  }
  useEffect(() => {
    const validarCamara = async () => {
      await navigator.mediaDevices
        .getUserMedia({video: true})
        .then(() => {
          setCamaraActiva(true)
        })
        .catch((error) => {
          console.log(error)
          setCamaraActiva(false)
        })
    }
    validarCamara()
  }, [camaraActiva])

  const cargarImagen = (e) => {
    const archivoLogo = e.target.files[0]
    const tamanoArchivo = archivoLogo.size / 1024 / 1024 // Conversion en  MiB
    if (tamanoArchivo > 2) {
      return
    }
    var reader = new FileReader()
    reader.readAsDataURL(archivoLogo)
    reader.onload = function (e) {
      var imagen = new Image()
      imagen.src = e.target.result
      imagen.onload = function () {
        let formData = new FormData()
        const fecha = moment().unix()
        formData.append('Archivo', archivoLogo, fecha + archivoLogo.name)
        SubirArchivo(fecha + archivoLogo.name, formData).then((res) => {
          contextoRegistro.setFoto(res.data)
        })
      }
    }
  }

  return (
    <div
      className={`w-full flex justify-center items-center bg-white border border-cendiatra rounded-md p-10 mb-50px ${
        deshabilitarEdicion ? 'pointer-events-none' : ''
      }`}
    >
      <div className="w-4/12 flex flex-wrap justify-center items-center">
        {!camaraActiva ? (
          <>
            <div className="w-60 h-56 flex flex-wrap justify-center items-center border-2 border-cendiatra-semaforo-rojo rounded-custom mb-2">
              <span className="w-3 h-3 relative bottom-20 left-40  rounded-full bg-cendiatra-semaforo-verde"></span>

              <img className="w-36 h-28" src={Camara} />
            </div>
            <div className="w-full flex flex-wrap justify-center items-center">
              <div className="w-11/12 flex justify-center items-center bg-cendiatra-gris-5 py-2 mb-1 rounded-lg">
                <div className="w-2/12 flex justify-center items-center">
                  <img src={IconoAlerta} alt="alerta" />
                </div>
                <div className="w-10/12">
                  <span className="text-cendiatra-semaforo-rojo">
                    La cámara no esta conectada, valide la conexión
                  </span>
                </div>
              </div>
              <BotonPerifericos
                textoBoton={'CAPTURAR FOTO'}
                funcionRecargar={() => setCamaraActiva(!camaraActiva)}
                recargar={true}
                textoRecargar={'RECARGAR CAMARA'}
              />
            </div>
          </>
        ) : (
          <CamaraWeb
            contexto={contextoRegistro}
            funcion={() => borrarImagen()}
            nombreArchivo={
              modificarPaciente
                ? `fotoUsuario_${contextoRegistro?.modificarPacienteInformacionPersonal?.numeroDocumento}`
                : `fotoUsuario_${contextoRegistro?.informacionPersonal.numeroDocumento}`
            }
            modificar={modificarPaciente}
            funcionCargarImagen={cargarImagen}
          />
        )}
      </div>
      <div className="w-4/12 flex flex-wrap justify-center items-center">
        {modificarPaciente ? (
          <>
            <LectorDeFirmas modificar={modificarPaciente} />
          </>
        ) : (
          <>
            <LectorDeFirmas />
          </>
        )}
      </div>
      <div className="w-4/12 flex flex-wrap justify-center items-center">
        <div className="w-60 h-56 flex flex-wrap justify-center items-center  rounded-custom mb-20">
          <span className="w-3 h-3 relative bottom-24 left-48  rounded-full bg-cendiatra-gris-placeholder"></span>

          <img className="w-40 h-56" src={Huella} />
        </div>
        <div className="w-full flex justify-center items-center ml-10">
          <button className="bg-cendiatra-gris-1 text-white px-1.5 py-3 rounded-lg text-17px">
            CAPTURAR HUELLA
          </button>
          <div className="ml-2">
            <img className="h-8" src={Borrador} alt="borrador" />
            <img className="h-3" src={TextoBorrar} alt="borrador" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirmaFotoHuella
