import {dbComun} from '../bases-datos/dbComun'

export const ObtenerTodasLasEps = async () => {
  return Promise.resolve(dbComun.table('Eps').toArray())
}

export const ObtenerEpsPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('Eps')
      .filter(
        ({Nombre}) =>
          Nombre.toLocaleUpperCase().indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerEpsPorId = async (id) => {
  return Promise.resolve(dbComun.table('Eps').get({Id: id}))
}
