import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {ContenedorDesplegable} from '../../Contenedores'
import {InformacionDireccion, FirmaFotoHuella} from '../formularioDeRegistro'
import ModificarInformacionPersonalPaciente from './ModificarInformacionPersonalPaciente'
import {BotonesFormularioPasos} from '../../Botones'
import {ContextoRegistro, ContextApplication} from '../../../contexto'
import {FachadaActualizarUsuario} from '../../../microservicios'
import {
  ObtenerDepartamentosPorNombre,
  ObtenerLocalidadPorNombre,
} from '../../../cache/servicios-cache'
import {AMBIENTES_SIN_FIRMA} from '../../../constantes'
import {ObtenerCiudadPorNombre} from '../../../cache/servicios-cache/Ciudades'
import {obtenerNombreAmbiente} from '../../../config'

const FormularioModificarUsuario = () => {
  const contextoRegistro = useContext(ContextoRegistro)
  const contextoAplicacion = useContext(ContextApplication)
  const [nombreAmbiente, setNombreAmbiente] = useState('')

  const history = useHistory()

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    history.go(0)
  }

  const manejarFinalizar = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    history.push('/main')
  }

  const manejarGuardar = async () => {
    let departamentoId = (
      await ObtenerDepartamentosPorNombre(
        contextoRegistro.informacionDireccion.departamento
      )
    )[0].Id
    let municipioId = (
      await ObtenerCiudadPorNombre(
        contextoRegistro.informacionDireccion.municipio
      )
    ).Id
    let localidadId = contextoRegistro.informacionDireccion.localidad
      ? (
          await ObtenerLocalidadPorNombre(
            contextoRegistro.informacionDireccion.localidad
          )
        )[0].Id
      : null

    let payloadActualizar = {
      direccion: {
        paisId: 1,
        usuarioId: contextoRegistro.modificarPacienteUsuarioId,
        departamentoId: departamentoId,
        municipioId: municipioId,
        localidadId: localidadId,
        zonaId: contextoRegistro.informacionDireccion.zona ?? null,
        direccion: contextoRegistro.informacionDireccion.direccion ?? '',
        estratoId: contextoRegistro.informacionDireccion.estrato,
        telefono: contextoRegistro.informacionDireccion.telefono,
        indicativo: contextoRegistro.informacionDireccion.indicativoCelular,
        celular: contextoRegistro.informacionDireccion.celular,
        correo:
          contextoRegistro.informacionDireccion.correoElectronico == ''
            ? null
            : contextoRegistro.informacionDireccion.correoElectronico,
      },
      firmaURL: !AMBIENTES_SIN_FIRMA.includes(nombreAmbiente)
        ? contextoRegistro.firma
        : 'https://www.google.com',
      fotoUrl: contextoRegistro.foto,
      huellaUrl: null,
      id: contextoRegistro.modificarPacienteUsuarioId,
      tipoDocumento:
        contextoRegistro.modificarPacienteInformacionPersonal.tipoDocumento,
      numeroDocumento:
        contextoRegistro.modificarPacienteInformacionPersonal.numeroDocumento,
      nombres: contextoRegistro.modificarPacienteInformacionPersonal.nombre,
      apellidos:
        contextoRegistro.modificarPacienteInformacionPersonal.apellidos,
      celular: contextoRegistro.informacionDireccion.celular,
      correo:
        contextoRegistro.informacionDireccion.correoElectronico == ''
          ? null
          : contextoRegistro.informacionDireccion.correoElectronico,
      cargo: null,
      grupoSanguineoId:
        contextoRegistro.modificarPacienteInformacionPersonal.grupoSanguineo,
      fechaNacimiento:
        contextoRegistro.modificarPacienteInformacionPersonal.fechaNacimiento,
      sexoId: contextoRegistro.modificarPacienteInformacionPersonal.sexo,
      estadoCivilId:
        contextoRegistro.modificarPacienteInformacionPersonal.estadoCivil,
      lugarNacimiento:
        contextoRegistro.modificarPacienteInformacionPersonal.lugarNacimiento,
      nacionalidad:
        contextoRegistro.modificarPacienteInformacionPersonal.nacionalidad,
      escolaridadId:
        contextoRegistro.modificarPacienteInformacionPersonal.escolaridad,
      indicativo: contextoRegistro.informacionDireccion.indicativoCelular,
      razaId: null,
    }
    FachadaActualizarUsuario(payloadActualizar)
      .then((res) => {
        if (res.status == 200) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'DATOS ACTUALIZADOS',
            contenido:
              'Paciente:' +
              ' ' +
              contextoRegistro.modificarPacienteInformacionPersonal.nombre.toUpperCase() +
              ' ' +
              contextoRegistro.modificarPacienteInformacionPersonal.apellidos.toUpperCase(),
            botones: [
              {
                nombre: 'Regresar',
                click: manejaCerrarModal,
              },
              {
                nombre: 'Finalizar',
                click: manejarFinalizar,
              },
            ],
          })
        }
      })
      .catch((error) => {
        if (
          error.response.data ==
          'El usuario tiene servicios con estado en proceso'
        ) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'DATOS NO ACTUALIZADOS',
            contenido: (
              <>
                <span className="text-cendiatra w-full font-bold mb-1">
                  EL PACIENTE ESTA SIENDO ATENDIDO POR UN MEDICO
                </span>
                <span className="text-cendiatra w-full font-bold mt-1">
                  POR FAVOR ESPERE A QUE TERMINE LA ATENCION
                </span>
              </>
            ),
            botones: [
              {
                nombre: 'Regresar',
                click: manejaCerrarModal,
              },
              {
                nombre: 'Finalizar',
                click: manejarFinalizar,
              },
            ],
          })
        } else {
          console.log(error)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      const ambiente = await obtenerNombreAmbiente()

      if (!!ambiente) {
        setNombreAmbiente(ambiente)
      } else {
        console.error('No se encontró nombre de ambiente')
      }
    })()
  }, [])

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <div className="w-11/12 flex flex-wrap justify-center items-center mt-10">
        <ContenedorDesplegable
          titulo={'Información paciente personal'}
          expandidoInicial={true}
        >
          <ModificarInformacionPersonalPaciente />
        </ContenedorDesplegable>
        <ContenedorDesplegable
          titulo={'Información paciente dirección'}
          expandidoInicial={true}
        >
          <InformacionDireccion numeroPagina={1} />
        </ContenedorDesplegable>
        <ContenedorDesplegable
          titulo={'Firma foto y huella'}
          expandidoInicial={true}
        >
          <FirmaFotoHuella modificarPaciente={true} numeroPagina={1} />
        </ContenedorDesplegable>

        <BotonesFormularioPasos
          esModificacionAtencion={true}
          contexto={contextoRegistro}
          clickGuardar={manejarGuardar}
        />
      </div>
    </div>
  )
}

export default FormularioModificarUsuario
