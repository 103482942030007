import React from 'react'

const FiltroTexto = ({column: {filterValue, preFilteredRows, setFilter}}) => {
  return (
    <input
      className="flex w-3/4 justify-evenly items-center rounded-md text-cendiatra p-3px placeholder-cendiatra"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Buscar..`}
    />
  )
}

export default FiltroTexto
