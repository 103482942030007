import {getRestApi} from './ApiAxios'

export async function ObtenerUsuariosB2C() {
  const apiReq = await getRestApi()
  return await apiReq.get('gato-api-function/ObtenerUsuariosB2C')
}

export async function ObtenerUsuarioPorIdB2C(id) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'GET',
    url: `gato-api-function/ObtenerUsuarioB2C`,
    params: {id},
  })
}

export async function CrearUsuarioB2C(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PUT',
    url: 'gato-api-function/CrearUsuarioB2C',
    data: payload,
  })
}

export async function FachadaCrearUsuarioB2C(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PUT',
    url: 'gato-api-function/FachadaCrearUsuarioB2C',
    data: payload,
  })
}

export async function ActualizarUsuarioB2C(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PATCH',
    url: 'gato-api-function/ActualizarUsuarioB2C',
    data: payload,
  })
}
