import {useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import {CeldaDobleTexto} from '../Celdas'
import BotonConImagen from '../BotonConImagen'
import IconoResultados from '../../imagenes/iconoResultadosVerde.svg'
import {RUTAS_APP} from '../../constantes'
import {FachadaObtenerDetalleAtencion} from '../../microservicios'

function TablaAtencionesEjecutivo({datosAtenciones}) {
  const history = useHistory()
  const [registroSeleccionado, setRegistroSeleccionado] = useState(null)

  const verServiciosAtendidosDelPaciente = async () => {
    try {
      const respuesta = await FachadaObtenerDetalleAtencion(
        registroSeleccionado.atencionId,
        false
      )
      if (respuesta.status === 200) {
        if (respuesta.data?.servicios?.length) {
          history.push(RUTAS_APP.EJECUTIVA_COMERCIAL.VER_DETALLE_DE_ATENCION, {
            serviciosAtencion: respuesta.data?.servicios ?? [],
            usuario: respuesta.data?.datosUsuario,
            ...registroSeleccionado,
            sedeId: respuesta.data?.sedeId,
          })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (Boolean(registroSeleccionado)) {
      verServiciosAtendidosDelPaciente()
    }
  }, [registroSeleccionado])

  const columnas = useMemo(
    () => [
      {
        Header: (row) => <EncabezadoFondo titulo={'Número de atención'} />,
        accessor: 'atencionId',
        className: 'w-1/12',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-20 rounded-l-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Fecha'} />,
        accessor: 'fechaAtencion',
        className: 'w-1/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-20 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: (row) => (
          <EncabezadoFondo Fila1={'No de identificación'} Fila2={'Nombre'} />
        ),
        accessor: 'numeroDocumento',
        className: 'w-3/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-20 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({row}) => {
          return (
            <CeldaDobleTexto
              datos={{
                Fila1: row.original.nombre,
                Fila2: row.original.numeroDocumento,
              }}
            />
          )
        },
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Empresa'} />,
        accessor: 'empresa',
        className: 'w-2/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-20 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Empresa hija'} />,
        accessor: 'empresaHija',
        className: 'w-2/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-20 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Estado'} />,
        accessor: 'estado',
        className: 'w-2/12',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 h-20 text-white text-14px'",
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
      },
      {
        Header: (row) => (
          <EncabezadoFondo Fila1={'Módulos'} Fila2={'Ver atención'} />
        ),
        accessor: 'modulos',
        className: 'w-1/12',
        headerStyle:
          'flex justify-center items-center bg-cendiatra-verde-5 h-20 text-white text-14px rounded-r-lg',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        disableFilters: true,
        Cell: ({row}) => {
          return (
            <BotonConImagen
              imagen={IconoResultados}
              estilosImagen={'w-10 h-9'}
              medio={true}
              funcionAEjecutar={() => {
                setRegistroSeleccionado(row.original)
              }}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <TablaConPaginacionYFiltros
      columns={columnas}
      data={datosAtenciones}
      paginacion={true}
      sortBy={[{id: 'atencionId', desc: true}]}
    />
  )
}

export default TablaAtencionesEjecutivo
