import React from 'react'
import {Input} from '../inputs'

const EncabezadoHistoriaClinica = ({foto, noDocumento, apellidos, nombre}) => {
  return (
    <div className={`w-full flex justify-center items-center flex-wrap mr-16`}>
      <img className={'w-32 h-32 mr-10'} src={foto} />
      <Input
        titulo={'No. de documento'}
        deshabilitado={true}
        valor={noDocumento}
        estilosContenedor={'w-1/4 flex-col pr-10'}
        align={'items-start'}
        estilosInput={'w-full border border-cendiatra pl-2 rounded-md'}
      />
      <Input
        titulo={'Apellidos'}
        deshabilitado={true}
        valor={apellidos}
        estilosContenedor={'w-1/4 flex-col pr-10'}
        align={'items-start'}
        estilosInput={'w-full border border-cendiatra pl-2 rounded-md'}
      />
      <Input
        titulo={'Nombre'}
        deshabilitado={true}
        valor={nombre}
        estilosContenedor={'w-1/4 flex-col'}
        align={'items-start'}
        estilosInput={'w-full border border-cendiatra pl-2 rounded-md'}
      />
    </div>
  )
}

export default EncabezadoHistoriaClinica
