import {getRestApi} from './ApiAxios'

export const consultMedicAgenda = async (siteId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('GetMedicAgenda?site_id=' + siteId)
}

export const getPatientAgendaInfo = async (idOrder) => {
  const apiReq = await getRestApi()
  return await apiReq.get('GetPacientOrder?idOrder=' + idOrder)
}

export const postNewPatientHistory = async (patientData) => {
  const apiReq = await getRestApi()
  return await apiReq.post('PostPacientHistory', patientData)
}
