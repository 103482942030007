import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import BotonConImagen from '../BotonConImagen'
import {CeldaDobleTexto} from '../Celdas'
import TablaDinamica from '../TablaDinamica'
import {EncabezadoFondo} from '../Tablas'

import IconoResultados from '../../imagenes/iconoResultadosVerde.svg'
import {useHistory} from 'react-router-dom'
import {FachadaObtenerDetalleAtencion} from '../../microservicios'
import {RUTAS_APP} from '../../constantes'

export default function TablaInformacionExamenesAtendidos({
  datosExamenes = [],
}) {
  const history = useHistory()
  const [atencionPickeada, setAtencionPickeada] = useState(null)

  const verServiciosAtendidosDelPaciente = async () => {
    try {
      const respuesta = await FachadaObtenerDetalleAtencion(
        atencionPickeada.atencionId
      )
      if (respuesta.status === 200) {
        if (respuesta.data?.servicios?.length) {
          history.push(RUTAS_APP.COMPARTIDO.SOLICITAR_MODIFICAR_EXAMENES, {
            serviciosAtencion: respuesta.data?.servicios ?? [],
            usuario: respuesta.data?.datosUsuario,
            ...atencionPickeada,
            sedeId: respuesta.data?.sedeId,
          })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (Boolean(atencionPickeada)) {
      verServiciosAtendidosDelPaciente()
    }
  }, [atencionPickeada])

  const columnas = useMemo(
    () => [
      {
        Header: () => <EncabezadoFondo titulo={'Número de atención / Fecha'} />,
        accessor: 'atencionId',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
        Cell: ({row}) => (
          <CeldaDobleTexto
            datos={{
              Fila1: row.original.atencionId,
              Fila2: moment(row.original.fechaAtencion).format('DD/MM/YYYY'),
            }}
          />
        ),
      },
      {
        Header: () => <EncabezadoFondo titulo={'No de documento'} />,
        accessor: 'numeroDocumento',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Nombre'} />,
        accessor: 'nombre',
        className:
          'w-3/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Apellidos'} />,
        accessor: 'apellidos',
        className:
          'w-3/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => (
          <EncabezadoFondo Fila1={'Módulos'} Fila2={'ver exámenes atendidos'} />
        ),
        accessor: 'modulos',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) => {
          return (
            <BotonConImagen
              imagen={IconoResultados}
              estilosImagen={'w-13 h-12'}
              medio={true}
              funcionAEjecutar={() => setAtencionPickeada(row.original)}
            />
          )
        },
      },
    ],
    []
  )
  return (
    <div className="w-full">
      <TablaDinamica
        columns={columnas}
        data={datosExamenes}
        estilosTr={
          'w-full flex border-b-2 border-b-gris justify-evenly items-center text-center text-17px text-cendiatra-gris-1 border py-2 border-cendiatra-verde-4 my-2 rounded-lg'
        }
      />
    </div>
  )
}
