import {useContext, useEffect, useState} from 'react'
import {PoblarCacheComun} from '../cache/servicios-cache/Comun'
import {obtenerVersionActual} from '../cache/servicios-cache/Version'
import {ContextApplication} from '../contexto'
import {
  getRestApi,
  refreshAccessToken as microRefreshAccessToken,
  idToken as microIdToken,
} from '../microservicios/ApiAxios'
import axios from 'axios'

const AxiosInterceptor = ({children}) => {
  const contextoAplicacion = useContext(ContextApplication)
  const [interceptoresConfigurados, setInterceptoresConfigurados] =
    useState(false)

  const errorMicroInteceptor = (error) => {
    contextoAplicacion.setIsLoading(false)
    contextoAplicacion.setCountFetching((prv) => prv - 1)
    if (!error.response) return Promise.reject()
    const respuesta = error.response.data
    contextoAplicacion.setModal({
      abierto: true,
      titulo:
        typeof respuesta === 'string'
          ? respuesta.trim().length > 0
            ? respuesta
            : 'Ocurrió un error, inténtelo más tarde'
          : typeof respuesta === 'object'
          ? respuesta.message
          : 'Ocurrió un error en el servicio, inténtelo más tarde',
      botones: [
        {
          nombre: 'Cerrar',
          click: () => {
            contextoAplicacion.setModal({
              abierto: false,
              botones: [],
              titulo: '',
            })
          },
        },
      ],
    })

    return Promise.reject(error)
  }

  const completadoMicroInteceptor = async (respuesta) => {
    contextoAplicacion.setIsLoading(false)
    contextoAplicacion.setCountFetching((prv) => prv - 1)
    if (respuesta.status === 201) {
      await PoblarCacheComun(true)
      respuesta.config.headers['VersionCache'] = await obtenerVersionActual()
      let nuevaRespuesta = await axios.request(respuesta.config)
      return nuevaRespuesta
    } else {
      return respuesta
    }
  }

  const requestMicroInterceptor = async (config) => {
    contextoAplicacion.setIsLoading(true)
    contextoAplicacion.setCountFetching((prv) => prv + 1)
    const sessionData = await microRefreshAccessToken()
    if (sessionData) {
      config.headers.common['Authorization'] = `Bearer ${sessionData}`
      config.headers.common['IdToken'] = `${microIdToken}`
    }
    if (config == 'Archivo') {
      config.headers.common['Content-Type'] = 'form/data'
      config.headers.common['Accept'] = 'form/data'
    } else {
      config.headers.common['Content-Type'] = 'application/json'
      config.headers.common['Accept'] = 'application/json'
    }
    config.headers.common['VersionCache'] = await obtenerVersionActual()
    return config
  }

  useEffect(() => {
    ;(async () => {
      const axiosReq = await getRestApi()
      const requestMicro = axiosReq.interceptors.request.use(
        requestMicroInterceptor
      )

      const respuestaMicro = axiosReq.interceptors.response.use(
        completadoMicroInteceptor,
        errorMicroInteceptor
      )

      setInterceptoresConfigurados(true)

      return () => {
        axiosReq.interceptors.response.eject(respuestaMicro)
        axiosReq.interceptors.request.eject(requestMicro)
      }
    })()
  }, [])

  return interceptoresConfigurados ? children : null
}

export default AxiosInterceptor
