import React from 'react'
import moment from 'moment'

const FiltroFecha = ({
  column: {
    filterValue,
    preFilteredRows,
    setFilter,
    establecerFechaFinal,
    establecerFechaInicial,
  },
}) => {
  let fechaActual = moment().format('YYYY-MM-DD')
  let fechaMinima = moment().subtract(30, 'days').format('YYYY-MM-DD')
  return (
    <input
      className="flex w-3/4 justify-evenly items-center rounded-md text-cendiatra"
      type="date"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      max={establecerFechaFinal ? fechaActual : null}
      min={establecerFechaInicial ? fechaMinima : null}
    />
  )
}

export default FiltroFecha
