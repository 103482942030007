import {getRestApi} from './ApiAxios'

export const CrearTurno = async (datosTurno) => {
  const apiReq = await getRestApi()
  return await apiReq.put('turno-function/AsignarTurno', datosTurno)
}

export const ObtenerTurnosPorSedeId = async (sedeId) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'turno-function/ObtenerTurnosPorSedeId?sedeid=' + sedeId
  )
}

export const LlamarTurno = async (idTurno, idSede) => {
  const apiReq = await getRestApi()
  let payload = {turnoId: idTurno, sedeId: idSede}
  return await apiReq.patch('turno-function/LlamarTurno', payload)
}

export const AtenderTurno = async (idTurno, idSede) => {
  const apiReq = await getRestApi()
  let payload = {turnoId: idTurno, sedeId: idSede}
  return await apiReq.patch('turno-function/AtenderTurno', payload)
}

export const CancelarTurno = async (idTurno, idSede) => {
  const apiReq = await getRestApi()
  let payload = {turnoId: idTurno, sedeId: idSede}
  return await apiReq.patch('turno-function/CancelarTurno', payload)
}

export const ReiniciarTurno = async (idTurno, idSede) => {
  const apiReq = await getRestApi()
  let payload = {turnoId: idTurno, sedeId: idSede}
  return await apiReq.patch('turno-function/ReiniciarTurno', payload)
}

export const GenerarPDFTurno = async (datosTurno) => {
  const apiReq = await getRestApi()
  return await apiReq.post('turno-function/GenerarPDFTurno', datosTurno, {
    responseType: 'blob',
  })
}
