import React from 'react'

const InputCargarArchivo = ({funcion, estilosInput}) => {
  return (
    <div className=" flex justify-center items-center ">
      <input
        className={`${estilosInput}`}
        id="archivo"
        type="file"
        accept="image/*"
        onChange={(e) => funcion(e)}
        onClick={(e) => (e.target.value = null)}
      />
    </div>
  )
}

export default InputCargarArchivo
