import React, {useContext} from 'react'
import {ContextoNavegacion} from '../../contexto'
import {NavLink} from 'react-router-dom'
import {
  flechaDesplegableBlanca,
  flechaDesplegableVerde,
} from '../../imagenes/Expandible'

const Submenu = ({
  titulo,
  imagen,
  ruta,
  funcion,
  deshabilitado,
  children,
  funcionOnClick = () => {},
  subMenus,
}) => {
  const contextoNavegacion = useContext(ContextoNavegacion)
  const paginaActual = window.location.pathname
  return (
    <>
      <NavLink
        className={` w-full ${deshabilitado ? 'pointer-events-none' : ''} `}
        to={`${ruta || paginaActual}`}
      >
        <div
          onMouseEnter={() => contextoNavegacion.setEnlaceActivo(titulo)}
          onClick={() => (funcion ? funcion() : '')}
          className={'flex w-full justify-center items-center h-10 my-1'}
        >
          <img className="w-7 h-7 m-2" src={imagen} alt="serviceImg" />
          <div
            className={
              contextoNavegacion.abrirMenu
                ? 'flex flex-nowrap w-full'
                : 'hidden'
            }
            onClick={funcionOnClick}
          >
            <div
              className={`w-full h-10 rounded flex items-center pl-2 ${
                contextoNavegacion.enlaceActivo === titulo && !deshabilitado
                  ? 'text-md text-white bg-navBg bg-cover bg-center'
                  : deshabilitado
                  ? 'text-cendiatra-gris-1'
                  : 'text-cendiatra'
              }`}
            >
              <span className="w-11/12">{titulo}</span>
              {subMenus ? (
                <img
                  className="w-1/12 h-auto m-2 "
                  src={
                    contextoNavegacion.enlaceActivo === titulo
                      ? flechaDesplegableBlanca
                      : flechaDesplegableVerde
                  }
                  alt=""
                  style={{
                    transform: `rotate(${
                      contextoNavegacion.opcionMenuSeleccionada === titulo
                        ? '180'
                        : '0'
                    }deg)`,
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </NavLink>
      <div className="w-80"> {children} </div>
    </>
  )
}

export default Submenu
