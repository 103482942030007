import {getRestApi} from './ApiAxios'

export const EmitirFactura = async (informacionFacturacion) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'facturacion-function/EmitirFactura',
    informacionFacturacion
  )
}

export const ObtenerFacturasPorNombreOIdentificacion = async (busqueda) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'facturacion-function/ObtenerFacturasPorNombreOIdentificacion?busqueda=' +
      busqueda
  )
}

export const ObtenerEstadoFacturacion = async (numeroDocumento) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'facturacion-function/ObtenerEstadoFacturacion?numeroDocumento=' +
      numeroDocumento
  )
}

export const ObtenerFactura = async (prefijo, consecutivo) => {
  const apiReq = await getRestApi()
  return await apiReq.get('facturacion-function/ObtenerFactura', {
    params: {prefijo, consecutivo},
  })
}

export const EmitirNotaCredito = async (informacionNotaCredito) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'facturacion-function/EmitirNotaCredito',
    informacionNotaCredito
  )
}

export const ObtenerDocumentoPDF = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'facturacion-function/ObtenerDocumentoPDF',
    params: {id},
  })
}

export const GenerarDocumentoFactura = async (facturaId) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'facturacion-function/GenerarDocumentoFactura',
    facturaId
  )
}
