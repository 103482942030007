import React, {useState, useContext, useEffect, useCallback} from 'react'
import {ContextApplication} from '../../contexto'
import addButtonImg from '../../imagenes/addButton.png'
import {TablaServicios, TablaPaquetes} from '../Tablas'
import BotonConImagen from '../BotonConImagen'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {
  AgregarServiciosACache,
  AgregarPaquetesACache,
  ObtenerPaqueteOServicioPorCodigo,
  ObtenerPaqueteOServicioPorNombre,
} from '../../cache/servicios-cache'
import {ROLES_APP} from '../../constantes'

export default function FormularioPrecios({
  contexto,
  numeroPagina,
  esPortafolio = false,
  claseBloqueo,
}) {
  const contextoApp = useContext(ContextApplication)
  const [servicioOPaqueteSeleccionado, setServicioOPaqueteSeleccionado] =
    useState({codigoInterno: '', nombre: ''})
  const [elementosFiltradosCodigo, setElementosFiltradosCodigo] = useState([])
  const [elementosFiltradosNombre, setElementosFiltradosNombre] = useState([])

  const obtenerInformacionPaquetesYServicios = useCallback(async () => {
    contextoApp.setIsLoading(true)
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    contextoApp.setIsLoading(false)
  }, [])

  useEffect(() => {
    if (numeroPagina === contexto.numeroPagina) {
      obtenerInformacionPaquetesYServicios().catch(console.error)
    }
  }, [contexto.numeroPagina])

  const agregarServicioOPaquete = () => {
    if (Boolean(contexto.portafolioSeleccionadoId)) {
      contexto.setSeguroDeCambio(true)
      return
    }

    if (servicioOPaqueteSeleccionado.tipo === undefined) return
    else if (servicioOPaqueteSeleccionado.tipo === 0) agregarServicio()
    else agregarPaquete()
  }

  useEffect(() => {
    if (numeroPagina === contexto.numeroPagina) {
      if (
        contexto.listaServicios.length == 0 &&
        contexto.listaPaquetes.length == 0
      )
        contexto.setformularioActualTieneErrores(true)
      else contexto.setformularioActualTieneErrores(false)
    }
  }, [contexto.numeroPagina, contexto.listaServicios, contexto.listaPaquetes])

  const agregarServicio = () => {
    let existe =
      contexto.listaServicios.filter(
        (x) => x.servicio_id == servicioOPaqueteSeleccionado.id
      ).length > 0
    if (existe) {
      contextoApp.setModal({
        abierto: true,
        titulo: 'ERROR',
        contenido: (
          <span className="w-full text-cendiatra text-xl font-bold">
            El servicio {servicioOPaqueteSeleccionado.nombre} ya se encuentra en
            el tarifario, no es posible agregarlo de nuevo.
          </span>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModalError,
          },
        ],
      })
    } else {
      contexto.setListaServicios([
        ...contexto.listaServicios,
        {
          servicio_id: servicioOPaqueteSeleccionado.id,
          nombre: servicioOPaqueteSeleccionado.nombre,
          alias: '',
          tipoCambioSedes: null,
          tipoCambioAliados: null,
          precios_sedes: contexto.sedesSeleccionadas
            .filter((x) => x.Activo)
            .map((sede) => ({
              id: 'servicio:' + sede.Id,
              nombre: sede.Nombre,
              grupo: sede.Grupo,
              precio: 0,
              nuevoPrecio: 0,
            })),
          precios_aliados: contexto.aliadosSeleccionados
            .filter((x) => x.Activo)
            .map((aliado) => ({
              id: 'aliado:' + aliado.Id,
              nombre: aliado.Nombre,
              grupo: 'ALIADOS',
              precio: 0,
              nuevoPrecio: 0,
            })),
        },
      ])
      setServicioOPaqueteSeleccionado({codigoInterno: '', nombre: ''})
      setElementosFiltradosCodigo([])
      setElementosFiltradosNombre([])
    }
  }

  const manejarCerrarModalError = () => {
    contextoApp.setModal({abierto: false, botones: []})
  }

  const agregarPaquete = () => {
    let existe =
      contexto.listaPaquetes.filter(
        (x) => x.paquete_id == servicioOPaqueteSeleccionado.id
      ).length > 0
    if (existe) {
      contextoApp.setModal({
        abierto: true,
        titulo: 'ERROR',
        contenido: (
          <span className="w-full text-cendiatra text-xl font-bold">
            El paquete {servicioOPaqueteSeleccionado.nombre} ya se encuentra en
            el tarifario, no es posible agregarlo de nuevo.
          </span>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModalError,
          },
        ],
      })
    } else {
      contexto.setListaPaquetes([
        ...contexto.listaPaquetes,
        {
          paquete_id: servicioOPaqueteSeleccionado.id,
          puedeModificarse: servicioOPaqueteSeleccionado.puedeModificarse,
          nombre: servicioOPaqueteSeleccionado.nombre,
          tipoCambioSedes: null,
          tipoCambioAliados: null,
          precios_sedes: contexto.sedesSeleccionadas
            .filter((x) => x.Activo)
            .map((sede) => ({
              id: 'paquete:' + sede.Id,
              nombre: sede.Nombre,
              grupo: sede.Grupo,
              precio: 0,
              nuevoPrecio: 0,
            })),
          precios_aliados: contexto.aliadosSeleccionados
            .filter((x) => x.Activo)
            .map((aliado) => ({
              id: 'aliado:' + aliado.Id,
              nombre: aliado.Nombre,
              grupo: 'ALIADOS',
              precio: 0,
              nuevoPrecio: 0,
            })),
        },
      ])
      setServicioOPaqueteSeleccionado({codigoInterno: '', nombre: ''})
      setElementosFiltradosCodigo([])
      setElementosFiltradosNombre([])
    }
  }

  const filtrarPorCodigo = (e) => {
    setElementosFiltradosNombre([])
    setServicioOPaqueteSeleccionado({
      ...servicioOPaqueteSeleccionado,
      nombre: '',
      codigoInterno: e.target.value,
      tipo: undefined,
    })
    if (e.target.value.length >= 3) {
      ObtenerPaqueteOServicioPorCodigo(e.target.value).then((res) => {
        if (res && res.length > 0) {
          setElementosFiltradosCodigo(
            res.map((elemento) => ({
              id: elemento.id,
              nombre: elemento.nombre,
              codigoInterno: elemento.codigoInterno,
              filtro: elemento.codigoInterno,
              tipo: elemento.tipo,
            }))
          )
        }
      })
    } else {
      setElementosFiltradosCodigo([])
    }
  }

  const filtrarPorNombre = (e) => {
    setElementosFiltradosCodigo([])
    setServicioOPaqueteSeleccionado({
      ...servicioOPaqueteSeleccionado,
      codigoInterno: '',
      nombre: e.target.value,
      tipo: undefined,
    })

    if (e.target.value.length >= 3) {
      ObtenerPaqueteOServicioPorNombre(e.target.value).then((res) => {
        if (res && res.length > 0) {
          setElementosFiltradosNombre(
            res.map((elemento) => ({
              id: elemento.id,
              nombre: elemento.nombre,
              codigoInterno: elemento.codigoInterno,
              filtro: elemento.nombre,
              tipo: elemento.tipo,
            }))
          )
        }
      })
    } else {
      setElementosFiltradosNombre([])
    }
  }

  const seleccionarOpcion = (opcion) => {
    setServicioOPaqueteSeleccionado({...opcion})
  }

  return (
    <div
      className={`w-full space-x-2 flex flex-wrap justify-center items-center`}
    >
      <div
        className={`${
          claseBloqueo
        } w-4/6 flex space-x-2 justify-center items-center my-10`}
      >
        <CampoFiltrarEspañol
          titulo={'Código servicio o paquete*'}
          estilosPersonalizados={'w-5/12'}
          estilosInput={'border-cendiatra'}
          placeholder={'Descripción (autocompletar y filtrar)'}
          tipo={'text'}
          valorDelCampoFiltro={servicioOPaqueteSeleccionado.codigoInterno}
          desactivarOtroFiltro={''}
          informacionFiltrada={elementosFiltradosCodigo}
          handleChange={filtrarPorCodigo}
          handleOptionChange={seleccionarOpcion}
        />
        <CampoFiltrarEspañol
          titulo={'Nombre del servicio o paquete*'}
          estilosPersonalizados={'w-5/12'}
          estilosInput={'border-cendiatra'}
          placeholder={'Descripción (autocompletar y filtrar)'}
          tipo={'text'}
          valorDelCampoFiltro={servicioOPaqueteSeleccionado.nombre}
          desactivarOtroFiltro={''}
          informacionFiltrada={elementosFiltradosNombre}
          handleChange={filtrarPorNombre}
          handleOptionChange={seleccionarOpcion}
        />
        <BotonConImagen
          ancho={'w-1/12'}
          imagen={addButtonImg}
          textoAlternativo={'Boton Agregar'}
          funcionAEjecutar={() => {
            agregarServicioOPaquete()
          }}
        />
      </div>
      <div className="w-full flex">
        <span className="text-center text-md text-cendiatra font-medium">
          Agregar mínimo un servicio y/o paquete
        </span>
      </div>
      <div className="w-full flex">
        {numeroPagina === contexto.numeroPagina ? (
          <TablaServicios contexto={contexto} esPortafolio={esPortafolio} />
        ) : null}
      </div>
      <div className="w-full flex">
        {numeroPagina === contexto.numeroPagina ? (
          <TablaPaquetes contexto={contexto} esPortafolio={esPortafolio} />
        ) : null}
      </div>
    </div>
  )
}
