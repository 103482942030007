import React from 'react'

const BotonConImagen = ({
  ancho,
  imagen,
  estilosImagen,
  estilosContenedor,
  textoAlternativo,
  funcionAEjecutar,
  medio,
  rotacion,
  desabilitado,
  texto,
}) => {
  return (
    <div
      className={`${ancho} ${estilosContenedor} flex justify-center flex-wrap items-center`}
    >
      <button
        className="w-full flex justify-center flex-wrap items-center"
        onClick={funcionAEjecutar}
        disabled={desabilitado}
        type={'button'}
      >
        <img
          className={`${medio ? '' : 'mt-5'} cursor-pointer ${estilosImagen}`}
          style={{transform: `rotate(${rotacion}deg)`}}
          src={imagen}
          alt={textoAlternativo}
        />
      </button>
      {texto ?? <span className="w-full">{texto}</span>}
    </div>
  )
}

export default BotonConImagen
