import React, {useEffect, useContext, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'

import userImg from '../../imagenes/usuarioImg.png'

import BaseFormulario from '../../componentes/Formularios/BaseFormulario.jsx'
import {
  ContextoFormulario,
  ContextoModales,
  ContextApplication,
} from '../../contexto'
import {
  ObtenerPlantillaPorId,
  ObtenerPlantillaPorServicioId,
} from '../../microservicios/Plantilla.js'
import {ObtenerSexoPorId} from '../../cache/servicios-cache/Referencia.js'
import {ObtenerEstadoCivilPorId} from '../../cache/servicios-cache/EstadoCivil'

import moment from 'moment'
import {
  ObtenerInformacionPaciente,
  ObtenerPlantillaPorServicioIdConDatos,
} from '../../microservicios/Fachada'
import {ObtenerEmpresaPorId} from '../../cache/servicios-cache/Empresas'
import {ObtenerTipoExamenPorId} from '../../cache/servicios-cache/TipoExamen'
import {ObtenerInformacionUltimaAtencionPorUsuarioId} from '../../microservicios/Atencion'
import {ObtenerEscolaridadPorId} from '../../cache/servicios-cache/Escolaridad'
import {ObtenerDatosExamenPorServicioAtencionId} from '../../microservicios/Examen'
import {
  AgregarServiciosACache,
  ObtenerServicioPorId,
} from '../../cache/servicios-cache/Servicios'
import {
  ObtenerHistoriaClinica,
  FachadaObtenerServiciosPorAtencionId,
} from '../../microservicios/Fachada'
import {nombreBotonesPlantilla} from '../../constantes/plantillaBotones'
import {objetoPredeterminadoPlantilla} from '../../constantes/motorPlantilla'
import {ObtenerPermisosServiciosPorRol} from '../../microservicios/Rol'
import {estadosExamenesConst} from '../../constantes/estadoExamen'
import {useMsal} from '@azure/msal-react'
import {ROLES_APP} from '../../constantes/roles'
import {useObtenerHistoriaClinicaUsuario} from '../../hooks/useObtenerHistoriaClinicaUsuario'
import {ObtenerSedePorId} from '../../cache/servicios-cache/Sedes.js'

export default function Examen() {
  const {state} = useLocation()
  const contextoFormulario = useContext(ContextoFormulario)
  const contextoModales = useContext(ContextoModales)
  const contextoAplicacion = useContext(ContextApplication)

  const [tipoGuardado, setTipoGuardado] = useState('')
  const [idsEspecialidades, setIdsEspecialidades] = useState([])

  const {accounts} = useMsal()
  const rolUsuario = accounts[0]?.idTokenClaims?.roles[0] ?? ''

  const plantillaId = useMemo(() => {
    return contextoFormulario.plantilla?.id ?? null
  }, [contextoFormulario.plantilla])

  const [obtenerHistoriaClinicaPaciente] = useObtenerHistoriaClinicaUsuario()

  useEffect(() => {
    const obtenerIdsIniciales = async () => {
      try {
        const respuesta =
          rolUsuario !== ROLES_APP.JEFE_SEDE
            ? (await ObtenerPermisosServiciosPorRol())?.data?.serviciosIds
            : []
        setIdsEspecialidades(respuesta)
      } catch (error) {
        console.log(error)
      }
    }
    obtenerIdsIniciales()
  }, [])

  const validarAtencionCorrespondeARol = (servicioPlantilla) => {
    if (rolUsuario !== ROLES_APP.JEFE_SEDE) {
      return Boolean(
        idsEspecialidades.find(
          (servicioId) => servicioId === servicioPlantilla
        ) ?? null
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      if (
        (state?.estadoExamen &&
          state?.estadoExamen === estadosExamenesConst.CERRADO) ||
        state?.deshabilitarTodo
      ) {
        contextoFormulario.setHabilitarEdicion(false)
      } else {
        contextoFormulario.setHabilitarEdicion(
          validarAtencionCorrespondeARol(state.servicioId)
        )
      }

      if (contextoFormulario?.formularios && state?.servicioId) {
        const buscarEsteExamen = contextoFormulario?.formularios?.find(
          (examen) => examen.servicioId === state.servicioId
        )

        contextoFormulario.setExamenEnVista(buscarEsteExamen ?? null)
      }
    })()
    contextoFormulario.setNumeroPagina(1)
  }, [state, contextoFormulario?.formularios])

  useEffect(() => {
    contextoAplicacion.setDeshabilitarMenu(true)
  }, [])

  useEffect(() => {
    contextoFormulario.setExamenInicial(state)
  }, [contextoFormulario.actualizarExamenInicial])

  const obtenerPlantillaPerServicioIdOPlantillaId = async (plantillaId) => {
    const informacionPlantillaServicio = {plantilla: null, datosExamen: []}
    try {
      const respuesta = !plantillaId
        ? await ObtenerPlantillaPorServicioId(state.servicioId)
        : await ObtenerPlantillaPorId(plantillaId)
      let plantilla = null
      if (respuesta.status === 200) {
        informacionPlantillaServicio.plantilla =
          convertirAtributosRespuestaPlantilla(
            respuesta?.data ?? objetoPredeterminadoPlantilla
          )
      } else {
        informacionPlantillaServicio.plantilla = {
          ...objetoPredeterminadoPlantilla,
        }
        plantilla404()
        if (
          contextoFormulario.plantilla?.hasOwnProperty('formulariosFinalPagina')
        ) {
          Object.assign(plantilla, {
            formulariosFinalPagina:
              contextoFormulario.plantilla.formulariosFinalPagina,
          })
        }
        limpiarValoresFormulario()
      }
      return informacionPlantillaServicio
    } catch (error) {
      limpiarValoresFormulario()
      informacionPlantillaServicio.plantilla = {
        ...objetoPredeterminadoPlantilla,
      }
      return informacionPlantillaServicio
    }
  }

  const obtenerPlantillaPerServicioIdServicioAtencionIdConDatos = async () => {
    const informacionPlantillaServicio = {plantilla: null, datosExamen: []}
    try {
      const respuesta = await ObtenerPlantillaPorServicioIdConDatos(
        state?.servicioId ?? 0,
        state?.servicioAtencionId ?? 0
      )

      if (respuesta.status === 200) {
        informacionPlantillaServicio.plantilla =
          convertirAtributosRespuestaPlantilla(
            respuesta.data?.plantilla ?? objetoPredeterminadoPlantilla
          )

        if (respuesta.data?.datos?.length > 0) {
          informacionPlantillaServicio.datosExamen = respuesta.data.datos
        }
      } else {
        informacionPlantillaServicio.plantilla = {
          ...objetoPredeterminadoPlantilla,
        }
        if (
          contextoFormulario.plantilla?.hasOwnProperty('formulariosFinalPagina')
        ) {
          Object.assign(informacionPlantillaServicio.plantilla, {
            formulariosFinalPagina:
              contextoFormulario.plantilla.formulariosFinalPagina,
          })
        }
        limpiarValoresFormulario()
        plantilla404()
      }

      return informacionPlantillaServicio
    } catch (error) {
      limpiarValoresFormulario()
      informacionPlantillaServicio.plantilla = {
        ...objetoPredeterminadoPlantilla,
      }
      return informacionPlantillaServicio
    }
  }

  const convertirAtributosRespuestaPlantilla = (plantilla) => {
    plantilla.pestanas.forEach((pestana) => {
      pestana.secciones.forEach((seccion) => {
        seccion.campos.forEach((campo) => {
          campo.atributos = JSON.parse(campo.atributos)
        })
      })
    })

    return plantilla
  }

  const plantilla404 = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'Este servicio no tiene una plantilla configurada',
      contenido: '',
      botones: [
        {
          nombre: 'Cerrar',
          click: () => {
            contextoAplicacion.setModal({
              abierto: false,
              titulo: '',
              contenido: '',
              botones: [],
            })
          },
        },
      ],
    })
  }

  const limpiarValoresFormulario = () => {
    contextoFormulario.setValoresCampos({})
    contextoFormulario.setValoresObservaciones({})
    contextoFormulario.setNodosPorComponente({})
    contextoFormulario.setEstadoHabilitadoBotones((prv) => ({
      ...prv,
      [nombreBotonesPlantilla.IMPRIMIRCERTIFICADO]: false,
      [nombreBotonesPlantilla.CERRAREXAMEN]: false,
      [nombreBotonesPlantilla.GUARDADOPARCIAL]: false,
      [nombreBotonesPlantilla.IMPRIMIREXAMEN]: false,
      [nombreBotonesPlantilla.INTERCONSULTA]:
        rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA,
      [nombreBotonesPlantilla.FORMULAMEDICA]:
        rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA,
      [nombreBotonesPlantilla.FORMULAOPTOMETRICA]:
        rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA,
      [nombreBotonesPlantilla.INCAPACIDADMEDICA]:
        rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA,
      [nombreBotonesPlantilla.SOLICITUDVALORACION]:
        rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA,
    }))
    contextoModales.setFormulaMedica({})
    contextoModales.setInterConsulta({})
    contextoModales.setFormulaOptometrica({})
  }

  useEffect(() => {
    if (state.servicioId) {
      limpiarValoresFormulario()
    }
  }, [state?.servicioId])

  useEffect(() => {
    contextoModales.setParametrosFormulario(state)
  }, [state])

  useEffect(() => {
    if (state?.atencionId) {
      obtenerListaExamenes()
    }
  }, [state?.atencionId, contextoFormulario.actualizarExamenInicial])

  useEffect(() => {
    if (contextoFormulario.recargarListadoFormularios && state?.atencionId) {
      contextoFormulario.setRecargarListadoFormularios(false)
      obtenerListaExamenes()
    }
  }, [contextoFormulario.recargarListadoFormularios])

  const obtenerListaExamenes = async () => {
    try {
      const respuesta = await FachadaObtenerServiciosPorAtencionId(
        state.atencionId
      )
      const idsEspecialidades =
        rolUsuario !== ROLES_APP.JEFE_SEDE
          ? await ObtenerPermisosServiciosPorRol()
          : []
      contextoFormulario.setFormularios(
        respuesta.data.map((examen) => {
          return {
            ...examen,
            especialidadMedico: idsEspecialidades?.data?.serviciosIds?.includes(
              examen.servicioId
            ),
            estadoExamen: examen.estadoServicioId,
            tipoExamen: examen.nombreServicio,
            medico: examen.nombreMedico,
          }
        }) ?? []
      )
      setTipoGuardado(
        respuesta?.data?.find(
          (dato) => dato.servicioAtencionId === state?.servicioAtencionId
        )?.tipoGuardado ?? ''
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    contextoFormulario.setNumeroPagina(1)
    contextoFormulario.setInformacionUsuario({
      urlFoto: userImg,
      datosUsuario: [
        {titulo: 'Nombre'},
        {titulo: 'Documento'},
        {titulo: 'Fecha primera atención'},
        {titulo: 'Género'},
        {titulo: 'Fecha de nacimiento'},
        {titulo: 'Edad'},
        {titulo: 'Lugar nacimiento'},
        {titulo: 'Estrato'},
        {titulo: 'Escolaridad'},
        {titulo: 'Estado civil'},
        {titulo: 'Nacionalidad'},
        {titulo: 'Tipo de examen'},
        {titulo: 'Empresa'},
        {titulo: 'Cargo'},
        {titulo: 'Actividad económica'},
        {titulo: 'Sede'},
        {titulo: 'Médico'},
      ],
    })
  }, [])

  const funcionSetearDatosExamenALosCampos = (plantilla, datosExamen = []) => {
    if (Boolean(plantilla?.id)) {
      const pestanas = plantilla?.pestanas ?? []

      const valoresCamposPerTodo = {}
      const observacionesCampoPerTodo = {}

      for (const pestana of pestanas) {
        const valoresPestana = {}
        const observacionesPestana = {}
        for (const seccion of pestana.secciones) {
          const camposValorYObservaciones = seccion.campos.map((campo) => {
            const valorPredeterminado = campo?.atributos['valorPredeterminado']

            const datoCampo = datosExamen.find(
              (dato) => dato.campoId === campo.id
            )

            const valores = {}

            Object.assign(valores, {
              valoresCampos: [
                [campo.tituloCampo],
                datoCampo?.valor ?? valorPredeterminado ?? '',
              ],
            })
            if (datoCampo?.observaciones) {
              Object.assign(valores, {
                valoresObservaciones: [
                  [campo.tituloCampo],
                  datoCampo?.observaciones ?? '',
                ],
              })
            }
            return valores
          })

          const valores = camposValorYObservaciones.map(
            (campo) => campo.valoresCampos
          )

          const observaciones = camposValorYObservaciones
            .filter((campito) => campito?.valoresObservaciones)
            .map((campo) => campo?.valoresObservaciones)

          Object.assign(valoresPestana, {
            [seccion?.id ?? seccion.nombreSeccion]: Object.fromEntries(valores),
          })

          Object.assign(observacionesPestana, {
            [seccion?.id ?? seccion.nombreSeccion]:
              Object.fromEntries(observaciones),
          })
        }

        Object.assign(valoresCamposPerTodo, {
          [pestana?.id ?? pestana.tituloPestana]: valoresPestana,
        })
        Object.assign(observacionesCampoPerTodo, {
          [pestana?.id ?? pestana.tituloPestana]: observacionesPestana,
        })
      }
      contextoFormulario.setValoresCampos(valoresCamposPerTodo)
      contextoFormulario.setValoresObservaciones(observacionesCampoPerTodo)
    }
  }

  const validacionesEstadosBotones = (plantilla, estadoRetornado) => {
    const btnTomaDatosDisponibleEnPlantilla = plantilla?.botones.some(
      (btn) =>
        btn.nombreBoton === nombreBotonesPlantilla.GUARDARTOMADEDATOS &&
        btn.estadoBoton
    )

    const estadosBotones = {...contextoFormulario.estadoHabilitadoBotones}

    if (estadoRetornado === 200) {
      estadosBotones[nombreBotonesPlantilla.IMPRIMIRCERTIFICADO] =
        (contextoFormulario?.examenEnVista?.estadoExamen ===
          estadosExamenesConst.CERRADO &&
          rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA) ||
        (rolUsuario === ROLES_APP.AUXILIAR_ENFERMERIA &&
          !btnTomaDatosDisponibleEnPlantilla &&
          contextoFormulario?.examenEnVista?.estadoExamen ===
            estadosExamenesConst.CERRADO) ||
        tipoGuardado.toUpperCase() === 'GP' ||
        contextoFormulario?.examenEnVista?.finalizado

      estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] =
        contextoFormulario?.examenEnVista?.estadoExamen !==
          estadosExamenesConst.CERRADO &&
        rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA &&
        !contextoFormulario?.examenEnVista?.finalizado

      estadosBotones[nombreBotonesPlantilla.IMPRIMIREXAMEN] =
        (contextoFormulario?.examenEnVista?.estadoExamen ===
          estadosExamenesConst.CERRADO &&
          rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA) ||
        (rolUsuario === ROLES_APP.AUXILIAR_ENFERMERIA &&
          !btnTomaDatosDisponibleEnPlantilla &&
          contextoFormulario?.examenEnVista?.estadoExamen ===
            estadosExamenesConst.CERRADO) ||
        contextoFormulario?.examenEnVista?.finalizado
    } else {
      estadosBotones[nombreBotonesPlantilla.IMPRIMIRCERTIFICADO] = false
      estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] = false
      estadosBotones[nombreBotonesPlantilla.IMPRIMIREXAMEN] = false
    }

    estadosBotones[nombreBotonesPlantilla.GUARDADOPARCIAL] =
      contextoFormulario?.examenEnVista?.estadoExamen !==
        estadosExamenesConst.CERRADO &&
      rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA &&
      !contextoFormulario?.examenEnVista?.finalizado

    estadosBotones[nombreBotonesPlantilla.GUARDARTOMADEDATOS] =
      rolUsuario === ROLES_APP.AUXILIAR_ENFERMERIA &&
      !Boolean(tipoGuardado) &&
      !contextoFormulario?.examenEnVista?.finalizado

    if (rolUsuario === ROLES_APP.AUXILIAR_ENFERMERIA) {
      if (!btnTomaDatosDisponibleEnPlantilla) {
        estadosBotones[nombreBotonesPlantilla.GUARDADOPARCIAL] =
          contextoFormulario?.examenEnVista?.estadoExamen !==
            estadosExamenesConst.CERRADO &&
          !contextoFormulario?.examenEnVista?.finalizado

        estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] =
          contextoFormulario?.examenEnVista?.estadoExamen !==
            estadosExamenesConst.CERRADO &&
          !contextoFormulario?.examenEnVista?.finalizado
      }
    }

    if (
      rolUsuario !== ROLES_APP.AUXILIAR_ENFERMERIA &&
      !estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] &&
      contextoFormulario?.examenEnVista?.estadoExamen !==
        estadosExamenesConst.CERRADO &&
      !contextoFormulario?.examenEnVista?.finalizado
    ) {
      estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] = true
    }
    if (
      !estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] &&
      state?.edicionHabilitada
    ) {
      estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] = true
    }

    return estadosBotones
  }

  useEffect(() => {
    let cancelado = false

    if (
      state.servicioAtencionId &&
      state.servicioId &&
      contextoFormulario?.examenEnVista?.servicioAtencionId
    ) {
      if (!cancelado) {
        datosExamenPorServicioAtencionId()
      }
    }
    return () => {
      cancelado = true
    }
  }, [contextoFormulario?.examenEnVista?.servicioAtencionId, tipoGuardado])

  const datosExamenPorServicioAtencionId = async () => {
    try {
      const respuesta = await ObtenerDatosExamenPorServicioAtencionId(
        state.servicioAtencionId
      )

      contextoFormulario.setEstadoExamen(respuesta.data.estadoServicioId)

      let estadosBotones = null
      let informacionPlantilla = null

      if (respuesta.status === 200) {
        contextoFormulario.setDatosAtencion({
          fechaActual: moment(respuesta.data.fechaCreacion).format(
            'DD/MM/YYYY'
          ),
          horaActual: moment(respuesta.data.fechaCreacion).format('LT'),
        })

        informacionPlantilla = await obtenerPlantillaPerServicioIdOPlantillaId(
          respuesta.data?.plantillaId ?? null
        )
        funcionSetearDatosExamenALosCampos(
          informacionPlantilla.plantilla,
          respuesta?.data?.datosExamen
        )
      } else {
        contextoFormulario.setDatosAtencion({
          fechaActual: moment().format('DD/MM/YYYY'),
          horaActual: moment().format('LT'),
        })

        informacionPlantilla =
          await obtenerPlantillaPerServicioIdServicioAtencionIdConDatos()
        funcionSetearDatosExamenALosCampos(
          informacionPlantilla.plantilla,
          informacionPlantilla.datosExamen
        )
      }

      estadosBotones = validacionesEstadosBotones(
        informacionPlantilla.plantilla,
        respuesta.status
      )

      contextoFormulario.setPlantilla(informacionPlantilla.plantilla)
      contextoFormulario.setEstadoHabilitadoBotones(estadosBotones)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (state.usuarioId && contextoFormulario.examenEnVista) {
      const obtenerDatosUsuario = async () => {
        const informacionPaciente = await ObtenerInformacionPaciente(
          state.usuarioId,
          true
        ).catch((error) => {
          console.log(error)
          return
        })
        const informacionAtencion =
          await ObtenerInformacionUltimaAtencionPorUsuarioId(
            state.usuarioId
          ).catch((error) => {
            console.log(error)
            return
          })
        const sexo = await ObtenerSexoPorId(
          informacionPaciente?.data?.sexoId ?? 0
        )
        contextoFormulario.setInformacionUsuario({
          urlFoto: informacionPaciente?.data?.urlFoto,
          datosUsuario: [
            {
              titulo: 'Nombre',
              contenido:
                (informacionPaciente?.data?.nombre ?? '') +
                ' ' +
                (informacionPaciente?.data?.apellidos ?? ''),
            },
            {
              titulo: 'Documento',
              contenido: informacionPaciente?.data?.documento ?? '',
            },
            {
              titulo: 'Fecha primera atención',
              contenido: informacionPaciente?.data?.fechaPrimeraAtencion
                ? moment(informacionPaciente.data.fechaPrimeraAtencion).format(
                    'DD / MM / YYYY'
                  )
                : '',
            },
            {
              titulo: 'Género',
              contenido: sexo?.Nombre ?? '',
            },
            {
              titulo: 'Fecha nacimiento',
              contenido: informacionPaciente?.data?.fechaNacimiento
                ? moment(informacionPaciente.data.fechaNacimiento).format(
                    'DD / MM / YYYY'
                  )
                : '',
            },
            {
              titulo: 'Edad',
              contenido: informacionPaciente?.data?.fechaNacimiento
                ? moment().diff(
                    informacionPaciente.data.fechaNacimiento,
                    'years'
                  )
                : '',
            },
            {
              titulo: 'Lugar de nacimiento',
              contenido: informacionPaciente?.data?.lugarNacimiento ?? '',
            },
            {
              titulo: 'Estrato',
              contenido: informacionPaciente?.data.direccion?.estratoId ?? '',
            },
            {
              titulo: 'Escolaridad',
              contenido:
                (
                  await ObtenerEscolaridadPorId(
                    informacionPaciente?.data?.escolaridadId ?? 0
                  )
                )?.Nombre ?? '',
            },
            {
              titulo: 'Estado civil',
              contenido:
                (
                  await ObtenerEstadoCivilPorId(
                    informacionPaciente.data.estadoCivilId ?? 0
                  )
                )?.Nombre ?? '',
            },
            {
              titulo: 'Nacionalidad',
              contenido: informacionPaciente?.data?.nacionalidad ?? '',
            },
            {
              titulo: 'Tipo examen',
              contenido:
                (
                  await ObtenerTipoExamenPorId(
                    informacionPaciente.data.tipoExamenId
                  )
                )?.Nombre ?? '',
            },
            {
              titulo: 'Empresa',
              contenido:
                (
                  await ObtenerEmpresaPorId(
                    informacionAtencion?.data?.empresaId
                  )
                )?.Nombre ?? '',
            },
            {
              titulo: 'Cargo',
              contenido: informacionPaciente?.data?.cargo ?? '',
            },
            {
              titulo: 'Actividad Económica',
              contenido: informacionPaciente?.data?.actividadEconomica ?? '',
            },
            {
              titulo: 'Sede',
              contenido:
                (await ObtenerSedePorId(informacionAtencion?.data?.sedeId))
                  ?.Nombre ?? '',
            },
            {
              titulo: 'Médico',
              contenido:
                contextoFormulario?.examenEnVista?.informacionUltimoGuardado
                  ?.nombreMedico ?? '',
            },
          ],
        })
      }
      obtenerDatosUsuario()
    }
  }, [contextoFormulario.examenEnVista])

  useEffect(() => {
    if (state.usuarioId) {
      ;(async () => {
        await AgregarServiciosACache().then(() =>
          obtenerHistoriaClinicaPaciente(state.usuarioId)
        )
      })()
    }
  }, [])

  useEffect(() => {
    if (contextoFormulario?.examenEnVista?.estadoExamen === 4) {
      const estadosBotones = {...contextoFormulario.estadoHabilitadoBotones}
      estadosBotones[nombreBotonesPlantilla.IMPRIMIRCERTIFICADO] = true
      estadosBotones[nombreBotonesPlantilla.CERRAREXAMEN] = false
      estadosBotones[nombreBotonesPlantilla.IMPRIMIREXAMEN] = true
      contextoFormulario.setEstadoHabilitadoBotones(estadosBotones)
    }
  }, [contextoFormulario?.examenEnVista])

  return (
    <div className="w-full flex flex-wrap justify-center">
      <div className="w-11/12 py-5">
        <BaseFormulario />
      </div>
    </div>
  )
}
