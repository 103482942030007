import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import FormularioAsignacionPlantillaAServicios from '../../../componentes/Administrador/MotorDePlantillas/FormularioAsignacionPlantillaAServicios'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {ContextApplication} from '../../../contexto'
import {AsignarServiciosPlantilla} from '../../../microservicios/Plantilla'

const AsignarPlantillaAServicio = ({}) => {
  const history = useHistory()
  const contextoAplicacion = useContext(ContextApplication)
  const [limpiarFormulario, setLimpiarFormulario] = useState(false)

  const cerrarAlerta = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const asignarServiciosAPlantilla = async (plantillaId, serviciosIds) => {
    if (!plantillaId || !serviciosIds?.length) {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: `${
          !plantillaId
            ? 'Debe seleccionar una plantilla para la asignación'
            : !serviciosIds?.length
            ? 'Debe seleccionar al menos un servicio para la asignación'
            : 'Todos los campos son requeridos'
        }  `,
        contenido: '',
        botones: [
          {
            nombre: 'Aceptar',
            click: () => {
              contextoAplicacion.setModal({
                abierto: false,
                titulo: '',
                botones: [],
              })
            },
          },
        ],
      })
    }

    await AsignarServiciosPlantilla(plantillaId, serviciosIds).then(
      (respuesta) => {
        if (respuesta.status === 200) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'Se ha realizado la asignación correctamente',
            contenido: '',
            botones: [
              {
                nombre: 'Finalizar',
                click: () => {
                  setLimpiarFormulario(true)
                  cerrarAlerta()
                  history.push('/')
                },
              },
              {
                nombre: 'Asignar nueva plantilla',
                click: () => {
                  setLimpiarFormulario(true)
                  cerrarAlerta()
                },
              },
              {
                nombre: 'Regresar Gestión asignar pl.',
                click: () => {
                  cerrarAlerta()
                  history.push('/gestion-asignacion-plantilla-a-servicio')
                },
              },
            ],
          })
        }
      }
    )
  }

  useEffect(() => {
    if (limpiarFormulario) {
      setTimeout(() => {
        setLimpiarFormulario(false)
      }, 1000)
    }
  }, [limpiarFormulario])

  return (
    <ContenedorPagina
      tituloPagina={'ASIGNAR PLANTILLA A SERVICIO'}
      clasesCssAdicionales={'w-full'}
    >
      <div className="flex w-full justify-center items-start flex-wrap">
        <div className="w-full flex flex-wrap justify-center items-center">
          <div className="w-11/12 py-10">
            <FormularioAsignacionPlantillaAServicios
              asignarServiciosAPlantilla={asignarServiciosAPlantilla}
              limpiarFormulario={limpiarFormulario}
            />
          </div>
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default AsignarPlantillaAServicio
