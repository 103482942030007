import {nombreBotonesPlantilla} from './plantillaBotones'

export const objetoPredeterminadoPlantilla = {
  pestanas: [],
  botones: [
    {
      nombreBoton: nombreBotonesPlantilla.GUARDARTOMADEDATOS,
      estadoBoton: false,
      botonId: 1,
    },
    {
      nombreBoton: nombreBotonesPlantilla.FORMULAMEDICA,
      estadoBoton: false,
      botonId: 2,
    },
    {
      nombreBoton: nombreBotonesPlantilla.FORMULAOPTOMETRICA,
      estadoBoton: false,
      botonId: 3,
    },
    {
      nombreBoton: nombreBotonesPlantilla.INTERCONSULTA,
      estadoBoton: false,
      botonId: 4,
    },
    {
      nombreBoton: nombreBotonesPlantilla.IMPRIMIRCERTIFICADO,
      estadoBoton: false,
      botonId: 5,
    },
    {
      nombreBoton: nombreBotonesPlantilla.IMPRIMIRRESULTADO,
      estadoBoton: false,
      botonId: 6,
    },
  ],
}

export const NO_APLICAR_PARSEO = [11]

export const FLAGS_PLUGINS_PESTANNAS_MOTOR = {
  RECOMENDACIONES: 'RECOMENDACIONES_MEDICAS',
}

export const ARRAY_BOTONES_SELECCION = [
  {
    nombreBoton: nombreBotonesPlantilla.GUARDARTOMADEDATOS,
    estadoBoton: true,
    botonId: 1,
  },
  {
    nombreBoton: nombreBotonesPlantilla.FORMULAMEDICA,
    estadoBoton: false,
    botonId: 2,
  },
  {
    nombreBoton: nombreBotonesPlantilla.FORMULAOPTOMETRICA,
    estadoBoton: false,
    botonId: 3,
  },
  {
    nombreBoton: nombreBotonesPlantilla.INTERCONSULTA,
    estadoBoton: false,
    botonId: 4,
  },
  {
    nombreBoton: nombreBotonesPlantilla.SOLICITUDVALORACION,
    estadoBoton: false,
    botonId: 5,
  },
  {
    nombreBoton: nombreBotonesPlantilla.INCAPACIDADMEDICA,
    estadoBoton: false,
    botonId: 6,
  },
  {
    nombreBoton: nombreBotonesPlantilla.IMPRIMIRCERTIFICADO,
    estadoBoton: false,
    botonId: 7,
  },
]
