import React from 'react'

const ConfirmacionEmpresaHijaCreadaOModificada = ({
  id,
  codigoInterno,
  nombreEmpresa,
}) => {
  return (
    <div className="w-full flex flex-wrap m-2">
      <span className="w-full text-cendiatra text-lg font-semibold">
        ID: {id}{' '}
      </span>
      <span className="w-full text-cendiatra text-lg font-semibold">
        CÓDIGO INTERNO EMPRESA HIJA: {codigoInterno}{' '}
      </span>
      <span className="w-full text-cendiatra text-lg font-semibold">
        NOMBRE EMPRESA HIJA: {nombreEmpresa}{' '}
      </span>
    </div>
  )
}
export default ConfirmacionEmpresaHijaCreadaOModificada
