import {useContext, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {BotonesFormularioPasos} from '../Botones'
import {ContextApplication, ContextoEmpresa} from '../../contexto'
import {Input} from '../inputs'
import {
  caracteresNoPermitidosB2c,
  caracteresNoPermitidosMail,
  regexCorreoElectronico,
} from '../../constantes'
import {
  ObtenerEmpresasPorIds,
  ObtenerTodasCiudades,
  ObtenerUsuarioPorNombreUsuarioB2c,
  ObtenerUsuariosPorCorreo,
} from '../../cache/servicios-cache'
import {ComponenteAlerta} from '../Visuales'
import {useLocation} from 'react-router-dom'

const eventosValidacion = ['pattern', 'maxLength', 'validate']

export default function FormularioUsuarioB2c({
  numPag,
  funcionSubmit,
  deshabilitado = false,
}) {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoEmpresa = useContext(ContextoEmpresa)
  const [correoExistente, setCorreoExistente] = useState(false)
  const [ciudades, setCiudades] = useState({})
  const location = useLocation()

  const {
    watch,
    control,
    trigger,
    getValues,
    formState: {errors, isValid},
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cargo: '',
      nombres: '',
      correo: '',
      userPrincipalName: '',
    },
  })

  const contieneCaracteresNoPermitidosMail = !caracteresNoPermitidosMail.test(
    watch('correo') ?? ''
  )

  const contieneCaracteresNoPermitidosB2c = !caracteresNoPermitidosB2c.test(
    watch('userPrincipalName') ?? ''
  )

  useEffect(() => {
    const obtenerInformacionCiudades = async () => {
      try {
        const informacionCiudades = await ObtenerTodasCiudades()
        setCiudades(
          informacionCiudades?.reduce((acc, ciudad) => {
            if (!acc[ciudad.Id]) acc[ciudad.Id] = ciudad.Nombre

            return acc
          }, {}) ?? {}
        )
      } catch (error) {
        console.log(error)
      }
    }

    obtenerInformacionCiudades()
  }, [])

  useEffect(() => {
    if (numPag === contextoEmpresa.numeroPagina && !deshabilitado) {
      trigger()
      contextoEmpresa.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0 || correoExistente
      )
    }
    contextoEmpresa.setFormularioUsuarioB2c(getValues())
  }, [
    contextoEmpresa.actualizar,
    isValid,
    contextoEmpresa.numeroPagina,
    correoExistente,
  ])

  useEffect(() => {
    const subscription = watch(() => {
      contextoEmpresa.setFormularioUsuarioB2c(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (deshabilitado) {
      reset(contextoEmpresa.formularioUsuarioB2c)
    }
    trigger()
  }, [contextoEmpresa.actualizar])

  const cerrarModal = () =>
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })

  const validarCorreoElectronico = async () => {
    if (!watch('correo')) return

    try {
      const usuariosCorreos = await ObtenerUsuariosPorCorreo(watch('correo'))

      if (!usuariosCorreos.length) return

      const empresasId = usuariosCorreos.map((usuario) => usuario.EmpresaId)
      const informacionEmpresas = await ObtenerEmpresasPorIds(empresasId)
      
      const infoEmpresaConCiudad =
        informacionEmpresas?.map((empresa) => {
          return {
            ciudad: ciudades[empresa.CiudadId] ?? '',
            nit: empresa.Nit,
            nombre: empresa.Nombre,
          }
        }) ?? []

      contextoAplicacion.setModal({
        abierto: true,
        titulo: informacionEmpresas?.length
          ? 'EL CORREO ELECTRÓNICO ESTÁ EN USO PARA:'
          : 'EL CORREO ELECTRÓNICO ESTÁ EN USO',
        contenido: (
          <div className="w-full flex flex-col gap-2">
            {informacionEmpresas.length ? (
              <div className="h-36 overflow-y-auto">
                {infoEmpresaConCiudad.map((empresa, key) => {
                  return (
                    <p
                      className="text-cendiatra-gris-1 py-2 border-b"
                      key={key}
                    >
                      {empresa.ciudad ? `${empresa.ciudad} /` : ''}{' '}
                      {empresa.nit} - {empresa.nombre}
                    </p>
                  )
                })}
              </div>
            ) : null}
            <p className="w-full">
              <p className="normal-case">
                ¿Desea asignar este correo a una nueva empresa para la creación
                y notificación del usuario?
              </p>
            </p>
          </div>
        ),
        botones: [
          {
            nombre: 'Si',
            click: () => {
              cerrarModal()
            },
          },
          {
            nombre: 'No',
            click: () => {
              setValue('correo', '')
              trigger('correo')
              cerrarModal()
            },
          },
        ],
        removerEspacios: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (watch('correo')?.length < 5 || location?.pathname?.includes('editar'))
      return

    const puedeValidarCorreo = async () => {
      if (await trigger('correo')) validarCorreoElectronico()
    }

    puedeValidarCorreo()
  }, [watch('correo')])

  const validarNombreUsuarioB2c = async () => {
    let usuarioDisponible = true
    try {
      const userPrincipalName = watch('userPrincipalName')
      if (caracteresNoPermitidosB2c.test(userPrincipalName)) {
        return 'Solo se permiten los siguientes caracteres: . - _'
      }

      const usuarioEnDb = await ObtenerUsuarioPorNombreUsuarioB2c(
        watch('userPrincipalName')
      )

      if (usuarioEnDb) {
        usuarioDisponible = false
      }
    } catch (error) {
      usuarioDisponible = false
    }

    return usuarioDisponible
  }

  return (
    <div className="w-full flex flex-wrap justify-center my-5">
      <div className="w-full">
        <div className="rounded-md space-y-4 flex justify-evenly flex-wrap items-center">
          <div className="w-full flex justify-start flex-wrap">
            <Controller
              name="cargo"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      error && !deshabilitado
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : `${
                            deshabilitado
                              ? 'bg-cendiatra-gris-2 text-white'
                              : 'text-cendiatra-gris-3'
                          } appearance-none rounded relative block w-full  p-1.5 border border-cendiatra  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Cargo*'}
                    valor={value}
                    deshabilitado={deshabilitado}
                  />
                </>
              )}
            />
          </div>
          <div className="w-full flex justify-start flex-wrap">
            <Controller
              name="nombres"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      error && !deshabilitado
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : `${
                            deshabilitado
                              ? 'bg-cendiatra-gris-2 text-white'
                              : 'text-cendiatra-gris-3'
                          } appearance-none rounded relative block w-full  p-1.5 border border-cendiatra  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Nombres y Apellidos*'}
                    valor={value}
                    deshabilitado={deshabilitado}
                  />
                </>
              )}
            />
          </div>
          <div className="w-full flex space-x-4">
            <div className="w-6/12 flex justify-start flex-wrap">
              <Controller
                name="correo"
                control={control}
                rules={{
                  required: true,
                  required: 'Usuario es un campo requerido',
                  pattern: {
                    value: regexCorreoElectronico,
                    message: 'Descripción correcta: ejemplo@ejemplo.com',
                  },
                  maxLength: {
                    value: 64,
                    message: 'Excedió el límite de 64 caracteres',
                  },
                }}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                  <>
                    <Input
                      onChange={(evt) => {
                        onChange(evt)
                      }}
                      estilosContenedor={'w-full'}
                      estilosInput={
                        (error || correoExistente) && !deshabilitado
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : `${
                              deshabilitado
                                ? 'bg-cendiatra-gris-2 text-white'
                                : 'text-cendiatra-gris-3'
                            } appearance-none rounded relative block w-full  p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
                      }
                      tipo={'text'}
                      placeholder={'Descripción'}
                      titulo={'Usuario (correo electrónico)*'}
                      valor={value}
                      deshabilitado={deshabilitado}
                    />
                  </>
                )}
              />
            </div>
            <div className="w-6/12 flex justify-right flex-wrap">
              <Controller
                name="userPrincipalName"
                control={control}
                rules={{
                  required: true,
                  validate: validarNombreUsuarioB2c,
                  maxLength: {
                    value: 32,
                    message: 'Excedió el límite de 32 caracteres',
                  },
                }}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                  <>
                    <Input
                      onChange={onChange}
                      estilosContenedor={'w-full'}
                      estilosInput={
                        error && !deshabilitado
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : `${
                              deshabilitado
                                ? 'bg-cendiatra-gris-2 text-white'
                                : 'text-cendiatra-gris-3'
                            } appearance-none rounded relative block w-full  p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
                      }
                      tipo={'text'}
                      placeholder={'Descripción'}
                      titulo={'Nombre de usuario B2C*'}
                      valor={value}
                      deshabilitado={deshabilitado}
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex space-x-4">
          <div className="w-6/12">
            {(eventosValidacion.includes(errors.correo?.type ?? '') &&
              errors.correo?.message) ||
            correoExistente ? (
              <ComponenteAlerta
                mensaje={
                  contieneCaracteresNoPermitidosMail
                    ? correoExistente
                      ? 'El correo ya se encuentra creado'
                      : errors?.correo?.message ?? ''
                    : 'Solo se permiten los siguientes caracteres: . - _'
                }
              />
            ) : (
              ''
            )}
          </div>
          <div className="w-6/12">
            {eventosValidacion.includes(errors.userPrincipalName?.type ?? '') &&
            (errors.userPrincipalName?.message ||
              errors.userPrincipalName?.type === 'validate') ? (
              <ComponenteAlerta
                mensaje={
                  contieneCaracteresNoPermitidosB2c
                    ? errors.userPrincipalName?.type === 'validate'
                      ? 'El usuario ya se encuentra creado'
                      : errors?.userPrincipalName?.message ?? ''
                    : 'Solo se permiten los siguientes caracteres: . - _'
                }
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div className="pt-6 w-full flex justify-center items-center">
        <BotonesFormularioPasos
          contexto={contextoEmpresa}
          clickGuardar={funcionSubmit}
          textoBotonUltimaPaginaGerenteGeneral={'Regresar a ver empresa'}
          fnUltimaPaginaGerenteGeneral={() => {
            contextoEmpresa.setIdEmpresa(undefined)
          }}
        />
      </div>
    </div>
  )
}
