import React, {useState, createContext} from 'react'

export const ContextoLlamarPaciente = createContext({})

export const LlamarPacienteConContexto = ({children}) => {
  const [listaTurnos, setListaTurnos] = useState([])
  const [filasLlamadas, setFilasLlamadas] = useState([])
  const [turnoIdAtendiendo, setTurnoIdAtendiendo] = useState()

  return (
    <ContextoLlamarPaciente.Provider
      value={{
        listaTurnos: listaTurnos,
        setListaTurnos: setListaTurnos,
        filasLlamadas: filasLlamadas,
        setFilasLlamadas: setFilasLlamadas,
        turnoIdAtendiendo: turnoIdAtendiendo,
        setTurnoIdAtendiendo: setTurnoIdAtendiendo,
      }}
    >
      {children}
    </ContextoLlamarPaciente.Provider>
  )
}
