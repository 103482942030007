import {createContext, useState} from 'react'

export const ContextoGestionOtrosServicios = createContext('')

export const GestionOtrosServiciosConContexto = ({children}) => {
  const [datosServicios, setDatosServicios] = useState([])
  const [serviciosConErrores, setServiciosConErrores] = useState([])
  const [serviciosNuevos, setServiciosNuevos] = useState([])
  const [serviciosAEliminar, setServiciosAEliminar] = useState([])
  const [serviciosModificados, setServiciosModificados] = useState([])
  const [actualizarRangos, setActualizarRangos] = useState(false)

  return (
    <ContextoGestionOtrosServicios.Provider
      value={{
        serviciosConErrores,
        setServiciosConErrores,
        datosServicios,
        setDatosServicios,
        serviciosNuevos,
        setServiciosNuevos,
        serviciosAEliminar,
        setServiciosAEliminar,
        serviciosModificados,
        setServiciosModificados,
        actualizarRangos,
        setActualizarRangos,
      }}
    >
      {children}
    </ContextoGestionOtrosServicios.Provider>
  )
}
