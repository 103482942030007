import React from 'react'

export const CeldaDobleTexto = ({
  datos,
  color,
  fila1Color = 'text-cendiatra-gris-1',
}) => {
  return (
    <div className="w-full flex flex-wrap justify-evenly items-center">
      <span className={`w-full flex justify-evenly items-center ${fila1Color}`}>
        {datos.Fila1}
      </span>
      <span
        className={`w-full flex justify-evenly items-center ${
          color ? 'text-cendiatra-verde-4' : ''
        }`}
      >
        {datos.Fila2}
      </span>
    </div>
  )
}

export default CeldaDobleTexto