import React from 'react'
import {NumericFormat} from 'react-number-format'

const InputNumerico = ({
  onValueChange,
  estilosContenedor,
  estilosInput,
  placeholder,
  titulo,
  deshabilitado,
  valor,
  formatoVacio,
  onFocus,
  ayudaDeTexto,
  usarDecimal = true,
  readOnly = false,
  deshabilitarTab = false,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      {titulo ? (
        <label className="block text-13px text-cendiatra my-5px">
          {titulo}
        </label>
      ) : null}
      <NumericFormat
        thousandSeparator={usarDecimal ? '.' : ''}
        decimalSeparator={','}
        decimalScale={usarDecimal ? 2 : 0}
        fixedDecimalScale={true}
        value={valor}
        defaultValue={0}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.source == 'event') {
            onValueChange(values)
          }
        }}
        className={estilosInput}
        placeholder={placeholder}
        disabled={deshabilitado}
        allowEmptyFormatting={formatoVacio}
        onFocus={onFocus}
        readOnly={readOnly}
        tabIndex={deshabilitarTab ? -1 : 0}
      />
      {ayudaDeTexto ? (
        <label className="block text-13px text-cendiatra my-5px">
          {ayudaDeTexto}
        </label>
      ) : null}
    </div>
  )
}

export default InputNumerico
