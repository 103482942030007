import React from 'react'
import {useTable} from 'react-table'

export default function TablaPrecios({columns, data, titulo, actualizarDatos}) {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      columns,
      data,
      actualizarDatos,
      titulo,
    })

  const indexCentral = Math.ceil(rows.length / 2)
  const primerSetDatos = rows.slice(0, indexCentral)
  const segundoSetDatos = rows.slice(indexCentral)

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full flex">
        <table
          {...getTableProps()}
          className="w-1/2 flex flex-wrap items-start"
        >
          <thead className="flex flex-wrap w-full">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="w-full flex justify-evenly items-center"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className:
                          column.className +
                          ' text-center text-sm text-cendiatra items-center justify-evenly font-bold h-14 flex',
                      },
                    ])}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="w-full flex flex-wrap justify-evenly items-center mb-5"
          >
            {primerSetDatos.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  className="w-full flex justify-evenly items-center my-1"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className:
                              cell.column.className +
                              ' text-center text-sm text-cendiatra items-center justify-center font-bold h-14 flex',
                          },
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <table
          {...getTableProps()}
          className="w-1/2 flex flex-wrap items-start"
        >
          {segundoSetDatos.length > 0 && (
            <thead className="flex flex-wrap w-full">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="w-full flex justify-evenly items-center"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        {
                          className:
                            column.className +
                            ' text-center text-sm text-cendiatra items-center justify-evenly font-bold h-14 flex',
                        },
                      ])}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          {segundoSetDatos.length > 0 && (
            <tbody
              {...getTableBodyProps()}
              className="w-full flex flex-wrap mb-5"
            >
              {segundoSetDatos.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className="w-full flex justify-evenly items-center my-1"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className:
                                cell.column.className +
                                ' text-center text-sm text-cendiatra items-center justify-evenly font-bold h-14 flex',
                            },
                          ])}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  )
}
