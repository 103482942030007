export const casosAtencionEjecutada = {
  EN_PROCESO: 'enProceso',
  BLOQUEO: 'bloqueo',
  MODIFICACION: 'modificacion',
  MODIFICACION_EMPRESA_CARGO: 'Modifcacion empresa/cargo',
  MODIFICACION_COMPLETA: 'Modificación completa',
  MOTIVO: 'motivo',
  CANCELAR: 'Cancelación',
}

export const estadosAtencionEjecutada = {
  EN_PROCESO_MEDICO: 2,
  EN_PROCESO_MODIFICACION: 4,
  EN_PROCESO_CANCELACION: 5,
}

export const motivosModificacion = {
  MODIFICACION_COMPLETA: 1,
  MODIFICACION_EMPRESA_CARGO: 2,
  CANCELACION: 3,
}
