import React, {useState, createContext} from 'react'

export const ContextoMedicoOcupacional = createContext({})

export const MedicoOcupacionalConContexto = ({children}) => {
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(13)
  const [informacionUsuario, setInformacionUsuario] = useState({
    urlFoto: '',
    datosUsuario: [],
  })
  const [listaServiciosHistoriaClinica, setListaServiciosHistoriaClinica] =
    useState([])
  const [estadoBotones, setEstadoBotones] = useState({
    tomaDatos: false,
    guardadoParcial: true,
    cerrarHistoria: false,
    imprimirCertificado: false,
    imprimirHistoria: false,
  })
  const [listaAntecedentes, setListaAntecedentes] = useState([])
  const [antecedentesGinecobstetricos, setAntecedentesGinecobstetricos] =
    useState({})
  const [listaAntecedentesToxicologicos, setListaAntecedentesToxicologicos] =
    useState([])
  const [formularioActualTieneErrores, setformularioActualTieneErrores] =
    useState(true)
  const [datosEmpresa, setDatosEmpresa] = useState({turnoTrabajo: ''})
  const [estadoDeSaludActual, setEstadoDeSaludActual] = useState({
    sintomatologia: '',
  })
  const [examenesParaclinicos, setExamenesParaclinicos] = useState('')
  const [diagnosticos, setDiagnosticos] = useState({
    diagnostico1: '',
    diagnostico2: '',
    diagnostico3: '',
    diagnostico4: '',
    diagnostico5: '',
  })
  const [examenFisico, setExamenFisico] = useState({
    peso: '',
    talla: '',
    fc: '',
    ta: '',
    hallazgos: '',
  })
  const [listaTinnelYPhalen, setListaTinnelYPhalen] = useState({
    tinnelDerecho: 0,
    tinnelIzquierdo: 0,
    phalenDerecho: 0,
    phalenIzquierdo: 0,
  })
  const [listaRiesgosFisicos, setListaRiesgosFisicos] = useState([])
  const [listaRiesgosBiologicos, setListaRiesgosBiologicos] = useState([])
  const [listaRiesgosErgonomicos, setListaRiesgosErgonomicos] = useState([])
  const [listaRiesgosLocativos, setListaRiesgosLocativos] = useState([])
  const [listaRiesgosNaturales, setListaRiesgosNaturales] = useState([])
  const [listaRiesgosSeguridad, setListaRiesgosSeguridad] = useState([])
  const [listaOtrosRiesgos, setListaOtrosRiesgos] = useState([])
  const [riesgosOcupacionales, setRiesgosOcupacionales] = useState({})
  const [conceptoAptitud, setConceptoAptitud] = useState({})
  const [riesgoSeleccionado, setRiesgoSeleccionado] = useState(false)
  const [primerEmpleo, setPrimerEmpleo] = useState(false)
  const [listaRecomendaciones, setListaRecomendaciones] = useState([])
  const [listaVigilanciasEpidemiologicas, setListaVigilanciasEpidemiologicas] =
    useState([])
  const [listaRestricciones, setListaRestricciones] = useState([])
  const [
    observacionesAntecedentesToxicologicos,
    setObservacionesAntecedentesToxicologicos,
  ] = useState('')
  const [
    observacionesAntecedentesPersonales,
    setObservacionesAntecedentesPersonales,
  ] = useState('')
  const [actualizar, setActualizar] = useState(false)
  const [listaOrganos, setListaOrganos] = useState([])
  const [habilitarImprimirCertificado, setHabilitarImprimirCertificado] =
    useState(false)

  return (
    <ContextoMedicoOcupacional.Provider
      value={{
        informacionUsuario: informacionUsuario,
        setInformacionUsuario: setInformacionUsuario,
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
        listaServiciosHistoriaClinica: listaServiciosHistoriaClinica,
        setListaServiciosHistoriaClinica: setListaServiciosHistoriaClinica,
        estadoBotones: estadoBotones,
        setEstadoBotones: setEstadoBotones,
        listaAntecedentes: listaAntecedentes,
        setListaAntecedentes: setListaAntecedentes,
        antecedentesGinecobstetricos: antecedentesGinecobstetricos,
        setAntecedentesGinecobstetricos: setAntecedentesGinecobstetricos,
        listaAntecedentesToxicologicos: listaAntecedentesToxicologicos,
        setListaAntecedentesToxicologicos: setListaAntecedentesToxicologicos,
        datosEmpresa: datosEmpresa,
        setDatosEmpresa: setDatosEmpresa,
        formularioActualTieneErrores: formularioActualTieneErrores,
        setformularioActualTieneErrores: setformularioActualTieneErrores,
        estadoDeSaludActual: estadoDeSaludActual,
        setEstadoDeSaludActual: setEstadoDeSaludActual,
        examenesParaclinicos: examenesParaclinicos,
        setExamenesParaclinicos: setExamenesParaclinicos,
        diagnosticos: diagnosticos,
        setDiagnosticos: setDiagnosticos,
        examenFisico: examenFisico,
        setExamenFisico: setExamenFisico,
        listaTinnelYPhalen: listaTinnelYPhalen,
        setListaTinnelYPhalen: setListaTinnelYPhalen,
        listaRiesgosFisicos: listaRiesgosFisicos,
        setListaRiesgosFisicos: setListaRiesgosFisicos,
        listaRiesgosBiologicos: listaRiesgosBiologicos,
        setListaRiesgosBiologicos: setListaRiesgosBiologicos,
        listaRiesgosErgonomicos: listaRiesgosErgonomicos,
        setListaRiesgosErgonomicos: setListaRiesgosErgonomicos,
        listaRiesgosLocativos: listaRiesgosLocativos,
        setListaRiesgosLocativos: setListaRiesgosLocativos,
        listaRiesgosNaturales: listaRiesgosNaturales,
        setListaRiesgosNaturales: setListaRiesgosNaturales,
        listaRiesgosSeguridad: listaRiesgosSeguridad,
        setListaRiesgosSeguridad: setListaRiesgosSeguridad,
        listaOtrosRiesgos: listaOtrosRiesgos,
        setListaOtrosRiesgos: setListaOtrosRiesgos,
        riesgosOcupacionales: riesgosOcupacionales,
        setRiesgosOcupacionales: setRiesgosOcupacionales,
        conceptoAptitud: conceptoAptitud,
        setConceptoAptitud: setConceptoAptitud,
        riesgoSeleccionado: riesgoSeleccionado,
        setRiesgoSeleccionado: setRiesgoSeleccionado,
        primerEmpleo: primerEmpleo,
        setPrimerEmpleo: setPrimerEmpleo,
        listaRecomendaciones: listaRecomendaciones,
        setListaRecomendaciones: setListaRecomendaciones,
        listaVigilanciasEpidemiologicas: listaVigilanciasEpidemiologicas,
        setListaVigilanciasEpidemiologicas: setListaVigilanciasEpidemiologicas,
        listaRestricciones: listaRestricciones,
        setListaRestricciones: setListaRestricciones,
        observacionesAntecedentesToxicologicos:
          observacionesAntecedentesToxicologicos,
        setObservacionesAntecedentesToxicologicos:
          setObservacionesAntecedentesToxicologicos,
        observacionesAntecedentesPersonales:
          observacionesAntecedentesPersonales,
        setObservacionesAntecedentesPersonales:
          setObservacionesAntecedentesPersonales,
        actualizar: actualizar,
        setActualizar: setActualizar,
        listaOrganos: listaOrganos,
        setListaOrganos: setListaOrganos,
        habilitarImprimirCertificado: habilitarImprimirCertificado,
        setHabilitarImprimirCertificado: setHabilitarImprimirCertificado,
      }}
    >
      {children}
    </ContextoMedicoOcupacional.Provider>
  )
}
