import React, {useState} from 'react'
import Boton from '../Botones/Boton'
import {useForm, Controller} from 'react-hook-form'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import Input from './Input'

const InputFiltroConBotones = ({titulo, funcionBuscar, funcionLimpiar}) => {
  const [valorInput, setValorInput] = useState('')
  const limpiarInput = () => {
    setValorInput('')
    if (funcionLimpiar) {
      funcionLimpiar()
    }
  }
  return (
    <div className={`flex justify-center items-center flex-wrap`}>
      <Input
        titulo={titulo}
        onChange={(e) => setValorInput(e.target.value)}
        estilosContenedor={'w-6/12 mr-5 mb-7'}
        estilosInput={
          'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
        }
        placeholder={'DESCRIPCIÓN'}
        tipo={'text'}
        valor={valorInput}
      />

      <Boton
        titulo="Buscar"
        habilitado={valorInput}
        colorPorDefecto={'bg-grayColor'}
        colorActivo={'bg-btnBg'}
        tipo={'button'}
        funcionCLick={() => funcionBuscar(valorInput)}
        alto={'h-9'}
      />

      <Boton
        titulo="Limpiar"
        habilitado={valorInput}
        funcionCLick={limpiarInput}
        colorPorDefecto={'bg-grayColor'}
        colorActivo={'bg-btnBg'}
        tipo={'button'}
        alto={'h-9'}
      />
    </div>
  )
}

export default InputFiltroConBotones
