import React, {useContext, useState, useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useMsal} from '@azure/msal-react'
import Boton from '../Botones/Boton'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {ListaDesplegable} from '../inputs'
import moment from 'moment'
import userImg from '../../imagenes/usuarioImg.png'
import telefono from '../../imagenes/telefono.svg'
import ubicacion from '../../imagenes/ubicacion.svg'
import informacion from '../../imagenes/informacion.svg'
import {ContextApplication} from '../../contexto'
import {
  ObtenerSedesPorCiudadId,
  ObtenerCiudadPorNombreSede,
  ObtenerSedePorId,
} from '../../cache/servicios-cache'
import {fnAsignarInformacionSessionOLocalStorage} from '../../utilidades/window.utils'

const FormularioSeleccionSede = ({textoDinamico, objetoDinamico}) => {
  const [listaSedes, setListaSedes] = useState([])
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([])
  const [listaDatos, setListaDinamica] = useState([])

  let fechaActual = moment().format('DD/MM/YYYY')
  let horaActual = moment().format('LT')
  const {accounts} = useMsal()

  const nombreUsuario = accounts[0]?.name ?? ''
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const contextoAplicacion = useContext(ContextApplication)
  const {
    trigger,
    setValue,
    control,
    handleSubmit,
    formState: {errors, isValid},
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [isValid])

  const guardarSede = (data) => {
    if (textoDinamico === 'Consultorio') {
      contextoAplicacion.setSede({
        ...contextoAplicacion.sede,
        sedeId: data.sede,
        consultorioId: data.inputDinamico,
      })
      fnAsignarInformacionSessionOLocalStorage(
        {
          key: 'consultorioId',
          valor: data.inputDinamico,
        },
        rol
      )
    } else {
      contextoAplicacion.setSede({
        ...contextoAplicacion.sede,
        sedeId: data.sede,
        recepcionId: data.inputDinamico,
      })
    }
    fnAsignarInformacionSessionOLocalStorage(
      {
        key:
          textoDinamico?.toUpperCase() === 'CONSULTORIO'
            ? 'consultorioId'
            : 'recepcionId',
        valor: data.inputDinamico,
      },
      rol
    )
    fnAsignarInformacionSessionOLocalStorage(
      {key: 'sedeId', valor: data.sede},
      rol
    )
  }

  const manejarCiudadCambiada = (e) => {
    contextoAplicacion.setSede({
      ...contextoAplicacion.sede,
      ciudad: e.target.value,
    })
    setError('ciudad', {type: 'require', message: ''})
    setValue('ciudad')
    if (e.target.value.length >= 3) {
      ObtenerCiudadPorNombreSede(e.target.value).then((res) => {
        if (res) {
          const ids = res.map((ciudad) => ciudad.CiudadId)
          const filtered = res.filter(
            ({CiudadId}, index) => !ids.includes(CiudadId, index + 1)
          )
          setCiudadesFiltradas(
            filtered.map((ciudad) => ({
              Id: ciudad.CiudadId,
              filtro: ciudad.Ciudad,
            }))
          )
        }
        setValue('inputDinamico')
        setValue('sede')
        setError('inputDinamico', {type: 'require', message: ''})
        setError('sede', {type: 'require', message: ''})
      })
    } else {
      setCiudadesFiltradas([])
      setListaSedes([])
      contextoAplicacion.setSede({
        sedeId: '',
        recepcionId: '',
        consultorioId: '',
        nombreSede: '',
        telefono: '',
        direccion: '',
        urlLogo: '',
      })
      setValue('inputDinamico', '')
      setValue('sede')
      setError('inputDinamico', {type: 'require', message: ''})
      setError('sede', {type: 'require', message: ''})
    }
  }
  const manejarCiudadSeleccionada = (ciudadSeleccionada) => {
    contextoAplicacion.setSede({
      ...contextoAplicacion.sede,
      ciudad: ciudadSeleccionada.filtro,
    })
    fnAsignarInformacionSessionOLocalStorage(
      {
        key: 'nombreCiudad',
        valor: ciudadSeleccionada.filtro,
      },
      rol
    )
    fnAsignarInformacionSessionOLocalStorage(
      {
        key: 'idCiudad',
        valor: ciudadSeleccionada.Id,
      },
      rol
    )

    setValue('ciudad', ciudadSeleccionada.filtro)
    clearErrors('ciudad')
    setCiudadesFiltradas([])
    ObtenerSedesPorCiudadId(ciudadSeleccionada.Id).then((res) => {
      setListaSedes(res)
    })
  }

  const manejarSedeSeleccionada = (idSede) => {
    if (idSede == '') {
      setListaDinamica([])
      contextoAplicacion.setSede({
        sedeId: '',
        recepcionId: '',
        consultorioId: '',
        nombreSede: '',
        telefono: '',
        direccion: '',
        urlLogo: '',
      })
      setValue('sede')
      setValue('inputDinamico')
      setError('inputDinamico', {type: 'require', message: ''})
      setError('sede', {type: 'require', message: ''})
    } else {
      ObtenerSedePorId(JSON.parse(idSede)).then((res) => {
        if (textoDinamico) {
          setListaDinamica(
            res[objetoDinamico].map((elemento) => {
              return {
                Id: elemento.id,
                Nombre: elemento.nombre,
              }
            })
          )
        }
        contextoAplicacion.setSede({
          ...contextoAplicacion.sede,
          nombreSede: res.Nombre,
          telefono: res.Telefono,
          direccion: res.Direccion,
          urlLogo: res.UrlLogo,
        })
      })
      setValue('inputDinamico')
      setError('inputDinamico', {type: 'require', message: ''})
      setError('sede', {type: 'require', message: ''})
    }
  }

  const validarInpitDinamico = (valor) => {
    if (valor == '') {
      setValue('inputDinamico', '')
    }
  }

  return (
    <div className="min-h-screen flex flex-wrap items-center justify-center py-12  sm:px-6 lg:px-8 bg-AppBg bg-cover">
      <form
        className="flex justify-center items-start flex-wrap w-full"
        onSubmit={handleSubmit(guardarSede)}
      >
        <div className="w-full flex flex-wrap justify-center mt-10 mb-10">
          <h3 className="  text-center text-3xl font-bold text-cendiatra">
            SELECCIONAR SEDE
          </h3>
        </div>

        <div className="bg-white w-8/12 p-2  flex flex-wrap justify-center items-center rounded rounded-t-3xl rounded-b-3xl border  border-opacity-50 border-cendiatra shadow-2xl">
          <div className="w-6/12 flex flex-wrap justify-center items-center pl-5">
            <div className="w-full flex justify-center items-center">
              <div className="w-3/12 pl-5">
                <img className="w-10/12" src={userImg} alt="userImg" />
              </div>
              <div className="w-9/12 flex flex-wrap justify-start items-center">
                <span className="w-full text-cendiatra text-center text-xl font-bold">
                  BIENVENIDO : {nombreUsuario}
                </span>
                <span className="w-full text-cendiatra text-center text-md font-bold">
                  Por seguridad de la información
                </span>
                <span className="w-full text-cendiatra text-center text-md font-bold">
                  desde este momento estará siendo monitoreado
                </span>
              </div>
            </div>
            <div className="w-full flex justify-center items-center my-8">
              <div className="w-4/12 flex justify-center items-center">
                <span className="w-full text-cendiatra text-start text-xl font-bold pl-10">
                  Ciudad*
                </span>
              </div>
              <div className="w-8/12 flex justify-center items-center">
                <Controller
                  name="ciudad"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={() => (
                    <CampoFiltrarEspañol
                      titulo={''}
                      estilosPersonalizados={'w-11/12'}
                      estilosInput={
                        Object.keys(errors).find(
                          (element) => element === 'ciudad'
                        )
                          ? 'appearance-none rounded relative block w-full   border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 '
                          : 'appearance-none rounded relative block w-full   border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 '
                      }
                      placeholder={'Autocompletar'}
                      tipo={'text'}
                      valorDelCampoFiltro={contextoAplicacion.sede.ciudad}
                      desactivarOtroFiltro={''}
                      informacionFiltrada={ciudadesFiltradas}
                      handleChange={manejarCiudadCambiada}
                      handleOptionChange={manejarCiudadSeleccionada}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-center items-center my-8">
              <div className="w-4/12 flex justify-center items-center">
                <span className="w-full text-cendiatra text-start text-xl font-bold pl-10">
                  Sede*
                </span>
              </div>
              <div className="w-8/12 flex justify-center items-center">
                <Controller
                  name="sede"
                  control={control}
                  rules={{
                    required: true,
                    onChange: (e) => manejarSedeSeleccionada(e.target.value),
                  }}
                  render={({field: {onChange, value}}) => (
                    <ListaDesplegable
                      onChange={onChange}
                      estilosContenedor={'w-11/12'}
                      estilosLista={
                        Object.keys(errors).find(
                          (element) => element === 'sede'
                        )
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      titulo={''}
                      opciones={listaSedes}
                      valor={value}
                      deshabilitado={
                        contextoAplicacion.sede.ciudad == '' ? true : false
                      }
                      esRegenerar={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-full justify-center flex items-center my-8  pb-20 h-20">
              <div
                className={`w-full justify-center items-center mt-10  ${
                  textoDinamico ? 'flex' : 'hidden'
                }`}
              >
                <div className="w-4/12 flex justify-center items-center">
                  <span className="w-full text-cendiatra text-start text-xl font-bold pl-10">
                    {textoDinamico + '*'}
                  </span>
                </div>
                <div className="w-8/12 flex justify-center items-center">
                  <Controller
                    name="inputDinamico"
                    control={control}
                    rules={{
                      required: Boolean(textoDinamico),
                      onChange: (e) => validarInpitDinamico(e.target.value),
                    }}
                    render={({field: {onChange, value}}) => (
                      <ListaDesplegable
                        onChange={onChange}
                        estilosContenedor={'w-11/12'}
                        estilosLista={
                          Object.keys(errors).find(
                            (element) => element === 'inputDinamico'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-placeholder  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        titulo={''}
                        opciones={listaDatos}
                        valor={value}
                        deshabilitado={
                          contextoAplicacion.sede.urlLogo == '' ? true : false
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              contextoAplicacion.sede.urlLogo == ''
                ? 'w-6/12 flex flex-wrap justify-center items-start h-450'
                : 'w-6/12 flex flex-wrap justify-center items-center'
            }
          >
            <div className="w-full flex flex-wrap justify-center items-start ">
              <span className="w-full text-cendiatra text-center text-md ">
                Fecha: {fechaActual}
              </span>
              <span className="w-full text-cendiatra text-center text-md ">
                Hora de ingreso al sistema: {horaActual}
              </span>
            </div>
            <div
              className={
                contextoAplicacion.sede.urlLogo == ''
                  ? 'hidden'
                  : 'w-7/12 flex justify-center items-center my-10 flex-wrap border border-gray-200 rounded-lg  shadow-2xl'
              }
            >
              <div
                className="w-full h-48 bg-gray-200 rounded-lg bg-cover bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${contextoAplicacion.sede.urlLogo})`,
                }}
              ></div>
              <div className="w-10/12 rounded-full -mt-5 bg-cendiatra p-2 text-white text-center text-lg  ">
                {contextoAplicacion.sede.nombreSede}
              </div>
              <div className="w-full flex flex-wrap justify-between items-start mt-5">
                <div className="w-2/12 flex justify-end">
                  <img src={ubicacion} alt="ubicacion" />
                </div>
                <span className="w-9/12 flex justify-start text-center text-xs my-2">
                  {contextoAplicacion.sede.direccion}
                </span>
              </div>
              <div className="w-full flex flex-wrap justify-between items-start my-2">
                <div className="w-2/12 flex justify-end items-start mt-2">
                  <img src={telefono} alt="ubicacion" />
                </div>
                <span className="w-9/12 flex justify-start  text-xs my-2">
                  {contextoAplicacion.sede.telefono}
                </span>
                <div className="w-full flex justify-center my-5">
                  <img src={informacion} alt="ubicacion" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <Boton
              titulo={'Guardar'}
              lineaPrimerCaracter={false}
              funcionCLick={null}
              habilitado={isValid}
              colorPorDefecto={'bg-cendiatra-gris-placeholder'}
              colorActivo={'bg-cendiatra'}
              tipo={'submit'}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default FormularioSeleccionSede
