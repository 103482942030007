import React, {useContext, useEffect, useCallback} from 'react'
import FormularioTarifario from '../../componentes/Tarifario/FormularioTarifario'
import {ContextoTarifario, ContextApplication} from '../../contexto'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import Pestana from '../../componentes/Pestanas/Pestana'
import TablaAliados from '../../componentes/Tablas/TablaAliados'
import TablaSedes from '../../componentes/Tablas/TablaSedes'
import FormularioPrecios from '../../componentes/Formularios/FormularioPrecios'
import SeleccionTarifario from '../../componentes/Tarifario/SeleccionTarifario'
import {AgregarEmpresasACache} from '../../cache/servicios-cache/Empresas'
import {AgregarTarifariosACache} from '../../cache/servicios-cache/Tarifarios'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import {AgregarPaquetesACache} from '../../cache/servicios-cache/Paquetes'
import {useGuardarTarifario} from '../../hooks/tarifarios/useGuardarTarifario'
import InputSeleccionarPortafolio from '../../componentes/Tarifario/InputSeleccionarPortafolio'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {UseObtenerRolValido} from '../../utilidades/funcionesComunes'

const EditarTarifario = () => {
  const contextoTarifario = useContext(ContextoTarifario)
  const contextoApp = useContext(ContextApplication)
  const [manejarGuardar] = useGuardarTarifario(false)

  const obtenerInformacionInicial = useCallback(async () => {
    await AgregarEmpresasACache()
    await AgregarTarifariosACache()
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
  }, [])

  useEffect(() => {
    contextoTarifario.setTotalPaginas(5)
    obtenerInformacionInicial().catch(console.error)
    contextoTarifario.setNumeroPagina(1)
    contextoTarifario.setModoEdicion(true)
    contextoTarifario.setValidacionActivarBotonTarifario(false)
  }, [obtenerInformacionInicial])

  const listaPestanas = [
    'Seleccionar Tarifario',
    'Seleccionar Empresa',
    'Seleccionar Sedes',
    'Seleccionar Aliados',
    'Asignar Precios y Alias',
  ]

  const usuarioSeguroDeCambios = () => {
    contextoTarifario.setPortafolioSeleccionadoId('')
  }

  useEffect(() => {
    if (contextoTarifario.seguroDeCambio) {
      contextoTarifario.setSeguroDeCambio(false)
      contextoApp.setModal({
        abierto: true,
        titulo: 'ESTÁ MODIFICANDO DATOS DEL PORTAFOLIO',
        contenido: '¿ESTÁ SEGURO DE REALIZARLO?',
        botones: [
          {
            nombre: 'Si',
            click: () => {
              usuarioSeguroDeCambios()
              contextoApp.cerrarAppModal()
            },
          },
          {
            nombre: 'No',
            click: contextoApp.cerrarAppModal,
          },
        ],
      })
    }
  }, [contextoTarifario.seguroDeCambio])

  useEffect(() => {
    const validarExistenciaListaSinValor = () => {
      const existenListas = contextoTarifario?.arregloMomentoActivacion.find(
        (elemento) => !elemento?.tipoCambioSedes && !elemento?.tipoCambioAliados
      )
      contextoTarifario.setValidacionActivarBotonTarifario(
        Boolean(existenListas)
      )
    }
    validarExistenciaListaSinValor()
  }, [contextoTarifario?.arregloMomentoActivacion])

  const rolValido = UseObtenerRolValido()

  const claseBloqueo = rolValido ? 'pointer-events-none' : ''

  const btnRegresar = document.getElementById('btn-regresar-gg')

  const clicEjecutarBtnRegresar = () => {
    contextoTarifario.setFormularioEmpresa({
      ...contextoTarifario.formularioEmpresa,
      fechaInicial: '',
      fechaFinal: '',
      portafolio: '',
    })
    contextoTarifario.setListaServicios([])
    contextoTarifario.setListaPaquetes([])
    contextoTarifario.setFilaSeleccionada(-1)
    contextoTarifario.setNombreTarifarioValidacion('')
    contextoTarifario.setPortafolioSeleccionadoId('')
    contextoTarifario.setformularioActualTieneErrores(true)
  }

  useEffect(() => {
    if (contextoTarifario.numeroPagina === 1) clicEjecutarBtnRegresar()
  }, [contextoTarifario.numeroPagina])

  useEffect(() => {
    if (!btnRegresar) return

    btnRegresar.addEventListener('click', clicEjecutarBtnRegresar)

    return () =>
      btnRegresar.removeEventListener('click', clicEjecutarBtnRegresar)
  }, [btnRegresar])

  return (
    <ContenedorPagina
      tituloPagina={
        rolValido
          ? TEXTO_VISUAL.TARIFARIO.VER_TARIFARIOS
          : TEXTO_VISUAL.TARIFARIO.VER_MODIFICAR_TARIFARIOS
      }
    >
      <div className="justify-center items-center flex">
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoTarifario}
        />
      </div>
      <div
        className={`w-full  justify-center items-center ${
          contextoTarifario.numeroPagina === 1 ? 'hidden' : 'flex'
        }`}
      >
        <InputSeleccionarPortafolio numeroPagina={2} modalEdicion={true} />
      </div>
      <Pestana
        contexto={contextoTarifario}
        numeroPagina={1}
        renderOnValidacionPagina
      >
        <SeleccionTarifario />
      </Pestana>
      <Pestana contexto={contextoTarifario} numeroPagina={2}>
        <FormularioTarifario
          claseBloqueo={claseBloqueo}
          numeroPagina={2}
          modalEdicion={true}
        />
      </Pestana>
      <Pestana contexto={contextoTarifario} numeroPagina={3}>
        <TablaSedes
          claseBloqueo={claseBloqueo}
          contexto={contextoTarifario}
          numeroPagina={3}
        />
      </Pestana>
      <Pestana contexto={contextoTarifario} numeroPagina={4}>
        <TablaAliados
          claseBloqueo={claseBloqueo}
          contexto={contextoTarifario}
          numeroPagina={4}
        />
      </Pestana>
      <Pestana
        textoBotonUltimaPaginaGerenteGeneral={'Regresar a ver tarifario'}
        contexto={contextoTarifario}
        numeroPagina={5}
        clickGuardar={manejarGuardar}
        deshabilitarBotonGuardar={false}
      >
        <FormularioPrecios
          claseBloqueo={claseBloqueo}
          contexto={contextoTarifario}
          numeroPagina={5}
        />
      </Pestana>
    </ContenedorPagina>
  )
}
export default EditarTarifario
