import React, {useState} from 'react'

const CeldaConTooltip = ({value: initialValue}) => {
  const [abrirDescripcion, setAbrirDescripcion] = useState(false)

  return (
    <div
      className="flex justify-center w-full"
      onMouseEnter={() => setAbrirDescripcion(true)}
      onMouseLeave={() => setAbrirDescripcion(false)}
    >
      <span className="text-center truncate">{initialValue}</span>
      <div
        className={
          abrirDescripcion
            ? 'flex justify-center items-center text-sm bg-cendiatra text-gray-100 px-1 absolute rounded  shadow-xl -mt-1 z-50'
            : 'hidden'
        }
      >
        <p>
          <span className="text-center text-lg z-50">{initialValue}</span>
        </p>
      </div>
    </div>
  )
}

export default CeldaConTooltip
