import React, {useContext, useMemo} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {AreaDeTexto} from '../../inputs'

const CampoTextoLargo = ({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const valorCampo =
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  const manejarCambioValor = (evt) => {
    if (readOnly) return

    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: evt.target.value,
        },
      },
    })
  }

  return (
    <AreaDeTexto
      estilosContenedor={'w-full flex'}
      estilosAreaDeTexto={`      ${
        campo?.requerido && !valorCampo
          ? 'border-cendiatra-rojo-1'
          : 'border-cendiatra'
      } rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
      titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
      placeholder={
        Boolean(campo.atributos?.textoPredeterminado)
          ? campo.atributos?.textoPredeterminado
          : 'Descripción'
      }
      requerido={campo.requerido}
      valor={valorCampo}
      cambioValor={manejarCambioValor}
      readOnly={readOnly}
    />
  )
}

export default CampoTextoLargo
