import React, {useState, useContext, useEffect} from 'react'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import CeldaConBotonSimple from '../Tablas/CeldaConBotonSimple'
import {ContenedorDesplegable} from '../Contenedores'
import {ContextApplication} from '../../contexto'
import moment from 'moment'
import {FormularioDatosDeLosServicios} from '../ConfirmacionPagosComponentes'
import ComponenteConjuntoDeFiltros from '../Filtros/ComponenteConjuntoDeFiltros'
import {
  agregarFormatoFecha,
  aplicarFormatoMoneda,
} from '../../utilidades/funcionesComunes'
import {AgregarServiciosACache} from '../../cache/servicios-cache'
import {Input} from '../inputs'
import {
  GuardarNumeroFactura,
  ObtenerPagosConfirmados,
} from '../../microservicios'
import {ObtenerSedePorId} from '../../cache/servicios-cache'
import {BotonSimple} from '../Botones'

const TablaFacturacionConfirmacionPagos = () => {
  const contextoAplicacion = useContext(ContextApplication)

  useEffect(() => {
    const obtenerDatosServicios = () => {
      AgregarServiciosACache()
    }
    obtenerDatosServicios()
  }, [])

  const filtrosIniciales = {
    tamano: 10,
    pagina: 1,
    filtros: {
      fechaPago: '',
      correo: '',
      razonSocial: '',
      nit: '',
      sede: '',
      fechaConfirmacion: '',
      nombreUsuario: '',
      nombreBanco: '',
      numeroCuenta: '',
      referencia: '',
      valor: '',
    },
  }

  const [dataFiltros, setDataFiltros] = useState(filtrosIniciales)

  const [datosTabla, setDatosTabla] = useState({datos: [], datosPaginacion: {}})

  const [actualizarInformacion, setActualizarInformacion] = useState(true)

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      try {
        const respuesta = await ObtenerPagosConfirmados(dataFiltros)
        if (respuesta.status === 200) {
          return setDatosTabla({
            datos: await Promise.all(
              respuesta?.data?.datos.map(async (fila) => {
                const sede = await ObtenerSedePorId(fila.sedeId)
                return {
                  ...fila,
                  sede: sede.Nombre,
                  numeroFacturaHelisa: '',
                }
              }) || []
            ),
            datosPaginacion: respuesta?.data?.paginacion || [],
          })
        }
        return setDatosTabla({datos: [], datosPaginacion: {}})
      } catch (error) {
        console.log(error)
      }
    }
    obtenerDatosIniciales()
  }, [actualizarInformacion])

  const actualizarNumeroFactura = (valor, filaId) => {
    setDatosTabla((prev) => {
      const nuevosDatos = prev.datos.map((fila) =>
        fila.id === filaId ? {...fila, numeroFacturaHelisa: valor} : fila
      )
      return {...prev, datos: nuevosDatos}
    })
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: 'id',
        className: 'w-0 hidden',
        headerStyle: '',
        disableFilters: true,
        cellStyles: '',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Fecha de pago '} />,
        accessor: 'fecha',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        Cell: ({row}) => (
          <span>{moment(row?.original?.fecha).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Email de facturación'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'correo',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Razón social - nombre'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'razonSocial',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Documento'} paddingFondoClaro={''} />
        ),
        accessor: 'nit',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Sede'} paddingFondoClaro={''} />
        ),
        accessor: 'sede',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Fecha confirmación'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'fechaConfirmacion',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
        Cell: ({row}) => (
          <span>
            {moment(row?.original?.fechaConfirmacion).format(
              'DD/MM/YYYY HH:mm'
            )}
          </span>
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Usuario que confirma'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'nombreUsuario',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Banco'} paddingFondoClaro={''} />
        ),
        accessor: 'cuenta.nombreBanco',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Cuenta'} paddingFondoClaro={''} />
        ),
        accessor: 'cuenta.numeroCuenta',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Referencia'} paddingFondoClaro={''} />
        ),
        accessor: 'referencia',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Servicios'} paddingFondoClaro={''} />
        ),
        accessor: 'Servicios',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center pl-3',
        Cell: ({row}) => (
          <CeldaConBotonSimple
            texto={'Ver Servicios'}
            estilosBoton={`w-24 h-10 text-white bg-cover bg-cendiatra bg-no-repeat rounded-lg text-14px  bg-btnBg`}
            funcion={() =>
              abrirModalServiciosAEnviar(row?.original?.servicios, {
                razonSocial: row?.original?.razonSocial,
                nit: row?.original?.nit,
                correo: row?.original?.correo,
                valor: row?.original?.valor,
              })
            }
          />
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Valor de la consignación'}
            paddingFondoClaro={''}
          />
        ),
        accessor: 'valor',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center pl-3',
        Cell: ({row}) => (
          <span>{aplicarFormatoMoneda(row?.original?.valor)}</span>
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'# de la factura Helisa'}
            paddingFondoClaro={''}
          />
        ),
        id: 'numeroFacturaHelisa',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center pl-3',
        Cell: ({row}) => (
          <Input
            funcion={(e) =>
              actualizarNumeroFactura(e.target.value, row.original.id)
            }
            estilosContenedor={'w-95%'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
            }
            tipo={'text'}
            placeholder={'DESCRIPCIÓN'}
            titulo={''}
            valor={row?.original?.numeroFacturaHelisa}
          />
        ),
      },
      {
        Header: () => (
          <EncabezadoFondo titulo={'Acciones'} paddingFondoClaro={''} />
        ),
        id: 'acciones',
        className: 'w-7.14%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 flex justify-center items-center pl-3',
        Cell: ({row}) => (
          <CeldaConBotonSimple
            texto={'Facturar pago'}
            estilosBoton={`w-28 h-10 text-white bg-cover bg-cendiatra bg-no-repeat rounded-lg text-14px  bg-btnBg`}
            funcion={() =>
              guardarNumeroDeFactura(
                row.original.id,
                row.original.numeroFacturaHelisa
              )
            }
          />
        ),
      },
    ],
    [actualizarInformacion]
  )
  const filtrarInformacion = () => {
    setActualizarInformacion(!actualizarInformacion)
  }
  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }
  const guardarNumeroDeFactura = async (id, numeroFactura) => {
    if (!numeroFactura) {
      return contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <div className="text-center w-full flex-nowrap">
            <h3 className="text-cendiatra-rojo-1 mb-6 text-2xl font-bold">
              Error de datos
            </h3>
            <div className="w-full flex justify-center items-center flex-nowrap text-cendiatra-gris-1 normal-case text-2xl">
              <p>El campo </p>
              <p className="text-cendiatra ml-1"># de factura Helisa </p>
              <p className="ml-1">
                {' '}
                debe ser diligenciado para facturar el pago
              </p>
            </div>
          </div>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: () => manejarCerrarModal(),
          },
        ],
      })
    }
    const informacionFactura = {
      PagoId: id,
      NumeroFactura: numeroFactura,
    }
    try {
      const respuesta = await GuardarNumeroFactura(informacionFactura)
      if (respuesta.status === 200) {
        abrirModalConfirmacion()
        limpiarFiltros()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const abrirModalConfirmacion = () => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <div className="text-center w-full">
          <h3 className="text-cendiatra mb-6 text-2xl font-bold">
            Guardado exitoso
          </h3>
          <p className="text-cendiatra text-xl font-bold normal-case mt-20">
            La confirmación de la factura fue guardada correctamente
          </p>
        </div>
      ),
      botones: [
        {
          nombre: 'Cerrar',
          click: () => manejarCerrarModal(),
        },
      ],
    })
  }

  const abrirModalServiciosAEnviar = async (servicios, valoresFormulario) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <div className="w-full flex justify-center items-center text-center">
          <FormularioDatosDeLosServicios
            valorFactura={valoresFormulario}
            funcionCerrar={manejarCerrarModal}
            valoresFormulario={valoresFormulario}
            servicios={servicios}
          />
        </div>
      ),
      botones: [],
    })
  }

  const filtrosExternos = [
    {
      id: 1,
      estilos: 'w-10%',
      titulo: 'Fecha de pago',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fechaPago',
      formato: 'input',
      valor: dataFiltros.filtros.fechaPago,
    },
    {
      id: 2,
      estilos: 'w-10%',
      titulo: 'Email de facturación',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'correo',
      formato: 'input',
      valor: dataFiltros.filtros.correo,
    },
    {
      id: 3,
      estilos: 'w-10%',
      titulo: 'Razón social - nombre',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'razonSocial',
      formato: 'input',
      valor: dataFiltros.filtros.razonSocial,
    },
    {
      id: 4,
      estilos: 'w-10%',
      titulo: 'Documento',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nit',
      fechaMaxima: moment(dataFiltros.filtros.fechaHasta).format('YYYY-MM-DD'),
      formato: 'input',
      valor: dataFiltros.filtros.nit,
    },
    {
      id: 5,
      estilos: 'w-10%',
      titulo: 'Sede',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'sede',
      formato: 'input',
      valor: dataFiltros.filtros.sede,
    },
    {
      id: 6,
      estilos: 'w-10%',
      titulo: 'Fecha confirmación',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fechaConfirmacion',
      formato: 'input',
      valor: dataFiltros.filtros.fechaConfirmacion,
    },
    {
      id: 7,
      estilos: 'w-10%',
      titulo: 'Usuario que confirma',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nombreUsuario',
      formato: 'input',
      valor: dataFiltros.filtros.nombreUsuario,
    },
    {
      id: 8,
      estilos: 'w-10%',
      titulo: 'Banco',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nombreBanco',
      formato: 'input',
      valor: dataFiltros.filtros.nombreBanco,
    },
    {
      id: 9,
      estilos: 'w-10%',
      titulo: 'Cuenta',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroCuenta',
      formato: 'input',
      valor: dataFiltros.filtros.numeroCuenta,
    },
    {
      id: 10,
      estilos: 'w-10%',
      titulo: 'Referencia',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'referencia',
      formato: 'input',
      valor: dataFiltros.filtros.referencia,
    },
    {
      id: 11,
      estilos: 'w-10%',
      titulo: 'Valor de la consignacion',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'valor',
      formato: 'input',
      valor: dataFiltros.filtros.valor,
    },
  ]

  const manejarCambioEnFiltro = (e) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: agregarFormatoFecha(e),
        },
      }
    })
  }
  const manejarCambioDePagina = (numeroPagina) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: numeroPagina,
      }
    })
    setActualizarInformacion(!actualizarInformacion)
  }

  const limpiarFiltros = () => {
    setDataFiltros(filtrosIniciales)
    setActualizarInformacion(!actualizarInformacion)
  }

  const ordenarPorFecha = (datos = []) => {
    return datos.sort((a, b) => {
      const fechaA = new Date(a.fechaConfirmacion)
      const fechaB = new Date(b.fechaConfirmacion)
      return fechaA - fechaB
    })
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <div className="w-full flex justify-start items-center">
        <ComponenteConjuntoDeFiltros
          filtrosPorCrear={filtrosExternos}
          funcionBoton={filtrarInformacion}
        />
      </div>
      <ContenedorDesplegable
        titulo={'Servicios a facturar'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
        ocultarBotonDesplegable={true}
      >
        <div className="w-full border border-cendiatra -mt-3 border-t-0 border-b-0 h-10 -mb-7 "></div>
        <TablaConPaginacionYFiltros
          columns={columns}
          data={ordenarPorFecha(datosTabla?.datos ?? [])}
          filasPorPagina={10}
          funcionFilaSeleccionada={null}
          paginacionBackend={true}
          parametrosPaginacionConBackEnd={datosTabla.datosPaginacion}
          maximoDePaginasparaMostrar={10}
          funcionCambiarPagina={manejarCambioDePagina}
          estilosTablaDatos={
            'border border-cendiatra -mt-1 border-t-0 pb-2 px-2 rounded-xl rounded-t-none'
          }
        />
        <div className="w-full flex justify-end items-center -mt-32 mr-10">
          <BotonSimple
            texto={'Limpiar filtros'}
            estilosBoton={
              'w-32 h-10 rounded-lg bg-cover bg-btnBg bg-no-repeat text-white text-lg border border-white'
            }
            funcion={limpiarFiltros}
            tipoDeBoton={'button'}
          />
        </div>
      </ContenedorDesplegable>
    </div>
  )
}

export default TablaFacturacionConfirmacionPagos
