import React from 'react'
import {SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA, TEXTO_VISUAL} from '../../constantes'
import {TextoSemaforo} from '../semaforos/TextoSemaforo'

const TEXTO_PANTALLA = TEXTO_VISUAL.RESOLUCION_FIRMA_ELECTRONICA.SEMAFORO

export const SemaforoResolucionesFirmaElectronica = () => {
  return (
    <div className="text-cendiatra">
      <TextoSemaforo
        colorSemaforo={
          SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_ACTIVA
        }
        texto={TEXTO_PANTALLA.RESOLUCION_ACTIVA}
      />
      <TextoSemaforo
        colorSemaforo={
          SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_PROXIMA_VENCER
        }
        texto={TEXTO_PANTALLA.RESOLUCION_PROXIMA_VENCER}
      />
      <TextoSemaforo
        colorSemaforo={
          SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_VENCIDA
        }
        texto={TEXTO_PANTALLA.RESOLUCION_VENCIDA}
      />
    </div>
  )
}
