import React, {useEffect, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ContextoEmpresa} from '../../contexto'
import {BotonesFormularioPasos} from '../Botones'
import CargarLogo from './CargarLogo'
import ImagenPorDefecto from '../../imagenes/no_imagen_sin_fondo.webp'
import {
  ROLES_APP,
  regexSinCaracteresEspeciales,
  regexTextoCaracteresBasicos,
} from '../../constantes'
import {Input, ErroresInput, AreaDeTexto} from '../inputs'
import {useObtenerRol} from '../../utilidades'

export default function FormularioAdicional({numPag, funcionSubmit, claseBloqueo}) {
  const contextoEmpresa = useContext(ContextoEmpresa)

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    setValue,
    watch,
    getValues,
    control,
    formState: {errors, isValid, touchedFields},
  } = useForm({
    defaultValues: {
      estaActiva: true,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [])

  useEffect(() => {
    if (numPag === contextoEmpresa.numeroPagina) {
      trigger()
      contextoEmpresa.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
    contextoEmpresa.setformularioAdicional(getValues())
    AsignarValorPorDefectoSiAplica()
  }, [
    contextoEmpresa.actualizar,
    isValid,
    contextoEmpresa.numeroPagina,
    touchedFields,
  ])

  useEffect(() => {
    reset(contextoEmpresa.formularioAdicional)
    trigger()
  }, [contextoEmpresa.actualizar])

  useEffect(() => {
    if (numPag === contextoEmpresa.numeroPagina) {
      trigger()
    }
  }, [contextoEmpresa.formularioAdicional.estaActiva])

  useEffect(() => {
    const subscription = watch(() => {
      contextoEmpresa.setformularioAdicional(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const AsignarValorPorDefectoSiAplica = () => {
    if (
      contextoEmpresa.logoEmpresa &&
      (contextoEmpresa.logoEmpresa.url == null ||
        contextoEmpresa.logoEmpresa.url == ImagenPorDefecto ||
        contextoEmpresa.logoEmpresa.url == '')
    ) {
      contextoEmpresa.setLogoEmpresa({
        nombre: 'Seleccionar archivo',
        url: ImagenPorDefecto,
      })
    }
  }

  const obtenerInformacionFormulario = () => {
    contextoEmpresa.setformularioAdicional(getValues())
  }


  return (
    <form
      onSubmit={handleSubmit(funcionSubmit)}
      className={`w-full flex flex-wrap justify-center my-5 ${claseBloqueo}`}
    >
      <div className="w-full">
        <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
          <div className="w-full md:w-4/12 flex justify-center flex-wrap">
            
              <div>
                <div className="w-full text-center text-lg text-cendiatra font-bold my-5">
                  <span> Activar / Inactivar empresa</span>
                </div>
                <div
                  className="relative w-20 mr-2 align-middle rounded-full select-none transition duration-200 ease-in flex items-center justify-around border"
                  onClick={() => {
                    contextoEmpresa.setformularioAdicional({
                      ...contextoEmpresa.formularioAdicional,
                      estaActiva:
                        !contextoEmpresa.formularioAdicional.estaActiva,
                    })
                    setValue(
                      'estaActiva',
                      !contextoEmpresa.formularioAdicional.estaActiva
                    )

                    !contextoEmpresa.formularioAdicional.estaActiva &&
                      setValue('motivoDesactivacion', '')
                  }}
                >
                  <div className="flex items-center justify-center w-full">
                    <input
                      type="checkbox"
                      className={
                        !contextoEmpresa.formularioAdicional.estaActiva
                          ? ' absolute  left-2 w-5 h-5 rounded-full border-red-500 bg-red-500 bg-center bg-cover appearance-none cursor-pointer text-red-500'
                          : ' absolute right-2 w-5 h-5 rounded-full border-cendiatra bg-cendiatra border-4 appearance-none cursor-pointer text-green-400'
                      }
                      {...register('estaActiva', {required: false})}
                    />
                    <label
                      className={
                        !contextoEmpresa.formularioAdicional.estaActiva
                          ? 'block overflow-hidden h-8 w-12  text-cendiatra text-md  cursor-pointer text-right mt-2 '
                          : 'block overflow-hidden h-8 w-12  text-cendiatra text-md bg-white cursor-pointer mt-2 '
                      }
                    >
                      {!contextoEmpresa.formularioAdicional.estaActiva
                        ? 'Off'
                        : 'On'}
                    </label>
                  </div>
                </div>
              </div>
           
          </div>
          <div className="w-7/12 flex justify-start flex-wrap">
            <Controller
              name="motivoDesactivacion"
              control={control}
              rules={{
                required: !contextoEmpresa.formularioAdicional.estaActiva,
                pattern: regexTextoCaracteresBasicos,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'motivoDesactivacion'
                      ) ||
                      (!contextoEmpresa.formularioAdicional.estaActiva &&
                        !contextoEmpresa.formularioAdicional
                          .motivoDesactivacion === '')
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={`Motivo inactivación ${
                      contextoEmpresa.formularioAdicional.estaActiva ? '' : '*'
                    }`}
                    valor={value}
                    onBlur={obtenerInformacionFormulario}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'motivoDesactivacion'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten estos caracteres + ( ) [ ] , / - . '}
                  />
                </>
              )}
            />
            <Controller
              name="ejecutivoComercial"
              control={control}
              rules={{
                required: true,
                pattern: regexSinCaracteresEspeciales,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'ejecutivoComercial'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Ejecutivo comercial*'}
                    valor={value}
                    onBlur={obtenerInformacionFormulario}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'ejecutivoComercial'}
                    tipoError={'pattern'}
                    mensaje={'Los caracteres especiales no estan permitidos'}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center mt-12">
          <Controller
            name="observaciones"
            control={control}
            rules={{
              required: false,
              pattern: regexTextoCaracteresBasicos,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <AreaDeTexto
                  titulo={'Observaciones de la empresa'}
                  estilosContenedor={'w-11/12 '}
                  estilosAreaDeTexto={`h-20 my-3 rounded  w-full  p-1.5 border ${
                    Object.keys(errors).find(
                      (element) => element === 'observaciones'
                    )
                      ? 'border-cendiatra-rojo-1'
                      : 'border-cendiatra'
                  } text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm h-28`}
                  placeholder={'DESCRIPCIÓN'}
                  valor={value}
                  cambioValor={onChange}
                  rows={'6'}
                  onBlur={obtenerInformacionFormulario}
                />
                <div className="w-11/12">
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'observaciones'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten estos caracteres + ( ) [ ] , / - . '}
                  />
                </div>
              </>
            )}
          />
        </div>
        <CargarLogo contexto={contextoEmpresa} />
      </div>
      <div className="pt-6 w-full flex justify-center items-center">
        <BotonesFormularioPasos
          contexto={contextoEmpresa}
          textoBotonUltimaPaginaGerenteGeneral={'Regresar a ver empresa hija'}
          fnUltimaPaginaGerenteGeneral={() => {
            contextoEmpresa.setIdEmpresaPadre()
            contextoEmpresa.setIdEmpresa()
            contextoEmpresa.setformularioDatos({})
            contextoEmpresa.setformularioFacturacion({})
            contextoEmpresa.setformularioAdicional({estaActiva: true})
            contextoEmpresa.setFormularioUsuarioB2c({})
          }}
        />
      </div>
    </form>
  )
}
