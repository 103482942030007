import {ObtenerUsuariosB2C} from '../../microservicios/B2C'
import {dbComercial} from '../bases-datos'
import Dexie from 'dexie'

async function ObtenerUsuarioPorCorreo(correo) {
  const usuario =
    (await dbComercial.UsuariosPortal?.filter(
      ({Correo}) => Correo?.toUpperCase() === correo.toUpperCase()
    )?.first()) ?? null

  return usuario
}

async function ObtenerUsuariosPorCorreo(correo) {
  const usuario =
    (await dbComercial.UsuariosPortal?.filter(
      ({Correo}) => Correo?.toUpperCase() === correo.toUpperCase()
    )?.toArray()) ?? []

  return usuario
}

async function ObtenerUsuarioPorNombreUsuarioB2c(nombreUsuario) {
  const usuario =
    (await dbComercial.UsuariosPortal?.filter(
      ({UserPrincipalName}) =>
        UserPrincipalName?.split('@')?.[0]?.toUpperCase() ===
        nombreUsuario.toUpperCase()
    )?.first()) ?? null

  return usuario
}

async function AgregarUsuariosACache(usuarios = [], desdeOtroModulo = false) {
  const db = await dbComercial.open()

  if (db.isOpen() && usuarios.length && !desdeOtroModulo) {
    db.UsuariosPortal.bulkPut(mapUsuarios(usuarios)).catch(
      Dexie.BulkError,
      (e) => {
        console.error(
          'Error al agregar usuarios portal al caché comercial: ' +
            (e.stack || e)
        )
      }
    )
  } else if (desdeOtroModulo) {
    const response = await ObtenerUsuariosB2C()

    if (response.status === 200) {
      db.UsuariosPortal.bulkPut(mapUsuarios(response.data))
    }
  }
}

function mapUsuarios(usuarios = []) {
  return usuarios.map((usuario) => ({
    Guid: usuario.guid,
    Correo: usuario.correo,
    UserPrincipalName: usuario.userPrincipalName,
    Nombres: usuario.nombres,
    EmpresaId: usuario?.empresaId ?? null,
    Cargo: usuario?.cargo ?? '',
  }))
}

async function obtenerUsuarioB2cPorGuid(guid) {
  return Promise.resolve(dbComercial.table('UsuariosPortal').get({Guid: guid}))
}

async function obtenerUsuarioB2cPorEmpresaId(id) {
  return Promise.resolve(
    dbComercial.table('UsuariosPortal').get({EmpresaId: id})
  )
}

export {
  ObtenerUsuarioPorCorreo,
  AgregarUsuariosACache,
  ObtenerUsuarioPorNombreUsuarioB2c,
  obtenerUsuarioB2cPorGuid,
  obtenerUsuarioB2cPorEmpresaId,
  ObtenerUsuariosPorCorreo,
}
